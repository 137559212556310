import React from "react"
import classNames from "classnames"
import S from "./Button.module.scss"

export default function Button({
  color = "primary",
  size = "lg",
  className,
  rounded = false,
  disabled = false,
  type = "button",
  children,
  active = false,
  Component = "button",
  innerRef = undefined,
  ...props
}) {
  return (
    <Component
      ref={innerRef}
      disabled={disabled}
      type={type}
      className={classNames(
        className,
        S["wp-btn"],
        S["wp-btn-size-" + size],
        S["wp-btn-" + color],
        {
          [S["wp-btn-rounded"]]: rounded,
          [S["wp-btn-active"]]: active,
        }
      )}
      {...props}
    >
      {children}
    </Component>
  )
}

export function ButtonGroup({ children }) {
  return (
    <div className={S["button-group"]}>
      {children}
    </div>
  )
}

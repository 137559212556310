import React from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import { convertErrorToForm } from "../utils"
import Button from "./Button"
import FieldInput from "./fields/FieldInput"
import WpModal from "./WpModal"
import { Field, Formik } from "formik"

const INITIAL_VALUES = {
  date_start: "",
  date_end: "",
  title: "",
  description: "",
  project_task: 0,
}

export function ProjectTaskSprintModal({ isOpen, sprint, onSave, onCancel, onClosed }) {
  const { t } = useTranslation(["translation", "field", "action"])

  const schema = yup.object().shape({
    date_start: yup
      .date()
      .typeError(
        t("field:errors.date", {
          field: t("field:project_task_sprint.date_start"),
        })
      )
      .required(t("field:errors.mandatory", { field: t("field:project_task_sprint.date_start") })),
    date_end: yup
      .date()
      .typeError(
        t("field:errors.date", {
          field: t("field:project_task_sprint.date_end"),
        })
      )
      .required(t("field:errors.mandatory", { field: t("field:project_task_sprint.date_end") })),
  })

  return (
    <WpModal
      onClosed={onClosed}
      isOpen={isOpen}
      toggle={onCancel}
      title={sprint.id ? t("action:edit_sprint") : t("action:new_sprint")}
    >
      <div>
        <div className="d-flex flex-row align-items-start my-4">
          <div className="d-flex flex-column ml-2 flex-1">
            <Formik
              initialValues={{ ...INITIAL_VALUES, ...sprint }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                return onSave(values).catch((error) => {
                  actions.setErrors(convertErrorToForm(error))
                })
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <>
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Field
                      name="date_start"
                      component={FieldInput}
                      type="date"
                      label={t("field:project_task_sprint.date_start")}
                      className={"mb-5"}
                    />
                    <Field
                      name="date_end"
                      component={FieldInput}
                      type="date"
                      label={t("field:project_task_sprint.date_end")}
                      className={"mb-5"}
                    />
                    {/* <Field
                      name="title"
                      component={FieldInput}
                      className={"mb-5"}
                      placeholder={t("field:incoming_request.placeholder.title")}
                      label={t("field:incoming_request.title")}
                    />
                    <Field
                      name="description"
                      component={FieldTextarea}
                      rows={4}
                      className={"mb-5"}
                      placeholder={t("field:incoming_request.placeholder.description")}
                      label={t("field:incoming_request.description")}
                    /> */}
                    <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                      <Button size="sm" color="none" onClick={onCancel}>
                        {t("action:cancel")}
                      </Button>
                      <Button size="sm" color="primary" type="submit" className="ml-5" disabled={!formik.isValid}>
                        {sprint.id ? t("action:save") : t("action:add")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </WpModal>
  )
}

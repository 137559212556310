import React, { useRef } from "react"
import { getIn } from "formik"
import { useTranslation } from "react-i18next"
import ErrorPopover from "../ErrorPopover/ErrorPopover"
import WpTextarea from "../WpTextarea"

export default function FieldTextarea({
  field,
  form: { touched, errors },
  label,
  ...props
}) {
  const { t } = useTranslation(["field"])
  const textarea = useRef()
  const fieldError = getIn(errors, field.name)
  const fieldTouched = getIn(touched, field.name)
  const showError = fieldError && fieldTouched
  const { value, ...passField } = field

  return (
    <div className="d-flex flex-column">
      {label && (
        <label className="font-weight-semibold mb-3 h3 text-uppercase">
          {label}
        </label>
      )}
      <WpTextarea
        ref={textarea}
        invalid={showError}
        {...passField}
        {...props}
        value={value ?? ""}
      />
      {showError && (
        <ErrorPopover
          placement={"top-start"}
          target={textarea.current}
          title={t("field:errors.general")}
          description={fieldError}
        />
      )}
    </div>
  )
}

import React, { useEffect, useRef, useState } from "react"

export default function MeasureContext({
  as,
  children,
  bottom,
  left,
  right,
  maxHeight = Infinity,
  ...props
}) {
  const domRef = useRef(null)
  const [top, setTop] = useState(null)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const nextTop = domRef.current?.getBoundingClientRect().top

  useEffect(() => {
    if (nextTop !== top) {
      setTop(nextTop)
    }
  }, [nextTop, top])

  useEffect(() => {
    const handler = (e) => {
      setWindowHeight(window.innerHeight)
    }
    window.addEventListener("resize", handler)
    return () => window.removeEventListener("resize", handler)
  }, [])

  const Component = as
  const height = Math.min(maxHeight, windowHeight - top - bottom)

  return (
    <>
      {top !== null && top !== undefined && (
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          style={{
            position: "fixed",
            top: windowHeight - height - bottom,
            bottom,
            height,
            left: 0,
            right: 0,
          }}
        >
          <Component
            {...props}
            style={{
              ...props.style,
            }}
          />
        </div>
      )}
      {children(domRef)}
    </>
  )
}

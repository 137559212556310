import classNames from "classnames"
import { groupBy, toPairs } from "lodash"
import { Fragment, useMemo } from "react"
import { useTranslation } from "react-i18next"
import S from "../style.module.scss"

export function Legend({ kinds, allocs }) {
  const { t } = useTranslation()

  const totals = useMemo(() => {
    if (kinds && allocs) {
      const result = {}
      for (const kind of kinds) {
        result[kind.id] = 0
      }
      for (const day of Object.values(allocs)) {
        for (const alloc of day.allocations) {
          result[alloc.kind] += alloc.hours
        }
      }
      return result
    }
    return {}
  }, [allocs, kinds])

  return (
    <div>
      <div className="d-flex flex-row justify-content-start align-items-center mb-5">
        <div className="flex-1">
          <b>{t("hr_workflow_new.allocation")}</b>
        </div>
        <div className="text-right">
          <b>{t("hr_workflow_new.hour_other")}</b>
        </div>
      </div>
      {toPairs(groupBy(kinds, "group")).map(([groupId, groupOfKinds], i, lst) => {
        return (
          <Fragment key={groupId}>
            {groupOfKinds.map((kind) => {
              const total = totals[kind.id] ?? 0
              return (
                <div className="d-flex flex-row align-items-center mb-2" key={kind.id}>
                  <div className={classNames("mr-5", S["legend-color-showcase"])} style={{ backgroundColor: kind.color }} />
                  <div className="flex-1 font-size-label">{kind.verbose_name}</div>
                  <div className={classNames("text-right font-weight-bold", { "text-muted": total === 0 })}>{total}</div>
                </div>
              )
            })}
            {i < lst.length - 1 && <hr />}
          </Fragment>
        )
      })}
    </div>
  )
}

import dayjs from "dayjs"
import { get, groupBy, mapValues, sumBy } from "lodash"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { BsExclamationTriangleFill } from "react-icons/bs"
import { Table } from "reactstrap"
import { makeDate } from "../utils"

export function CalendarList({ month, year, allocs, kinds }) {
  const { t, i18n } = useTranslation()
  const allDates = useMemo(() => {
    const baseDate = dayjs(makeDate(year, month, 1)).locale(i18n.language)
    const dates = []
    for (let i = 0; i < baseDate.daysInMonth(); i++) {
      dates.push(baseDate.add(i, "day"))
    }
    return dates
  }, [i18n.language, month, year])

  return (
    <Table striped bordered className="mb-0" size="sm">
      <thead>
        <tr>
          <th>{t("hr_workflow_new.table_column_date")}</th>
          <th>{t("hr_workflow_new.table_column_day")}</th>
          {kinds.map((kind) => (
            <th key={kind.id}>{kind.verbose_name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {allDates.map((date) => {
          const dateData = get(allocs, date.format("YYYY-MM-DD"), { allocations: [], alerts: [] })
          const allocLookup = mapValues(groupBy(dateData.allocations, "kind"), (allocs) => sumBy(allocs, "hours"))
          const hasAlerts = dateData.alerts.length > 0
          return (
            <tr key={date.format("YYYY-MM-DD")}>
              <td>
                {date.format("L")}
                {hasAlerts && (
                  <span className="ml-4 text-danger">
                    <BsExclamationTriangleFill />
                  </span>
                )}
              </td>
              <td className="text-uppercase">{date.format("ddd")}</td>
              {kinds.map((kind) => (
                <td key={kind.id}>{allocLookup[kind.id] ?? ""}</td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )

  // return (
  //   <div className={classNames(S["calendar-row"], "bg-primary-10 rounded p-4 h-100 d-flex flex-column")}>
  //     {calendarMatrix.map((row, i) => (
  //       <div className="d-flex flex-row flex-1" key={i}>
  //         {row.map((cell, j) => {
  //           const date = dayjs(makeDate(year, month, cell)).locale(i18n.language)
  //           // eslint-disable-next-line no-unused-vars
  //           const { alerts: dateAlerts, work_day: workDay, allocations } = get(allocs, date.format("YYYY-MM-DD"), {
  //             allocations: [],
  //             alerts: [],
  //             work_day: true,
  //           })
  //           const isFilled = allocations.length > 0
  //           const hasAlerts = dateAlerts.length > 0
  //           const groupedAllocations = groupBy(allocations, "kind")
  //           const aggregatedAllocations = Object.values(groupedAllocations).map((allocs) =>
  //             allocs.reduce(
  //               (acc, alloc) => {
  //                 return { ...alloc, hours: acc.hours + alloc.hours }
  //               },
  //               { hours: 0 }
  //             )
  //           )
  //           const sortedAllocations = orderBy(
  //             aggregateAllocations ? aggregatedAllocations : allocations,
  //             ["kind_group", "kind_order"],
  //             ["asc", "asc"]
  //           )
  //           return (
  //             <div
  //               key={j}
  //               className={S["calendar-cell"]}
  //               onClick={() => {
  //                 onDateSelect(date)
  //               }}
  //             >
  //               {cell > 0 && (
  //                 <div>
  //                   <div
  //                     className={classNames("d-flex flex-row justify-content-center align-items-center mb-3", {
  //                       "text-primary": isFilled && !hasAlerts,
  //                       "text-danger": hasAlerts,
  //                     })}
  //                   >
  //                     <div className={classNames("mr-3", S["calendar-cell-day"])}>{cell.toString().padStart(2, "0")}</div>
  //                     <div className="text-uppercase">{date.format("ddd")}</div>
  //                   </div>
  //                   <div
  //                     className="d-flex flex-row flex-wrap justify-content-start align-items-center"
  //                     style={{ rowGap: 2, columnGap: 2 }}
  //                   >
  //                     {sortedAllocations.map((alloc) => {
  //                       return (
  //                         <div
  //                           className={S["calendar-badge"]}
  //                           style={{
  //                             width: Math.min(1, alloc.hours / 8) * 100 + "%",
  //                             backgroundColor: alloc.kind_color,
  //                             color: alloc.kind_text_color,
  //                             minWidth: 20,
  //                           }}
  //                           title={`${alloc.kind_verbose} ${alloc.hours}h`}
  //                           key={alloc.id}
  //                         >
  //                           {alloc.hours}h
  //                         </div>
  //                       )
  //                     })}
  //                   </div>
  //                 </div>
  //               )}
  //             </div>
  //           )
  //         })}
  //       </div>
  //     ))}
  //   </div>
  // )
}

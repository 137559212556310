import React, { useContext } from "react"
import { FlowGraph } from "../../../components/FlowGraph"
import Layout from "../../../components/Layout"
import { ProjectContext } from "../../../context"
import { useProjectFlow } from "../../../hooks/project"
import ProjectTabBarContent from "../ProjectTabBarContent"
import ProjectBottomBarContent from "../ProjectBottomBarContent"
import { TrackerLandmarks } from "../../../components/TrackerLandmarks"

export function ProjectFlow() {
  const [{ project }] = useContext(ProjectContext)
  const [{ graph }] = useProjectFlow(project.id)

  return (
    <Layout>
      {graph && (
        <div className="d-flex flex-row justify-content-center mt-9">
          <FlowGraph
            width={1000}
            nodes={graph[0]}
            arcs={graph[1]}
            current={`p${project.id}`}
          ></FlowGraph>
        </div>
      )}
      <Layout.TabBar>
        <ProjectTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <ProjectBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { Table } from "reactstrap"
import Layout from "../../components/Layout"
import { TrackerLandmarks } from "../../components/TrackerLandmarks"
import { CAP, CAPLEVEL, useCapabilities } from "../../hooks/capabilities"
import { useHrAllocationKinds } from "../../hooks/useHrAllocations"
import { useHrAnomalyDetectionRules } from "../../hooks/useHrAnomalyDetectionRules"
import { useHrResources } from "../../hooks/useHrResources"
import { Navbar } from "./components/Navbar"
import dayjs from "dayjs"
import LocaleData from "dayjs/plugin/localeData"
import { keyBy } from "lodash"

dayjs.extend(LocaleData)

export function HrWorkflowAnomalyDetectionRules() {
  const { t, i18n } = useTranslation()
  const [, { hasCapability }] = useCapabilities()
  const isHrAdmin = hasCapability(CAP.WORKFLOW, CAPLEVEL.ADMIN)
  const [{ data: hrResources }] = useHrResources()
  const [{ data: kinds }] = useHrAllocationKinds()
  const [{ data: rules }] = useHrAnomalyDetectionRules()

  if (!isHrAdmin) {
    return (
      <Layout>
        <Navbar />
        <div className="px-page">Accesso negato</div>
        <Layout.FirstLevelNavi>
          <TrackerLandmarks />
        </Layout.FirstLevelNavi>
      </Layout>
    )
  }

  if (!hrResources || !kinds || !rules) {
    return (
      <Layout>
        <Navbar />
        <div className="px-page">Caricamento...</div>
        <Layout.FirstLevelNavi>
          <TrackerLandmarks />
        </Layout.FirstLevelNavi>
      </Layout>
    )
  }

  const kindsMap = keyBy(kinds, "id")
  const resourcesMap = keyBy(hrResources, "id")

  return (
    <Layout displayRawContent className="flex-1 d-flex flex-column justify-content-start align-items-stretch">
      <Navbar />
      <div className="pt-5 px-page pb-page-bottom flex-1 d-flex flex-column">
        <h1 className="text-primary font-weight-semibold">{t("hr_workflow_anomaly_rules.title")}</h1>
        <Table striped className="mt-7">
          <thead>
            <tr>
              <th>{t("field:anomaly_detection_rule.name")}</th>
              <th>{t("field:anomaly_detection_rule.weekday")}</th>
              <th>{t("field:anomaly_detection_rule.total_hours")}</th>
              <th>{t("hr_workflow_anomaly_rules.col_kinds")}</th>
              <th>{t("field:anomaly_detection_rule.apply_to")}</th>
              <th>{t("hr_workflow_anomaly_rules.col_validity")}</th>
            </tr>
          </thead>
          <tbody>
            {rules?.map((rule) => (
              <tr key={rule.id}>
                <td>
                  <div className="d-flex flex-column">
                    {rule.name}
                    <small>{rule.description}</small>
                  </div>
                </td>
                <td className="text-capitalize">
                  {dayjs().locale(i18n.language).localeData().weekdaysShort()[rule.weekday]}
                  <br />
                  <small>
                    {rule.enabled_holidays && !rule.enable_non_holidays && t("hr_workflow_anomaly_rules.enable_only_holidays")}
                    {!rule.enabled_holidays &&
                      rule.enable_non_holidays &&
                      t("hr_workflow_anomaly_rules.enable_only_non_holidays")}
                    {rule.enabled_holidays && rule.enable_non_holidays && t("hr_workflow_anomaly_rules.enable_always")}
                  </small>
                </td>
                <td>{rule.total_hours}</td>
                <td>
                  {rule.any_kind && t("field:anomaly_detection_rule.any_kind")}
                  {!rule.any_kind &&
                    rule.select_kinds.map((kindId) => (
                      <Fragment key={kindId}>
                        {kindsMap[kindId].verbose_name}
                        <br />
                      </Fragment>
                    ))}
                </td>
                <td>
                  {rule.apply_to.map((resourceId) => (
                    <Fragment key={resourcesMap[resourceId].id}>
                      {resourcesMap[resourceId].full_name}
                      <br />
                    </Fragment>
                  ))}
                </td>
                <td>
                  {rule.valid_from ? dayjs(rule.valid_from).format("DD/MM/YYYY") : "∞"}
                  {" - "}
                  {rule.valid_to ? dayjs(rule.valid_to).format("DD/MM/YYYY") : "∞"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import { FormikContext, useFormik } from "formik"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import EstimateSelector from "../../../components/EstimateSelector"
import WpModal from "../../../components/WpModal/WpModal"

export default function LinkEstimateModal({
  isOpen,
  toggle,
  onClosed,
  save,
  project,
}) {
  const { t } = useTranslation(["translation", "action"])

  // deps.maybe(null) -> turn off automatic running provided by useRunRj
  const formik = useFormik({
    initialValues: { estimate: null },
    onSubmit: (values, actions) => {
      return save({ estimate: values.estimate })
    },
  })

  const estimateDefaultFilters = useMemo(
    () => ({
      customer: project.customer ?? undefined,
      drafting_state: "approved",
    }),
    [project.customer]
  )

  return (
    <WpModal
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
      title={t("project_info.link_estimates")}
    >
      <FormikContext.Provider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-column">
            <label className={"font-weight-semibold mb-3 h3 text-uppercase"}>
              {t("project_info.estimate_link_modal.estimate_label")}
            </label>
            <EstimateSelector
              estimateId={formik.values.estimate}
              onEstimateChange={(nextEstimate) => {
                formik.setFieldValue("estimate", nextEstimate.id)
              }}
              placeholder={t(
                "project_info.estimate_link_modal.estimate_placeholder"
              )}
              capitalize={false}
              emptyMessage={t("field:errors.no_match")}
              defaultFilters={estimateDefaultFilters}
            />
          </div>
          <div className="text-right mt-5">
            <Button
              type="button"
              size="sm"
              color="none"
              onClick={() => toggle()}
              className="mr-4"
            >
              {t("action:cancel")}
            </Button>
            <Button type="submit" size="sm">
              {t("action:save")}
            </Button>
          </div>
        </form>
      </FormikContext.Provider>
    </WpModal>
  )
}

import dayjs from "dayjs"
import { identity, truncate } from "lodash"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { BsCheckCircle, BsCheckCircleFill } from "react-icons/bs"
import { FaCheck, FaCircle, FaTimes } from "react-icons/fa"
import { TbArrowRightCircle } from "react-icons/tb"
import { deps } from "react-rocketjump"
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import EmptyFilterImage from "../assets/icons/empty_filter.svg"
import { useInteractions } from "../hooks/interaction"
import useModalTrigger from "../hooks/useModalTrigger"
import useWpHistoryMethods from "../hooks/useWpHistoryMethods"
import Button from "./Button"
import ConfirmDeleteModal from "./ConfirmDeleteModal"
import { CreateCustomerInteractionModal } from "./CreateCustomerInteractionModal"
import EstimateSelector from "./EstimateSelector/EstimateSelector"
import FilterButton from "./FilterButton"
import Icon from "./Icon"
import IncomingRequestSelector from "./IncomingRequestSelector"
import LongBorder from "./LongBorder"
import MeasureContext from "./MeasureContext/MeasureContext"
import OrgUserSelector from "./OrgUserSelector/OrgUserSelector"
import Pagination from "./Pagination/Pagination"
import ProjectSelector from "./ProjectSelector"
import Table from "./Table"
import WpInput from "./WpInput"
import WpLink from "./WpLink"

export function InteractionList({
  filter,
  initial,
  disableCreate = false,
  displayCustomer = false,
  disableEstimateFilter = false,
  disableProjectFilter = false,
  disableRequestFilter = false,
  disableAssigneeFilter = false,
  customer = null,
}) {
  const { t } = useTranslation(["translation", "tab", "action"])

  const [
    { completed, pending, canceled, fromDate, toDate, ordering, estimate, project, request, assignee },
    setQueryParams,
    {
      page: debPage,
      completed: debCompleted,
      pending: debPending,
      canceled: debCanceled,
      fromDate: debFromDate,
      toDate: debToDate,
      ordering: debOrdering,
      estimate: debEstimate,
      project: debProject,
      request: debRequest,
      assignee: debAssignee,
    },
    setDebQueryParams,
  ] = useQueryParams({
    page: {
      encode: (intValue) => intValue.toString(10),
      decode: (strValue) => (strValue ? parseInt(strValue, 10) : 1),
    },
    completed: {
      decode: (strValue) => (strValue ?? "false") === "true",
      encode: (boolValue) => (boolValue !== undefined ? boolValue.toString() : undefined),
    },
    pending: {
      decode: (strValue) => (strValue ?? "true") === "true",
      encode: (boolValue) => (boolValue !== undefined ? boolValue.toString() : undefined),
    },
    canceled: {
      decode: (strValue) => (strValue ?? "false") === "true",
      encode: (boolValue) => (boolValue !== undefined ? boolValue.toString() : undefined),
    },
    ordering: {
      decode: (v) => v || "date",
      encode: identity,
    },
    estimate: {
      decode: (v) => (v ? parseInt(v, 10) : undefined),
      encode: (n) => n?.toString(10),
    },
    project: {
      decode: (v) => (v ? parseInt(v, 10) : undefined),
      encode: (n) => n?.toString(10),
    },
    request: {
      decode: (v) => (v ? parseInt(v, 10) : undefined),
      encode: (n) => n?.toString(10),
    },
    assignee: {
      decode: (v) => (v ? parseInt(v, 10) : undefined),
      encode: (n) => n?.toString(10),
    },
  })

  const filters = useMemo(() => {
    let display = []
    if (debCompleted) {
      display.push("completed")
    }
    if (debPending) {
      display.push("pending")
    }
    if (debCanceled) {
      display.push("canceled")
    }
    display = display.join("-")
    if (display.length === 0) {
      display = "none"
    }
    return {
      page: debPage,
      display: display,
      fromDate: debFromDate,
      toDate: debToDate,
      ordering: debOrdering,
      estimate: debEstimate,
      project: debProject,
      request: debRequest,
      assigned_to: debAssignee,
      ...filter,
    }
  }, [
    debCompleted,
    debPending,
    debCanceled,
    debPage,
    debFromDate,
    debToDate,
    debOrdering,
    debEstimate,
    debProject,
    debRequest,
    debAssignee,
    filter,
  ])

  const [{ interactions, count, pageCount, hasNext, hasPrev }, { create, update, run: reload, remove }] = useInteractions(
    deps.maybe(filters)
  )

  const [editModalState, editModalActions] = useModalTrigger()
  const [deleteModalState, deleteModalActions] = useModalTrigger()
  const history = useWpHistoryMethods()

  const columns = useMemo(
    () =>
      [
        {
          label: t("field:customer_interaction.date"),
          name: "date",
          size: 2,
          orderingField: "date",
          render: (selectedDatum, colName, datum) => {
            return (
              <WpLink to={`/customers/${datum.customer}/interactions/${datum.id}`}>
                {dayjs(selectedDatum).format("DD/MM/YYYY")}
              </WpLink>
            )
          },
        },
        {
          label: t("field:customer_interaction.customer_name"),
          name: "customer_name",
          size: 4,
          visible: displayCustomer,
          render: (selectedDatum, colName, datum) => {
            return <WpLink to={`/customers/${datum.customer_data.id}/interactions`}>{datum.customer_data?.name}</WpLink>
          },
        },
        {
          label: t("field:customer_interaction.description"),
          name: "description",
          size: 14 + (displayCustomer ? -4 : 0),
          render: (selectedDatum, colName, datum) => {
            return (
              <div>
                <p className="m-0">{selectedDatum}</p>
                <p className="m-0">
                  {datum.request && (
                    <Badge
                      className="mr-3 pointer"
                      color="cyan"
                      onClick={() => {
                        history.push(`/customers/${datum.customer}/requests/${datum.request}`)
                      }}
                    >
                      {truncate(datum.request_title, {
                        length: 20,
                        omission: "…",
                      })}
                    </Badge>
                  )}
                  {datum.estimate && (
                    <Badge
                      className="mr-3 pointer"
                      color="teal"
                      onClick={() => {
                        history.push(`/estimates/${datum.estimate}`)
                      }}
                    >
                      {datum.estimate_name}
                    </Badge>
                  )}
                  {datum.project && (
                    <Badge
                      className="mr-3 pointer"
                      color="cyan"
                      onClick={() => {
                        history.push(`/projects/${datum.project}`)
                      }}
                    >
                      {datum.project_name}
                    </Badge>
                  )}
                </p>
              </div>
            )
          },
        },
        {
          label: t("field:customer_interaction.status"),
          name: "status",
          size: 2,
          render: (selectedDatum, colName, datum) => {
            if (datum.completed) {
              return (
                <span>
                  <FaCheck />
                </span>
              )
            } else if (datum.canceled) {
              return (
                <span>
                  <FaTimes />
                </span>
              )
            } else if (!datum.completed && dayjs().isAfter(dayjs(datum.date))) {
              return (
                <span className="text-danger">
                  <FaCircle />
                </span>
              )
            } else {
              return null
            }
          },
        },
        {
          label: t("field:customer_interaction.assigned_to"),
          name: "assigned_to",
          size: 4,
          render: (selectedDatum, colName, datum) => {
            return <span>{datum.assigned_to_name}</span>
          },
        },
        {
          label: t("actions"),
          name: "actions",
          size: 2,
          render: (selectedDatum, colName, datum) => {
            return (
              <div className="d-flex flex-row justify-content-between align-items-center w-100">
                <span className={"action-icon-container-primary-hover"} onClick={() => editModalActions.open(datum)}>
                  <Icon name="edit" title={t("action:edit_customer_interaction")} className="pointer text-dark" />
                </span>
                <span
                  className={"action-icon-container-primary-hover"}
                  onClick={() =>
                    editModalActions.open({
                      ...datum,
                      customer: datum.customer,
                      id: undefined,
                      description: "",
                      date: "",
                      consequence_of: datum.id,
                      consequence_of_name: datum.description,
                      completed: false,
                    })
                  }
                >
                  <TbArrowRightCircle
                    className="pointer text-dark"
                    style={{ width: 24, height: 24, padding: 3 }}
                    title={t("action:create_interaction_followup")}
                  />
                </span>
                <div className={"action-icon-container-primary-hover"}>
                  <UncontrolledDropdown>
                    <DropdownToggle caret={false} tag={"span"}>
                      <Icon
                        role="button"
                        name="vdots"
                        className="text-dark pointer"
                        title={t("action:other_options")}
                        placement="right"
                      />
                    </DropdownToggle>
                    <DropdownMenu right modifiers={{ offset: { offset: "0, 12" } }} className="border-primary">
                      <DropdownItem className={"text-capitalize px-0 dropdown-item-primary-active"}>
                        <div
                          className="d-flex flex-row align-items-center mx-4"
                          onClick={() => {
                            deleteModalActions.open(datum)
                          }}
                        >
                          <Icon name="delete" className="pointer mr-4" />
                          <span>{t("action:delete")}</span>
                        </div>
                      </DropdownItem>
                      {datum.completed && (
                        <DropdownItem className={"text-capitalize px-0 dropdown-item-primary-active"}>
                          <div
                            className="d-flex flex-row align-items-center mx-4"
                            onClick={() => update.onSuccess(() => reload(filters)).run(datum.id, { completed: false })}
                          >
                            <BsCheckCircle className="pointer mr-5" style={{ margin: 3, width: 16, height: 16 }} />
                            <span>{t("action:undo_customer_interaction")}</span>
                          </div>
                        </DropdownItem>
                      )}
                      {!datum.completed && (
                        <DropdownItem className={"text-capitalize px-0 dropdown-item-primary-active"}>
                          <div
                            className="d-flex flex-row align-items-center mx-4"
                            onClick={() => update.onSuccess(() => reload(filters)).run(datum.id, { completed: true })}
                          >
                            <BsCheckCircleFill className="pointer mr-5" style={{ margin: 3, width: 16, height: 16 }} />
                            <span>{t("action:do_customer_interaction")}</span>
                          </div>
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            )
          },
        },
      ].filter((col) => col.visible !== false),
    [deleteModalActions, displayCustomer, editModalActions, filters, history, reload, t, update]
  )

  const relatedFilterDefault = useMemo(() => {
    if (customer) {
      return {
        customer: (typeof customer === "object" ? customer?.id : customer) ?? undefined,
      }
    } else {
      return undefined
    }
  }, [customer])

  return (
    <>
      <div className="">
        <div className="mt-5 mb-8 d-flex flex-row justify-content-start align-items-center flex-1">
          <b className="mr-8">{t("customer_interactions.display")}</b>
          <div
            className="d-flex flex-row justify-content-start align-items-center flex-1 flex-wrap"
            style={{ rowGap: 16, columnGap: 32 }}
          >
            <div className="d-flex flex-row align-items-center flex-1">
              <FilterButton
                onClick={() => setQueryParams({ completed: !completed, page: 1 })}
                className={"mr-4"}
                selected={completed}
              >
                {t("enums:interaction_completed.completed")}
              </FilterButton>
              <FilterButton
                onClick={() => setQueryParams({ pending: !pending, page: 1 })}
                className={"mr-4"}
                selected={pending}
              >
                {t("enums:interaction_completed.pending")}
              </FilterButton>
              <FilterButton
                onClick={() => setQueryParams({ canceled: !canceled, page: 1 })}
                className={"mr-4"}
                selected={canceled}
              >
                {t("enums:interaction_completed.canceled")}
              </FilterButton>
            </div>
            <div>
              {!disableCreate && (
                <Button
                  onClick={() => {
                    editModalActions.open(initial)
                  }}
                  className="ml-8"
                >
                  {t("action:new_customer_interaction")}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5 mb-8 d-flex flex-row justify-content-start align-items-center flex-1">
          <b className="mr-8">{t("customer_interactions.filters")}</b>
          <div
            className="d-flex flex-row justify-content-start align-items-center flex-1 flex-wrap"
            style={{ rowGap: 16, columnGap: 32 }}
          >
            <div style={{ whiteSpace: "nowrap" }}>
              <span className="mr-3">{t("customer_interactions.period_filter_period")}</span>
              <br />
              <span className="mr-3">{t("customer_interactions.period_filter_from")}</span>
              <WpInput
                medium
                type="date"
                value={fromDate}
                onChange={(e) => setDebQueryParams({ fromDate: e.target.value, page: 1 })}
              />
              <span className="mx-3">{t("customer_interactions.period_filter_to")}</span>
              <WpInput
                medium
                type="date"
                value={toDate}
                onChange={(e) => setDebQueryParams({ toDate: e.target.value, page: 1 })}
              />
            </div>

            {!disableEstimateFilter && (
              <div>
                <span className="mr-3">{t("customer_interactions.filter_estimate")}</span>
                <br />
                <EstimateSelector
                  clearable
                  itemWidth={200}
                  estimateId={estimate}
                  onEstimateChange={(est) => {
                    setQueryParams({ estimate: est?.id ?? undefined, page: 1 })
                  }}
                  style={{ width: 180 }}
                  oneLine
                  placeholder={t("customer_interactions.filter_estimate_placeholder")}
                  defaultFilters={relatedFilterDefault}
                />
              </div>
            )}

            {!disableProjectFilter && (
              <div>
                <span className="mr-3">{t("customer_interactions.filter_project")}</span>
                <br />
                <ProjectSelector
                  clearable
                  itemWidth={200}
                  projectId={project}
                  onProjectChange={(arg) => {
                    setQueryParams({ project: arg?.id ?? undefined, page: 1 })
                  }}
                  style={{ width: 180 }}
                  placeholder={t("customer_interactions.filter_project_placeholder")}
                  defaultFilters={relatedFilterDefault}
                />
              </div>
            )}

            {!disableRequestFilter && (
              <div>
                <span className="mr-3">{t("customer_interactions.filter_request")}</span>
                <br />
                <IncomingRequestSelector
                  clearable
                  itemWidth={200}
                  requestId={request}
                  onRequestChange={(arg) => {
                    setQueryParams({ request: arg?.id ?? undefined, page: 1 })
                  }}
                  style={{ width: 180 }}
                  placeholder={t("customer_interactions.filter_request_placeholder")}
                  autoFilters={relatedFilterDefault}
                />
              </div>
            )}

            {!disableAssigneeFilter && (
              <div>
                <span className="mr-3">{t("customer_interactions.filter_assignee")}</span>
                <br />
                <OrgUserSelector
                  clearable
                  itemWidth={350}
                  userId={assignee}
                  onMemberChange={(arg) => {
                    setQueryParams({ assignee: arg?.id ?? undefined, page: 1 })
                  }}
                  style={{ width: 200 }}
                  placeholder={t("customer_interactions.filter_assignee_placeholder")}
                  useShortNames
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {count !== 0 && (
        <>
          <LongBorder topBar="toolbar" />

          <Table
            columns={columns}
            data={interactions}
            className="pb-8"
            use24
            highlightRows={[]}
            ordering={ordering}
            onOrderChange={(val) => {
              setQueryParams({ ordering: val, page: 1 })
            }}
          />

          <Pagination
            current={debPage}
            pageCount={pageCount}
            goToPage={(page) => {
              setQueryParams({ page })
              window.scrollTo(0, 10)
            }}
            hasNext={hasNext}
            hasPrev={hasPrev}
          />
        </>
      )}
      {count === 0 && (
        <div className="d-flex flex-column text-primary align-items-start">
          <MeasureContext as="img" src={EmptyFilterImage} alt="" bottom={0} style={{ maxHeight: 417, height: "100%" }}>
            {(ref) => <div ref={ref}></div>}
          </MeasureContext>
        </div>
      )}
      {editModalState.value && (
        <CreateCustomerInteractionModal
          isOpen={editModalState.isOpen}
          onClosed={editModalActions.onClose}
          toggle={editModalActions.close}
          onSave={(result) => {
            editModalActions.close()
            return (editModalState.value.id ? update.curry(editModalState.value.id) : create)
              .onSuccess(() => {
                reload(filters)
              })
              .asPromise({ ...initial, ...result })
          }}
          interaction={typeof editModalState.value === "object" ? editModalState.value : null}
          relatedFilterDefault={relatedFilterDefault}
        />
      )}
      {deleteModalState.value && (
        <ConfirmDeleteModal
          toggle={deleteModalActions.toggle}
          isOpen={deleteModalState.isOpen}
          onConfirm={() => {
            remove
              .onSuccess(() => {
                reload(filters)
                deleteModalActions.close()
              })
              .run(deleteModalState.value.id)
          }}
          onClosed={deleteModalActions.onClose}
          item={truncate(deleteModalState.value.description, {
            length: 11,
            omission: "…",
          })}
        />
      )}
    </>
  )
}

import { Field, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { convertErrorToForm } from "../../utils"
import Button from "../Button"
import FieldInput from "../fields/FieldInput"
import WpModal from "../WpModal/WpModal"
import * as yup from "yup"
import { useMemo } from "react"


export default function PriceListCreateModal({
  isOpen,
  toggle,
  onClosed,
  onSave,
}) {
  const { t } = useTranslation(["action", "field"])

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup
          .string()
          .required(
            t("field:errors.mandatory", { field: t("field:price_list.title") })
          )
          .max(512, t("field:errors.max", { max_value: 512 })),
        currency: yup.string().required(
          t("field:errors.mandatory", {
            field: t("field:price_list.currency"),
          })
        ),
      }),
    [t]
  )

  return (
    <WpModal
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
      title={t("action:create_price_list")}
    >
      <div>
        <div className="d-flex flex-row align-items-start my-4">
          <div className="d-flex flex-column ml-2 flex-1">
            <Formik
              initialValues={{
                title: "",
                currency: "EUR",
              }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                return onSave(values).catch((error) => {
                  actions.setErrors(convertErrorToForm(error))
                })
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <>
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Field
                      name="title"
                      component={FieldInput}
                      className={"mb-5"}
                      placeholder={t("field:price_list.placeholder.title")}
                      label={t("field:price_list.title")}
                    />
                    <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                      <Button size="sm" color="none" onClick={toggle}>
                        {t("action:cancel")}
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        type="submit"
                        className="ml-5"
                        disabled={!formik.isValid}
                      >
                        {t("action:add")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </WpModal>
  )
}

import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import Icon from "../../components/Icon"
import Separator from "../../components/Separator"
import ScenarioSymbol from "../../components/ScenarioSymbol"
import Button from "../../components/Button"

export default function ScenarioSelector({
  scenariosList,
  selectedScenarios,
  onSave,
  isOpen,
  toggle,
  reducer,
  title = "",
  enableAll = false,
  enableBase = false,
  children,
}) {
  const [_tempState, setTempState] = useState(undefined)
  const tempState = _tempState === undefined ? selectedScenarios : _tempState

  const { t } = useTranslation(["translation", "action"])

  const handleToggle = useCallback(() => {
    if (isOpen) {
      onSave(tempState)
    }
    toggle()
    setTempState(undefined)
  }, [isOpen, onSave, tempState, toggle])

  return (
    <Dropdown
      className={"mr-1 d-flex flex-row align-items-center"}
      isOpen={isOpen}
      toggle={handleToggle}
    >
      <DropdownToggle
        caret={false}
        tag={"span"}
        className="d-inline-flex flex-row align-items-center pointer"
      >
        {typeof children !== "function" && (
          <>
            <Icon
              name="scenario"
              title={t("action:select_scenario")}
              style={{
                width: 24,
                height: 24,
              }}
              className="mr-2 action-icon-container-primary-hover"
            />
            {children}
          </>
        )}
        {typeof children === "function" &&
          children(
            <Icon
              name="scenario"
              title={t("action:select_scenario")}
              style={{
                width: 24,
                height: 24,
              }}
              className="mr-2 action-icon-container-primary-hover"
            />
          )}
      </DropdownToggle>
      <DropdownMenu
        right
        modifiers={{ offset: { offset: "0, 12" } }}
        className="border-primary"
      >
        {title !== "" && (
          <>
            <DropdownItem className={"px-0"} header>
              <div className="d-flex flex-row align-items-center mx-4">
                <h3 className="text-dark">{title}</h3>
              </div>
            </DropdownItem>
            <Separator className="my-0 mx-4 border-separator" />
          </>
        )}

        {enableAll && (
          <>
            <DropdownItem
              className={"px-0 dropdown-item-primary-active"}
              onClick={() => setTempState(reducer(tempState, null))}
              toggle={false}
              active={tempState === null}
            >
              <div className="d-flex flex-row align-items-center mx-4">
                {/* <Icon
                  name="remove"
                  className={classNames("pointer mr-4 text-dark")}
                /> */}
                <span>{t("scenarios.filter_all")}</span>
              </div>
            </DropdownItem>
            <Separator className="my-0 mx-4 border-separator" />
          </>
        )}
        {enableBase && (
          <>
            <DropdownItem
              className={"px-0 dropdown-item-primary-active"}
              onClick={() => setTempState(reducer(tempState, []))}
              toggle={false}
              active={tempState?.length === 0}
            >
              <div className="d-flex flex-row align-items-center mx-4">
                {/* <Icon
                  name="remove"
                  className={classNames("pointer mr-4 text-dark")}
                /> */}
                <span>{t("scenarios.filter_base_only")}</span>
              </div>
            </DropdownItem>
            <Separator className="my-0 mx-4 border-separator" />
          </>
        )}

        {scenariosList?.length > 0 && (
          <>
            {scenariosList.map((scenario) => {
              return (
                <React.Fragment key={scenario.id}>
                  <DropdownItem
                    className={"px-0 dropdown-item-primary-active"}
                    key={scenario.id}
                    onClick={() => {
                      setTempState(reducer(tempState, scenario))
                    }}
                    toggle={false}
                    active={tempState?.includes(scenario.id)}
                  >
                    <div className="d-flex flex-row align-items-center mx-4">
                      <ScenarioSymbol scenario={scenario} />
                      <span className="ml-4">{scenario.name}</span>
                    </div>
                  </DropdownItem>
                  <Separator className="my-0 mx-4 border-separator" />
                </React.Fragment>
              )
            })}
          </>
        )}

        <DropdownItem className={"px-0"} header>
          <div className="d-flex flex-row align-items-center justify-content-center mx-4">
            <Button size="sm" onClick={handleToggle}>
              {t("action:save")}
            </Button>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

import classNames from "classnames"
import lodash, { uniqueId } from "lodash"
import useConstant from "magik-react-hooks/useConstant"
import React, { Fragment, useContext, useEffect, useMemo, useState } from "react"
import { createPortal } from "react-dom"
import { useTranslation } from "react-i18next"
import { BsGear, BsPeople } from "react-icons/bs"
import { VscDashboard } from "react-icons/vsc"
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useAuthActions, useAuthUser } from "use-eazy-auth"
import { CAP, useCapabilities } from "../../hooks/capabilities"
import { useLinkToIndicators } from "../../hooks/links"
import { useProfile } from "../../hooks/profile"
import useCurrentOrganization from "../../hooks/useCurrentOrganization"
import { prefixLocationWithOrg } from "../../utils"
import Icon from "../Icon"
import ImagePlaceholder from "../ImagePlaceholder"
import ImagePreview from "../ImagePreview"
import Separator from "../Separator/Separator"
import WpNavLink from "../WpNavLink"
import S from "./Layout.module.scss"
import NotificationCenter from "./NotificationsCenter"
import VerifyEmailBanner from "./VerifyEmailBanner"

const COLLAPSE_THRESHOLD = 1

const LayoutContext = React.createContext({})
const CollapsePanelContext = React.createContext({ collapseId: null })

const LayoutRightCollapse = ({ onClick, children }) => {
  const { collapseId } = useContext(CollapsePanelContext)
  if (collapseId === null) {
    throw new Error("You can only use Layout.Collapse in the context of a collapsible panel")
  }
  const Component = (
    <div className={S["right-pane-collapse"]} onClick={onClick}>
      <Icon name="arrow-right" className="text-dark pointer" width={24} height={24} />
    </div>
  )
  return createPortal(Component, document.getElementById(collapseId))
}

const LayoutRight = ({ children, className, onClose }) => {
  const collapsibleId = useConstant(() => uniqueId("__layout_right_wp_"))
  const collapsibleCtx = useMemo(
    () => ({
      collapseId: collapsibleId,
    }),
    [collapsibleId]
  )
  return (
    <CollapsePanelContext.Provider value={collapsibleCtx}>
      <div className={`${S["right-pane-wrapper"]} ${className}`}>
        <div className={`${S["right-pane-content"]} position-relative`}>{children}</div>
        <div id={collapsibleId}></div>
      </div>
    </CollapsePanelContext.Provider>
  )
}

const OverviewPanel = ({ children, className, width, closedWidth, isVisible, onClose }) => {
  const collapsibleId = useConstant(() => uniqueId("__layout_right_wp_"))
  const collapsibleCtx = useMemo(
    () => ({
      collapseId: collapsibleId,
    }),
    [collapsibleId]
  )
  return (
    <CollapsePanelContext.Provider value={collapsibleCtx}>
      <div
        className={`${S["overview-panel-wrapper"]} ${className}`}
        style={{
          display: isVisible ? "block" : "none",
        }}
      >
        <div className={`${S["overview-panel-content"]} position-relative`} style={{ width }}>
          {children}
        </div>
        <div id={collapsibleId}>
          {onClose && (
            <div className={S["right-pane-collapse"]} onClick={onClose}>
              <Icon name="arrow-right" className="text-dark pointer" width={24} height={24} />
            </div>
          )}
        </div>
      </div>
    </CollapsePanelContext.Provider>
  )
}

const MainNavigationItem = ({
  iconName,
  title,
  className,
  exact,
  to,
  icon = undefined,
  activeUrlPrefixes = [],
  exactUrls = [],
}) => {
  const location = useLocation()
  const org = useCurrentOrganization()
  return (
    <WpNavLink
      className={classNames(S["main-navigation-item"], className)}
      exact={exact}
      activeClassName={S["active"]}
      to={to}
      isActive={() =>
        activeUrlPrefixes.some(
          (prefix) =>
            location.pathname === prefix || location.pathname.startsWith(prefix + "/") || location.pathname === prefixLocationWithOrg(prefix, org?.id) || location.pathname.startsWith(prefixLocationWithOrg(prefix, org?.id) + "/")
        ) ||
        exactUrls.some((prefix) => location.pathname === prefix || location.pathname === prefixLocationWithOrg(prefix, org?.id))
      }
    >
      {icon && <span className={classNames({ "mr-3": title })}>{icon}</span>}
      {iconName && <Icon name={iconName} className={classNames({ "mr-3": title })} />}
      {title && <span style={{ whiteSpace: "nowrap" }}>{title}</span>}
    </WpNavLink>
  )
}

const ProfileItem = ({ src, className, to }) => {
  const match = useRouteMatch(to)

  const { user } = useAuthUser()

  const { logout } = useAuthActions()

  const { t } = useTranslation(["translation", "action"])

  return (
    <UncontrolledDropdown>
      <DropdownToggle caret={false} tag={"div"} style={{ width: 60, height: 60 }}>
        <ImagePreview
          src={src}
          active={match}
          className={classNames(className)}
          placeholder={ImagePlaceholder("avatar", user.id)}
        />
      </DropdownToggle>
      <DropdownMenu right positionFixed className="border-primary">
        <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
          <Link className="d-flex flex-row align-items-center mx-4 no-link" to={to}>
            <Icon name="user" className="pointer mr-4" style={{ height: 24, width: 24 }} />
            <span>{t("action:profile")}</span>
          </Link>
        </DropdownItem>
        <Separator className="my-0 mx-4 border-primary" />
        <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
          <div className="d-flex flex-row align-items-center mx-4" onClick={() => logout()}>
            <Icon name="logout" className="pointer mr-4" style={{ height: 24, width: 24 }} />
            <span>{t("action:logout")}</span>
          </div>
        </DropdownItem>
        <Separator className="my-0 mx-4 border-primary" />
        <DropdownItem className={classNames("text-capitalize px-0")}>
          <div className="d-flex flex-row align-items-center mx-4 text-muted">
            <span className="d-flex justify-content-center align-items-center mr-4" style={{ width: 24, height: 24 }}>
              WP
            </span>
            <span>Versione {process.env.REACT_APP_VERSION}</span>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const OrganizationItem = ({ currentOrg, organizations, organizationsIds, className }) => {
  const { t } = useTranslation(["translation", "action"])
  const [{ profile }] = useProfile()
  const matchNew = useRouteMatch("/org/new")
  const matchOrgSettings = useRouteMatch(`/org/${currentOrg?.id ?? 0}/settings`)

  if (profile.profile_type === "accounting_user") {
    return (
      <div className="d-flex flex-row align-items-center mr-5">
        <ImagePreview
          src={currentOrg.logo}
          shape="square"
          disableHover
          className="mr-3"
          placeholder={ImagePlaceholder("tile", currentOrg?.id)}
        />
        <span>{currentOrg.name}</span>
      </div>
    )
  }

  return (
    <div
      className={classNames(
        { [S["active"]]: matchOrgSettings !== null },
        "d-flex align-self-stretch align-items-center",
        className
      )}
    >
      <UncontrolledDropdown>
        <DropdownToggle caret={false} tag={"div"}>
          <div className="d-flex flex-row align-items-center pointer">
            {matchNew === null && (
              <>
                <ImagePreview
                  src={currentOrg.logo}
                  shape="square"
                  disableHover
                  className="mr-3"
                  placeholder={ImagePlaceholder("tile", currentOrg?.id)}
                />
                <span>{currentOrg.name}</span>
                <Icon name="arrow-down" className="ml-3" style={{ width: 16, height: 16 }} />
              </>
            )}
            {matchNew !== null && (
              <>
                <span>
                  <i>{t("organization.new_organization")}</i>
                </span>
                <Icon name="arrow-down" className="ml-3" style={{ width: 16, height: 16 }} />
              </>
            )}
          </div>
        </DropdownToggle>
        <DropdownMenu right positionFixed className="border-primary">
          <DropdownItem className="font-weight-semibold h3 text-dark" header>
            {t("organization.select_message")}
          </DropdownItem>
          <Separator className="my-0 mx-4 border-primary" />
          {organizationsIds.map((orgId, i) => {
            const org = organizations[orgId]
            const isCurrent = currentOrg?.id === org.id && !matchNew
            return (
              <Fragment key={org.id}>
                <DropdownItem className={classNames("px-0", "dropdown-item-primary-active")}>
                  <Link className="d-flex flex-row align-items-center mx-4 no-link" to={"/org/" + org.id}>
                    <div className="d-flex flex-row align-items-center mx-4">
                      <div
                        className={classNames(S["org-indicator"], {
                          [S["is-current-org"]]: isCurrent,
                        })}
                      ></div>
                      <ImagePreview
                        src={org.logo}
                        shape="square"
                        size="sm"
                        disableHover
                        className="mx-3 my-2"
                        placeholder={ImagePlaceholder("tile", org.id)}
                      />
                      <span>{org.name}</span>
                    </div>
                  </Link>
                </DropdownItem>
                <Separator className="my-0 mx-4 border-primary" />
              </Fragment>
            )
          })}
          <DropdownItem className={classNames("px-0", "dropdown-item-primary-active")}>
            <Link className="d-flex flex-row align-items-center mx-4 no-link" to={"/org/new"}>
              <div className="d-flex flex-row align-items-center mx-4 my-2">
                <Icon name="resource-add" className="mr-3" />
                <span>{t("organization.create_new")}</span>
              </div>
            </Link>
          </DropdownItem>
          <Separator className="my-0 mx-4 border-primary" />
          <DropdownItem className={classNames("px-0", "dropdown-item-primary-active")}>
            <Link className="d-flex flex-row align-items-center mx-4 no-link" to={`/org/${currentOrg?.id}/settings`}>
              <div className="d-flex flex-row align-items-center mx-4 my-2">
                <Icon name="organization" className="mr-3" />
                <span>{t("organization.handle_org")}</span>
              </div>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}

/* TOOLBAR */
const Toolbar = ({ children, className, title }) => {
  const ctx = useContext(LayoutContext)
  return (
    <div className={`${S["toolbar"]} ${className}`} style={{ top: 60 + ctx.toolbarTop }}>
      {/* <h1
        className={classNames(
          S["toolbar-title"],
          "text-primary font-weight-semibold"
        )}
      >
        {title}
      </h1> */}
      <div className={classNames(S["toolbar-content"], "mx-page")}>{children}</div>
    </div>
  )
}

const ToolbarIcon = ({ svgIcon, text, textClassName, title = "", className, link, ...props }) => {
  const history = useHistory()
  const organization = useCurrentOrganization()

  // const { isTopCollapsed } = useContext(LayoutContext)
  const myId = useMemo(() => "toolbar-icon-" + lodash.uniqueId(), [])

  return (
    <div
      className={classNames(S["toolbar-icon"], "pointer", className)}
      onClick={() => {
        if (link) {
          history.push(prefixLocationWithOrg(link, organization?.id))
        }
      }}
      {...props}
    >
      {React.cloneElement(svgIcon, { id: myId })}
      <span className={classNames(S["toolbar-icon-text"], textClassName)}>{text}</span>
      {/* {isTopCollapsed && title && title !== "" && (
        <UncontrolledTooltip placement={"bottom-start"} target={myId}>
          {title}
        </UncontrolledTooltip>
      )} */}
    </div>
  )
}

const ToolbarDivider = () => {
  return <div className={classNames(S["toolbar-divider"])}></div>
}

/*TABBAR*/
const TabBar = ({ children, className }) => {
  const ctx = useContext(LayoutContext)
  return (
    <div className={`${S["tabbar"]} ${className}`} style={{ top: ctx.tabBarTop }}>
      <div className={classNames(S["tabbar-content"], "mx-tab")}>{children}</div>
    </div>
  )
}

const TabBarTab = ({ svgIcon, text, textClassName, className, to, activeUrlPrefixes = [], exact, ...props }) => {
  const org = useCurrentOrganization()

  return (
    <WpNavLink
      className={classNames(S["tabbar-tab"], "pointer", className)}
      activeClassName={S["active"]}
      to={to}
      isActive={(match, location) => {
        let isActive = false
        const fullTo = prefixLocationWithOrg(to, org?.id)
        if (exact) {
          isActive = location.pathname === to || location.pathname === fullTo
        } else {
          isActive = location.pathname.startsWith(to) || location.pathname.startsWith(fullTo)
        }
        isActive =
          isActive ||
          [...activeUrlPrefixes].some(
            (prefix) =>
              location.pathname.startsWith(prefix) || location.pathname.startsWith(prefixLocationWithOrg(prefix, org?.id))
          )
        return isActive
      }}
      exact={exact}
      {...props}
    >
      {svgIcon}
      <span className={classNames(S["tabbar-tab-text"], textClassName)}>{text}</span>
    </WpNavLink>
  )
}

const TabBarDivider = () => {
  return <div className={classNames(S["tabbar-divider"])}></div>
}

/* FIRST LEVEL SECTION */
const FirstLevelNavi = ({ children, className }) => {
  return (
    <div className={`${S["tabbar-landmarks"]} ${className}`}>
      <div className={classNames(S["tabbar-landmarks-content"], "mx-tab")}>{children}</div>
    </div>
  )
}

const FirstLevelNaviTab = ({ svgIcon, text, textClassName, className, to, ...props }) => {
  return (
    <WpNavLink
      className={classNames(S["tabbar-landmarks-tab"], "pointer", className)}
      activeClassName={S["active"]}
      to={to}
      {...props}
    >
      {svgIcon}
      <span className={classNames(S["tabbar-landmarks-tab-text"], textClassName)}>{text}</span>
    </WpNavLink>
  )
}

/* BOTTOMBAR */
const BottomBar = ({ children, className }) => {
  return <div className={`${S["bottombar"]} ${className}`}>{children}</div>
}

/*LEFTBAR*/
const LeftBar = ({ children, className }) => {
  return <div className={`${S["leftbar"]} ${className}`}>{children}</div>
}

const Layout = ({
  children,
  keepTopCollapsed = false,
  showDrawer,
  openOverview,
  toggleDrawer,
  className = "",
  displayRawContent = false,
}) => {
  const [{ profile }] = useProfile()
  const [isTopCollapsed, setTopCollapsed] = useState(false)
  const childrenArray = React.Children.toArray(children)
  const [, { hasCapability }] = useCapabilities()

  const RightPaneContent = childrenArray.find((child) => {
    return child.type === LayoutRight
  })
  const ToolbarElement = childrenArray.find((child) => {
    return child.type === Toolbar
  })
  const TabBarElement = childrenArray.find((child) => {
    return child.type === TabBar
  })
  const FirstLevelNaviElement = childrenArray.find((child) => {
    return child.type === FirstLevelNavi
  })
  const BottomBarElement = childrenArray.find((child) => {
    return child.type === BottomBar
  })
  const LeftBarElement = childrenArray.find((child) => {
    return child.type === LeftBar
  })
  const OverviewPanelElements = childrenArray.filter((child) => {
    return child.type === OverviewPanel
  })
  const Content = childrenArray.filter((child) => {
    return (
      child.type !== LayoutRight &&
      child.type !== Toolbar &&
      child.type !== FirstLevelNavi &&
      child.type !== TabBar &&
      child.type !== BottomBar &&
      child.type !== LeftBar &&
      child.type !== OverviewPanel
    )
  })

  // if (ToolbarElement && TabBarElement) {
  //   throw new Error("Toolbar and TabBar cannot coexist")
  // }

  const naviHeight = FirstLevelNaviElement ? 44 : 0

  const hasToolbarElement = !!ToolbarElement

  const ctx = useMemo(
    () => ({
      toggleDrawer,
      isTopCollapsed: hasToolbarElement ? isTopCollapsed : true,
      toolbarTop: naviHeight,
      tabBarTop: (naviHeight ? naviHeight : 0) + (hasToolbarElement ? 140 : 60),
    }),
    [hasToolbarElement, isTopCollapsed, naviHeight, toggleDrawer]
  )

  useEffect(() => {
    const handler = () => {
      if (document.body.scrollTop > COLLAPSE_THRESHOLD || document.documentElement.scrollTop > COLLAPSE_THRESHOLD) {
        setTopCollapsed(true)
      } else {
        setTopCollapsed(false)
      }
    }
    window.addEventListener("scroll", handler)
    return () => {
      window.removeEventListener("scroll", handler)
    }
  }, [])

  const { t } = useTranslation("translation")

  const contentStyle = useMemo(() => {
    const out = {}
    if (LeftBarElement) {
      out.paddingLeft = 48
    }
    if (OverviewPanelElements.length > 0) {
      const paddingRight = Math.max(
        ...OverviewPanelElements.map((panel) => {
          if (panel.props.preventContentShrink || !panel.props.isOpen) {
            return panel.props.closedWidth
          } else {
            return panel.props.width
          }
        })
      )
      out.paddingRight = paddingRight
    }
    return out
  }, [LeftBarElement, OverviewPanelElements])

  const indicatorsLink = useLinkToIndicators()

  const currentOrg = useCurrentOrganization()

  return (
    <div
      className={classNames(className, S["main-layout"], {
        //[S["top-expanded"]]: !isTopCollapsed && ToolbarElement,
        [S["with-toolbar"]]: ToolbarElement,
        [S["with-tabbar"]]: TabBarElement,
        [S["with-landmarks"]]: FirstLevelNaviElement,
        [S["with-bottombar"]]: BottomBarElement,
        [S["top-force-collapse"]]: keepTopCollapsed || !ToolbarElement,
      })}
    >
      <LayoutContext.Provider value={ctx}>
        {!displayRawContent && (
          <div className={S["layout-content"]} style={contentStyle}>
            {Content}
          </div>
        )}
        {displayRawContent && Content}
        {ToolbarElement}
        {TabBarElement}
        {FirstLevelNaviElement}
        {LeftBarElement}
        {BottomBarElement}

        {OverviewPanelElements.map((panel, i) => (
          <div
            key={i}
            className={classNames(S["overview-panel"], {
              [S["is-open"]]: panel.props.isOpen,
            })}
            style={{
              right: panel.props.isOpen ? 0 : panel.props.closedWidth - panel.props.width,
            }}
          >
            {panel}
          </div>
        ))}

        <div className={S["main-bar"]}>
          <div className={S["main-navigation"]}>
            <MainNavigationItem className={S["home"]} iconName="home" to="/" exact exactUrls={["/"]} />
            {(hasCapability(CAP.ESTIMATES) || hasCapability(CAP.TEMPLATES) || hasCapability(CAP.RESOURCES)) && (
              <MainNavigationItem
                iconName="estimate-list"
                title={t("navbar.estimate")}
                to={"/estimates"}
                activeUrlPrefixes={["/estimates", "/resources", "/templates", "/pricelists"]}
              />
            )}
            {(hasCapability(CAP.PROJECTS) || hasCapability(CAP.CALENDAR)) && (
              <MainNavigationItem
                iconName="reporting"
                title={t("navbar.reporting")}
                to={"/projects"}
                activeUrlPrefixes={["/projects", "/deadlines", "/tracker-calendar", "/compliance-issue-report", "/hr"]}
              />
            )}
            {(hasCapability(CAP.OVERVIEW) || hasCapability(CAP.BUSINESS) || hasCapability(CAP.CUSTOMER)) && (
              <MainNavigationItem
                icon={<BsPeople size={20} />}
                title={t("navbar.crm")}
                to={"/customers"}
                activeUrlPrefixes={["/customers", "/businessinteractions", "/requests"]}
              />
            )}
            {hasCapability(CAP.INDICATORS) && (
              <MainNavigationItem
                icon={<VscDashboard size={20} />}
                title={t("navbar.indicators")}
                to={indicatorsLink}
                activeUrlPrefixes={["/indicators", "/business", "/overview"]}
              />
            )}
            {hasCapability(CAP.TAGS) && (
              <MainNavigationItem
                icon={<BsGear size={20} />}
                title={t("navbar.settings")}
                to={`/tags`}
                activeUrlPrefixes={["/tags"]}
              />
            )}
          </div>
          <div className={S["main-bar-alert"]}>{!profile.email_verified && <VerifyEmailBanner />}</div>
          <div className={S["main-bar-tools"]}>
            <NotificationCenter className="mr-8" />
            <OrganizationItem
              currentOrg={currentOrg}
              organizations={profile.organizationsById}
              organizationsIds={profile.organizationsIds}
              className="pointer mr-5"
            />
            <ProfileItem src={profile.initials} to="/profile/" className="pointer" />
          </div>
        </div>
        <div
          className={classNames(S["right-pane"], {
            [S["right-pane-collapsed"]]: !showDrawer,
          })}
        >
          {RightPaneContent}
        </div>
      </LayoutContext.Provider>
    </div>
  )
}

function ToolbarAlignBetween() {
  return <div className="flex-1" />
}

Toolbar.Icon = ToolbarIcon
Toolbar.Divider = ToolbarDivider
Toolbar.SpaceBetween = ToolbarAlignBetween
TabBar.Divider = TabBarDivider
TabBar.TabBarTab = TabBarTab
FirstLevelNavi.Tab = FirstLevelNaviTab
FirstLevelNavi.Divider = TabBarDivider
Layout.Drawer = LayoutRight
Layout.DrawerCollapse = LayoutRightCollapse
Layout.OverviewPanel = OverviewPanel
Layout.Toolbar = Toolbar
Layout.Context = LayoutContext
Layout.TabBar = TabBar
Layout.BottomBar = BottomBar
Layout.LeftBar = LeftBar
Layout.FirstLevelNavi = FirstLevelNavi

export default Layout

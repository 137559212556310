import React, { useEffect, useState } from "react"
import { matchPath, useLocation } from "react-router"
import Login from "./Login"
import SignUp from "./SignUp"
import qs from "query-string"
import { useJoinOrganization } from "../../hooks/organization"
import Loader from "../../components/Loader/Loader"

export default function LoginScreen({ openOnSignup = false, ...props }) {
  const [loginOrSignup, setLoginOrSignup] = useState(
    openOnSignup ? "signup" : "login"
  )
  const [emailInitial, setEmailInitial] = useState("")

  const location = useLocation()
  // TODO: For now we rely on location state referrer from eazy-auth
  // switch to query string when eazy-auth provide a more advanced way to
  // handle custom anon redirect.........
  const match = matchPath(location.state?.referrer?.pathname, {
    path: "/org/join/:token",
    exact: true,
    strict: false,
  })
  const token = match?.params?.token ?? qs.parse(location.search)?.join_token

  const [{ data, pending }, { run: unpackToken }] = useJoinOrganization(null)

  useEffect(() => {
    if (token) {
      unpackToken
        .onSuccess((data) => {
          setEmailInitial(data.email)
        })
        .run(token)
    }
  }, [token, unpackToken])

  if (pending) {
    return (
      <div className="d-flex flex-column align-items-center">
        <Loader />
      </div>
    )
  }

  if (loginOrSignup === "login") {
    return <Login {...props} changeMode={() => setLoginOrSignup("signup")} token={token} emailInitial={emailInitial} tokenData={data} />
  } else {
    return <SignUp {...props} changeMode={() => setLoginOrSignup("login")} token={token} emailInitial={emailInitial} tokenData={data} />
  }
}

import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { rj, useRunRj } from "react-rocketjump"
import api from "../api"

const PlansState = rj()
  .computed({ plans: "getData" })
  .effect({
    name: "Plans",
    effectCaller: rj.configured(),
    effect: (wpAuth) => () => api.auth(wpAuth).get("/api/plans"),
  })

export default function usePlans() {
  const { t } = useTranslation(["translation", "enums"])
  const [{ plans }] = useRunRj(PlansState)

  const translatedPlans = useMemo(() => {
    if (plans === null) {
      return []
    }
    return plans.map((plan) => {
      const translatedFeatures = [
        plan.grants.resources === -1
          ? t("plans.unlimited_resources")
          : t("plans.limited_resources", { limit: plan.grants.resources }),
        plan.grants.estimates === -1
          ? t("plans.unlimited_estimates")
          : t("plans.limited_estimates", { limit: plan.grants.estimates }),
        plan.grants.templates === -1
          ? t("plans.unlimited_templates")
          : t("plans.limited_templates", { limit: plan.grants.templates }),
        t("plans.limited_members", { limit: plan.grants.collaborators }),
      ]
      return {
        ...plan,
        translatedFeatures,
      }
    })
  }, [t, plans])

  return translatedPlans
}

import { get } from "lodash"
import { useEffect, useMemo } from "react"
import { rj, useRunRj } from "react-rocketjump"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import ReconnectingWebSocket from "reconnectingwebsocket"
import { useAuthUser } from "use-eazy-auth"
import api from "../api"

const getOrSelect = (context, pathOrFunction) => {
  if (typeof pathOrFunction === "function") {
    return pathOrFunction(context)
  }
  return get(context, pathOrFunction)
}

const NotificationsState = rj(
  rjList({
    pageSize: 100,
    pagination: nextPreviousPaginationAdapter,
    customPaginationReducer: (prevState, { payload: { data } }) => {
      return {
        ...prevState,
        count: getOrSelect(data, nextPreviousPaginationAdapter.count),
        current: getOrSelect(data, nextPreviousPaginationAdapter.current),
        next: getOrSelect(data, nextPreviousPaginationAdapter.next),
        previous: getOrSelect(data, nextPreviousPaginationAdapter.previous),
        unread: data.unread,
      }
    },
  }),
  {
    name: "Notifications",
    effectCaller: rj.configured(),
    effect: (wpAuth) => (filters) => {
      return api.auth(wpAuth).get("/api/notification", filters)
    },
    mutations: {
      markAsRead: rj.mutation.single({
        effect: (wpAuth) => (id) => {
          return api
            .auth(wpAuth)
            .post(`/api/notification/${id}/mark_as_read/`, {})
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.map((item) =>
                item.id === result.id ? result : item
              ),
              pagination: {
                ...state.data.pagination,
                unread: state.data.pagination.unread - 1,
              },
            },
          }
        },
      }),
    },
    selectors: ({ getPagination }) => ({
      getUnreadCount: (state) => {
        if (state.root) {
          return get(state, "root.data.pagination.unread", 0)
        }
        return get(state, "data.pagination.unread", 0)
      },
    }),
    computed: {
      notifications: "getList",
      count: "getCount",
      unreadCount: "getUnreadCount",
      loading: "isPending",
      error: "getError",
      pageCount: "getNumPages",
      hasNext: "hasNext",
      hasPrev: "hasPrev",
    },
  }
)

const UnreadNotificationsCountState = rj({
  name: "UnreadNotificationsCount",
  effectCaller: rj.configured(),
  effect: (wpAuth) => () => {
    return api.auth(wpAuth).get("/api/notification/count_unread/")
  },
})

export default function useNotifications(filters, options) {
  const [state, actions] = useRunRj(NotificationsState, [filters])

  const memoActions = useMemo(
    () => ({
      ...actions,
      markAsRead: actions.markAsRead
        .onSuccess(() => {
          window.dispatchEvent(new Event("wp-notification-read"))
        })
        .curry(),
    }),
    [actions]
  )

  return [state, memoActions]
}

export function useUnreadNotificationCount() {
  const [state, actions] = useRunRj(UnreadNotificationsCountState)
  const { token } = useAuthUser()

  useEffect(() => {
    const proto = window.location.protocol.startsWith("https") ? "wss" : "ws"
    const hostname = process.env.NODE_ENV === "development" ? "localhost:8000" : window.location.host
    const ws = new ReconnectingWebSocket(
      `${proto}://${hostname}/ws/core/notification/count/?token=${token}`
    )
    ws.addEventListener("message", (e) => {
      const { type, ...data } = JSON.parse(e.data)
      if (type === "push_notification_count") {
        actions.updateData({ count: data.count })
      }
    })
    return () => {
      ws.close()
    }
  }, [actions, token])

  return state?.data?.count ?? 0
}

import { rj, useRunRj } from "react-rocketjump"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import api from "../api"

export const PaymentOrdersState = rj(
  rjList({
    pageSize: 100,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    name: "PaymentsOrder",
    effectCaller: rj.configured(),
    effect: (wpAuth) => (filters = {}) =>
      api.auth(wpAuth).get(`/api/payments/order/`, filters),
    computed: {
      payments: "getList",
    },
  }
)

export function usePaymentOrders(filters) {
  return useRunRj(PaymentOrdersState, [filters])
}

const pendingPaymentsFilter = { paid_at__isnull: true }
export function usePendingPaymentOrders() {
  return useRunRj(PaymentOrdersState, [pendingPaymentsFilter])
}

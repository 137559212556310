import { keyBy } from "lodash"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Table } from "reactstrap"
import Button from "../../components/Button"
import Layout from "../../components/Layout"
import { TrackerLandmarks } from "../../components/TrackerLandmarks"
import { CAP, CAPLEVEL, useCapabilities } from "../../hooks/capabilities"
import { useHrPeople } from "../../hooks/hrPeople"
import { useHrResources } from "../../hooks/useHrResources"
import { Navbar } from "./components/Navbar"

export function HrWorkflowResources() {
  const { t } = useTranslation()
  const [, { hasCapability }] = useCapabilities()
  const isHrAdmin = hasCapability(CAP.WORKFLOW, CAPLEVEL.ADMIN)
  const [{ data: people }] = useHrPeople()
  const [{ data: hrResources }, { create, update, run: reload }] = useHrResources()

  const hrUserLookup = useMemo(() => {
    return keyBy(hrResources, "profile")
  }, [hrResources])

  if (!isHrAdmin) {
    return (
      <Layout>
        <Navbar />
        <div className="px-page">Accesso negato</div>
        <Layout.FirstLevelNavi>
          <TrackerLandmarks />
        </Layout.FirstLevelNavi>
      </Layout>
    )
  }

  return (
    <Layout displayRawContent className="flex-1 d-flex flex-column justify-content-start align-items-stretch">
      <Navbar />
      <div className="pt-5 px-page pb-page-bottom flex-1 d-flex flex-column">
        <h1 className="text-primary font-weight-semibold">{t("hr_workflow_resources.title")}</h1>
        <Table striped className="mt-7">
          <thead>
            <tr>
              <th>{t("hr_workflow_resources.col_full_name")}</th>
              <th>{t("hr_workflow_resources.col_hr_status")}</th>
              <th>{t("hr_workflow_resources.col_serial_number")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {hrResources?.map((hrResource) => (
              <tr key={"HR" + hrResource.id}>
                <td>{hrResource.full_name}</td>
                <td>
                  {hrResource.is_active ? t("hr_workflow_resources.status_active") : t("hr_workflow_resources.status_inactive")}
                </td>
                <td>{hrResource.serial_nr}</td>
                <td className="text-right">
                  {hrResource.is_active && (
                    <Button
                      size="sm"
                      onClick={() => {
                        update
                          .onSuccess(() => {
                            reload()
                          })
                          .run(hrResource.id, { is_active: false })
                      }}
                    >
                      {t("action:disable")}
                    </Button>
                  )}
                  {!hrResource.is_active && (
                    <Button
                      size="sm"
                      onClick={() => {
                        update
                          .onSuccess(() => {
                            reload()
                          })
                          .run(hrResource.id, { is_active: true })
                      }}
                    >
                      {t("action:enable")}
                    </Button>
                  )}
                </td>
              </tr>
            ))}
            {people
              ?.filter((person) => !hrUserLookup[person.id])
              .map((person) => (
                <tr key={"P" + person.id}>
                  <td>{person.full_name}</td>
                  <td>{t("hr_workflow_resources.status_none")}</td>
                  <td>{"--"}</td>
                  <td className="text-right">
                    <Button
                      size="sm"
                      onClick={() => {
                        create
                          .onSuccess(() => {
                            reload()
                          })
                          .run({
                            profile: person.id,
                            full_name: person.full_name,
                            is_active: true,
                          })
                      }}
                    >
                      {t("action:enable")}
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

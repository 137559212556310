export default function CoverSplitter(
  node,
  props,
  firstChunkSpace,
  sheetHeight
) {
  return [
    {
      props: {
        height: sheetHeight,
      },
      height: sheetHeight * ((props.frag?.data?.pageFillFactor ?? 100) / 100)
    }
  ]
}

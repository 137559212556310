import { Field, Formik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import FieldColorPicker from "../../../components/fields/FieldColorPicker"
import FieldImageLinear from "../../../components/fields/FieldImageLinear"
import FieldInput from "../../../components/fields/FieldInput"
import FieldSwitch from "../../../components/fields/FieldSwitch"
import Separator from "../../../components/Separator"
import WpModal from "../../../components/WpModal"

export default function EditCoverModal({
  isOpen,
  onClosed,
  toggle,
  frag,
  save,
}) {
  const { t } = useTranslation(["translation", "field"])
  return (
    <WpModal
      title={t("template.cover.edit_frag", { title: frag?.title ?? "" })}
      isOpen={isOpen}
      onClosed={onClosed}
      toggle={toggle}
    >
      <Formik
        initialValues={frag}
        onSubmit={(values) => save(values).then(toggle)}
      >
        {({ isValid, values, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="h-100 d-flex flex-column">
            <div className="flex-1 px-4">
              <Field
                name={"title"}
                label={t("field:document.title")}
                component={FieldInput}
              />
              <Separator className="mx-0" />

              <Field
                name="data.displayLogo"
                label={t("field:document.cover.show_logo")}
                message={t("field:document.cover.show_logo_message")}
                component={FieldSwitch}
              />

              {values.data?.displayLogo && (
                <>
                  <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                    <div className="">
                      {t("field:document.cover.logo_width")}
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-end">
                      <Field
                        name="data.logoWidth"
                        component={FieldInput}
                        type="number"
                        tiny
                        className="text-right"
                        min={10}
                        enableDelete
                      />
                      <span className="ml-1">px</span>
                    </div>
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                    <div className="">
                      {t("field:document.cover.logo_height")}
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-end">
                      <Field
                        name="data.logoHeight"
                        component={FieldInput}
                        type="number"
                        tiny
                        className="text-right"
                        min={10}
                        enableDelete
                      />
                      <span className="ml-1">px</span>
                    </div>
                  </div>
                </>
              )}

              <Separator className="mx-0" />

              <Field
                name="data.footerCols"
                label={t("field:document.cover.show_footer")}
                message={t("field:document.cover.show_footer_message")}
                component={FieldSwitch}
              />
              <Separator className="mx-0" />

              <div className="d-flex flex-row justify-content-between align-items-start">
                <div className="font-weight-semibold h3 text-uppercase">
                  {t("field:document.cover.full_cover_image")}
                </div>
                <div className="position-relative">
                  <Field
                    name="data.coverImage"
                    component={FieldImageLinear}
                    shape="square"
                    enableDelete
                  />
                </div>
              </div>

              <Separator className="mx-0 mt-0" />

              <div className="d-flex flex-row justify-content-between align-items-start">
                <div className="font-weight-semibold h3 text-uppercase">
                  {t("field:document.cover.full_cover_color")}
                </div>
                <div className="position-relative">
                  <Field
                    name="data.coverColor"
                    component={FieldColorPicker}
                    bottom={true}
                  />
                </div>
              </div>

              <Separator className="mx-0" />

            </div>
            <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
              <Button size="sm" color="none" onClick={toggle}>
                {t("action:cancel")}
              </Button>
              <Button
                size="sm"
                color="primary"
                type="submit"
                className="ml-5"
                disabled={!isValid}
              >
                {t("action:save")}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </WpModal>
  )
}

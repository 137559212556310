import classNames from "classnames"
import { flatten, groupBy, keyBy, map, pick, sortBy, sum, uniqBy } from "lodash"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import Separator from "../../../../../components/Separator"
import useCurrencyFormatter from "../../../../../hooks/useCurrencyFormatter"
import { useLocalizer } from "../../../../../hooks/useLocalizer"
import { ResourceUnitTable } from "../../../EstimateResources/ResourceCard/ResourceCard"
import {
  aggregateResources,
  flattenCloneTree,
} from "../../../EstimateTasksTree/TaskTable/utils"
import { pagerLog } from "../../Pager"

const FragComponent = React.memo(
  ({
    frag,
    estimate,
    sliceRowStart: sliceBlockStart,
    sliceRowEnd: sliceBlockEnd,
    inPage,
    chunkIsFirst,
  }) => {
    if (inPage) {
      pagerLog("[PAGER] Resource list rendered")
    }

    const { t, i18n} = useTranslation()
    const l = useLocalizer(i18n.language)

    const mappedResources = useMemo(() => {
      const flattenedTree = flattenCloneTree(estimate.task_tree)

      const resourceUsage = groupBy(
        flatten(
          flattenedTree.map((task) =>
            task.resources.map((resource) => ({
              resource_id: resource.resource_id,
              task_path: task.path,
              task_title: task.title,
            }))
          )
        ),
        "resource_id"
      )

      const flattenedResources = flatten(
        flattenedTree.map((node) => node.resources)
      )

      const aggregatedResources = aggregateResources(flattenedResources)

      const groupedByIdResources = groupBy(aggregatedResources, "resource_id")

      const resourcesById = keyBy(estimate.resources, "id")

      return map(groupedByIdResources, (resource) => {
        return {
          resource_id: resource[0].resource_id,
          name: resourcesById[resource[0].resource_id].name,
          name_en: resourcesById[resource[0].resource_id].name_en,
          total_cost: sum(
            resource
              .map((r) => parseFloat(r.unitary_cost) * parseFloat(r.size))
              .filter((n) => !isNaN(n))
          ),
          total_price: sum(
            resource
              .map((r) => parseFloat(r.unitary_price) * parseFloat(r.size))
              .filter((n) => !isNaN(n))
          ),
          details: resource.map((r) =>
            pick(r, "cost_unit", "cost_unit_en", "size", "unitary_cost", "unitary_price")
          ),
          description: resourcesById[resource[0].resource_id].description,
          description_en: resourcesById[resource[0].resource_id].description_en,
          resource_type: resourcesById[resource[0].resource_id].resource_type,
          using_tasks: uniqBy(
            resourceUsage[resource[0].resource_id],
            "task_path"
          ),
        }
      })
    }, [estimate.resources, estimate.task_tree])

    const filteredMappedResources = useMemo(
      () =>
        mappedResources
          .filter((r) => {
            if (!frag.data.showHR) {
              if (r.resource_type === "hr") {
                return false
              }
            }
            return true
          })
          .filter((r) => {
            if (!frag.data.showService) {
              if (r.resource_type === "service") {
                return false
              }
            }
            return true
          })
          .filter((r) => {
            if (!frag.data.showMaterial) {
              if (r.resource_type === "material") {
                return false
              }
            }
            return true
          }),
      [
        frag.data.showHR,
        frag.data.showMaterial,
        frag.data.showService,
        mappedResources,
      ]
    )

    const sortedResources = useMemo(() => {
      if (frag.data.sortByType) {
        return sortBy(
          filteredMappedResources,
          ["resource_type", "resource_id"],
          ["asc", "asc"]
        )
      }
      return filteredMappedResources
    }, [filteredMappedResources, frag.data.sortByType])

    const groupedResources = useMemo(() => {
      return groupBy(sortedResources, "resource_type")
    }, [sortedResources])

    const currencyFmt = useCurrencyFormatter()

    const groupSizes = map(groupedResources, (group) => group.length).filter(
      (s) => s > 0
    )

    const hasTasks = estimate.task_tree.length > 0

    return (
      <div>
        <div>
          <div className="bg-white">
            {frag?.data?.showTitle && chunkIsFirst && (
              <h1 className="__pager-title m-0 pb-3">{frag?.title}</h1>
            )}
            {!hasTasks && (
              <div className="__pager-node disclaimer">
                {t("documents.no_visible_tasks")}
              </div>
            )}
            {frag.data.groupByType &&
              Object.keys(groupedResources)
                .filter((k) => groupedResources[k].length > 0)
                .map((k, i) => {
                  const group = groupedResources[k]
                  const baseIndex = sum([i, ...groupSizes.slice(0, i)])
                  let showHeader = true
                  if (
                    (sliceBlockStart !== undefined &&
                      baseIndex < sliceBlockStart) ||
                    (sliceBlockEnd !== undefined && baseIndex >= sliceBlockEnd)
                  ) {
                    showHeader = false
                  }
                  return (
                    <React.Fragment key={k}>
                      {showHeader && (
                        <h2
                          key={group[0].resource_id}
                          className={classNames(
                            "font-weight-semibold pt-4 pb-3 page-break-after-avoid __pager-node"
                          )}
                          style={{ fontSize: 20 }}
                        >
                          {t(`enums:resource_type.${group[0].resource_type}`, {
                            count: 2,
                          })}
                        </h2>
                      )}
                      {group.map((r, j) => {
                        const idx = baseIndex + 1 + j
                        if (
                          (sliceBlockStart !== undefined &&
                            idx < sliceBlockStart) ||
                          (sliceBlockEnd !== undefined && idx >= sliceBlockEnd)
                        ) {
                          return null
                        }
                        return (
                          <div key={r.resource_id} className="__pager-node">
                            <div className="d-flex flex-row justify-content-between align-items-end mb-3">
                              <h3
                                className={classNames(
                                  "d-flex flex-row font-weight-semibold"
                                )}
                              >
                                {r[l`name`] || r.name}
                              </h3>
                              {frag.data.isPriceVisible && (
                                <div className="font-weight-bold">
                                  {currencyFmt.format(r.total_price)}
                                </div>
                              )}
                            </div>

                            <div>
                              <p className="text-justify">{r[l`description`] || r.description}</p>
                            </div>

                            <div className="d-flex flex-row pb-5 align-items-start">
                              {frag.data.isUnitTableVisible && (
                                <div className="w-50">
                                  <span className="font-weight-semibold">
                                    {t(
                                      "documents.frag.resource_list.price_and_quantity"
                                    )}
                                  </span>
                                  <ResourceUnitTable
                                    data={r.details}
                                    isCostVisible={false}
                                    showTitle={false}
                                  />
                                </div>
                              )}
                              {frag.data.showUsingTasks &&
                                r.using_tasks.length > 0 &&
                                frag.data.isUnitTableVisible && (
                                  <Separator
                                    vertical
                                    className={classNames(
                                      "mx-4 mt-0 mb-0 align-self-stretch"
                                    )}
                                  />
                                )}
                              {frag.data.showUsingTasks &&
                                r.using_tasks.length > 0 && (
                                  <div className={classNames("flex-1 w-50")}>
                                    <span className="font-weight-semibold">
                                      {t(
                                        "documents.frag.resource_list.used_tasks"
                                      )}
                                    </span>
                                    <ul>
                                      {r.using_tasks.map((t) => (
                                        <li key={t.task_path}>
                                          {t.task_path} {t.task_title}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                            </div>
                          </div>
                        )
                      })}
                    </React.Fragment>
                  )
                })}
            {!frag.data.groupByType && (
              <>
                {sortedResources.map((r, i) => {
                  if (
                    (sliceBlockStart !== undefined && i < sliceBlockStart) ||
                    (sliceBlockEnd !== undefined && i >= sliceBlockEnd)
                  ) {
                    return null
                  }
                  return (
                    <div
                      className="page-break-inside-avoid __pager-node"
                      key={r.resource_id}
                    >
                      <div className="d-flex flex-row justify-content-between align-items-end mb-3">
                        <h2
                          className={classNames(
                            "d-flex flex-row font-weight-semibold"
                          )}
                        >
                          {r.name}
                        </h2>
                        {frag.data.isPriceVisible && (
                          <div className="font-weight-bold">
                            {currencyFmt.format(r.total_price)}
                          </div>
                        )}
                      </div>

                      <div>
                        <p className="text-justify">{r[l`description`] || r.description}</p>
                      </div>

                      <div className="d-flex flex-row pb-5 align-items-start">
                        {frag.data.isUnitTableVisible && (
                          <div className="w-50">
                            <span className="font-weight-semibold">
                              {t(
                                "documents.frag.resource_list.price_and_quantity"
                              )}
                            </span>
                            <ResourceUnitTable
                              data={r.details}
                              isCostVisible={false}
                              showTitle={false}
                            />
                          </div>
                        )}
                        {frag.data.showUsingTasks &&
                          r.using_tasks.length > 0 &&
                          frag.data.isUnitTableVisible && (
                            <Separator
                              vertical
                              className={classNames(
                                "mx-4 mt-0 mb-0 align-self-stretch"
                              )}
                            />
                          )}
                        {frag.data.showUsingTasks && r.using_tasks.length > 0 && (
                          <div className={classNames("flex-1 w-50")}>
                            <span className="font-weight-semibold">
                              {t("documents.frag.resource_list.used_tasks")}
                            </span>
                            <ul>
                              {r.using_tasks.map((t) => (
                                <li>
                                  {t.task_path} {t.task_title}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                })}
              </>
            )}
          </div>
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => {
    const checkProps = ["frag", "estimate", "sliceRowStart", "sliceRowEnd", "chunkIsFirst"]
    for (let i = 0; i < checkProps.length; i++) {
      const prop = checkProps[i]
      if (prevProps[prop] !== nextProps[prop]) {
        return false
      }
    }
    return true
  }
)

export default FragComponent

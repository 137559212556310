import React from "react"
import { NavLink } from "react-router-dom"
import classNames from "classnames"
import S from "./OrganizationSettings.module.scss"
import useCurrentOrganization from "../../hooks/useCurrentOrganization"
import { useTranslation } from "react-i18next"
import { useProfile } from "../../hooks/profile"
import Icon from "../../components/Icon"

export default function OrganizationPreviewLink({ organization }) {
  const current = useCurrentOrganization()
  const [{ profile }, { updateProfile }] = useProfile()
  const { t } = useTranslation()

  const favoriteOrgId =
    profile.favorite_organization ?? profile.personal_organization

  return (
    <NavLink
      activeClassName="text-primary"
      className="d-flex flex-row align-items-center no-link"
      to={`/org/${organization.id}/settings`}
    >
      <div
        className={classNames("align-self-stretch", S["org-sign"], {
          [S["current-org"]]: current.id === organization.id,
          [S["not-current-org"]]: current.id !== organization.id,
        })}
      ></div>
      <div className="ml-2 mr-5 align-items-center flex-1">
        <div className="py-5 pl-4 border-bottom">
          <h2>{organization.name}</h2>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <p className="mb-0">
              {t("organization.members_count", {
                count: organization.members_count + 1,
              })}
            </p>
            {organization.id === favoriteOrgId && (
              <p className="mb-0 d-flex flex-row align-items-center justify-content-end">
                <Icon
                  name="star-filled"
                  className={classNames(
                    "action-icon-container-primary-hover pointer fill-primary mr-1"
                  )}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    updateProfile({
                      favorite_organization: null,
                    })
                  }}
                  title={t(`action:unset_favorite`)}
                  width={20}
                  height={20}
                />
              </p>
            )}
            {organization.id !== favoriteOrgId && (
              <p className="mb-0">
                <Icon
                  name="star"
                  className={classNames(
                    "action-icon-container-primary-hover pointer fill-primary"
                  )}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    updateProfile({
                      favorite_organization: organization.id,
                    })
                  }}
                  width={20}
                  height={20}
                  title={t(`action:set_favorite`)}
                />
              </p>
            )}
          </div>
        </div>
      </div>
    </NavLink>
  )
}

import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useAuthState } from "use-eazy-auth"
import { ReactComponent as Artwork1 } from "../../assets/404/artwork1.svg"
import { ReactComponent as Artwork2 } from "../../assets/404/artwork2.svg"
import { ReactComponent as Artwork3 } from "../../assets/404/artwork3.svg"
import { ReactComponent as Guy } from "../../assets/404/guy.svg"
import Button from "../Button/Button"
import Layout from "../Layout"
import S from "./NotFound.module.scss"


function Variant1() {
  const { t } = useTranslation(["translation", "action"])
  const history = useHistory()
  return (
    <>
      <h1 className={S["title"]}>-404-</h1>
      <Artwork1 className={S["artwork1"]} />
      <h1 className={S["subtitle"]}>{t("not_found.wrong_universe")}</h1>
      <p className={S["suggestion"]}>{t("not_found.should_return_home")}</p>
      <Button className={S["button"]} onClick={() => history.push("/")}>
        {t("action:return_home")}
      </Button>
    </>
  )
}

function Variant2() {
  const { t } = useTranslation(["translation", "action"])
  const history = useHistory()
  return (
    <>
      <h1 className={S["title"]}>-404-</h1>
      <Artwork2 className={S["artwork2"]} />
      <h1 className={S["subtitle"]}>
        {t("not_found.lost_in_space")}
        <Guy className={S["guy"]} />
      </h1>
      <p className={S["suggestion"]}>{t("not_found.should_return_home")}</p>
      <Button className={S["button"]} onClick={() => history.push("/")}>
        {t("action:return_home")}
      </Button>
    </>
  )
}

function Variant3() {
  const { t } = useTranslation(["translation", "action"])
  const history = useHistory()
  return (
    <>
      <Artwork3 className={S["artwork3"]} />
      <h1 className={S["subtitle"]}>
        {t("not_found.lost_in_space")}
        <Guy className={S["guy"]} />
      </h1>
      <p className={S["suggestion"]}>{t("not_found.should_return_home")}</p>
      <Button className={S["button"]} onClick={() => history.push("/")}>
        {t("action:return_home")}
      </Button>
    </>
  )
}

function Variant1Anon() {
  const { t } = useTranslation(["translation", "action"])
  const history = useHistory()
  return (
    <>
      <h1 className={S["title"]}>-404-</h1>
      <Artwork1 className={S["artwork1"]} />
      <h1 className={S["subtitle"]}>{t("not_found.wrong_universe")}</h1>
      <p className={S["suggestion"]}>{t("not_found.login_to_universe")}</p>
      <Button className={S["button"]} onClick={() => history.push("/login")}>
        {t("action:return_home")}
      </Button>
    </>
  )
}

function Variant2Anon() {
  const { t } = useTranslation(["translation", "action"])
  const history = useHistory()
  return (
    <>
      <h1 className={S["title"]}>-404-</h1>
      <Artwork2 className={S["artwork2"]} />
      <h1 className={S["subtitle"]}>
        {t("not_found.lost_in_space")}
        <Guy className={S["guy"]} />
      </h1>
      <p className={S["suggestion"]}>{t("not_found.login_to_home")}</p>
      <Button className={S["button"]} onClick={() => history.push("/login")}>
        {t("action:return_home")}
      </Button>
    </>
  )
}

function Variant3Anon() {
  const { t } = useTranslation(["translation", "action"])
  const history = useHistory()
  return (
    <>
      <Artwork3 className={S["artwork3"]} />
      <h1 className={S["subtitle"]}>
        {t("not_found.lost_in_space")}
        <Guy className={S["guy"]} />
      </h1>
      <p className={S["suggestion"]}>{t("not_found.login_to_home")}</p>
      <Button className={S["button"]} onClick={() => history.push("/login")}>
        {t("action:return_home")}
      </Button>
    </>
  )
}

const AuthenticatedVariants = [Variant1, Variant2, Variant3]
const NotAuthenticatedVariants = [Variant1Anon, Variant2Anon, Variant3Anon]

export default function NotFound() {
  const { authenticated } = useAuthState()

  const rand = Math.floor(Math.random() * 3)

  // Authenticated version of 404
  if (authenticated) {
    const Cmp = AuthenticatedVariants[rand]
    return (
      <Layout>
        <div className="d-flex flex-column pt-5 border-top align-items-center">
          <Cmp />
        </div>
      </Layout>
    )
  }

  // Anon 404
  const Cmp = NotAuthenticatedVariants[rand]
  return (
    <div className="d-flex flex-column pt-8 align-items-center">
      <Cmp />
    </div>
  )
}

import React, { useCallback, useContext, useState } from "react"
import classNames from "classnames"
import Icon from "../../../../../components/Icon"
import EditTaskForm from "../../forms/EditTaskForm"
import S from "./TaskCard.module.scss"
import TaskResourcesAndCostsTable from "../TaskResourcesAndCostsTable"
import Separator from "../../../../../components/Separator"
import { range, uniqueId } from "lodash"
import { TaskOperationsContext } from "../../context"
import { useTranslation } from "react-i18next"
import { UncontrolledTooltip } from "reactstrap"
import useWindowSize from "../../../../../hooks/useWindowSize"
import { useWpCopyClipboard } from "../../../../../hooks/wpClipboard"
import ScenarioSymbol from "../../../../../components/ScenarioSymbol"
import ScenarioSelector from "../../../ScenarioSelector"
import useCurrencyFormatter from "../../../../../hooks/useCurrencyFormatter"

const INDENT_TASK_WIDTH = 30

function TaskCard({
  estimate,
  updatingAllocations,
  task,
  isOpen,
  depth,
  toggleOpen,
  onSubmitUpdateTask,
  resources,
  resourcesById,
  patchTaskResource,
  addTaskResource,
  removeTaskResource,
  addTaskFixedCost,
  patchTaskFixedCost,
  removeTaskFixedCost,
  selectedScenarios,
}) {
  const depthLevel = task.path.split(".").length
  const indentWidth = (depthLevel - 1) * INDENT_TASK_WIDTH

  const { t } = useTranslation(["translation", "action"])
  const currencyFmt = useCurrencyFormatter()

  const { addTask, deleteTask, moveTask } = useContext(TaskOperationsContext)

  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = useCallback(() => setDropdownOpen((v) => !v), [])

  const handleAddChild = useCallback(
    (e) => {
      e.stopPropagation()
      addTask({
        target: task.id,
        position: "children",
      })
    },
    [addTask, task.id]
  )

  const handleAddAfter = useCallback(
    (e) => {
      e.stopPropagation()
      addTask({
        target: task.id,
        position: "after",
      })
    },
    [addTask, task.id]
  )

  const handleMove = useCallback(
    (e) => {
      e.stopPropagation()
      moveTask(task)
    },
    [moveTask, task]
  )

  const copyInClip = useWpCopyClipboard()
  const handleCopy = useCallback(
    (e) => {
      e.stopPropagation()
      copyInClip({ task, estimate })
    },
    [copyInClip, estimate, task]
  )

  const titleId = "title" + uniqueId()

  const { width } = useWindowSize()
  const isSmallScreen = width < 1800 ? true : false

  const readOnly = estimate.readonly

  return (
    <div id={"task-" + task.id} className={S["task-row"]}>
      <div
        className={classNames(S["task-header"], {
          [S["no-task-border"]]: isOpen,
        })}
        onClick={() => toggleOpen(task.id)}
      >
        <div
          className={classNames(
            "d-flex flex-row h-100 w-100",
            S["header-container"]
          )}
        >
          <div
            style={{ marginLeft: indentWidth }}
            className={classNames(
              S["task-header-section"],
              S["task-header-section-path"],
              "py-5"
            )}
          >
            {task.path}
          </div>
          <Separator vertical className="my-4 mx-4" />
          <div
            className={classNames(
              S["task-header-section"],
              S["task-header-section-title"]
            )}
          >
            <span id={titleId} className={S["title"]}>
              {task.title}
            </span>
            <UncontrolledTooltip
              placement={"bottom"}
              key={titleId}
              target={titleId}
              fade={false}
            >
              {task.title}
            </UncontrolledTooltip>
          </div>
          {readOnly && (
            <div className={classNames(S["task-header-section"])}>
              {estimate.scenarios.map((scenario) => {
                if (!task.inferredScenarios.includes(scenario.id)) {
                  return null
                }
                return (
                  <div className="mr-2">
                    <ScenarioSymbol
                      scenario={scenario}
                      isInferred={task.inferredOnlyScenarios.includes(
                        scenario.id
                      )}
                    />
                  </div>
                )
              })}
            </div>
          )}

          {!readOnly && (
            <>
              <div
                className={classNames(S["task-header-section"])}
                onClick={(e) => e.stopPropagation()}
              >
                <ScenarioSelector
                  scenariosList={estimate.scenarios}
                  selectedScenarios={task.scenarios}
                  onSave={(toChange) =>
                    onSubmitUpdateTask({
                      ...task,
                      scenarios: toChange,
                    })
                  }
                  title={t("tasks.scenario.select_scenario_message")}
                  enableAll={false}
                  enableBase={true}
                  reducer={(tempState, selected) => {
                    if (selected === null) {
                      //this should never happen due to enableAll = false
                      return null
                    } else if (selected?.length === 0) {
                      return []
                    } else {
                      if (tempState === null) {
                        //this should never happen due to enableAll = false
                        return [selected.id]
                      } else if (tempState.includes(selected.id)) {
                        return tempState.filter((i) => i !== selected.id)
                      } else {
                        return [...tempState, selected.id]
                      }
                    }
                  }}
                  isOpen={isDropdownOpen}
                  toggle={toggleDropdown}
                >
                  {(icon) => {
                    return (
                      <>
                        {estimate.scenarios.map((scenario) => {
                          if (!task.inferredScenarios.includes(scenario.id)) {
                            return null
                          }
                          return (
                            <div className="mr-2" key={scenario.id}>
                              <ScenarioSymbol
                                scenario={scenario}
                                isInferred={task.inferredOnlyScenarios.includes(
                                  scenario.id
                                )}
                              />
                            </div>
                          )
                        })}
                        {icon}
                      </>
                    )
                  }}
                </ScenarioSelector>
              </div>
              <Separator vertical className="my-4 mx-2" />
            </>
          )}

          <div className={classNames(S["task-header-section"])}>
            <div
              role="button"
              className={classNames(S["icon-container"])}
              onClick={handleMove}
            >
              <Icon
                name="duplicate"
                onClick={handleCopy}
                title={t("action:copy_task")}
                style={{
                  width: 24,
                  height: 24,
                }}
              />
            </div>
          </div>
          {!readOnly && (
            <>
              <Separator vertical className="my-4 mx-2" />
              <div className={classNames(S["task-header-section"])}>
                <div
                  role="button"
                  className={classNames(S["icon-container"])}
                  onClick={handleMove}
                >
                  <Icon
                    name="move"
                    title={t("action:move_task")}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {!readOnly && (
            <>
              <Separator vertical className="my-4 mx-2" />
              <div className={classNames(S["task-header-section"])}>
                <div
                  role="button"
                  className={classNames(S["icon-container"])}
                  onClick={handleAddAfter}
                >
                  <Icon
                    name="add-after"
                    title={t("action:add_after")}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {!readOnly && (
            <>
              <Separator vertical className="my-4 mx-2" />
              <div className={classNames(S["task-header-section"])}>
                <div
                  role="button"
                  className={classNames(S["icon-container"])}
                  onClick={handleAddChild}
                >
                  <Icon
                    name="add-child"
                    title={t("action:add_child")}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {!readOnly && (
            <>
              <Separator vertical className="my-4 mx-2" />
              <div className={classNames(S["task-header-section"])}>
                <div role="button" className={classNames(S["icon-container"])}>
                  <Icon
                    name="delete"
                    title={t("action:delete")}
                    onClick={(e) => {
                      e.stopPropagation()
                      deleteTask(task)
                    }}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <Separator vertical className="my-4 mr-4 ml-2" />
          <div className={classNames(S["task-header-section"], S["cost"])}>
            <span className="d-inline-flex flex-column align-items-end">
              {task.costMap[""] !== undefined && (
                <span>
                  {currencyFmt.format(task.costMap[""])}
                </span>
              )}
              {estimate.scenarios.map((scenario) => {
                if (scenario.id in task.costMap) {
                  return (
                    <span key={scenario.id}>
                      <small>{scenario.code + ": "}</small>
                      {currencyFmt.format(task.costMap[scenario.id]+(task.costMap[""] ?? 0))}
                    </span>
                  )
                } else {
                  return null
                }
              })}
            </span>
          </div>
          <div
            className={classNames(S["task-header-section-money"], {
              [S["top-level"]]: depthLevel === 1,
            })}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              {depth > 1 &&
                range(1, depth + 1).map((i) => {
                  return (
                    <svg
                      key={i}
                      width={7}
                      height={7}
                      className={S["depth-dot"]}
                    >
                      <circle
                        cx={3.5}
                        cy={3.5}
                        r={i === depthLevel ? 3.5 : 1.5}
                        fill="white"
                      />
                    </svg>
                  )
                })}
            </div>
            <span className="d-inline-flex flex-column align-items-end">
              {task.priceMap[""] !== undefined && (
                <span>
                  {currencyFmt.format(task.priceMap[""])}
                </span>
              )}
              {estimate.scenarios.map((scenario) => {
                if (scenario.id in task.priceMap) {
                  return (
                    <span key={scenario.id}>
                      <small>{scenario.code + ": "}</small>
                      {currencyFmt.format(task.priceMap[scenario.id] + (task.priceMap[""] ?? 0))}
                    </span>
                  )
                } else {
                  return null
                }
              })}
            </span>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="border-bottom">
          <div className={classNames(S["edit-task-card"])}>
            <div className={classNames(S["task-info"])}>
              <EditTaskForm
                task={task}
                onSubmit={onSubmitUpdateTask}
                readOnly={estimate.readonly}
              />
              {/* {estimate.scenarios.map(scenario => {
                const isAdded = task.scenarios.includes(scenario.id)
                return (
                  <Button
                    key={scenario.id}
                    color={isAdded ? "primary" : "light"}
                    onClick={() => {
                      let nextScenarios
                      if (isAdded) {
                        nextScenarios = task.scenarios.filter(s => s !== scenario.id)
                      } else {
                        nextScenarios = [...task.scenarios, scenario.id]
                      }
                      onSubmitUpdateTask({
                        ...task,
                        scenarios: nextScenarios
                      })
                    }}
                  >
                    {scenario.name}
                  </Button>
                )
              })} */}
            </div>
            <Separator
              vertical={!isSmallScreen}
              className={classNames(
                { "mx-4 mt-0 mb-0": !isSmallScreen },
                { "w-100": isSmallScreen }
              )}
            />
            <div
              className={classNames(S["resource-edit"], "d-flex flex-column")}
            >
              <TaskResourcesAndCostsTable
                estimate={estimate}
                updatingAllocations={updatingAllocations}
                task={task}
                resources={resources}
                resourcesById={resourcesById}
                patchTaskResource={patchTaskResource}
                addTaskResource={addTaskResource}
                removeTaskResource={removeTaskResource}
                addTaskFixedCost={addTaskFixedCost}
                patchTaskFixedCost={patchTaskFixedCost}
                removeTaskFixedCost={removeTaskFixedCost}
                readOnly={estimate.readonly}
              />
              {parseFloat(task.own_price) !== 0 && (
                <div className="d-flex justify-content-end pt-4">
                  {t("tasks.partial_price", {
                    partial_price: currencyFmt.format(task.own_price),
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(TaskCard)

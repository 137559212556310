import React, { useEffect, useRef } from "react"
import { getDepth } from "react-sortable-tree"
import TaskCard from "./TaskCard/TaskCard"

function TaskTreeIteree({ taskTree, opens, ...props }) {
  return (
    <>
      {taskTree.map((task) => (
        <React.Fragment key={task.id}>
          <TaskCard task={task} isOpen={opens[task.id]} {...props} />
          <TaskTreeIteree taskTree={task.children} opens={opens} {...props} />
        </React.Fragment>
      ))}
    </>
  )
}

export default function TaskTree({
  taskTree,
  opens,
  toggleOpen,
  initialTaskViewed,
  ...props
}) {
  const isMountRef = useRef(true)
  useEffect(() => {
    if (isMountRef.current && initialTaskViewed) {
      const elem = document.getElementById(`task-${initialTaskViewed}`)
      if (elem) {
        elem.scrollIntoView({
          // NOTE: I don't know why but smooth behavior is bugged
          // with DropdownButton component of reacstrap i think is the damn
          // popper should investigate it take a lot of time to knowing this...
          // behavior: "smooth",
          block: "center",
          inline: "center",
        })
      }
    }
    isMountRef.current = false
  }, [initialTaskViewed])

  return (
    <TaskTreeIteree
      depth={getDepth({ children: taskTree })}
      taskTree={taskTree}
      opens={opens}
      toggleOpen={toggleOpen}
      {...props}
    />
  )
}

import { Field } from "formik"
import { keyBy, range } from "lodash"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { getDepth } from "react-sortable-tree"
import FieldRange from "../../../../../components/fields/FieldRange"
import FieldRichText from "../../../../../components/fields/FieldRichText"
import FieldSlider from "../../../../../components/fields/FieldSlider"
import FieldSwitch from "../../../../../components/fields/FieldSwitch"
import EditDataFrame, {
  EditDataSection,
} from "../../EditDataFrame/EditDataFrame"

export const EditDataForm = ({
  fullEstimate,
  frag,
  save,
  remove,
  toggle,
  scrollFragIntoView,
}) => {
  const depth = getDepth({ children: fullEstimate.task_tree })

  const { t } = useTranslation(["translation"])
  const readOnly = fullEstimate.readonly
  const ctx = useMemo(() => ({ estimate: fullEstimate }), [fullEstimate])

  const sliderMarks = useMemo(() => {
    const values = range(1, depth + 1).map((v) => {
      if (v < depth) {
        return {
          id: v,
          label: t("tasks.level", { level: v }),
        }
      }
      return {
        id: v,
        label: t("tasks.all"),
      }
    })
    return keyBy(values, "id")
  }, [depth, t])

  const rangeMarks = useMemo(() => {
    const height = fullEstimate.task_tree.length
    const values = range(1, height + 1).map((v) => {
      return {
        id: v,
        label: v,
      }
    })
    return keyBy(values, "id")
  }, [fullEstimate.task_tree.length])

  return (
    <EditDataFrame
      contentType={t("documents.frag.viz.scenario_task_viz.title")}
      frag={frag}
      save={save}
      remove={remove}
      toggle={toggle}
      scrollFragIntoView={scrollFragIntoView}
      readOnly={readOnly}
      scenarios={fullEstimate.scenarios}
      enableScenarioSelector={false}
    >
      {(formik) => (
        <>
          <EditDataSection
            title={t("field:document.scenario_task_viz.show_caption")}
          >
            <Field
              name="data.enableCaption"
              message={t(
                "field:document.scenario_task_viz.show_caption_message"
              )}
              component={FieldSwitch}
              readOnly={readOnly}
            />
            {formik.values.data.enableCaption && (
              <div className="mt-4">
                <Field
                  name="data.caption"
                  component={FieldRichText}
                  readOnly={readOnly}
                  placeholder={readOnly ? "" : undefined}
                  enableImages={false}
                  templatingContext={ctx}
                  enableBlockFormatting={false}
                  enableUndoRedo={false}
                  enableFontSize={false}
                  enableLists={false}
                />
              </div>
            )}
          </EditDataSection>
          <EditDataSection
            title={t("field:document.scenario_task_viz.task_section_title")}
          >
            {fullEstimate.task_tree.length > 1 && (
              <div className="overflow-x-visible">
                <p className="mb-2 text-uppercase">
                  {t("field:document.task_table.task_selection")}
                </p>
                <div className="mx-3 overflow-x-visible">
                  <Field
                    name="data.visibleTasks"
                    marks={rangeMarks}
                    component={FieldRange}
                    readOnly={readOnly}
                    style={{ width: "100%" }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}
            {depth > 1 && (
              <div className="mt-4 overflow-x-visible">
                <p className="mb-2 text-uppercase">
                  {t("field:document.task_table.depth_level")}
                </p>
                <div className="mx-3 overflow-x-visible">
                  <Field
                    name="data.maxDepth"
                    marks={sliderMarks}
                    max={depth}
                    component={FieldSlider}
                    readOnly={readOnly}
                    style={{ width: "100%" }}
                    className="mb-5"
                  />
                </div>
              </div>
            )}
            <div className="mt-4">
              <Field
                name="data.isPriceVisible"
                message={t(
                  "field:document.scenario_task_viz.show_price_message"
                )}
                component={FieldSwitch}
                readOnly={readOnly}
              />
            </div>
          </EditDataSection>
          <EditDataSection
            title={t(
              "field:document.scenario_task_viz.scenarios_section_title"
            )}
          >
            <div>
              <Field
                name="data.showLegend"
                message={t(
                  "field:document.scenario_task_viz.show_legend"
                )}
                component={FieldSwitch}
                readOnly={readOnly}
              />
            </div>
            <div className="mt-4">
              <Field
                name="data.isNormalized"
                message={t(
                  "field:document.scenario_task_viz.show_normalized_message"
                )}
                component={FieldSwitch}
                readOnly={readOnly}
              />
            </div>
            <div className="mt-4">
              <Field
                name="data.isBaseVisible"
                message={t(
                  "field:document.scenario_task_viz.show_base_message"
                )}
                component={FieldSwitch}
                readOnly={readOnly}
              />
            </div>
          </EditDataSection>
        </>
      )}
    </EditDataFrame>
  )
}

export default EditDataForm

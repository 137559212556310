import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Icon from "../Icon"
import WpInput from "../WpInput"
import S from "./PasswordShowHide.module.scss"
import classNames from "classnames"

export default function PasswordShowHide(props) {
  const [visiblePassword, setVisiblePassword] = useState(false)
  const { t } = useTranslation(["action"])

  return (
    <div className="position-relative">
      <WpInput type={visiblePassword ? "text" : "password"} {...props} />
      <Icon
        name={visiblePassword ? "hide" : "view"}
        title={
          visiblePassword
            ? t("action:hide_password")
            : t("action:show_password")
        }
        className={classNames(
          "action-icon-container-primary-hover pointer",
          S["icon"]
        )}
        onClick={() => {
          setVisiblePassword(!visiblePassword)
        }}
      />
    </div>
  )
}

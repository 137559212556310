import React from "react"
import reduceCSSCalc from "reduce-css-calc"
import { getStringWidth } from "@visx/text"
import { isEqual } from "lodash"

const SVG_STYLE = { overflow: "visible" }

function isNumber(val) {
  return typeof val === "number"
}

function isValidXOrY(xOrY) {
  return (
    // number that is not NaN or Infinity
    (typeof xOrY === "number" && Number.isFinite(xOrY)) ||
    // for percentage
    typeof xOrY === "string"
  )
}

export default class MultiLineText extends React.Component {
  static defaultProps = {
    x: 0,
    y: 0,
    dx: 0,
    dy: 0,
    lineHeight: "1em",
    capHeight: "0.71em", // Magic number from d3
    scaleToFit: false,
    textAnchor: "start",
    verticalAnchor: "end", // default SVG behavior
  }

  state = {
    wordsByLines: [],
  }

  wordsWithWidth = []

  spaceWidth = 0

  componentDidMount() {
    this.updateWordsByLines(this.props, true)
  }

  componentDidUpdate(prevProps, prevState) {
    // We calculated a new state, break out of the loop.
    if (prevState.wordsByLines !== this.state.wordsByLines) {
      return
    }

    const needCalculate =
      prevProps.children !== this.props.children ||
      prevProps.style !== this.props.style ||
      prevProps.maxLines !== this.props.maxLines ||
      !isEqual(prevProps.maxLinesPerChunk, this.props.maxLinesPerChunk)
    this.updateWordsByLines(this.props, needCalculate)
  }

  updateWordsByLines(props, needCalculate = false) {
    // Only perform calculations if using features that require them (multiline, scaleToFit)
    if (props.width || props.scaleToFit) {
      if (needCalculate) {
        const lines =
          props.children == null
            ? []
            : props.children.split("\n").map((line) => line.toString())
        const words = lines.map((line) => {
          const words = line.split(/(?:(?!\u00A0+)\s+)/)
          return words.map((word) => ({
            word,
            width: getStringWidth(word, props.style) || 0,
          }))
        })
        this.wordsWithWidth = words
        this.spaceWidth = getStringWidth("\u00A0", props.style) || 0
      }

      const wordsByLines = this.calculateWordsByLines(
        this.wordsWithWidth,
        this.spaceWidth,
        props.width
      )
      this.setState({ wordsByLines })
    } else {
      this.updateWordsWithoutCalculate(props)
    }
  }

  updateWordsWithoutCalculate(props) {
    const words =
      props.children == null
        ? []
        : props.children.toString().split(/(?:(?!\u00A0+)\s+)/)
    this.setState({ wordsByLines: [{ words }] })
  }

  limitLines(linesWithWidth, maxLines) {
    if (maxLines < 0 || maxLines === undefined || maxLines === null) {
      return linesWithWidth
    }
    if (maxLines >= linesWithWidth.length) {
      return linesWithWidth
    }
    const result = linesWithWidth.slice(0, maxLines)
    result[result.length - 1].words.push("...")
    result[result.length - 1].width += getStringWidth("...")
    return result
  }

  calculateWordsByLines(linesWithWidth, spaceWidth, lineWidth) {
    const { scaleToFit } = this.props
    const result = linesWithWidth
      .map((wordsWithWidth, i) => {
        let result = wordsWithWidth.reduce(
          (result, { word, width }) => {
            const currentLine = result[result.length - 1]

            if (
              currentLine &&
              (lineWidth == null ||
                scaleToFit ||
                (currentLine.width || 0) + width + spaceWidth < lineWidth)
            ) {
              // Word can be added to an existing line
              currentLine.words.push(word)
              currentLine.width = currentLine.width || 0
              currentLine.width += width + spaceWidth
            } else {
              // Add first word to line or word is too long to scaleToFit on existing line
              const newLine = { words: [word], width }
              result.push(newLine)
            }

            return result
          },
          []
        )
        if (Array.isArray(this.props.maxLinesPerChunk)) {
          const limit = this.props.maxLinesPerChunk[i]
          result = this.limitLines(result, limit)
        }
        return result
      })
      .reduce((result, lines) => result.concat(lines), [])
    if (isNumber(this.props.maxLines)) {
      return this.limitLines(result, this.props.maxLines)
    } else {
      return result
    }
  }

  render() {
    const {
      dx,
      dy,
      textAnchor,
      verticalAnchor,
      scaleToFit,
      angle,
      lineHeight,
      capHeight,
      innerRef,
      width,
      maxLines,
      maxLinesPerChunk,
      ...textProps
    } = this.props

    const { wordsByLines } = this.state
    const { x, y } = textProps

    // Cannot render <text> if x or y is invalid
    if (!isValidXOrY(x) || !isValidXOrY(y)) {
      return (
        <svg
          ref={innerRef}
          x={dx}
          y={dy}
          fontSize={textProps.fontSize}
          style={SVG_STYLE}
        />
      )
    }

    let startDy
    if (verticalAnchor === "start") {
      startDy = reduceCSSCalc(`calc(${capHeight})`)
    } else if (verticalAnchor === "middle") {
      startDy = reduceCSSCalc(
        `calc(${
          (wordsByLines.length - 1) / 2
        } * -${lineHeight} + (${capHeight} / 2))`
      )
    } else {
      startDy = reduceCSSCalc(
        `calc(${wordsByLines.length - 1} * -${lineHeight})`
      )
    }

    const transforms = []
    if (
      isNumber(x) &&
      isNumber(y) &&
      isNumber(width) &&
      scaleToFit &&
      wordsByLines.length > 0
    ) {
      const lineWidth = wordsByLines[0].width || 1
      const sx = width / lineWidth
      const sy = sx
      const originX = x - sx * x
      const originY = y - sy * y
      transforms.push(`matrix(${sx}, 0, 0, ${sy}, ${originX}, ${originY})`)
    }
    if (angle) {
      transforms.push(`rotate(${angle}, ${x}, ${y})`)
    }

    const transform = transforms.length > 0 ? transforms.join(" ") : undefined

    return (
      <svg
        ref={innerRef}
        x={dx}
        y={dy}
        fontSize={textProps.fontSize}
        style={SVG_STYLE}
      >
        <text transform={transform} {...textProps} textAnchor={textAnchor}>
          {wordsByLines.map((line, index) => (
            <tspan key={index} x={x} dy={index === 0 ? startDy : lineHeight}>
              {line.words.join(" ")}
            </tspan>
          ))}
        </text>
      </svg>
    )
  }
}

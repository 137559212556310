import React from "react"
import Dropdown from "../Dropdown"
import classNames from "classnames"

export default function FontPicker({ value, onChange, options, className, ...props }) {
  return (
    <Dropdown
      className={classNames("w-100", className)}
      onChange={(v) => onChange(v)}
      value={value}
      options={options.map(({ label, value }) => ({
        value,
        label: (
          <span style={{ fontFamily: value }} key={label}>
            {label}
          </span>
        ),
      }))}
      {...props}
    />
  )
}

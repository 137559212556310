import React, { useCallback, useContext, useMemo } from "react"
import { deps, rj, useRunRj } from "react-rocketjump"
import api from "../api"
import useCurrentOrganization from "./useCurrentOrganization"

const CapabilitiesRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => () => api.auth(wpAuth).get("/api/me/capabilities"),
})

export function useCurrentOrgCapabilitiesAPI(org) {
  return useRunRj(CapabilitiesRj, [org], true)
}

const CapContext = React.createContext([])

export function ProvideCapabilities({ children }) {
  const org = useCurrentOrganization()
  const [{ data: capabilities }] = useCurrentOrgCapabilitiesAPI(deps.maybeGet(org, "id"))

  const hasCapability = useCallback(
    (capToCheck, minLevel = CAPLEVEL.LIMITED_READ) => {
      if (capabilities?.[0] === "*") {
        return true
      }
      if (capToCheck.endsWith("/*")) {
        const testValue = capToCheck.substr(0, capToCheck.length - 1)
        return capabilities?.some((cap) => cap.capability.startsWith(testValue) && cap.level >= minLevel) ?? false
      }
      return capabilities?.some((cap) => cap.capability === capToCheck && cap.level >= minLevel) ?? false
    },
    [capabilities]
  )

  const ctx = useMemo(() => {
    return [capabilities, { hasCapability }]
  }, [capabilities, hasCapability])

  return <CapContext.Provider value={ctx}>{children}</CapContext.Provider>
}

export function useCapabilities() {
  return useContext(CapContext)
}

export const CAPLEVEL = Object.freeze({
  NONE: 0,
  LIMITED_READ: 100,
  READ: 200,
  WRITE: 300,
  ADMIN: 400,
})

export const CAP = Object.freeze({
  INDICATORS: "WP0000",
  OVERVIEW: "WP0001",
  BUSINESS: "WP0002",
  CUSTOMER: "WP0003",
  REQUESTS: "WP0004",
  INTERACTIONS: "WP0005",
  ESTIMATES: "WP0006",
  TEMPLATES: "WP0007",
  RESOURCES: "WP0008",
  PROJECTS: "WP0009",
  DEADLINES: "WP0010",
  CALENDAR: "WP0019",
  TEAM: "WP0011",
  TASKS: "WP0012",
  ACTIVITIES: "WP0014",
  BILLS: "WP0015",
  PROJECT_INDICATORS: "WP0016",
  NC_REPORT: "WP0018",
  TAGS: "WP0020",
  ORG_META: "WP0021",
  ORG_MEMBERS: "WP0022",
  ORG_BILLING: "WP0023",
  WORKFLOW: "WP0024",
})

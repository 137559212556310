import { Field, Formik } from "formik"
import React from "react"
import Scrollbars from "react-custom-scrollbars"
import { useTranslation } from "react-i18next"
import FieldInput from "../../../../components/fields/FieldInput"
import FieldScenarioSelector from "../../../../components/fields/FieldScenarioSelector"
import Icon from "../../../../components/Icon"
import Separator from "../../../../components/Separator"
import { toast } from "react-toastify"
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown"
import DropdownToggle from "reactstrap/lib/DropdownToggle"
import DropdownMenu from "reactstrap/lib/DropdownMenu"
import DropdownItem from "reactstrap/lib/DropdownItem"
import FieldSwitch from "../../../../components/fields/FieldSwitch"
import FormAutoSave from "../../../../components/FormAutoSave"

export function EditDataSection({ title, children }) {
  return (
    <>
      <div className="overflow-x-visible mx-4">
        {title && <h3 className="text-uppercase mb-3 mt-0 mx-0">{title}</h3>}
        {children}
      </div>
      <Separator className="mx-0" />
    </>
  )
}

export default function EditDataFrame({
  contentType,
  children,
  frag,
  save,
  remove,
  saveTemplate,
  pickCover,
  toggle,
  scrollFragIntoView,
  enableAll = true,
  enableScenarioSelector = true,
  enableShowTitle = true,
  readOnly = false,
  scenarios,
  allowMargin = true,
  scenariosConfigurationExtra = null,
}) {
  const { t } = useTranslation(["translation"])

  return (
    <Formik
      initialValues={frag}
      key={frag.id}
      onSubmit={(values) => {
        return save(values).then((arg) => {
          toast.success(t("documents.updated_message"))
          return arg
        })
      }}
    >
      {({ isValid, handleSubmit, values, ...formik }) => (
        <form
          onSubmit={handleSubmit}
          className="h-100 d-flex flex-column overflow-x-visible"
          style={{ padding: "32px 0px" }}
        >
          {!readOnly && <FormAutoSave debounceTime={150} />}
          <div className="d-flex flex-column h-100 overflow-x-visible">
            <div
              className="d-flex flex-row justify-content-between align-items-center mb-4 mx-4"
              style={{ paddingLeft: 20, paddingRight: 20 }}
            >
              <h2 className="text-uppercase font-weight-semibold">
                {t("documents.edit_data_form")}
              </h2>
            </div>
            <div
              className="d-flex flex-row justify-content-between align-items-center mb-4 mx-4"
              style={{ paddingLeft: 20, paddingRight: 20 }}
            >
              <h3
                className="text-primary text-uppercase"
                style={{ fontSize: 14 }}
              >
                {contentType}
              </h3>
              <div className="d-flex flex-row align-items-center">
                <div
                  className="action-icon-container-primary-hover mx-1 pointer"
                  onClick={() => {
                    if (typeof scrollFragIntoView === "function") {
                      scrollFragIntoView(frag)
                    }
                  }}
                >
                  <Icon
                    name="scroll-into-view"
                    style={{ width: 28, height: 28 }}
                    title={t("action:scroll_into_view")}
                  />
                </div>
                {!readOnly && (
                  <UncontrolledDropdown
                    className={"action-icon-container-primary-hover"}
                  >
                    <DropdownToggle caret={false} tag={"span"}>
                      <Icon
                        role="button"
                        name="vdots"
                        className="text-dark pointer"
                        title={t("action:other_options")}
                        placement={"right"}
                      />
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{ offset: { offset: "0, 12" } }}
                      className="border-primary"
                      style={{ width: 180 }}
                    >
                      {typeof remove === "function" && (
                        <>
                          <DropdownItem
                            className={
                              "text-capitalize px-0 dropdown-item-primary-active"
                            }
                          >
                            <div
                              className="d-flex flex-row align-items-center mx-4"
                              onClick={() => {
                                remove(frag)
                              }}
                            >
                              <Icon name="delete" className="pointer mr-3" />
                              <span>{t("action:delete")}</span>
                            </div>
                          </DropdownItem>
                        </>
                      )}
                      {(frag.fragment_type === "text" ||
                        frag.fragment_type === "cover") && (
                        <>
                          {frag.fragment_type === "text" && (
                            <Separator className="my-0 mx-4 border-primary" />
                          )}
                          <DropdownItem
                            className={"px-0 dropdown-item-primary-active"}
                          >
                            <div
                              className="d-flex flex-row align-items-center mx-4"
                              onClick={() => saveTemplate(frag)}
                            >
                              <Icon
                                name="template"
                                style={{ width: 20, height: 20 }}
                                className="pointer mr-3"
                              />
                              <span>{t("action:save_as_template")}</span>
                            </div>
                          </DropdownItem>
                        </>
                      )}
                      {frag.fragment_type === "cover" && (
                        <>
                          <Separator className="my-0 mx-4 border-primary" />
                          <DropdownItem
                            className={"px-0 dropdown-item-primary-active"}
                          >
                            <div
                              className="d-flex flex-row align-items-center mx-4"
                              onClick={() => pickCover()}
                            >
                              <Icon
                                name="edit"
                                style={{ width: 20, height: 20 }}
                                className="pointer mr-3"
                              />
                              <span>{t("action:pick_cover")}</span>
                            </div>
                          </DropdownItem>
                        </>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
                {readOnly &&
                  (frag.fragment_type === "text" ||
                    frag.fragment_type === "cover") && (
                    <UncontrolledDropdown
                      className={"action-icon-container-primary-hover"}
                    >
                      <DropdownToggle caret={false} tag={"span"}>
                        <Icon
                          role="button"
                          name="vdots"
                          className="text-dark pointer"
                          title={t("action:other_options")}
                          placement={"right"}
                        />
                      </DropdownToggle>
                      <DropdownMenu
                        modifiers={{ offset: { offset: "0, 12" } }}
                        className="border-primary"
                        style={{ width: 180 }}
                      >
                        <DropdownItem
                          className={"px-0 dropdown-item-primary-active"}
                        >
                          <div
                            className="d-flex flex-row align-items-center mx-4"
                            onClick={() => saveTemplate(frag)}
                          >
                            <Icon
                              name="template"
                              style={{ width: 20, height: 20 }}
                              className="pointer mr-3"
                            />
                            <span>{t("action:save_as_template")}</span>
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
              </div>
            </div>
            <div className="flex-1 overflow-x-visible">
              <Scrollbars style={{ width: "100%", height: "100%" }}>
                <div
                  className="overflow-x-visible"
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                >
                  <EditDataSection title={t("field:document.title")}>
                    <div>
                      <Field
                        name={"title"}
                        component={FieldInput}
                        readOnly={readOnly}
                        debounce
                      />
                    </div>
                    {enableShowTitle && (
                      <div className="mt-4">
                        <Field
                          name="data.showTitle"
                          message={t("field:document.show_title_message")}
                          component={FieldSwitch}
                          readOnly={readOnly}
                        />
                      </div>
                    )}
                  </EditDataSection>

                  {enableScenarioSelector && (
                    <EditDataSection title={t("field:document.scenario")}>
                      <Field
                        name={"selected_scenarios"}
                        component={FieldScenarioSelector}
                        readOnly={readOnly}
                        enableAll={enableAll}
                        enableBase={true}
                        scenarios={scenarios}
                      />
                      {scenariosConfigurationExtra}
                    </EditDataSection>
                  )}
                  {typeof children === "function" &&
                    children({
                      ...formik,
                      isValid,
                      handleSubmit,
                      values,
                    })}
                  {typeof children !== "function" && children}
                  {allowMargin && (
                    <EditDataSection title={t("field:document.page_margin_mm")}>
                      <div className="d-flex flex-row justify-content-end align-items-center">
                        <div className="flex-1">
                          {t("field:document.page_margin_mm_message")}
                        </div>
                        <Field
                          name={"data.page_margin"}
                          component={FieldInput}
                          type="number"
                          min={0}
                          tiny
                          className="text-right"
                          placeholder={5}
                          readOnly={readOnly}
                        />
                        <span className="ml-2">{"mm"}</span>
                      </div>
                    </EditDataSection>
                  )}
                </div>
              </Scrollbars>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}

import React from "react"
import { scaleLinear } from "d3-scale"
import { flatten, last } from "lodash"
import { TreeMap } from "./TreeMap"
import { accumulate } from "./utils"

export function computeDomain(dataset, metric) {
  const metricCube = dataset.map((data) =>
    data.map((row) => {
      return row.items.map((item) => item[metric], 0)
    })
  )

  const accumulatedMetricCube = metricCube.map((metricMatrix) =>
    metricMatrix.map((row) => accumulate(row))
  )

  const heightMetricMatrix = accumulatedMetricCube.map(
    (accumulatedMetricMatrix) => accumulatedMetricMatrix.map((row) => last(row))
  )

  const minHeightMetricValue = Math.min(...flatten(heightMetricMatrix))
  const maxHeightMetricValue = Math.max(...flatten(heightMetricMatrix))

  return [minHeightMetricValue, maxHeightMetricValue]
}

export default function TreeMapGroup({
  dataset,
  minRowHeight,
  maxRowHeight,
  minDomain,
  maxDomain,
  metric,
  eachWrappedIn,
  ...props
}) {
  let minHeightMetricValue = minDomain
  let maxHeightMetricValue = maxDomain

  if (
    minHeightMetricValue === undefined ||
    minHeightMetricValue === null ||
    maxHeightMetricValue === undefined ||
    maxHeightMetricValue === null
  ) {
    const autoDomain = computeDomain(dataset, metric)
    if (minHeightMetricValue === undefined || minHeightMetricValue === null) {
      minHeightMetricValue = autoDomain[0]
    }
    if (maxHeightMetricValue === undefined || maxHeightMetricValue === null) {
      maxHeightMetricValue = autoDomain[1]
    }
  }

  const heightScale =
    minHeightMetricValue < maxHeightMetricValue
      ? scaleLinear()
          .domain([minHeightMetricValue, maxHeightMetricValue])
          .range([minRowHeight, maxRowHeight])
      : scaleLinear()
          .domain([minHeightMetricValue, minHeightMetricValue])
          .range([
            (minRowHeight + maxRowHeight) / 2,
            (minRowHeight + maxRowHeight) / 2,
          ])

  return dataset.map((data, i) => {
    if (data === null) {
      return null
    } else {
      if (eachWrappedIn) {
        return eachWrappedIn(
          <TreeMap
            key={i}
            rowScale={heightScale}
            {...props}
            data={data}
            metric={metric}
            minRowHeight={minRowHeight}
            maxRowHeight={maxRowHeight}
            index={i}
          />,
          i
        )
      }
      return (
        <TreeMap
          key={i}
          rowScale={heightScale}
          {...props}
          data={data}
          metric={metric}
          minRowHeight={minRowHeight}
          maxRowHeight={maxRowHeight}
          index={i}
        />
      )
    }
  })
}

import api from "../api"
import { rj, useRj, useRunRj } from "react-rocketjump"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import rjDebounce from "react-rocketjump/plugins/debounce"
import get from "lodash/get"

const getOrSelect = (context, pathOrFunction) => {
  if (typeof pathOrFunction === "function") {
    return pathOrFunction(context)
  }
  return get(context, pathOrFunction)
}

const PricedResourcesListState = rj({
  name: "PricedResourcesList",
  effectCaller: rj.configured(),
  effect: (wpAuth) => (params = {}) =>
    api.auth(wpAuth).get("/api/priced-resource/", params),
  computed: {
    pricedResources: "getData",
    loading: "isPending",
    error: "getError",
  },
})

export function usePricedResourcesList(params) {
  return useRunRj(PricedResourcesListState, [params], false)
}

const ResourcesListState = rj(
  rjDebounce(200),
  rjList({
    pageSize: 100,
    pagination: nextPreviousPaginationAdapter,
    customPaginationReducer: (prevState, { payload: { data } }) => {
      return {
        ...prevState,
        count: getOrSelect(data, nextPreviousPaginationAdapter.count),
        current: getOrSelect(data, nextPreviousPaginationAdapter.current),
        next: getOrSelect(data, nextPreviousPaginationAdapter.next),
        previous: getOrSelect(data, nextPreviousPaginationAdapter.previous),
        full_count: data.full_count,
      }
    },
  }),
  {
    name: "ResourcesList",
    effectCaller: rj.configured(),
    effect: (wpAuth) => (params = {}) =>
      api.auth(wpAuth).get("/api/resource/", params),
    selectors: ({ getPagination }) => ({
      getFullCount: (state) => {
        if (state.root) {
          return get(state, "root.data.pagination.full_count", 0)
        }
        return get(state, "data.pagination.full_count", 0)
      },
    }),
    computed: {
      resources: "getList",
      count: "getCount",
      fullCount: "getFullCount",
      loading: "isPending",
      error: "getError",
      updatingResources: (s) => s.mutations.updateResource.pendings,
      pageCount: "getNumPages",
      hasNext: "hasNext",
      hasPrev: "hasPrev",
    },
    mutations: {
      createResource: rj.mutation.single({
        effect: (wpAuth) => (resourceData) => {
          return api
            .auth(wpAuth)
            .post("/api/resource/?embed_prices=yes", resourceData)
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list:
                state.data.list.length < 100
                  ? [...state.data.list, result]
                  : state.data.list,
            },
          }
        },
      }),
      duplicateResource: rj.mutation.single({
        effect: (wpAuth) => (resourceId, resourceData) => {
          return api
            .auth(wpAuth)
            .post(`/api/resource/${resourceId}/duplicate/`, resourceData)
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list:
                state.data.list.length < 100
                  ? [...state.data.list, result]
                  : state.data.list,
            },
          }
        },
      }),
      updateResource: rj.mutation.multi((resourceId) => resourceId, {
        effect: (wpAuth) => (resourceId, updatedData) =>
          api
            .auth(wpAuth)
            .patch(
              `/api/resource/${resourceId}/?embed_prices=yes&estimate=none`,
              updatedData
            ),
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.map((item) =>
                item.id !== result.id ? item : result
              ),
            },
          }
        },
      }),
      deleteResource: rj.mutation.multi((resourceId) => resourceId, {
        effect: (wpAuth) => (resourceId) =>
          api
            .auth(wpAuth)
            .mapResponse(() => resourceId)
            .delete(`/api/resource/${resourceId}/`),
        updater: (state, resourceId) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.filter((item) => item.id !== resourceId),
            },
          }
        },
      }),
    },
  }
)

export function useResourcesList(params) {
  return useRunRj(ResourcesListState, [params], false)
}

export function useSearchResources(params) {
  return useRj(ResourcesListState, [params], false)
}

import { useTranslation } from "react-i18next"
import { CRMLandmarks } from "../../components/CRMLandmarks"
import { InteractionList } from "../../components/InteractionsList"
import Layout from "../../components/Layout"

const EMPTY = {}

export default function BusinessInteractions() {
  const { t } = useTranslation(["translation", "tab", "action"])

  return (
    <Layout>
      <div className="container-fluid pt-5 px-page pb-page-bottom">
        <h1 className="text-primary font-weight-semibold mb-9">
          {t("business_interactions.title")}
        </h1>
        <InteractionList displayCustomer initial={EMPTY} />
      </div>
      <Layout.FirstLevelNavi>
        <CRMLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import classNames from "classnames"
import { Field, Formik } from "formik"
import React from "react"
import * as Yup from "yup"
import Button from "../../../components/Button"
import FieldInput from "../../../components/fields/FieldInput"
import { convertErrorToForm } from "../../../utils"
import S from "./Members.module.scss"

const MemberSchema = Yup.object({
  email: Yup.string().email().required(),
})

function InivitationRowForm({ save, idx, onAddClick, showAdd = false }) {
  return (
    <Formik
      validateOnMount
      onSubmit={(values, actions) =>
        save(idx, values).catch((error) => {
          actions.setErrors(convertErrorToForm(error))
        })
      }
      validationSchema={MemberSchema}
      initialValues={{
        email: "",
      }}
    >
      {({ handleSubmit, isValid, isSubmitting }) => (
        <tr>
          <td>
            <div className="d-flex align-items-center">
              {showAdd && (
                <Button className={classNames("mr-5", S["plus-row-btn"])} rounded onClick={onAddClick}>
                  {"+"}
                </Button>
              )}
              <Field autoComplete="off" type="email" name="email" containerClassName="flex-1" component={FieldInput} />
            </div>
          </td>
          <td>-</td>
          <td>-</td>
          <td />
          <td className="text-center">
            <Button disabled={!isValid || isSubmitting} onClick={handleSubmit}>
              Invita
            </Button>
          </td>
        </tr>
      )}
    </Formik>
  )
}

export default React.memo(InivitationRowForm)

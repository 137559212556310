import React from "react"
import classNames from "classnames"
import Button from "../Button"
import S from "./PlanCard.module.scss"
import { useTranslation } from "react-i18next"
import Icon from "../Icon"

export default function PlanCard({
  className = "",
  disabled,
  title,
  code,
  period,
  description,
  features,
  price,
  priceInfo,
  comingSoon,
  onClick,
}) {
  const { t } = useTranslation()
  return (
    <div
      className={classNames(S["plan-card"], `d-flex flex-column ${className}`)}
    >
      <div className={classNames(S["plan-card-top"], "text-center pt-6 pb-1")}>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
      <div className="d-flex flex-column justify-content-between flex-1">
        <div className="p-4">
          {features.map((feature, i) => (
            <div key={i} className="py-2">
              <Icon name="settings" className="text-primary" />
              {feature}
            </div>
          ))}
        </div>
        <div>
          <div className="d-flex justify-content-center py-4">
            <div className="d-flex align-items-center">
              {typeof price === "number" && (
                <>
                  <h1 className={S["price-title"]}>{price} €</h1>
                  {priceInfo && (
                    <span className="ml-3 mb-2 font-weight-semibold">
                      {priceInfo}
                    </span>
                  )}
                </>
              )}
              {typeof price === "string" && (
                <>
                  <h1>
                    <i>{price}</i>
                    <small>{' / '}<i>{t(`plans.period_${period}`)}</i></small>
                  </h1>
                </>
              )}
            </div>
          </div>
          <div className="d-flex mb-9">
            <Button
              disabled={disabled || comingSoon}
              className="w-75 mx-auto"
              onClick={() => onClick(code)}
            >
              {!comingSoon && t("plans.start")}
              {comingSoon && t("plans.coming_soon")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

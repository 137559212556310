import { useCallback, useMemo, useState } from "react"

/**
 * `useModalTrigger` semplifica la gestione delle modal che necessitano di un 
 * contesto per poter funzionare. Ad esempio, se consideriamo una situazione in
 * cui la pagina contiene una lista di oggetti e una modal di dettaglio, poter
 * eseguire il rendering della modal in modo condizionato al fatto che ci sia una
 * riga selezionata semplifica molto l'implementazione della modal stessa. Ai fini
 * di questo hook, il valore che comanda il render della modal è noto come `value`
 * (concettualmente, è un valore che "passiamo" alla modal)
 * 
 * La signature di questo hook è
 * const [{ isOpen, value }, { open, close, toggle, onClose }] = useModalTrigger()
 * 
 * Il tipico pattern di interazione con la modal è
 * 
 * <>
 *   {value && (
 *     <MyModal
 *       isOpen={isOpen}
 *       toggle={toggle}
 *       onClosed={onClose}
 *       someProp={value}
 *     />
 *   )}
 * </>
 * 
 * In questo modo la modal è montata solo se il contesto è disponibile, e aperta in base a `isOpen`
 * 
 * In fase di chiusura, la chiamata a `close()` rimuove il flag `isOpen`, attende l'animazione di chiusura,
 * il cui completamento è notificato dalla chiamata a `onClose`, e quindi rimuove il value smontando la modal
 * 
 * Per aprire la modal settando il value desiderato, chiamare `open(myValue)`
 */
export default function useModalTrigger() {
  const [state, setState] = useState({
    isOpen: false,
    value: null,
  })

  const open = useCallback(
    (value = null) =>
      setState({
        value,
        isOpen: true,
      }),
    []
  )

  const close = useCallback(
    () =>
      setState((s) => ({
        ...s,
        isOpen: false,
      })),
    []
  )

  const toggle = useCallback(
    () =>
      setState((s) => ({
        ...s,
        isOpen: !s.isOpen,
      })),
    []
  )

  const onClose = useCallback(
    () =>
      setState((s) => ({
        value: null,
        isOpen: false,
      })),
    []
  )

  const memoActions = useMemo(
    () => ({
      open,
      toggle,
      close,
      onClose,
    }),
    [open, toggle, close, onClose]
  )

  return [state, memoActions]
}

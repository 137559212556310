import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Route } from "react-router-dom"
import ChangeViewButton from "../../../components/ChangeViewButton"
import Layout from "../../../components/Layout"
import EstimateBottomBarContent from "../EstimateBottomBarContent"
import EstimateTabBarContent from "../EstimateTabBarContent"
import EstimateVizHome from "./EstimateVizHome/EstimateVizHome"
import EstimateBubble from "./EstimateBubble"
import EstimateTreeViz from "./EstimateTreeViz"
import EstimateScenarioViz from "./EstimateScenarioViz"
import { CurrentEstimateStateContext } from "../../../context"
import EstimateTreeMap from "./EstimateTreeMap"
import { EstimateLandmarks } from "../../../components/EstimateLandmarks"

export default function EstimateVisualization({ history, match }) {
  const { t } = useTranslation(["translation", "action"])
  const [{ fullEstimate, estimate }] = useContext(CurrentEstimateStateContext)
  return (
    <Layout>
      {estimate.task_tree.length > 0 && (
        <div className="container-fluid pt-5 px-half-page pb-page-bottom">
          <Route path={match.path} exact component={EstimateVizHome} />
          <Route path={`${match.path}/bubble`}>
            {estimate && <EstimateBubble estimate={estimate} />}
          </Route>
          <Route path={`${match.path}/tree`}>
            {estimate && <EstimateTreeViz estimate={estimate} />}
          </Route>
          <Route path={`${match.path}/treemap`}>
            {estimate && <EstimateTreeMap estimate={estimate} />}
          </Route>
          <Route path={`${match.path}/scenario`}>
            {estimate && (
              <EstimateScenarioViz
                estimate={estimate}
                fullEstimate={fullEstimate}
              />
            )}
          </Route>
        </div>
      )}
      {estimate.task_tree.length === 0 && (
        <div className="container-fluid pt-5 px-half-page pb-page-bottom">
          <p className="mt-6">{t("tasks.empty_viz_description")}</p>
        </div>
      )}

      <Layout.LeftBar>
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <ChangeViewButton
            match={match}
            path="bubble"
            iconName="graph"
            activeClassName="active"
            exact
            className="ml-4 mb-8"
            title={t("action:estimate_bubble")}
          />
          <ChangeViewButton
            match={match}
            path="tree"
            iconName="tree"
            activeClassName="active"
            exact
            className="ml-4 mt-2 mb-8"
            title={t("action:estimate_viz_tree")}
          />
          <ChangeViewButton
            match={match}
            path="treemap"
            iconName="treemap"
            activeClassName="active"
            exact
            className="ml-4 mt-2 mb-8"
            title={t("action:estimate_treemap")}
          />
          <ChangeViewButton
            match={match}
            path="scenario"
            iconName="scenario"
            activeClassName="active"
            exact
            className="ml-4 mt-2"
            title={t("action:estimate_scenario")}
          />
        </div>
      </Layout.LeftBar>
      <Layout.TabBar>
        <EstimateTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <EstimateBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <EstimateLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

export const defaultData = {
  maxDepth: 5,
  visibleTasks: null,
  showPrices: true,
  caption: null,
  enableCaption: false,
}

export { default as EditDataForm } from "./EditDataForm"
export { default as FragComponent } from "./FragComponent"
export { default as pageSplitter } from "./pageSplitter"

import React, { useMemo } from "react"
import classNames from "classnames"
import S from "./ScenarioTaskViz.module.scss"
import { useTranslation } from "react-i18next"
import ScenarioSymbol from "../../../../../components/ScenarioSymbol"
import { scaleLinear } from "d3-scale"
import { flattenTree } from "../../../EstimateTasksTree/TaskTable/utils"
import { ColorScheme } from "../../colorScheme"
import { chunk } from "lodash"
import useCurrencyFormatter from "../../../../../hooks/useCurrencyFormatter"

function ScenarioTaskViz({
  estimate,
  scenario,
  width,
  maxPrice,
  isPriceVisible,
  isNormalized,
}) {
  const { t } = useTranslation(["translation"])
  const currencyFmt = useCurrencyFormatter()
  
  const isBase = scenario === null

  const scale = scaleLinear()
    .domain([0, maxPrice])
    .range([0, width - 180])

  const colorScheme = useMemo(() => {
    return new ColorScheme("sequential", 1)
  }, [])

  return (
    <div className="w-100 h-100 d-flex flex-column pb-7">
      <div
        className={classNames(
          "d-flex flex-row align-items-center justify-content-start",
          S["viz-container"]
        )}
      >
        <div
          className={classNames(
            "d-flex flex-column align-items-end text-right",
            S["title"]
          )}
        >
          <span className="h2 font-weight-semibold prevent-overflow">
            {!isBase && scenario.name}
            {isBase && t("documents.frag.scenario_list.scenario_base")}
          </span>
          <span className="h3 font-weight-semibold">
            {currencyFmt.format(estimate.price)}
          </span>
          {!isBase && <ScenarioSymbol scenario={scenario} />}
          {isBase && (
            <span className="badge badge-separator ml-4 py-2">
              {t("scenarios.base")}
            </span>
          )}
        </div>
        <div
          className={classNames(S["first-level-container"], "h-100 mx-4")}
          style={{
            width: isNormalized
              ? scale(maxPrice) + 6
              : scale(estimate.price) + 6,
          }}
        >
          {estimate.task_tree.map((firstLevTask) => {
            const index = parseInt(firstLevTask.path.split(".")[0], 10) - 1
            const matches = firstLevTask.matchesScenarios !== false
            return (
              <div
                className={classNames(
                  S["first-level"],
                  {
                    [colorScheme.texture(0, index)]: !matches,
                    "bg-white": !matches,
                    [colorScheme.background(0, index)]: matches,
                  },
                  "h-100",
                  colorScheme.border(0, index)
                )}
                style={{
                  width: isNormalized
                    ? scale(parseFloat(firstLevTask.fullTaskPrice))
                    : scale(parseFloat(firstLevTask.price)),
                }}
              >
                {parseFloat(firstLevTask.own_price) !== 0 && (
                  <div
                    className={classNames(
                      S["inner-level"],
                      "h-100",
                      colorScheme.border(0, parseInt(index, 10))
                    )}
                    style={{
                      width: scale(parseFloat(firstLevTask.own_price)),
                    }}
                  ></div>
                )}
                {flattenTree(firstLevTask.children).map((elem) => {
                  const childMatches = elem.matchesScenarios !== false
                  if (parseFloat(elem.own_price) !== 0) {
                    return (
                      <div
                        className={classNames(
                          S["inner-level"],
                          {
                            [colorScheme.texture(0, index)]:
                              matches && !childMatches,
                            "bg-white": matches && !childMatches,
                            [colorScheme.background(0, index)]:
                              matches && childMatches,
                          },
                          "h-100",
                          colorScheme.border(0, parseInt(index, 10))
                        )}
                        style={{
                          width: scale(parseFloat(elem.own_price)),
                        }}
                      ></div>
                    )
                  } else {
                    return null
                  }
                })}
              </div>
            )
          })}
        </div>
      </div>
      <div
        className="d-flex flex-row align-items-center"
        style={{ marginLeft: 162 }}
      >
        {estimate.task_tree.map((firstLevTask) => {
          return (
            <div
              className={classNames("text-center")}
              style={{
                width: isNormalized
                  ? scale(parseFloat(firstLevTask.fullTaskPrice))
                  : scale(parseFloat(firstLevTask.price)),
              }}
            >
              {scale(
                parseFloat(
                  isNormalized ? firstLevTask.fullTaskPrice : firstLevTask.price
                )
              ) > 80 && (
                <>
                  <span
                    style={{
                      maxWidth:
                        scale(
                          parseFloat(
                            isNormalized
                              ? firstLevTask.fullTaskPrice
                              : firstLevTask.price
                          )
                        ) - 32,
                    }}
                    className="prevent-overflow mx-5 font-weight-semibold "
                  >
                    {firstLevTask.title}
                  </span>
                  {isPriceVisible && (
                    <span>{currencyFmt.format(firstLevTask.price)}</span>
                  )}
                </>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

function Legend({ estimate, columns = 1 }) {
  const { t } = useTranslation()
  const colorScheme = useMemo(() => {
    return new ColorScheme("sequential", 1)
  }, [])

  const chunks = chunk(
    estimate.task_tree,
    Math.ceil(estimate.task_tree.length / columns)
  )

  return (
    <div className="d-flex flex-row align-items-start">
      {chunks.map((chunk, n) => (
        <div className="flex-1">
          <div style={{ opacity: n === 0 ? 1 : 0 }}>
            <b>{t("viz.legend")}</b>
          </div>
          {chunk.map((task) => {
            const index = parseInt(task.path.split(".")[0], 10) - 1
            return (
              <div
                key={task.id}
                className="d-flex flex-row justify-content-start align-items-center mb-3"
              >
                <div
                  className={classNames(
                    S["legend-square"],
                    colorScheme.border(0, index),
                    colorScheme.background(0, index)
                  )}
                />
                {task.title}
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

ScenarioTaskViz.Legend = Legend

export default ScenarioTaskViz

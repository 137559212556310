import React from "react"
import classNames from "classnames"
import WpSwitch from "../WpSwitch/WpSwitch"

export default function FieldSwitch({
  label,
  labelClassname,
  message,
  field,
  form,
  disabled,
  readOnly,
  className,
  ...props
}) {
  return (
    <div className={classNames("d-flex flex-column", className)}>
      {label && (
        <label
          className={classNames(
            "font-weight-semibold mb-3 h3 text-uppercase",
            labelClassname
          )}
        >
          {label}
        </label>
      )}
      <div className="d-flex flex-row justify-content-between align-items-center">
        <span>{message}</span>
        <WpSwitch
          checked={!!field.value}
          onChange={(value) => form.setFieldValue(field.name, value)}
          disabled={readOnly || disabled}
        />
      </div>
    </div>
  )
}

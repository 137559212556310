import React from "react"
import classNames from "classnames"
import { range } from "lodash"
import Icon from "../Icon"

function PageNumber({ active = false, page, goToPage }) {
  return (
    <span
      className={classNames(
        {
          "text-primary border-bottom border-1 border-primary": active,
          pointer: !active,
        },
        "px-2 mx-2"
      )}
      onClick={() => goToPage(page)}
    >
      {page}
    </span>
  )
}

function PageDots() {
  return <Icon name="more" className="px-2 mx-2 text-separator" />
}

export default function PageNavigator({
  current,
  pageCount,
  goToPage,
  hasNext,
  hasPrev,
}) {
  let pagesToSee = []
  if (pageCount > 7) {
    if (current < 5) {
      pagesToSee.push(
        ...range(1, 7).map((i) => (
          <PageNumber
            key={i}
            active={current === i}
            page={i}
            goToPage={goToPage}
          />
        ))
      )
      pagesToSee.push(<PageDots key="right" />)
    } else if (pageCount - current < 5) {
      pagesToSee.push(<PageDots key="left" />)
      pagesToSee.push(
        ...range(pageCount - 5, pageCount + 1).map((i) => (
          <PageNumber
            key={i}
            active={current === i}
            page={i}
            goToPage={goToPage}
          />
        ))
      )
    } else {
      pagesToSee.push(<PageDots key="left" />)
      pagesToSee.push(
        ...range(current - 2, current + 3).map((i) => (
          <PageNumber
            key={i}
            active={current === i}
            page={i}
            goToPage={goToPage}
          />
        ))
      )
      pagesToSee.push(<PageDots key="right" />)
    }
  } else {
    pagesToSee.push(
      ...range(1, pageCount + 1).map((i) => (
        <PageNumber
          key={i}
          active={current === i}
          page={i}
          goToPage={goToPage}
        />
      ))
    )
  }

  return (
    <div className="d-flex flex-row justify-content-between align-items-center">
      <Icon
        name="arrow-left"
        className={classNames(
          { "text-dark pointer": hasPrev, "text-separator": !hasPrev },
          "mr-3"
        )}
        onClick={() => {
          if (hasPrev) {
            goToPage(current - 1)
          }
        }}
      />
      <div className="d-flex flex-row">{pagesToSee}</div>
      <Icon
        name="arrow-right"
        className={classNames(
          { "text-dark pointer": hasNext, "text-separator": !hasNext },
          "ml-3"
        )}
        onClick={() => {
          if (hasNext) {
            goToPage(current + 1)
          }
        }}
      />
    </div>
  )
}

import { Field, Formik } from "formik"
import { mapValues } from "lodash"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import Button from "../../components/Button"
import FieldInput from "../../components/fields/FieldInput"
import FieldTextarea from "../../components/fields/FieldTextarea"
import WpModal from "../../components/WpModal"
import { convertErrorToForm } from "../../utils"

const INITIAL_VALUES = {
  date: "",
  description: "",
}

export function EditDeadlineModal({
  isOpen,
  toggle,
  onSave,
  onClosed,
  deadline,
}) {
  const { t } = useTranslation(["translation", "field", "action"])

  const schema = yup.object().shape({
    date: yup
      .date()
      .typeError(
        t("field:errors.date", {
          field: t("field:project_activity.date_ref"),
        })
      )
      .required(
        t("field:errors.mandatory", { field: t("field:project.name") })
      ),
    description: yup.string().required(),
  })

  return (
    <WpModal
      onClosed={onClosed}
      isOpen={isOpen}
      toggle={toggle}
      title={
        deadline?.id
          ? t("action:edit_project_deadline")
          : t("action:new_project_deadline")
      }
    >
      <div>
        <div className="d-flex flex-row align-items-start my-4">
          <div className="d-flex flex-column ml-2 flex-1">
            <Formik
              initialValues={{ ...INITIAL_VALUES, ...deadline }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                return onSave(
                  mapValues(values, (val) => (val === undefined ? null : val))
                ).catch((error) => {
                  actions.setErrors(convertErrorToForm(error))
                })
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <>
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Field
                      name="date"
                      component={FieldInput}
                      type="date"
                      label={t("field:deadline.date")}
                      className={"mb-5"}
                    />
                    <Field
                      name="description"
                      component={FieldTextarea}
                      className={"mb-5"}
                      placeholder={t("field:deadline.placeholder.description")}
                      label={t("field:deadline.description")}
                    />
                    <Field
                      name="completion_percent"
                      component={FieldInput}
                      type="number"
                      min="0"
                      max="100"
                      step="1"
                      className={"mb-5"}
                      placeholder={t(
                        "field:deadline.placeholder.completion_percent"
                      )}
                      label={t("field:deadline.completion_percent")}
                    />
                    <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                      <Button size="sm" color="none" onClick={toggle}>
                        {t("action:cancel")}
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        type="submit"
                        className="ml-5"
                        disabled={!formik.isValid}
                      >
                        {deadline?.id ? t("action:save") : t("action:add")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </WpModal>
  )
}

import * as Msal from "msal"

const msalConfig = {
  auth: {
    clientId: "ee593f31-1fa5-4cbf-a604-ce27d8a42e07",
    redirectUri: "http://localhost:3000/",
  },
}

const msalInstance = new Msal.UserAgentApplication(msalConfig)

export default msalInstance

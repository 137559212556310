import dayjs from "dayjs"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useComplianceIssueReports } from "../hooks/complianceIssueReport"
import useModalTrigger from "../hooks/useModalTrigger"
import Button from "./Button"
import { CreateReportModal } from "./CreateReportModal"
import Icon from "./Icon"
import Loader from "./Loader"
import LongBorder from "./LongBorder"
import Pagination from "./Pagination/Pagination"
import Table from "./Table"
import WpInput from "./WpInput"
import WpLink from "./WpLink"


export function ComplianceIssueReportList({ filters, defaults, showProject = false, showCustomer = false }) {
  const [
    { page, fromDate, toDate, ordering },
    setQueryParams,
    { page: debPage, fromDate: debFromDate, toDate: debToDate, ordering: debOrdering },
    setDebQueryParams,
  ] = useQueryParams({
    page: {
      encode: (intValue) => intValue.toString(10),
      decode: (strValue) => (strValue ? parseInt(strValue, 10) : 1),
    },
  })

  const reportsFilters = useMemo(
    () => ({
      ...filters,
      page: debPage,
      detection_date__gte: debFromDate,
      detection_date__lte: debToDate,
      ordering: debOrdering,
    }),
    [debFromDate, debPage, debToDate, filters, debOrdering]
  )

  const { t } = useTranslation()

  const [{ reports, hasNext, hasPrev, pageCount }, { create, run: reload }] = useComplianceIssueReports(reportsFilters)

  const [createModalState, createModalActions] = useModalTrigger()

  let descriptionWidth = 17
  if (showCustomer) {
    descriptionWidth -= 3
  }
  if (showProject) {
    descriptionWidth -= 3
  }

  const columns = useMemo(() => {
    return [
      {
        label: t("field:complianceIssueReport.id"),
        name: "code",
        size: 2,
        orderingField: "code",
      },
      {
        label: t("field:complianceIssueReport.detection_date"),
        name: "detection_date",
        size: 3,
        render: (selectedDatum) => dayjs(selectedDatum).format("DD/MM/YYYY"),
        orderingField: "detection_date",
      },
      {
        label: t("field:complianceIssueReport.project_name"),
        name: "project_name",
        size: 3,
        visible: showProject,
        render: (selectedDatum, colName, datum) => <WpLink to={`/projects/${datum.project}`}>{selectedDatum}</WpLink>,
      },
      {
        label: t("field:complianceIssueReport.customer_name"),
        name: "customer_name",
        size: 3,
        visible: showCustomer,
        render: (selectedDatum, colName, datum) => <WpLink to={`/customers/${datum.customer}`}>{selectedDatum}</WpLink>,
        orderingField: "project__customer__name",
      },
      {
        label: t("field:complianceIssueReport.description"),
        name: "description",
        size: descriptionWidth,
        orderingField: "description",
      },
      {
        label: t("actions"),
        name: "actions",
        size: 2,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <div className={"action-icon-container-primary-hover"}>
                <WpLink to={`/projects/${datum.project}/compliance-issue-report/${datum.id}`}>
                  <Icon name="edit" title={t("action:edit_comliance_issue_report")} className="pointer text-dark" />
                </WpLink>
              </div>
            </div>
          )
        },
      },
    ].filter((col) => col.visible !== false)
  }, [descriptionWidth, showCustomer, showProject, t])

  return (
    <div className="container-fluid pt-5 px-page pb-page-bottom">
      <div className="d-flex flex-row justify-content-between align-items-center mb-6">
        <h1 className="text-primary font-weight-semibold">{t("project_compliance_issues_reports.title")}</h1>
        <div>
          {defaults && (
            <Button
              className="d-inline-flex align-items-center px-5 mr-5"
              onClick={() => {
                createModalActions.open(defaults)
              }}
            >
              <Icon name="plus-rounded" className="mr-2" height={16} width={16} />
              {t("action:new_compliance_issue_report")}
            </Button>
          )}
        </div>
      </div>

      <div className="mt-5 mb-8 d-flex flex-row justify-content-start align-items-center flex-1">
        <b className="mr-8">{t("customer_interactions.filters")}</b>
        <div
          className="d-flex flex-row justify-content-start align-items-center flex-1 flex-wrap"
          style={{ rowGap: 16, columnGap: 32 }}
        >
          <div style={{ whiteSpace: "nowrap" }}>
            <span className="mr-3">{t("customer_interactions.period_filter_period")}</span>
            <br />
            <span className="mr-3">{t("customer_interactions.period_filter_from")}</span>
            <WpInput
              medium
              type="date"
              value={fromDate}
              onChange={(e) => setDebQueryParams({ fromDate: e.target.value, page: 1 })}
            />
            <span className="mx-3">{t("customer_interactions.period_filter_to")}</span>
            <WpInput
              medium
              type="date"
              value={toDate}
              onChange={(e) => setDebQueryParams({ toDate: e.target.value, page: 1 })}
            />
          </div>
        </div>
      </div>

      {!reports && <Loader />}
      {reports && (
        <>
          <LongBorder topBar="tabbar" />
          <Table
            data={reports}
            columns={columns}
            stickyHeader
            stickyTopBar="tabbar"
            className="pb-8"
            valueCheckFn={(val) => val !== undefined && val !== null}
            use24
            ordering={ordering}
            onOrderChange={(val) => {
              setQueryParams({ ordering: val, page: 1 })
            }}
          />
          {reports.length > 0 && (
            <Pagination
              current={page}
              pageCount={pageCount}
              goToPage={(page) => {
                setQueryParams({ page })
                window.scrollTo(0, 10)
              }}
              hasNext={hasNext}
              hasPrev={hasPrev}
            />
          )}
        </>
      )}
      {createModalState.value && (
        <CreateReportModal
          isOpen={createModalState.isOpen}
          report={createModalState.value}
          onClosed={createModalActions.onClose}
          toggle={createModalActions.toggle}
          onSave={(result) => {
            return create
              .onSuccess(() => {
                createModalActions.close()
                reload(reportsFilters)
              })
              .asPromise(result)
          }}
        />
      )}
    </div>
  )
}

import React, { useMemo } from "react"
import RichTextEditor from "../../../../../components/RichTextEditor/RichTextEditor"
import S from "./FragComponent.module.scss"
import classNames from "classnames"

const FragComponent = ({
  frag,
  estimate,
  fullWidth,
  fullHeight,
  isPdf,
  logoUrl,
}) => {
  const ctx = useMemo(() => ({ estimate }), [estimate])

  let backgroundImage
  let backgroundColor
  let wrapperEditorClassName

  if (frag.data.coverImage) {
    backgroundImage = `url(${frag.data.coverImage})`
    wrapperEditorClassName = "cover-force-bg-transparent"
  }

  if (frag.data.coverColor) {
    backgroundColor = frag.data.coverColor
  }

  return (
    <div
      className={S["cover"]}
      style={{
        width: fullWidth,
        height: fullHeight * ((frag?.data?.pageFillFactor ?? 100) / 100),
        backgroundImage,
        backgroundColor,
      }}
    >
      <div
        className={classNames(
          "flex-1 d-flex flex-column justify-content-center align-items-center",
          S["main"]
        )}
      >
        {frag.data?.displayLogo && logoUrl && (
          <img
            className="mb-8"
            src={logoUrl}
            style={{
              width: frag.data?.logoWidth,
              height: frag.data?.logoHeight,
            }}
            alt="Logo"
          />
        )}
        {frag.data?.displayLogo && !logoUrl && !isPdf && (
          <div
            className="mb-8 d-flex flex-column justify-content-center align-items-center"
            style={{
              width: frag.data?.logoWidth,
              height: frag.data?.logoHeight,
              borderRadius: "50%",
              border: "1px solid var(--separator)",
              color: "var(--separator)",
            }}
          >
            {"No uploaded logo"}
          </div>
        )}
        <div className="w-100">
          <RichTextEditor
            content={frag?.data?.mainText}
            readOnly={true}
            placeholder={""}
            minHeight0
            templatingContext={ctx}
            wrapperClassName={wrapperEditorClassName}
          />
        </div>
      </div>
      <div
        className={classNames(
          "d-flex flex-row justify-content-start align-items-stretch w-100",
          S["footer"]
        )}
      >
        <div style={{ flex: "1 1 0%", minWidth: 0, paddingRight: 4 }}>
          <RichTextEditor
            content={frag?.data?.footerText1}
            readOnly={true}
            placeholder={""}
            minHeight0
            templatingContext={ctx}
            wrapperClassName={wrapperEditorClassName}
          />
        </div>
        {frag.data?.footerCols && (
          <div style={{ flex: "1 1 0%", minWidth: 0, paddingLeft: 4 }}>
            <RichTextEditor
              content={frag?.data?.footerText2}
              readOnly={true}
              placeholder={""}
              minHeight0
              templatingContext={ctx}
              wrapperClassName={wrapperEditorClassName}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default FragComponent

import React from "react"
import { useProjectTeam } from "../../hooks/projectTeam"
import DropdownList from "../DropdownList"


export default function ProjectTeamMemberSelector({
  projectId,
  memberId,
  onMemberChange,
  clearable,
  extraOptions = [],
  ...props
}) {
  const [
    { data: members },
  ] = useProjectTeam(projectId)

  const memberOptions =
    members?.map((member) => ({
      label: member.user.full_name || member.user.email,
      value: member.user.id,
    })) ?? []

  const options = [...extraOptions, ...memberOptions]

  let caption = undefined

  if (memberId) {
    caption = options.find((opt) => opt.value === memberId)?.label
  }

  return (
    <DropdownList
      {...props}
      value={memberId}
      caption={caption}
      options={options}
      onChange={(nextMemberId) => {
        const nextMember = members.find((t) => t.user.id === nextMemberId)
        onMemberChange(nextMember)
      }}
      onSearchChange={(searchStr) => {
      }}
      clearable={clearable}
      onClear={() => {
        onMemberChange(null)
      }}
    />
  )
}

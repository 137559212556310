import { useParams } from "react-router-dom"
import { BusinessInteractionDetail } from "../../../components/BusinessInteractionDetail"
import { CRMLandmarks } from "../../../components/CRMLandmarks"
import Layout from "../../../components/Layout"
import CustomerBottomBarContent from "../CustomerBottomBarContent"
import CustomerTabBarContent from "../CustomerTabBarContent"

export default function CustomerInteractionDetail() {
  const { interactionID, operation } = useParams()

  return (
    <Layout>
      <BusinessInteractionDetail interactionId={interactionID} initialEditMode={operation === "edit"} enableEditMode />
      <Layout.TabBar>
        <CustomerTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <CustomerBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <CRMLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

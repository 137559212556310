export const defaultData = {
  maxDepth: 5,
  isDescriptionVisible: true,
  isPriceVisible: true,
  isResourcesVisible: true,
  visibleTasks: null,
  isScenarioVisible: true,
}

export { default as EditDataForm } from "./EditDataForm"
export { default as FragComponent } from "./FragComponent"
export { default as pageSplitter } from "./pageSplitter"

import { isNil } from "lodash"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import React from "react"
import { useTranslation } from "react-i18next"
import { Card, CardBody, CardDeck } from "reactstrap"
import { IndicatorsLandmarks } from "../../components/IndicatorsLandmarks"
import Layout from "../../components/Layout"
import WpInput from "../../components/WpInput"
import { useIndicators } from "../../hooks/indicators"
import { useAnalysisFilters } from "../../hooks/useAnalysisFilters"

export function Indicators() {
  const { t } = useTranslation()
  const { filters, renderFilters } = useAnalysisFilters()

  const [{ fromDate, toDate }, , { fromDate: debFromDate, toDate: debToDate }, setDebQueryParams] = useQueryParams()

  const [{ data }] = useIndicators(debFromDate, debToDate, filters)

  return (
    <Layout>
      <div className="container-fluid pt-8 px-page pb-page-bottom">
        <h1 className="text-primary font-weight-semibold">{t("indicators.title")}</h1>
        <div className="d-flex flex-row justify-content-start align-items-start">
          <div
            className="d-flex flex-row justify-content-start align-items-center flex-1 flex-wrap"
            style={{ rowGap: 16, columnGap: 32 }}
          >
            <div style={{ whiteSpace: "nowrap" }}>
              <span className="mr-3">{t("indicators.period_filter_from")}</span>
              <WpInput
                medium
                type="date"
                value={fromDate}
                onChange={(e) => setDebQueryParams({ fromDate: e.target.value, page: 1 })}
              />
              <span className="mx-3">{t("indicators.period_filter_to")}</span>
              <WpInput
                medium
                type="date"
                value={toDate}
                onChange={(e) => setDebQueryParams({ toDate: e.target.value, page: 1 })}
              />
            </div>
            <div style={{ whiteSpace: "nowrap" }}>{renderFilters()}</div>
          </div>
        </div>
        {data && (
          <>
            <CardDeck className="mt-9">
              <Card size="sm">
                <CardBody className="text-center">
                  <p>{t("indicators.billed_amount")}</p>
                  <h1>{formatEuro(data.billed_amount)}</h1>
                </CardBody>
              </Card>
              <Card size="sm">
                <CardBody className="text-center">
                  <p>{t("indicators.amount_to_bill")}</p>
                  <h1>{formatEuro(data.amount_to_bill)}</h1>
                </CardBody>
              </Card>
              <Card size="sm">
                <CardBody className="text-center">
                  <p>{t("indicators.amount_to_bill_potential")}</p>
                  <h1>{formatEuro(data.amount_to_bill_potential, t)}</h1>
                </CardBody>
              </Card>
            </CardDeck>
            <CardDeck className="mt-9">
              <Card size="sm">
                <CardBody className="text-center">
                  <p>{t("indicators.approved_estimates_amount")}</p>
                  <h1>{formatEuro(data.approved_estimates_amount)}</h1>
                </CardBody>
              </Card>
              <Card size="sm">
                <CardBody className="text-center">
                  <p>{t("indicators.average_project_margin")}</p>
                  <h1>{formatPercent(data.average_project_margin)}</h1>
                </CardBody>
              </Card>
              <Card size="sm">
                <CardBody className="text-center">
                  <p>{t("indicators.average_estimate_time")}</p>
                  <h1>{formatDays(data.average_estimate_time, t)}</h1>
                </CardBody>
              </Card>
            </CardDeck>
            <CardDeck className="mt-7">
              <Card size="sm">
                <CardBody className="text-center">
                  <p>{t("indicators.won_contests_percent")}</p>
                  <h1>{formatPercent(data.won_contests_percent)}</h1>
                </CardBody>
              </Card>
              <Card size="sm">
                <CardBody className="text-center">
                  <p>{t("indicators.projects_closed_in_time_percent")}</p>
                  <h1>{formatPercent(data.projects_closed_in_time_percent)}</h1>
                </CardBody>
              </Card>
              <Card size="sm">
                <CardBody className="text-center"></CardBody>
              </Card>
            </CardDeck>
          </>
        )}
      </div>
      <Layout.FirstLevelNavi>
        <IndicatorsLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

function formatEuro(value) {
  if (isNil(value)) {
    return <i>Non calcolabile</i>
  } else {
    return <span>{parseFloat(value).toFixed(2) + " €"}</span>
  }
}

function formatDays(value, t) {
  if (isNil(value)) {
    return <i>Non calcolabile</i>
  } else {
    return <span>{value + " " + t("days")}</span>
  }
}

function formatPercent(value) {
  if (isNil(value)) {
    return <i>Non calcolabile</i>
  } else {
    return <span>{parseFloat(value).toFixed(2) + " %"}</span>
  }
}

import { Field } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import FieldSwitch from "../../../../../components/fields/FieldSwitch"
import EditDataFrame, {
  EditDataSection,
} from "../../EditDataFrame/EditDataFrame"

export const EditDataForm = ({
  fullEstimate,
  frag,
  save,
  remove,
  toggle,
  scrollFragIntoView,
}) => {
  const { t } = useTranslation(["translation"])
  const readOnly = fullEstimate.readonly

  return (
    <EditDataFrame
      contentType={t("documents.frag.task.table.title")}
      frag={frag}
      save={save}
      remove={remove}
      toggle={toggle}
      scrollFragIntoView={scrollFragIntoView}
      readOnly={readOnly}
      enableScenarioSelector={false}
    >
      <EditDataSection>
        <div className="mt-4">
          <Field
            name="data.displayDates"
            message={t("field:document.billing_frag.display_dates_message")}
            component={FieldSwitch}
            readOnly={readOnly}
          />
        </div>
      </EditDataSection>
    </EditDataFrame>
  )
}

export default EditDataForm

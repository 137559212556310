import React, { useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import { ProjectContext } from "../../../context"
import ProjectBottomBarContent from "../ProjectBottomBarContent"
import ProjectTabBarContent from "../ProjectTabBarContent"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import { useProjectTaskList } from "../../../hooks/projectTasks"
import LongBorder from "../../../components/LongBorder"
import Pagination from "../../../components/Pagination/Pagination"
import Table from "../../../components/Table"
import ProgressChart from "../../../components/ProgressChart"
import Button from "../../../components/Button/Button"
import Icon from "../../../components/Icon"
import classNames from "classnames"
import S from "../CommonStyle.module.scss"
import WpInput from "../../../components/WpInput"
import ProjectActivityModal from "../../../components/ProjectActivityModal/ProjectActivityModal"
import useModalTrigger from "../../../hooks/useModalTrigger"
import { useAuthUser } from "use-eazy-auth"
import CreateTaskModal from "./CreateTaskModal"
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown"
import DropdownToggle from "reactstrap/lib/DropdownToggle"
import DropdownMenu from "reactstrap/lib/DropdownMenu"
import DropdownItem from "reactstrap/lib/DropdownItem"
import { TrackerLandmarks } from "../../../components/TrackerLandmarks"
import { sortBy } from "lodash"
import dayjs from "dayjs"
import { FaPencilAlt, FaPlusCircle, FaTrashAlt } from "react-icons/fa"
import { ProjectTaskSprintModal } from "../../../components/ProjectTaskSprintModal"
import useWpHistoryMethods from "../../../hooks/useWpHistoryMethods"
import { RiFlowChart } from "react-icons/ri"

export default function ProjectTasks() {
  const history = useWpHistoryMethods()
  const { t } = useTranslation(["translation", "field", "action"])
  const { user } = useAuthUser()

  const [
    { search, assignedToMe },
    setQueryParams,
    { page: debPage, search: debSearch, assignedToMe: debAssignedToMe },
    setDebQueryParams,
  ] = useQueryParams({
    page: {
      encode: (intValue) => intValue.toString(10),
      decode: (strValue) => (strValue ? parseInt(strValue, 10) : 1),
    },
  })

  const [{ project }] = useContext(ProjectContext)

  const filters = useMemo(
    () => ({
      scope_project: project.id,
      page: debPage,
      search: debSearch,
      assigned_to_me: debAssignedToMe,
    }),
    [project.id, debPage, debSearch, debAssignedToMe]
  )

  const [displayFilters, setDisplayFilters] = useState(false)

  const [createActivityModal, createActivityModalActions] = useModalTrigger()
  const [taskModal, taskModalActions] = useModalTrigger()
  const [removeTaskModal, removeTaskModalActions] = useModalTrigger()
  const [changeSprintModalState, changeSprintModalActions] = useModalTrigger()

  const [
    { tasks, pageCount, hasNext, hasPrev, count, fullCount },
    { createActivity, create, update, remove, run: refresh, createSprint, updateSprint, removeSprint },
  ] = useProjectTaskList(filters)

  const columns = useMemo(() => {
    return [
      {
        label: t("field:project_task.completion"),
        name: "completion",
        size: 3,
        render: (selectedDatum, index, datum) => {
          return <ProgressChart percent={selectedDatum} innerRadius={15} outerRadius={20} textColor="black" />
        },
      },
      {
        label: t("field:project_task.code"),
        name: "code",
        size: 4,
        render: (selectedDatum, index, datum) => {
          return (
            <div className="d-flex flex-column justify-content-start align-items-start">
              {datum.estimate_code && <small>{datum.estimate_code}</small>}
              <span>{datum.code}</span>
            </div>
          )
        },
      },
      {
        label: t("field:project_task.title"),
        name: "title",
        size: 8,
      },
      {
        label: t("field:project_task.sprints"),
        name: "sprints",
        size: 4,
        render: (selectedDatum, index, datum) => {
          return (
            <div className="d-flex flex-column justify-content-start align-items-start">
              {sortBy(selectedDatum, "date_start").map((sprint) => (
                <div key={sprint.id}>
                  {dayjs(sprint.date_start).format("DD/MM/YYYY")}
                  {" - "}
                  {dayjs(sprint.date_end).format("DD/MM/YYYY")}
                  <span
                    className="pointer ml-3"
                    onClick={() => {
                      changeSprintModalActions.open(sprint)
                    }}
                  >
                    <FaPencilAlt />
                  </span>
                  <span
                    className="pointer ml-3"
                    onClick={() => {
                      removeSprint(datum.id, sprint.id)
                    }}
                  >
                    <FaTrashAlt />
                  </span>
                </div>
              ))}
              <span
                className="pointer"
                onClick={() => {
                  changeSprintModalActions.open({
                    project_task: datum.id,
                    date_start: project.date_begin,
                    date_end: project.estimated_delivery_date,
                  })
                }}
              >
                <FaPlusCircle />
              </span>
            </div>
          )
        },
      },
      {
        label: t("field:project_task.activity_count"),
        name: "activity_count",
        size: 2,
      },
      {
        label: t("actions"),
        name: "actions",
        size: 3,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <div className={"action-icon-container-primary-hover"}>
                <Icon
                  name="project-activity-add"
                  title={t("action:add_project_activity")}
                  className="pointer text-dark"
                  disabled={!datum.is_assigned_to_me || !project.accept_activities}
                  onClick={() => {
                    if (datum.is_assigned_to_me && project.accept_activities) {
                      createActivityModalActions.open({
                        project: datum.project,
                        project_task: datum.id,
                        subject: user.id,
                      })
                    }
                  }}
                />
              </div>
              <div className={"action-icon-container-primary-hover"}>
                <Icon
                  name="edit"
                  title={t("action:edit_project_task")}
                  className="pointer text-dark"
                  onClick={() => {
                    taskModalActions.open(datum)
                  }}
                />
              </div>
              <div className={"action-icon-container-primary-hover"}>
                <UncontrolledDropdown>
                  <DropdownToggle caret={false} tag={"span"}>
                    <Icon
                      role="button"
                      name="vdots"
                      className="text-dark pointer"
                      title={t("action:other_options")}
                      placement="right"
                    />
                  </DropdownToggle>
                  <DropdownMenu right modifiers={{ offset: { offset: "0, 12" } }} className="border-primary">
                    {!datum.archived && (
                      <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                        <div
                          className="d-flex flex-row align-items-center mx-4"
                          onClick={() => removeTaskModalActions.open(datum)}
                        >
                          <Icon name="delete" className={classNames("pointer mr-4")} />
                          <span>{t("action:delete")}</span>
                        </div>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )
        },
      },
    ]
  }, [
    t,
    changeSprintModalActions,
    removeSprint,
    project.date_begin,
    project.estimated_delivery_date,
    project.accept_activities,
    createActivityModalActions,
    user.id,
    taskModalActions,
    removeTaskModalActions,
  ])

  return (
    <Layout>
      <div className="container-fluid pt-5 px-page pb-page-bottom">
        <div className="d-flex flex-row justify-content-between align-items-center mb-6">
          <div className="d-flex flex-row aling-items-center">
            <Button
              className="d-inline-flex align-items-center px-5 mr-5"
              onClick={() => {
                taskModalActions.open({ project: project.id })
              }}
            >
              <Icon name="plus-rounded" className="mr-2" height={16} width={16} />
              {t("action:add_project_task")}
            </Button>
            <Button
              className="d-inline-flex align-items-center px-5 mr-5"
              active={displayFilters}
              onClick={() => setDisplayFilters((v) => !v)}
            >
              <Icon name="filter" className="mr-2" height={16} width={16} />
              {t("action:filter_project_tasks")}
            </Button>
            <Button
              className="d-inline-flex align-items-center px-5 mr-5"
              onClick={() => history.push(`/projects/${project.id}/gantt`)}
            >
              <RiFlowChart className="mr-2" />
              {t("action:display_gantt")}
            </Button>
          </div>
          <div>
            {t("project_task_list.task_count", {
              filtered: count,
              count: fullCount,
            })}
          </div>
        </div>
        {displayFilters && (
          <div className="d-flex flex-row justify-content-start align-items-center mb-6">
            <div className="mr-8">
              <label className={classNames(S["filter-label"], "d-block")}>{t("project_task_list.search_filter")}</label>
              <WpInput
                medium
                className="mr-5"
                placeholder={t("project_task_list.search_filter_placeholder")}
                value={search}
                onChange={(e) => setDebQueryParams({ search: e.target.value })}
              />
            </div>
            <div className="mr-8">
              <label className={classNames(S["filter-label"], "d-block")}>{t("project_task_list.assign_filter_label")}</label>
              <div className="d-flex flex-row justify-content-start align-items-center" style={{ padding: "5px 0" }}>
                <input
                  type="checkbox"
                  checked={assignedToMe === "true"}
                  onChange={(e) =>
                    setQueryParams({
                      assignedToMe: e.target.checked ? "true" : undefined,
                    })
                  }
                />
                <label className="my-0 mx-4">{t("project_task_list.assigned_to_me_filter")}</label>
              </div>
            </div>
          </div>
        )}
        {(!project || !tasks) && <Loader />}
        {project && tasks && (
          <>
            <LongBorder topBar="tabbar" />
            <Table
              data={tasks}
              columns={columns}
              use24
              stickyHeader
              stickyTopBar="tabbar"
              className="pb-8"
              valueCheckFn={(val) => val !== undefined && val !== null}
            />
            <Pagination
              current={debPage}
              pageCount={pageCount}
              goToPage={(page) => {
                setQueryParams({ page })
                window.scrollTo(0, 10)
              }}
              hasNext={hasNext}
              hasPrev={hasPrev}
            />
          </>
        )}
      </div>
      {createActivityModal.value && (
        <ProjectActivityModal
          isOpen={createActivityModal.isOpen}
          toggle={createActivityModalActions.toggle}
          onClosed={createActivityModalActions.onClose}
          defaults={createActivityModal.value}
          save={(data) => createActivity.asPromise(data)}
        />
      )}
      {taskModal.value && (
        <CreateTaskModal
          isOpen={taskModal.isOpen}
          toggle={taskModalActions.toggle}
          onClosed={taskModalActions.onClose}
          save={(taskData) => {
            if (taskData.id) {
              return update
                .onSuccess(() => {
                  taskModalActions.close()
                })
                .asPromise(taskData.id, taskData)
            } else {
              return create
                .onSuccess(() => {
                  taskModalActions.close()
                  refresh(filters)
                })
                .asPromise(taskData)
            }
          }}
          data={taskModal.value}
        />
      )}
      {removeTaskModal.value && (
        <ConfirmDeleteModal
          isOpen={removeTaskModal.isOpen}
          toggle={removeTaskModalActions.toggle}
          onClosed={removeTaskModalActions.onClose}
          item={removeTaskModal.value.title}
          onConfirm={() => {
            remove
              .onSuccess(() => {
                refresh(filters)
              })
              .run(removeTaskModal.value.id)
          }}
        />
      )}
      {changeSprintModalState.value && (
        <ProjectTaskSprintModal
          isOpen={changeSprintModalState.isOpen}
          onCancel={changeSprintModalActions.toggle}
          onClosed={changeSprintModalActions.onClose}
          sprint={changeSprintModalState.value}
          onSave={(result) => {
            if (changeSprintModalState.value.id) {
              return updateSprint
                .onSuccess(() => {
                  changeSprintModalActions.close()
                })
                .asPromise(changeSprintModalState.value.id, result)
            } else {
              return createSprint
                .onSuccess(() => {
                  changeSprintModalActions.close()
                })
                .asPromise(result)
            }
          }}
        />
      )}
      <Layout.TabBar>
        <ProjectTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <ProjectBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import { chunkBlocks } from "../pageUtils"

export default function ScenarioListSplitter(
  node,
  props,
  firstChunkSpace,
  sheetHeight
) {
  const blocks = node.querySelectorAll(".__pager-node")
  const title = node.querySelector("__pager-title")

  const titleHeight = title?.offsetHeight ?? 0

  return chunkBlocks(blocks, firstChunkSpace - titleHeight, sheetHeight, 0)
}

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { BsChevronLeft, BsChevronRight, BsDownload } from "react-icons/bs"
import Button from "../../../components/Button"
import { Spinner } from "reactstrap"

export function Navigator({ month, year, onChange, downloadReport, isReportLoading }) {
  const { t, i18n } = useTranslation()
  const today = dayjs()

  return (
    <div className="bg-light rounded py-3 px-5 d-flex flex-row align-items-center">
      <h2 className="text-primary text-capitalize mr-11 my-0">
        {dayjs(`${year}-${month}-01`).locale(i18n.language).format("MMMM YYYY")}
      </h2>
      <div
        className="mr-4 pointer text-primary"
        onClick={() => {
          if (month === 1) {
            onChange([12, year - 1])
          } else {
            onChange([month - 1, year])
          }
        }}
      >
        <BsChevronLeft />
      </div>
      <div
        onClick={() => {
          if (month === 12) {
            onChange([1, year + 1])
          } else {
            onChange([month + 1, year])
          }
        }}
        className="mr-11 pointer text-primary"
      >
        <BsChevronRight />
      </div>
      <Button
        color="white-fill"
        onClick={() => {
          onChange([today.get("month") + 1, today.get("year")])
        }}
      >
        <b>{t("hr_workflow_new.today")}</b>
      </Button>
      <div className="flex-1" />
      <Button
        color="white-fill"
        onClick={() => {
          downloadReport()
        }}
      >
        {isReportLoading ? <Spinner size="sm" /> : <BsDownload />}
      </Button>
    </div>
  )
}

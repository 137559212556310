import { Field, Formik } from "formik"
import { mapValues } from "lodash"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import Button from "../../components/Button"
import FieldInput from "../../components/fields/FieldInput"
import FieldTextarea from "../../components/fields/FieldTextarea"
import WpModal from "../../components/WpModal"
import { convertErrorToForm } from "../../utils"

const INITIAL_VALUES = {
  name: "",
}

export function EditTagModal({
  isOpen,
  toggle,
  onSave,
  onClosed,
  tag,
}) {
  const { t } = useTranslation(["translation", "field", "action"])

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(
        t("field:errors.mandatory", { field: t("field:tag_category.name") })
      ),
  })

  return (
    <WpModal
      onClosed={onClosed}
      isOpen={isOpen}
      toggle={toggle}
      title={
        tag?.id
          ? t("action:edit_tag")
          : t("action:new_tag")
      }
    >
      <div>
        <div className="d-flex flex-row align-items-start my-4">
          <div className="d-flex flex-column ml-2 flex-1">
            <Formik
              initialValues={{ ...INITIAL_VALUES, ...tag }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                return onSave(
                  mapValues(values, (val) => (val === undefined ? null : val))
                ).catch((error) => {
                  actions.setErrors(convertErrorToForm(error))
                })
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <>
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Field
                      name="name"
                      component={FieldInput}
                      label={t("field:tag_category.name")}
                      className={"mb-5"}
                    />
                    <Field
                      name="description"
                      component={FieldTextarea}
                      label={t("field:tag_category.description")}
                      className={"mb-5"}
                    />
                    <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                      <Button size="sm" color="none" onClick={toggle}>
                        {t("action:cancel")}
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        type="submit"
                        className="ml-5"
                        disabled={!formik.isValid}
                      >
                        {tag?.id ? t("action:save") : t("action:add")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </WpModal>
  )
}

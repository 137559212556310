import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import S from "./MinScreenSize.module.scss"
import logo from "../../assets/logo@2x.png"

const MIN_HEIGHT = 500
const MIN_WIDTH = 900

export default function MinScreenSize({ children }) {
  const { t } = useTranslation()
  const [{ width, height }, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handler = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener("resize", handler)
    return () => {
      window.removeEventListener("resize", handler)
    }
  }, [])

  // NOTE: Avoid madness when open dev tools
  if (process.env.REACT_APP_NO_MIN_SCREEN_SIZE) {
    return children
  }

  return (
    <>
      {children}
      {(width < MIN_WIDTH || height < MIN_HEIGHT) && (
        <div className={S["min-screen-size-required"]}>
          <img src={logo} alt="" className={S["logo"]} />
          <p className={S["message"]}>
            {t("min_screen_size_required", {
              width: MIN_WIDTH,
              height: MIN_HEIGHT,
            })}
          </p>
        </div>
      )}
    </>
  )
}

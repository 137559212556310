import { useTranslation } from "react-i18next"
import Icon from "../Icon"

export default function FieldFavorite({
  field,
  form: { touched, errors, setFieldValue },
  label,
  type = "text",
  enableShowHide = "false",
  className = "",
  containerClassName = "",
  debounce = false,
  ...props
}) {
  const { t } = useTranslation(["field"])

  return (
    <div className={`d-flex flex-column ${containerClassName}`}>
      {label && (
        <label className="font-weight-semibold mb-3 h3 text-uppercase">
          {label}
        </label>
      )}
      <div
        style={{
          height: 37.5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {field.value && (
          <span
            className="pointer"
            onClick={() => {
              setFieldValue(field.name, false)
            }}
          >
            <Icon
              name="star-filled"
              title={t("action:remove_favorite")}
              className="pointer text-dark"
            />
          </span>
        )}
        {!field.value && (
          <span
            className="pointer"
            onClick={() => {
              setFieldValue(field.name, true)
            }}
          >
            <Icon
              name="star"
              title={t("action:add_favorite")}
              className="pointer text-dark"
            />
          </span>
        )}
      </div>
    </div>
  )
}

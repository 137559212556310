import { Field, useFormikContext } from "formik"
import React, { useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import Separator from "../../../../components/Separator"
import classNames from "classnames"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import Icon from "../../../../components/Icon"
import FieldInput from "../../../../components/fields/FieldInput"
import FieldDropdown from "../../../../components/fields/FieldDropdown"
import S from "../columns.module.scss"
import { uniqueId } from "lodash"
import { TaskOperationsContext } from "../context"
import FieldDropdownList from "../../../../components/fields/FieldDropdownList"
import { shouldAutoSubmitResource } from "../TreeView/utils"
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter"

function ResourceValue({ display = "price", values }) {
  const { t } = useTranslation(["translation", "field", "enums"])
  const currencyFmt = useCurrencyFormatter()

  const size = parseFloat(values.size)
  const unitary = parseFloat(values[`unitary_${display}`])
  const value = size * unitary
  const displayValue = isNaN(value) ? "" : currencyFmt.format(value)
  const displayUnitary = isNaN(unitary) ? "" : currencyFmt.format(unitary)
  const unitaryId = "unitary" + uniqueId()

  return (
    <div className="d-flex flex-column align-items-end">
      <span>{displayValue}</span>
      <span id={unitaryId} className="h4 text-separator">
        {displayUnitary}
      </span>
      <UncontrolledTooltip
        key={unitaryId}
        placement={"bottom"}
        target={unitaryId}
        fade={false}
      >
        {t(`field:task.resource.hint.unitary_${display}`)}
      </UncontrolledTooltip>
    </div>
  )
}

export default function ResourceInnerForm({
  resourcesOptions,
  resourceType,
  resourcesById,
  onRemove,
  onSearchChange,
  estimate,
  resourceAllocation: stableValues,
  isLoading,
  readOnly = false,
}) {
  const { editResource } = useContext(TaskOperationsContext)
  const { values, setFieldValue, handleSubmit } = useFormikContext()

  const dirty = !stableValues || shouldAutoSubmitResource(values, stableValues)

  const unitOptions = useMemo(() => {
    return (resourcesById[values.resource_id]?.price_list ?? []).map((r) => ({
      value: r.cost_unit,
      label: r.cost_unit,
    }))
  }, [resourcesById, values.resource_id])

  const currentResource = resourcesById[values.resource_id]

  useEffect(() => {
    const hasUnit = (resourcesById[values.resource_id]?.price_list ?? []).some(
      (r) => r.cost_unit === values.cost_unit
    )
    if (!hasUnit) {
      setFieldValue("cost_unit", "")
      setFieldValue("size", "")
    }
  }, [values.resource_id, values.cost_unit, setFieldValue, resourcesById])

  const { t } = useTranslation(["translation", "action"])

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="d-flex flex-row justify-content-between align-items-center"
      >
        <div className={S["col-title"]}>
          <div className="d-flex flex-row align-items-center w-100">
            <Icon
              name={resourceType}
              title={t(`enums:resource_type.${resourceType}`)}
              style={{ width: 24, height: 24, marginRight: 4 }}
            />
            <div className="flex-1">
              <Field
                name="resource_id"
                placeholder={t("field:task.resource.placeholder.resource_id")}
                emptyMessage={t("field:errors.no_match")}
                component={FieldDropdownList}
                options={resourcesOptions}
                caption={(value) => {
                  const estimateResource = estimate.resources.find(
                    (r) => r.id === value
                  )
                  if (estimateResource) {
                    return estimateResource.name
                  } else {
                    return resourcesOptions.find((item) => item.value === value)
                      ?.label
                  }
                }}
                onSearchChange={onSearchChange}
                readOnly={readOnly}
              />
            </div>
          </div>
        </div>
        <Separator vertical className="my-3 mx-0 align-self-stretch" />
        <div className={S["col-unit"]}>
          {values.resource_id !== "" && (
            <Field
              name="cost_unit"
              placeholder={t("field:task.resource.placeholder.cost_unit")}
              component={FieldDropdown}
              options={unitOptions}
              readOnly={readOnly}
              className="px-3 py-2"
            />
          )}
        </div>
        <Separator vertical className="my-3 mx-0 align-self-stretch" />
        <div className={S["col-quantity"]}>
          {values.resource_id !== "" && values.cost_unit !== "" && (
            <Field
              type="number"
              name="size"
              placeholder={t("field:task.resource.placeholder.quantity")}
              component={FieldInput}
              tiny
              clear
              readOnly={readOnly}
            />
          )}
        </div>
        <Separator vertical className="my-3 mx-0 align-self-stretch" />
        <div className={classNames("text-right", S["col-cost"])}>
          {values.resource_id !== "" &&
            values.size !== "" &&
            values.cost_unit !== "" &&
            !dirty &&
            !isLoading && (
              <ResourceValue display="cost" values={stableValues} />
            )}
          {values.resource_id !== "" &&
            values.size !== "" &&
            values.cost_unit !== "" &&
            (dirty || isLoading) && <Spinner className="mt-3" size="sm" />}
        </div>
        <Separator vertical className="my-3 mx-0 align-self-stretch" />
        <div className={classNames("text-right", S["col-price"])}>
          {values.resource_id !== "" &&
            values.size !== "" &&
            values.cost_unit !== "" &&
            !dirty &&
            !isLoading && (
              <ResourceValue display="price" values={stableValues} />
            )}
          {values.resource_id !== "" &&
            values.size !== "" &&
            values.cost_unit !== "" &&
            (dirty || isLoading) && <Spinner size="sm" className="mt-3" />}
        </div>
        <Separator vertical className="my-3 mx-0 align-self-stretch" />
        <div className={classNames(S["col-action"])}>
          {!readOnly && (
            <UncontrolledDropdown
              className={"action-icon-container-primary-hover"}
            >
              <DropdownToggle caret={false} tag={"span"}>
                <Icon
                  name="vdots"
                  className="text-dark pointer"
                  title={t("action:other_options")}
                  placement="right"
                />
              </DropdownToggle>
              <DropdownMenu
                right
                modifiers={{ offset: { offset: "0, 12" } }}
                className="border-primary"
              >
                {values.resource_id !== "" &&
                  values.cost_unit !== "" &&
                  values.size !== "" && (
                    <>
                      <DropdownItem
                        onClick={() => editResource(currentResource)}
                        className={
                          "text-capitalize px-0 dropdown-item-primary-active"
                        }
                      >
                        <div className="d-flex flex-row align-items-center mx-4">
                          <Icon
                            name="edit"
                            className="pointer mr-4"
                          />
                          <span>{t("action:edit_price")}</span>
                        </div>
                      </DropdownItem>
                      <Separator className="my-0 mx-4 border-primary" />
                    </>
                  )}
                <DropdownItem
                  className={
                    "text-capitalize px-0 dropdown-item-primary-active"
                  }
                  onClick={onRemove}
                >
                  <div className="d-flex flex-row align-items-center mx-4">
                    <Icon name="delete" className="pointer mr-4" />
                    <span>{t("action:delete")}</span>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </div>
      </form>
    </>
  )
}

import React, { useMemo } from "react"
import { Group } from "@visx/group"
import { hierarchy, Tree } from "@visx/hierarchy"
import classNames from "classnames"
import S from "./Dendrogram.module.scss"
import getLinkComponent from "./utils"
import MultiLineText from "../../../../components/MultiLineText"

const defaultMargin = { top: 10, left: 10, right: 107, bottom: 100 }

function Node({ node, orientation, textSpace }) {
  const isRoot = node.depth === 0
  const isParent = !!node.children

  const isHoriz = orientation === "horizontal"

  const x = isHoriz ? node.y : node.x
  const y = isHoriz ? node.x : node.y

  if (isRoot) return <RootNode node={node} orientation={orientation} />
  else if (isParent) return <ParentNode node={node} orientation={orientation} />

  return (
    <Group top={y} left={x}>
      <circle className={classNames(S["leaf-node"])} r={5} />
      <MultiLineText
        className={classNames(S["leaf-node-text"], S["depth-" + node.depth])}
        x={isHoriz ? 12 : 0}
        y={isHoriz ? 0 : 12}
        textAnchor={isHoriz ? "start" : "middle"}
        verticalAnchor={isHoriz ? "middle" : "start"}
        width={textSpace}
        maxLines={2}
      >
        {node.data.title}
      </MultiLineText>
    </Group>
  )
}

function ParentNode({ node, orientation }) {
  const isHoriz = orientation === "horizontal"

  const x = isHoriz ? node.y : node.x
  const y = isHoriz ? node.x : node.y

  return (
    <Group top={y} left={x}>
      <circle className={classNames(S["parent-node"])} r={5} />
      <MultiLineText
        width={100}
        className={classNames(S["parent-node-text"], S["depth-" + node.depth])}
        x={isHoriz ? 0 : 12}
        y={isHoriz ? -12 : 0}
        textAnchor={isHoriz ? "middle" : "start"}
        verticalAnchor={isHoriz ? "end" : "middle"}
        maxLines={3}
      >
        {node.data.title}
      </MultiLineText>
    </Group>
  )
}

function RootNode({ node, orientation }) {
  const isHoriz = orientation === "horizontal"

  const x = isHoriz ? node.y : node.x
  const y = isHoriz ? node.x : node.y

  return (
    <Group top={y} left={x}>
      <circle className={classNames(S["root-node"])} r={5} />
    </Group>
  )
}

function CustomLink({ link, linkComponent: LinkComponent }) {
  return <LinkComponent data={link} className={classNames(S["link"])} />
}

function patchPositions(node) {

  function patchPositionRecursive(node) {
    node.y = node.y - offset
    node.children?.forEach(patchPositionRecursive)
  }

  if (!node.children) {
    return
  }
  const offset = (node.children[0].y - node.y) / 2

  node.children?.forEach(patchPositionRecursive, offset)

  return offset
}

export default function Dendrogram({
  width,
  height,
  margin = defaultMargin,
  data: clusterData,
  orientation = "horizontal",
  layout = "cartesian",
  linkType = "diagonal",
}) {
  const data = useMemo(() => hierarchy(clusterData), [clusterData])

  const isHoriz = orientation === "horizontal"

  const _xMax = width - margin.left - margin.right
  const _yMax = height - margin.top - margin.bottom

  const xMax = isHoriz ? _yMax : _xMax
  const yMax = isHoriz ? _xMax : _yMax

  const link = getLinkComponent({
    layout,
    linkType,
    orientation,
  })

  const TreeNode = (props) => {
    return <Node {...props} orientation={orientation} />
  }

  const TreeLink = (props) => {
    return <CustomLink {...props} linkComponent={link} />
  }

  if (isNaN(width) || isNaN(height)) {
    return null
  }

  return (
    <svg width={width} height={height}>
      {!isNaN(xMax) && !isNaN(yMax) && (
        <Group top={margin.top} left={margin.left}>
          <Tree
            root={data}
            size={[xMax, yMax]}
          >
            {(tree) => {
              const offset = patchPositions(tree)
              return (
                <Group top={margin.top} left={margin.left}>
                  {tree.links().map((link, i) => (
                    <TreeLink
                      key={`link-${i}`}
                      link={link}
                    />
                  ))}
                  {tree.descendants().map((node, i) => (
                    <TreeNode key={`node-${i}`} node={node} textSpace={offset} />
                  ))}
                </Group>
              )
            }}
          </Tree>
        </Group>
      )}
    </svg>
  )
}

// export default function Example() {
//   const [{ estimate }] = useEstimateTree(9)
//   if (!estimate) {
//     return null
//   }
//   return (
//     <Dendrogram
//       width={600}
//       height={600}
//       clusterData={{ children: estimate.task_tree }}
//     />
//   )
// }

import classNames from "classnames"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import React, { Fragment, useCallback, useMemo, useReducer, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import EmptyFilterImage from "../../assets/icons/empty_filter.svg"
import EmptyImage from "../../assets/icons/empty_page.svg"
import Button from "../../components/Button"
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import Dropdown from "../../components/Dropdown"
import ErrorPopover from "../../components/ErrorPopover"
import FilterButton from "../../components/FilterButton"
import Icon from "../../components/Icon"
import Layout from "../../components/Layout"
import Loader from "../../components/Loader/Loader"
import LongBorder from "../../components/LongBorder"
import MeasureContext from "../../components/MeasureContext/MeasureContext"
import Pagination from "../../components/Pagination/Pagination"
import Separator from "../../components/Separator/Separator"
import Table from "../../components/Table/Table"
import WpInput from "../../components/WpInput"
import WpModalQuota from "../../components/WpModalQuota"
import WpTextarea from "../../components/WpTextarea/WpTextarea"
import { RESOURCE_TYPE, RESOURCE_TYPES } from "../../consts"
import { useQuota } from "../../hooks/quota"
import { useResourcesList } from "../../hooks/resources"
import { usePriceLists } from "../../hooks/priceList"
import useModalTrigger from "../../hooks/useModalTrigger"
import { notifyError } from "../../utils"
import ResourceDetail from "./ResourceDetail"
import { getCurrencyFormatter } from "../../hooks/useCurrencyFormatter"
import { EstimateLandmarks } from "../../components/EstimateLandmarks"

const MiniCostTable = ({ currency, priceList, priceField }) => {
  const currencyFmt = useMemo(() => {
    return getCurrencyFormatter(currency)
  }, [currency])
  return (
    <div className="flex-fill">
      {priceList.map((priced, i) => {
        const value = priced[`unitary_${priceField}`]
        return (
          <Fragment key={priced.id}>
            <div className={classNames("d-flex justify-content-between py-2")}>
              <div>{priced.cost_unit}</div>
              {value !== null && <div>{currencyFmt.format(value)}</div>}
              {value === null && <div>{"n.a."}</div>}
            </div>
            {i !== priceList.length - 1 && <Separator className="px-3 my-0" />}
          </Fragment>
        )
      })}
    </div>
  )
}

export default function ResourcesList() {
  const [
    { resourceType, resourceFilter, showArchived, priceList: _priceList },
    setQueryParams,
    { resourceType: debResourceType, resourceFilter: debResourceFilter, page: debPage, showArchived: debShowArchived },
    setDebouncedQueryParams,
  ] = useQueryParams({
    page: {
      encode: (intValue) => intValue.toString(10),
      decode: (strValue) => (strValue ? parseInt(strValue, 10) : 1),
    },
    resourceType: {
      encode: (value) => value || "",
      decode: (rawvalue) => rawvalue || "",
    },
    resourceFilter: {
      encode: (value) => value || "",
      decode: (rawvalue) => rawvalue || "",
    },
    showArchived: {
      encode: (value) => value || "",
      decode: (rawvalue) => rawvalue || "no",
    },
    priceList: {
      encode: (value) => value || "",
      decode: (rawvalue) => (rawvalue ? parseInt(rawvalue, 10) : ""),
    },
  })

  const resourcesFilters = useMemo(
    () => ({
      embed_prices: true,
      estimate: "none",
      resource_type: debResourceType,
      page: debPage,
      search: debResourceFilter,
      show_archived: debShowArchived,
      include_unpriced: true,
    }),
    [debPage, debResourceFilter, debResourceType, debShowArchived]
  )

  const { t } = useTranslation(["translation", "field", "enums", "action"])

  const [{ data: priceLists }] = usePriceLists()

  const currentPriceListId = _priceList || priceLists?.[0]?.id || null
  const currentPriceList = priceLists?.find((lst) => lst.id === currentPriceListId)

  const [
    { resources, count, fullCount, pageCount, hasNext, hasPrev, loading },
    { updateResource, createResource, deleteResource, run: reloadResources, duplicateResource },
  ] = useResourcesList(resourcesFilters)

  const [selectedResource, setSelectedResource] = useState(null)

  const selectedResourceIndex = useMemo(() => {
    const i = resources?.findIndex((item) => item.id === selectedResource?.id)
    if (i) {
      return [i]
    }
    return []
  }, [resources, selectedResource])

  const [deleteModal, deleteModalActions] = useModalTrigger()

  // Don't use "useModalTriggerQuota" here since quota check is performed manually
  const [duplicateModal, duplicateModalActions] = useModalTrigger()

  const [, { refreshQuota }] = useQuota()

  const onResourceDeletion = useCallback(
    (toDelete) => {
      setSelectedResource(null)
      deleteResource
        .onFailure(notifyError)
        .onSuccess(() => {
          reloadResources(resourcesFilters)
        })
        .run(toDelete.id)
    },
    [deleteResource, reloadResources, resourcesFilters]
  )

  const onResourceArchive = useCallback(
    (toArchive) => {
      setSelectedResource(null)
      updateResource
        .onFailure(notifyError)
        .onSuccess(() => {
          reloadResources(resourcesFilters)
        })
        .run(toArchive.id, {
          archived: true,
        })
    },
    [reloadResources, resourcesFilters, updateResource]
  )

  const onResourceRestore = useCallback(
    (toRestore) => {
      setSelectedResource(null)
      updateResource
        .onFailure(notifyError)
        .onSuccess(() => {
          reloadResources(resourcesFilters)
        })
        .run(toRestore.id, {
          archived: false,
        })
    },
    [reloadResources, resourcesFilters, updateResource]
  )


  const [creatingResource, dispatchCreateAction] = useReducer(
    (state, action) => {
      if (action.type === "setType") {
        return {
          ...state,
          resourceType: action.payload,
          error: null,
          canSave: !!(action.payload && state.resourceTitle),
        }
      } else if (action.type === "setTitle") {
        return {
          ...state,
          resourceTitle: action.payload,
          error: null,
          canSave: !!(action.payload && state.resourceType),
        }
      } else if (action.type === "openModal") {
        return { ...state, modal: true }
      } else if (action.type === "openModalForType") {
        return { ...state, modal: true, resourceType: action.payload }
      } else if (action.type === "reset") {
        return {
          ...state,
          modal: false,
          resourceType: null,
          resourceTitle: "",
          error: null,
          canSave: false,
          enableErrors: false,
        }
      } else if (action.type === "init") {
        return {
          ...state,
          modal: false,
          resourceType: action.payload.resourceType,
          resourceTitle: action.payload.resourceTitle,
          error: null,
          canSave: (!!action.payload.resourceType && !!action.payload.resourceTitle),
          enableErrors: false,
        }
      } else if (action.type === "setError") {
        return { ...state, error: action.payload, canSave: false }
      } else if (action.type === "clearError") {
        return {
          ...state,
          error: null,
          canSave: !!(state.resourceType && state.resourceTitle),
        }
      } else if (action.type === "enableErrors") {
        return { ...state, enableErrors: true }
      } else {
        console.error("Unknown action: " + action.type)
        return state
      }
    },
    {
      modal: false,
      resourceType: null,
      resourceTitle: "",
      error: null,
      canSave: false,
      enableErrors: false,
    }
  )

  const columns = useMemo(
    () => [
      {
        label: t("field:resource.resource_type"),
        name: "resource_type",
        render: (selectedDatum, colName, datum) => (
          <div className="d-flex flex-column h-100 w-100 justify-content-center">
            {t(`enums:resource_type:${selectedDatum}`)}
            {datum.archived && (
              <span className="badge badge-separator mt-2">{t("enums:resource_state.archived_resource")}</span>
            )}
          </div>
        ),
        size: 1,
        capitalize: true,
      },
      {
        label: t("field:resource.name"),
        name: "name",
        render: (selectedDatum, colName, datum) => (
          <div className="d-flex flex-column">
            <span>{selectedDatum}</span>
            <div>
              {datum?.tag?.length > 0 && (
                <div className="pt-4">
                  <span>{t("field:resource.tag", { count: 2 }) + ":"}</span>
                  {datum?.tag?.map((t) => (
                    <span className="border rounded-xl px-4">{t}</span>
                  ))}
                </div>
              )}
            </div>
          </div>
        ),
        size: 3,
      },
      {
        label: t("field:resource.description"),
        name: "description",
        size: 3,
      },
      {
        label: t("field:resource.price_list.unitary_cost"),
        name: "price_list",
        render: (selectedDatum, colName, datum) => (
          <MiniCostTable
            currency={currentPriceList?.currency ?? "EUR"}
            priceList={selectedDatum.filter((x) => x.price_list === currentPriceListId)}
            priceField="cost"
          />
        ),
        size: 2,
      },
      {
        label: t("field:resource.price_list.unitary_price"),
        name: "price_list",
        render: (selectedDatum, colName, datum) => {
          return (
            <MiniCostTable
              currency={currentPriceList?.currency ?? "EUR"}
              priceList={selectedDatum.filter((x) => x.price_list === currentPriceListId)}
              priceField="price"
            />
          )
        },
        size: 2,
      },
      {
        label: t("actions"),
        name: "actions",
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center">
              {!datum.archived && (
                <div className={"action-icon-container-primary-hover"}>
                  <Icon
                    name="edit"
                    title={t("action:edit_resource")}
                    className="pointer text-dark"
                    onClick={() => {
                      setSelectedResource(datum)
                    }}
                  />
                </div>
              )}
              <div className={"action-icon-container-primary-hover"}>
                <UncontrolledDropdown>
                  <DropdownToggle caret={false} tag={"span"}>
                    <Icon name="vdots" className="text-dark pointer" title={t("action:other_options")} placement="right" />
                  </DropdownToggle>
                  <DropdownMenu right modifiers={{ offset: { offset: "0, 12" } }} className="border-primary">
                    {!datum.archived && (
                      <>
                        <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                          <div
                            className="d-flex flex-row align-items-center mx-4"
                            onClick={() => {
                              refreshQuota
                                .onSuccess((quota) => {
                                  if (quota.resources > 0) {
                                    const { id, ...others } = datum
                                    createResource
                                      .onSuccess((resource) => {
                                        setSelectedResource(resource)
                                        reloadResources(resourcesFilters)
                                      })
                                      .onFailure((err) => {
                                        // TODO what is "err"??
                                      })
                                      .run(others)
                                  } else {
                                    dispatchCreateAction({ type: "init", payload: { resourceType: datum.resource_type, resourceTitle: datum.name }})
                                    duplicateModalActions.open(datum)
                                  }
                                })
                                .run()
                            }}
                          >
                            <Icon name="duplicate" className="pointer mr-4" />
                            <span>{t("action:duplicate")}</span>
                          </div>
                        </DropdownItem>

                        <Separator className="my-0 mx-4 border-primary" />
                      </>
                    )}
                    {!datum.archived && (
                      <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                        <div className="d-flex flex-row align-items-center mx-4" onClick={() => onResourceArchive(datum)}>
                          <Icon name="archive" className={classNames("pointer mr-4")} />
                          <span>{t("action:archive")}</span>
                        </div>
                      </DropdownItem>
                    )}
                    {datum.archived && (
                      <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                        <div className="d-flex flex-row align-items-center mx-4" onClick={() => onResourceRestore(datum)}>
                          <Icon name="restore_archive" className={classNames("pointer mr-4")} />
                          <span>{t("action:restore")}</span>
                        </div>
                      </DropdownItem>
                    )}
                    <Separator className="my-0 mx-4 border-primary" />
                    <DropdownItem
                      className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}
                      disabled={datum.is_allocated}
                    >
                      <div className="d-flex flex-row align-items-center mx-4" onClick={() => deleteModalActions.open(datum)}>
                        <Icon
                          name="delete"
                          className={classNames("pointer mr-4", {
                            "text-separator": datum.is_allocated,
                          })}
                        />
                        <span>{t("action:delete")}</span>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )
        },
        size: 1,
      },
    ],
    [
      createResource,
      currentPriceListId,
      deleteModalActions,
      duplicateModalActions,
      onResourceArchive,
      onResourceRestore,
      refreshQuota,
      reloadResources,
      resourcesFilters,
      t,
      currentPriceList,
    ]
  )

  const inputRef = useRef()

  const closeDrawer = useCallback(() => {
    setSelectedResource(null)
  }, [])

  return (
    <Layout showDrawer={selectedResource !== null} toggleDrawer={closeDrawer}>
      {resources === null && <Loader />}
      {resources !== null && (
        <div className="container-fluid pt-8 px-page pb-page-bottom">
          <h1 className="text-primary font-weight-semibold">{t("resource_list.title")}</h1>

          <div className="d-flex flex-column mt-4 mb-7">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="d-flex flex-row align-items-center">
                <FilterButton
                  onClick={() => setQueryParams({ resourceType: "", page: 1 })}
                  className={"mr-4"}
                  selected={!resourceType}
                >
                  {t("enums:resource_type.all")}
                </FilterButton>
                {RESOURCE_TYPES.map((resType) => (
                  <FilterButton
                    onClick={() => setQueryParams({ resourceType: resType, page: 1 })}
                    key={resType}
                    className={"mr-4 text-capitalize"}
                    selected={resourceType === resType}
                  >
                    {t(`enums:resource_type.${resType}`, { count: 2 })}
                  </FilterButton>
                ))}
                <div className="d-flex flex-row align-items-center justify-content-center ml-8">
                  <span className="pr-3">{t("action:search")}:</span>
                  <WpInput
                    placeholder={t("field:resource.placeholder.search")}
                    style={{ width: 235 }}
                    value={resourceFilter}
                    medium
                    onChange={(e) =>
                      setDebouncedQueryParams({
                        resourceFilter: e.target.value,
                        page: 1,
                      })
                    }
                  />
                </div>
              </div>

              <span className="ml-3">
                {t("resource_list.resource_count", {
                  filtered: count,
                  count: fullCount,
                  context: count !== 1 ? "many" : "one",
                })}
              </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between mt-5">
              <div className="d-flex flex-row align-items-center">
                <FilterButton
                  onClick={() => setQueryParams({ showArchived: "both", page: 1 })}
                  className={"mr-4"}
                  selected={showArchived === "both"}
                >
                  {t("enums:resource_state.all")}
                </FilterButton>
                <FilterButton
                  onClick={() => setQueryParams({ showArchived: "no", page: 1 })}
                  className={"mr-4"}
                  selected={showArchived === "no"}
                >
                  {t("enums:resource_state.active")}
                </FilterButton>
                <FilterButton
                  onClick={() => setQueryParams({ showArchived: "only", page: 1 })}
                  className={"mr-4"}
                  selected={showArchived === "only"}
                >
                  {t("enums:resource_state.archived")}
                </FilterButton>

                {/* TODO search tag */}
              </div>
              <div>
                <Dropdown
                  className="px-3 py-2"
                  value={currentPriceListId}
                  options={(priceLists ?? []).map((priceList) => ({
                    value: priceList.id,
                    label: priceList.title + " (" + getCurrencyFormatter(priceList.currency).currencySymbol + ")",
                  }))}
                  onChange={(value) => {
                    setQueryParams({ priceList: value })
                  }}
                  placeholder=""
                  style={{ minWidth: 150 }}
                />
              </div>
            </div>
          </div>

          {/*Something to show*/}
          {count !== 0 && (
            <>
              <LongBorder topBar="toolbar" />

              <Table columns={columns} data={resources} className="pb-8" highlightRows={selectedResourceIndex} />

              <Pagination
                current={debPage}
                pageCount={pageCount}
                goToPage={(page) => {
                  setQueryParams({ page })
                  window.scrollTo(0, 10)
                }}
                hasNext={hasNext}
                hasPrev={hasPrev}
              />
            </>
          )}

          {/*Something to show but filtered so empty or Nothing to show but archived and not loading*/}
          {((fullCount !== 0 && count === 0) || (fullCount === 0 && showArchived === "only")) && !loading && (
            <div className="d-flex flex-column text-primary align-items-start">
              <span className="pb-4">{t("resource_list.empty_resources_filter")}</span>
              <MeasureContext as="img" src={EmptyFilterImage} alt="" bottom={0} style={{ maxHeight: 417, height: "100%" }}>
                {(ref) => (
                  <>
                    <Button
                      size="sm"
                      color="dark"
                      onClick={() =>
                        setQueryParams({
                          resourceType: "",
                          page: 1,
                          resourceFilter: "",
                        })
                      }
                    >
                      {t("action:select_all")}
                    </Button>
                    <div ref={ref}></div>
                  </>
                )}
              </MeasureContext>
            </div>
          )}

          {/*Nothing to show but active or all*/}
          {fullCount === 0 && (showArchived === "no" || showArchived === "both") && (
            <div className="d-flex flex-column text-primary">
              <span className="pb-4">{t("resource_list.empty_resources")}</span>
              <MeasureContext as="img" src={EmptyImage} alt="" bottom={0} maxHeight={400} className="h-100">
                {(ref) => (
                  <div
                    className="d-flex flex-row align-items-center position-relative pointer"
                    onClick={() => {
                      refreshQuota()
                      dispatchCreateAction({ type: "openModal" })
                    }}
                  >
                    <Button color={"primary"} size="lg" rounded>
                      <Icon name="plus" className="pointer" />
                    </Button>
                    <span className="pl-3" ref={ref}>
                      {t("resource_list.create_resource")}
                    </span>
                  </div>
                )}
              </MeasureContext>
            </div>
          )}
        </div>
      )}

      <ConfirmDeleteModal
        toggle={deleteModalActions.toggle}
        isOpen={deleteModal.isOpen}
        onConfirm={() => onResourceDeletion(deleteModal.value)}
        item={deleteModal.value?.name ?? ""}
        onClosed={deleteModalActions.onClose}
      />

      <WpModalQuota
        checkQuota="resources"
        isOpen={creatingResource.modal}
        toggle={() => dispatchCreateAction({ type: "reset" })}
        title={t("resource_list.create_resource")}
      >
        <div>
          <p className="mx-5">{t("resource_list.create_resource_message")}</p>
          <div className="d-flex flex-row align-items-start my-4">
            <div className="d-flex flex-column ml-2 flex-1">
              <span className="text-uppercase font-semibold h3 ml-5 mb-3">{t("field:resource.name")}</span>
              <WpTextarea
                ref={inputRef}
                auto
                onChange={(e) =>
                  dispatchCreateAction({
                    type: "setTitle",
                    payload: e.target.value,
                  })
                }
                onBlur={() => dispatchCreateAction({ type: "enableErrors" })}
                invalid={
                  creatingResource.enableErrors && (creatingResource.resourceTitle === "" || creatingResource.error !== null)
                }
                rows={2}
                className={classNames("mr-5 flex-1")}
                value={creatingResource.resourceTitle}
                placeholder={t("field:resource.placeholder.name")}
              />
            </div>
            <div className="d-flex flex-column mr-2">
              <span className="text-uppercase font-semibold h3 ml-4 mb-3">{t("field:resource.resource_type")}</span>
              <Dropdown
                value={creatingResource.resourceType}
                options={RESOURCE_TYPES.map((resType) => ({
                  value: resType,
                  label: t(`enums:resource_type.${resType}`),
                }))}
                onChange={(value) => {
                  dispatchCreateAction({ type: "setType", payload: value })
                }}
                placeholder={t("action:select_type")}
                style={{ minWidth: 150 }}
              />
            </div>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
            <Button size="sm" color="none" onClick={() => dispatchCreateAction({ type: "reset" })}>
              {t("action:cancel")}
            </Button>
            <Button
              size="sm"
              color="primary"
              type="submit"
              className="ml-5"
              disabled={!creatingResource.canSave}
              onClick={() => {
                createResource
                  .onSuccess((resource) => {
                    setSelectedResource(resource)
                    reloadResources(resourcesFilters)
                    dispatchCreateAction({ type: "reset" })
                  })
                  .onFailure((err) => {
                    // TODO what is "err"??
                  })
                  .run({
                    resource_type: creatingResource.resourceType,
                    name: creatingResource.resourceTitle,
                    description: "",
                    price_list: [],
                  })
              }}
            >
              {t("action:add")}
            </Button>
          </div>
          {creatingResource.enableErrors && creatingResource.resourceTitle === "" && (
            <ErrorPopover
              placement={"top-start"}
              target={inputRef}
              title={t("field:errors.general")}
              description={t("field:errors.mandatory", {
                field: t("field:resource.name"),
              })}
            />
          )}
          {creatingResource.enableErrors && creatingResource.error && (
            <ErrorPopover
              placement={"top-start"}
              target={inputRef}
              title={t("field:errors.general")}
              description={creatingResource.error} // TODO Django error translation
            />
          )}
        </div>
      </WpModalQuota>

      <WpModalQuota
        checkQuota="resources"
        isOpen={duplicateModal.isOpen}
        toggle={duplicateModalActions.toggle}
        title={t("resource_list.duplicate_resource")}
        onClosed={duplicateModalActions.onClose}
      >
        <div>
          <p>{t("resource_list.duplicate_resource_message")}</p>
          <div className="d-flex flex-row align-items-start my-4">
            <div className="d-flex flex-column ml-2 flex-1">
              <span className="text-uppercase font-semibold h3 mb-3">{t("field:resource.name")}</span>
              <WpInput
                ref={inputRef}
                auto
                onChange={(e) =>
                  dispatchCreateAction({
                    type: "setTitle",
                    payload: e.target.value,
                  })
                }
                onBlur={() => dispatchCreateAction({ type: "enableErrors" })}
                invalid={
                  creatingResource.enableErrors && (creatingResource.resourceTitle === "" || creatingResource.error !== null)
                }
                rows={2}
                className={classNames("mr-5 flex-1")}
                value={creatingResource.resourceTitle}
                placeholder={t("field:resource.placeholder.name")}
              />
            </div>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
            <Button size="sm" color="none" onClick={() => {
              dispatchCreateAction({ type: "reset" })
              duplicateModalActions.close()
            }}>
              {t("action:cancel")}
            </Button>
            <Button
              size="sm"
              color="primary"
              type="submit"
              className="ml-5"
              disabled={!creatingResource.canSave}
              onClick={() => {
                duplicateResource
                  .onSuccess((resource) => {
                    setSelectedResource(resource)
                    reloadResources(resourcesFilters)
                    dispatchCreateAction({ type: "reset" })
                    duplicateModalActions.close()
                  })
                  .onFailure((err) => {
                    // TODO what is "err"??
                  })
                  .run(duplicateModal.value.id, { name: creatingResource.resourceTitle })
              }}
            >
              {t("action:duplicate")}
            </Button>
          </div>
          {creatingResource.enableErrors && creatingResource.resourceTitle === "" && (
            <ErrorPopover
              placement={"top-start"}
              target={inputRef}
              title={t("field:errors.general")}
              description={t("field:errors.mandatory", {
                field: t("field:resource.name"),
              })}
            />
          )}
          {creatingResource.enableErrors && creatingResource.error && (
            <ErrorPopover
              placement={"top-start"}
              target={inputRef}
              title={t("field:errors.general")}
              description={creatingResource.error} // TODO Django error translation
            />
          )}
        </div>
      </WpModalQuota>

      <Layout.Toolbar title={t("resource_list.toolbar_title")}>
        <Layout.Toolbar.Icon
          text={t("action:new_resource_hr")}
          title={t("action:new_resource_hr")}
          svgIcon={<Icon name="new-resource-hr" />}
          onClick={() => {
            refreshQuota()
            dispatchCreateAction({
              type: "openModalForType",
              payload: RESOURCE_TYPE.HR,
            })
          }}
        />
        <Layout.Toolbar.Divider />
        <Layout.Toolbar.Icon
          text={t("action:new_resource_service")}
          title={t("action:new_resource_service")}
          svgIcon={<Icon name="new-resource-service" />}
          onClick={() => {
            refreshQuota()
            dispatchCreateAction({
              type: "openModalForType",
              payload: RESOURCE_TYPE.SERVICE,
            })
          }}
        />
        <Layout.Toolbar.Divider />
        <Layout.Toolbar.Icon
          text={t("action:new_resource_material")}
          title={t("action:new_resource_material")}
          svgIcon={<Icon name="new-resource-material" />}
          onClick={() => {
            refreshQuota()
            dispatchCreateAction({
              type: "openModalForType",
              payload: RESOURCE_TYPE.MATERIAL,
            })
          }}
        />
      </Layout.Toolbar>

      <Layout.FirstLevelNavi>
        <EstimateLandmarks />
      </Layout.FirstLevelNavi>

      <Layout.Drawer>
        {selectedResource && (
          <ResourceDetail
            resource={selectedResource}
            priceList={currentPriceList}
            onSave={updateResource}
            onCancel={() => setSelectedResource(null)}
            onDelete={() => deleteModalActions.open(selectedResource)}
            onArchive={() => onResourceArchive(selectedResource)}
          />
        )}
      </Layout.Drawer>
    </Layout>
  )
}

import { map } from "lodash"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { CRMLandmarks } from "../../../components/CRMLandmarks"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import { CustomerContext } from "../../../context"
import { useCustomerStats } from "../../../hooks/customers"
import CustomerBottomBarContent from "../CustomerBottomBarContent"
import CustomerTabBarContent from "../CustomerTabBarContent"

function formatAddress(customer) {
  let address = customer.address
  if (address && customer.house_number) {
    address += " " + customer.house_number
  }
  if (address && customer.city) {
    address += ", "
    if (customer.postal_code) {
      address += customer.postal_code + " "
    }
    address += customer.city
    if (customer.province) {
      address += " (" + customer.province + ")"
    }
  }
  if (customer.nation) {
    if (address) {
      address += ", "
    }
    address += customer.nation
  }
  return address
}

export default function CustomerStats() {
  const { t } = useTranslation(["translation", "tab", "action"])

  const [{ customer }] = useContext(CustomerContext)
  const [{ stats }] = useCustomerStats(customer.id)

  return (
    <Layout>
      <div className="container pt-5 px-half-page pb-page-bottom">
        {!customer && <Loader />}
        {customer && (
          <>
            <h1 className="text-primary font-weight-semibold">
              {customer.name}
            </h1>
            <p>{formatAddress(customer)}</p>
            <p>{customer.phone}</p>
            <p>{customer.info_email}</p>
            <p>{customer.website}</p>
            {stats && (
              <>
                <h2>{t("customer.estimates")}</h2>
                <ul>
                  {map(stats.estimates, (count, key) => (
                    <li key={key}>
                      {t(`enums:drafting_state.${key}`)}: {count}
                    </li>
                  ))}
                </ul>
                <h2>{t("customer.jobs")}</h2>
                <ul>
                  {map(stats.projects, (count, key) => (
                    <li key={key}>
                      {t(`enums:project_status.${key}`)}: {count}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </div>
      <Layout.TabBar>
        <CustomerTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <CustomerBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <CRMLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

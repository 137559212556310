import React from "react"
import msalInstance from "../auth/microsoftAuth"
import { useAuthActions, useAuthState } from "use-eazy-auth"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import logo from "../assets/social/outlook.png"

export default function MicrosoftAuthButton({ joinToken, className, ...props }) {
  const { loginLoading } = useAuthState()
  const { login } = useAuthActions()
  const { t, i18n } = useTranslation()

  function handleMsAuth() {
    const loginRequest = {
      scopes: ["user.read", "email"],
    }
    msalInstance
      .loginPopup(loginRequest)
      .then((response) => {
        const msToken = response.idToken.rawIdToken
        login({
          socialToken: msToken,
          provider: "microsoft",
          lang: i18n.language,
          joinToken,
        })
      })
      .catch((error) => {
        console.log("Got microsoft error", error)
      })
  }

  return (
    <Button
      disabled={loginLoading}
      onClick={handleMsAuth}
      type="button"
      outline
      color="primary"
      className={classNames("d-flex flex-row justify-content-center align-items-center", className)}
      {...props}
    >
      <img
        src={logo}
        style={{ width: 16, height: 16, marginRight: 4 }}
        alt=""
      />
      {t("login.outlook")}
    </Button>
  )
}

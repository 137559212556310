import api from "../api"
import { rj, useRunRj } from "react-rocketjump"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import get from "lodash/get"
import { PDF_BASE_URL } from "../consts"

const getOrSelect = (context, pathOrFunction) => {
  if (typeof pathOrFunction === "function") {
    return pathOrFunction(context)
  }
  return get(context, pathOrFunction)
}

function insertEstimateInList(state, result) {
  return {
    ...state,
    data: {
      ...state.data,
      list:
        state.data.list.length < 100
          ? [...state.data.list, result]
          : state.data.list,
    },
  }
}

const EstimatesListState = rj(
  rjList({
    pageSize: 20,
    pagination: nextPreviousPaginationAdapter,
    customPaginationReducer: (prevState, { payload: { data } }) => {
      return {
        ...prevState,
        count: getOrSelect(data, nextPreviousPaginationAdapter.count),
        current: getOrSelect(data, nextPreviousPaginationAdapter.current),
        next: getOrSelect(data, nextPreviousPaginationAdapter.next),
        previous: getOrSelect(data, nextPreviousPaginationAdapter.previous),
        full_count: data.full_count,
      }
    },
  }),
  {
    name: "EstimatesList",
    effectCaller: rj.configured(),
    effect: (wpAuth) => (params = {}) =>
      api.auth(wpAuth).get("/api/estimate/", {
        page_size: 20,
        ...params,
        dataformat: "flat",
        is_template: params.is_template ?? false,
      }),
    selectors: ({ getPagination }) => ({
      getFullCount: (state) => {
        if (state.root) {
          return get(state, "root.data.pagination.full_count", 0)
        }
        return get(state, "data.pagination.full_count", 0)
      },
    }),
    computed: {
      estimates: "getList",
      count: "getCount",
      fullCount: "getFullCount",
      loading: "isPending",
      error: "getError",
      pageCount: "getNumPages",
      hasNext: "hasNext",
      hasPrev: "hasPrev",
      exportingPdfs: (s) => s.mutations.exportPDF.pendings,
    },
    mutations: {
      createEstimate: rj.mutation.single({
        effect: (wpAuth) => (estimateData) => {
          return api
            .auth(wpAuth)
            .post("/api/estimate/?dataformat=flat", estimateData)
        },
        updater: insertEstimateInList,
      }),
      updateEstimate: rj.mutation.multi(
        (id, data) => {
          return id
        },
        {
          effect: (wpAuth) => (id, data) => {
            return api.auth(wpAuth).patch(`/api/estimate/${id}/`, data)
          },
          updater: (state, result) => {
            return {
              ...state,
              data: {
                ...state.data,
                list: state.data.list.map((item) =>
                  item.id !== result.id ? item : result
                ),
              },
            }
          },
        }
      ),
      deleteEstimate: rj.mutation.multi((estimateId) => estimateId, {
        effect: (wpAuth) => (estimateId) =>
          api
            .auth(wpAuth)
            .mapResponse(() => estimateId)
            .delete(`/api/estimate/${estimateId}/`),
        updater: (state, estimateId) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.filter((item) => item.id !== estimateId),
            },
          }
        },
      }),
      cloneEstimate: {
        effect: (wpAuth) => (id, estimateData) => {
          return api
            .auth(wpAuth)
            .post(`/api/estimate/${id}/clone?dataformat=flat`, estimateData)
        },
        updater: insertEstimateInList,
      },
      templetizeEstimate: {
        effect: (wpAuth) => (id, estimateData) => {
          return api
            .auth(wpAuth)
            .post(
              `/api/estimate/${id}/templetize?dataformat=flat`,
              estimateData
            )
        },
        updater: insertEstimateInList,
      },
      instanceEstimatTemplate: {
        effect: (wpAuth) => (id, estimateData) => {
          return api
            .auth(wpAuth)
            .post(
              `/api/estimate/${id}/instance-template?dataformat=flat`,
              estimateData
            )
        },
        updater: insertEstimateInList,
      },
      createEstimateTemplate: {
        effect: (wpAuth) => (estimateData) => {
          return api.auth(wpAuth).post(`/api/estimate/template`, estimateData)
        },
        updater: insertEstimateInList,
      },
      exportPDF: rj.mutation.multi((estimateId) => estimateId, {
        effect: (wpAuth) => (id, docId) =>
          api
            .request({
              responseType: "blob",
            })
            .get(
              `${PDF_BASE_URL}/estimate-document/pdf/${id}/${docId}?t=Token ${wpAuth.token}&org=${wpAuth.organizationId}`
            ),
        updater: (s) => s, // Nothing to do
      }),
    },
  }
)

export function useEstimatesList(params) {
  return useRunRj(EstimatesListState, [params], false)
}

const EstimateFlowRj = rj({
  name: "EstimateFlow",
  effectCaller: rj.configured(),
  effect: (wpAuth) => (id) => api.auth(wpAuth).get(`/api/estimate/${id}/flow`),
  computed: {
    graph: "getData",
    loading: "isPending",
    error: "getError",
  },
})

export function useEstimateFlow(id) {
  return useRunRj(EstimateFlowRj, [id], true)
}

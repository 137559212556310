import dayjs from "dayjs"
import { Field, FormikContext, useFormik, useFormikContext } from "formik"
import { chain, get, groupBy, keyBy } from "lodash"
import useConstant from "magik-react-hooks/useConstant"
import React, { useEffect, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useAuthUser } from "use-eazy-auth"
import * as yup from "yup"
import { CAP, CAPLEVEL, useCapabilities } from "../../hooks/capabilities"
import { useProjectTaskCompatResources } from "../../hooks/projectTasks"
import Button from "../Button/Button"
import Loader from "../Loader/Loader"
import ProjectTaskSelector from "../ProjectTaskSelector"
import ProjectTeamMemberSelector from "../ProjectTeamMemberSelector"
import WpModal from "../WpModal/WpModal"
import FieldDropdown from "../fields/FieldDropdown"
import FieldInput from "../fields/FieldInput"
import FieldTextarea from "../fields/FieldTextarea"

const ACTIVITY_DEFAULTS = {
  subject: null,
  project: null,
  project_task: null,
  estimate: null,
  priced_resource: null,
  size: 1,
  date_start: null,
  time_start: null,
  date_end: null,
  time_end: null,
  description: "",
  lat: null,
  lon: null,
}

function FormikObserver({ path, onChange }) {
  const { values } = useFormikContext()
  const prevValue = useRef(null)
  const isMount = useRef(true)

  useEffect(() => {
    if (!isMount.current) {
      const curr = get(values, path)
      const prev = prevValue.current
      if (curr !== prev) {
        prevValue.current = curr
        onChange(curr, prev)
      }
    } else {
      isMount.current = false
      prevValue.current = get(values, path)
    }
  })

  return null
}

export default function ProjectActivityModal({ isOpen, toggle, save, onClosed, defaults }) {
  const { t } = useTranslation(["translation", "field"])
  const [, { hasCapability }] = useCapabilities()
  const { user } = useAuthUser()

  const schema = useMemo(() => {
    return yup.object().shape({
      project: yup
        .number()
        .typeError(
          t("field:errors.mandatory", {
            field: t("field:project_activity.project"),
          })
        )
        .required(
          t("field:errors.mandatory", {
            field: t("field:project_activity.project"),
          })
        ),
      project_task: yup
        .number()
        .typeError(
          t("field:errors.mandatory", {
            field: t("field:project_activity.project_task"),
          })
        )
        .required(
          t("field:errors.mandatory", {
            field: t("field:project_activity.project_task"),
          })
        ),
      priced_resource: yup
        .number()
        .typeError(
          t("field:errors.mandatory", {
            field: t("field:project_activity.priced_resource"),
          })
        )
        .required(
          t("field:errors.mandatory", {
            field: t("field:project_activity.priced_resource"),
          })
        ),
      date_start: yup
        .date()
        .typeError(
          t("field:errors.date", {
            field: t("field:project_activity.date_ref"),
          })
        )
        .required(
          t("field:errors.mandatory", {
            field: t("field:project_activity.date_ref"),
          })
        ),
      size: yup.number().required(
        t("field:errors.mandatory", {
          field: t("field:project_activity.size"),
        })
      ),
    })
  }, [t])

  const formik = useFormik({
    initialValues: {
      ...ACTIVITY_DEFAULTS,
      date_start: dayjs().format("YYYY-MM-DD"),
      subject: user.id,
      ...defaults,
    },
    onSubmit: (values, actions) => {
      return save(values).then(() => {
        toggle()
      })
    },
    validationSchema: schema,
  })

  const initialResourcesFilter = useConstant(() => {
    if (defaults.project_task) {
      return [defaults.project_task, { subject: defaults.subject ?? user.id }]
    } else {
      return null
    }
  })

  const [
    { data: rawPricedResources, pending: rawPricedResourcesLoading },
    { run: searchPricedResources, clean: cleanPricedResources },
  ] = useProjectTaskCompatResources()

  const preformFirstSearch = useRef(initialResourcesFilter ? 0 : 2)

  const { setFieldValue } = formik

  useEffect(() => {
    if (initialResourcesFilter && preformFirstSearch.current === 0) {
      preformFirstSearch.current = 1
      searchPricedResources
        .onSuccess((resources) => {
          if (defaults.priced_resource) {
            const record = resources.find((item) => item.id === defaults.priced_resource)
            if (record) {
              setFieldValue("resource", record.resource)
            }
          } else if (resources.length === 1) {
            setFieldValue("priced_resource", resources[0].id)
            setFieldValue("resource", resources[0].resource)
          }
          preformFirstSearch.current = 2
        })
        .run(...initialResourcesFilter)
    } else {
      preformFirstSearch.current = 2
    }
  }, [defaults.priced_resource, setFieldValue, initialResourcesFilter, searchPricedResources])

  const pricedResources =
    rawPricedResources?.map((d) => {
      return {
        id: d.id,
        name: d.resource_data.name,
        cost_unit: d.cost_unit,
        resource_id: d.resource,
      }
    }) ?? []

  const resources = chain(pricedResources)
    .map((pr) => ({
      id: pr.resource_id,
      name: pr.name,
    }))
    .uniqBy("id")
    .value()

  const pricedResourcesByResource = groupBy(pricedResources, "resource_id")

  const pricedResourcesById = keyBy(pricedResources, "id")

  if ((rawPricedResourcesLoading && preformFirstSearch.current === 1) || preformFirstSearch.current === 0) {
    return (
      <WpModal
        isOpen={isOpen}
        toggle={toggle}
        title={defaults?.id ? t("activity_modal.title_update") : t("activity_modal.title_create")}
        size="lg"
        onClosed={onClosed}
      >
        <div className="py-8">
          <Loader.BaseLoader />
        </div>
      </WpModal>
    )
  }

  return (
    <WpModal
      isOpen={isOpen}
      toggle={toggle}
      title={defaults?.id ? t("activity_modal.title_update") : t("activity_modal.title_create")}
      size="lg"
      onClosed={onClosed}
    >
      <FormikContext.Provider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="container container-fluid">
            <div className="row mb-5">
              <div className="col-6">
                <div className="d-flex flex-column">
                  <label className={"font-weight-semibold mb-3 h3 text-uppercase"}>
                    {t("field:project_activity.subject") + " *"}
                  </label>
                  <ProjectTeamMemberSelector
                    projectId={formik.values.project}
                    memberId={formik.values.subject}
                    onMemberChange={(nextValue) => {
                      formik.setFieldValue("subject", nextValue.user.id)
                    }}
                    placeholder={t("field:project_activity.placeholder.subject")}
                    capitalize={false}
                    emptyMessage={t("field:errors.no_match")}
                    oneLine
                    className="w-100"
                    disabled={!formik.values.project || !hasCapability(CAP.ACTIVITIES, CAPLEVEL.ADMIN)}
                  />
                  <FormikObserver
                    path="subject"
                    onChange={(subjectId) => {
                      formik.setFieldValue("project_task", null)
                      formik.setFieldValue("priced_resource", null)
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex flex-column">
                  <label className={"font-weight-semibold mb-3 h3 text-uppercase"}>
                    {t("field:project_activity.project_task") + " *"}
                  </label>
                  <ProjectTaskSelector
                    key={formik.values.project}
                    projectId={formik.values.project}
                    taskId={formik.values.project_task}
                    onTaskChange={(nextTask) => {
                      formik.setFieldValue("project_task", nextTask.id)
                    }}
                    placeholder={t("field:project_activity.placeholder.project_task")}
                    capitalize={false}
                    emptyMessage={t("field:errors.no_match")}
                    disabled={!formik.values.project}
                    oneLine
                    className="w-100"
                  />
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-6">
                <FormikObserver
                  path="project_task"
                  onChange={(taskId) => {
                    formik.setFieldValue("resource", null)
                    formik.setFieldValue("priced_resource", null)
                    cleanPricedResources()
                    searchPricedResources
                      .onSuccess((resources) => {
                        if (resources.length === 1) {
                          formik.setFieldValue("priced_resource", resources[0].id)
                          formik.setFieldValue("resource", resources[0].resource)
                        }
                      })
                      .run(taskId, { subject: formik.values.subject })
                  }}
                />
                <Field
                  key={formik.values.project_task}
                  name="resource"
                  label={t("field:project_activity.resource") + " *"}
                  placeholder={t("field:project_activity.placeholder.resource")}
                  capitalize={false}
                  component={FieldDropdown}
                  className="px-3 py-2"
                  options={
                    resources?.map((r) => ({
                      value: r.id,
                      label: r.name,
                    })) ?? []
                  }
                  disabled={!formik.values.project_task}
                />
              </div>
              <div className="col-4">
                <div className="d-flex flex-row justify-content-start align-items-center">
                  <div className="flex-1">
                    <FormikObserver
                      path="resource"
                      onChange={(resource) => {
                        if (resource) {
                          const suitablePricedResources = pricedResourcesByResource[resource]
                          if (suitablePricedResources.map((elem) => elem.id).indexOf(formik.values.priced_resource) < 0) {
                            formik.setFieldValue("priced_resource", null)
                          }
                        }
                      }}
                    />
                    <Field
                      key={formik.values.project_task}
                      name="priced_resource"
                      label={t("field:project_activity.cost_unit") + " *"}
                      placeholder={t("field:project_activity.placeholder.cost_unit")}
                      capitalize={false}
                      component={FieldDropdown}
                      className="px-3 py-2"
                      options={
                        pricedResourcesByResource[formik.values.resource]?.map((r) => ({
                          value: r.id,
                          label: r.cost_unit,
                        })) ?? []
                      }
                      disabled={!formik.values.resource}
                    />
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="d-flex flex-row justify-content-start align-items-center">
                  <div className="flex-1">
                    <Field
                      name="size"
                      type="number"
                      label={
                        (pricedResourcesById[formik.values.priced_resource]?.cost_unit ?? t("field:project_activity.size")) +
                        " *"
                      }
                      placeholder={t("field:project_activity.placeholder.size")}
                      component={FieldInput}
                      medium
                      disabled={!formik.values.priced_resource}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-4">
                <Field
                  type="date"
                  name="date_start"
                  label={t("field:project_activity.date_ref") + " *"}
                  component={FieldInput}
                  medium
                  style={{ height: 29 }}
                />
              </div>
              <div className="col-2">
                <Field
                  type="time"
                  name="time_start"
                  label={t("field:project_activity.time_start")}
                  component={FieldInput}
                  medium
                  style={{ height: 29 }}
                />
              </div>
              <div className="col-4">
                <Field
                  type="date"
                  name="date_end"
                  label={t("field:project_activity.date_end")}
                  component={FieldInput}
                  medium
                  style={{ height: 29 }}
                />
              </div>
              <div className="col-2">
                <Field
                  type="time"
                  name="time_end"
                  label={t("field:project_activity.time_end")}
                  component={FieldInput}
                  medium
                  style={{ height: 29 }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Field
                  name="description"
                  label={t("field:project.description")}
                  placeholder={t("field:project.placeholder.description")}
                  component={FieldTextarea}
                  rows={5}
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col text-right">
                <Button size="sm" color="none" className="mr-4" onClick={toggle}>
                  {t("action:cancel")}
                </Button>
                <Button type="submit" size="sm" disabled={!formik.isValid}>
                  {t("action:save")}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </FormikContext.Provider>
    </WpModal>
  )
}

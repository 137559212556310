import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAuthActions, useAuthState } from "use-eazy-auth"
import { ReactComponent as Artwork } from "../../assets/img/login-artwork.svg"
import logo from "../../assets/logo@2x.png"
import Button from "../../components/Button"
import GoogleAuthButton from "../../components/GoogleAuthButton"
import InlineLangSelector from "../../components/InlineLangSelector"
import MicrosoftAuthButton from "../../components/MicrosoftAuthButton"
import PasswordShowHide from "../../components/PasswordShowHide"
import WpInput from "../../components/WpInput"
import S from "./SignUp.module.scss"

export default function NewAccount({ changeMode, token, tokenData: data, emailInitial }) {
  const { t, i18n } = useTranslation()

  const { loginLoading, loginError } = useAuthState()
  const { login, clearLoginError } = useAuthActions()

  const [email, setEmail] = useState(emailInitial)
  const [password, setPassword] = useState("")
  const [tos, setTos] = useState(false)

  useEffect(() => {
    return () => clearLoginError()
  }, [clearLoginError])

  function handleSubmit(e) {
    e.preventDefault()
    if (!loginLoading) {
      login({
        provider: "registration",
        email: data?.email ?? email,
        lang: i18n.language,
        password,
        joinToken: token,
      })
    }
  }

  return (
    <div className="flex-1 d-flex flex-row justify-content-start align-items-stretch">
      <div className={S["artwork-container"]}>
        <div className={S["intermediate"]}>
          <Artwork preserveAspectRatio="xMinYMin slice" />
        </div>
      </div>
      <div className={S["main-container"]}>
        <div className={S["top"]}>
          <img src={logo} className={S["logo"]} alt="" />
          <div className={S["tools"]}>
            <span>
              {t("login.already_a_member")}{" "}
              <span className={S.a} role="link" onClick={() => changeMode()} >{t("login.sign_in")}</span>
            </span>
            <span className="pl-8" />
            <InlineLangSelector />
          </div>
        </div>

        <div className={S["form"]}>
          <div>
            <h1>{t("login.sign_up")}</h1>
            <p className="mb-8">{t("login.create_account_for_free")}</p>
            {!!token && (
              <p className="mb-8" style={{ width: 350 }}>{t("login.join_org", {org: data?.organization?.name ?? ""})}<br /><a href="/signup">{t("login.dismiss_invitation")}</a></p>
            )}
            <div className="d-flex flex-row justify-content-between align-items-center mt-8">
              <GoogleAuthButton joinToken={token} className="flex-1" />
              <div className="pl-4" />
              <MicrosoftAuthButton joinToken={token} className="flex-1" />
            </div>
            <div className="d-flex flex-row justify-content-start align-items-center mt-8">
              <div className="flex-1 border-bottom border-separator" />
              <div className="mx-4">
                <span>{t("login.or")}</span>
              </div>
              <div className="flex-1 border-bottom border-separator" />
            </div>
            <form
              onSubmit={handleSubmit}
              style={{ width: 350 }}
              className="mt-8"
            >
              <div className="d-flex flex-column">
                <label for="email">{t("login.email")}</label>
                <WpInput
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border-primary"
                />
                <p
                  className={classNames("text-danger mt-2 mb-0 line-height-1", {
                    [S["hidden"]]: !loginError,
                  })}
                >
                  {t("login.registration_error") || "placeholder"}
                </p>
              </div>
              <div className="d-flex flex-column mt-3">
                <label for="password">{t("login.password")}</label>
                <PasswordShowHide
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border-primary w-100"
                />
                <p
                  className={classNames("text-danger mt-2 mb-0 line-height-1", {
                    [S["hidden"]]: !loginError,
                  })}
                >
                  {t("login.registration_error") || "placeholder"}
                </p>
              </div>
              <div className="d-flex flex-row align-items-center mt-3">
                <WpInput
                  id="tos"
                  type="checkbox"
                  checked={tos}
                  onChange={(e) => setTos(e.target.checked)}
                  className="mr-3"
                />
                <label className={classNames("m-0", S["flat"])} for="tos">
                  {t("login.i_accept")}{" "}
                  <a
                    href={`https://wooplan.com/${i18n.language}/content/terms`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("login.link_terms_of_service")}
                  </a>{" "}
                  {t("login.of_wooplan")}
                </label>
              </div>
              <div className="mt-8">
                <Button className="w-100" type="submit" disabled={!tos}>
                  {t("login.sign_up")}
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className={S["bottom"]}>
          <span>
            <a
              href={`https://wooplan.com/${i18n.language}/content/terms`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("login.link_terms_of_service")}
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

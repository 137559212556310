import React from "react"
import CurrentPageIndicator from "./CurrentPageIndicator"
import PageNavigator from "./PageNavigator"
import JumpToPage from "./JumpToPage"

export default function Pagination({
  current,
  pageCount,
  goToPage,
  hasNext,
  hasPrev,
}) {
  return (
    <div className="d-flex flex-row justify-content-between align-items-center">
      <CurrentPageIndicator current={current} pageCount={pageCount} />
      <PageNavigator
        current={current}
        pageCount={pageCount}
        goToPage={goToPage}
        hasNext={hasNext}
        hasPrev={hasPrev}
      />
      <JumpToPage goToPage={goToPage} max={pageCount} />
    </div>
  )
}

import React from "react"
import { getIn } from "formik"
import classNames from "classnames"
import WpImageInput from "../WpImageInput/WpImageInput"
import Icon from "../Icon"

export default function FieldImageLinear({
  field,
  form: { touched, errors, setFieldValue },
  label,
  labelClassname,
  className,
  centered = false,
  enableDelete = false,
  readOnly = false,
  ...props
}) {
  const fieldError = getIn(errors, field.name)
  const fieldTouched = getIn(touched, field.name)
  const showError = fieldError && fieldTouched

  return (
    <div
      className={classNames("d-flex flex-row align-items-center", {
        "justify-content-center": centered,
      })}
    >
      {label && (
        <div className="d-flex flex-column">
          <label className={classNames("mr-4 mb-0", labelClassname)}>
            {label}
          </label>
          <span className={"invisible"}>{"."}</span>
        </div>
      )}
      <div className={classNames("d-flex flex-column", className)}>
        <div className="d-flex flex-row align-items-start">
          <WpImageInput
            {...props}
            src={field.value}
            readOnly={readOnly}
            onChange={(file) => {
              setFieldValue(field.name, file)
            }}
          />
          {enableDelete && !readOnly && (
            <Icon
              name="resource-delete"
              style={{ width: 12, height: 12 }}
              className={"text-separator pointer"}
              onClick={() => setFieldValue(field.name, null)}
            />
          )}
        </div>
        <span
          className={classNames("h4 text-danger ml-4 mt-1", {
            invisible: !showError,
            visible: showError,
          })}
        >
          {fieldError || "."}
        </span>
      </div>
    </div>
  )
}

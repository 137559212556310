import classNames from "classnames"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import Icon from "../../../components/Icon"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import ProjectTeamMemberModal from "../../../components/ProjectTeamModal"
import { ProjectContext } from "../../../context"
import { useProjectTeam } from "../../../hooks/projectTeam"
import useModalTrigger from "../../../hooks/useModalTrigger"
import ProjectBottomBarContent from "../ProjectBottomBarContent"
import ProjectTabBarContent from "../ProjectTabBarContent"
import S from "./ProjectTeam.module.scss"
import { FaRegStar, FaStar } from "react-icons/fa"
import { difference } from "lodash"
import { TrackerLandmarks } from "../../../components/TrackerLandmarks"

function MemberCard({ member, onEdit, onRemove, onElectLeader, isLeader }) {
  const { t } = useTranslation(["translation"])

  return (
    <div className={S["member-card"]}>
      <img src={member.user.picture} alt="" />
      <span
        className={S["username"]}
        title={member.user.full_name || member.user.email}
      >
        {member.user.full_name || member.user.email}
      </span>
      <span className={S["permissions"]}>
        {member.task_count >= 0
          ? t("project_team.task_count", {
              count: member.task_count,
            })
          : t("project_team.task_count_all")}
        {", "}
        {member.resource_count >= 0
          ? t("project_team.resource_count", {
              count: member.resource_count,
            })
          : t("project_team.resource_count_all")}
      </span>
      <div className={S["actions"]}>
        <div onClick={onEdit}>
          <Icon name="edit" />
        </div>
        <div onClick={onRemove}>
          <Icon name="delete" />
        </div>
        <div onClick={onElectLeader}>
          {isLeader ? <FaStar /> : <FaRegStar />}
        </div>
      </div>
    </div>
  )
}

export default function ProjectTeam() {
  const [{ project }, { updateProject }] = useContext(ProjectContext)
  const [
    { data: team },
    {
      update: updateTeamMember,
      create: createTeamMember,
      remove: removeTeamMember,
    },
  ] = useProjectTeam(project?.id)

  const [teamModalState, teamModalActions] = useModalTrigger()
  const [deleteModalState, deleteModalActions] = useModalTrigger()

  const teamLeader = team?.find(
    (member) => member.user.id === project.team_leader
  )
  const teamMembers = difference(team, [teamLeader])

  return (
    <Layout>
      <div className="container pt-8 px-page pb-page-bottom">
        {!team && <Loader />}
        {team && (
          <>
            <h1 className="text-primary text-center mb-8">
              Team {project.name}
            </h1>
            {teamLeader && (
              <>
                <p className="text-center">
                  <i>Responsabile</i>
                </p>
                <div className="d-flex flex-row justify-content-center align-items-start flex-wrap">
                  <MemberCard
                    isLeader
                    member={teamLeader}
                    onEdit={() => {
                      teamModalActions.open(teamLeader)
                    }}
                    onRemove={() => {
                      deleteModalActions.open(teamLeader)
                    }}
                    onElectLeader={() => {
                      updateProject(project.id, {
                        team_leader: null,
                      })
                    }}
                  />
                </div>
              </>
            )}
            <p className="mt-9 text-center">
              <i>Gruppo di lavoro</i>
            </p>
            <div className="d-flex flex-row justify-content-center align-items-start flex-wrap">
              {teamMembers.map((member) => {
                return (
                  <MemberCard
                    key={member.user.id}
                    member={member}
                    onEdit={() => {
                      teamModalActions.open(member)
                    }}
                    onRemove={() => {
                      deleteModalActions.open(member)
                    }}
                    onElectLeader={() => {
                      updateProject(project.id, {
                        team_leader: member.user.id,
                      })
                    }}
                  />
                )
              })}
              <div
                className={classNames(S["member-card"], S["placeholder"])}
                onClick={() => {
                  teamModalActions.open({})
                }}
              >
                <img
                  src="https://app.wooplan.com/jdenticon/initials?data=%2B&width=40&height=40&r=20"
                  alt=""
                />
                <i>Add member</i>
              </div>
            </div>
          </>
        )}
      </div>
      {teamModalState.value && (
        <ProjectTeamMemberModal
          isOpen={teamModalState.isOpen}
          toggle={teamModalActions.toggle}
          onClosed={teamModalActions.onClose}
          data={teamModalState.value}
          projectId={project.id}
          save={(data) => {
            if (teamModalState.value.user) {
              return updateTeamMember
                .onSuccess(() => {
                  teamModalActions.close()
                })
                .asPromise(project.id, data)
            } else {
              return createTeamMember
                .onSuccess(() => {
                  teamModalActions.close()
                })
                .asPromise(project.id, data)
            }
          }}
        />
      )}
      {deleteModalState.value && (
        <ConfirmDeleteModal
          isOpen={deleteModalState.isOpen}
          toggle={deleteModalActions.toggle}
          onClosed={deleteModalActions.onClose}
          item={deleteModalState.value.user.full_name}
          onConfirm={() => {
            removeTeamMember(project.id, deleteModalState.value)
          }}
        />
      )}
      <Layout.TabBar>
        <ProjectTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <ProjectBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

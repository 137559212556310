import { truncate } from "lodash"
import React from "react"
import S from "./TextTemplate.module.scss"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

export default function FragTextPreviewCard({ frag, onClick, selected }) {
  const { t } = useTranslation(["translation", "enums"])

  return (
    <div
      className={classNames(S["preview-card-container"])}
      onClick={() => onClick(frag.id)}
    >
      <div
        className={classNames("align-self-stretch", S["marker"], {
          [S["current"]]: selected,
          [S["not-current"]]: !selected,
        })}
      ></div>
      <div className="ml-2 mr-5 align-items-center flex-1">
        <div
          className={classNames(
            { "text-primary": selected },
            "py-5 pl-4 border-bottom"
          )}
        >
          <div className="d-flex flex-row align-items-start justify-content-between">
            <p className="text-uppercase font-weight-semibold h2">
              {frag.title}
            </p>
            {frag.archived && (
              <span className="badge badge-separator mt-2">
                {t("enums:text_template_state.archived_fragment")}
              </span>
            )}
          </div>
          {frag.text_content !== "" && (
            <p>{truncate(frag.text_content, { length: 80 })}</p>
          )}
        </div>
      </div>
    </div>
  )
}

import { rj, useRunRj } from "react-rocketjump"
import api from "../api"

const HrPeopleState = rj({
  effectCaller: rj.configured(),
  effect: wpAuth => () => {
    return api
      .auth(wpAuth)
      .get("/api/hr/people/")
  }
})

export function useHrPeople() {
  return useRunRj(HrPeopleState)
}
import { useTranslation } from "react-i18next"
import { CAP, useCapabilities } from "../hooks/capabilities"
import Icon from "./Icon"
import Layout from "./Layout"

export function EstimateLandmarks() {
  const { t } = useTranslation()
  const [, { hasCapability }] = useCapabilities()
  return (
    <>
      {hasCapability(CAP.ESTIMATES) && (
        <>
          <Layout.FirstLevelNavi.Tab text={t("navbar.estimate")} svgIcon={<Icon name="estimate-list" />} to={"/estimates"} />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
      {hasCapability(CAP.RESOURCES) && (
        <>
          <Layout.FirstLevelNavi.Tab text={t("navbar.resources")} svgIcon={<Icon name="resources" />} to={"/resources"} />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
      {hasCapability(CAP.TEMPLATES) && (
        <>
          <Layout.FirstLevelNavi.Tab text={t("navbar.template")} svgIcon={<Icon name="template" />} to={"/templates"} />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
      {hasCapability(CAP.RESOURCES) && (
        <>
          <Layout.FirstLevelNavi.Tab text={t("navbar.priceLists")} svgIcon={<Icon name="price_lists" />} to={"/pricelists"} />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
    </>
  )
}

import { useMemo } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { prefixLocationWithOrg } from "../utils"

/**
 * Hack original useHistory() with org prefix in push / replace
 *
 * NOTE: "methods" suffix is to explicit that we avoid to mirror
 * mutable stuff on histroy, if you need this stuff use the orginal hook.
 */
export default function useWpHistoryMethods() {
  const match = useRouteMatch("/org/:organization")
  const organization = match?.params?.organization ?? null

  const history = useHistory()

  const wpHistoryMethods = useMemo(
    () => ({
      goBack: history.goBack.bind(history),
      go: history.go.bind(history),
      goForward: history.goForward.bind(history),
      push: (path, state) =>
        history.push(prefixLocationWithOrg(path, organization), state),
      replace: (path, state) =>
        history.replace(prefixLocationWithOrg(path, organization), state),
    }),
    [organization, history]
  )

  return wpHistoryMethods
}

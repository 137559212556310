import { useLocation } from "react-router-dom"
import useWpHistoryMethods from "../../../hooks/useWpHistoryMethods"
import { CAP, CAPLEVEL, useCapabilities } from "../../../hooks/capabilities"

function NavbarEntry({ label, pageUrl }) {
  const history = useWpHistoryMethods()
  const location = useLocation()

  return (
    <div
      className={`pointer px-5 py-3 ${location.pathname.endsWith(pageUrl) ? "bg-primary text-white" : ""}`}
      onClick={() => {
        history.push(pageUrl)
      }}
    >
      {label}
    </div>
  )
}

export function Navbar() {
  const [, { hasCapability }] = useCapabilities()
  const isHrAdmin = hasCapability(CAP.WORKFLOW, CAPLEVEL.ADMIN)

  return (
    <div className="px-page bg-primary-10 d-flex flex-row justify-content-start align-items-stretch">
      <NavbarEntry label="Calendario" pageUrl="/hr/workflow" />
      <NavbarEntry label="Lista" pageUrl="/hr/workflow-list" />
      <NavbarEntry label="Regole" pageUrl="/hr/workflow-rules" />
      {isHrAdmin && <NavbarEntry label="Dipendenti" pageUrl="/hr/workflow-resources" />}
    </div>
  )
}

import { Field, Formik } from "formik"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import Icon from "../../components/Icon"
import Layout from "../../components/Layout"
import { useProfile } from "../../hooks/profile"
import * as yup from "yup"
import Button from "../../components/Button"
import classNames from "classnames"
import S from "./Profile.module.scss"
import FieldImageLinear from "../../components/fields/FieldImageLinear"
import { convertErrorToForm } from "../../utils"
import Loader from "../../components/Loader/Loader"
import FieldInput from "../../components/fields/FieldInput"
import useModalTrigger from "../../hooks/useModalTrigger"
import SetPasswordModal from "./SetPasswordModal"
import { toast } from "react-toastify"
import FieldDropdown from "../../components/fields/FieldDropdown"
import ReactCountryFlag from "react-country-flag"
import ConfirmDeleteModal from "./ConfirmDeleteModal"
import { Link } from "react-router-dom"

export default function Profile() {
  const [
    { profile },
    { updateProfile, changePassword, deleteProfile },
  ] = useProfile()

  const { t } = useTranslation(["translation", "action", "tab", "field"])

  const [setPasswordModal, setPasswordModalActions] = useModalTrigger()
  const [deleteAccountModal, deleteAccountModalActions] = useModalTrigger()

  const onPasswordSet = useCallback(
    (oldPassword, newPassword) => {
      return changePassword
        .onSuccess(() => {
          setPasswordModalActions.close()
        })
        .onFailure((error) => {
          //TODO handle errors
        })
        .asPromise(oldPassword, newPassword)
    },
    [changePassword, setPasswordModalActions]
  )

  const schema = useMemo(() => {
    return yup.object().shape({
      full_name: yup
        .string()
        .max(250, t("field:errors.max", { max_value: 250 })),
      email: yup
        .string()
        .email(t("field:errors.email"))
        .required(
          t("field:errors.mandatory", { field: t("field:profile.email") })
        ),
    })
  }, [t])

  return (
    <Layout>
      {!profile && <Loader />}
      {profile && (
        <Formik
          initialValues={{
            ...profile,
          }}
          validationSchema={schema}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            const { picture, company_logo, ...payload } = values
            if (
              picture instanceof File ||
              picture instanceof Blob ||
              picture === null
            ) {
              payload.picture = picture
            }
            updateProfile
              .onSuccess(() => {
                actions.setSubmitting(false)
                toast.success(t("profile.updated_message"))
              })
              .onFailure((error) => {
                actions.setSubmitting(false)
                actions.setErrors(convertErrorToForm(error))
              })
              .run(payload)
          }}
        >
          {(formik) => (
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="container pt-5 px-half-page pb-page-bottom">
                <div className="d-flex flex-column px-page mb-8">
                  <Field
                    name="picture"
                    component={FieldImageLinear}
                    centered
                    className="pt-4"
                    enableDelete
                    placeholder={profile.initials}
                  />
                  <h2 className="text-uppercase font-semibold text-center pt-3 pb-4">
                    {t("field:profile.personal_data")}
                  </h2>

                  <div className="d-flex flex-row justify-content-center">
                    <div className="flex-1">
                      <Field
                        name="full_name"
                        className="mr-8"
                        label={t("field:profile.full_name")}
                        placeholder={t("field:profile.placeholder.full_name")}
                        component={FieldInput}
                      />
                    </div>
                    <div className="flex-1">
                      <Field
                        name="email"
                        label={t("field:profile.email")}
                        placeholder={t("field:profile.placeholder.email")}
                        component={FieldInput}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row justify-content-center mt-5">
                    <div className="flex-1 d-flex flex-column">
                      <label
                        className={classNames(
                          "text-uppercase font-weight-semibold"
                        )}
                      >
                        {t("field:profile.password")}
                      </label>
                      <div>
                        <Button
                          color={"primary"}
                          size={"sm"}
                          className="align-self-start"
                          onClick={setPasswordModalActions.open}
                        >
                          {profile.has_password
                            ? t("action:reset_password")
                            : t("action:set_password")}
                        </Button>
                      </div>
                    </div>
                    <div className="flex-1">
                      <Field
                        name="lang"
                        label={t("field:profile.lang")}
                        component={FieldDropdown}
                        options={[
                          {
                            label: (
                              <span className="d-inline-flex align-items-center">
                                <ReactCountryFlag
                                  svg
                                  countryCode="IT"
                                  className="mr-2"
                                />
                                Italiano
                              </span>
                            ),
                            value: "it",
                          },
                          {
                            label: (
                              <span className="d-inline-flex align-items-center">
                                <ReactCountryFlag
                                  svg
                                  countryCode="GB"
                                  className="mr-2"
                                />
                                English
                              </span>
                            ),
                            value: "en",
                          },
                        ]}
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-row mb-6 border-danger border rounded-lg mt-8">
                    <div className="py-4 px-8 w-100">
                      <label className="font-weight-semibold mb-3 h3 text-uppercase">
                        {t("profile.danger_zone")}
                      </label>
                      <div className="w-100 text-right">
                        <p className="mb-3 text-left">
                          {t("profile.delete_profile_msg")}
                        </p>
                        <p className="mb-3 text-left">
                          {t("profile.delete_profile_msg_orgs")}
                        </p>
                        <p className="mb-0 text-left">
                          {t("profile.delete_profile_msg_orgs_list")}
                          <ul>
                            {profile.organizationsIds
                              .map((id) => profile.organizationsById[id])
                              .filter((org) => org.is_owned)
                              .map((org) => (
                                <li key={org.id}>
                                  <Link to={`/org/${org.id}/settings`}>
                                    {org.name}
                                  </Link>
                                  {org.id === profile.personal_organization &&
                                    ` (${t("profile.personal_org")})`}
                                </li>
                              ))}
                          </ul>
                        </p>
                        <Button
                          color="danger"
                          size="sm"
                          className="ml-8"
                          onClick={() => {
                            deleteAccountModalActions.open()
                          }}
                        >
                          {t("action:delete_profile")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  "d-flex flex-row justify-content-center py-3",
                  S["save-bar"]
                )}
              >
                <Button
                  color="none"
                  size="lg"
                  className="mr-8"
                  onClick={formik.resetForm}
                >
                  {t("action:cancel")}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  disabled={!formik.isValid}
                  size="lg"
                >
                  {t("action:save")}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}

      <SetPasswordModal
        toggle={setPasswordModalActions.toggle}
        isOpen={setPasswordModal.isOpen}
        onConfirm={onPasswordSet}
        onClosed={setPasswordModalActions.onClose}
        hasPassword={profile.has_password}
      />

      <ConfirmDeleteModal
        toggle={deleteAccountModalActions.toggle}
        isOpen={deleteAccountModal.isOpen}
        onConfirm={(passwd) => {
          deleteProfile
            .onFailure(() => {
              toast.error(t("profile.wrong_password"))
            })
            .run(passwd)
        }}
        onClosed={deleteAccountModalActions.onClose}
      />

      <Layout.TabBar>
        <Layout.TabBar.Divider />
        <Layout.TabBar.TabBarTab
          text={t("tab:profile.general")}
          to={"/profile/"}
          svgIcon={<Icon name="home" />}
        />
        <Layout.TabBar.Divider />
        {/* <Layout.TabBar.TabBarTab
          text={t("tab:profile.privacy")}
          to={"/profile/privacy"}
          svgIcon={<Icon name="privacy" />}
        />
        <Layout.TabBar.Divider /> */}
        {/* <Layout.TabBar.TabBarTab
          text={t("tab:profile.help")}
          to={"/profile/help"}
          svgIcon={<Icon name="help" />}
        />
        <Layout.TabBar.Divider /> */}
      </Layout.TabBar>
    </Layout>
  )
}

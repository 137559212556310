import React from "react"
import { ComplianceIssueReportList as Impl } from "../../components/ComplianceIssueReportList"
import Layout from "../../components/Layout"
import { TrackerLandmarks } from "../../components/TrackerLandmarks"

export function GlobalComplianceIssueReportList() {
  return (
    <Layout>
      <Impl showCustomer showProject />
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import { useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function FormNoFieldError({ className = '' }) {
  const { t } = useTranslation(['translation'])
  const { errors } = useFormikContext()

  if (!errors.__noFieldsServerError) {
    return null
  }
  // TODO: Improve ....
  return (
    <div className={`alert alert-danger ${className}`}>
      {t('error_notify_message')}
    </div>
  )
}
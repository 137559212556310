import classNames from "classnames"
import dayjs from "dayjs"
import { isNil, range, sumBy } from "lodash"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import React from "react"
import { useTranslation } from "react-i18next"
import { IndicatorsLandmarks } from "../../components/IndicatorsLandmarks"
import Layout from "../../components/Layout"
import WpInput from "../../components/WpInput"
import { useBusinessMonitor } from "../../hooks/businessMonitor"
import { useAnalysisFilters } from "../../hooks/useAnalysisFilters"
import S from "./style.module.scss"
import { BsInfoCircleFill } from "react-icons/bs"
import { UncontrolledTooltip } from "reactstrap"
import useWpHistoryMethods from "../../hooks/useWpHistoryMethods"

const MONTHS = [
  "gennaio",
  "febbraio",
  "marzo",
  "aprile",
  "maggio",
  "giugno",
  "luglio",
  "agosto",
  "settembre",
  "ottobre",
  "novembre",
  "dicembre",
]

export function BusinessMonitoring() {
  const { t } = useTranslation()
  const { filters, renderFilters } = useAnalysisFilters()
  const history = useWpHistoryMethods()

  const [
    { fromDate, toDate, search },
    ,
    { fromDate: debFromDate, toDate: debToDate, search: debSearch },
    setDebQueryParams,
  ] = useQueryParams()

  const [{ data }] = useBusinessMonitor(debFromDate, debToDate, debSearch, filters)

  const numOfMonths =
    debFromDate && debToDate
      ? dayjs(debToDate).endOf("month").add(1, "day").diff(dayjs(debFromDate).startOf("month"), "month")
      : 0

  const iterDateBase = debFromDate ? dayjs(fromDate).startOf("month") : null
  const iterBaseMonth = iterDateBase?.get("month")
  const iterBaseYear = iterDateBase?.get("year")

  return (
    <Layout className="flex-1 d-flex flex-column justify-content-start align-items-stretch" displayRawContent>
      <div className={S.outer}>
        <div className={classNames("px-page pt-8", S["sticky-left"])} style={{ zIndex: 50 }}>
          <h1 className="text-primary font-weight-semibold">{t("business.title")}</h1>
          <div className="d-flex flex-row justify-content-start align-items-start">
            <div
              className="d-flex flex-row justify-content-start align-items-center flex-1 flex-wrap"
              style={{ rowGap: 16, columnGap: 32 }}
            >
              <div style={{ whiteSpace: "nowrap" }}>
                <span className="mr-3">{t("business.search")}</span>
                <WpInput
                  medium
                  value={search}
                  onChange={(e) => setDebQueryParams({ search: e.target.value, page: 1 })}
                  className="mr-3"
                  placeholder={t("business.search_placeholder")}
                  style={{ width: 250 }}
                />
              </div>
              <div style={{ whiteSpace: "nowrap" }}>
                <span className="mr-3">{t("business.period_filter_from")}</span>
                <WpInput
                  medium
                  type="date"
                  value={fromDate}
                  onChange={(e) => setDebQueryParams({ fromDate: e.target.value, page: 1 })}
                />
                <span className="mx-3">{t("business.period_filter_to")}</span>
                <WpInput
                  medium
                  type="date"
                  value={toDate}
                  onChange={(e) => setDebQueryParams({ toDate: e.target.value, page: 1 })}
                />
              </div>
              <div style={{ whiteSpace: "nowrap" }}>{renderFilters()}</div>
            </div>
          </div>
        </div>
        {!(!!numOfMonths && numOfMonths > 0 && numOfMonths < 24) && (
          <div className="px-page pt-8">
            <i>
              Impossibile mostrare questo intervallo, verificare che le date siano ordinate correttamente e che il periodo non
              superi i 24 mesi
            </i>
          </div>
        )}
        {!!numOfMonths && numOfMonths > 0 && numOfMonths < 24 && (
          <table
            className="table table-sm table-striped mt-5"
            style={{
              tableLayout: "fixed",
              borderCollapse: "separate",
              borderSpacing: 0,
            }}
          >
            <thead>
              <tr>
                <th className={classNames("bg-white", S["sticky-left"], "border-right")} style={{ width: 200 }}>
                  &nbsp;
                </th>
                <th className={classNames("bg-white", S["sticky-left-2"], "border-right")} style={{ width: 200 }}>
                  &nbsp;
                </th>
                {range(numOfMonths).map((n) => (
                  <React.Fragment key={n}>
                    <th colSpan={3} style={{ width: 125 * 3 }} className="text-center border-right">
                      {t(MONTHS[(iterBaseMonth + n) % 12]) + " " + (iterBaseYear + Math.floor((iterBaseMonth + n) / 12))}
                    </th>
                  </React.Fragment>
                ))}
              </tr>
              <tr>
                <th className={classNames(S["sticky-left"], "border-right", "bg-white")}>{t("business.customer")}</th>
                <th className={classNames(S["sticky-left-2"], "border-right", "bg-white")}>{t("business.object")}</th>
                {range(numOfMonths).map((n) => (
                  <React.Fragment key={n}>
                    <th style={{ whiteSpace: "nowrap", width: 125 }} className="border-right">
                      {t("business.billed")}
                    </th>
                    <th style={{ whiteSpace: "nowrap", width: 125 }} className="border-right">
                      {t("business.to_bill")}
                    </th>
                    <th style={{ whiteSpace: "nowrap", width: 125 }} className="border-right">
                      {t("business.maybe_bill")}
                    </th>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((item, i) => {
                return (
                  <tr key={item.kind + item.id}>
                    <th
                      style={{
                        whiteSpace: "nowrap",
                        background: i % 2 === 1 ? "white" : "#f5f5f5",
                      }}
                      className={classNames(S["sticky-left"], "border-right")}
                    >
                      {item.customer_name}
                    </th>
                    <th
                      style={{
                        whiteSpace: "nowrap",
                        background: i % 2 === 1 ? "white" : "#f5f5f5",
                      }}
                      className={classNames(S["sticky-left-2"], "border-right")}
                    >
                      {item.name}
                    </th>
                    {range(numOfMonths).map((n) => {
                      const billed = sumBy(item.billed[n], "amount")
                      const to_bill = sumBy(item.to_bill[n], "amount")
                      const maybe_bill = sumBy(item.maybe_bill[n], "amount")
                      return (
                        <React.Fragment key={n}>
                          <td style={{ whiteSpace: "nowrap" }} className="border-right text-right">
                            <div className="px-2">
                              {formatEuro(billed)}
                              {billed > 0 && (
                                <>
                                  <BsInfoCircleFill
                                    className="ml-3 pointer"
                                    id={`billed-${item.id}-${n}`}
                                    onClick={() => {
                                      if (item.kind === "project") {
                                        history.push(`/projects/${item.id}/bills`)
                                      } else {
                                        history.push(`/estimates/${item.id}/billing`)
                                      }
                                    }}
                                  />
                                  <UncontrolledTooltip placement="bottom" target={`billed-${item.id}-${n}`}>
                                    <ComputationTooltip items={item.billed[n]} />
                                  </UncontrolledTooltip>
                                </>
                              )}
                            </div>
                          </td>
                          <td style={{ whiteSpace: "nowrap" }} className="border-right text-right">
                            <div className="px-2">
                              {formatEuro(to_bill)}
                              {to_bill > 0 && (
                                <>
                                  <BsInfoCircleFill
                                    className="ml-3 pointer"
                                    id={`to-bill-${item.id}-${n}`}
                                    onClick={() => {
                                      if (item.kind === "project") {
                                        history.push(`/projects/${item.id}/bills`)
                                      } else {
                                        history.push(`/estimates/${item.id}/billing`)
                                      }
                                    }}
                                  />
                                  <UncontrolledTooltip placement="bottom" target={`to-bill-${item.id}-${n}`}>
                                    <ComputationTooltip items={item.to_bill[n]} />
                                  </UncontrolledTooltip>
                                </>
                              )}
                            </div>
                          </td>
                          <td style={{ whiteSpace: "nowrap" }} className="border-right text-right">
                            <div className="px-2">
                              {formatEuro(maybe_bill)}
                              {maybe_bill > 0 && (
                                <>
                                  <BsInfoCircleFill
                                    className="ml-3 pointer"
                                    id={`maybe-bill-${item.id}-${n}`}
                                    onClick={() => {
                                      if (item.kind === "project") {
                                        history.push(`/projects/${item.id}/bills`)
                                      } else {
                                        history.push(`/estimates/${item.id}/billing`)
                                      }
                                    }}
                                  />
                                  <UncontrolledTooltip placement="bottom" target={`maybe-bill-${item.id}-${n}`}>
                                    <ComputationTooltip items={item.maybe_bill[n]} />
                                  </UncontrolledTooltip>
                                </>
                              )}
                            </div>
                          </td>
                        </React.Fragment>
                      )
                    })}
                  </tr>
                )
              })}
              <tr className="font-weight-bold">
                <td
                  style={{ whiteSpace: "nowrap", background: (data?.length ?? 0) % 2 === 1 ? "white" : "#f5f5f5" }}
                  className={classNames(S["sticky-left"], "border-right")}
                >
                  {t("total")}
                </td>
                <td
                  style={{ whiteSpace: "nowrap", background: (data?.length ?? 0) % 2 === 1 ? "white" : "#f5f5f5" }}
                  className={classNames(S["sticky-left-2"], "border-right")}
                />

                {range(numOfMonths).map((n) => {
                  return (
                    <React.Fragment key={n}>
                      <td style={{ whiteSpace: "nowrap" }} className="border-right text-right">
                        <div className="px-2">
                          {formatEuro(data?.reduce((acc, item) => acc + parseFloat(sumBy(item.billed[n], "amount")), 0))}
                          <BsInfoCircleFill className="invisible ml-3" />
                        </div>
                      </td>
                      <td style={{ whiteSpace: "nowrap" }} className="border-right text-right">
                        <div className="px-2">
                          {formatEuro(data?.reduce((acc, item) => acc + parseFloat(sumBy(item.to_bill[n], "amount")), 0))}
                          <BsInfoCircleFill className="invisible ml-3" />
                        </div>
                      </td>
                      <td style={{ whiteSpace: "nowrap" }} className="border-right text-right">
                        <div className="px-2">
                          {formatEuro(data?.reduce((acc, item) => acc + parseFloat(sumBy(item.maybe_bill[n], "amount")), 0))}
                          <BsInfoCircleFill className="invisible ml-3" />
                        </div>
                      </td>
                    </React.Fragment>
                  )
                })}
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <Layout.FirstLevelNavi>
        <IndicatorsLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

function ComputationTooltip({ items }) {
  return (
    <table>
      <tbody>
        {items.map((item) => (
          <tr key={item.id}>
            <td className="text-right">{item.amount} €</td>
            <td className="text-left">{"- " + item.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

function formatEuro(value) {
  if (isNil(value)) {
    return <span>-</span>
  } else if (value === 0) {
    return <span></span>
  } else {
    return <span>{parseFloat(value).toFixed(2) + " €"}</span>
  }
}

import classNames from "classnames"
import dayjs from "dayjs"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import React, { useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import DropdownItem from "reactstrap/lib/DropdownItem"
import DropdownMenu from "reactstrap/lib/DropdownMenu"
import DropdownToggle from "reactstrap/lib/DropdownToggle"
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown"
import Button from "../../../components/Button/Button"
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal"
import Icon from "../../../components/Icon"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import LongBorder from "../../../components/LongBorder"
import Pagination from "../../../components/Pagination/Pagination"
import ProjectActivityModal from "../../../components/ProjectActivityModal/ProjectActivityModal"
import ProjectTaskSelector from "../../../components/ProjectTaskSelector"
import Table from "../../../components/Table"
import { TrackerLandmarks } from "../../../components/TrackerLandmarks"
import WpInput from "../../../components/WpInput/WpInput"
import { ProjectContext } from "../../../context"
import { useProjectActivityList } from "../../../hooks/projectActivities"
import useModalTrigger from "../../../hooks/useModalTrigger"
import S from "../CommonStyle.module.scss"
import ProjectBottomBarContent from "../ProjectBottomBarContent"
import ProjectTabBarContent from "../ProjectTabBarContent"
import { MdOutlineFileDownload } from "react-icons/md"
import { omit } from "lodash"
import { Spinner } from "reactstrap"
import { toast } from "react-toastify"
import { saveAs } from "file-saver"

export default function ProjectActivity({ history, location }) {
  const { t } = useTranslation(["translation", "field", "action"])

  const [
    { fromDate, toDate, resourceName, subjectName, projectTask },
    setQueryParams,
    {
      page: debPage,
      fromDate: debFromDate,
      toDate: debToDate,
      resourceName: debResourceName,
      subjectName: debSubjectName,
      projectTask: debProjectTask,
    },
    setDebQueryParams,
  ] = useQueryParams({
    page: {
      encode: (intValue) => intValue.toString(10),
      decode: (strValue) => (strValue ? parseInt(strValue, 10) : 1),
    },
  })

  const [{ project }] = useContext(ProjectContext)

  const filters = useMemo(
    () => ({
      project: project.id,
      page: debPage,
      date_start__gte: debFromDate,
      date_start__lte: debToDate,
      resource_name: debResourceName,
      subject_name: debSubjectName,
      project_task: debProjectTask,
      ordering: "date_ref",
    }),
    [project.id, debPage, debFromDate, debToDate, debResourceName, debSubjectName, debProjectTask]
  )

  const [
    { activities, pageCount, hasNext, hasPrev, count, fullCount },
    { create, update, remove, download },
  ] = useProjectActivityList(filters)

  const [displayFilters, setDisplayFilters] = useState(!!location.search)
  const [downloading, setDownloading] = useState(false)

  const [createModal, createModalActions] = useModalTrigger()
  const [deleteModal, deleteModalActions] = useModalTrigger()

  const columns = useMemo(() => {
    return [
      {
        label: t("field:project_activity.date_ref"),
        name: "date_start",
        size: 3,
        render: (selectedDatum) => dayjs(selectedDatum).format("DD/MM/YYYY"),
      },
      {
        label: t("field:project_activity.subject"),
        name: "subject",
        size: 3,
        render: (selectedDatum, index, datum) => {
          return datum.subject_data.full_name || datum.subject_data.email
        },
      },
      {
        label: t("field:project_activity.priced_resource"),
        name: "priced_resource_data",
        size: 4,
        render: (selectedDatum) => selectedDatum.resource_name,
      },
      {
        label: t("field:project_activity.description"),
        name: "description",
        size: 6,
        render: (selectedDatum, index, datum) => {
          return <div className={S["description-" + (index % 2 ? "odd" : "even")]}>{selectedDatum}</div>
        },
      },
      {
        label: t("field:project_activity.project_task"),
        name: "project_task",
        size: 3,
        render: (selectedDatum, index, datum) => {
          return (
            <div className="d-flex flex-column justify-content-start align-items-start">
              {datum.project_task_data.estimate_code && <small>{datum.project_task_data.estimate_code}</small>}
              <span>{datum.project_task_data.code}</span>
            </div>
          )
        },
      },
      {
        label: t("field:project_activity.size"),
        name: "size",
        size: 3,
        render: (selectedDatum, index, datum) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <span>{datum.priced_resource_data.cost_unit}</span>
              <span>{selectedDatum}</span>
            </div>
          )
        },
      },
      {
        label: t("actions"),
        name: "actions",
        size: 2,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <div className={"action-icon-container-primary-hover"}>
                <Icon
                  name="edit"
                  title={t("action:edit_project_activity")}
                  className="pointer text-dark"
                  onClick={() => {
                    createModalActions.open(datum)
                  }}
                />
              </div>
              <div className={"action-icon-container-primary-hover"}>
                <UncontrolledDropdown>
                  <DropdownToggle caret={false} tag={"span"}>
                    <Icon
                      role="button"
                      name="vdots"
                      className="text-dark pointer"
                      title={t("action:other_options")}
                      placement="right"
                    />
                  </DropdownToggle>
                  <DropdownMenu right modifiers={{ offset: { offset: "0, 12" } }} className="border-primary">
                    {!datum.archived && (
                      <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                        <div
                          className="d-flex flex-row align-items-center mx-4"
                          onClick={() => {
                            deleteModalActions.open(datum)
                          }}
                        >
                          <Icon name="delete" className={classNames("pointer mr-4")} />
                          <span>{t("action:delete")}</span>
                        </div>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )
        },
      },
    ]
  }, [createModalActions, deleteModalActions, t])

  return (
    <Layout>
      <div className="container-fluid pt-8 px-page pb-page-bottom">
        <div className="d-flex flex-row justify-content-between align-items-center mb-6">
          <div>
            <Button
              className="d-inline-flex align-items-center px-5 mr-5"
              disabled={!project.accept_activities}
              onClick={() => {
                createModalActions.open({
                  project: project.id,
                })
              }}
            >
              <Icon name="plus-rounded" className="mr-2" height={16} width={16} />
              {t("action:record_activity")}
            </Button>
            <Button
              className="d-inline-flex align-items-center px-5 mr-5"
              active={displayFilters}
              onClick={() => setDisplayFilters((v) => !v)}
            >
              <Icon name="filter" className="mr-2" height={16} width={16} />
              {t("action:filter_activities")}
            </Button>
            <Button
              className="d-inline-flex align-items-center px-5 mr-5"
              onClick={() => {
                setDownloading(true)
                download
                  .asPromise(omit(filters, ["page"]))
                  .then((blob) => {
                    saveAs(blob, `activities_${project.id}.csv`)
                  })
                  .catch((err) => {
                    console.error(err)
                    toast.error(t("error_download"))
                  })
                  .finally(() => {
                    setDownloading(false)
                  })
              }}
            >
              {!downloading && <MdOutlineFileDownload size={16} className="mr-2" />}
              {downloading && <Spinner size="sm" className="mr-2" />}
              {t("action:download_csv")}
            </Button>
          </div>
          <div>
            {t("activity_list.activity_count", {
              filtered: count,
              count: fullCount,
            })}
          </div>
        </div>
        {displayFilters && (
          <div className="d-flex flex-row justify-content-start align-items-center mb-6">
            <div className="mr-8">
              <label className={classNames(S["filter-label"], "d-block")}>{t("activity_list.period_filter")}</label>
              <WpInput
                medium
                className="mr-5"
                type="date"
                value={fromDate}
                onChange={(e) => setDebQueryParams({ fromDate: e.target.value })}
              />
              <WpInput medium type="date" value={toDate} onChange={(e) => setDebQueryParams({ toDate: e.target.value })} />
            </div>
            <div className="mr-8">
              <label className={classNames(S["filter-label"], "d-block")}>{t("activity_list.resource_name_filter")}</label>
              <WpInput
                medium
                placeholder={t("activity_list.resource_name_filter_placeholder")}
                value={resourceName}
                onChange={(e) => setDebQueryParams({ resourceName: e.target.value })}
              />
            </div>
            <div className="mr-8">
              <label className={classNames(S["filter-label"], "d-block")}>{t("activity_list.subject_name_filter")}</label>
              <WpInput
                medium
                placeholder={t("activity_list.subject_name_filter_placeholder")}
                value={subjectName}
                onChange={(e) => setDebQueryParams({ subjectName: e.target.value })}
              />
            </div>
            <div className="mr-8">
              <label className={classNames(S["filter-label"], "d-block")}>{t("activity_list.project_task_filter")}</label>
              <ProjectTaskSelector
                projectId={project.id}
                taskId={projectTask}
                onTaskChange={(nextTask) => {
                  setQueryParams({ projectTask: nextTask?.id ?? undefined })
                }}
                placeholder={t("activity_list.project_task_filter_placeholder")}
                capitalize={false}
                emptyMessage={t("field:errors.no_match")}
                clearable
                style={{ width: 250 }}
                oneLine
              />
            </div>
            <Button
              className="d-inline-flex align-items-center px-5 mr-5"
              onClick={() => history.push(location.pathname.replace("activity", "insight") + location.search)}
              style={{ marginTop: 22 }}
            >
              <Icon name="project-insight" className="mr-2" height={16} width={16} />
              {t("action:analyze_activities")}
            </Button>
          </div>
        )}
        {!project && <Loader />}
        {project && activities && (
          <>
            <LongBorder topBar="tabbar" />
            <Table data={activities} columns={columns} use24 stickyHeader stickyTopBar="tabbar" className="pb-8" />
            <Pagination
              current={debPage}
              pageCount={pageCount}
              goToPage={(page) => {
                setQueryParams({ page })
                window.scrollTo(0, 10)
              }}
              hasNext={hasNext}
              hasPrev={hasPrev}
            />
          </>
        )}
      </div>

      {createModal.value && (
        <ProjectActivityModal
          isOpen={createModal.isOpen}
          toggle={createModalActions.toggle}
          onClosed={createModalActions.onClose}
          defaults={createModal.value}
          save={(data) => {
            if (data.id) {
              update
                .onSuccess(() => {
                  createModalActions.close()
                })
                .asPromise(data.id, data)
            } else {
              create
                .onSuccess(() => {
                  createModalActions.close()
                })
                .asPromise(data)
            }
          }}
        />
      )}
      {deleteModal.value && (
        <ConfirmDeleteModal
          isOpen={deleteModal.isOpen}
          toggle={deleteModalActions.toggle}
          onClosed={deleteModalActions.onClose}
          item={
            deleteModal.value.priced_resource_data.resource_name +
            " " +
            dayjs(deleteModal.value.start_date).format("DD/MM/YYYY")
          }
          onConfirm={() => {
            remove(deleteModal.value.id)
          }}
        />
      )}
      <Layout.TabBar>
        <ProjectTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <ProjectBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import React from "react"
import { useTranslation } from "react-i18next"
import WpModal from "../WpModal"
import classNames from "classnames"
import S from "./ProjectTeamModal.module.scss"
import ProjectTaskSelector from "../ProjectTaskSelector"
import { FieldArray, Formik } from "formik"
import ProjectResourceSelector from "../ProjectResourceSelector/ProjectResourceSelector"
import Button from "../Button/Button"
import Icon from "../Icon"
import useCurrentOrganization from "../../hooks/useCurrentOrganization"
import OrgUserSelector from "../OrgUserSelector"

const DEFAULTS = {
  user: null,
  permissions: [],
  temp: { projectTask: -1, pricedResource: -1 },
}

const cache = {}

export default function ProjectTeamMemberModal({
  isOpen,
  toggle,
  onClosed,
  data,
  projectId,
  save,
}) {
  const { t } = useTranslation()
  const currentOrg = useCurrentOrganization()

  return (
    <WpModal
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
      title={
        data.user
          ? t("project_team.member_modal.title_update")
          : t("project_team.member_modal.title_create")
      }
      size="lg"
    >
      <Formik
        initialValues={{ ...DEFAULTS, ...data }}
        onSubmit={(values, actions) => {
          save(values)
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            {!data.user && (
              <div className="d-flex flex-row justify-content-start align-items-center pl-4 mb-6">
                <label className={classNames("mr-5", "mb-0", S["label"])}>
                  {t("field:project_permission.user")}
                </label>
                <OrgUserSelector
                  orgId={currentOrg.id}
                  userId={formik.values.user?.id}
                  placeholder={t("project_team.member_modal.select_user")}
                  onMemberChange={(nextUser) => {
                    formik.setFieldValue("user", {
                      id: nextUser.id,
                      full_name: nextUser.full_name,
                      email: nextUser.email,
                    })
                  }}
                  capitalize={false}
                />
              </div>
            )}
            {data.user && (
              <div className="d-flex flex-row justify-content-start align-items-center pl-4 mb-6">
                <label className={classNames("mr-5", "mb-0", S["label"])}>
                  {t("field:project_permission.user")}
                </label>
                <span>
                  {data.user.full_name} ({data.user.email})
                </span>
              </div>
            )}
            <div className={S["table"]}>
              <div className={classNames(S["header"], S["row"])}>
                <div className={S["cell"]}>
                  {t("field:project_permission.project_task")}
                </div>
                <div className={S["cell"]}>
                  {t("field:project_permission.priced_resource")}
                </div>
                <div className={S["cell"]}></div>
              </div>
              <FieldArray name="permissions">
                {(arrayHelpers) => (
                  <>
                    {formik.values.permissions.map((perm, i) => (
                      <div className={classNames(S["row"])}>
                        <div className={S["cell"]}>
                          {perm.project_task?.title ??
                            t("project_team.member_modal.task_all")}
                        </div>
                        <div className={S["cell"]}>
                          {perm.priced_resource &&
                            `${perm.priced_resource.resource_name} (${perm.priced_resource.cost_unit})`}
                          {!perm.priced_resource &&
                            t("project_team.member_modal.resource_all")}
                        </div>
                        <div className={S["cell"]}>
                          <div className="action-icon-container-primary-hover pointer">
                            <Icon
                              name="delete"
                              onClick={() => arrayHelpers.remove(i)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className={classNames(S["row"])}>
                      <div className={S["cell"]}>
                        <ProjectTaskSelector
                          projectId={projectId}
                          taskId={formik.values.temp.projectTask}
                          extraOptions={[
                            {
                              value: -1,
                              label: t("project_team.member_modal.task_all"),
                            },
                          ]}
                          onTaskChange={(nextTask) => {
                            formik.setFieldValue(
                              "temp.projectTask",
                              nextTask?.id ?? -1
                            )
                            cache[`task${nextTask?.id}`] = nextTask
                            formik.setFieldValue("temp.pricedResource", -1)
                          }}
                          capitalize={false}
                          style={{ width: 250 }}
                          oneLine
                        />
                      </div>
                      <div className={S["cell"]}>
                        <ProjectResourceSelector
                          key={
                            formik.values.temp.projectTask &&
                            formik.values.temp.projectTask > 0
                              ? formik.values.temp.projectTask
                              : "dummy"
                          }
                          projectId={projectId}
                          taskId={
                            formik.values.temp.projectTask &&
                            formik.values.temp.projectTask > 0
                              ? formik.values.temp.projectTask
                              : null
                          }
                          resourceId={formik.values.temp.pricedResource}
                          extraOptions={[
                            {
                              value: -1,
                              label: t(
                                "project_team.member_modal.resource_all"
                              ),
                            },
                          ]}
                          onResourceChange={(nextResource) => {
                            formik.setFieldValue(
                              "temp.pricedResource",
                              nextResource?.id
                            )
                            cache[`resource${nextResource?.id}`] = nextResource
                          }}
                          capitalize={false}
                          style={{ width: 250 }}
                          oneLine
                        />
                      </div>
                      <div className={S["cell"]}>
                        <div className="action-icon-container-primary-hover pointer">
                          <Icon
                            name="plus-rounded"
                            onClick={() => {
                              arrayHelpers.push({
                                priced_resource:
                                  cache[
                                    `resource${formik.values.temp.pricedResource}`
                                  ] ?? null,
                                project_task:
                                  cache[
                                    `task${formik.values.temp.projectTask}`
                                  ] ?? null,
                              })
                              formik.setFieldValue("temp.pricedResource", -1)
                              formik.setFieldValue("temp.projectTask", -1)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </FieldArray>
            </div>
            {formik.values.permissions.length < 1 && (
              <div>
                <small className="text-danger">
                  {t("project_team.member_modal.please_add_one_permission")}
                </small>
              </div>
            )}
            <div className="text-right mt-5">
              <Button
                size="sm"
                type="button"
                color="none"
                className="mr-5"
                onClick={toggle}
              >
                {t("action:cancel")}
              </Button>
              <Button
                size="sm"
                type="submit"
                color="primary"
                disabled={formik.values.permissions.length < 1}
              >
                {t("action:save")}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </WpModal>
  )
}

import { rj, useRunRj } from "react-rocketjump"
import api from "../api"

const OverviewRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (filters) => api.auth(wpAuth).get("/api/overview/general", filters),
})

export function useOverview(filters) {
  return useRunRj(OverviewRj, [filters], false)
}

import { useRouteMatch } from "react-router-dom"
import { useAuthUser } from "use-eazy-auth"

export default function useCurrentOrganization(options = {}) {
  const { user } = useAuthUser()
  let personalOrg = user?.favorite_organization ?? user?.personal_organization
  const match = useRouteMatch("/org/:organization")
  let orgParam = match?.params?.organization ?? personalOrg

  if (orgParam === "join") {
    orgParam = personalOrg
  }

  if (orgParam) {
    return user?.organizationsById?.[orgParam] ?? user?.organizationsById?.[user?.personal_organization] ?? null
  }
  return null
}

import { get } from "lodash"
import { deps, rj, useRj, useRunRj } from "react-rocketjump"
import rjList, { nextPreviousPaginationAdapter } from "react-rocketjump/plugins/list"
import api from "../api"
import { mergeMap } from "rxjs/operators"
import rjDebounced from "react-rocketjump/plugins/debounce"

const getOrSelect = (context, pathOrFunction) => {
  if (typeof pathOrFunction === "function") {
    return pathOrFunction(context)
  }
  return get(context, pathOrFunction)
}

const ProjectTaskListRj = rj(
  rjList({
    pageSize: 100,
    pagination: nextPreviousPaginationAdapter,
    customPaginationReducer: (prevState, { payload: { data } }) => {
      return {
        ...prevState,
        count: getOrSelect(data, nextPreviousPaginationAdapter.count),
        current: getOrSelect(data, nextPreviousPaginationAdapter.current),
        next: getOrSelect(data, nextPreviousPaginationAdapter.next),
        previous: getOrSelect(data, nextPreviousPaginationAdapter.previous),
        full_count: data.full_count,
      }
    },
  }),
  {
    effectCaller: rj.configured(),
    effect: (wpAuth) => (filters) => {
      return api.auth(wpAuth).get(`/api/project-task/`, filters)
    },
    selectors: ({ getPagination }) => ({
      getFullCount: (state) => {
        if (state.root) {
          return get(state, "root.data.pagination.full_count", 0)
        }
        return get(state, "data.pagination.full_count", 0)
      },
    }),
    computed: {
      tasks: "getList",
      count: "getCount",
      fullCount: "getFullCount",
      loading: "isPending",
      error: "getError",
      pageCount: "getNumPages",
      hasNext: "hasNext",
      hasPrev: "hasPrev",
    },
    mutations: {
      create: rj.mutation.single({
        effect: (wpAuth) => (data) => {
          return api.auth(wpAuth).post(`/api/project-task/`, data)
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: [...state.data.list, result],
            },
          }
        },
      }),
      update: rj.mutation.multi((id) => id, {
        effect: (wpAuth) => (id, data) => {
          return api.auth(wpAuth).patch(`/api/project-task/${id}/`, data)
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.map((item) => (item.id !== result.id ? item : result)),
            },
          }
        },
      }),
      remove: rj.mutation.single({
        effect: (wpAuth) => (id) => {
          return api
            .auth(wpAuth)
            .mapResponse(() => id)
            .delete(`/api/project-task/${id}/`)
        },
        updater: (state, deletedId) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.filter((item) => item.id !== deletedId),
            },
          }
        },
      }),
      createActivity: rj.mutation.single({
        effect: (wpAuth) => (data) => {
          return api
            .auth(wpAuth)
            .post(`/api/activity/`, data)
            .pipe(
              mergeMap((activity) => {
                return api.auth(wpAuth).get(`/api/project-task/${activity.project_task}/`)
              })
            )
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.map((projectTask) => {
                if (projectTask.id !== result.id) {
                  return projectTask
                } else {
                  return result
                }
              }),
            },
          }
        },
      }),
      createSprint: rj.mutation.single({
        effect: (wpAuth) => (data) => {
          return api.auth(wpAuth).post(`/api/sprint/`, data)
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.map((task) => {
                if (task.id !== result.project_task) {
                  return task
                } else {
                  return { ...task, sprints: [...task.sprints, result] }
                }
              }),
            },
          }
        },
      }),
      updateSprint: rj.mutation.single({
        effect: (wpAuth) => (id, data) => {
          return api.auth(wpAuth).patch(`/api/sprint/${id}/`, data)
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.map((task) => {
                if (task.id !== result.project_task) {
                  return task
                } else {
                  return { ...task, sprints: task.sprints.map((sprint) => (sprint.id !== result.id ? sprint : result)) }
                }
              }),
            },
          }
        },
      }),
      removeSprint: rj.mutation.single({
        effect: (wpAuth) => (taskId, id) => {
          return api
            .auth(wpAuth)
            .mapResponse(() => [id])
            .delete(`/api/sprint/${id}/`)
        },
        updater: (state, [id]) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.map((task) => {
                return { ...task, sprints: task.sprints.filter((sprint) => sprint.id !== id) }
              }),
            },
          }
        },
      }),
    },
  }
)

export function useProjectTaskList(params) {
  return useRunRj(ProjectTaskListRj, [params])
}

const ProjectTaskEnumRj = rj(rjDebounced(), {
  effectCaller: rj.configured(),
  effect: (wpAuth) => (projectId, filters) => {
    return api
      .auth(wpAuth)
      .mapResponse((r) => r.response.results)
      .get(`/api/project/${projectId}/enums/tasks/`, filters)
  },
  computed: {
    tasks: "getData",
  },
})

export function useProjectTaskEnum(projectId, filters = {}) {
  return useRunRj(ProjectTaskEnumRj, [deps.maybe(projectId), filters])
}

const ProjectTaskEnumDetailRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (projectId, taskId) => {
    return api.auth(wpAuth).get(`/api/project/${projectId}/enums/tasks/${taskId}/`)
  },
})

export function useProjectTaskEnumDetail(projectId, taskId) {
  return useRunRj(ProjectTaskEnumDetailRj, [deps.maybe(projectId), deps.maybe(taskId)])
}

const ProjectTaskCompatResourcesRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (taskId, filters = {}) => {
    return api.auth(wpAuth).get(`/api/project-task/${taskId}/compatible-resources/`, filters)
  },
})

export function useProjectTaskCompatResources() {
  return useRj(ProjectTaskCompatResourcesRj)
}

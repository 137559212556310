import React from "react"
import { pagerLog } from "../../Pager"

const FragComponent = React.memo(
  ({ isPdf, inPage }) => {
    if (inPage) {
      pagerLog("[PAGER] Page break rendered")
    }
    if (isPdf) {
      return null
    }
    return (
      <div className="d-flex flex-row justify-content-center align-items-center">
        <div className="flex-1 dashed-line" />
        <div className="text-separator px-1">Interruzione di pagina</div>
        <div className="flex-1 dashed-line" />
      </div>
    )
  },
  (prevProps, nextProps) => {
    const checkProps = ["isPdf"]
    for (let i = 0; i < checkProps.length; i++) {
      const prop = checkProps[i]
      if (prevProps[prop] !== nextProps[prop]) {
        return false
      }
    }
    return true
  }
)

export default FragComponent

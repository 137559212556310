import React, { useCallback, useEffect, useRef } from "react"
import { Scrollbars } from "react-custom-scrollbars"
import classNames from "classnames"
import S from "./AutoTextarea.module.scss"
import ResizeObserver from "react-resize-observer"

function autoFitTextarea(elem) {
  if (!elem) {
    return
  }
  elem.style.height = "5px"
  elem.style.height = elem.scrollHeight + 2 + "px"
}

function AutoTextarea(
  { onInput, rows = 4, style, className, id, ...props },
  ref
) {
  const domRef = useRef(null)

  const auto_grow = useCallback(
    (e) => {
      const elem = e.target
      autoFitTextarea(elem)
      if (typeof onInput === "function") {
        onInput(e)
      }
    },
    [onInput]
  )

  useEffect(() => {
    autoFitTextarea(domRef.current)
  }, [])

  return (
    <div
      id={id}
      ref={ref}
      className={classNames(className, "position-relative", {
        [S["wrapper-disabled"]]: props.disabled,
      })}
    >
      <ResizeObserver onResize={() => autoFitTextarea(domRef.current)} />
      <Scrollbars
        style={{
          width: "100%",
          height: rows * "23",
          paddingRight: 2,
        }}
      >
        <textarea
          style={{ ...style, minHeight: rows * "20" }}
          rows={rows}
          className={classNames(S["textarea-scroll"])}
          ref={domRef}
          onInput={auto_grow}
          {...props}
        ></textarea>
      </Scrollbars>
    </div>
  )
}

export default React.forwardRef(AutoTextarea)

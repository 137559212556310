import { rj, useRunRj } from "react-rocketjump"
import api from "../api"

const TrackerPeopleState = rj({
  effectCaller: rj.configured(),
  effect: wpAuth => () => {
    return api
      .auth(wpAuth)
      .get("/api/tracker/people/")
  }
})

export function useTrackerPeople() {
  return useRunRj(TrackerPeopleState)
}
import React from "react"
import classNames from "classnames"
import FontPicker from "../FontPicker/FontPicker"

// TODO: Handle errors???
export default function FieldFontPicker({
  label,
  labelClassname,
  containerClassName,
  field,
  emptyText = "",
  form,
  readOnly=false,
  disabled = undefined,
  ...props
}) {
  const fieldBlur = field.onBlur(field.name)
  return (
    <div className={`d-flex flex-column ${containerClassName}`}>
      {label && (
        <label
          className={classNames(
            "font-weight-semibold mb-3 h3 text-uppercase",
            labelClassname
          )}
        >
          {label}
        </label>
      )}
      <FontPicker
        value={field.value}
        onChange={(value) => form.setFieldValue(field.name, value)}
        onBlur={fieldBlur}
        disabled={disabled || readOnly}
        {...props}
      />
    </div>
  )
}

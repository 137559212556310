import { Field, FormikProvider, useFormik } from "formik"
import { mapValues, pick, truncate } from "lodash"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useAuthUser } from "use-eazy-auth"
import * as yup from "yup"
import { useEffortTrackerInfo } from "../../hooks/commercialEffortTracker"
import { convertErrorToForm } from "../../utils"
import Button from "../Button"
import { CustomerSelector } from "../CustomerSelector/CustomerSelector"
import EstimateSelector from "../EstimateSelector/EstimateSelector"
import FieldDropdown from "../fields/FieldDropdown"
import FieldInput from "../fields/FieldInput"
import FieldSwitch from "../fields/FieldSwitch"
import FieldTextArea from "../fields/FieldTextarea"
import IncomingRequestSelector from "../IncomingRequestSelector"
import OrgUserSelector from "../OrgUserSelector/OrgUserSelector"
import ProjectSelector from "../ProjectSelector"
import WpModal from "../WpModal"

const INITIAL_VALUES = {
  customer: undefined,
  date: "",
  description: "",
  completed: false,
  estimate: null,
  project: null,
  request: null,
  assigned_to: undefined,
  consequence_of: undefined,
  effort: "0.00",
  repeat_frequency: "repeat_no",
  repeat_end: null,
  canceled: false,
}

function getInitialValues(interaction) {
  return pick(interaction, ...Object.keys(INITIAL_VALUES))
}

const RELATED_FILTER_DEFAULT = {}

export function CreateCustomerInteractionModal({
  isOpen,
  toggle,
  onSave,
  onClosed,
  interaction,
  readOnlyFields = [],
  relatedFilterDefault = RELATED_FILTER_DEFAULT,
}) {
  const { t } = useTranslation(["translation", "field", "action"])
  const { user } = useAuthUser()

  const [{ data: trackerInfo }] = useEffortTrackerInfo()

  const repeatFrequencyOpts = useMemo(
    () =>
      ["repeat_no", "repeat_week", "repeat_month", "repeat_trimester"].map((v) => ({
        label: t("enums:repeat_rule." + v),
        value: v,
      })),
    [t]
  )

  const schema = yup.object().shape({
    date: yup
      .date()
      .typeError(
        t("field:errors.date", {
          field: t("field:project_activity.date_ref"),
        })
      )
      .required(t("field:errors.mandatory", { field: t("field:project.name") })),
    description: yup.string().required(),
    assigned_to: yup.number().required(),
  })

  const initialValues = useMemo(
    () => ({
      ...INITIAL_VALUES,
      assigned_to: user.id,
    }),
    [user.id]
  )

  const formik = useFormik({
    initialValues: interaction ? { ...initialValues, ...getInitialValues(interaction) } : initialValues,
    validationSchema: schema,
    onSubmit: (values, actions) => {
      return onSave(mapValues(values, (val) => (val === undefined ? null : val))).catch((error) => {
        actions.setErrors(convertErrorToForm(error))
      })
    },
    enableReinitialize: true,
  })

  const relatedFilter = useMemo(() => {
    if (relatedFilterDefault !== RELATED_FILTER_DEFAULT) {
      return relatedFilterDefault
    } else if (formik.values.customer) {
      return { customer: formik.values.customer }
    } else {
      return RELATED_FILTER_DEFAULT
    }
  }, [formik.values.customer, relatedFilterDefault])

  return (
    <WpModal
      onClosed={onClosed}
      isOpen={isOpen}
      toggle={toggle}
      title={interaction?.id ? t("action:edit_customer_interaction") : t("action:new_customer_interaction")}
    >
      <div>
        <div className="d-flex flex-row align-items-start my-4">
          <div className="d-flex flex-column ml-2 flex-1">
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit} autoComplete="off">
                {interaction?.consequence_of && (
                  <>
                    <label className="font-weight-semibold mb-3 h3 text-uppercase">
                      {t("field:customer_interaction.consequence_of")}
                    </label>
                    <p>
                      {truncate(interaction.consequence_of_name, {
                        length: 200,
                        omission: "…",
                      })}
                    </p>
                  </>
                )}
                <Field
                  name="customer"
                  component={CustomerSelector.Field}
                  label={t("field:customer_interaction.customer")}
                  className="px-4 py-3"
                  disabled={!!interaction.customer}
                />
                <div className="pb-5" />
                <Field
                  name="date"
                  component={FieldInput}
                  type="date"
                  label={t("field:customer_interaction.date")}
                  className={"mb-5"}
                />
                <Field
                  name="description"
                  component={FieldTextArea}
                  className={"mb-5"}
                  placeholder={t("field:customer_interaction.placeholder.description")}
                  label={t("field:customer_interaction.description")}
                />
                <Field
                  name="request"
                  component={IncomingRequestSelector.Field}
                  containerClassName={"w-100 mb-5"}
                  className="py-3 px-4"
                  clearable
                  label={t("field:customer_interaction.request")}
                  readOnly={readOnlyFields.includes("request")}
                  autoFilters={
                    relatedFilter === RELATED_FILTER_DEFAULT && formik.values.customer
                      ? { customer: formik.values.customer }
                      : relatedFilter
                  }
                />
                <Field
                  name="estimate"
                  component={EstimateSelector.Field}
                  containerClassName={"w-100 mb-5"}
                  className="py-3 px-4"
                  clearable
                  label={t("field:customer_interaction.estimate")}
                  readOnly={readOnlyFields.includes("estimate")}
                  defaultFilters={
                    relatedFilter === RELATED_FILTER_DEFAULT && formik.values.customer
                      ? { customer: formik.values.customer }
                      : relatedFilter
                  }
                  filtersReinitialize
                />
                <Field
                  name="project"
                  component={ProjectSelector.Field}
                  containerClassName={"w-100 mb-5"}
                  className="py-3 px-4"
                  clearable
                  label={t("field:customer_interaction.project")}
                  readOnly={readOnlyFields.includes("project")}
                  defaultFilters={
                    relatedFilter === RELATED_FILTER_DEFAULT && formik.values.customer
                      ? { customer: formik.values.customer }
                      : relatedFilter
                  }
                  filtersReinitialize
                />
                <Field
                  name="assigned_to"
                  component={OrgUserSelector.Field}
                  containerClassName={"w-100 mb-5"}
                  className="py-3 px-4"
                  label={t("field:customer_interaction.assigned_to")}
                />
                <div className="d-flex flex-row">
                  <div className="flex-1 pr-5">
                    <Field
                      name="repeat_frequency"
                      component={FieldDropdown}
                      containerClassName={"w-100 mb-5"}
                      className="py-3 px-4"
                      label={t("field:customer_interaction.repeat_frequency")}
                      options={repeatFrequencyOpts}
                    />
                  </div>
                  <div className="flex-1 pl-5">
                    {formik.values.repeat_frequency !== "repeat_no" && (
                      <Field
                        name="repeat_end"
                        component={FieldInput}
                        containerClassName={"w-100 mb-5"}
                        className="py-3 px-4"
                        label={t("field:customer_interaction.repeat_end")}
                        type="date"
                      />
                    )}
                  </div>
                </div>
                <hr className="mb-5" />
                <Field
                  name="completed"
                  component={FieldSwitch}
                  className={"mb-5"}
                  message={t("field:customer_interaction.completed")}
                />
                <Field
                  name="canceled"
                  component={FieldSwitch}
                  className={"mb-5"}
                  message={t("field:customer_interaction.canceled")}
                />
                {trackerInfo && (
                  <>
                    <hr className="mb-5" />
                    <Field
                      name="effort"
                      component={FieldInput}
                      type="number"
                      className={"mb-5"}
                      label={t("field:customer_interaction.effort") + " (" + trackerInfo.cost_unit + ")"}
                      placeholder={t("field:customer_interaction.placeholder.effort")}
                    />
                  </>
                )}
                <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                  <Button size="sm" color="none" onClick={toggle}>
                    {t("action:cancel")}
                  </Button>
                  <Button size="sm" color="primary" type="submit" className="ml-5" disabled={!formik.isValid}>
                    {interaction?.id ? t("action:save") : t("action:add")}
                  </Button>
                  {interaction.id && (
                    <Button
                      size="sm"
                      color="primary"
                      type="button"
                      className="ml-5"
                      disabled={!formik.isValid}
                      onClick={() => {
                        onSave(
                          mapValues({ ...formik.values, extend_update: true }, (val) => (val === undefined ? null : val))
                        ).catch((error) => {
                          formik.setErrors(convertErrorToForm(error))
                        })
                      }}
                    >
                      {t("action:save_extend")}
                    </Button>
                  )}
                </div>
              </form>
            </FormikProvider>
          </div>
        </div>
      </div>
    </WpModal>
  )
}

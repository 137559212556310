import React, { useMemo } from "react"

import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg"
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg"
import { ReactComponent as PlusRounded } from "../../assets/icons/plus-rounded.svg"
import { ReactComponent as ResourceDelete } from "../../assets/icons/resource-delete.svg"
import { ReactComponent as More } from "../../assets/icons/more.svg"
import { ReactComponent as Edit } from "../../assets/icons/edit.svg"
import { ReactComponent as Duplicate } from "../../assets/icons/duplicate.svg"
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg"
import { ReactComponent as Revert } from "../../assets/icons/revert.svg"
import { ReactComponent as Alert } from "../../assets/icons/alert.svg"
import { ReactComponent as Plus } from "../../assets/icons/plus.svg"
import { ReactComponent as NewResourceHR } from "../../assets/icons/new-resource-hr.svg"
import { ReactComponent as NewResourceService } from "../../assets/icons/new-resource-service.svg"
import { ReactComponent as NewResourceMaterial } from "../../assets/icons/new-resource-material.svg"
import { ReactComponent as Vdots } from "../../assets/icons/vdots.svg"
import { ReactComponent as Share } from "../../assets/icons/share.svg"
import { ReactComponent as Delete } from "../../assets/icons/delete.svg"
import { ReactComponent as Home } from "../../assets/icons/home.svg"
import { ReactComponent as EstimateList } from "../../assets/icons/estimate-list.svg"
import { ReactComponent as Resources } from "../../assets/icons/resources.svg"
import { ReactComponent as Template } from "../../assets/icons/template.svg"
import { ReactComponent as NewEstimate } from "../../assets/icons/new-estimate.svg"
import { ReactComponent as Help } from "../../assets/icons/help.svg"
import { ReactComponent as Privacy } from "../../assets/icons/privacy.svg"
import { ReactComponent as Archive } from "../../assets/icons/archive.svg"
import { ReactComponent as Pdf } from "../../assets/icons/pdf.svg"
import { ReactComponent as Word } from "../../assets/icons/word.svg"
import { ReactComponent as User } from "../../assets/icons/user.svg"
import { ReactComponent as Logout } from "../../assets/icons/logout.svg"
import { ReactComponent as List } from "../../assets/icons/list.svg"
import { ReactComponent as Table } from "../../assets/icons/table.svg"
import { ReactComponent as Graph } from "../../assets/icons/graph.svg"
import { ReactComponent as MoveUp } from "../../assets/icons/move-up.svg"
import { ReactComponent as MoveDown } from "../../assets/icons/move-down.svg"
import { ReactComponent as AddAfter } from "../../assets/icons/add-after.svg"
import { ReactComponent as AddChild } from "../../assets/icons/add-child.svg"
import { ReactComponent as Move } from "../../assets/icons/move.svg"
import { ReactComponent as Info } from "../../assets/icons/info.svg"
import { ReactComponent as Task } from "../../assets/icons/task.svg"
import { ReactComponent as Document } from "../../assets/icons/document.svg"
import { ReactComponent as Resource } from "../../assets/icons/resource.svg"
import { ReactComponent as Preview } from "../../assets/icons/preview.svg"
import { ReactComponent as Search } from "../../assets/icons/search.svg"
import { ReactComponent as Settings } from "../../assets/icons/settings.svg"
import { ReactComponent as TreeMap } from "../../assets/icons/treemap.svg"
import { ReactComponent as Tree } from "../../assets/icons/tree.svg"
import { ReactComponent as Material } from "../../assets/icons/material.svg"
import { ReactComponent as HR } from "../../assets/icons/hr.svg"
import { ReactComponent as Service } from "../../assets/icons/service.svg"
import { ReactComponent as FixedCost } from "../../assets/icons/fixed-cost.svg"
import { ReactComponent as DragHandle } from "../../assets/icons/drag-handle.svg"
import { ReactComponent as Expand } from "../../assets/icons/expand.svg"
import { ReactComponent as Collapse } from "../../assets/icons/collapse.svg"
import { ReactComponent as Back } from "../../assets/icons/back.svg"
import { ReactComponent as TemplateText } from "../../assets/icons/template_text.svg"
import { ReactComponent as View } from "../../assets/icons/view.svg"
import { ReactComponent as Star } from "../../assets/icons/star.svg"
import { ReactComponent as StarFilled } from "../../assets/icons/star-filled.svg"
import { ReactComponent as Paste } from "../../assets/icons/paste.svg"
import { ReactComponent as NewEstimateTemplate } from "../../assets/icons/new_estimate_template.svg"
import { ReactComponent as ScrollIntoView } from "../../assets/icons/scroll-into-view.svg"
import { ReactComponent as RestoreArchive } from "../../assets/icons/restore_archive.svg"
import { ReactComponent as Hide } from "../../assets/icons/hide.svg"
import { ReactComponent as Lock } from "../../assets/icons/lock.svg"
import { ReactComponent as Unlock } from "../../assets/icons/unlock.svg"
import { ReactComponent as Scenario } from "../../assets/icons/scenario.svg"
import { ReactComponent as Remove } from "../../assets/icons/remove.svg"
import { ReactComponent as Organization } from "../../assets/icons/organization.svg"
import { ReactComponent as Billing } from "../../assets/icons/billing.svg"
import { ReactComponent as Close } from "../../assets/icons/close.svg"
import { ReactComponent as Customize } from "../../assets/icons/customize.svg"
import { ReactComponent as Notification } from "../../assets/icons/notification.svg"
import { ReactComponent as Times } from "../../assets/icons/times.svg"
import { ReactComponent as PriceLists } from "../../assets/icons/price_lists.svg"
import { ReactComponent as Project } from "../../assets/icons/project.svg"
import { ReactComponent as Reporting } from "../../assets/icons/reporting.svg"
import { ReactComponent as ProjectTeam } from "../../assets/icons/project-team.svg"
import { ReactComponent as ProjectActivity } from "../../assets/icons/project-activity.svg"
import { ReactComponent as ProjectActivityAdd } from "../../assets/icons/project-activity-add.svg"
import { ReactComponent as ProjectInsight } from "../../assets/icons/project-insight.svg"
import { ReactComponent as Filter } from "../../assets/icons/filter.svg"
import { ReactComponent as ReadDocOnline } from "../../assets/icons/read-doc-online.svg"
import { ReactComponent as Customers } from "../../assets/icons/customers.svg"

import { UncontrolledTooltip } from "reactstrap"
import { uniqueId } from "lodash"

const ALIAS = {
  "arrow-right": ArrowRight,
  "arrow-left": ArrowLeft,
  "resource-add": PlusRounded,
  "resource-delete": ResourceDelete,
  more: More,
  edit: Edit,
  duplicate: Duplicate,
  "arrow-down": ArrowDown,
  revert: Revert,
  alert: Alert,
  plus: Plus,
  "plus-rounded": PlusRounded,
  "new-resource-hr": NewResourceHR,
  "new-resource-service": NewResourceService,
  "new-resource-material": NewResourceMaterial,
  vdots: Vdots,
  share: Share,
  delete: Delete,
  home: Home,
  "estimate-list": EstimateList,
  resources: Resources,
  template: Template,
  "new-estimate": NewEstimate,
  privacy: Privacy,
  help: Help,
  archive: Archive,
  word: Word,
  pdf: Pdf,
  user: User,
  logout: Logout,
  list: List,
  table: Table,
  graph: Graph,
  "move-up": MoveUp,
  "move-down": MoveDown,
  move: Move,
  "add-after": AddAfter,
  "add-child": AddChild,
  info: Info,
  task: Task,
  document: Document,
  resource: Resource,
  preview: Preview,
  search: Search,
  settings: Settings,
  treemap: TreeMap,
  tree: Tree,
  material: Material,
  hr: HR,
  service: Service,
  "fixed-cost": FixedCost,
  "drag-handle": DragHandle,
  expand: Expand,
  collapse: Collapse,
  back: Back,
  template_text: TemplateText,
  view: View,
  star: Star,
  "star-filled": StarFilled,
  paste: Paste,
  new_estimate_template: NewEstimateTemplate,
  "scroll-into-view": ScrollIntoView,
  restore_archive: RestoreArchive,
  hide: Hide,
  lock: Lock,
  unlock: Unlock,
  scenario: Scenario,
  remove: Remove,
  organization: Organization,
  billing: Billing,
  close: Close,
  customize: Customize,
  notification: Notification,
  times: Times,
  price_lists: PriceLists,
  project: Project,
  reporting: Reporting,
  "project-team": ProjectTeam,
  "project-activity": ProjectActivity,
  "project-activity-add": ProjectActivityAdd,
  "project-insight": ProjectInsight,
  filter: Filter,
  "read-doc-online": ReadDocOnline,
  customers: Customers,
}

export default function Icon({ name, title, id, placement = "bottom-start", disabled = false, style, ...props }) {
  const Component = ALIAS[name]
  const myId = useMemo(() => id || "icon-" + uniqueId(), [id])
  return (
    <>
      <Component id={myId} {...props} style={{ ...style, opacity: disabled ? 0.3 : style?.opacity }} />
      {title && !disabled &&  (
        <UncontrolledTooltip key={myId} placement={placement} target={myId} fade={false}>
          {title}
        </UncontrolledTooltip>
      )}
    </>
  )
}

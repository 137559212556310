import { rj, useRj } from "react-rocketjump"
import api from "../api"
import { PDF_BASE_URL } from "../consts"
import { notifyError } from "../utils"
import { saveAs } from "file-saver"
import { useCallback, useMemo } from "react"
import qs from "query-string"

const DocumentPdfRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (estimateId, docId) => {
    return api
      .request({
        responseType: "blob",
      })
      .get(
        `${PDF_BASE_URL}/estimate-document/pdf/${estimateId}/${docId}?t=Token ${wpAuth.token}&org=${wpAuth.organizationId}`
      )
  },
})

export default function usePdfDoc() {
  const [{ pending }, { run }] = useRj(DocumentPdfRj)

  const downloadPdf = useCallback(
    (estimateId, documentId, filename) => {
      run
        .onFailure(notifyError)
        .onSuccess((docBinary) => {
          if (docBinary !== null) {
            saveAs(docBinary, filename || `${estimateId}.pdf`)
          }
        })
        .run(estimateId, documentId)
    },
    [run]
  )

  const retValue = useMemo(() => ({ downloading: pending, downloadPdf }), [
    downloadPdf,
    pending,
  ])

  return retValue
}

const ActivityReportPdfRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (projectId, filters) => {
    return api
      .request({
        responseType: "blob",
      })
      .get(
        `${PDF_BASE_URL}/activity-report/pdf/${projectId}/?t=Token ${wpAuth.token}&org=${wpAuth.organizationId}&${qs.stringify(filters)}`
      )
  },
})

export function usePdfActivityReport() {
  const [{ pending }, { run }] = useRj(ActivityReportPdfRj)

  const downloadPdf = useCallback(
    (projectId, filters, filename) => {
      run
        .onFailure(notifyError)
        .onSuccess((docBinary) => {
          if (docBinary !== null) {
            saveAs(docBinary, filename || `${projectId}.pdf`)
          }
        })
        .run(projectId, filters)
    },
    [run]
  )

  const retValue = useMemo(() => ({ downloading: pending, downloadPdf }), [
    downloadPdf,
    pending,
  ])

  return retValue
}

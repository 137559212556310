import React, { useContext } from "react"
import { EstimateLandmarks } from "../../../components/EstimateLandmarks"
import { FlowGraph } from "../../../components/FlowGraph"
import Layout from "../../../components/Layout"
import { CurrentEstimateStateContext } from "../../../context"
import { useEstimateFlow } from "../../../hooks/estimates"
import EstimateBottomBarContent from "../EstimateBottomBarContent"
import EstimateTabBarContent from "../EstimateTabBarContent"

export function EstimateFlow() {
  const [{ estimate }] = useContext(CurrentEstimateStateContext)
  const [{ graph }] = useEstimateFlow(estimate.id)

  return (
    <Layout>
      {graph && (
        <div className="d-flex flex-row justify-content-center mt-9">
          <FlowGraph
            width={1000}
            nodes={graph[0]}
            arcs={graph[1]}
            current={`e${estimate.id}`}
          ></FlowGraph>
        </div>
      )}
      <Layout.TabBar>
        <EstimateTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <EstimateBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <EstimateLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

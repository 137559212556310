import dayjs from "dayjs"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import Icon from "../../../components/Icon"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import Pagination from "../../../components/Pagination/Pagination"
import Table from "../../../components/Table/Table"
import { useEstimatesList } from "../../../hooks/estimates"
import { notifyError } from "../../../utils"
import TemplateTabBarContent from "../TemplateTabBarContent"
import Separator from "../../../components/Separator/Separator"
import {
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
} from "reactstrap"
import useModalTrigger from "../../../hooks/useModalTrigger"
import useModalTriggerQuota from "../../../hooks/useModalTriggerQuota"
import useWpHistoryMethods from "../../../hooks/useWpHistoryMethods"
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import CreateEstimateModal from "../../../components/CreateEstimateModal"
import WpInput from "../../../components/WpInput"
import classNames from "classnames"
import FilterButton from "../../../components/FilterButton"
import LongBorder from "../../../components/LongBorder/LongBorder"
import WpLink from "../../../components/WpLink"
import Button from "../../../components/Button"
import { getCurrencyFormatter } from "../../../hooks/useCurrencyFormatter"
import { EstimateLandmarks } from "../../../components/EstimateLandmarks"

export default function EstimateTemplate() {
  const { t } = useTranslation(["translation", "action", "field"])

  const history = useWpHistoryMethods()

  const [
    { title, code },
    setQueryParams,
    {
      title: debTitle,
      code: debCode,
      tag: debTag,
      page: debPage,
      archived: debArchived,
    },
    setDebouncedQueryParams,
  ] = useQueryParams({
    page: {
      encode: (intValue) => intValue.toString(10),
      decode: (strValue) => (strValue ? parseInt(strValue, 10) : 1),
    },
    title: {
      encode: (value) => value || "",
      decode: (rawvalue) => rawvalue || "",
    },
    code: {
      encode: (value) => value || "",
      decode: (rawvalue) => rawvalue || "",
    },
    tag: {
      encode: (value) => value || "",
      decode: (rawvalue) => rawvalue || "",
    },
    archived: {
      encode: (value) => value || "",
      decode: (rawvalue) => rawvalue || undefined,
    },
  })

  const estimatesFilters = useMemo(
    () => ({
      page: debPage,
      code: debCode,
      title: debTitle,
      tag: debTag,
      is_template: true,
      archived: debArchived,
    }),
    [debArchived, debCode, debPage, debTag, debTitle]
  )

  const [createModal, createModalActions] = useModalTriggerQuota()
  const [deleteModal, deleteModalActions] = useModalTrigger()

  const openClone = useCallback(
    (estimate) => {
      createModalActions.open({
        title: estimate.title,
        code: estimate.code,
        cloneId: estimate.id,
      })
    },
    [createModalActions]
  )

  const [
    { estimates, count, pageCount, hasNext, hasPrev },
    {
      deleteEstimate,
      instanceEstimatTemplate,
      createEstimateTemplate,
      updateEstimate,
      run: reload,
    },
  ] = useEstimatesList(estimatesFilters)

  const onEstimateCreation = useCallback(
    (estimateData) => {
      const { cloneId, ...cloneData } = estimateData
      const redirect2Estimate = (estimate) => {
        history.push(`/estimates/${estimate.id}/`)
      }
      if (cloneId) {
        return instanceEstimatTemplate
          .onSuccess(redirect2Estimate)
          .asPromise(cloneId, cloneData)
      }
      return createEstimateTemplate
        .onSuccess(redirect2Estimate)
        .asPromise(cloneData)
    },
    [createEstimateTemplate, history, instanceEstimatTemplate]
  )

  const onEstimateDeletion = useCallback(() => {
    const deleteId = deleteModal?.value?.id
    return deleteEstimate
      .onFailure(notifyError)
      .onSuccess(() => {
        deleteModalActions.close()
        reload(estimatesFilters)
      })
      .run(deleteId)
  }, [
    deleteEstimate,
    deleteModal?.value?.id,
    deleteModalActions,
    estimatesFilters,
    reload,
  ])

  const onEstimateArchive = useCallback(
    (toArchive) => {
      updateEstimate
        .onFailure(notifyError)
        .onSuccess(() => {
          reload(estimatesFilters)
        })
        .run(toArchive.id, {
          archived: true,
        })
    },
    [estimatesFilters, reload, updateEstimate]
  )

  const onEstimateRestore = useCallback(
    (toRestore) => {
      updateEstimate
        .onFailure(notifyError)
        .onSuccess(() => {
          reload(estimatesFilters)
        })
        .run(toRestore.id, {
          archived: false,
        })
    },
    [estimatesFilters, reload, updateEstimate]
  )

  const onEstimateLock = useCallback(
    (toLock) => {
      updateEstimate
        .onFailure(notifyError)
        .onSuccess(() => {
          reload(estimatesFilters)
        })
        .run(toLock.id, {
          editable_state: "closed",
        })
    },
    [estimatesFilters, reload, updateEstimate]
  )

  const onEstimateUnlock = useCallback(
    (toUnlock) => {
      updateEstimate
        .onFailure(notifyError)
        .onSuccess(() => {
          reload(estimatesFilters)
        })
        .run(toUnlock.id, {
          editable_state: "open",
        })
    },
    [estimatesFilters, reload, updateEstimate]
  )

  const columns = useMemo(
    () => [
      {
        label: t("field:estimate.code"),
        name: "code",
        size: 2,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-column h-100 w-100 align-items-start justify-content-center">
              {selectedDatum}
              {datum.archived && (
                <span className="badge badge-separator mt-2">
                  {t("enums:template_state.archived_estimate")}
                </span>
              )}
              {!datum.archived && datum.editable_state === "closed" && (
                <span className="badge badge-secondary mt-2">
                  {t("enums:template_state.locked_template")}
                </span>
              )}
            </div>
          )
        },
      },
      {
        label: t("field:estimate.title"),
        name: "title",
        size: 3,
      },
      {
        label: t("field:estimate.description"),
        name: "description",
        size: 3,
      },
      {
        label: t("field:estimate.updated_at"),
        name: "updated_at",
        render: (selectedDatum, colName, datum) => {
          return dayjs(selectedDatum).format("DD-MM-YYYY HH:mm")
        },
        size: 2,
      },
      {
        label: t("field:estimate.price"),
        name: "price",
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="text-right">
              {getCurrencyFormatter(datum).format(selectedDatum)}
            </div>
          )
        },
        size: 1,
      },
      {
        label: t("actions"),
        name: "actions",
        size: 1,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-around align-items-center w-100">
              <WpLink
                to={`/estimates/${datum.id}/tasks`}
                className={"action-icon-container-primary-hover"}
              >
                {!datum.readonly && (
                  <Icon
                    name="edit"
                    title={t("action:edit_template")}
                    className="pointer text-dark"
                  />
                )}
                {datum.readonly && (
                  <Icon
                    name="view"
                    title={t("action:view_template")}
                    className="pointer text-dark"
                  />
                )}
              </WpLink>
              <div className={"action-icon-container-primary-hover"}>
                <Icon
                  height={18}
                  width={18}
                  name="new-estimate"
                  title={t("action:instance_template")}
                  className="pointer text-dark"
                  onClick={() => openClone(datum)}
                />
              </div>
              <div className={"action-icon-container-primary-hover"}>
                <UncontrolledDropdown>
                  <DropdownToggle caret={false} tag={"span"}>
                    <Icon
                      role="button"
                      name="vdots"
                      className="text-dark pointer"
                      title={t("action:other_options")}
                      placement="right"
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    right
                    modifiers={{ offset: { offset: "0, 12" } }}
                    className="border-primary"
                  >
                    {!datum.archived && (
                      <DropdownItem
                        className={classNames(
                          "text-capitalize px-0",
                          "dropdown-item-primary-active"
                        )}
                      >
                        <div
                          className="d-flex flex-row align-items-center mx-4"
                          onClick={() => onEstimateArchive(datum)}
                        >
                          <Icon
                            name="archive"
                            className={classNames("pointer mr-4")}
                          />
                          <span>{t("action:archive")}</span>
                        </div>
                      </DropdownItem>
                    )}
                    {datum.archived && (
                      <DropdownItem
                        className={classNames(
                          "text-capitalize px-0",
                          "dropdown-item-primary-active"
                        )}
                      >
                        <div
                          className="d-flex flex-row align-items-center mx-4"
                          onClick={() => onEstimateRestore(datum)}
                        >
                          <Icon
                            name="restore_archive"
                            className={classNames("pointer mr-4")}
                          />
                          <span>{t("action:restore")}</span>
                        </div>
                      </DropdownItem>
                    )}
                    <Separator className="my-0 mx-4 border-primary" />
                    {!datum.archived && (
                      <>
                        {datum.editable_state === "open" && (
                          <DropdownItem
                            className={classNames(
                              "text-capitalize px-0",
                              "dropdown-item-primary-active"
                            )}
                          >
                            <div
                              className="d-flex flex-row align-items-center mx-4"
                              onClick={() => onEstimateLock(datum)}
                            >
                              <Icon
                                name="lock"
                                className={classNames("pointer mr-4")}
                              />
                              <span>{t("action:lock")}</span>
                            </div>
                          </DropdownItem>
                        )}
                        {datum.editable_state === "closed" && (
                          <DropdownItem
                            className={classNames(
                              "text-capitalize px-0",
                              "dropdown-item-primary-active"
                            )}
                          >
                            <div
                              className="d-flex flex-row align-items-center mx-4"
                              onClick={() => onEstimateUnlock(datum)}
                            >
                              <Icon
                                name="unlock"
                                className={classNames("pointer mr-4")}
                              />
                              <span>{t("action:unlock")}</span>
                            </div>
                          </DropdownItem>
                        )}
                        <Separator className="my-0 mx-4 border-primary" />
                      </>
                    )}
                    <DropdownItem
                      className={
                        "text-capitalize px-0 dropdown-item-primary-active"
                      }
                    >
                      <div
                        className="d-flex flex-row align-items-center mx-4"
                        onClick={() => {
                          deleteModalActions.open(datum)
                        }}
                      >
                        <Icon name="delete" className="pointer mr-4" />
                        <span>{t("action:delete")}</span>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )
        },
      },
    ],
    [
      deleteModalActions,
      onEstimateArchive,
      onEstimateLock,
      onEstimateRestore,
      onEstimateUnlock,
      openClone,
      t,
    ]
  )
  return (
    <Layout>
      {estimates === null && <Loader />}
      {estimates !== null && (
        <div className="container-fluid pt-8 px-page pb-page-bottom">
          <div className="d-flex flex-column mt-4 mb-7">
            <div className="d-flex flex-row align-items-center justify-content-between mt-3 ml-3">
              <div>
                <Button onClick={() => createModalActions.open()} size="lg">
                  Nuovo template preventivo
                </Button>
              </div>
              <div className="d-flex flex-row align-items-center">
                <FilterButton
                  onClick={() =>
                    setQueryParams({ archived: undefined, page: 1 })
                  }
                  className={"mr-4"}
                  selected={debArchived === undefined}
                >
                  {t("enums:template_state.all")}
                </FilterButton>
                <FilterButton
                  onClick={() => setQueryParams({ archived: "false", page: 1 })}
                  className={"mr-4"}
                  selected={debArchived === "false"}
                >
                  {t("enums:template_state.active")}
                </FilterButton>
                <FilterButton
                  onClick={() => setQueryParams({ archived: "true", page: 1 })}
                  className={"mr-4"}
                  selected={debArchived === "true"}
                >
                  {t("enums:template_state.archived")}
                </FilterButton>

                <div className="pr-5">
                  <span className="mr-3">{t("estimate_list.filter_code")}</span>
                  <WpInput
                    medium
                    placeholder={t("field:estimate.code")}
                    value={code}
                    style={{ width: 150 }}
                    onChange={(e) =>
                      setDebouncedQueryParams({
                        code: e.target.value,
                        page: 1,
                      })
                    }
                    className="pl-3"
                  />
                </div>
                <div>
                  <span className="mr-3">
                    {t("estimate_list.filter_title")}
                  </span>
                  <WpInput
                    medium
                    placeholder={t("field:estimate.title")}
                    style={{ width: 150 }}
                    value={title}
                    onChange={(e) =>
                      setDebouncedQueryParams({
                        title: e.target.value,
                        page: 1,
                      })
                    }
                    className="pl-3"
                  />
                </div>
              </div>
            </div>
          </div>
          {estimates.length > 0 && (
            <>
              <LongBorder topBar="tabbar" />
              <Table
                columns={columns}
                data={estimates}
                className="pb-8"
                highlightRows={[]}
                stickyHeader={true}
                stickyTopBar="tabbar"
              />
              {count !== 0 && (
                <Pagination
                  current={debPage}
                  pageCount={pageCount}
                  goToPage={(page) => {
                    setQueryParams({ page })
                    window.scrollTo(0, 10)
                  }}
                  hasNext={hasNext}
                  hasPrev={hasPrev}
                />
              )}
            </>
          )}
          {estimates.length === 0 && (
            <div className="text-center w-100 pt-8">
              <span>
                <i>{t("template.estimate.empty_list")}</i>
              </span>
            </div>
          )}
        </div>
      )}

      <ConfirmDeleteModal
        toggle={deleteModalActions.toggle}
        isOpen={deleteModal.isOpen}
        onConfirm={onEstimateDeletion}
        item={deleteModal.value?.title ?? ""}
        onClosed={deleteModalActions.onClose}
      />

      <CreateEstimateModal
        mode={
          createModal.value?.cloneId ? "instance_template" : "create_template"
        }
        cloneEstimate={createModal.value}
        toggle={createModalActions.toggle}
        isOpen={createModal.isOpen}
        onSave={onEstimateCreation}
        onClosed={createModalActions.onClose}
      />

      <Layout.TabBar>
        <TemplateTabBarContent />
      </Layout.TabBar>

      <Layout.FirstLevelNavi>
        <EstimateLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import WpModal from "../../../components/WpModal"
import { useDocFragments } from "../../../hooks/docFragments"

function InnerConfirm({ scenario, onConfirm, toggle }) {
  const { t } = useTranslation(["translation"])

  const params = useMemo(() => ({ use_scenario: scenario.id }), [scenario.id])
  const [{ frags }] = useDocFragments(params)

  return (
    <div className="d-flex flex-column">
      <span>{t("message.delete_item", { item: scenario.name })}</span>
      <span className="py-4">{t("message.delete_confirm")}</span>
      {frags !== null && frags.length > 0 && (
        <div className="alert-warning p-3 mb-4 rounded">
          {t("scenarios.remove_framgns_warn_message")}
          <ul className='mt-2 mb-0'>
            {frags.map((frag) => (
              <li key={frag.id}>{frag.title}</li>
            ))}
          </ul>
        </div>
      )}
      <div className="d-flex flex-row align-items-center justify-content-end">
        <Button
          size="sm"
          color="none"
          onClick={toggle}
        >
          {t("action:cancel")}
        </Button>
        <Button
          disabled={frags === null}
          size="sm"
          color="dark"
          className="ml-8"
          onClick={() => {
            onConfirm()
            toggle()
          }}
        >
          {t("action:confirm")}
        </Button>
      </div>
    </div>
  )
}

export default function ConfirmDeleteModalScenario({
  isOpen,
  toggle,
  onConfirm,
  onClosed,
  scenario,
}) {
  const { t } = useTranslation(["translation"])
  return (
    <WpModal
      isOpen={isOpen}
      toggle={toggle}
      title={t("confirm_delete.title")}
      onClosed={onClosed}
    >
      <InnerConfirm scenario={scenario} onConfirm={onConfirm} toggle={toggle} />
    </WpModal>
  )
}

import React, { useEffect, useMemo, useState } from "react"
import WpModal from "../../../../components/WpModal"
import Button from "../../../../components/Button"
import { useTranslation } from "react-i18next"
import FragCardButton from "../FragCardButton"
import { ReactComponent as FragTextIcon } from "../../../../assets/icons/frag_text.svg"
import { ReactComponent as FragTextTemplateIcon } from "../../../../assets/icons/frag_text_template.svg"
import { ReactComponent as FragTableIcon } from "../../../../assets/icons/frag_table.svg"
import { ReactComponent as FragListIcon } from "../../../../assets/icons/frag_list.svg"
import { ReactComponent as FragVizIcon } from "../../../../assets/icons/frag_viz.svg"
import { ReactComponent as FragVizDendrogramIcon } from "../../../../assets/icons/frag_viz_dendrogram.svg"
import { ReactComponent as FragVizTreemapIcon } from "../../../../assets/icons/frag_viz_treemap.svg"
import { ReactComponent as FragTasksIcon } from "../../../../assets/icons/frag_tasks.svg"
import { ReactComponent as FragScenarioListIcon } from "../../../../assets/icons/frag_scenario_list.svg"
import { ReactComponent as FragResourcesIcon } from "../../../../assets/icons/frag_resources.svg"
import { DOC_FRAGMENT_TYPE } from "../../../../consts"
import classNames from "classnames"
import S from "./AddFragModal.module.scss"
import Icon from "../../../../components/Icon"
import Table from "../../../../components/Table"
import Scrollbars from "react-custom-scrollbars"
import WpInput from "../../../../components/WpInput"
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor"
import useDebounce from "../../../../hooks/useDebounce"
import { useTemplateDocFragments } from "../../../../hooks/templateDocFragments"

const TEMPLATE_FILTERS = { fragment_type: "text" }

export default function AddFragModal({
  onAdd,
  toggle,
  isOpen,
  allowPageBreak,
  ...props
}) {
  const { t } = useTranslation(["translation", "action"])
  const [formName, setFormName] = useState("pick-element")

  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [templateFilter, setTemplateFilter] = useState("")

  const debTemplateFilter = useDebounce(templateFilter, 200)

  const filter = useMemo(() => {
    return {
      ...TEMPLATE_FILTERS,
      search: debTemplateFilter,
    }
  }, [debTemplateFilter])

  const [{ fragments }] = useTemplateDocFragments(filter)

  useEffect(() => {
    if (!isOpen) {
      setFormName("pick-element")
    }
  }, [isOpen])

  const options = [
    {
      title: t("documents.frag.text.title"),
      description: t("documents.frag.text.description"),
      icon: FragTextIcon,
      type: DOC_FRAGMENT_TYPE.TEXT,
      onClick: () => onAdd(DOC_FRAGMENT_TYPE.TEXT),
    },
    {
      title: t("documents.frag.text_template.title"),
      description: t("documents.frag.text_template.description"),
      icon: FragTextTemplateIcon,
      type: DOC_FRAGMENT_TYPE.TEXT,
      onClick: () => {
        setFormName("pick-template")
      },
    },
    {
      title: t("documents.frag.task.title"),
      description: t("documents.frag.task.description"),
      icon: FragTasksIcon,
      type: DOC_FRAGMENT_TYPE.TASK_LIST,
      onClick: () => {
        setFormName("pick-task")
      },
    },
    {
      title: t("documents.frag.viz.title"),
      description: t("documents.frag.viz.description"),
      icon: FragVizIcon,
      type: DOC_FRAGMENT_TYPE.VIZ,
      onClick: () => {
        setFormName("pick-viz")
      },
    },
    {
      title: t("documents.frag.resources.title"),
      description: t("documents.frag.resources.description"),
      icon: FragResourcesIcon,
      type: DOC_FRAGMENT_TYPE.RESOURCE_LIST,
      onClick: () => {
        setFormName("pick-resource")
      },
    },
    {
      title: t("documents.frag.scenario.title"),
      description: t("documents.frag.scenario.description"),
      icon: FragScenarioListIcon,
      type: DOC_FRAGMENT_TYPE.SCENARIO_LIST,
      onClick: () => onAdd(DOC_FRAGMENT_TYPE.SCENARIO_LIST),
    },
    {
      title: t("documents.frag.billing"),
      description: t("documents.frag.billing.description"),
      icon: FragTextIcon,
      type: DOC_FRAGMENT_TYPE.BILLING,
      onClick: () => onAdd(DOC_FRAGMENT_TYPE.BILLING),
    },
  ]

  const vizOptions = [
    {
      title: t("documents.frag.viz.bubble.title"),
      description: t("documents.frag.viz.bubble.description"),
      icon: FragVizIcon,
      onClick: () => onAdd(DOC_FRAGMENT_TYPE.CHART_BUBBLE),
    },
    {
      title: t("documents.frag.viz.dendrogram.title"),
      description: t("documents.frag.viz.dendrogram.description"),
      icon: FragVizDendrogramIcon,
      onClick: () => onAdd(DOC_FRAGMENT_TYPE.CHART_DENDROGRAM),
    },
    {
      title: t("documents.frag.viz.treemap.title"),
      description: t("documents.frag.viz.treemap.description"),
      icon: FragVizTreemapIcon,
      onClick: () => onAdd(DOC_FRAGMENT_TYPE.CHART_TREEMAP),
    },
    {
      title: t("documents.frag.viz.scenario_task_viz.title"),
      description: t("documents.frag.viz.scenario_task_viz.description"),
      icon: FragScenarioListIcon,
      onClick: () => onAdd(DOC_FRAGMENT_TYPE.SCENARIO_TASK_VIZ),
    },
    {
      title: t("documents.frag.viz.scenario_resource_viz.title"),
      description: t("documents.frag.viz.scenario_resource_viz.description"),
      icon: FragScenarioListIcon,
      onClick: () => onAdd(DOC_FRAGMENT_TYPE.SCENARIO_RESOURCE_VIZ),
    },
  ]

  const resourceOptions = [
    {
      title: t("documents.frag.resources.list.title"),
      description: t("documents.frag.resources.list.description"),
      icon: FragListIcon,
      onClick: () => onAdd(DOC_FRAGMENT_TYPE.RESOURCE_LIST),
    },
    {
      title: t("documents.frag.resources.table.title"),
      description: t("documents.frag.resources.table.description"),
      icon: FragTableIcon,
      onClick: () => onAdd(DOC_FRAGMENT_TYPE.RESOURCE_TABLE),
    },
  ]

  const taskOptions = [
    {
      title: t("documents.frag.task.list.title"),
      description: t("documents.frag.task.list.description"),
      icon: FragListIcon,
      type: DOC_FRAGMENT_TYPE.TASK_LIST,
      onClick: () => onAdd(DOC_FRAGMENT_TYPE.TASK_LIST),
    },
    {
      title: t("documents.frag.task.table.title"),
      description: t("documents.frag.task.table.description"),
      icon: FragTableIcon,
      type: DOC_FRAGMENT_TYPE.TASK_TABLE,
      onClick: () => onAdd(DOC_FRAGMENT_TYPE.TASK_TABLE),
    },
  ]

  const templateColumns = useMemo(
    () => [
      {
        label: t("field:template.text.title"),
        name: "title",
        size: 11,
        onClick: (e, datum) => {
          onAdd(DOC_FRAGMENT_TYPE.TEXT, datum.data, datum.title)
        },
        cellClassname: "pointer",
        render: (selectedDatum, colName, datum) => {
          return <div>{selectedDatum}</div>
        },
      },
      {
        label: t("action:see_info"),
        name: "info",
        size: 1,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className={"action-icon-container-primary-hover"}>
                <Icon
                  name="info"
                  title={t("field:template.text.info")}
                  className="pointer text-dark"
                  onClick={() => {
                    setFormName("template-info")
                    setSelectedTemplate(datum)
                  }}
                  style={{ width: 24, height: 24 }}
                />
              </div>
            </div>
          )
        },
      },
    ],
    [onAdd, t]
  )

  return (
    <WpModal
      isOpen={isOpen}
      toggle={toggle}
      {...props}
      size="lg"
      title={t("action:new_doc_frag")}
    >
      <div>
        <div className={classNames(S["frag-animator"], S[formName])}>
          <div className={classNames(S["add-frag-modal-choice"])}>
            <p>{t("documents.add_frag")}</p>
            <div className="d-flex flex-wrap align-items-center justify-content-around">
              {options.map((o, i) => (
                <FragCardButton key={i} elem={o} className="my-3" />
              ))}
            </div>
          </div>

          <div className={classNames(S["add-frag-select-viz"])}>
            <Button
              size="sm"
              color="none"
              onClick={() => setFormName("pick-element")}
              className="mb-5"
            >
              <div className="d-flex flex-row align-items-center">
                <Icon name="back" className="mr-3" />
                {t("action:back")}
              </div>
            </Button>
            <p>{t("documents.select_viz")}</p>
            <div className="d-flex flex-wrap align-items-center justify-content-around">
              {vizOptions.map((o, i) => (
                <FragCardButton key={i} elem={o} className="my-3" />
              ))}
            </div>
          </div>

          <div className={classNames(S["add-frag-select-resource"])}>
            <Button
              size="sm"
              color="none"
              onClick={() => setFormName("pick-element")}
              className="mb-5"
            >
              <div className="d-flex flex-row align-items-center">
                <Icon name="back" className="mr-3" />
                {t("action:back")}
              </div>
            </Button>
            <p>{t("documents.select_resource")}</p>
            <div className="d-flex flex-wrap align-items-center justify-content-around">
              {resourceOptions.map((o, i) => (
                <FragCardButton key={i} elem={o} className="my-3" />
              ))}
            </div>
          </div>

          <div className={classNames(S["add-frag-select-task"])}>
            <Button
              size="sm"
              color="none"
              onClick={() => setFormName("pick-element")}
              className="mb-5"
            >
              <div className="d-flex flex-row align-items-center">
                <Icon name="back" className="mr-3" />
                {t("action:back")}
              </div>
            </Button>
            <p>{t("documents.select_task")}</p>
            <div className="d-flex flex-wrap align-items-center justify-content-around">
              {taskOptions.map((o, i) => (
                <FragCardButton key={i} elem={o} className="my-3" />
              ))}
            </div>
          </div>

          <div className={classNames(S["add-frag-select-template"])}>
            <div className="d-flex flex-row align-items-center justify-content-between mb-5">
              <Button
                size="sm"
                color="none"
                onClick={() => setFormName("pick-element")}
              >
                <div className="d-flex flex-row align-items-center">
                  <Icon name="back" className="mr-3" />
                  {t("action:back")}
                </div>
              </Button>
              <div className="d-flex flex-row align-items-center justify-content-center">
                <span className="pr-3">{t("action:search")}:</span>
                <WpInput
                  placeholder={t("field:template.text.placeholder.search")}
                  style={{ width: 235 }}
                  value={templateFilter}
                  onChange={(e) => setTemplateFilter(e.target.value)}
                />
              </div>
            </div>
            <Scrollbars style={{ width: "100%", height: "85%" }}>
              {fragments !== null && fragments.length > 0 && (
                <Table
                  columns={templateColumns}
                  data={fragments}
                  highlightRows={[]}
                  stickyHeader={false}
                />
              )}
              {fragments !== null && fragments.length === 0 && (
                <div className="w-100 text-center pt-5">
                  <span>{t("template.text.empty_list")}</span>
                </div>
              )}
            </Scrollbars>
          </div>

          <div className={classNames(S["add-frag-template-info"])}>
            <Button
              size="sm"
              color="none"
              onClick={() => setFormName("pick-template")}
              className="mb-5"
            >
              <div className="d-flex flex-row align-items-center">
                <Icon name="back" className="mr-3" />
                {t("action:back")}
              </div>
            </Button>
            <Scrollbars style={{ width: "100%", height: "85%" }}>
              {selectedTemplate && (
                <div className="d-flex flex-column mx-8">
                  <p className="text-uppercase h2">{selectedTemplate.title}</p>
                  <RichTextEditor
                    key={selectedTemplate.id}
                    readOnly={true}
                    initialContent={selectedTemplate.data.content}
                    placeholder={t("template.text.empty_content")}
                  />
                </div>
              )}
            </Scrollbars>
          </div>
        </div>

        <div className="d-flex flex-row align-items-center justify-content-between pt-7 px-7">
          {allowPageBreak && (
            <Button
              size="sm"
              color="none"
              onClick={() => {
                onAdd(DOC_FRAGMENT_TYPE.PAGE_BREAK)
              }}
            >
              {t("action:page_break")}
            </Button>
          )}
          {!allowPageBreak && <span />}
          <div className="d-flex flex-row align-items-center justify-content-end">
            <Button size="sm" color="none" onClick={toggle}>
              {t("action:cancel")}
            </Button>
            {selectedTemplate && formName === "template-info" && (
              <Button
                size="sm"
                color="primary"
                className="ml-5"
                onClick={() =>
                  onAdd(
                    DOC_FRAGMENT_TYPE.TEXT,
                    selectedTemplate.data,
                    selectedTemplate.title
                  )
                }
              >
                {t("action:confirm")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </WpModal>
  )
}

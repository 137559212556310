import React, { useCallback, useMemo, useState } from "react"
import Layout from "../../../components/Layout"
import { useTemplateDocFragments } from "../../../hooks/templateDocFragments"
import TemplateTabBarContent from "../TemplateTabBarContent"
import Loader from "../../../components/Loader/Loader"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import WpInput from "../../../components/WpInput"
import { omit } from "lodash"
import Scrollbars from "react-custom-scrollbars"
import S from "./TextTemplate.module.scss"
import FragTextPreviewCard from "./FragTextPreviewCard"
import Icon from "../../../components/Icon"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import Separator from "../../../components/Separator/Separator"
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import useModalTrigger from "../../../hooks/useModalTrigger"
import { Field, Formik } from "formik"
import FormAutoSave from "../../../components/FormAutoSave"
import FieldInput from "../../../components/fields/FieldInput"
import * as yup from "yup"
import CreateFragModal from "../CreateFragModal"
import { fromRichText2Text, notifyError } from "../../../utils"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import RichTextEditor from "../../../components/RichTextEditor/RichTextEditor"
import FilterButton from "../../../components/FilterButton"
import useModalTriggerQuota from "../../../hooks/useModalTriggerQuota"
import { useQuota } from "../../../hooks/quota"
import WpModalQuota from "../../../components/WpModalQuota"
import { EstimateLandmarks } from "../../../components/EstimateLandmarks"

const TEMPLATE_FILTERS = { fragment_type: "text" }

export default function TextTemplate() {
  const [
    params,
    setQueryParams,
    debParams,
    setDebouncedQueryParams,
  ] = useQueryParams()

  const filter = useMemo(() => {
    return {
      ...TEMPLATE_FILTERS,
      ...debParams,
    }
  }, [debParams])

  const [
    { fragments },
    { createFrag, deleteFrag, updateFrag, run: reload },
  ] = useTemplateDocFragments(filter)

  const { t } = useTranslation(["translation", "action", "field"])

  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [editable, setEditable] = useState(false)

  const [deleteModal, deleteModalActions] = useModalTrigger()

  const [createFragModal, createFragModalActions] = useModalTriggerQuota()

  const [, { refreshQuota }] = useQuota()
  // Don't use useModalTriggerQuota here since quota check is performed before opening
  const [exhaustedQuotaModal, exhaustedQuotaModalActions] = useModalTrigger()

  const onCreate = useCallback(() => {
    createFragModalActions.close()
  }, [createFragModalActions])

  const onDelete = useCallback(() => {
    deleteModalActions.close()
  }, [deleteModalActions])

  const onTemplateCreation = useCallback(
    ({ title }) => {
      return createFrag
        .onSuccess((frag) => {
          createFragModalActions.close()
          setSelectedTemplateId(frag.id)
          setEditable(true)
        })
        .asPromise({ title, data: { content: null }, fragment_type: "text" })
    },
    [createFrag, createFragModalActions]
  )

  const onTemplateDuplication = useCallback(
    (frag) => {
      return refreshQuota
        .onSuccess((quota) => {
          if (quota.templates > 0) {
            return createFrag
              .onFailure(notifyError)
              .onSuccess((frag) => {
                createFragModalActions.close()
                setSelectedTemplateId(frag.id)
                setEditable(true)
              })
              .run(
                omit(
                  { ...frag, title: frag.title + "_copy", archived: "false" },
                  "id"
                )
              )
          } else {
            exhaustedQuotaModalActions.open()
          }
        })
        .run()
    },
    [
      createFrag,
      createFragModalActions,
      exhaustedQuotaModalActions,
      refreshQuota,
    ]
  )

  const onTemplateDeletion = useCallback(() => {
    setSelectedTemplateId(null)
    return deleteFrag
      .onFailure(notifyError)
      .onSuccess(() => {
        deleteModalActions.close()
        setEditable(false)
      })
      .asPromise(deleteModal.value)
  }, [deleteFrag, deleteModal, deleteModalActions])

  const onTemplateArchive = useCallback(
    (toArchive) => {
      updateFrag
        .onFailure(notifyError)
        .onSuccess(() => {
          reload(filter)
        })
        .run({ id: toArchive.id, archived: true })
    },
    [filter, reload, updateFrag]
  )

  const onTemplateRestore = useCallback(
    (toRestore) => {
      updateFrag
        .onFailure(notifyError)
        .onSuccess(() => {
          reload(filter)
        })
        .run({ id: toRestore.id, archived: false })
    },
    [filter, reload, updateFrag]
  )

  const handleSubmit = useCallback(
    (values) => {
      return updateFrag.asPromise(values)
    },
    [updateFrag]
  )

  if (fragments === null) {
    return (
      <Layout>
        <Loader />
        <Layout.TabBar>
          <TemplateTabBarContent />
        </Layout.TabBar>

        <Layout.FirstLevelNavi>
          <EstimateLandmarks />
        </Layout.FirstLevelNavi>
      </Layout>
    )
  }

  const selectedTemplate =
    selectedTemplateId !== null
      ? fragments.find((elem) => elem.id === selectedTemplateId)
      : null

  const mappedTextFrags = fragments.map((frag) => {
    return {
      title: frag.title,
      id: frag.id,
      text_content: fromRichText2Text(frag.data.content),
      archived: frag.archived,
    }
  })

  const handleChangeFrag = (id) => {
    setSelectedTemplateId(id)
    if (id !== selectedTemplateId) {
      setEditable(false)
    }
  }

  const schema = yup.object().shape({
    title: yup
      .string()
      .required(
        t("field:errors.mandatory", { field: t("field:template.text.title") })
      )
      .max(512, t("field:errors.max", { max_value: 512 })),
  })

  return (
    <Layout
      displayRawContent
      className="flex-1 d-flex flex-column justify-content-start align-items-stretch"
    >
      <div className="flex-1 d-flex flex-row">
        <div className={S["left-container"]}>
          <Scrollbars style={{ width: "100%", height: "100%" }}>
            <div className="sticky-top bg-gray-bg-light mr-4">
              <div className="pt-7 pb-5 ml-4 px-4 border-bottom">
                <Button onClick={() => createFragModalActions.open()}>
                  {t("action:new_text_frag")}
                </Button>
              </div>
            </div>

            {mappedTextFrags.length > 0 &&
              mappedTextFrags.map((textFrag) => (
                <FragTextPreviewCard
                  key={textFrag.id}
                  frag={textFrag}
                  onClick={handleChangeFrag}
                  selected={selectedTemplateId === textFrag.id}
                />
              ))}
            {mappedTextFrags.length === 0 && (
              <div className="text-center w-100 pt-8">
                <span>
                  <i>{t("template.text.empty_list")}</i>
                </span>
              </div>
            )}
          </Scrollbars>
        </div>
        <div className="flex-1">
          <Scrollbars style={{ width: "100%", height: "100%" }}>
            <div className="sticky-top bg-white mx-4">
              <div className="d-flex flex-row justify-content-end align-items-center px-5 py-8">
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <div className="d-flex flex-row align-items-center mr-4">
                    <FilterButton
                      onClick={() => setQueryParams({ archived: undefined })}
                      className={"mr-4"}
                      selected={debParams.archived === undefined}
                    >
                      {t("enums:text_template_state.all")}
                    </FilterButton>
                    <FilterButton
                      onClick={() => setQueryParams({ archived: "false" })}
                      className={"mr-4"}
                      selected={debParams.archived === "false"}
                    >
                      {t("enums:text_template_state.active")}
                    </FilterButton>
                    <FilterButton
                      onClick={() => setQueryParams({ archived: "true" })}
                      className={"mr-4"}
                      selected={debParams.archived === "true"}
                    >
                      {t("enums:text_template_state.archived")}
                    </FilterButton>

                    {/* TODO search tag */}
                  </div>
                  <span className="pr-3">{t("action:search")}:</span>
                  <WpInput
                    placeholder={t("field:template.text.placeholder.search")}
                    medium
                    style={{ width: 235 }}
                    value={params.search}
                    onChange={(e) => {
                      setSelectedTemplateId(null)
                      setDebouncedQueryParams({ search: e.target.value })
                    }}
                  />
                </div>
              </div>
            </div>

            {!editable && (
              <div>
                {selectedTemplate && (
                  <div className="d-flex flex-column align-items-stretch justify-content-start">
                    <div className="d-flex flex-row align-items-center justify-content-between px-8">
                      <h2 className="text-uppercase">
                        {selectedTemplate.title}
                      </h2>
                      <div className="d-flex flex-row align-items-center justify-content-center">
                        {!selectedTemplate.archived && (
                          <Icon
                            name="edit"
                            onClick={() => setEditable(true)}
                            className={"action-icon-container-primary-hover"}
                            title={t("action:edit_template_mode")}
                          />
                        )}
                        <Icon
                          name="duplicate"
                          className={classNames(
                            "action-icon-container-primary-hover pointer",
                            "ml-4"
                          )}
                          onClick={() =>
                            onTemplateDuplication(selectedTemplate)
                          }
                          title={t("action:duplicate")}
                        />
                        <div
                          className={classNames(
                            "action-icon-container-primary-hover pointer",
                            "ml-4"
                          )}
                        >
                          <UncontrolledDropdown>
                            <DropdownToggle caret={false} tag={"span"}>
                              <Icon
                                role="button"
                                name="vdots"
                                className="text-dark pointer"
                                title={t("action:other_options")}
                              />
                            </DropdownToggle>
                            <DropdownMenu
                              right
                              modifiers={{ offset: { offset: "0, 12" } }}
                              className="border-primary"
                            >
                              {!selectedTemplate.archived && (
                                <DropdownItem
                                  className={classNames(
                                    "text-capitalize px-0",
                                    "dropdown-item-primary-active"
                                  )}
                                >
                                  <div
                                    className="d-flex flex-row align-items-center mx-4"
                                    onClick={() =>
                                      onTemplateArchive(selectedTemplate)
                                    }
                                  >
                                    <Icon
                                      name="archive"
                                      className={classNames("pointer mr-4")}
                                    />
                                    <span>{t("action:archive")}</span>
                                  </div>
                                </DropdownItem>
                              )}
                              {selectedTemplate.archived && (
                                <DropdownItem
                                  className={classNames(
                                    "text-capitalize px-0",
                                    "dropdown-item-primary-active"
                                  )}
                                >
                                  <div
                                    className="d-flex flex-row align-items-center mx-4"
                                    onClick={() =>
                                      onTemplateRestore(selectedTemplate)
                                    }
                                  >
                                    <Icon
                                      name="restore_archive"
                                      className={classNames("pointer mr-4")}
                                    />
                                    <span>{t("action:restore")}</span>
                                  </div>
                                </DropdownItem>
                              )}
                              <Separator className="my-0 mx-4 border-primary" />
                              <DropdownItem
                                className={classNames(
                                  "text-capitalize px-0",
                                  "dropdown-item-primary-active"
                                )}
                              >
                                <div
                                  className="d-flex flex-row align-items-center mx-4"
                                  onClick={() => {
                                    deleteModalActions.open(selectedTemplate)
                                  }}
                                >
                                  <Icon
                                    name="delete"
                                    className="pointer mr-4"
                                  />
                                  <span>{t("action:delete")}</span>
                                </div>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </div>
                    </div>
                    <Separator />
                    <div className="mx-8">
                      <RichTextEditor
                        key={selectedTemplateId}
                        readOnly={!editable}
                        initialContent={selectedTemplate.data.content}
                        placeholder={t(
                          "field:template.text.placeholder.empty_content"
                        )}
                      />
                    </div>
                    <Separator />
                  </div>
                )}
                {!selectedTemplate && (
                  <p className="pt-8 text-center">
                    <i>{t("template.text.empty_selection")}</i>
                  </p>
                )}
              </div>
            )}
            {editable && (
              <div>
                {selectedTemplateId && (
                  <Formik
                    key={selectedTemplateId}
                    initialValues={selectedTemplate}
                    onSubmit={handleSubmit}
                    validationSchema={schema}
                  >
                    {({ handleSubmit, setFieldValue }) => (
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <FormAutoSave />
                        <div className="d-flex flex-column align-items-stretch justify-content-start">
                          <div className="d-flex flex-row align-items-center justify-content-between px-8">
                            <Field
                              placeholder={t(
                                "field:template.text.placeholder.title"
                              )}
                              name="title"
                              component={FieldInput}
                              className="font-weight-semibold"
                              style={{ width: 235 }}
                            />
                            <div className="d-flex flex-row align-items-center justify-content-center">
                              <Icon
                                name="view"
                                onClick={() => setEditable(false)}
                                className={
                                  "action-icon-container-primary-hover pointer"
                                }
                                title={t("action:view_template_mode")}
                              />
                              <Icon
                                name="duplicate"
                                className={classNames(
                                  "action-icon-container-primary-hover pointer",
                                  "ml-4"
                                )}
                                onClick={() =>
                                  onTemplateDuplication(selectedTemplate)
                                }
                                title={t("action:duplicate")}
                              />
                              <div
                                className={classNames(
                                  "action-icon-container-primary-hover",
                                  "ml-4"
                                )}
                              >
                                <UncontrolledDropdown>
                                  <DropdownToggle caret={false} tag={"span"}>
                                    <Icon
                                      role="button"
                                      name="vdots"
                                      className="text-dark pointer"
                                      title={t("action:other_options")}
                                    />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    right
                                    modifiers={{ offset: { offset: "0, 12" } }}
                                    className="border-primary"
                                  >
                                    {!selectedTemplate.archived && (
                                      <DropdownItem
                                        className={classNames(
                                          "text-capitalize px-0",
                                          "dropdown-item-primary-active"
                                        )}
                                      >
                                        <div
                                          className="d-flex flex-row align-items-center mx-4"
                                          onClick={() => {
                                            onTemplateArchive(selectedTemplate)
                                            setEditable(false)
                                          }}
                                        >
                                          <Icon
                                            name="archive"
                                            className={classNames(
                                              "pointer mr-4"
                                            )}
                                          />
                                          <span>{t("action:archive")}</span>
                                        </div>
                                      </DropdownItem>
                                    )}
                                    <Separator className="my-0 mx-4 border-primary" />
                                    <DropdownItem
                                      className={classNames(
                                        "text-capitalize px-0",
                                        "dropdown-item-primary-active"
                                      )}
                                    >
                                      <div
                                        className="d-flex flex-row align-items-center mx-4"
                                        onClick={() => {
                                          deleteModalActions.open(
                                            selectedTemplate
                                          )
                                        }}
                                      >
                                        <Icon
                                          name="delete"
                                          className="pointer mr-4"
                                        />
                                        <span>{t("action:delete")}</span>
                                      </div>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </div>
                          </div>
                          <Separator />
                          <div className="mx-8">
                            <RichTextEditor
                              readOnly={!editable}
                              initialContent={selectedTemplate.data.content}
                              save={(content) =>
                                setFieldValue("data.content", content)
                              }
                            />
                          </div>
                          <Separator />
                        </div>
                      </form>
                    )}
                  </Formik>
                )}
                {!selectedTemplateId && (
                  <p className="pt-8 text-center">
                    <i>{t("template.text.empty_selection")}</i>
                  </p>
                )}
              </div>
            )}
          </Scrollbars>
        </div>
      </div>

      {deleteModal.value && (
        <ConfirmDeleteModal
          toggle={onDelete}
          isOpen={deleteModal.isOpen}
          onConfirm={onTemplateDeletion}
          item={deleteModal.value.title}
          onClosed={deleteModalActions.onClose}
        />
      )}

      <CreateFragModal
        fragmentType={"text"}
        toggle={onCreate}
        isOpen={createFragModal.isOpen}
        onConfirm={onTemplateCreation}
        onClosed={createFragModalActions.onClose}
      />

      <WpModalQuota
        checkQuota="templates"
        title={t(`template.text.duplicate_frag`)}
        isOpen={exhaustedQuotaModal.isOpen}
        toggle={exhaustedQuotaModalActions.toggle}
        onClosed={exhaustedQuotaModalActions.onClose}
      />

      <Layout.TabBar>
        <TemplateTabBarContent />
      </Layout.TabBar>

      <Layout.FirstLevelNavi>
        <EstimateLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import WpModal from "../../../components/WpModal"

export default function ConfirmUnlinkModal({
  isOpen,
  toggle,
  onConfirm,
  item,
  onClosed,
}) {
  const { t } = useTranslation(["translation"])

  return (
    <WpModal
      isOpen={isOpen}
      toggle={toggle}
      title={t("confirm_unlink.title")}
      onClosed={onClosed}
    >
      <div className="d-flex flex-column">
        <span style={{ userSelect: "none" }}>
          {t("confirm_unlink.message", { item: item })}
        </span>
        <div className="d-flex flex-row align-items-center justify-content-end">
          <Button size="sm" color="none" onClick={toggle}>
            {t("action:cancel")}
          </Button>
          <Button
            size="sm"
            color="dark"
            className="ml-8"
            onClick={() => {
              onConfirm()
              toggle()
            }}
          >
            {t("action:confirm")}
          </Button>
        </div>
      </div>
    </WpModal>
  )
}

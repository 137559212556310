import { get } from "lodash"
import { rj, useRunRj } from "react-rocketjump"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import api from "../api"

const getOrSelect = (context, pathOrFunction) => {
  if (typeof pathOrFunction === "function") {
    return pathOrFunction(context)
  }
  return get(context, pathOrFunction)
}

const ProjectListRj = rj(
  rjList({
    pageSize: 100,
    pagination: nextPreviousPaginationAdapter,
    customPaginationReducer: (prevState, { payload: { data } }) => {
      return {
        ...prevState,
        count: getOrSelect(data, nextPreviousPaginationAdapter.count),
        current: getOrSelect(data, nextPreviousPaginationAdapter.current),
        next: getOrSelect(data, nextPreviousPaginationAdapter.next),
        previous: getOrSelect(data, nextPreviousPaginationAdapter.previous),
        full_count: data.full_count,
      }
    },
  }),
  {
    effectCaller: rj.configured(),
    effect: (wpAuth) => (filters) => {
      return api.auth(wpAuth).get("/api/project/", filters)
    },
    selectors: ({ getPagination }) => ({
      getFullCount: (state) => {
        if (state.root) {
          return get(state, "root.data.pagination.full_count", 0)
        }
        return get(state, "data.pagination.full_count", 0)
      },
    }),
    computed: {
      projects: "getList",
      count: "getCount",
      fullCount: "getFullCount",
      loading: "isPending",
      error: "getError",
      pageCount: "getNumPages",
      hasNext: "hasNext",
      hasPrev: "hasPrev",
    },
    mutations: {
      create: rj.mutation.single({
        effect: (wpAuth) => (data) => {
          return api.auth(wpAuth).post(`/api/project/`, data)
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: [...state.data.list, result],
            },
          }
        },
      }),
      update: rj.mutation.multi((projectId) => projectId, {
        effect: (wpAuth) => (projectId, data) => {
          return api.auth(wpAuth).patch(`/api/project/${projectId}/`, data)
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.map((item) =>
                item.id !== result.id ? item : result
              ),
            },
          }
        },
      }),
      remove: rj.mutation.single({
        effect: (wpAuth) => (projectId) => {
          return api
            .auth(wpAuth)
            .mapResponse(() => projectId)
            .delete(`/api/project/${projectId}/`)
        },
        updater: (state, projectId) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.filter((item) => item.id !== projectId),
            },
          }
        },
      }),
    },
  }
)

export function useProjectsList(params) {
  return useRunRj(ProjectListRj, [params], false)
}

import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"

import { registerLocale } from "react-datepicker"
import it from "date-fns/locale/it"

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      queryStringParams: { v: process.env.REACT_APP_VERSION || "dev" },
    },
    supportedLngs: ["it", "en"],
    fallbackLng: {
      default: ["it"],
    },
    debug: process.env.NODE_ENV !== "production",
    ns: ["action", "enums", "field", "tab", "translation"],
    defaultNS: "translation",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

registerLocale("it", it)

export default i18n

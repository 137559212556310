import React, { useRef } from "react"
import classNames from "classnames"
import DropdownList from "../DropdownList"
import { useTranslation } from "react-i18next"
import useIsMount from "../../hooks/useIsMount"
import { getIn } from "formik"
import ErrorPopover from "../ErrorPopover/ErrorPopover"

// TODO: Handle errors???
export default function FieldDropdownList({
  label,
  labelClassname,
  field,
  emptyText = "",
  form,
  readOnly = false,
  disabled = undefined,
  onChange = () => {},
  ...props
}) {
  const { t } = useTranslation()
  const input = useRef()
  const isMount = useIsMount()
  const fieldError = getIn(form.errors, field.name)
  const fieldTouched = getIn(form.touched, field.name)
  const showError = fieldError && fieldTouched
  const fieldBlur = () => field.onBlur(field.name)

  return (
    <div className={classNames("d-flex flex-column")}>
      {label && (
        <label
          className={classNames(
            "font-weight-semibold mb-3 h3 text-uppercase",
            labelClassname
          )}
        >
          {label}
        </label>
      )}
      <DropdownList
        ref={input}
        value={field.value}
        onChange={(value) => {
          form.setFieldValue(field.name, value)
          onChange(value)
        }}
        onBlur={fieldBlur}
        disabled={disabled || readOnly}
        invalid={showError}
        {...props}
      />
      {showError && !isMount && (
        <ErrorPopover
          placement={"top-start"}
          target={input.current}
          title={t("field:errors.general")}
          description={fieldError}
        />
      )}
    </div>
  )
}

import { Group } from "@visx/group"
import { hierarchy, Pack } from "@visx/hierarchy"
import classNames from "classnames"
import { sum } from "lodash"
import React, { useMemo } from "react"
import { getDepth } from "react-sortable-tree"
import MultiLineText from "../../../../components/MultiLineText"
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter"
import { ColorScheme } from "../colorScheme"
import S from "./CirclePack.module.scss"

const defaultMargin = { top: 10, left: 10, right: 10, bottom: 10 }

const CirclePackOptionsContext = React.createContext()

/**
 *
 * @param {{ colorScheme: ColorScheme }} param0
 */
function Node({ node, colorScheme, options }) {

  const currencyFmt = useCurrencyFormatter()

  const x = node.x
  const y = node.y
  const r = node.r

  // Some nodes like fake nodes have no path
  const topLevelAncestorIdx = parseInt((node?.data?.path?.split(".") ?? [0])[0])

  const myClassNames = []
  if (!node.data.__is_fake) {
    myClassNames.push(
      colorScheme.stroke(node.depth - 1, topLevelAncestorIdx - 1),
      colorScheme.fill(node.depth - 1, topLevelAncestorIdx - 1)
    )
  } else {
    myClassNames.push(S["fake-node"])
  }

  if (node.data._isOwnResources) {
    myClassNames.push(S["own-resources"])
  }

  if (node.depth === 0 || node.r <= 0) {
    return null
  }

  const textParts = [node.data.path]

  if (options?.displayPrices) {
    const p = parseFloat(node.data.own_price)
    if (p > 0.01 || p < -0.01) {
      textParts.push(currencyFmt.format(p))
    }
  }

  return (
    <Group top={y} left={x}>
      <circle className={classNames(myClassNames)} r={r} />
      {!node.children && !node.data._isOwnResources && (
        <MultiLineText
          width={r > 0 ? r * 1.5 : 100}
          style={{ pointerEvents: "none" }}
          textAnchor="middle"
          verticalAnchor="middle"
          maxLinesPerChunk={[-1, -1]}
        >
          {textParts.join("\n")}
        </MultiLineText>
      )}
    </Group>
  )
}

function recursiveAddTitleFakeNode(node, titleSpacingFactor = 0.07, isRoot) {
  if (isRoot) {
    return {
      ...node,
      children: node.children.map((child) =>
        recursiveAddTitleFakeNode(child, titleSpacingFactor, false)
      ),
    }
  } else if (node.children.length > 0) {
    return {
      ...node,
      children: [
        {
          path: node.path,
          title: node.title,
          __is_fake: true,
          __real_price: node.price,
          own_price: node.own_price,
          price:
            sum(node.children.map((child) => parseFloat(child.price))) *
            titleSpacingFactor,
          // price: 10 ** Math.floor(Math.log10(node.price)),
          children: [],
        },
        ...node.children.map((child) =>
          recursiveAddTitleFakeNode(child, titleSpacingFactor, false)
        ),
      ],
    }
  } else {
    return node
  }
}

export default function CirclePack({
  width,
  height,
  margin = defaultMargin,
  data: clusterData,
  distortion: titleSpacingFactor = 0.07,
  options = {},
}) {
  const data = useMemo(
    () =>
      hierarchy(
        recursiveAddTitleFakeNode(clusterData, titleSpacingFactor, true)
      ).sum((d) => parseFloat(d.price) ?? 0),
    [clusterData, titleSpacingFactor]
  )

  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const colorScheme = useMemo(() => {
    return new ColorScheme("area", getDepth(clusterData) - 1)
  }, [clusterData])

  return (
    <CirclePackOptionsContext.Provider value={options}>
      <svg width={width} height={height}>
        {!isNaN(xMax) && !isNaN(yMax) && (
          <Group top={margin.top} left={margin.left}>
            <Pack
              root={data}
              size={[xMax, yMax]}
              nodeComponent={Node}
              padding={20}
            >
              {(pack) => {
                return pack.descendants().map((node) => {
                  let k = ""
                  if (node.data._isOwnResources) {
                    k = "R" + node.data.id
                  }
                  else if (node.data.__is_fake) {
                    k = "F" + node.data.path
                  } else {
                    k = "N" + node.data.id
                  }
                  return (
                    <Node
                      key={k}
                      node={node}
                      colorScheme={colorScheme}
                      options={options}
                    />
                  )
                })
              }}
            </Pack>
          </Group>
        )}
      </svg>
    </CirclePackOptionsContext.Provider>
  )
}

import { rj, useRunRj } from "react-rocketjump"
import api from "../api"

const HrAnomalyDetectionRulesRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (params) => {
    return api.auth(wpAuth).get(`/api/hr/anomaly-detection-rule/`, params)
  },
})

export function useHrAnomalyDetectionRules(params) {
  return useRunRj(HrAnomalyDetectionRulesRj, [params])
}

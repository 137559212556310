import React from "react"
import { useTranslation } from "react-i18next"
import SortableTree from "react-sortable-tree"
import FileExplorerTheme from "react-sortable-tree-theme-file-explorer"
import { notifyError } from "../../../utils"
// NOTE: use simple css over css module cause i need real class names
import './TaskTreePreview.scss'

export default function TaskTreePreview({
  taskTree,
  updateTaskTree,
  moveTask,
  disableMove = false
}) {
  const { t } = useTranslation(["translation"])
  return (
    <>
      <h2 className="text-uppercase font-size-semibold pt-2 pb-3 px-4">
        {t("tasks.overview")}
      </h2>
      <div className="p">
        <SortableTree
          className='SortableTaskTreeSortablePreview'
          theme={FileExplorerTheme}
          isVirtualized={false}
          getNodeKey={({ node }) => node.id}
          treeData={taskTree}
          onChange={() => {}}
          canDrag={!disableMove}
          generateNodeProps={({ node }) => ({
            title: (
              <>
                {node.path} {node.title}
              </>
            ),
          })}
          onVisibilityToggle={({ treeData }) => {
            updateTaskTree(treeData)
          }}
          onMoveNode={({ node, nextParentNode, treeData }) => {
            const siblisings = nextParentNode
              ? nextParentNode.children
              : treeData
            const index = siblisings.indexOf(node)
            const afterNode = index > 0 ? siblisings[index - 1] : null
            const payload = {
              after: afterNode?.id ?? null,
              parent: nextParentNode?.id ?? null,
              ids: [node.id],
            }
            moveTask.onFailure(notifyError).run(payload, treeData)
          }}
        />
      </div>
    </>
  )
}

import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import get from "lodash/get"
import { set } from "object-path-immutable"
import { CurrentEstimateStateContext } from "../context"
import { notifyError } from "../utils"

const NO_OPTIONS = {}

export default function useCurrentEstimateOption(path, defaultValue) {
  const [{ estimate }, { patchEstimateOptions }] = useContext(
    CurrentEstimateStateContext
  )
  const patchEstimateOptionsWithErrorNotify = useMemo(() => {
    return patchEstimateOptions.onFailure(notifyError).curry()
  }, [patchEstimateOptions])

  // In case of readonly estimate, we don't persist options
  // but we keep them locally in order to allow for interactions
  // in vizs and tables, see https://github.com/inmagik/workplan2-web/issues/97
  const [localOptions, setLocalOptions] = useState(NO_OPTIONS)

  const remoteOptions = estimate?.options ?? NO_OPTIONS

  useEffect(() => {
    setLocalOptions(remoteOptions)
  }, [remoteOptions])

  const readonlyEstimate = estimate?.readonly

  const options = readonlyEstimate
    ? localOptions
    : remoteOptions

  const setOptions = readonlyEstimate
    ? setLocalOptions
    : patchEstimateOptionsWithErrorNotify

  const value = get(
    readonlyEstimate ? localOptions : remoteOptions,
    path,
    defaultValue
  )

  const setValue = useCallback(
    (nextValue) => {
      setOptions(set(options, path, nextValue))
    },
    [setOptions, options, path]
  )

  return [value, setValue]
}

import React from "react"
import classNames from "classnames"
import S from "./ScenarioCard.module.scss"
import Separator from "../../../components/Separator"
import { UncontrolledTooltip } from "reactstrap"
import ScenarioSymbol from "../../../components/ScenarioSymbol"
import Icon from "../../../components/Icon"
import { useTranslation } from "react-i18next"
import ScenarioEditForm from "./ScenarioEditForm"
import useCurrencyFormatter from "../../../hooks/useCurrencyFormatter"

export default function ScenarioCard({
  scenario,
  scenarioPrice,
  isOpen,
  toggleOpen,
  onSave,
  onRemove,
  readOnly = false,
}) {
  const { t } = useTranslation()
  const currencyFmt = useCurrencyFormatter()

  return (
    <div className={S["scenario-row"]}>
      <div
        className={classNames(S["scenario-header"], {
          [S["no-scenario-border"]]: isOpen,
        })}
        onClick={() => toggleOpen(scenario.id)}
      >
        <div
          className={classNames(
            "d-flex flex-row h-100 w-100",
            S["header-container"]
          )}
        >
          <div
            className={classNames(
              S["scenario-header-section"],
              S["scenario-header-section-path"],
              "py-5"
            )}
          >
            <ScenarioSymbol scenario={scenario} />
          </div>
          <Separator vertical className="my-4 mx-4" />
          <div
            className={classNames(
              S["scenario-header-section"],
              S["scenario-header-section-title"]
            )}
          >
            <span id={`scenario-title-${scenario.id}`} className={S["title"]}>
              {scenario.name}
            </span>
            <UncontrolledTooltip
              placement={"bottom"}
              key={scenario.id}
              target={`scenario-title-${scenario.id}`}
              fade={false}
            >
              {scenario.name}
            </UncontrolledTooltip>
          </div>
          {!readOnly && (
            <>
              <div className={classNames(S["scenario-header-section"])}>
                <div
                  role="button"
                  className={classNames(S["icon-container"])}
                  // onClick={handleMove}
                >
                  <Icon
                    name="duplicate"
                    // onClick={handleCopy}
                    // title={t("action:copy_task")}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                </div>
              </div>
              <Separator vertical className="my-4 mx-2" />
              <div className={classNames(S["scenario-header-section"])}>
                <div role="button" className={classNames(S["icon-container"])}>
                  <Icon
                    name="delete"
                    title={t("action:delete")}
                    onClick={(e) => {
                      e.stopPropagation()
                      onRemove(scenario)
                    }}
                    style={{
                      width: 24,
                      height: 24,
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <div className={classNames(S["scenario-header-section-money"], "ml-2")}>
            <span>{currencyFmt.format(scenarioPrice)}</span>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="border-bottom">
          <div className={classNames(S["edit-scenario-card"])}>
            <ScenarioEditForm
              readOnly={readOnly}
              scenario={scenario}
              onSave={onSave}
            />
          </div>
        </div>
      )}
    </div>
  )
}

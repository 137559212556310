import { rj, useRj } from "react-rocketjump"
import api from "../api"

const AttachmentCreateRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (file) => {
    const formData = new FormData()
    formData.append("file", file)
    return api.auth(wpAuth).post(`/api/attachment/`, formData)
  },
})

export function useAttachmentUploader() {
  const [{ pending }, { run }] = useRj(AttachmentCreateRj)

  return { uploading: pending, upload: run }
}

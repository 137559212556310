import React from "react"
import { useTranslation } from "react-i18next"
import { BsCalendar2Check } from "react-icons/bs"
import { DropdownItem } from "reactstrap"
import { useInteractionTools } from "./InteractionModalContext"

export function DropdownItemCreateInteraction({
  disabled = false,
  initialValues = {},
  lockedFields = [],
  onSuccess,
  onFailure,
}) {
  const { t } = useTranslation()
  const { createInteraction } = useInteractionTools()

  return (
    <DropdownItem
      className={"text-capitalize px-0 dropdown-item-primary-active"}
      disabled={disabled}
    >
      <div
        className="d-flex flex-row align-items-center mx-4"
        onClick={() => {
          if (!disabled) {
            createInteraction(initialValues, lockedFields).then(
              (result) => {
                if (typeof onSuccess === "function") {
                  onSuccess(result)
                }
              },
              (err) => {
                if (typeof onFailure === "function") {
                  onFailure(err)
                }
              }
            )
          }
        }}
      >
        <BsCalendar2Check
          style={{
            width: 16,
            height: 16,
            marginRight: 16,
            marginLeft: 4,
          }}
        />
        <span>{t("action:new_customer_interaction")}</span>
      </div>
    </DropdownItem>
  )
}

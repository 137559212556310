import { Field } from "formik"
import React, { useMemo } from "react"
import FieldRichText from "../../../../../components/fields/FieldRichText"
import EditDataFrame, {
  EditDataSection,
} from "../../EditDataFrame/EditDataFrame"

const EditDataForm = ({
  fullEstimate,
  frag,
  save,
  remove,
  saveTemplate,
  toggle,
  scrollFragIntoView,
}) => {
  const readOnly = fullEstimate.readonly
  const ctx = useMemo(() => ({ estimate: fullEstimate }), [fullEstimate])

  return (
    <EditDataFrame
      frag={frag}
      save={save}
      remove={remove}
      saveTemplate={saveTemplate}
      toggle={toggle}
      scrollFragIntoView={scrollFragIntoView}
      enableShowTitle={false}
      readOnly={fullEstimate.readonly}
      scenarios={fullEstimate.scenarios}
      enableScenarioSelector={false}
    >
      <EditDataSection>
        <Field
          name="data.content"
          component={FieldRichText}
          readOnly={readOnly}
          placeholder={readOnly ? "" : undefined}
          templatingContext={ctx}
          enableBlockFormatting={true}
          enableUndoRedo={false}
          enableFontSize={false}
          enableLists={false}
        />
      </EditDataSection>
    </EditDataFrame>
  )
}

export default EditDataForm

import React, { useCallback, useState } from "react"
import classNames from "classnames"
import ScenarioSelector from "../../pages/Estimate/ScenarioSelector"
import { useTranslation } from "react-i18next"
import ScenarioSymbol from "../ScenarioSymbol"

export default function FieldScenarioSelector({
  label,
  labelClassname,
  field,
  form,
  scenarios,
  disabled,
  readOnly,
  enableAll,
  enableBase,
  ...props
}) {
  const { t } = useTranslation(["translation"])
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const toggle = useCallback(() => {
    if(readOnly) {
      return
    }
    setDropdownOpen((v) => !v)
  }, [readOnly])
  return (
    <div className="d-flex flex-column">
      {label && (
        <label
          className={classNames(
            "font-weight-semibold mb-3 h3 text-uppercase",
            labelClassname
          )}
        >
          {label}
        </label>
      )}
      <div className="d-flex flex-row justify-content-start align-items-center pointer">
        <ScenarioSelector
          scenariosList={scenarios}
          selectedScenarios={field.value}
          onSave={(value) => form.setFieldValue(field.name, value)}
          title={t("documents.select_scenario_message")}
          enableAll={enableAll}
          enableBase={enableBase}
          reducer={(tempState, selected) => {
            if (selected === null) {
              return null
            } else if (selected?.length === 0) {
              return []
            } else {
              return [selected.id]
            }
          }}
          isOpen={isDropdownOpen}
          toggle={toggle}
        >
          <span className="font-weight-semibold">
            {t("scenarios.filter_message") + ":"}
          </span>
          {field.value === null &&
            scenarios.map((scenario) => (
              <ScenarioSymbol
                key={scenario.id}
                scenario={scenario}
                isInferred={false}
                className="mx-2"
              />
            ))}
          {field.value !== null &&
            field.value.length > 0 &&
            scenarios.map((scenario) => {
              if (!field.value.includes(scenario.id)) {
                return null
              }
              return (
                <ScenarioSymbol
                  key={scenario.id}
                  scenario={scenario}
                  isInferred={false}
                  className="mx-2"
                />
              )
            })}
          {field.value !== null && field.value.length === 0 && (
            <span className="badge badge-separator ml-4 py-2">
              {t("scenarios.filter_base_only")}
            </span>
          )}
        </ScenarioSelector>
      </div>
    </div>
  )
}

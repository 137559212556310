import React from "react"
import { useTranslation } from "react-i18next"

export default function CurrentPageIndicator({ current, pageCount }) {
  const { t } = useTranslation()
  return (
    <span>
      {t("pagination.current_page", {
        current: current,
        page_count: pageCount,
      })}
    </span>
  )
}

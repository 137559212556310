import React from "react"
import S from "./ScenarioSymbol.module.scss"
import chroma from "chroma-js"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

const DEFAULT_COLORS = {
  background: "#ffffff",
  textDark: "#333333",
  textLight: "#ffffff",
}

export default function ScenarioSymbol({
  scenario,
  isInferred = false,
  enableCode = true,
  className = "",
}) {
  const { t } = useTranslation(["translation"])
  const bgColorBase = scenario.options?.color ?? DEFAULT_COLORS.background
  const bgColor = chroma(bgColorBase).alpha(0.4)
  const textColor =
    chroma.contrast(bgColor, DEFAULT_COLORS.textDark) >= 4.5
      ? DEFAULT_COLORS.textDark
      : DEFAULT_COLORS.textLight

  const saturation = chroma(bgColorBase).get("hsv.s")
  const value = chroma(bgColorBase).get("hsv.v")
  
  const borderColor = scenario.options?.color
    ? saturation < 0.3 && value > 0.7
      ? textColor
      : bgColorBase
    : DEFAULT_COLORS.textDark
  return (
    <div
      className={classNames(S["container"], className)}
      title={isInferred ? t("scenarios.inferred_title") : scenario.name}
    >
      <div
        className={S["symbol-container"]}
        style={{
          backgroundColor: bgColor,
          color: textColor,
          borderStyle: isInferred ? "dotted" : "solid",
          borderColor: borderColor,
          opacity: isInferred ? 0.5 : 1,
        }}
      >
        {enableCode && scenario.code}
      </div>
    </div>
  )
}

import { chunkBlocks } from "../pageUtils"

export default function PageBreakSplitter(
  node,
  props,
  firstChunkSpace,
  sheetHeight
) {
  const caption = node.querySelector(".__pager-caption")
  const captionHeight = caption?.offsetHeight ?? 0

  const blocks = node.querySelectorAll(".__pager-node")
  const title = node.querySelector(".__pager-title")
  const titleHeight = title?.offsetHeight ?? 0

  const out = chunkBlocks(
    blocks,
    firstChunkSpace - titleHeight,
    sheetHeight,
    captionHeight
  )

  return out
}

import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useProfile } from "../../hooks/profile"
import useModalTrigger from "../../hooks/useModalTrigger"
import Button from "../Button/Button"
import Icon from "../Icon/Icon"
import WpModal from "../WpModal/WpModal"
import S from "./VerifyEmailBanner.module.scss"
import { toast } from "react-toastify"

export default function VerifyEmailBanner() {
  const [modalState, modalActions] = useModalTrigger()
  const { t } = useTranslation(["translation", "action"])
  const [, { resendVerificationEmail }] = useProfile()

  const resendEmail = useCallback(() => {
    resendVerificationEmail
      .onSuccess(() => {
        toast.success(t("email-verification.email-send-done"))
      })
      .onFailure(() => {
        toast.error(t("email-verification.email-send-error"))
      })
      .run()
  }, [resendVerificationEmail, t])

  return (
    <>
      <div
        className={S["verify-email-banner"]}
        onClick={() => modalActions.open()}
      >
        <h3 className="m-0 d-flex align-items-center">
          {t("email-verification.error")}
          <span className="pl-4" />
          <Icon name="info" style={{ width: 20, height: 20 }} />
        </h3>
      </div>
      <WpModal
        isOpen={modalState.isOpen}
        onClosed={modalActions.onClose}
        toggle={modalActions.toggle}
        title={t("email-verification.title")}
      >
        <p>{t("email-verification.limitation-intro")}</p>
        <p>{t("email-verification.limitation-list-caption")}</p>
        <ul>
          <li>{t("email-verification.limitation-features")}</li>
          <li>{t("email-verification.limitation-expires")}</li>
        </ul>
        <div className="text-right">
          <Button size="sm" className="mr-5" color="none" onClick={resendEmail}>
            {t("email-verification.resend-email")}
          </Button>
          <Button size="sm" onClick={modalActions.close}>
            {t("action:understood")}
          </Button>
        </div>
      </WpModal>
    </>
  )
}

export function chunkBlocks(blocks, firstChunkSpace, sheetHeight, chunkBias, log = false) {
  let currentChunkHeight = chunkBias
  let currentChunkStart = undefined
  let currentChunkEnd = undefined
  const chunks = []
  let maxChunkHeight = firstChunkSpace

  const firstBlockHeight = blocks[0].offsetHeight

  if (log) {
    console.log(firstBlockHeight, maxChunkHeight, currentChunkHeight)
  }

  // If there is not enough space on first page for header + footer
  // just push a null chunk to tell the paginator to break page before 
  // rendering this component
  if (currentChunkHeight + firstBlockHeight > maxChunkHeight) {
    chunks.push(null)
    maxChunkHeight = sheetHeight
  }

  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i]
    const height = block.offsetHeight
    if (log) {
      console.log(i, height, currentChunkHeight, chunks.length)
    }
    if (currentChunkHeight + height > maxChunkHeight) {
      // Another page has been filled, push it in the chunks array
      // Remember: all chunks must measure their height before returning
      chunks.push({
        props: {
          sliceRowStart: currentChunkStart,
          sliceRowEnd: currentChunkEnd + 1
        },
        height: currentChunkHeight
      })
      currentChunkStart = i
      currentChunkEnd = i
      currentChunkHeight = chunkBias + height
      maxChunkHeight = sheetHeight
    } else {
      currentChunkEnd = i
      currentChunkHeight += height
    }
  }

  chunks.push({
    props: {
      sliceRowStart: currentChunkStart,
      sliceRowEnd: undefined
    },
    height: currentChunkHeight
  })

  return chunks
}
import { Field, Formik } from "formik"
import * as Yup from "yup"
import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import FieldTextarea from "../../../components/fields/FieldTextarea"
import WpModal from "../../../components/WpModal"
import { convertErrorToForm } from "../../../utils"
import FormNoFieldError from "../../../components/FormNoFieldError"

const INITIAL_SCENARIO = {
  name: "",
  description: "",
}

const ScenarioSchema = Yup.object({
  name: Yup.string().required(),
  description: Yup.string(),
})

export default function ModalAddScenario({ onSave, isOpen, toggle, onClosed }) {
  const { t } = useTranslation(["field", "action"])
  return (
    <WpModal title={t("action:new_scenario")} isOpen={isOpen} toggle={toggle}>
      <Formik
        onClosed={onClosed}
        onSubmit={(values, actions) => onSave(values).catch(error => {
          actions.setErrors(convertErrorToForm(error))
        })}
        validationSchema={ScenarioSchema}
        initialValues={INITIAL_SCENARIO}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Field
              className="mb-4"
              name="name"
              component={FieldTextarea}
              label={t("field:scenario.name")}
            />
            <Field
              rows={6}
              name="description"
              component={FieldTextarea}
              label={t("field:scenario.description")}
            />
            <div className="d-flex flex-row align-items-center justify-content-end mt-6">
              <Button
                disabled={isSubmitting}
                type="button"
                size="sm"
                color="none"
                onClick={toggle}
              >
                {t("action:cancel")}
              </Button>
              <Button
                disabled={isSubmitting}
                type="submit"
                size="sm"
                color="primary"
                className="ml-5"
              >
                {t("action:save")}
              </Button>
            </div>
            <FormNoFieldError className='mt-4' />
          </form>
        )}
      </Formik>
    </WpModal>
  )
}

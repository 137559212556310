import dayjs from "dayjs"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { useAuthUser } from "use-eazy-auth"
import HrResourceSelector from "../../components/HrResourceSelector"
import Layout from "../../components/Layout"
import { TrackerLandmarks } from "../../components/TrackerLandmarks"
import { CAP, CAPLEVEL, useCapabilities } from "../../hooks/capabilities"
import { useHrAllocationKinds, useHrAllocations, useHrAllocationsReportNew } from "../../hooks/useHrAllocations"
import { useHrResources } from "../../hooks/useHrResources"
import { CalendarList } from "./components/CalendarList"
import { Legend } from "./components/Legend"
import { Navbar } from "./components/Navbar"
import { Navigator } from "./components/Navigator"

function HrWorkflowListNewSafe({ hrResource }) {
  const { t } = useTranslation()
  const [, { hasCapability }] = useCapabilities()

  const today = dayjs()
  const [[month, year], setMonthAndYear] = useState([today.get("month") + 1, today.get("year")])
  const [selectedResourceId, setSelectedResourceId] = useState(hrResource.id)

  const filters = useMemo(() => {
    if (selectedResourceId > 0) {
      return { person: selectedResourceId }
    }
    return {}
  }, [selectedResourceId])

  const [{ data: allocs }] = useHrAllocations(year, month, filters)
  const [{ data: kinds }] = useHrAllocationKinds()

  const [{ pending: reportPending }, { run: downloadReport }] = useHrAllocationsReportNew()

  return (
    <Layout displayRawContent className="flex-1 d-flex flex-column justify-content-start align-items-stretch">
      <Navbar year={year} month={month} />
      <div className="pt-5 px-page pb-page-bottom flex-1 d-flex flex-column">
        <Row style={{ marginLeft: -4, marginRight: -4 }}>
          <Col sm={2} className="d-flex flex-row align-items-center px-2">
            <h1 className="text-primary font-weight-semibold">{t("hr_workflow_new.title")}</h1>
          </Col>
          <Col sm={10} className="px-2">
            <Navigator
              month={month}
              year={year}
              onChange={setMonthAndYear}
              isReportLoading={reportPending}
              downloadReport={() => {
                downloadReport(year, month)
              }}
            />
          </Col>
        </Row>
        <Row className="mt-4 flex-1" style={{ marginLeft: -4, marginRight: -4 }}>
          <Col sm={2} className="px-2">
            <div className="bg-light rounded p-4 h-100 d-flex flex-column justify-content-start align-items-stretch">
              {hasCapability(CAP.WORKFLOW, CAPLEVEL.ADMIN) && (
                <div className="mb-10">
                  <HrResourceSelector
                    containerClassName="w-100"
                    idOrIds={selectedResourceId}
                    onChange={(res) => {
                      setSelectedResourceId(res)
                    }}
                    extraOptions={[{ value: -1, label: t("hr_workflow_new.all_resources") }]}
                  />
                </div>
              )}
              {kinds && <Legend kinds={kinds} allocs={allocs} />}
              <div className="flex-1" />
            </div>
          </Col>
          <Col sm={10} className="px-2">
            <CalendarList month={month} year={year} allocs={allocs} kinds={kinds ?? []} />
          </Col>
        </Row>
      </div>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

export function HrWorkflowListNew() {
  const { user } = useAuthUser()
  const [, { hasCapability }] = useCapabilities()

  const hrResourceFilter = useMemo(() => {
    return { is_active: true }
  }, [])
  const [{ data: hrResources }] = useHrResources(hrResourceFilter)
  const personalResource = hrResources?.find((r) => r.profile === user.id)
  const isHrAdmin = hasCapability(CAP.WORKFLOW, CAPLEVEL.ADMIN)

  if (personalResource) {
    return <HrWorkflowListNewSafe hrResource={personalResource} />
  } else if (hrResources && !personalResource && isHrAdmin) {
    return <HrWorkflowListNewSafe hrResource={hrResources[0]} />
  } else if (hrResources && !personalResource && !isHrAdmin) {
    return (
      <Layout>
        <Navbar />
        <div className="px-page">Accesso negato</div>
        <Layout.FirstLevelNavi>
          <TrackerLandmarks />
        </Layout.FirstLevelNavi>
      </Layout>
    )
  } else {
    return (
      <Layout>
        <Navbar />
        <div className="px-page">Caricamento...</div>
        <Layout.FirstLevelNavi>
          <TrackerLandmarks />
        </Layout.FirstLevelNavi>
      </Layout>
    )
  }
}

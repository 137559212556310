import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import Icon from "../../components/Icon"
import Layout from "../../components/Layout"
import { ProjectsList as ProjectsListCmp } from "../../components/ProjectsList"
import { TrackerLandmarks } from "../../components/TrackerLandmarks"

export default function ProjectsList({ history }) {
  const { t } = useTranslation(["translation", "field", "enums", "action"])
  const ref = useRef()

  return (
    <Layout>
      <div className="container-fluid pt-8 px-page pb-page-bottom">
        <h1 className="text-primary font-weight-semibold">
          {t("projects_list.title")}
        </h1>
        <ProjectsListCmp ref={ref} />
      </div>

      <Layout.Toolbar title={t("estimate_list.toolbar_title")}>
        <Layout.Toolbar.Icon
          text={t("action:new_project")}
          svgIcon={<Icon name="project" />}
          title={t("action:new_project")}
          onClick={() => ref.current.triggerCreate({})}
        />
        <Layout.Toolbar.Divider />
      </Layout.Toolbar>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

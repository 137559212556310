import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import WpModal from "../../../components/WpModal/WpModal"

export default function ImportBillsModal({
  isOpen,
  toggle,
  onClosed,
  onConfirm,
}) {
  const { t } = useTranslation()

  return (
    <WpModal
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
      title={t("project_info.generate_bills")}
    >
      <p>{t("project_info.generate_bills_msg")}</p>
      <div className="text-right mt-5">
        <Button
          type="button"
          size="sm"
          color="none"
          onClick={() => toggle()}
          className="mr-4"
        >
          {t("action:cancel")}
        </Button>
        <Button type="button" size="sm" onClick={onConfirm}>
          {t("action:save")}
        </Button>
      </div>
    </WpModal>
  )
}

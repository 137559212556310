import * as chroma from "chroma-js"
import classNames from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { BsPlusCircleDotted } from "react-icons/bs"
import useModalTrigger from "../hooks/useModalTrigger"
import Button from "./Button"
import { TagSelector } from "./TagSelector"
import WpModal from "./WpModal"

function Tag({ tag, style, className, onRemove, ...props }) {
  let textColor = "black"
  if (
    chroma.contrast("white", tag.color) > chroma.contrast("black", tag.color)
  ) {
    textColor = "white"
  }
  return (
    <div
      style={{
        ...style,
        borderRadius: 3,
        backgroundColor: tag.color,
        color: textColor,
        fontSize: "0.8em",
      }}
      className={classNames(className, "py-1 px-2 d-inline-block")}
      {...props}
    >
      {tag.name}
      {typeof onRemove === "function" && (
        <span
          className="pointer ml-3"
          onClick={() => {
            onRemove(tag)
          }}
        >
          ×
        </span>
      )}
    </div>
  )
}

export function TagCollection({ tags, style, onRemove, onAdd, ...props }) {
  const [pickerModalState, pickerModalActions] = useModalTrigger()

  return (
    <>
      {tags.map((tag) => {
        return (
          <Tag
            key={tag.id}
            tag={tag}
            style={style}
            {...props}
            onRemove={onRemove}
          />
        )
      })}
      {onAdd && (
        <span
          className="pointer"
          onClick={() => {
            pickerModalActions.open("dummy")
          }}
        >
          <BsPlusCircleDotted />
        </span>
      )}
      {onAdd && pickerModalState.value && (
        <PickerModal
          isOpen={pickerModalState.isOpen}
          toggle={pickerModalActions.toggle}
          onClosed={pickerModalActions.onClose}
          onSave={(tag) => {
            onAdd(tag)
          }}
        />
      )}
    </>
  )
}

function PickerModal({ isOpen, toggle, onSave, onClosed }) {
  const { t } = useTranslation()
  const [selectedTag, setSelectedTag] = useState(null)
  return (
    <WpModal
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      onClosed={onClosed}
      title={t("tagSelector.title")}
    >
      <div className="d-flex flex-column">
        <TagSelector tagId={selectedTag?.id} onChange={setSelectedTag} />
        <div className="d-flex flex-row justify-content-end mt-5">
          <Button size="sm" color="none" onClick={toggle}>
            {t("action:cancel")}
          </Button>
          <Button
            size="sm"
            color="primary"
            type="submit"
            className="ml-5"
            disabled={!selectedTag}
            onClick={() => {
              onSave(selectedTag)
              toggle()
            }}
          >
            {t("action:save")}
          </Button>
        </div>
      </div>
    </WpModal>
  )
}

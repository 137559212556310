import Slider from "rc-slider"
import React from "react"
import classNames from "classnames"

export default function FieldSlider({
  label,
  labelClassname,
  field,
  form,
  marks,
  max,
  disabled,
  readOnly,
  min = 1,
  step = null,
  style,
  className = "ml-6 mb-5",
  ...props
}) {
  return (
    <div className="d-flex flex-column">
      {label && (
        <label
          className={classNames(
            "font-weight-semibold mb-3 h3 text-uppercase",
            labelClassname
          )}
        >
          {label}
        </label>
      )}
      <Slider
        value={field.value ?? max}
        min={min}
        marks={marks}
        className={className}
        step={step}
        max={max}
        onChange={(value) => form.setFieldValue(field.name, value)}
        defaultValue={max}
        style={{ width: "90%", ...style }}
        trackStyle={{ backgroundColor: "var(--primary)" }}
        handleStyle={{
          border: "2px solid var(--primary)",
          height: 16,
          width: 16,
          marginTop: -6,
          cursor: "pointer",
        }}
        activeDotStyle={{
          borderColor: "transparent",
          backgroundColor: "var(--primary)",
        }}
        
        disabled={disabled || readOnly}
      />
    </div>
  )
}

import React from "react"
import TaskTable from "../../../EstimateTasksTree/TaskTable/TaskTable"
import { pagerLog } from "../../Pager"
import { sliceExtent } from "../../../treeOperations"

const FragComponent = React.memo(
  ({ frag, estimate, sliceRowStart, sliceRowEnd, chunkIsLast, inPage, chunkIsFirst }) => {
    if (inPage) {
      pagerLog("[PAGER] Task table rendered")
    }

    const visibleTasks = frag?.data?.visibleTasks ?? null

    const cutSelectTree = sliceExtent(estimate.task_tree, visibleTasks)

    const selectedScenarios = frag.selected_scenarios

    if (frag.data.display_base_forced) {
      selectedScenarios.push(0)
    }

    return (
      <div className="">
        {frag?.data?.showTitle && chunkIsFirst && <h1 className="__pager-title m-0 pb-3">{frag?.title}</h1>}
        <TaskTable
          {...frag.data}
          taskTree={cutSelectTree}
          resources={estimate.resources}
          editable={false}
          stickyHeader={false}
          scenarios={estimate.scenarios}
          selectedScenarios={selectedScenarios}
          fullEstimate={estimate}
          sliceRowStart={sliceRowStart}
          sliceRowEnd={sliceRowEnd}
          arePricesVisible={frag.data.arePricesVisible !== false}
          isTotalVisible={(chunkIsLast === true || chunkIsLast === undefined) && frag.data.isTotalVisible}
        />
      </div>
    )
  },
  (prevProps, nextProps) => {
    const checkProps = ["frag", "estimate", "sliceRowStart", "sliceRowEnd", "chunkIsLast", "chunkIsFirst"]
    for (let i = 0; i < checkProps.length; i++) {
      const prop = checkProps[i]
      if (prevProps[prop] !== nextProps[prop]) {
        return false
      }
    }
    return true
  }
)

export default FragComponent

import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import rjLogger from "react-rocketjump/logger"
import * as Sentry from "@sentry/react"
import "@stripe/stripe-js"
import "./i18n"

/* STYLES */
import "./styles/index.scss"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "rc-switch/assets/index.css"
import "react-toastify/dist/ReactToastify.css"
import "./styles/toast.scss"
import "react-datepicker/dist/react-datepicker.css"
import { BrowserTracing } from "@sentry/tracing"

if (process.env?.REACT_APP_RJ_LOGGER !== "no-log") {
  rjLogger()
}

if (process.env.NODE_ENV === "production" && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: "https://b0a41d3059254c73802878a2532aeffe@o1047151.ingest.sentry.io/4503930387365888",
    integrations: [new BrowserTracing()],
    release: process.env.REACT_APP_VERSION ?? "dev",
    tracesSampleRate: 0.0,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={"An error has occurred, our team is working on it"}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

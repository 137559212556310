import { useTranslation } from "react-i18next"
import { MdOutlineDashboard } from "react-icons/md"
import { VscDashboard } from "react-icons/vsc"
import { CAP, useCapabilities } from "../hooks/capabilities"
import { useLinkToBusinessMonitor, useLinkToIndicators, useLinkToOverview } from "../hooks/links"
import Layout from "./Layout"

export function IndicatorsLandmarks() {
  const { t } = useTranslation()
  const [, { hasCapability }] = useCapabilities()
  const overviewLink = useLinkToOverview()
  const businessMonitorLink = useLinkToBusinessMonitor()
  const indicatorsLink = useLinkToIndicators()

  return (
    <>
      {hasCapability(CAP.OVERVIEW) && (
        <>
          <Layout.FirstLevelNavi.Tab
            text={t("navbar.crm_overview")}
            svgIcon={<MdOutlineDashboard size={20} />}
            to={overviewLink}
          />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
      {hasCapability(CAP.BUSINESS) && (
        <>
          <Layout.FirstLevelNavi.Tab
            text={t("navbar.business_monitoring")}
            svgIcon={<VscDashboard size={20} />}
            to={businessMonitorLink}
          />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
      {hasCapability(CAP.INDICATORS) && (
        <>
          <Layout.FirstLevelNavi.Tab text={t("navbar.indicators")} svgIcon={<VscDashboard size={20} />} to={indicatorsLink} />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
    </>
  )
}

import classNames from "classnames"
import dayjs from "dayjs"
import { isEmpty, isNil } from "lodash"
import React from "react"
import { BsCalendar2Check, BsCalendar2X } from "react-icons/bs"
import { FaTags } from "react-icons/fa"
import { Link } from "react-router-dom"
import { Badge } from "reactstrap"
import { TagCollection } from "../../components/TagCollection"
import WpLink from "../../components/WpLink"
import S from "./style.module.scss"

export function Card({
  title,
  link,
  value,
  expireDate,
  nextInteractionId,
  nextInteractionDate,
  nextInteractionDescription,
  missedDeadlineId,
  missedDeadlineDate,
  missedDeadlineDescription,
  billableAmount,
  customerId,
  borderDanger,
  annotations,
  tags,
  editInteraction,
  nextBillDate,
}) {
  return (
    <div
      className={classNames(S.card, {
        [S["card-border-danger"]]: borderDanger,
      })}
    >
      <div>
        <WpLink to={link}>{title}</WpLink>
      </div>
      <div className="d-flex flex-row">
        {value && (
          <Badge color="primary" className="mr-2" title="Valore progetto">
            {value}
          </Badge>
        )}
        {expireDate && (
          <Badge color="danger" className="mr-2" title="Data di consegna">
            {dayjs(expireDate).format("DD/MM/YYYY")}
          </Badge>
        )}
        <div className="flex-1" />
        {!!billableAmount && (
          <Badge color="success" className="mr-2" title="Importo non fatturato">
            {formatEuro(billableAmount)}
          </Badge>
        )}
        {!!nextBillDate && (
          <Badge color="warning" className="mr-2" title="Data prossima fattura">
            {dayjs(nextBillDate).format("DD/MM/YYYY")}
          </Badge>
        )}
      </div>
      {!isEmpty(tags) && (
        <div className={S["interaction-row"]}>
          <div className="d-flex flex-row flex-wrap align-items-center mt-1" style={{ gap: 3 }}>
            <FaTags />
            <TagCollection tags={tags} />
          </div>
        </div>
      )}
      {annotations && annotations.length > 0 && (
        <div className={S["interaction-row"]}>
          {annotations.map(({ type, message }, i) => (
            <div key={i}>
              <small className={classNames(S["interaction-description"], `text-${type}`)}>{message}</small>
            </div>
          ))}
        </div>
      )}
      {nextInteractionDate && (
        <div
          className={S["interaction-row"]}
          onClick={() => {
            editInteraction(nextInteractionId)
          }}
        >
          <Link>
            <BsCalendar2Check size={15} className="mr-4" />
            {dayjs(nextInteractionDate).format("DD/MM/YYYY")}
            <br />
            <span className={S["interaction-description"]}>{nextInteractionDescription}</span>
          </Link>
        </div>
      )}
      {missedDeadlineDate && (
        <div className={S["interaction-row"] + " text-danger"}>
          <BsCalendar2X size={15} className="mr-4" />
          {dayjs(missedDeadlineDate).format("DD/MM/YYYY")}
          <br />
          <span className={S["interaction-description"]}>{missedDeadlineDescription}</span>
        </div>
      )}
    </div>
  )
}

function formatEuro(value) {
  if (isNil(value)) {
    return <i>Non calcolabile</i>
  } else {
    return <span>{parseFloat(value).toFixed(2) + " €"}</span>
  }
}

import React from "react"
import { deps } from "react-rocketjump"
import {
  useProjectTaskEnum,
  useProjectTaskEnumDetail,
} from "../../hooks/projectTasks"
import DropdownList from "../DropdownList"

const PROJECT_TASK_SEARCH_FILTER = {}

export default function ProjectTaskSelector({
  label,
  projectId,
  taskId,
  onTaskChange,
  clearable,
  extraOptions = [],
  ...props
}) {
  const [
    { data: task },
    { updateData: updateSelectedTask },
  ] = useProjectTaskEnumDetail(
    projectId,
    deps.maybe(taskId && taskId > 0 ? taskId : null)
  )
  const [{ tasks }, { runDebounced: searchTasks }] = useProjectTaskEnum(
    projectId,
    PROJECT_TASK_SEARCH_FILTER
  )

  const taskOptions =
    tasks?.map((task) => ({
      label: task.title,
      value: task.id,
    })) ?? []

  const options = [...extraOptions, ...taskOptions]

  let caption = undefined

  if (taskId) {
    if (taskId > 0) {
      caption = task?.title ?? "..."
    } else {
      caption = extraOptions.find((opt) => opt.value === taskId)?.label
    }
  }

  return (
    <DropdownList
      {...props}
      value={taskId}
      caption={caption}
      options={options}
      onChange={(nextTaskId) => {
        const nextTask = tasks.find((t) => t.id === nextTaskId)
        updateSelectedTask(nextTask)
        onTaskChange(nextTask)
      }}
      onSearchChange={(searchStr) => {
        searchTasks(projectId, { search: searchStr })
      }}
      clearable={clearable}
      onClear={() => {
        updateSelectedTask(null)
        onTaskChange(null)
      }}
    />
  )
}

import {
  difference,
  flatten,
  fromPairs,
  get,
  groupBy,
  map,
  pick,
  sortBy,
  sum,
  union,
} from "lodash"
import intersection from "lodash/intersection"
import { flattenCloneTree } from "./EstimateTasksTree/TaskTable/utils"

export function isInBaseScenario(task) {
  return task.inferredScenarios.length === 0
}

export function matchesScenarios(task, scenariosIds) {
  return intersection(task.inferredScenarios, scenariosIds).length > 0
}

function constructScenarioValueMap(task, prop = "own_price") {
  const pairs = task.inferredScenarios.map((scenarioId) => [
    scenarioId,
    parseFloat(task[prop]),
  ])
  if (pairs.length === 0) {
    return { "": parseFloat(task[prop]) }
  }
  return fromPairs(pairs)
}

function mergeScenarioValueMaps(baseMap, ...maps) {
  const out = { ...baseMap }
  for (const map of maps) {
    for (const scenarioId in map) {
      if (scenarioId in out) {
        out[scenarioId] = out[scenarioId] + map[scenarioId]
      } else {
        out[scenarioId] = map[scenarioId] + get(baseMap, "", 0)
      }
    }
  }
  return out
}

export function sumPrices(list, accessor, base = 0) {
  return list
    .reduce((acc, item) => {
      return acc + parseFloat(get(item, accessor, 0))
    }, parseFloat(base))
    .toFixed(2)
}

export function adaptForScenarios(fullEstimate, scenariosIds) {
  const estimate = inferConsistentScenarios(fullEstimate)
  const filterByScenarios =
    scenariosIds !== null ? scenariosIds : map(estimate.scenarios, "id")
  const recursiveAdaptForScenarios = (task) => {
    return {
      ...task,
      children: task.children
        .filter((t) => {
          return isInBaseScenario(t) || matchesScenarios(t, filterByScenarios)
        })
        .map(recursiveAdaptForScenarios),
    }
  }

  const recursiveComputeCostAndPrice = (task) => {
    const nextChildren = task.children.map(recursiveComputeCostAndPrice)
    return {
      ...task,
      children: nextChildren,
      cost: sumPrices(nextChildren, "cost", task.own_cost),
      price: sumPrices(nextChildren, "price", task.own_price),
      priceMap: mergeScenarioValueMaps(
        constructScenarioValueMap(task, "own_price"),
        ...map(nextChildren, "priceMap")
      ),
      costMap: mergeScenarioValueMaps(
        constructScenarioValueMap(task, "own_cost"),
        ...map(nextChildren, "costMap")
      ),
    }
  }

  // if (filterByScenarios === null) {
  //   return estimate
  // }
  const tree = { children: estimate.task_tree }
  const tasks = recursiveAdaptForScenarios(tree).children
  const taskWithPrices = tasks.map(recursiveComputeCostAndPrice)

  return {
    ...estimate,
    task_tree: taskWithPrices,
    price: sumPrices(taskWithPrices, "price"),
  }
}

export function markForScenarios(fullEstimate, scenariosIds) {
  const estimate = inferConsistentScenarios(fullEstimate)
  const filterByScenarios =
    scenariosIds !== null ? scenariosIds : map(estimate.scenarios, "id")
  const recursiveMarkForScenarios = (task) => {
    return {
      ...task,
      children: task.children
        .map((t) => {
          return {
            ...t,
            matchesScenarios:
              task.matchesScenarios &&
              (isInBaseScenario(t) || matchesScenarios(t, filterByScenarios)),
          }
        })
        .map(recursiveMarkForScenarios),
    }
  }

  const recursiveComputeCostAndPrice = (task) => {
    const nextChildren = task.children.map(recursiveComputeCostAndPrice)
    const accountChildren = nextChildren.filter((c) => c.matchesScenarios)
    return {
      ...task,
      children: nextChildren,
      cost: sumPrices(
        accountChildren,
        "cost",
        task.matchesScenarios ? task.own_cost : 0
      ),
      fullTaskCost: sumPrices(accountChildren, "cost", task.own_cost),
      price: sumPrices(
        accountChildren,
        "price",
        task.matchesScenarios ? task.own_price : 0
      ),
      fullTaskPrice: sumPrices(nextChildren, "fullTaskPrice", task.own_price),
    }
  }

  const tree = {
    matchesScenarios: true,
    children: estimate.task_tree,
  }
  const tasks = recursiveMarkForScenarios(tree).children
  const taskWithPrices = tasks.map(recursiveComputeCostAndPrice)

  return {
    ...estimate,
    task_tree: taskWithPrices,
    price: sumPrices(
      taskWithPrices.filter((c) => c.matchesScenarios),
      "price"
    ),
  }
}

export function inferConsistentScenarios(estimate) {
  function liftChildrenScenariosUp(task, domain = []) {
    const children = task.children.map((child) =>
      liftChildrenScenariosUp(child, task.scenarios)
    )
    const inferredFromChildren = union(
      ...children.map((task) => task._inferred)
    )
    const inferredForTask =
      task.scenarios.length === 0 && domain.length === 0
        ? []
        : union(task.scenarios, inferredFromChildren)
    return {
      ...task,
      children,
      _inferred: inferredForTask,
      _inferredFromChildren: inferredFromChildren,
    }
  }

  function fixInferenceDown(task, inheritFromParent = []) {
    let inferredScenarios = [] // nessuno scenario = tutti gli scenari
    if (task._inferred.length === 0) {
      inferredScenarios = sortBy(inheritFromParent)
    } else {
      inferredScenarios = sortBy(union(task._inferred, inheritFromParent))
    }
    let subtreeDomain = union(task.scenarios, inheritFromParent)
    return {
      ...task,
      inferredScenarios,
      inferredOnlyScenarios: difference(inferredScenarios, task.scenarios),
      children: task.children.map((child) =>
        fixInferenceDown(child, subtreeDomain)
      ),
    }
  }

  const rooted_task_tree = {
    children: estimate.task_tree,
    scenarios: [],
  }

  return {
    ...estimate,
    task_tree: fixInferenceDown(liftChildrenScenariosUp(rooted_task_tree))
      .children,
  }
}

export function computeScenarioPrices(fullEstimate) {
  const estimate = inferConsistentScenarios(fullEstimate)
  const prices = {}
  for (const scenario of estimate.scenarios) {
    prices[scenario.id] = 0
  }
  const queue = [...estimate.task_tree]
  while (queue.length > 0) {
    const task = queue.shift()
    let scenarios = task.inferredScenarios
    if (scenarios.length === 0) {
      scenarios = map(fullEstimate.scenarios, "id")
    }
    for (const scenarioId of scenarios) {
      prices[scenarioId] += parseFloat(task.own_price)
    }
    queue.push(...task.children)
  }
  return prices
}

export function computeResourcesTable(estimate) {
  const flattenedTree = flattenCloneTree(estimate.task_tree)

  const allAllocations = flatten(flattenedTree.map((node) => node.resources))

  const aggregateAllocations = aggregateResources(allAllocations)

  const allocationsByResource = groupBy(aggregateAllocations, "resource_id")

  return estimate.resources.map((resource) => {
    const allocations = allocationsByResource[resource.id] ?? [{ unitary_cost: 0, unitary_price: 0, size: 0, cost_unit: ""}]
    return {
      resource_id: resource.id,
      name: resource.name,
      total_cost: sum(
        allocations
          .map((r) => parseFloat(r.unitary_cost) * parseFloat(r.size))
          .filter((n) => !isNaN(n))
      ),
      total_price: sum(
        allocations
          .map((r) => parseFloat(r.unitary_price) * parseFloat(r.size))
          .filter((n) => !isNaN(n))
      ),
      details: allocations.map((r) =>
        pick(r, "cost_unit", "size", "unitary_cost", "unitary_price")
      ),
      description: resource.description,
      resource_type: resource.resource_type,
    }
  })
}

export function cutTreeAtDepth(rootedTree, depth) {
  if (depth === 1) {
    return {
      ...rootedTree,
      children: [],
    }
  } else {
    return {
      ...rootedTree,
      children: rootedTree.children?.map((child) =>
        cutTreeAtDepth(child, depth - 1)
      ),
    }
  }
}

export function aggregateResources(resourcesList) {
  const groupedResources = groupBy(resourcesList, "priced_resource_id")

  return Object.values(groupedResources).map((group) => {
    return {
      priced_resource_id: group[0].priced_resource_id,
      resource_id: group[0].resource_id,
      cost_unit: group[0].cost_unit,
      unitary_cost: group[0].unitary_cost,
      unitary_price: group[0].unitary_price,
      size: group.reduce((acc, elem) => acc + parseFloat(elem.size), 0),
    }
  })
}

export function flattenTree(rootedTree) {
  return flatten([
    rootedTree,
    ...rootedTree.children.map((child) => flattenTree(child)),
  ])
}

export function compressTreeAtDepth(rootedTree, depth) {
  if (depth === 1) {
    const flatTree = flattenTree(rootedTree)
    return {
      ...rootedTree,
      resources: aggregateResources(
        flatten(flatTree.map((node) => node.resources))
      ),
      fixed_costs: flatten(flatTree.map((node) => node.fixed_costs)),
      children: [],
      own_cost: rootedTree.cost,
      own_price: rootedTree.price,
    }
  } else {
    return {
      ...rootedTree,
      resources: aggregateResources(rootedTree.resources),
      children: rootedTree.children?.map((child) =>
        compressTreeAtDepth(child, depth - 1)
      ),
    }
  }
}

export function getTotalFixedCost(rootedTree) {
  const flatTree = flattenTree(rootedTree)
  const fixedCosts = flatten(flatTree.map((node) => node.fixed_costs ?? []))
  return fixedCosts.reduce((acc, curr) => acc + parseFloat(curr.price), 0)
}

export function countNodes(rootedTree) {
  return 1 + sum(rootedTree.children.map((child) => countNodes(child)))
}

export function getDepth(tree) {
  if (Array.isArray(tree)) {
    return getDepth({ children: tree }) - 1
  }
  return 1 + Math.max(0, ...tree.children.map((child) => getDepth(child)))
}

export function sliceExtent(tree, extent) {
  if (!extent) {
    return tree
  }
  const lower = parseInt(extent[0])
  const upper = parseInt(extent[1])
  if (!Array.isArray(tree)) {
    return {
      ...tree,
      children: tree.children.filter((firstLevelNode) => {
        const index = parseInt(firstLevelNode.path.split(".")[0])
        return index >= lower && index <= upper
      }),
    }
  }
  return tree.filter((firstLevelNode) => {
    const index = parseInt(firstLevelNode.path.split(".")[0])
    return index >= lower && index <= upper
  })
}

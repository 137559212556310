import { rj, useRunRj } from "react-rocketjump"
import rjPlainList from "react-rocketjump/plugins/plainList"
import api from "../api"

const priceListsRj = rj(rjPlainList(), {
  effectCaller: rj.configured(),
  effect: (wpAuth) => () => api.auth(wpAuth).get(`/api/price-list/`),
  mutations: {
    create: rj.mutation.single({
      effect: (wpAuth) => (priceListData) => {
        return api.auth(wpAuth).post(`/api/price-list/`, priceListData)
      },
      updater: "insertItem",
    }),
    update: rj.mutation.single({
      effect: (wpAuth) => (id, priceListData) => {
        return api.auth(wpAuth).patch(`/api/price-list/${id}/`, priceListData)
      },
      updater: "updateItem",
    }),
  },
})

export function usePriceLists() {
  return useRunRj(priceListsRj)
}

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react"
import { rj, useRj } from "react-rocketjump"
import api from "../api"
import useCurrentOrganization from "./useCurrentOrganization"

const QuotaState = rj()
  .computed({
    rawQuota: "getData",
    peding: "isPending",
  })
  .effect({
    name: "Quota",
    effectCaller: rj.configured(),
    takeEffect: "exhaust",
    effect: (wpAuth) => () => api.auth(wpAuth).get(`/api/quota`),
  })

const QuotaContext = createContext(null)

export function QuotaProvider({ children }) {
  const [{ rawQuota, peding }, { run: refreshQuota, clean }] = useRj(QuotaState)
  const quota = peding ? null : rawQuota

  const org = useCurrentOrganization()
  const prevOrg = useRef(null)
  useEffect(() => {
    if (prevOrg.current !== null && prevOrg.current !== org?.id) {
      clean()
    }
    prevOrg.current = org?.id ?? null
  }, [clean, org])

  const ctx = useMemo(() => [quota, { refreshQuota }], [quota, refreshQuota])

  return <QuotaContext.Provider value={ctx}>{children}</QuotaContext.Provider>
}

export function useQuota({ checkQuota } = {}) {
  const [quota, actions] = useContext(QuotaContext)
  const { refreshQuota } = actions

  useEffect(() => {
    if (checkQuota === true) {
      refreshQuota()
    }
  }, [checkQuota, refreshQuota])

  return [quota, actions]
}

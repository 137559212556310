import { rj, useRunRj } from "react-rocketjump"
import memoize from "memoize-one"
import api from "../api"

export const CountriesState = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => () => api.auth(wpAuth).get("/api/utils/countries/"),
  selectors: ({ getData }) => {
    const memoGetAsOptions = memoize((data) =>
      data.map((datum) => ({
        value: datum.code,
        label: datum.name,
      }))
    )
    const empty = []
    return {
      getDataAsOptions: (state) => memoGetAsOptions(getData(state) ?? empty),
    }
  },
  computed: {
    countiresOptions: "getDataAsOptions",
  },
})

export function useCountires() {
  return useRunRj(CountriesState)
}

import classNames from "classnames"
import Icon from "./Icon"

export default function ButtonFavorite({ active, onChange, className, positiveTooltip, negativeTooltip }) {
  return (
    <>
      {active && (
        <span
          className="pointer"
          onClick={() => {
            onChange(false)
          }}
        >
          <Icon name="star-filled" title={negativeTooltip} className={classNames("pointer", className)} />
        </span>
      )}
      {!active && (
        <span
          className="pointer"
          onClick={() => {
            onChange(true)
          }}
        >
          <Icon name="star" title={positiveTooltip} className={classNames("pointer", className)} />
        </span>
      )}
    </>
  )
}

import { faAlignCenter, faAlignLeft, faAlignRight, faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { ContentState, convertFromRaw, convertToRaw, EditorState, Modifier } from "draft-js"
import { Field, Formik } from "formik"
import { isEqual } from "lodash"
import useDebounceCallback from "magik-react-hooks/useDebounceCallback"
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import { useDropzone } from "react-dropzone"
import { useTranslation } from "react-i18next"
import Button from "../Button"
import FieldInput from "../fields/FieldInput"
import WpModal from "../WpModal/WpModal"
import useModalTrigger from "../../hooks/useModalTrigger"
import { useAttachmentUploader } from "../../hooks/attachment"
import S from "./RichTextEditor.module.scss"
import LinkDecorator from "./linkDecorator"
import TemplatingDecorator from "./templatingDecorator"
import { usePager } from "../../pages/Estimate/EstimateDocument/Pager"
import ColorPickerPopover from "../ColorPickerPopover/ColorPickerPopover"
import { Spinner } from "reactstrap"
import TemplatingPopover from "../TemplatingPopover"

const ResizeModalContext = React.createContext(null)

export const RichTextEditorContext = React.createContext({})

function isSizeValid(size) {
  return size === "auto" || parseInt(size, 10).toString() === size
}

const FormikDropzone = ({ url, setFieldValue }) => {
  const lastUrlRef = useRef(url)

  const onDrop = useCallback(
    (acceptedFiles) => {
      const image = acceptedFiles[0]
      const url = URL.createObjectURL(image)
      setFieldValue("image", image)
      setFieldValue("url", url)
    },
    [setFieldValue]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [".jpg", ".jpeg", ".png"] },
  })

  useEffect(() => {
    if (lastUrlRef.current) {
      URL.revokeObjectURL(lastUrlRef.current)
    }
    lastUrlRef.current = url
  }, [url])

  return (
    <div {...getRootProps()} className={S["dropzone"]}>
      <input {...getInputProps()} />
      {isDragActive && (
        <div>
          <p>Drop the files here ...</p>
        </div>
      )}
      {!isDragActive && !url && (
        <div>
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
      )}
      {url && <img src={url} alt="" />}
    </div>
  )
}

const ImageUploadModal = ({ config, onChange }) => {
  const { t } = useTranslation(["translation", "action"])

  const [isOpen, setOpen] = useState(false)

  const { uploading, upload: uploadContent } = useAttachmentUploader()

  const onExpandEvent = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(true)
  }, [])

  return (
    <div
      aria-haspopup="true"
      aria-expanded={isOpen}
      aria-label="rdw-color-picker"
      className="d-flex flex-row align-items-center"
      style={{ marginBottom: 6 }}
    >
      <div className="rdw-option-wrapper" onMouseDown={onExpandEvent}>
        <img src={config.icon} alt="" />
      </div>
      <WpModal isOpen={isOpen} title={t("rich_text_editor.add_image")}>
        <Formik
          initialValues={{
            width: "auto",
            height: "auto",
            caption: "",
            image: null,
            url: null,
          }}
          onSubmit={(values, actions) => {
            uploadContent
              .onSuccess((data) => {
                const w = values.width === "auto" ? values.width : values.width + "px"
                const h = values.height === "auto" ? values.height : values.height + "px"
                onChange(data.file, h, w, values.caption)
                setOpen(false)
              })
              .run(values.image)
          }}
        >
          {(formik) => (
            <>
              <div className="d-flex flex-row justify-content-start align-items-stretch">
                <div style={{ flex: "0 0 40%" }} className="p-3">
                  <form>
                    <Field name="width" label="Width" component={FieldInput} className="mb-4" />
                    <Field name="height" label="Height" component={FieldInput} className="mb-4" />
                  </form>
                </div>
                <div style={{ flex: "0 0 60%" }} className="p-3">
                  <FormikDropzone
                    setFieldValue={formik.setFieldValue}
                    url={formik.values.url}
                    image={formik.values.image}
                  />
                </div>
              </div>
              <div className="p-3">
                <Field name="caption" label="Caption" component={FieldInput} />
              </div>
              <div className="d-flex flex-row justify-content-end align-items-center">
                <Button
                  size="sm"
                  color="none"
                  onClick={() => {
                    formik.resetForm()
                    setOpen(false)
                  }}
                >
                  {t("action:cancel")}
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  type="submit"
                  className="ml-5"
                  disabled={
                    !formik.values.image || !isSizeValid(formik.values.width) || !isSizeValid(formik.values.height)
                  }
                  onClick={formik.handleSubmit}
                >
                  {uploading && <Spinner size="sm" className="mr-1" />}
                  {t("action:save")}
                </Button>
              </div>
            </>
          )}
        </Formik>
      </WpModal>
    </div>
  )
}

const ResizeModal = ({ width, height, caption, isOpen, onClosed, onSubmit, onCancel }) => {
  const { t } = useTranslation(["translation", "action"])

  return (
    <WpModal isOpen={isOpen} title={t("rich_text_editor.resize_image")} onClosed={onClosed}>
      <Formik
        initialValues={{
          width,
          height,
          caption,
        }}
        onSubmit={(values, actions) => {
          onSubmit(values)
        }}
      >
        {(formik) => (
          <>
            <form>
              <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                <Field name="width" label="Width" component={FieldInput} className="mb-4" />
                <Field name="height" label="Height" component={FieldInput} className="mb-4" />
              </div>
              <div className="mb-3">
                <Field name="caption" label="Caption" component={FieldInput} />
              </div>
            </form>
            <div className="d-flex flex-row justify-content-end align-items-center">
              <Button
                size="sm"
                color="none"
                onClick={() => {
                  onCancel()
                }}
              >
                {t("action:cancel")}
              </Button>
              <Button
                size="sm"
                color="primary"
                type="submit"
                className="ml-5"
                disabled={!isSizeValid(formik.values.width) || !isSizeValid(formik.values.height)}
                onClick={formik.handleSubmit}
              >
                {t("action:save")}
              </Button>
            </div>
          </>
        )}
      </Formik>
    </WpModal>
  )
}

const EditLinkModal = ({ config, currentState, onChange }) => {
  const { t } = useTranslation(["translation", "action"])

  const [isOpen, setOpen] = useState(false)

  return (
    <div
      aria-haspopup="true"
      aria-expanded={isOpen}
      aria-label="rdw-link-editor"
      className="d-flex flex-row align-items-center"
      style={{ marginBottom: 6 }}
    >
      <div className="rdw-option-wrapper" onMouseDown={() => setOpen(true)}>
        <img src={config.link.icon} alt="" />
      </div>
      <div
        className={classNames("rdw-option-wrapper", {
          "rdw-option-disabled": !currentState.link,
        })}
        onMouseDown={() => {
          if (currentState.link) {
            onChange("unlink")
          }
        }}
      >
        <img src={config.unlink.icon} alt="" />
      </div>
      <WpModal
        isOpen={isOpen}
        title={currentState?.link ? t("rich_text_editor.edit_link") : t("rich_text_editor.add_link")}
      >
        <Formik
          initialValues={{
            linkText: currentState?.link?.title ?? currentState?.selectionText ?? "",
            linkUrl: currentState?.link?.target ?? "",
          }}
          onSubmit={(values, actions) => {
            onChange("link", values.linkText, values.linkUrl, {})
            actions.setSubmitting(false)
            setOpen(false)
          }}
          enableReinitialize={true}
        >
          {(formik) => (
            <>
              <form>
                <div className="d-flex flex-column justify-content-start align-items-stretch mb-3">
                  <Field name="linkText" label="Link caption" component={FieldInput} className="mb-4" />
                  <Field name="linkUrl" label="Link target url" component={FieldInput} className="mb-4" />
                </div>
              </form>
              <div className="d-flex flex-row justify-content-end align-items-center">
                <Button
                  size="sm"
                  color="none"
                  onClick={() => {
                    formik.resetForm()
                    setOpen(false)
                  }}
                >
                  {t("action:cancel")}
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  type="submit"
                  className="ml-5"
                  disabled={formik.values.linkText.length === 0 || formik.values.linkUrl.length === 0}
                  onClick={formik.handleSubmit}
                >
                  {t("action:save")}
                </Button>
              </div>
            </>
          )}
        </Formik>
      </WpModal>
    </div>
  )
}

const CustomImageComponent = ({ block, contentState, config }) => {
  const [, setDummyState] = useState(0)
  const entity = contentState.getEntity(block.getEntityAt(0))
  const { src, alignment, height, width, alt: caption } = entity.getData()
  const alignmentClass = (() => {
    switch (alignment) {
      case "left":
        return S["image-left"]
      case "right":
        return S["image-right"]
      default:
        return S["image-center"]
    }
  })()

  const forceRender = useCallback(() => {
    setDummyState((v) => (v === 0 ? 1 : 0))
  }, [])

  const setAlignment = useCallback(
    (alignment) => {
      const entityKey = block.getEntityAt(0)
      const nextContentState = contentState.mergeEntityData(entityKey, {
        alignment,
      })
      config.onChange(EditorState.push(config.getEditorState(), nextContentState, "change-block-data"))
      forceRender()
    },
    [block, config, contentState, forceRender]
  )

  const dropImage = useCallback(() => {
    /** @type {ContentState} */
    const currentState = contentState
    const blockArray = currentState.getBlocksAsArray()
    const nextBlockArray = blockArray.filter((item) => item.getKey() !== block.getKey())
    const nextContentState = ContentState.createFromBlockArray(nextBlockArray)
    config.onChange(EditorState.push(config.getEditorState(), nextContentState, "delete-character"))
  }, [block, config, contentState])

  const handleImageMetaEdit = useCallback(
    (width, height, caption) => {
      const entityKey = block.getEntityAt(0)
      const nextContentState = contentState.mergeEntityData(entityKey, {
        width: width === "auto" ? width : width + "px",
        height: height === "auto" ? height : height + "px",
        alt: caption,
      })
      config.onChange(EditorState.push(config.getEditorState(), nextContentState, "change-block-data"))
      forceRender()
    },
    [block, config, contentState, forceRender]
  )

  const resizeModalActions = useContext(ResizeModalContext)
  const { readOnly } = useContext(RichTextEditorContext)

  const { requestPagination } = usePager()

  return (
    <div className={classNames(S["image-align"], alignmentClass)}>
      <div className={S["image-wrapper"]}>
        <div className={S["image-positioner"]}>
          <img src={src} alt={caption} style={{ width, height }} onLoad={() => requestPagination()} />
          {!readOnly && (
            <div className={S["image-tools"]}>
              <div className={S["image-tools-row"]}>
                <span
                  className={S["image-tools-action"]}
                  onMouseDown={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setAlignment("left")
                  }}
                >
                  <FontAwesomeIcon icon={faAlignLeft} />
                </span>
                <span
                  className={S["image-tools-action"]}
                  onMouseDown={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setAlignment("none")
                  }}
                >
                  <FontAwesomeIcon icon={faAlignCenter} />
                </span>
                <span
                  className={S["image-tools-action"]}
                  onMouseDown={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setAlignment("right")
                  }}
                >
                  <FontAwesomeIcon icon={faAlignRight} />
                </span>
              </div>
              <div className={S["image-tools-row"]}>
                <span
                  className={S["image-tools-action"]}
                  onMouseDown={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    resizeModalActions.open({
                      width: width.replace("px", ""),
                      height: height.replace("px", ""),
                      caption,
                      callback: handleImageMetaEdit,
                    })
                  }}
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </span>
                <span
                  className={S["image-tools-action"]}
                  onMouseDown={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    dropImage()
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
                </span>
              </div>
            </div>
          )}
        </div>
        {caption && (
          <small>
            <i>{caption}</i>
          </small>
        )}
      </div>
    </div>
  )
}

function ColorPickerModal({ config, currentState, onChange }) {
  return (
    <ColorPickerPopover
      size="richtext"
      value={currentState?.color || "#000000"}
      onChange={(hex) => {
        onChange("color", hex)
      }}
    />
  )
}

const customBlockRenderFunc = (block, config, getEditorState) => {
  if (block.getType() === "atomic") {
    const contentState = getEditorState().getCurrentContent()
    const entity = contentState.getEntity(block.getEntityAt(0))
    if (entity && entity.type === "IMAGE") {
      return {
        component: (props) => <CustomImageComponent config={config} {...props} />,
        editable: false,
      }
    }
  }
  return undefined
}

function InsertTemplateTokenButton({ editorState, onChange, templatingContext }) {
  return (
    <TemplatingPopover
      left={false}
      context={templatingContext}
      onSelect={(expr, e) => {
        e.preventDefault()
        const currentContent = editorState.getCurrentContent(),
          currentSelection = editorState.getSelection()

        const newContent = Modifier.replaceText(currentContent, currentSelection, `{{${expr}}}`)

        const newEditorState = EditorState.push(editorState, newContent, "insert-characters")
        const commitState = EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())
        onChange(commitState)
      }}
    />
  )
}

export default function RichTextEditor({
  initialContent,
  content = undefined,
  save,
  readOnly = false,
  placeholder = undefined,
  templatingContext = {},
  editorClassName = undefined,
  wrapperClassName = undefined,
  enableInlineFormatting = true,
  enableBlockFormatting = true,
  enableFontSize = true,
  enableLists = true,
  enableTextAlign = true,
  enableLinks = true,
  enableImages = true,
  enableUndoRedo = true,
  minHeight0 = false,
  enableColorPicker = true,
}) {
  const initialEditorState = initialContent
    ? EditorState.createWithContent(convertFromRaw(initialContent))
    : EditorState.createEmpty()
  const [state, setState] = useState(initialEditorState)
  const lastSaveArg = useRef(null)

  const { upload: uploadContent } = useAttachmentUploader()

  const performSave = useDebounceCallback(
    (arg) => {
      lastSaveArg.current = arg
      save(arg)
    },
    500,
    [save]
  )

  const toolbar = useMemo(() => {
    return {
      options: [
        enableInlineFormatting ? "inline" : "",
        enableBlockFormatting ? "blockType" : "",
        enableFontSize ? "fontSize" : "",
        // "fontFamily",
        enableLists ? "list" : "",
        enableTextAlign ? "textAlign" : "",
        enableColorPicker ? "colorPicker" : "",
        enableLinks ? "link" : "",
        enableImages ? "image" : "",
        enableUndoRedo ? "history" : "",
      ].filter((opt) => opt !== ""),
      colorPicker: {
        component: ColorPickerModal,
      },
      image: {
        component: ImageUploadModal,
        popupClassName: undefined,
        urlEnabled: false,
        uploadEnabled: true,
        alignmentEnabled: true,
        uploadCallback: (file) => {
          return uploadContent.asPromise(file).then((result) => ({
            data: {
              link: result.file,
            },
          }))
        },
        previewImage: true,
        inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
        alt: { present: true, mandatory: false },
        defaultSize: {
          height: "auto",
          width: "auto",
        },
      },
      link: {
        component: EditLinkModal,
        showOpenOptionOnHover: false,
      },
    }
  }, [
    enableBlockFormatting,
    enableColorPicker,
    enableFontSize,
    enableImages,
    enableInlineFormatting,
    enableLinks,
    enableLists,
    enableTextAlign,
    enableUndoRedo,
    uploadContent,
  ])

  const [resizeModal, resizeModalActions] = useModalTrigger()

  const { t } = useTranslation(["translation"])

  const richTextEditorCtx = useMemo(
    () => ({
      templatingContext: templatingContext,
      readOnly: readOnly,
    }),
    [readOnly, templatingContext]
  )

  let controlledState = undefined
  if (content) {
    try {
      controlledState = EditorState.createWithContent(convertFromRaw(content))
    } catch (e) {
      controlledState = EditorState.createEmpty()
    }
  }

  return (
    <ResizeModalContext.Provider value={resizeModalActions}>
      <RichTextEditorContext.Provider value={richTextEditorCtx}>
        <Editor
          readOnly={readOnly}
          toolbarHidden={readOnly}
          toolbar={toolbar}
          toolbarCustomButtons={[<InsertTemplateTokenButton templatingContext={templatingContext} />]}
          customBlockRenderFunc={customBlockRenderFunc}
          customDecorators={[LinkDecorator, TemplatingDecorator]}
          editorState={controlledState || state}
          placeholder={placeholder ?? t("rich_text_placeholder")}
          wrapperClassName={classNames(S["frag-text-wrapper"], wrapperClassName)}
          editorClassName={classNames(minHeight0 ? null : S["frag-text-editor"], editorClassName)}
          onEditorStateChange={(nextState) => {
            setState(nextState)
            const content = convertToRaw(nextState.getCurrentContent())
            if (!isEqual(content, lastSaveArg.current)) {
              performSave(content)
            }
          }}
        />
        {resizeModal.value && (
          <ResizeModal
            width={resizeModal.value.width}
            height={resizeModal.value.height}
            caption={resizeModal.value.caption}
            isOpen={resizeModal.isOpen}
            onCancel={resizeModalActions.close}
            onSubmit={({ width, height, caption }) => {
              resizeModal.value.callback(width, height, caption)
              resizeModalActions.close()
            }}
          />
        )}
      </RichTextEditorContext.Provider>
    </ResizeModalContext.Provider>
  )
}

import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useAuthActions, useAuthState } from "use-eazy-auth"
import { ReactComponent as Artwork } from "../../assets/img/login-artwork.svg"
import logo from "../../assets/logo@2x.png"
import Button from "../../components/Button"
import GoogleAuthButton from "../../components/GoogleAuthButton"
import InlineLangSelector from "../../components/InlineLangSelector"
import MicrosoftAuthButton from "../../components/MicrosoftAuthButton"
import PasswordShowHide from "../../components/PasswordShowHide"
import WpInput from "../../components/WpInput"
import S from "./Login.module.scss"

export default function NewAccount({ changeMode, token, emailInitial, tokenData: data }) {
  const { t, i18n } = useTranslation()

  const { loginLoading, loginError } = useAuthState()
  const { login, clearLoginError } = useAuthActions()

  const [email, setEmail] = useState(emailInitial)
  const [password, setPassword] = useState("")

  useEffect(() => {
    return () => clearLoginError()
  }, [clearLoginError])

  function handleSubmit(e) {
    e.preventDefault()
    if (!loginLoading) {
      login({
        provider: "password",
        email: data?.email ?? email,
        password,
        joinToken: token,
      })
    }
  }

  return (
    <div className="flex-1 d-flex flex-row justify-content-start align-items-stretch">
      <div className={S["artwork-container"]}>
        <div className={S["intermediate"]}>
          <Artwork
            preserveAspectRatio="xMinYMin slice"
            width="100%"
            height="100%"
          />
        </div>
      </div>
      <div className={S["main-container"]}>
        <div className={S["top"]}>
          <img src={logo} className={S["logo"]} alt="" />
          <div className={S["tools"]}>
            <span>
              {t("login.not_a_member")}{" "}
              <span className={S.a} role="link" onClick={() => changeMode()}>
                {t("login.sign_up")}
              </span>
            </span>
            <span className="pl-8" />
            <InlineLangSelector />
          </div>
        </div>
        <div className={S["form"]}>
          <div>
            <h1>{t("login.sign_in")}</h1>
            <p className="mb-8">{t("login.welcome_back")}</p>
            {!!token && (
              <p className="mb-8" style={{ width: 350 }}>
                {t("login.join_org", { org: data?.organization?.name ?? "" })}
                <br />
                <a href="/">{t("login.dismiss_invitation")}</a>
              </p>
            )}
            <form onSubmit={handleSubmit} style={{ width: 350 }}>
              <div className="d-flex flex-column">
                <label htmlFor="email">{t("login.email")}</label>
                <WpInput
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border-primary"
                  disabled={!!token}
                />
                <p
                  className={classNames("text-danger mt-2 mb-0 line-height-1", {
                    [S["hidden"]]: !loginError,
                  })}
                >
                  {t("login.credentials_error") || "placeholder"}
                </p>
              </div>
              <div className="d-flex flex-column mt-3">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <label htmlFor="password">{t("login.password")}</label>
                  <Link to="/recoverpasswd">{t("login.forgot_password")}</Link>
                </div>
                <PasswordShowHide
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border-primary w-100"
                />
                <p
                  className={classNames("text-danger mt-2 mb-0 line-height-1", {
                    [S["hidden"]]: !loginError,
                  })}
                >
                  {t("login.credentials_error") || "placeholder"}
                </p>
              </div>
              <div className="mt-8">
                <Button className="w-100" type="submit">
                  {t("login.sign_in")}
                </Button>
              </div>
            </form>
            <div className="d-flex flex-row justify-content-start align-items-center mt-8">
              <div className="flex-1 border-bottom border-separator" />
              <div className="mx-4">
                <span>{t("login.or_continue_with")}</span>
              </div>
              <div className="flex-1 border-bottom border-separator" />
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center mt-8">
              <GoogleAuthButton joinToken={token} className="flex-1" />
              <div className="pl-4" />
              <MicrosoftAuthButton joinToken={token} className="flex-1" />
            </div>
          </div>
        </div>
        <div className={S["bottom"]}>
          <span>
            <a
              href={`https://wooplan.com/${i18n.language}/content/terms`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("login.link_terms_of_service")}
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

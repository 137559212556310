import React, { useMemo } from "react"
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom"
import Loader from "../../components/Loader/Loader"
import NotFound from "../../components/NotFound"
import { ProjectContext } from "../../context"
import { CAP, useCapabilities } from "../../hooks/capabilities"
import { useProject } from "../../hooks/project"
import { AuditTrail } from "./AuditTrail"
import { ComplianceIssueReport } from "./ComplianceIssueReport"
import { ComplianceIssueReportList } from "./ComplianceIssueReportList"
import ProjectActivity from "./ProjectActivity"
import { Bills } from "./ProjectBills"
import { ProjectDeadlines } from "./ProjectDeadlines"
import { ProjectFlow } from "./ProjectFlow"
import { ProjectGantt } from "./ProjectGantt"
import ProjectInfo from "./ProjectInfo"
import ProjectInfoStatic from "./ProjectInfoStatic"
import ProjectInsight from "./ProjectInsight"
import { ProjectInteractions } from "./ProjectInteractions"
import ProjectTasks from "./ProjectTasks"
import ProjectTeam from "./ProjectTeam"

export default function Project() {
  const { path } = useRouteMatch()
  const { id } = useParams()

  const [projectState, projectActions] = useProject(id)
  const { project } = projectState

  const [, { hasCapability }] = useCapabilities()

  // Estimate shared ctx
  const projectContext = useMemo(
    () => [
      {
        ...projectState,
      },
      {
        ...projectActions,
      },
    ],
    [projectActions, projectState]
  )

  if (project === null) {
    return <Loader />
  }

  return (
    <ProjectContext.Provider value={projectContext}>
      <Switch>
        <Route path={`${path}`} exact component={ProjectInfoStatic} />
        <Route path={`${path}/edit`} exact component={ProjectInfo} />
        <Route path={`${path}/audit-trail`} exact component={AuditTrail} />
        {hasCapability(CAP.TASKS) && <Route path={`${path}/tasks`} component={ProjectTasks} />}
        {hasCapability(CAP.TASKS) && <Route path={`${path}/gantt`} component={ProjectGantt} />}
        {hasCapability(CAP.ACTIVITIES) && <Route path={`${path}/activity`} component={ProjectActivity} />}
        {hasCapability(CAP.TEAM) && <Route path={`${path}/team`} component={ProjectTeam} />}
        {hasCapability(CAP.PROJECT_INDICATORS) && <Route path={`${path}/insight`} component={ProjectInsight} />}
        {hasCapability(CAP.INTERACTIONS) && <Route path={`${path}/interactions`} component={ProjectInteractions} />}
        {hasCapability(CAP.DEADLINES) && <Route path={`${path}/deadlines`} component={ProjectDeadlines} />}
        {hasCapability(CAP.BILLS) && <Route path={`${path}/bills`} component={Bills} />}
        {hasCapability(CAP.REQUESTS) && hasCapability(CAP.ESTIMATES) && <Route path={`${path}/flow`} component={ProjectFlow} />}
        {hasCapability(CAP.NC_REPORT) && (
          <Route path={`${path}/compliance-issue-report`} exact component={ComplianceIssueReportList} />
        )}
        {hasCapability(CAP.NC_REPORT) && (
          <Route path={`${path}/compliance-issue-report/:reportId`} exact component={ComplianceIssueReport} />
        )}
        <Route component={NotFound} />
      </Switch>
    </ProjectContext.Provider>
  )
}

import classNames from "classnames"
import dayjs from "dayjs"
import { truncate } from "lodash"
import { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import Button from "../../../components/Button"
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal"
import { EstimateLandmarks } from "../../../components/EstimateLandmarks"
import Icon from "../../../components/Icon"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import LongBorder from "../../../components/LongBorder"
import Table from "../../../components/Table"
import { CurrentEstimateStateContext } from "../../../context"
import useModalTrigger from "../../../hooks/useModalTrigger"
import EstimateBottomBarContent from "../EstimateBottomBarContent"
import EstimateTabBarContent from "../EstimateTabBarContent"
import { EditBillingTrancheModal } from "./EditBillingTrancheModal"

export function BillingTranches() {
  const { t } = useTranslation(["translation", "tab", "action"])

  const [
    { estimate },
    {
      createBillingTranche: create,
      updateBillingTranche: update,
      removeBillingTranche: remove,
    },
  ] = useContext(CurrentEstimateStateContext)

  const [editModalState, editModalActions] = useModalTrigger()
  const [removeModalState, removeModalActions] = useModalTrigger()

  const columns = useMemo(() => {
    return [
      {
        label: t("field:billing_tranche.date"),
        name: "date",
        size: 2,
        render: (selectedDatum, index, datum) => {
          return <span>{dayjs(selectedDatum).format("DD/MM/YYYY")}</span>
        },
      },
      {
        label: t("field:billing_tranche.amount"),
        name: "amount",
        size: 2,
        render: (selectedDatum, index, datum) => {
          if (datum.value_mode === "percent") {
            const f = parseFloat(datum.amount_percent)
            if (!isNaN(f)) {
              return <span>{f.toFixed(2) + " %"}</span>
            } else {
              return <span />
            }
          } else {
            const f = parseFloat(datum.amount_fixed)
            if (!isNaN(f)) {
              return <span>{f.toFixed(2) + " €"}</span>
            } else {
              return <span />
            }
          }
        },
      },
      {
        label: t("field:billing_tranche.description"),
        name: "description",
        size: 7,
      },
      {
        label: t("actions"),
        name: "actions",
        size: 1,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <div className={"action-icon-container-primary-hover"}>
                <Icon
                  name="edit"
                  title={t("action:edit_billing_tranche")}
                  className="pointer text-dark"
                  onClick={() => {
                    editModalActions.open(datum)
                  }}
                />
              </div>
              <div className={"action-icon-container-primary-hover"}>
                <UncontrolledDropdown>
                  <DropdownToggle caret={false} tag={"span"}>
                    <Icon
                      role="button"
                      name="vdots"
                      className="text-dark pointer"
                      title={t("action:other_options")}
                      placement="right"
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    right
                    modifiers={{ offset: { offset: "0, 12" } }}
                    className="border-primary"
                  >
                    {!datum.archived && (
                      <DropdownItem
                        className={classNames(
                          "text-capitalize px-0",
                          "dropdown-item-primary-active"
                        )}
                      >
                        <div
                          className="d-flex flex-row align-items-center mx-4"
                          onClick={() => {
                            removeModalActions.open(datum)
                          }}
                        >
                          <Icon
                            name="delete"
                            className={classNames("pointer mr-4")}
                          />
                          <span>{t("action:delete")}</span>
                        </div>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )
        },
      },
    ]
  }, [editModalActions, removeModalActions, t])

  return (
    <Layout>
      <div className="container-fluid pt-5 px-page pb-page-bottom">
        <div className="d-flex flex-row justify-content-between align-items-center mb-6">
          <h1 className="text-primary">{t("tab:estimate.billing")}</h1>
          <div>
            <Button
              className="d-inline-flex align-items-center px-5 mr-5"
              onClick={() => {
                editModalActions.open({ estimate: estimate.id })
              }}
            >
              <Icon
                name="plus-rounded"
                className="mr-2"
                height={16}
                width={16}
              />
              {t("action:new_billing_tranche")}
            </Button>
          </div>
        </div>

        {!estimate && <Loader />}
        {estimate && (
          <>
            <LongBorder topBar="tabbar" />
            <Table
              data={estimate.billing_tranches}
              columns={columns}
              stickyHeader
              stickyTopBar="tabbar"
              className="pb-8"
              valueCheckFn={(val) => val !== undefined && val !== null}
            />
          </>
        )}
        {editModalState.value && (
          <EditBillingTrancheModal
            isOpen={editModalState.isOpen}
            tranche={editModalState.value}
            onClosed={editModalActions.onClose}
            toggle={editModalActions.toggle}
            onSave={(result) => {
              if (result.id) {
                return update
                  .onSuccess(() => {
                    editModalActions.close()
                  })
                  .asPromise(result.id, result)
              } else {
                return create
                  .onSuccess(() => {
                    editModalActions.close()
                  })
                  .asPromise(result)
              }
            }}
          />
        )}
        {removeModalState.value && (
          <ConfirmDeleteModal
            isOpen={removeModalState.isOpen}
            toggle={removeModalActions.toggle}
            onClosed={removeModalActions.onClose}
            item={truncate(removeModalState.value.description, 20)}
            onConfirm={() => {
              return remove.asPromise(removeModalState.value.id)
            }}
          />
        )}
      </div>
      <Layout.TabBar>
        <EstimateTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <EstimateBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <EstimateLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

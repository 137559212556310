import { useTranslation } from "react-i18next"
import { BsCalendar2Check, BsPeople, BsQuestionDiamond } from "react-icons/bs"
import { MdOutlineDashboard } from "react-icons/md"
import { VscDashboard } from "react-icons/vsc"
import { CAP, useCapabilities } from "../hooks/capabilities"
import { useLinkToBusinessMonitor } from "../hooks/links"
import Layout from "./Layout"

export function CRMLandmarks() {
  const { t } = useTranslation()
  const [, { hasCapability }] = useCapabilities()
  const businessMonitorLink = useLinkToBusinessMonitor()

  return (
    <>
      {hasCapability(CAP.CUSTOMER) && (
        <>
          <Layout.FirstLevelNavi.Tab text={t("navbar.customers")} svgIcon={<BsPeople size={20} />} to={"/customers"} />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
      {hasCapability(CAP.OVERVIEW) && (
        <>
          <Layout.FirstLevelNavi.Tab
            text={t("navbar.crm_overview")}
            svgIcon={<MdOutlineDashboard size={20} />}
            to={"/overview"}
          />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
      {hasCapability(CAP.BUSINESS) && (
        <>
          <Layout.FirstLevelNavi.Tab
            text={t("navbar.business_monitoring")}
            svgIcon={<VscDashboard size={20} />}
            to={businessMonitorLink}
          />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
      {hasCapability(CAP.INTERACTIONS) && (
        <>
          <Layout.FirstLevelNavi.Tab
            text={t("navbar.business_interactions")}
            svgIcon={<BsCalendar2Check style={{ width: 18 }} />}
            to={"/businessinteractions"}
          />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
      {hasCapability(CAP.REQUESTS) && (
        <>
          <Layout.FirstLevelNavi.Tab
            text={t("navbar.requests")}
            svgIcon={<BsQuestionDiamond style={{ width: 18 }} />}
            to={"/requests"}
          />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
    </>
  )
}

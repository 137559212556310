import React from "react"
import classNames from "classnames"
import S from "./WpTextarea.module.scss"
import AutoTextarea from "../AutoTextarea"

function WpTextarea(
  {
    className = "",
    invalid,
    auto = false,
    placeholder = undefined,
    readOnly = false,
    readOnlyPlaceholder = undefined,
    disabled,
    ...props
  },
  ref
) {
  if (auto) {
    return (
      <AutoTextarea
        ref={ref}
        disabled={disabled || readOnly}
        placeholder={readOnly ? readOnlyPlaceholder : placeholder}
        className={classNames("pl-4 py-3", className, S["wp-textarea"], {
          "invalid-input": invalid,
        })}
        {...props}
      />
    )
  }

  return (
    <textarea
      ref={ref}
      disabled={disabled || readOnly}
      placeholder={readOnly ? readOnlyPlaceholder : placeholder}
      className={classNames(S["wp-textarea"], "px-4 py-3", String(className), {
        "invalid-input": invalid,
      })}
      {...props}
    />
  )
}

export default React.forwardRef(WpTextarea)

import React from "react"
import WpModal from "../../../../components/WpModal"
import { Field, Formik } from "formik"
import FieldInput from "../../../../components/fields/FieldInput"
import Button from "../../../../components/Button"
import { useTranslation } from "react-i18next"
import { calcMargin } from "../../../../utils"
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter"

/*React renders div even if the modal is closed */
const ModalHeader = ({ resource, t }) => {
  return (
    <div className="d-flex flex-column">
      <span>{t("tasks.edit_resource_message", { title: resource.name })}</span>
      <span>{t("tasks.edit_resource_local_message")}</span>
      <div className="text-uppercase h2 pt-4 pb-7 font-weight-semibold">
        {resource.name}
      </div>
    </div>
  )
}

export default function EditResoucePricesModalForm({
  isOpen,
  resource,
  onReset,
  toggle,
  onSubmit,
  resetting,
}) {
  const { t } = useTranslation(["translation", "action", "field"])
  const currencyFmt = useCurrencyFormatter()

  return (
    <WpModal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title={t("tasks.edit_resource")}
    >
      <ModalHeader resource={resource} t={t} />
      <Formik
        enableReinitialize={true}
        onSubmit={onSubmit}
        initialValues={resource}
      >
        {({ handleSubmit, isSubmitting, values, resetForm }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="text-uppercase h3 font-weight-semibold pb-4">
              {t("tasks.cost_and_price")}
            </div>
            <div className="row no-gutters px-3">
              <div className="col-2 text-uppercase font-weigth-semibold h3">
                {t("field:task.resource.cost_unit")}
              </div>
              <div className="col-2 text-uppercase text-right font-weigth-semibold h3">
                {t("field:task.resource.base_unitary_cost")}
              </div>
              <div className="col-2 text-uppercase  text-right font-weigth-semibold h3">
                {t("field:task.resource.unitary_cost")}
              </div>
              <div className="col-2 text-uppercase text-right font-weigth-semibold h3">
                {t("field:task.resource.base_unitary_price")}
              </div>
              <div className="col-2 text-uppercase text-right font-weigth-semibold h3">
                {t("field:task.resource.unitary_price")}
              </div>
              <div className="col-2 text-uppercase text-right font-weigth-semibold h3">
                {t("field:task.resource.margin")}
              </div>
            </div>
            {values.price_list.map((pricedResource, index) => (
              <div
                key={pricedResource.id}
                className="row no-gutters border-bottom border-separator px-3 align-items-center py-3"
              >
                <div className="col-2 text-muted">
                  {pricedResource.cost_unit}
                </div>
                <div className="col-2 text-right text-muted">
                  {currencyFmt.formatNaN(pricedResource.base_unitary_cost)}
                </div>
                <div className="col-2 d-flex flex-row align-items-center">
                  <div className="flex-grow-1">
                    <Field
                      name={`price_list[${index}].unitary_cost`}
                      type="number"
                      className="text-right w-100"
                      placeholder={t("field:task.resource.unitary_cost")}
                      component={FieldInput}
                      tiny
                      clear
                    />
                  </div>
                  {currencyFmt.currencySymbol}
                </div>
                <div className="col-2 text-right text-muted">
                  {currencyFmt.format(pricedResource.base_unitary_price)}
                </div>
                <div className="col-2 d-flex flex-row align-items-center">
                  <div className="flex-grow-1">
                    <Field
                      name={`price_list[${index}].unitary_price`}
                      type="number"
                      className="text-right w-100"
                      placeholder={t("field:task.resource.unitary_price")}
                      component={FieldInput}
                      tiny
                      clear
                    />
                  </div>
                  {currencyFmt.currencySymbol}
                </div>
                <div className="col-2 text-right text-muted">
                  {calcMargin(
                    pricedResource.unitary_cost,
                    pricedResource.unitary_price
                  )}{" "}
                  {"%"}
                </div>
              </div>
            ))}
            <div className="d-flex flex-row align-items-center justify-content-between pt-5">
              <div>
                <Button
                  type="button"
                  size="lg"
                  color="none"
                  onClick={() => {
                    onReset.onSuccess(() => resetForm()).run()
                  }}
                >
                  {t("action:reset_global")}
                </Button>
              </div>
              <div>
                <Button type="button" size="lg" color="none" onClick={toggle}>
                  {t("action:cancel")}
                </Button>
                <Button
                  type="submit"
                  size="lg"
                  disabled={isSubmitting || resetting}
                  onClick={handleSubmit}
                  className="ml-5"
                >
                  {t("action:save")}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </WpModal>
  )
}

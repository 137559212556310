import { Field, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import * as yup from "yup"
import { convertErrorToForm } from "../../utils"
import Button from "../Button"
import FieldInput from "../fields/FieldInput"
import FieldTextarea from "../fields/FieldTextarea"
import WpModal from "../WpModal"

const INITIAL_VALUES = {
  name: "",
  surname: "",
  phone1: "",
  phone2: "",
  email: "",
  role: "",
}

export function CreateContactModal({ isOpen, toggle, onSave, onClosed, contact = null }) {
  const { t } = useTranslation(["translation", "field", "action"])

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(t("field:errors.mandatory", { field: t("field:contact.name") }))
      .max(512, t("field:errors.max", { max_value: 256 })),
    surname: yup
      .string()
      .required(t("field:errors.mandatory", { field: t("field:contact.surname") }))
      .max(512, t("field:errors.max", { max_value: 256 })),
    phone1: yup
      .string()
      .notRequired()
      .matches(/^\+?\d+ ?[\d ]*$/g, {
        message: t("field:errors.phone_format"),
        excludeEmptyString: true,
      }),
    phone2: yup
      .string()
      .notRequired()
      .matches(/^\+?\d+ ?[\d ]*$/g, {
        message: t("field:errors.phone_format"),
        excludeEmptyString: true,
      }),
    internal_contact: yup
      .string()
      .notRequired()
      .matches(/^[\d -]*$/g, {
        message: t("field:errors.phone_format"),
        excludeEmptyString: true,
      }),
    role: yup
      .string()
      .max(50, t("field:errors.max_length", { len: 50 }))
      .notRequired(),
    email: yup.string().notRequired().email(t("field:errors.email")),
  })

  return (
    <WpModal
      onClosed={onClosed}
      isOpen={isOpen}
      toggle={toggle}
      title={contact ? t("contact_list.edit_contact") : t("contact_list.create_contact")}
    >
      <div>
        <div className="d-flex flex-row align-items-start my-4">
          <div className="d-flex flex-column ml-2 flex-1">
            <Formik
              initialValues={contact ?? INITIAL_VALUES}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                return onSave(values).catch((error) => {
                  actions.setErrors(convertErrorToForm(error))
                })
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <>
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Field
                      name="surname"
                      component={FieldInput}
                      placeholder={t("field:contact.placeholder.surname")}
                      label={t("field:contact.surname")}
                      className={"mb-5"}
                    />
                    <Field
                      name="name"
                      component={FieldInput}
                      placeholder={t("field:contact.placeholder.name")}
                      label={t("field:contact.name")}
                      className={"mb-5"}
                    />
                    <Row>
                      <Col sm={9}>
                        <Field
                          name="phone1"
                          component={FieldInput}
                          placeholder={t("field:contact.placeholder.phone1")}
                          label={t("field:contact.phone1")}
                          className={"mb-5"}
                        />
                      </Col>
                      <Col sm={3}>
                        <Field
                          name="internal_contact"
                          component={FieldInput}
                          placeholder={t("field:contact.placeholder.internal_contact")}
                          label={t("field:contact.internal_contact")}
                          className={"mb-5"}
                        />
                      </Col>
                    </Row>
                    <Field
                      name="phone2"
                      component={FieldInput}
                      placeholder={t("field:contact.placeholder.phone2")}
                      label={t("field:contact.phone2")}
                      className={"mb-5"}
                    />
                    <Field
                      name="email"
                      component={FieldInput}
                      placeholder={t("field:contact.placeholder.email")}
                      label={t("field:contact.email")}
                      className={"mb-5"}
                      type="email"
                    />
                    <Field
                      name="role"
                      component={FieldInput}
                      placeholder={t("field:contact.placeholder.role")}
                      label={t("field:contact.role")}
                      className={"mb-5"}
                    />
                    <Field
                      name="notes"
                      component={FieldTextarea}
                      placeholder={t("field:contact.placeholder.notes")}
                      label={t("field:contact.notes")}
                      className={"mb-5"}
                      rows={4}
                    />
                    <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                      <Button size="sm" color="none" onClick={toggle}>
                        {t("action:cancel")}
                      </Button>
                      <Button size="sm" color="primary" type="submit" className="ml-5" disabled={!formik.isValid}>
                        {contact ? t("action:save") : t("action:add")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </WpModal>
  )
}

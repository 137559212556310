import { get } from "lodash"
import React, { useCallback, useContext } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import Icon from "../../../components/Icon"
import { CurrentEstimateStateContext } from "../../../context"
import useModalTrigger from "../../../hooks/useModalTrigger"
import useOpensMap from "../../../hooks/useOpensMap"
import { notifyError } from "../../../utils"
import ConfirmDeleteModalScenario from "./ConfirmDeleteModalScenario"
import ModalAddScenario from "./ModalAddScenario"
import ScenarioCard from "./ScenarioCard"

export default function EstimateScenarios() {
  const { t } = useTranslation(["translation", "action", "field"])

  const [
    { scenarios, estimate, scenarioPrices, saving },
    { patchScenario, addScenario, removeScenario },
  ] = useContext(CurrentEstimateStateContext)
  // TODO: Should live on Estimate level???????
  const [opens, toggleOpen] = useOpensMap()

  const [modalAdd, modalAddActions] = useModalTrigger()
  const [modalDelete, modalDeleteActions] = useModalTrigger()

  const handleScenarioSave = useCallback(
    (scenario) => {
      return patchScenario.asPromise(scenario)
    },
    [patchScenario]
  )

  const handleScenarioCreate = useCallback(
    (scenario) => {
      return addScenario
        .onSuccess(() => modalAddActions.close())
        .asPromise({
          ...scenario,
          estimate: estimate.id,
        })
    },
    [addScenario, estimate.id, modalAddActions]
  )

  const handleScenarioRemove = useCallback(
    (scenario) => {
      return removeScenario
        .onFailure(notifyError)
        .onSuccess(() => modalDeleteActions.close())
        .run(modalDelete.value.id)
    },
    [removeScenario, modalDelete.value?.id, modalDeleteActions]
  )

  const readOnly = estimate.readonly

  return (
    <div style={{ paddingBottom: 200 }}>
      {scenarios?.length === 0 && <p>{t("scenarios.empty_scenarios_list")}</p>}
      {scenarios !== null && (
        <div>
          <div className="text-right text-separator h3 mb-2">
            {saving && t("message.saving")}
            {!saving && t("message.saved")}
          </div>
          {scenarios.map((scenario) => (
            <ScenarioCard
              onRemove={modalDeleteActions.open}
              key={scenario.id}
              scenario={scenario}
              scenarioPrice={get(scenarioPrices, scenario.id, "--")}
              isOpen={!!opens[scenario.id]}
              toggleOpen={toggleOpen}
              readOnly={readOnly}
              onSave={handleScenarioSave}
            />
          ))}
        </div>
      )}

      {!readOnly && (
        <Button
          onClick={modalAddActions.toggle}
          color="primary"
          size="lg"
          rounded
          className="mt-4"
        >
          <Icon
            name="plus"
            className="pointer"
            title={t("action:new_scenario")}
          />
        </Button>
      )}
      <ModalAddScenario
        onSave={handleScenarioCreate}
        isOpen={modalAdd.isOpen}
        toggle={modalAddActions.toggle}
        onClosed={modalAddActions.onClosed}
      />

      <ConfirmDeleteModalScenario
        isOpen={modalDelete.isOpen}
        toggle={modalDeleteActions.toggle}
        onClosed={modalDeleteActions.onClose}
        scenario={modalDelete.value}
        onConfirm={handleScenarioRemove}
      />
    </div>
  )
}

import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { FaCheck, FaTimes } from "react-icons/fa"
import Button from "../../../components/Button"
import Icon from "../../../components/Icon"
import ImagePlaceholder from "../../../components/ImagePlaceholder"
import ImagePreview from "../../../components/ImagePreview"
import useModalTrigger from "../../../hooks/useModalTrigger"
import { CapabilitiesModal } from "./CapabilitiesModal"
import S from "./Members.module.scss"

function MemberInnerRowForm({ member, estimateRoleOptions, trackerRoleOptions, onDelete, onChange }) {
  const { t } = useTranslation(["translation", "action"])

  const [capModalState, capModalActions] = useModalTrigger()

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <ImagePreview
            src={member.initials}
            shape="circle"
            size="sm"
            disableHover
            className="mr-4"
            placeholder={ImagePlaceholder("avatar", member.member)}
          />
          <div>{member.email}</div>
        </div>
      </td>
      <td>
        {member.is_active ? <FaCheck /> : <FaTimes />}
        {member.is_active && (
          <Button
            size="sm"
            className="ml-3"
            onClick={() => {
              onChange(member.id, { is_active: false })
            }}
          >
            {t("action:disable")}
          </Button>
        )}
        {!member.is_active && (
          <Button
            size="sm"
            className="ml-3"
            onClick={() => {
              onChange(member.id, { is_active: true })
            }}
          >
            {t("action:enable")}
          </Button>
        )}
      </td>
      <td>
        <u
          className="pointer"
          onClick={() => {
            capModalActions.open(member.capabilities)
          }}
        >
          {t("translation:organization.members.cap_count", { count: member.capabilities.length })}
        </u>
        {capModalState.value && (
          <CapabilitiesModal
            isOpen={capModalState.isOpen}
            onSubmit={(capabilities) => {
              onChange(member.id, { capabilities })
              capModalActions.close()
            }}
            onCancel={capModalActions.close}
            onClosed={capModalActions.onClose}
            capabilities={capModalState.value}
          />
        )}
      </td>
      <td className="text-center">
        <div className={classNames(S["status"], S["status-member"])} />
      </td>
      <td className="text-center">
        <span onClick={() => onDelete(member.id)}>
          <Icon name="close" title={t("actions:delete")} style={{ width: 18, height: 18, cursor: "pointer" }} />
        </span>
      </td>
    </tr>
  )
}

export default React.memo(MemberInnerRowForm)

import { cmpFloat } from "../../../../utils"

export function shouldAutoSubmitResource(values, prevValues) {
  const rrun =
    values.resource_id !== prevValues.resource_id ||
    values.cost_unit !== prevValues.cost_unit ||
    !cmpFloat(values.size, prevValues.size)

  return rrun
}

export function shouldAutoSubmitFixedCost(values, prevValues) {
  const rrun =
    values.description !== prevValues.description ||
    !cmpFloat(values.cost, prevValues.cost) ||
    !cmpFloat(values.price, prevValues.price)

  return rrun
}
import classNames from "classnames"
import React from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

export default function WpModal({ isOpen, toggle, title, size, children, onClosed, contentClassName }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={size}
      contentClassName={classNames("border-2 border-primary border-radius-xl", contentClassName)}
      centered
      onClosed={onClosed}
    >
      {title && (
        <ModalHeader className="text-uppercase justify-content-center bg-primary-50 text-white py-3" tag="h3">
          {title}
        </ModalHeader>
      )}
      <ModalBody>{children}</ModalBody>
    </Modal>
  )
}

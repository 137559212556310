import { rj, useRj, useRunRj } from "react-rocketjump"
import api from "../api"
import { saveAs } from "file-saver"

const HrAllocationsRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (year, month, params) => {
    return api.auth(wpAuth).get(`/api/hr/allocation/m/${year}/${month}/`, params)
  },
  mutations: {
    create: {
      effect: (wpAuth) => (data) => {
        return api.auth(wpAuth).post(`/api/hr/allocation/`, data)
      },
      updater: (state, result) => state,
    },
    update: {
      effect: (wpAuth) => (id, data) => {
        return api.auth(wpAuth).patch(`/api/hr/allocation/${id}/`, data)
      },
      updater: (state, result) => state,
    },
    destroy: {
      effect: (wpAuth) => (id) => {
        return api.auth(wpAuth).delete(`/api/hr/allocation/${id}/`)
      },
      updater: (state, result) => state,
    },
    bulk_create: {
      effect: (wpAuth) => (data) => {
        return api.auth(wpAuth).post(`/api/hr/allocation/bulk/`, data)
      },
      updater: (state, result) => state,
    },
  },
})

export function useHrAllocations(year, month, params) {
  return useRunRj(HrAllocationsRj, [year, month, params])
}

const HrAllocationKindsRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (params) => {
    return api.auth(wpAuth).get("/api/hr/allocation-kind", params)
  },
})

export function useHrAllocationKinds(params) {
  return useRunRj(HrAllocationKindsRj, [params])
}

const HrAllocationPresetsRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (params) => {
    return api.auth(wpAuth).get("/api/hr/allocation-preset", params)
  },
})

export function useHrAllocationPresets(params) {
  return useRunRj(HrAllocationPresetsRj, [params])
}

const HrAllocationsReportRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (year, month, params) => {
    return api
      .auth(wpAuth)
      .request({ responseType: "blob" })
      .mapResponse((res) => saveAs(res.response, `presenze_${year}_${month}.xlsx`))
      .get(`/api/hr/allocation/report/${year}/${month}/`, params)
  },
})

export function useHrAllocationsReport(year, month, params) {
  return useRj(HrAllocationsReportRj, [year, month, params])
}


const HrAllocationsReportNewRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (year, month, params) => {
    return api
      .auth(wpAuth)
      .request({ responseType: "blob" })
      .mapResponse((res) => saveAs(res.response, `presenze_${year}_${month}.xlsx`))
      .get(`/api/hr/allocation/report-new/${year}/${month}/`, params)
  },
})

export function useHrAllocationsReportNew() {
  return useRj(HrAllocationsReportNewRj)
}

export default function PageBreakSplitter(
  node,
  props,
  firstChunkSpace,
  sheetHeight
) {
  return [
    {
      props,
      height: firstChunkSpace
    }
  ]
}

import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import Icon from "../../components/Icon"
import Layout from "../../components/Layout"
import Table from "../../components/Table/Table"
import { useEstimatesList } from "../../hooks/estimates"
import dayjs from "dayjs"
import { DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from "reactstrap"
import Separator from "../../components/Separator/Separator"
import CreateEstimateModal from "../../components/CreateEstimateModal"
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import Loader from "../../components/Loader/Loader"
import MeasureContext from "../../components/MeasureContext/MeasureContext"
import EmptyImage from "../../assets/icons/empty_page.svg"
import Button from "../../components/Button"
import { notifyError } from "../../utils"
import useModalTrigger from "../../hooks/useModalTrigger"
import CreateEstimateTemplateModal from "../../components/CreateEstimateTemplateModal/CreateEstimateTemplateModal"
import { saveAs } from "file-saver"
import classNames from "classnames"
import LongBorder from "../../components/LongBorder"
import S from "./Home.module.scss"
import WpLink from "../../components/WpLink"
import useWpHistoryMethods from "../../hooks/useWpHistoryMethods"
import useModalTriggerQuota from "../../hooks/useModalTriggerQuota"
import { getCurrencyFormatter } from "../../hooks/useCurrencyFormatter"
import Card from "reactstrap/lib/Card"
import CardBody from "reactstrap/lib/CardBody"
import CardTitle from "reactstrap/lib/CardTitle"
import CardSubtitle from "reactstrap/lib/CardSubtitle"
import { useProjectHomeList } from "../../hooks/homepage_projects"
import CardDeck from "reactstrap/lib/CardDeck"
import ListGroup from "reactstrap/lib/ListGroup"
import ListGroupItem from "reactstrap/lib/ListGroupItem"
import { useAuthUser } from "use-eazy-auth"
import ProjectActivityModal from "../../components/ProjectActivityModal/ProjectActivityModal"
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip"
import CreateProjectModal from "../../components/CreateProjectModal"
import { CAP, useCapabilities } from "../../hooks/capabilities"

const ESTIMATE_FILTER = {
  ordering: "-updated_at",
  archived: "false",
  page_size: 6
}

export default function Home() {
  const { t } = useTranslation(["translation", "action", "field"])

  const [createModal, createModalActions] = useModalTriggerQuota()
  const [deleteModal, deleteModalActions] = useModalTrigger()
  const [createTemplateModal, createTemplateModalActions] = useModalTriggerQuota()

  const history = useWpHistoryMethods()

  const [
    { estimates, exportingPdfs },
    { createEstimate, deleteEstimate, cloneEstimate, updateEstimate, exportPDF, instanceEstimatTemplate, run: reload },
  ] = useEstimatesList(ESTIMATE_FILTER)

  const onEstimateCreation = useCallback(
    (estimateData) => {
      if (estimateData.cloneId) {
        const { cloneId, ...cloneData } = estimateData
        return cloneEstimate
          .onSuccess((estimate) => {
            history.push(`/estimates/${estimate.id}/`)
          })
          .asPromise(cloneId, cloneData)
      }
      return createEstimate
        .onSuccess((estimate) => {
          history.push(`/estimates/${estimate.id}/`)
        })
        .asPromise(estimateData)
    },
    [cloneEstimate, createEstimate, history]
  )

  const onEstimateDeletion = useCallback(() => {
    const deleteId = deleteModal?.value?.id
    return deleteEstimate
      .onFailure(notifyError)
      .onSuccess(() => {
        deleteModalActions.close()
        reload(ESTIMATE_FILTER)
      })
      .run(deleteId)
  }, [deleteEstimate, deleteModal?.value?.id, deleteModalActions, reload])

  const onEstimateTemplateCreation = useCallback(
    (estimateData) => {
      const { cloneId, ...cloneData } = estimateData
      return instanceEstimatTemplate
        .onSuccess((estimate) => {
          history.push(`/estimates/${estimate.id}/`)
        })
        .asPromise(cloneId, cloneData)
    },
    [history, instanceEstimatTemplate]
  )

  const onEstimateArchive = useCallback(
    (toArchive) => {
      updateEstimate
        .onFailure(notifyError)
        .onSuccess(() => {
          reload(ESTIMATE_FILTER)
        })
        .run(toArchive.id, {
          archived: true,
        })
    },
    [reload, updateEstimate]
  )

  const onEstimateLock = useCallback(
    (toLock) => {
      updateEstimate
        .onFailure(notifyError)
        .onSuccess(() => {
          reload(ESTIMATE_FILTER)
        })
        .run(toLock.id, {
          editable_state: "closed",
        })
    },
    [reload, updateEstimate]
  )

  const onEstimateUnlock = useCallback(
    (toUnlock) => {
      updateEstimate
        .onFailure(notifyError)
        .onSuccess(() => {
          reload(ESTIMATE_FILTER)
        })
        .run(toUnlock.id, {
          editable_state: "open",
        })
    },
    [reload, updateEstimate]
  )

  const openClone = useCallback(
    (estimate) => {
      createModalActions.open({
        title: estimate.title,
        code: estimate.code,
        cloneId: estimate.id,
      })
    },
    [createModalActions]
  )

  const downloadEstimatePDF = useCallback(
    (estimate) => {
      exportPDF
        .onFailure(notifyError)
        .onSuccess((docBinary) => {
          if (docBinary !== null) {
            saveAs(docBinary, `${estimate.code}.pdf`)
          }
        })
        .run(estimate.id, estimate.documents[0])
    },
    [exportPDF]
  )

  const columns = useMemo(
    () => [
      {
        label: t("field:estimate.code"),
        name: "code",
        size: 2,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-column h-100 w-100 align-items-start justify-content-center">
              <p className={classNames(S["prevent-overflow"], "m-0")}>{selectedDatum}</p>
              {datum.editable_state === "closed" && (
                <span className="badge badge-secondary mt-2">{t("enums:estimate_state.locked_estimate")}</span>
              )}
            </div>
          )
        },
      },
      {
        label: t("field:estimate.title"),
        name: "title",
        size: 3,
      },
      {
        label: t("field:estimate.updated_at"),
        name: "updated_at",
        render: (selectedDatum, colName, datum) => {
          return dayjs(selectedDatum).format("DD-MM-YYYY HH:mm")
        },
        size: 2,
      },
      {
        label: t("field:estimate.price"),
        name: "price",
        render: (selectedDatum, colName, datum) => {
          return <div className="text-right">{getCurrencyFormatter(datum).format(selectedDatum)}</div>
        },
        size: 2,
      },
      {
        label: t("field:estimate.drafting_state"),
        name: "drafting_state",
        size: 1,
        render: (selectedDatum, colName, datum) => {
          return <div>{t("enums:drafting_state." + selectedDatum)}</div>
        },
      },
      {
        label: t("actions"),
        name: "actions",
        size: 2,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <WpLink to={`/estimates/${datum.id}/tasks`} className={"action-icon-container-primary-hover"}>
                {!datum.readonly && <Icon name="edit" title={t("action:edit_estimate")} className="pointer text-dark" />}
                {datum.readonly && <Icon name="view" title={t("action:view_estimate")} className="pointer text-dark" />}
              </WpLink>
              <div className={"action-icon-container-primary-hover"}>
                {exportingPdfs[datum.id] ? (
                  <Spinner color="primary" style={{ height: 20, width: 20 }} />
                ) : (
                  <Icon
                    className={classNames("pointer text-dark", {
                      "icon-disabled": datum.documents.length === 0,
                    })}
                    name="pdf"
                    title={t("action:pdf_export")}
                    onClick={() => downloadEstimatePDF(datum)}
                  />
                )}
              </div>
              <div className={"action-icon-container-primary-hover"}>
                <Icon
                  name="duplicate"
                  title={t("action:duplicate")}
                  className="pointer text-dark"
                  onClick={() => openClone(datum)}
                />
              </div>
              <div className={"action-icon-container-primary-hover"}>
                <UncontrolledDropdown>
                  <DropdownToggle caret={false} tag={"span"}>
                    <Icon
                      role="button"
                      name="vdots"
                      className="text-dark pointer"
                      title={t("action:other_options")}
                      placement="right"
                    />
                  </DropdownToggle>
                  <DropdownMenu right modifiers={{ offset: { offset: "0, 12" } }} className="border-primary">
                    <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                      <div className="d-flex flex-row align-items-center mx-4" onClick={() => onEstimateArchive(datum)}>
                        <Icon name="archive" className={classNames("pointer mr-4")} />
                        <span>{t("action:archive")}</span>
                      </div>
                    </DropdownItem>
                    <Separator className="my-0 mx-4 border-primary" />
                    {datum.editable_state === "open" && (
                      <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                        <div className="d-flex flex-row align-items-center mx-4" onClick={() => onEstimateLock(datum)}>
                          <Icon name="lock" className={classNames("pointer mr-4")} />
                          <span>{t("action:lock")}</span>
                        </div>
                      </DropdownItem>
                    )}
                    {datum.editable_state === "closed" && (
                      <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                        <div className="d-flex flex-row align-items-center mx-4" onClick={() => onEstimateUnlock(datum)}>
                          <Icon name="unlock" className={classNames("pointer mr-4")} />
                          <span>{t("action:unlock")}</span>
                        </div>
                      </DropdownItem>
                    )}
                    <Separator className="my-0 mx-4 border-primary" />
                    <DropdownItem className={"text-capitalize px-0 dropdown-item-primary-active"}>
                      <div
                        className="d-flex flex-row align-items-center mx-4"
                        onClick={() => {
                          deleteModalActions.open(datum)
                        }}
                      >
                        <Icon name="delete" className="pointer mr-4" />
                        <span>{t("action:delete")}</span>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )
        },
      },
    ],
    [deleteModalActions, downloadEstimatePDF, exportingPdfs, onEstimateArchive, onEstimateLock, onEstimateUnlock, openClone, t]
  )

  const [{ projects }, { createActivity, create: createProject }] = useProjectHomeList()
  const { user } = useAuthUser()
  const [createActivityModal, createActivityModalActions] = useModalTrigger()
  const [createProjectModal, createProjectModalActions] = useModalTriggerQuota()
  const [, { hasCapability }] = useCapabilities()

  const isEstimateUser = hasCapability(CAP.ESTIMATES)
  const isTrackerUser = hasCapability(CAP.PROJECTS)

  return (
    <Layout>
      {((estimates === null && isEstimateUser) || (projects === null && isTrackerUser)) && <Loader />}
      {(estimates !== null || !isEstimateUser) && (projects !== null || !isTrackerUser) && (
        <div className="container-fluid pt-8 px-page pb-page-bottom">
          {isEstimateUser && (
            <>
              <h1 className="text-primary pb-6">{t("home.title")}</h1>
              {estimates.length === 0 && (
                <div className="d-flex flex-column text-primary mt-5">
                  <span className="pb-4">{t("estimate_list.empty_estimates")}</span>
                  <MeasureContext as="img" src={EmptyImage} alt="" bottom={0} maxHeight={400} className="h-100">
                    {(ref) => (
                      <div
                        className="d-flex flex-row align-items-center position-relative pointer"
                        onClick={() => createModalActions.open()}
                      >
                        <Button color={"primary"} size="lg" rounded>
                          <Icon name="plus" className="pointer" />
                        </Button>
                        <span className="pl-3" ref={ref}>
                          {t("estimate_list.create_estimate")}
                        </span>
                      </div>
                    )}
                  </MeasureContext>
                </div>
              )}
              {estimates.length !== 0 && (
                <>
                  <LongBorder topBar="toolbar" />
                  <Table columns={columns} data={estimates.slice(0, 6)} className="pb-8" highlightRows={[]} />
                </>
              )}
            </>
          )}
          {isTrackerUser && (
            <>
              <h1 className="text-primary pb-6">{t("home.tracker")}</h1>
              {projects.length === 0 && (
                <div>
                  <p>{t("home.no_projects")}</p>
                </div>
              )}
              {projects.length > 0 && (
                <CardDeck>
                  {projects.map((project) => (
                    <Card key={project.id}>
                      <CardBody>
                        <CardTitle tag="h5">
                          <div className="d-flex flex-row justify-content-start align-items-start">
                            <span className="flex-1 prevent-overflow">{project.name}</span>
                            <WpLink className={"action-icon-container-primary-hover"} to={`/projects/${project.id}`}>
                              {!project.readonly && (
                                <Icon name="edit" title={t("action:edit_estimate")} className="pointer text-dark" />
                              )}
                              {project.readonly && (
                                <Icon name="view" title={t("action:view_estimate")} className="pointer text-dark" />
                              )}
                            </WpLink>
                            <WpLink className={"action-icon-container-primary-hover"} to={`/projects/${project.id}/insight`}>
                              <Icon
                                name="project-insight"
                                title={t("tab:project.insight")}
                                className="pointer text-dark"
                                height={24}
                                width={24}
                              />
                            </WpLink>
                          </div>
                        </CardTitle>
                        <CardSubtitle tag="h6">{project.code}</CardSubtitle>
                      </CardBody>
                      <ListGroup flush>
                        {project.tasks.map((task) => (
                          <ListGroupItem key={task.id}>
                            <div className="d-flex flex-row justify-content-start align-items-start">
                              <span className="flex-1 prevent-overflow pr-3" id={`task-${task.id}`}>
                                {task.title}
                              </span>
                              <UncontrolledTooltip placement="bottom" target={`task-${task.id}`}>
                                {task.title}
                              </UncontrolledTooltip>
                              <div className={"action-icon-container-primary-hover"}>
                                <Icon
                                  name="project-activity-add"
                                  title={t("action:add_project_activity")}
                                  className="pointer text-dark"
                                  disabled={!task.is_assigned_to_me}
                                  onClick={() => {
                                    createActivityModalActions.open({
                                      project: project.id,
                                      project_task: task.id,
                                      subject: user.id,
                                    })
                                  }}
                                />
                              </div>
                            </div>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </Card>
                  ))}
                </CardDeck>
              )}
            </>
          )}
        </div>
      )}

      <CreateEstimateModal
        mode={createModal.value ? "duplicate" : "create"}
        cloneEstimate={createModal.value}
        toggle={createModalActions.toggle}
        isOpen={createModal.isOpen}
        onSave={onEstimateCreation}
        onClosed={createModalActions.onClose}
      />

      <CreateEstimateTemplateModal
        toggle={createTemplateModalActions.toggle}
        isOpen={createTemplateModal.isOpen}
        onSave={onEstimateTemplateCreation}
        onClosed={createTemplateModalActions.onClose}
      />

      {deleteModal.value && (
        <ConfirmDeleteModal
          toggle={deleteModalActions.toggle}
          isOpen={deleteModal.isOpen}
          onConfirm={onEstimateDeletion}
          item={deleteModal.value.title}
          onClosed={deleteModalActions.onClose}
        />
      )}

      {createActivityModal.value && (
        <ProjectActivityModal
          isOpen={createActivityModal.isOpen}
          toggle={createActivityModalActions.toggle}
          onClosed={createActivityModalActions.onClose}
          defaults={createActivityModal.value}
          save={(data) => createActivity.asPromise(data)}
        />
      )}

      {createProjectModal.value && (
        <CreateProjectModal
          isOpen={createProjectModal.isOpen}
          toggle={createProjectModalActions.toggle}
          onClosed={createProjectModalActions.onClose}
          onSave={(data) => {
            createProject
              .onSuccess((result) => {
                createProjectModalActions.close()
                history.push(`/projects/${result.id}`)
              })
              .run(data)
          }}
        />
      )}

      <Layout.Toolbar title={t("home.toolbar_title")}>
        {isEstimateUser && (
          <>
            <Layout.Toolbar.Icon
              text={t("action:new_estimate_blank")}
              svgIcon={<Icon name="new-estimate" />}
              title={t("action:new_estimate_blank")}
              onClick={() => createModalActions.open()}
            />
            <Layout.Toolbar.Divider />
            <Layout.Toolbar.Icon
              text={t("action:new_estimate_template")}
              svgIcon={<Icon name="new_estimate_template" />}
              title={t("action:new_estimate_template")}
              onClick={() => createTemplateModalActions.open()}
            />
          </>
        )}
        {isEstimateUser && isTrackerUser && <Layout.Toolbar.Divider />}
        {isTrackerUser && (
          <Layout.Toolbar.Icon
            text={t("action:new_project")}
            svgIcon={<Icon name="project" />}
            title={t("action:new_project")}
            onClick={() => createProjectModalActions.open("dummy")}
          />
        )}
      </Layout.Toolbar>
    </Layout>
  )
}

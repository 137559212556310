import { BsCheckSquare, BsSquare } from "react-icons/bs"

export default function FieldYesNo({
  field,
  form: { touched, errors, setFieldValue },
  label,
  type = "text",
  enableShowHide = "false",
  className = "",
  containerClassName = "",
  debounce = false,
  readOnly = false,
  style = undefined,
  labelRight = "",
  positiveValue = true,
  dualMode = false,
  ...props
}) {
  return (
    <div className={`d-flex flex-column ${containerClassName}`}>
      {label && <label className="font-weight-semibold mb-3 h3 text-uppercase">{label}</label>}
      <div
        style={{
          height: 37.5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          ...style,
        }}
      >
        {field.value === positiveValue && (
          <span
            className="pointer"
            onClick={() => {
              if (!readOnly && !dualMode) {
                setFieldValue(field.name, !positiveValue)
              }
            }}
          >
            <BsCheckSquare
              className="pointer"
              style={{ backgroundColor: readOnly ? "#f9f9f9" : "#fff", color: readOnly ? "#999" : "currentColor" }}
            />
            {labelRight && <span className="ml-2">{labelRight}</span>}
          </span>
        )}
        {field.value !== positiveValue && (
          <span
            className="pointer"
            onClick={() => {
              if (!readOnly) {
                setFieldValue(field.name, positiveValue)
              }
            }}
          >
            <BsSquare
              className="pointer"
              style={{ backgroundColor: readOnly ? "#f9f9f9" : "#fff", color: readOnly ? "#999" : "currentColor" }}
            />
            {labelRight && <span className="ml-2">{labelRight}</span>}
          </span>
        )}
      </div>
    </div>
  )
}

import { Field, Formik } from "formik"
import { mapValues } from "lodash"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import Button from "../../../components/Button"
import FieldInput from "../../../components/fields/FieldInput"
import FieldTextarea from "../../../components/fields/FieldTextarea"
import WpModal from "../../../components/WpModal"
import { convertErrorToForm } from "../../../utils"

const INITIAL_VALUES = {
  date: "",
  description: "",
  amount: "",
  code: "",
}

export function EditBillModal({ isOpen, toggle, onSave, onClosed, bill }) {
  const { t } = useTranslation(["translation", "field", "action"])

  const schema = yup.object().shape({
    date: yup
      .date()
      .typeError(
        t("field:errors.date", {
          field: t("field:project_activity.date_ref"),
        })
      )
      .required(
        t("field:errors.mandatory", { field: t("field:project.name") })
      ),
    description: yup.string().required(),
  })

  return (
    <WpModal
      onClosed={onClosed}
      isOpen={isOpen}
      toggle={toggle}
      title={bill?.id ? t("action:edit_bill") : t("action:new_bill")}
    >
      <div>
        <div className="d-flex flex-row align-items-start my-4">
          <div className="d-flex flex-column ml-2 flex-1">
            <Formik
              initialValues={{ ...INITIAL_VALUES, ...bill }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                return onSave(
                  mapValues(values, (val) => (val === undefined ? null : val))
                ).catch((error) => {
                  actions.setErrors(convertErrorToForm(error))
                })
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <>
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Field
                      name="date"
                      component={FieldInput}
                      type="date"
                      label={t("field:bill.date")}
                      className={"mb-5"}
                    />
                    <Field
                      name="amount"
                      component={FieldInput}
                      min="0.00"
                      step="0.01"
                      className={"mb-5"}
                      placeholder={t("field:bill.placeholder.amount")}
                      label={t("field:bill.amount")}
                    />
                    <Field
                      name="description"
                      component={FieldTextarea}
                      className={"mb-5"}
                      placeholder={t("field:bill.placeholder.description")}
                      label={t("field:bill.description")}
                    />
                    <Field
                      name="code"
                      component={FieldInput}
                      className={"mb-5"}
                      placeholder={t("field:bill.placeholder.code")}
                      label={t("field:bill.code")}
                    />
                    <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                      <Button size="sm" color="none" onClick={toggle}>
                        {t("action:cancel")}
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        type="submit"
                        className="ml-5"
                        disabled={!formik.isValid}
                      >
                        {bill?.id ? t("action:save") : t("action:add")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </WpModal>
  )
}

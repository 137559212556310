import React from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '../../components/Icon'
import Layout from '../../components/Layout'

export default function TemplateTabBarContent() {
  const { t } = useTranslation(["translation", "tab"])
  return (
    <>
      <Layout.TabBar.TabBarTab
        text={t("tab:template.estimate")}
        exact
        to={`/templates`}
        svgIcon={<Icon name="document" style={{ width: 23, height: 21 }}/>}
      />
      <Layout.TabBar.Divider />
      <Layout.TabBar.TabBarTab
        text={t("tab:template.text")}
        to={`/templates/text`}
        svgIcon={<Icon name="template_text" style={{ width: 23, height: 21 }} />}
      />
      <Layout.TabBar.Divider />
      <Layout.TabBar.TabBarTab
        text={t("tab:template.cover")}
        to={`/templates/cover`}
        svgIcon={<Icon name="template_text" style={{ width: 23, height: 21 }} />}
      />
    </>
  )
}
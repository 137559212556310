import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { deps } from "react-rocketjump"
import { useCustomerEnum, useCustomersEnum } from "../../hooks/customers"
import DropdownList from "../DropdownList"

export function CustomerSelector({
  customerId,
  onChange,
  clearable = false,
  caption,
  enableNone = false,
  enableAll = false,
  ...props
}) {
  const [{ customers }, { runDebounced: loadCustomers }] = useCustomersEnum()
  const [{ data: customer }] = useCustomerEnum(deps.maybe(customerId))
  const [autoCaption, setAutoCaption] = useState("")

  const { t } = useTranslation()

  const options =
    customers?.map((customer) => ({
      value: customer.id,
      label: customer.name + (customer.code ? ` (${customer.code})` : ""),
    })) ?? []

  if (enableNone) {
    options.unshift({ value: null, label: t("none") })
  }

  return (
    <DropdownList
      {...props}
      value={customerId}
      caption={caption || customer?.name || autoCaption || ""}
      options={options}
      onChange={(nextCustomerId) => {
        const nextCustomer = customers.find((c) => c.id === nextCustomerId)
        if (nextCustomer) {
          setAutoCaption(nextCustomer.name + (nextCustomer.code ? ` (${nextCustomer.code})` : ""))
        } else {
          setAutoCaption("")
        }
        onChange(nextCustomerId)
      }}
      onSearchChange={(search) => {
        loadCustomers({ search, page: 1 })
      }}
      clearable={clearable}
      onClear={() => {
        onChange(null)
      }}
      autoFilterIgnoreCase
      itemWidth={300}
    />
  )
}

export function CustomerSelectorMultiple({
  customersId,
  onChange,
  clearable = false,
  caption,
  enableNone = false,
  enableAll = false,
  emptyCaption = "",
  ...props
}) {
  const customerId = customersId.length === 1 ? customersId[0] : null

  const [{ customers }, { runDebounced: loadCustomers }] = useCustomersEnum()
  const [{ data: customer }] = useCustomerEnum(deps.maybe(customerId))

  const { t } = useTranslation()

  const options =
    customers?.map((customer) => ({
      value: customer.id,
      label: customer.name + (customer.code ? ` (${customer.code})` : ""),
    })) ?? []

  let autoCaption = customersId.length + " " + t("selected_items")

  if (customersId.length === 1) {
    autoCaption = customer?.name
  }

  if (customersId.length === 0 && emptyCaption) {
    autoCaption = emptyCaption
  }

  if (enableNone) {
    options.unshift({ value: null, label: t("none") })
  }

  return (
    <DropdownList
      {...props}
      value={customersId}
      caption={caption || autoCaption || ""}
      options={options}
      onChange={(nextCustomersId) => {
        onChange(nextCustomersId)
      }}
      onSearchChange={(search) => {
        loadCustomers({ search, page: 1 })
      }}
      clearable={clearable}
      onClear={() => {
        onChange([])
      }}
      autoFilterIgnoreCase
      itemWidth={300}
      capitalize={false}
    />
  )
}

function CustomerSelectorField({ form, field, label, multiple = false, ...props }) {
  return (
    <div className="d-flex flex-column align-items-stretch">
      {label && <label className="font-weight-semibold mb-3 h3 text-uppercase">{label}</label>}
      {!multiple && (
        <CustomerSelector customerId={field.value} onChange={(custId) => form.setFieldValue(field.name, custId)} {...props} />
      )}
      {multiple && (
        <CustomerSelectorMultiple
          customerId={field.value}
          onChange={(custId) => form.setFieldValue(field.name, custId)}
          {...props}
        />
      )}
    </div>
  )
}

CustomerSelector.Field = CustomerSelectorField

import { Field, Formik } from "formik"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import FieldDropdown from "../../../components/fields/FieldDropdown"
import FieldInput from "../../../components/fields/FieldInput"
import FieldRichText from "../../../components/fields/FieldRichText"
import WpModal from "../../../components/WpModal/WpModal"
import { usePriceLists } from "../../../hooks/priceList"
import { getCurrencyFormatter } from "../../../hooks/useCurrencyFormatter"
import * as yup from "yup"

const TASK_DEFAULTS = {
  estimate_code: null,
  code: "",
  task: null,
  title: "",
  description_rich: null,
  price_list: null,
}

export default function CreateTaskModal({
  isOpen,
  toggle,
  onClosed,
  data,
  save,
}) {
  const { t } = useTranslation(["translation", "field", "action"])
  const [{ data: priceLists }] = usePriceLists()

  const schema = useMemo(() => {
    return yup.object().shape({
      code: yup.string().required(
        t("field:errors.mandatory", {
          field: t("field:project_task.code"),
        })
      ),
      title: yup.string().required(
        t("field:errors.mandatory", {
          field: t("field:project_task.title"),
        })
      ),
      price_list: yup
        .number()
        .typeError(
          t("field:errors.mandatory", {
            field: t("field:project_task.price_list"),
          })
        )
        .required(
          t("field:errors.mandatory", {
            field: t("field:project_task.price_list"),
          })
        ),
    })
  }, [t])

  if (!priceLists) {
    return null
  }

  return (
    <WpModal
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
      title={
        data.id
          ? t("project_task_list.task_modal.title_update")
          : t("project_task_list.task_modal.title_create")
      }
      size="lg"
    >
      <Formik
        initialValues={{
          TASK_DEFAULTS,
          price_list: priceLists?.[0]?.id ?? -1,
          ...data,
        }}
        validationSchema={schema}
        onSubmit={(values, actions) => {
          return save(values)
        }}
      >
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <div className="container-fluid">
                <div className="row mb-5">
                  <div className="col-6">
                    <Field
                      name="code"
                      component={FieldInput}
                      label={t("field:project_task.code")}
                      placeholder={t("field:project_task.placeholder.code")}
                      disabled={formik.values.estimate_code}
                    />
                  </div>
                  <div className="col-6">
                    <Field
                      name="title"
                      component={FieldInput}
                      label={t("field:project_task.title")}
                      placeholder={t("field:project_task.placeholder.title")}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-6">
                    <Field
                      name="price_list"
                      component={FieldDropdown}
                      label={t("field:project_task.price_list")}
                      placeholder={t(
                        "field:project_task.placeholder.price_list"
                      )}
                      options={priceLists.map((lst) => {
                        return {
                          value: lst.id,
                          label:
                            lst.title +
                            " (" +
                            getCurrencyFormatter(lst.currency).currencySymbol +
                            ")",
                        }
                      })}
                      capitalize={false}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12">
                    <Field
                      name="description_rich"
                      component={FieldRichText}
                      label={t("field:project_task.description_rich")}
                      placeholder={t(
                        "field:project_task.placeholder.description_rich"
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-right">
                    <Button
                      type="button"
                      color="none"
                      onClick={toggle}
                      className="mr-5"
                      size="sm"
                    >
                      Annulla
                    </Button>
                    <Button
                      disabled={formik.isSubmitting}
                      size="sm"
                      type="submit"
                    >
                      Salva
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          )
        }}
      </Formik>
    </WpModal>
  )
}

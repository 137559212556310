import classNames from "classnames"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import DropdownItem from "reactstrap/lib/DropdownItem"
import DropdownMenu from "reactstrap/lib/DropdownMenu"
import DropdownToggle from "reactstrap/lib/DropdownToggle"
import UncontrolledDropdown from "reactstrap/lib/UncontrolledDropdown"
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import { CreateCustomerModal } from "../../components/CreateCustomerModal"
import Icon from "../../components/Icon"
import Layout from "../../components/Layout"
import Loader from "../../components/Loader/Loader"
import LongBorder from "../../components/LongBorder"
import Pagination from "../../components/Pagination/Pagination"
import Table from "../../components/Table"
import WpInput from "../../components/WpInput"
import WpLink from "../../components/WpLink"
import { useCustomers } from "../../hooks/customers"
import useModalTrigger from "../../hooks/useModalTrigger"
import useModalTriggerQuota from "../../hooks/useModalTriggerQuota"
import S from "./CustomersList.module.scss"
import { BsPersonPlus, BsQuestionOctagon } from "react-icons/bs"
import useCurrentOrganization from "../../hooks/useCurrentOrganization"
import { CRMLandmarks } from "../../components/CRMLandmarks"
import Dropdown from "../../components/Dropdown"

export function CustomersList({ history }) {
  const currentOrg = useCurrentOrganization()
  const { t } = useTranslation(["translation", "field", "enums", "action"])

  const [
    { name, position },
    setQueryParams,
    { name: debName, page: debPage, position: debPosition },
    setDebouncedQueryParams,
  ] = useQueryParams({
    page: {
      encode: (intValue) => intValue.toString(10),
      decode: (strValue) => (strValue ? parseInt(strValue, 10) : 1),
    },
    position: {
      encode: (rawValue) => rawValue.toString(),
      decode: (strValue) => strValue || "all",
    },
  })

  const filters = useMemo(() => {
    const fx = {
      page: debPage,
      position: debPosition,
      name__icontains: debName,
    }
    return fx
  }, [debName, debPage, debPosition])

  const [createModal, createModalActions] = useModalTriggerQuota()
  const [deleteModal, deleteModalActions] = useModalTrigger()

  const [
    { customers, count, pageCount, hasNext, hasPrev, fullCount },
    { remove, create, run: reload },
  ] = useCustomers(filters)

  const columns = useMemo(
    () => [
      {
        label: t("field:customer.name"),
        name: "name",
        size: 6,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-column h-100 w-100 align-items-start justify-content-center">
              <p className={classNames(S["prevent-overflow"], "m-0")}>
                <BsQuestionOctagon
                  size={16}
                  className="mr-2"
                  style={{ opacity: datum.position === "prospect" ? 1 : 0 }}
                />
                <WpLink to={`/customers/${datum.id}`}>{selectedDatum}</WpLink>
              </p>
            </div>
          )
        },
      },
      {
        label: t("field:customer.code"),
        name: "code",
        size: 3,
      },
      {
        label: t("field:customer.info_email"),
        name: "info_email",
        size: 4,
      },
      {
        label: t("field:customer.phone"),
        name: "phone",
        size: 3,
      },
      {
        label: t("field:customer.website"),
        name: "website",
        size: 4,
      },
      {
        label: t("actions"),
        name: "actions",
        size: 4,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <WpLink
                className={"action-icon-container-primary-hover"}
                to={`/customers/${datum.id}/info`}
              >
                {!datum.readonly && (
                  <Icon
                    name="edit"
                    title={t("action:edit_estimate")}
                    className="pointer text-dark"
                  />
                )}
                {datum.readonly && (
                  <Icon
                    name="view"
                    title={t("action:view_estimate")}
                    className="pointer text-dark"
                  />
                )}
              </WpLink>
              <div className={"action-icon-container-primary-hover"}>
                <UncontrolledDropdown>
                  <DropdownToggle caret={false} tag={"span"}>
                    <Icon
                      role="button"
                      name="vdots"
                      className="text-dark pointer"
                      title={t("action:other_options")}
                      placement="right"
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    right
                    modifiers={{ offset: { offset: "0, 12" } }}
                    className="border-primary"
                  >
                    <DropdownItem
                      className={
                        "text-capitalize px-0 dropdown-item-primary-active"
                      }
                    >
                      <div
                        className="d-flex flex-row align-items-center mx-4"
                        onClick={() => {
                          deleteModalActions.open(datum)
                        }}
                      >
                        <Icon name="delete" className="pointer mr-4" />
                        <span>{t("action:delete")}</span>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )
        },
      },
    ],
    [t, deleteModalActions]
  )

  return (
    <Layout>
      {customers === null && <Loader />}
      {customers !== null && (
        <div className="container-fluid pt-8 px-page pb-page-bottom">
          <h1 className="text-primary font-weight-semibold">
            {t("customers_list.title")}
          </h1>

          <div className="d-flex flex-column mt-4 mb-7">
            <div className="d-flex flex-row align-items-center justify-content-between mt-3 ml-3">
              <div className="d-flex flex-row">
                <div className="mr-5">
                  <span className="mr-3">
                    {t("customers_list.filter_name")}
                  </span>
                  <WpInput
                    medium
                    placeholder={t("field:customer.name")}
                    style={{ width: 150 }}
                    value={name}
                    onChange={(e) =>
                      setDebouncedQueryParams({
                        name: e.target.value,
                        page: 1,
                      })
                    }
                    className="pl-3"
                  />
                </div>
                <div className="mr-5">
                  <span className="mr-3">
                    {t("customers_list.filter_position")}
                  </span>
                  <Dropdown
                    value={position}
                    className="px-3 py-2"
                    options={[
                      {
                        value: "prospect",
                        label: t("enums:customer_position.prospect"),
                      },
                      {
                        value: "effective",
                        label: t("enums:customer_position.effective"),
                      },
                      {
                        value: "all",
                        label: t("enums:customer_position.all"),
                      },
                    ]}
                    onChange={(val) => {
                      setQueryParams({ position: val, page: 1 })
                    }}
                    itemWidth={200}
                    style={{ width: 200 }}
                  />
                </div>
              </div>
              <div>
                <span className="ml-2">
                  {t("customers_list.customers_count", {
                    filtered: count,
                    count: fullCount,
                    context: count !== 1 ? "many" : "one",
                  })}
                </span>
              </div>
            </div>
          </div>

          {/*Something to show*/}
          {count !== 0 && (
            <>
              <LongBorder topBar="toolbar" />

              <Table
                columns={columns}
                data={customers}
                className="pb-8"
                highlightRows={[]}
                use24
              />

              <Pagination
                current={debPage}
                pageCount={pageCount}
                goToPage={(page) => {
                  setQueryParams({ page })
                  window.scrollTo(0, 10)
                }}
                hasNext={hasNext}
                hasPrev={hasPrev}
              />
            </>
          )}
        </div>
      )}

      {createModal.value && (
        <CreateCustomerModal
          isOpen={createModal.isOpen}
          toggle={createModalActions.toggle}
          onClosed={createModalActions.onClose}
          onSave={(data) => {
            create
              .onSuccess(() => {
                reload()
                createModalActions.close()
              })
              .run({ ...data, owner_org: currentOrg.id })
          }}
        />
      )}

      {deleteModal.value && (
        <ConfirmDeleteModal
          toggle={deleteModalActions.toggle}
          isOpen={deleteModal.isOpen}
          onConfirm={() => {
            remove
              .onSuccess(() => {
                reload(filters)
                deleteModalActions.close()
              })
              .run(deleteModal.value.id)
          }}
          onClosed={deleteModalActions.onClose}
          item={deleteModal.value.name}
        />
      )}

      <Layout.Toolbar title={t("customers_list.toolbar_title")}>
        <Layout.Toolbar.Icon
          text={t("action:new_customer")}
          svgIcon={<BsPersonPlus size={20} />}
          title={t("action:new_customer")}
          onClick={() => createModalActions.open("dummy")}
        />
        <Layout.Toolbar.Divider />
      </Layout.Toolbar>
      <Layout.FirstLevelNavi>
        <CRMLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

export default function TaskTablePageSplitter(
  node,
  props,
  firstChunkSpace,
  sheetHeight
) {
  const frag = props.frag
  const blocks = new Array(...node.querySelectorAll("[data-block=\"true\"]")).map(blockNode => {
    const key = blockNode.dataset.offsetKey
    const style = blockNode.currentStyle || getComputedStyle(blockNode)
    let outerHeight = blockNode.offsetHeight
    let marginBottom = parseInt(style.marginBottom, 10)
    if (isNaN(marginBottom)) {
      marginBottom = 0
    }
    outerHeight = outerHeight + marginBottom
    let marginTop = parseInt(style.marginTop, 10)
    if (isNaN(marginTop)) {
      marginTop = 0
    }
    outerHeight = outerHeight + marginTop

    if (blockNode.nodeName.toLowerCase() === "li") {
      if (blockNode.parentNode.firstElementChild === blockNode) {
        outerHeight += 16 // margin of <ul> or <ol>
      }
      if (blockNode.parentNode.lastElementChild === blockNode) {
        outerHeight += 16 // margin of <ul> or <ol>
      }
    }

    return {
      key,
      height: outerHeight,
      blockNode
    }
  })

  let currentChunkHeight = 12
  let currentChunkStart = undefined
  let currentChunkEnd = undefined
  const chunks = []
  let maxChunkHeight = firstChunkSpace

  // If there is not enough space on first page for header + footer
  // just push a null chunk to tell the paginator to break page before
  // rendering this component
  if (currentChunkHeight > maxChunkHeight || currentChunkHeight + blocks[0].height > maxChunkHeight) {
    chunks.push(null)
    maxChunkHeight = sheetHeight
  }

  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i]
    const height = block.height
    if (currentChunkHeight + height > maxChunkHeight) {
      // Another page has been filled, push it in the chunks array
      // Remember: all chunks must measure their height before returning
      chunks.push({
        props: {
          frag: {
            ...frag,
            data: {
              ...frag.data,
              content: {
                ...frag.data.content,
                blocks: frag.data.content.blocks.slice(
                  currentChunkStart,
                  currentChunkEnd + 1
                ),
              },
            },
          },
        },
        height: currentChunkHeight,
      })
      currentChunkStart = i
      currentChunkEnd = i
      currentChunkHeight = height + 12
      maxChunkHeight = sheetHeight
      if (block.blockNode.nodeName.toLowerCase() === "li") {
        currentChunkHeight += 16 // margin of <ul>
      }
    } else {
      currentChunkEnd = i
      currentChunkHeight += height
    }
  }

  chunks.push({
    props: {
      frag: {
        ...frag,
        data: {
          ...frag.data,
          content: {
            ...frag.data.content,
            blocks: frag.data?.content?.blocks.slice(
              currentChunkStart,
              undefined
            ),
          },
        },
      },
    },
    height: currentChunkHeight,
  })

  return chunks
}

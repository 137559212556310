import { find, flatten, groupBy, range } from "lodash"
import React, { useMemo } from "react"
import Icon from "../../../../components/Icon"
import ResourceTable from "./ResourceTable"
import S from "./TaskTable.module.scss"
import classNames from "classnames"
import FixedCostTable from "./FixedCostTable"
import Separator from "../../../../components/Separator"
import ScenarioSymbol from "../../../../components/ScenarioSymbol"
import WpLink from "../../../../components/WpLink"
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter"

export function useEditableColumns(
  isResourceVisible,
  isCostVisible,
  isScenarioVisible,
  resources,
  estimateScenarios,
  selectedScenarios,
  t,
  depth,
  history,
  estimateId,
  onEditTaskClick,
  readOnly,
) {
  const currencyFmt = useCurrencyFormatter()

  const columns = useMemo(() => {
    return [
      {
        label: t("field:task.path"),
        name: "path",
        render: (selectedDatum, colName, datum) => {
          const depthLevel = selectedDatum.split(".").length

          return (
            <div className="d-flex flex-row align-items-center">
              <div className={"d-flex flex-column justify-content-center align-items-center text-dynamic-theme"}>
                {depth > 1 &&
                  range(1, depth + 1).map((i) => {
                    return (
                      <svg key={i} width={7} height={7} className={S["depth-dot"]}>
                        <circle cx={3.5} cy={3.5} r={i === depthLevel ? 3.5 : 1.5} fill="currentColor" />
                      </svg>
                    )
                  })}
              </div>
              <span className="pl-3">{selectedDatum}</span>
            </div>
          )
        },
        size: 2,
      },
      {
        label: t("field:task.title"),
        name: "title",
        render: (selectedDatum, col, datum) => {
          return (
            <div className="d-flex flex-column align-items-start justify-content-center">
              <div>{selectedDatum}</div>
              {isScenarioVisible && (
                <div className="d-flex">
                  {estimateScenarios.map((scenario) => {
                    if (
                      !datum.inferredScenarios.includes(scenario.id) ||
                      !(selectedScenarios === null || selectedScenarios.includes(scenario.id))
                    ) {
                      return null
                    }
                    return (
                      <div className="mr-2">
                        <ScenarioSymbol scenario={scenario} isInferred={datum.inferredOnlyScenarios.includes(scenario.id)} />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )
        },
        size: isResourceVisible ? 5 : isCostVisible ? 12 : 15,
      },
      {
        label: t("field:task.resources"),
        name: "resources",
        render: (selectedDatum, colName, datum) => (
          <div className="d-flex flex-column w-100">
            <ResourceTable nodeResources={selectedDatum} estimateResources={resources} />
            {datum.fixed_costs.length > 0 && selectedDatum.length > 0 && <Separator className="mx-0 my-3" />}
            <FixedCostTable nodeFixedCosts={datum.fixed_costs} />
          </div>
        ),
        size: isCostVisible ? 7 : 10,
      },
      {
        label: t("field:task.cost"),
        name: "cost",
        render: (selectedDatum, colName, datum) => {
          return (
            <span className="d-inline-flex flex-column align-items-end">
              {datum.costMap[""] !== undefined && <span>{currencyFmt.format(datum.costMap[""])}</span>}
              {estimateScenarios.map((scenario) => {
                if (scenario.id in datum.costMap && (selectedScenarios === null || selectedScenarios.includes(scenario.id))) {
                  return (
                    <span key={scenario.id}>
                      <small>{scenario.code + ": "}</small>
                      {currencyFmt.format(datum.costMap[scenario.id] + (datum.costMap[""] ?? 0))}
                    </span>
                  )
                } else {
                  return null
                }
              })}
            </span>
          )
        },

        cellClassname: (selectedDatum, colName, datum) => {
          return "d-flex justify-content-end"
        },
        size: 3,
      },
      {
        label: t("field:task.price"),
        name: "price",
        cellClassname: (selectedDatum, colName, datum) => {
          return classNames("d-flex justify-content-end", {
            [S["before-total"]]: datum.total,
          })
        },
        render: (selectedDatum, colName, datum) => {
          const hasPrice = find(datum.priceMap, (price) => Math.abs(parseFloat(price)) > 0.001)
          if (datum.isLeaf || hasPrice) {
            return (
              <span className="d-inline-flex flex-column align-items-end">
                {datum.priceMap[""] !== undefined && <span>{currencyFmt.format(datum.priceMap[""])}</span>}
                {estimateScenarios.map((scenario) => {
                  if (
                    scenario.id in datum.priceMap &&
                    (selectedScenarios === null || selectedScenarios.includes(scenario.id))
                  ) {
                    return (
                      <span key={scenario.id}>
                        <small>{scenario.code + ": "}</small>
                        {currencyFmt.format(datum.priceMap[scenario.id] + (datum.priceMap[""] ?? 0))}
                      </span>
                    )
                  } else {
                    return null
                  }
                })}
              </span>
            )
          } else {
            return null
          }
        },
        size: 3,
      },
      {
        label: t("field:task.total"),
        name: "total",
        cellClassname: (selectedDatum, colName, datum) => {
          return classNames("d-flex justify-content-end", {
            [S["total"]]: selectedDatum,
          })
        },
        render: (selectedDatum, colName, datum) => {
          if (!selectedDatum) {
            return null
          } else {
            return (
              <span className="d-inline-flex flex-column align-items-end">
                {selectedDatum[""] !== undefined && <span>{currencyFmt.format(selectedDatum[""])}</span>}
                {estimateScenarios.map((scenario) => {
                  if (scenario.id in selectedDatum && (selectedScenarios === null || selectedScenarios.includes(scenario.id))) {
                    return (
                      <span key={scenario.id}>
                        <small>{scenario.code + ": "}</small>
                        {currencyFmt.format(selectedDatum[scenario.id] + (selectedDatum[""] ?? 0))}
                      </span>
                    )
                  } else {
                    return null
                  }
                })}
              </span>
            )
          }
        },
        size: 3,
      },
      {
        label: "",
        name: "actions",
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-center align-items-center w-100">
              <div className={classNames(S["action-icon-container"])}>
                <WpLink
                  onClick={() => onEditTaskClick && onEditTaskClick(datum)}
                  to={{
                    pathname: `/estimates/${estimateId}/tasks`,
                    state: { initialOpen: datum.id },
                  }}
                >
                  <Icon
                    name={readOnly ? "view" : "edit"}
                    title={readOnly ? t("action: see_resource") : t("action:edit_resource")}
                    className="pointer text-dark"
                  />
                </WpLink>
              </div>
            </div>
          )
        },
        size: 1,
        disableCellPadding: true,
      },
    ]
      .filter((c) => {
        if (!isResourceVisible) {
          if (c.name === "resources") {
            return false
          }
        }
        return true
      })
      .filter((c) => {
        if (!isCostVisible) {
          if (c.name === "cost") {
            return false
          }
        }
        return true
      })
  }, [
    currencyFmt,
    depth,
    estimateId,
    estimateScenarios,
    isCostVisible,
    isResourceVisible,
    isScenarioVisible,
    onEditTaskClick,
    readOnly,
    resources,
    selectedScenarios,
    t,
  ])
  return columns
}

export function useColumns(
  isResourceVisible,
  isScenarioVisible,
  resources,
  estimateScenarios,
  selectedScenarios,
  t,
  isDotsVisible,
  depth,
  maxDepth,
  arePricesVisible,
) {
  const currencyFmt = useCurrencyFormatter()

  let titleSize = 9

  if (isResourceVisible) {
    titleSize -= 4
  }
  if (maxDepth > 1) {
    titleSize -= 2
  }

  const columns = useMemo(() => {
    return [
      {
        label: t("field:task.path"),
        name: "path",
        render: (selectedDatum, colName, datum) => {
          const depthLevel = selectedDatum.split(".").length

          return (
            <div className="d-flex flex-row align-items-center">
              <div className="d-flex flex-column justify-content-center align-items-center text-dynamic-theme">
                {depth > 1 &&
                  isDotsVisible &&
                  range(1, depth + 1).map((i) => {
                    return (
                      <svg key={i} width={7} height={7} className={S["depth-dot"]}>
                        <circle cx={3.5} cy={3.5} r={i === depthLevel ? 3.5 : 1.5} fill="currentColor" />
                      </svg>
                    )
                  })}
              </div>
              <span className="pl-3">{selectedDatum}</span>
            </div>
          )
        },
        size: 1,
        visible: true,
      },
      {
        label: t("field:task.title"),
        name: "title",
        cellClassname: (selectedDatum, colName, datum) => {
          if (datum.total && !isResourceVisible && maxDepth === 1) {
            return S["before-total"] + " d-flex justify-content-start"
          }
          return "d-flex justify-content-start"
        },
        render: (selectedDatum, col, datum) => {
          return (
            <div className="d-flex flex-column align-items-start justify-content-center">
              <div>{selectedDatum}</div>
              {isScenarioVisible && (
                <div className="d-flex">
                  {estimateScenarios.map((scenario) => {
                    if (
                      !datum.inferredScenarios.includes(scenario.id) ||
                      !(selectedScenarios === null || selectedScenarios.includes(scenario.id))
                    ) {
                      return null
                    }
                    return (
                      <div className="mr-2">
                        <ScenarioSymbol scenario={scenario} isInferred={datum.inferredOnlyScenarios.includes(scenario.id)} />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )
        },
        size: titleSize,
        visible: true,
      },
      {
        label: t("field:task.resources"),
        name: "resources",
        cellClassname: (selectedDatum, colName, datum) => {
          if (datum.total && isResourceVisible && maxDepth === 1) {
            return S["before-total"] + " d-flex justify-content-start"
          }
          return "d-flex justify-content-start"
        },
        render: (selectedDatum, colName, datum) => (
          <div className="d-flex flex-column w-100">
            <ResourceTable nodeResources={selectedDatum} estimateResources={resources} />
            {datum.fixed_costs.length > 0 && selectedDatum.length > 0 && <Separator className="mx-0 my-3" />}
            <FixedCostTable nodeFixedCosts={datum.fixed_costs} />
          </div>
        ),
        size: 4,
        visible: isResourceVisible,
      },
      {
        label: t("field:task.price"),
        name: "price",
        cellClassname: (selectedDatum, colName, datum) => {
          if (datum.total) {
            return S["before-total"] + " d-flex justify-content-end"
          }
          return "d-flex justify-content-end"
        },
        render: (selectedDatum, colName, datum) => {
          const hasPrice = find(datum.priceMap, (price) => Math.abs(parseFloat(price)) > 0.001)
          if (datum.isLeaf || hasPrice) {
            return (
              <span className="d-inline-flex flex-column align-items-end">
                {datum.priceMap[""] !== undefined && <span>{currencyFmt.format(datum.priceMap[""])}</span>}
                {estimateScenarios.map((scenario) => {
                  if (
                    scenario.id in datum.priceMap &&
                    (selectedScenarios === null || selectedScenarios.includes(scenario.id))
                  ) {
                    return (
                      <span key={scenario.id}>
                        <small>{scenario.code + ": "}</small>
                        {currencyFmt.format(datum.priceMap[scenario.id])}
                      </span>
                    )
                  } else {
                    return null
                  }
                })}
              </span>
            )
          } else {
            return null
          }
        },
        size: 2,
        visible: maxDepth > 1 && arePricesVisible,
      },
      {
        label: t("field:task.total"),
        name: "total",
        cellClassname: (selectedDatum, colName, datum) => {
          if (selectedDatum) {
            return S["total"] + " d-flex justify-content-end"
          }
          return "d-flex justify-content-end"
        },
        render: (selectedDatum, colName, datum) => {
          if (!selectedDatum) {
            return null
          } else {
            return (
              <span className="d-inline-flex flex-column align-items-end">
                {selectedDatum[""] !== undefined && (selectedScenarios === null || selectedScenarios.length === 0 || selectedScenarios.includes(0)) && <span>{currencyFmt.format(selectedDatum[""])}</span>}
                {estimateScenarios.map((scenario) => {
                  if (scenario.id in selectedDatum && (selectedScenarios === null || selectedScenarios.includes(scenario.id))) {
                    return (
                      <span key={scenario.id}>
                        <small>{scenario.code + ": "}</small>
                        {currencyFmt.format(selectedDatum[scenario.id] + selectedDatum[""])}
                      </span>
                    )
                  } else {
                    return null
                  }
                })}
              </span>
            )
          }
        },
        size: 2,
        visible: arePricesVisible,
      },
    ].filter((c) => c.visible)
  }, [arePricesVisible, currencyFmt, depth, estimateScenarios, isDotsVisible, isResourceVisible, isScenarioVisible, maxDepth, resources, selectedScenarios, t, titleSize])
  return columns
}

export function aggregateResources(resourcesList) {
  const groupedResources = groupBy(resourcesList, "priced_resource_id")

  return Object.values(groupedResources).map((group) => {
    return {
      priced_resource_id: group[0].priced_resource_id,
      resource_id: group[0].resource_id,
      cost_unit: group[0].cost_unit,
      cost_unit_en: group[0].cost_unit_en,
      unitary_cost: group[0].unitary_cost,
      unitary_price: group[0].unitary_price,
      size: group.reduce((acc, elem) => acc + parseFloat(elem.size), 0),
    }
  })
}

export function flattenCutTree(tree, maxDepth, scenarios = []) {
  const flattened = flattenTree(tree)

  return flattened
    .filter((elem) => elem.path.split(".").length <= maxDepth)
    .map((elem) => {
      const depthLevel = elem.path.split(".").length
      const newElem = {
        id: elem.id,
        title: elem.title,
        description: elem.description,
        description_rich: elem.description_rich,
        path: elem.path,
        total: depthLevel === 1 ? elem.priceMap : undefined,
        isLeaf: elem.children.length === 0,
        inferredScenarios: elem.inferredScenarios,
        inferredOnlyScenarios: elem.inferredOnlyScenarios,
        costMap: elem.costMap,
        priceMap: elem.priceMap,
      }

      if (depthLevel < maxDepth) {
        newElem.resources = aggregateResources(elem.resources)
        newElem.cost = elem.own_cost
        newElem.price = elem.own_price
        newElem.fixed_costs = elem.fixed_costs
      } else {
        newElem.resources = aggregateResources(
          flatten(
            flattenTree([elem]).map((node) => {
              return node.resources
            })
          )
        )
        newElem.cost = elem.cost
        newElem.price = elem.price
        newElem.fixed_costs = flatten(
          flattenTree([elem]).map((node) => {
            return node.fixed_costs
          })
        )
      }

      return newElem
    })
}

export function flattenCloneTree(tree, flattened = []) {
  tree.forEach((elem) => {
    flattened.push({ ...elem })
    flattenTree(elem.children, flattened)
  })

  return flattened
}

export function flattenTree(tree, flattened = []) {
  tree.forEach((elem) => {
    flattened.push(elem)
    flattenTree(elem.children, flattened)
  })

  return flattened
}

function cutTreeHelper(node, maxDepth, currentDepth) {
  if (currentDepth === maxDepth) {
    return { ...node, children: [] }
  } else {
    return {
      ...node,
      children: node.children.map((child) => {
        return cutTreeHelper(child, maxDepth, currentDepth + 1)
      }),
    }
  }
}

export function cutTree(tree, maxDepth) {
  return cutTreeHelper({ children: tree }, maxDepth, 0)
}

import useDebounceCallback from "magik-react-hooks/useDebounceCallback"
import React, { useCallback, useState } from "react"
import WpInput from "./WpInput"

export default function AutoDebWpInput({
  name, 
  value,
  onChange,
  onFocus = undefined,
  onBlur = undefined,
  ...props
}) {
  const [shadow, setShadow] = useState(null)
  const [hasFocus, setFocus] = useState(false)

  const performSave = useDebounceCallback(
    (onChange, arg) => {
      onChange({ target: { name, value: arg } })
    },
    500,
    []
  )

  const handleTyping = useCallback(
    (e) => {
      setShadow(e.target.value)
      performSave(onChange, e.target.value)
    },
    [onChange, performSave]
  )

  const handleFocus = useCallback(
    (e) => {
      setFocus(true)
      if (typeof onFocus === "function") {
        onFocus(e)
      }
    },
    [onFocus]
  )

  const handleBlur = useCallback(
    (e) => {
      setFocus(false)
      if (shadow !== null) {
        onChange(shadow)
        setShadow(null)
      }
      if (typeof onBlur === "function") {
        onBlur(e)
      }
    },
    [onBlur, onChange, shadow]
  )

  return (
    <WpInput
      value={hasFocus ? shadow ?? value : value}
      onChange={handleTyping}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...props}
    />
  )
}

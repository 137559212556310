import dayjs from "dayjs"
import { chunk, range } from "lodash"

export function makeStartDate(year, month) {
  const yearCode = year.toString()
  const monthCode = month.toString().padStart(2, "0")
  return `${yearCode}-${monthCode}-01`
}

export function makeEndDate(year, month) {
  const yearCode = year.toString()
  const monthCode = month.toString().padStart(2, "0")
  return dayjs(`${yearCode}-${monthCode}-15`).endOf("month").format("YYYY-MM-DD")
}

export function makeDate(year, month, day) {
  const yearCode = year.toString()
  const monthCode = month.toString().padStart(2, "0")
  const dayCode = day.toString().padStart(2, "0")
  return `${yearCode}-${monthCode}-${dayCode}`
}

export function makeCalendarMatrix(year, month, keepSixWeeks = false) {
  const firstDateInMonth = dayjs(makeStartDate(year, month))
  const days = range(1, firstDateInMonth.daysInMonth() + 1)
  const emptyBeginning = new Array((firstDateInMonth.get("day") + 6) % 7).fill(0)
  const tailSize = 7 - (days.length + emptyBeginning.length) % 7
  const emptyEnd_fit = tailSize === 7 ? [] : new Array(tailSize).fill(0)
  const emptyEnd_fill = new Array(42 - (days.length + emptyBeginning.length)).fill(0)
  const emptyEnd = keepSixWeeks ? emptyEnd_fill : emptyEnd_fit
  return chunk([].concat(emptyBeginning, days, emptyEnd), 7)
}

export function capitalize(str) {
  return str[0].toUpperCase() + str.substr(1)
}

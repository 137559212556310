import { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import S from "./LongBorder.module.scss"

/**
 *
 * @param {{ topBar: "none" | "toolbar" | "tabbar"}} props
 */
export default function LongBorder({ topBar = "none" }) {
  const domRef = useRef()
  const [threshold, setThreshold] = useState(0)

  const [showLongBorder, setShowLongBorder] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const nextThreshold = domRef.current.getBoundingClientRect().top - domRef.current.parentNode.getBoundingClientRect().top
    if (nextThreshold !== threshold) {
      setThreshold(nextThreshold)
      if (document.documentElement.scrollTop > nextThreshold && !showLongBorder) {
        setShowLongBorder(true)
      } else if (document.documentElement.scrollTop <= nextThreshold && showLongBorder) {
        setShowLongBorder(false)
      }
    }
  })

  useEffect(() => {
    const handler = () => {
      if (document.documentElement.scrollTop > threshold) {
        setShowLongBorder(true)
      } else {
        setShowLongBorder(false)
      }
    }
    window.addEventListener("scroll", handler)
    return () => {
      window.removeEventListener("scroll", handler)
    }
  }, [threshold])

  return (
    <>
      <div ref={domRef} />
      <div
        className={classNames(S["long-border"], {
          [S["hidden"]]: !showLongBorder,
          [S["with-toolbar"]]: topBar === "toolbar",
          [S["with-tabbar"]]: topBar === "tabbar"
        })}
      />
    </>
  )
}
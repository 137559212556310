import api from "../api"

function normalizeUserOrgs(user) {
  const { organizations, ...userData } = user
  return {
    ...userData,
    ...organizations.reduce(
      (normalizedOrgs, org) => ({
        organizationsIds: normalizedOrgs.organizationsIds.concat(org.id),
        organizationsById: {
          ...normalizedOrgs.organizationsById,
          [org.id]: org,
        },
      }),
      {
        organizationsIds: [],
        organizationsById: {},
      }
    ),
  }
}

export const meCall = (token) =>
  api
    .auth({ token })
    .mapResponse((r) => normalizeUserOrgs(r.response))
    .get("/api/me")

const apiMapToken = api.mapResponse((r) => ({
  accessToken: r.response.token,
}))

export const loginCall = ({ provider, email, password, socialToken, joinToken, lang }) => {
  if (provider === "__wooplan") {
    return Promise.resolve({ accessToken: socialToken })
  } else if (provider === "registration") {
    return apiMapToken.post("/api/register/", {
      email,
      password,
      join_token: joinToken,
      lang,
    })
  } else if (provider !== "password") {
    return apiMapToken.post("/api/login/social/", {
      provider,
      social_token: socialToken,
      join_token: joinToken,
      lang,
    })
  } else {
    return apiMapToken.post("/api/login/credentials/", {
      email,
      password,
      join_token: joinToken,
    })
  }
}

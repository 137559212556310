import React from "react"
import RichTextEditor from "../RichTextEditor/RichTextEditor"
import classNames from "classnames"
import S from "./FieldRichText.module.scss"

export default function FieldRichText({
  field,
  form: { setFieldValue },
  label,
  placeholder = undefined,
  readOnly = false,
  readOnlyPlaceholder = undefined,
  ...props
}) {
  return (
    <div className="d-flex flex-column">
      {label && (
        <label className="font-weight-semibold mb-3 h3 text-uppercase">
          {label}
        </label>
      )}
      <div
        className={classNames(S["editor-area"], {
          [S["editor-disabled"]]: readOnly,
        })}
      >
        <RichTextEditor
          initialContent={field.value}
          save={(data) => setFieldValue(field.name, data)}
          wrapperClassName={S["wrapper"]}
          editorClassName={classNames(S["text-area"], {
            [S["text-disabled"]]: readOnly,
          })}
          placeholder={readOnly ? readOnlyPlaceholder : placeholder}
          readOnly={readOnly}
          {...props}
        />
      </div>
    </div>
  )
}

import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import Layout from "../../components/Layout"
import Button from "../../components/Button"
import Loader from "../../components/Loader/Loader"
import S from "./VerifyEmail.module.scss"
import { useTranslation } from "react-i18next"
import { useProfile } from "../../hooks/profile"

export default function VerifyEmail() {
  const history = useHistory()
  const { token } = useParams()

  const { t } = useTranslation(["translation", "action"])
  const [outcome, setOutcome] = useState("unknown")

  const [{ profile }, { verifyEmail }] = useProfile()

  useEffect(() => {
    if (profile.email_verified === false) {
      verifyEmail
        .onSuccess(() => {
          setOutcome("done")
        })
        .onFailure(() => {
          setOutcome("error")
        })
        .run(token)
    }
  }, [profile.email_verified, token, verifyEmail])

  if (outcome === "unknown") {
    return (
      <Layout>
        <Loader />
      </Layout>
    )
  }

  if (outcome === "error") {
    return (
      <Layout>
        <div className="container">
          <div className={S.panel}>
            <p>{t("email-verification.verify-error")}</p>
            <div className="mt-8">
              <Button className="mx-8" onClick={() => history.push(`/`)}>
                {t("action:go_home")}
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

  if (outcome === "done") {
    return (
      <Layout>
        <div className="container">
          <div className={S.panel}>
            <p>{t("email-verification.verify-success")}</p>
            <div className="mt-8">
              <Button className="mx-8" onClick={() => history.push(`/`)}>
                {t("action:go_home")}
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

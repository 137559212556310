import { useCallback, useMemo } from "react"
import { deps, rj, useRunRj } from "react-rocketjump"
import { useAuthActions, useAuthUser } from "use-eazy-auth"
import api from "../api"

const OrganizationDetailState = rj({
  name: "OrganizationDetail",
  effectCaller: rj.configured(),
  mutations: {
    updateOrganization: {
      effect: (wpAuth) => (id, orgData) =>
        api.auth(wpAuth).put(`/api/organization/${id}`, orgData),
      updater: "updateData",
    },
    inviteMembers: {
      effect: (wpAuth) => (id, invitations) =>
        api.auth(wpAuth).post(`/api/organization/${id}/invite`, invitations),
      updater: (state, invitations) => ({
        ...state,
        data: {
          ...state.data,
          invitations: state.data.invitations.concat(invitations),
        },
      }),
    },
    updateMember: {
      effect: (wpAuth) => (orgId, memberId, updates) =>
        api
          .auth(wpAuth)
          .patch(`/api/organization/${orgId}/members/${memberId}`, updates),
      updater: (state, member) => {
        const nextMembers =
          state?.data?.members?.map((item) =>
            item.id === member.id ? member : item
          ) ?? []
        return {
          ...state,
          data: {
            ...state.data,
            members: nextMembers,
            members_count: nextMembers.length,
          },
        }
      },
    },
    removeMember: {
      effect: (wpAuth) => (orgId, memberId) =>
        api
          .auth(wpAuth)
          .mapResponse(r => ({...r.response, deletedMember: memberId }))
          .delete(`/api/organization/${orgId}/members/${memberId}`),
      updater: (state, result) => {
        const nextMembers =
          state?.data?.members?.filter((item) => item.id !== result.deletedMember) ?? []
        return {
          ...state,
          data: {
            ...state.data,
            members: nextMembers,
            members_count: nextMembers.length,
          },
        }
      },
    },
    updateInvitation: {
      effect: (wpAuth) => (orgId, invitationId, role) =>
        api
          .auth(wpAuth)
          .patch(`/api/organization/${orgId}/invitations/${invitationId}/`, {
            role,
          }),
      updater: (state, invitation) => ({
        ...state,
        data: {
          ...state.data,
          invitations: state.data.invitations.map((item) =>
            item.id !== invitation.id ? item : invitation
          ),
        },
      }),
    },
    cancelInvitation: {
      effect: (wpAuth) => (orgId, invitationId) =>
        api
          .auth(wpAuth)
          .mapResponse(() => invitationId)
          .delete(`/api/organization/${orgId}/invitations/${invitationId}/`),
      updater: (state, deleted) => ({
        ...state,
        data: {
          ...state.data,
          invitations: state.data.invitations.filter(
            (item) => item.id !== deleted
          ),
        },
      }),
    },
  },
  effect: (wpAuth) => (id) => api.auth(wpAuth).get(`/api/organization/${id}`),
  computed: {
    organization: "getData",
  },
})

export function useOrganizationDetail(id) {
  const [state, actions] = useRunRj(OrganizationDetailState, [id])
  const { user } = useAuthUser()
  const { updateUser } = useAuthActions()

  const syncUpdatedOrg = useCallback(
    (org) => {
      updateUser({
        ...user,
        organizationsById: {
          ...user.organizationsById,
          [org.id]: {
            ...user.organizationsById[org.id],
            ...org,
            members_count: org.members_count ?? org.members.length,
          },
        },
      })
    },
    [updateUser, user]
  )

  const curryActions = useMemo(
    () => ({
      ...actions,
      updateOrganization: actions.updateOrganization
        .onSuccess(syncUpdatedOrg)
        .curry(),
      removeMember: actions.removeMember.onSuccess(syncUpdatedOrg).curry(),
    }),
    [actions, syncUpdatedOrg]
  )

  return useMemo(() => [state, curryActions], [state, curryActions])
}

const JoinOrganizationState = rj({
  name: "JoinOrganization",
  effectCaller: rj.configured(),
  mutations: {
    joinOrganization: {
      updater: (s) => s,
      effect: (wpAuth) => (token) =>
        api.auth(wpAuth).post("/api/organization/join_from_invitation", {
          token,
        }),
    },
    dismissInvitation: {
      updater: (s) => s,
      effect: (wpAuth) => (token) =>
        api.auth(wpAuth).post("/api/organization/dismiss_invitation", {
          token,
        }),
    },
  },
  effect: (wpAuth) => (token) =>
    (wpAuth ? api.auth(wpAuth) : api).post(
      "/api/organization/validate_invitation",
      {
        token,
      }
    ),
})

export function useJoinOrganization(token) {
  const { user } = useAuthUser()
  const { updateUser } = useAuthActions()

  const [state, actions] = useRunRj(JoinOrganizationState, [deps.maybe(token)])

  const syncJoinedOrg = useCallback(
    (payload) => {
      updateUser({
        ...user,
        organizationsIds: user.organizationsIds.concat(payload.organization.id),
        organizationsById: {
          ...user.organizationsById,
          [payload.organization.id]: payload.organization,
        },
      })
    },
    [updateUser, user]
  )

  const curryActions = useMemo(
    () => ({
      ...actions,
      joinOrganization: actions.joinOrganization
        .onSuccess(syncJoinedOrg)
        .curry(token),
      dismissInvitation: actions.dismissInvitation.curry(token),
    }),
    [actions, syncJoinedOrg, token]
  )

  return [state, curryActions]
}

import { Field } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import FieldSwitch from "../../../../../components/fields/FieldSwitch"
import EditDataFrame, {
  EditDataSection,
} from "../../EditDataFrame/EditDataFrame"

export const EditDataForm = ({
  fullEstimate,
  frag,
  save,
  remove,
  toggle,
  scrollFragIntoView,
}) => {
  const { t } = useTranslation(["translation"])
  const readOnly = fullEstimate.readonly

  return (
    <EditDataFrame
      contentType={t("documents.frag.scenario.title")}
      frag={frag}
      save={save}
      remove={remove}
      toggle={toggle}
      scrollFragIntoView={scrollFragIntoView}
      enableScenarioSelector={false}
      readOnly={readOnly}
    >
      <EditDataSection title={t("field:document.scenario_list.scenarios")}>
        <div>
          <Field
            name="data.showBaseScenario"
            message={t(
              "field:document.scenario_list.show_scenario_base_message"
            )}
            component={FieldSwitch}
            readOnly={readOnly}
          />
        </div>
        <div className="mt-4">
          <Field
            name="data.showDescription"
            message={t("field:document.scenario_list.show_description_message")}
            component={FieldSwitch}
            readOnly={readOnly}
          />
        </div>
        <div className="mt-4">
          <Field
            name="data.showPrice"
            message={t("field:document.scenario_list.show_price_message")}
            component={FieldSwitch}
            readOnly={readOnly}
          />
        </div>
      </EditDataSection>
    </EditDataFrame>
  )
}

export default EditDataForm

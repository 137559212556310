import React, { useCallback } from "react"
import { ConfigureRj } from "react-rocketjump"
import EazyAuth, { useAuthActions } from "use-eazy-auth"
import { ProvideCapabilities } from "../hooks/capabilities"
import useCurrentOrganization from "../hooks/useCurrentOrganization"
import { loginCall, meCall } from "./authCalls"

function InnerAuth({ children }) {
  const { callAuthApiObservable } = useAuthActions()
  const organization = useCurrentOrganization()
  const organizationId = organization?.id ?? null

  const withOrgAuthCaller = useCallback(
    (effectFn, ...args) => callAuthApiObservable((token) => effectFn(token ? { token, organizationId } : null), ...args),
    [callAuthApiObservable, organizationId]
  )

  return <ConfigureRj effectCaller={withOrgAuthCaller}>{children}</ConfigureRj>
}

export default function WpAuth({ children }) {
  return (
    <EazyAuth loginCall={loginCall} meCall={meCall}>
      <InnerAuth>
        <ProvideCapabilities>{children}</ProvideCapabilities>
      </InnerAuth>
    </EazyAuth>
  )
}

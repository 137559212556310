import React from "react"

export function LinearConnection(props) {
  return (
    <svg width={24} height={24} {...props} viewBox="0 0 24 24">
      <rect
        x={0.5}
        y={17}
        width={4}
        height={4}
        stroke="currentColor"
        strokeWidth={0.5}
        fill="none"
      />
      <rect
        x={19}
        y={2.5}
        width={4}
        height={4}
        stroke="currentColor"
        strokeWidth={0.5}
        fill="none"
      />
      <line
        x1={4.5}
        y1={17}
        x2={19}
        y2={6.5}
        stroke="currentColor"
        strokeWidth={0.5}
        fill="none"
      />
    </svg>
  )
}

export function StepConnection(props) {
  return (
    <svg width={24} height={24} {...props} viewBox="0 0 24 24">
      <rect
        x={0.5}
        y={17}
        width={4}
        height={4}
        stroke="currentColor"
        strokeWidth={0.5}
        fill="none"
      />
      <rect
        x={19}
        y={2.5}
        width={4}
        height={4}
        stroke="currentColor"
        strokeWidth={0.5}
        fill="none"
      />
      <path d="M2.5 17l0 -5.25l18.5 0l0 -5.25" strokeWidth={0.5} stroke={"currentColor"} fill="none" />
    </svg>
  )
}

export function BezierConnection(props) {
  return (
    <svg width={24} height={24} {...props} viewBox="0 0 24 24">
      <rect
        x={0.5}
        y={17}
        width={4}
        height={4}
        stroke="currentColor"
        strokeWidth={0.5}
        fill="none"
      />
      <rect
        x={19}
        y={2.5}
        width={4}
        height={4}
        stroke="currentColor"
        strokeWidth={0.5}
        fill="none"
      />
      <path d="M4.5 19c7.25 0 7.25 0 7.25 -7.25s0 -7.25 7.25 -7.25" strokeWidth={0.5} stroke={"currentColor"} fill="none" />
    </svg>
  )
}
import { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import Button from "../../../components/Button"
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import { CreateContactModal } from "../../../components/CreateContactModal"
import { CRMLandmarks } from "../../../components/CRMLandmarks"
import Icon from "../../../components/Icon"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import Table from "../../../components/Table"
import { CustomerContext } from "../../../context"
import { useContacts } from "../../../hooks/contacts"
import useModalTrigger from "../../../hooks/useModalTrigger"
import CustomerBottomBarContent from "../CustomerBottomBarContent"
import CustomerTabBarContent from "../CustomerTabBarContent"

export default function CustomerContacts() {
  const { t } = useTranslation(["translation", "tab", "action"])

  const [{ customer }] = useContext(CustomerContext)

  const contactsFiltersDefault = useMemo(
    () => ({
      customer: customer.id,
    }),
    [customer.id]
  )

  const filters = contactsFiltersDefault

  const [{ contacts }, { create, update, remove, run: reload }] = useContacts(
    filters
  )

  const [editModalState, editModalActions] = useModalTrigger()
  const [deleteModalState, deleteModalActions] = useModalTrigger()

  const columns = useMemo(
    () => [
      {
        label: t("field:contact.surname"),
        name: "surname",
        size: 4,
      },
      {
        label: t("field:contact.name"),
        name: "name",
        size: 4,
      },
      {
        label: t("field:contact.role"),
        name: "role",
        size: 5,
      },
      {
        label: t("field:contact.phone"),
        name: "phone",
        size: 4,
        render: (selectedDatum, colName, datum) => {
          const phone1 = datum.phone1
          const phone2 = datum.phone2
          if (phone1 && phone2) {
            return (
              <span>
                <span>{phone1}</span>
                <span>{" / "}</span>
                <span>{phone2}</span>
              </span>
            )
          } else {
            return <span>{phone1 || phone2}</span>
          }
        },
      },
      {
        label: t("field:contact.email"),
        name: "email",
        size: 5,
      },
      {
        label: t("actions"),
        name: "actions",
        size: 2,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <span
                className={"action-icon-container-primary-hover"}
                onClick={() => editModalActions.open(datum)}
              >
                <Icon
                  name="edit"
                  title={t("action:edit_contact")}
                  className="pointer text-dark"
                />
              </span>
              <div className={"action-icon-container-primary-hover"}>
                <UncontrolledDropdown>
                  <DropdownToggle caret={false} tag={"span"}>
                    <Icon
                      role="button"
                      name="vdots"
                      className="text-dark pointer"
                      title={t("action:other_options")}
                      placement="right"
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    right
                    modifiers={{ offset: { offset: "0, 12" } }}
                    className="border-primary"
                  >
                    <DropdownItem
                      className={
                        "text-capitalize px-0 dropdown-item-primary-active"
                      }
                    >
                      <div
                        className="d-flex flex-row align-items-center mx-4"
                        onClick={() => {
                          deleteModalActions.open(datum)
                        }}
                      >
                        <Icon name="delete" className="pointer mr-4" />
                        <span>{t("action:delete")}</span>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )
        },
      },
    ],
    [deleteModalActions, editModalActions, t]
  )

  return (
    <Layout>
      <div className="container-fluid pt-5 px-page pb-page-bottom">
        {!contacts && <Loader />}
        {customer && (
          <>
            <h1 className="text-primary font-weight-semibold mt-8">
              {t("customer.contacts")}
            </h1>
            <div className="d-flex flex-row justify-content-end align-items-center mb-8">
              <Button
                onClick={() => {
                  editModalActions.open("dummy")
                }}
              >
                {t("action:new_contact")}
              </Button>
            </div>
            <Table
              use24
              columns={columns}
              data={contacts}
              className="pb-8"
              highlightRows={[]}
            />
          </>
        )}
      </div>
      {editModalState.value && (
        <CreateContactModal
          isOpen={editModalState.isOpen}
          onClosed={editModalActions.onClose}
          toggle={editModalActions.close}
          onSave={(result) => {
            return (result.id ? update.curry(result.id) : create)
              .onSuccess(() => {
                editModalActions.close()
                reload(filters)
              })
              .asPromise({ ...result, customer: customer.id })
          }}
          contact={
            typeof editModalState.value === "object"
              ? editModalState.value
              : null
          }
        />
      )}
      {deleteModalState.value && (
        <ConfirmDeleteModal
          toggle={deleteModalActions.toggle}
          isOpen={deleteModalState.isOpen}
          onConfirm={() => {
            remove
              .onSuccess(() => {
                reload(filters)
                deleteModalActions.close()
              })
              .run(deleteModalState.value.id)
          }}
          onClosed={deleteModalActions.onClose}
          item={
            deleteModalState.value.surname + " " + deleteModalState.value.name
          }
        />
      )}
      <Layout.TabBar>
        <CustomerTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <CustomerBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <CRMLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import WpInput from "../../../components/WpInput/WpInput"
import WpModal from "../../../components/WpModal"

export default function ConfirmLeaveModal({
  isOpen,
  toggle,
  onConfirm,
  item,
  onClosed,
  message = "",
  challenge = "",
}) {
  const { t } = useTranslation(["translation"])
  const [challengeInput, setChallengeInput] = useState("")

  return (
    <WpModal
      isOpen={isOpen}
      toggle={toggle}
      title={t("confirm_leave.title")}
      onClosed={onClosed}
    >
      <div className="d-flex flex-column">
        {message === "" && (
          <span style={{ userSelect: "none" }}>
            {t("message.leave_item", { item: item })}
          </span>
        )}
        {message !== "" && <span>{message}</span>}
        <span className="py-4">{t("message.leave_confirm")}</span>
        {challenge !== "" && (
          <div className="d-flex flex-column pb-5">
            <span className="py-4" style={{ userSelect: "none" }}>
              {t("message.challenge_leave", {challenge: challenge })}
            </span>
            <WpInput
              invalid={challengeInput !== challenge}
              onChange={(e) => setChallengeInput(e.target.value)}
            />
          </div>
        )}
        <div className="d-flex flex-row align-items-center justify-content-end">
          <Button size="sm" color="none" onClick={toggle}>
            {t("action:cancel")}
          </Button>
          <Button
            size="sm"
            color="dark"
            className="ml-8"
            disabled={challenge !== challengeInput}
            onClick={() => {
              onConfirm()
              toggle()
            }}
          >
            {t("action:confirm")}
          </Button>
        </div>
      </div>
    </WpModal>
  )
}

import React from "react"
import classNames from "classnames"
import X from "./style.module.scss"
import { getCurrencyFormatter } from "../../hooks/useCurrencyFormatter"
import { keyBy, uniq } from "lodash"

export default function ProjectInsightActivityTable({
  activityAggregates: data,
  showSubjects,
}) {
  const usersMap = keyBy(data?.users_data, "id")
  const resourcesMap = keyBy(data?.resources_data, "id")
  const currencies = uniq(data?.resources_data.map((d) => d.currency) ?? [])
  return (
    <div className={classNames(X["table"])}>
      {data.by_resource.map(
        ({ priced_resource: resourceId, sum, total }, i) => {
          const resource = resourcesMap[resourceId]
          const formatter = getCurrencyFormatter(resource.currency)
          const children = data.by_subject_and_resource.filter(
            (record) => record.priced_resource === resourceId
          )
          return (
            <div key={i}>
              <div>
                <div>{resource.resource_name}</div>
                <div>{resource.cost_unit}</div>
                <div>{sum}</div>
                <div>{formatter.format(total)}</div>
              </div>
              {showSubjects &&
                children.map((child) => (
                  <div key={child.subject}>
                    <div>
                      {usersMap[child.subject].full_name ||
                        usersMap[child.subject].email}
                    </div>
                    <div />
                    <div>{child.sum}</div>
                    <div>{formatter.format(child.total)}</div>
                  </div>
                ))}
            </div>
          )
        }
      )}
      {currencies.map((curr) => {
        const formatter = getCurrencyFormatter(curr)
        return (
          <div key={curr}>
            <div>
              <div></div>
              <div></div>
              <div></div>
              <div>
                <b>
                  {formatter.format(
                    data.by_resource
                      .filter(
                        (item) =>
                          resourcesMap[item.priced_resource].currency === curr
                      )
                      .reduce((acc, curr) => acc + curr.total, 0)
                  )}
                </b>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

import dayjs from "dayjs"
import { truncate } from "lodash"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import Button from "../../../components/Button"
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import { CreateIncomingRequestModal } from "../../../components/CreateIncomingRequestModal"
import { CRMLandmarks } from "../../../components/CRMLandmarks"
import { DropdownItemCreateInteraction } from "../../../components/DropdownItemCreateInteraction"
import { DropdownItemDelete } from "../../../components/DropdownItemDelete"
import Icon from "../../../components/Icon"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader"
import LongBorder from "../../../components/LongBorder"
import Pagination from "../../../components/Pagination/Pagination"
import Separator from "../../../components/Separator"
import Table from "../../../components/Table"
import WpLink from "../../../components/WpLink"
import { CustomerContext } from "../../../context"
import { useIncomingRequests } from "../../../hooks/incomingRequest"
import useModalTrigger from "../../../hooks/useModalTrigger"
import CustomerBottomBarContent from "../CustomerBottomBarContent"
import CustomerTabBarContent from "../CustomerTabBarContent"

export function CustomerRequests() {
  const { t } = useTranslation(["translation", "tab", "action"])

  const [{ customer }] = useContext(CustomerContext)
  const [createModalState, createModalActions] = useModalTrigger()
  const [deleteModalState, deleteModalActions] = useModalTrigger()

  const [
    { page },
    setQueryParams,
    { page: debPage, status: debStatus },
  ] = useQueryParams({
    page: {
      encode: (intValue) => intValue.toString(10),
      decode: (strValue) => (strValue ? parseInt(strValue, 10) : 1),
    },
  })

  const filter = useMemo(
    () => ({
      customer: customer.id,
      page: debPage,
      status: debStatus,
    }),
    [customer.id, debPage, debStatus]
  )

  const [
    { requests, count, pageCount, hasNext, hasPrev },
    { create, run: reload, remove },
  ] = useIncomingRequests(filter)

  const columns = useMemo(
    () => [
      {
        label: t("field:incoming_request.created_at"),
        name: "created_at",
        size: 1,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-column h-100 w-100 align-items-start justify-content-center">
              {dayjs(selectedDatum).format("DD/MM/YYYY")}
            </div>
          )
        },
      },
      {
        label: t("field:incoming_request.code"),
        name: "code",
        size: 3,
      },
      {
        label: t("field:incoming_request.title"),
        name: "title",
        size: 5,
      },
      {
        label: t("field:incoming_request.status"),
        name: "status",
        size: 2,
        render: (selectedDatum, colName, datum) => {
          return (
            <span>{t(`enums:incoming_request_status.${selectedDatum}`)}</span>
          )
        },
      },
      {
        label: t("actions"),
        name: "actions",
        size: 1,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <WpLink
                className={"action-icon-container-primary-hover"}
                to={`/customers/${customer.id}/requests/${datum.id}`}
              >
                <Icon
                  name="edit"
                  title={t("action:edit_incoming_request")}
                  className="pointer text-dark"
                />
              </WpLink>
              <div className={"action-icon-container-primary-hover"}>
                <UncontrolledDropdown>
                  <DropdownToggle caret={false} tag={"span"}>
                    <Icon
                      role="button"
                      name="vdots"
                      className="text-dark pointer"
                      title={t("action:other_options")}
                      placement="right"
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    right
                    modifiers={{ offset: { offset: "0, 12" } }}
                    className="border-primary"
                  >
                    <DropdownItemCreateInteraction
                      initialValues={{
                        request: datum.id,
                        customer: datum.customer,
                      }}
                      lockedFields={["request"]}
                    />
                    <Separator className="my-0 mx-4 border-primary" />
                    <DropdownItemDelete
                      onClick={() => {
                        deleteModalActions.open(datum)
                      }}
                    />
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )
        },
      },
    ],
    [customer.id, deleteModalActions, t]
  )

  return (
    <Layout>
      <div className="container-fluid pt-5 px-page pb-page-bottom">
        {!customer && <Loader />}
        {customer && (
          <>
            <div className="d-flex flex-row justify-content-between align-items-end my-8">
              <h1 className="text-primary font-weight-semibold">
                {t("customer_incoming_requests.title", { name: customer.name })}
              </h1>
              <Button onClick={() => createModalActions.open("dummy")}>
                {t("action:new_incoming_request")}
              </Button>
            </div>
            {requests && count > 0 && (
              <>
                <LongBorder topBar="toolbar" />

                <Table
                  columns={columns}
                  data={requests}
                  className="pb-8"
                  highlightRows={[]}
                />

                <Pagination
                  current={page}
                  pageCount={pageCount}
                  goToPage={(page) => {
                    setQueryParams({ page })
                    window.scrollTo(0, 10)
                  }}
                  hasNext={hasNext}
                  hasPrev={hasPrev}
                />
              </>
            )}
          </>
        )}
      </div>
      {createModalState.value && (
        <CreateIncomingRequestModal
          isOpen={createModalState.isOpen}
          onClosed={createModalActions.onClose}
          toggle={createModalActions.toggle}
          onSave={(result) => {
            return create
              .onSuccess(() => {
                createModalActions.close()
                reload(filter)
              })
              .asPromise({ ...result, customer: customer.id })
          }}
        />
      )}
      {deleteModalState.value && (
        <ConfirmDeleteModal
          toggle={deleteModalActions.toggle}
          isOpen={deleteModalState.isOpen}
          onConfirm={() => {
            remove
              .onSuccess(() => {
                reload(filter)
                deleteModalActions.close()
              })
              .run(deleteModalState.value.id)
          }}
          onClosed={deleteModalActions.onClose}
          item={truncate(deleteModalState.value.title, { length: 20 })}
        />
      )}
      <Layout.TabBar>
        <CustomerTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <CustomerBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <CRMLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import { flatten, groupBy, keyBy, map, max, sum } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import ReactResizeDetector from "react-resize-detector"
import FilterButton from "../../../../components/FilterButton"
import useCurrentEstimateOption from "../../../../hooks/useCurrentEstimateOption"
import {
  aggregateResources,
  flattenCloneTree,
} from "../../EstimateTasksTree/TaskTable/utils"
import { adaptForScenarios, markForScenarios } from "../../treeOperations"
import ScenarioResourceViz from "./ScenarioResourceViz"
import ScenarioTaskViz from "./ScenarioTaskViz"

const mapResources = (estimate) => {
  const flattenedTree = flattenCloneTree(estimate.task_tree)

  const flattenedResources = flatten(
    flattenedTree.map((node) => node.resources)
  )

  const aggregatedResources = aggregateResources(flattenedResources)

  const groupedByIdResources = groupBy(aggregatedResources, "resource_id")

  const resourcesById = keyBy(estimate.resources, "id")

  return map(groupedByIdResources, (resource) => {
    return {
      resource_id: resource[0].resource_id,
      name: resourcesById[resource[0].resource_id].name,
      total_price: sum(
        resource
          .map((r) => parseFloat(r.unitary_price) * parseFloat(r.size))
          .filter((n) => !isNaN(n))
      ),
      resource_type: resourcesById[resource[0].resource_id].resource_type,
    }
  })
}

const calcTotalFixedCosts = (estimate) => {
  const flattenedTree = flattenCloneTree(estimate.task_tree)

  const flattenedCosts = flatten(flattenedTree.map((node) => node.fixed_costs))

  return sum(flattenedCosts.map((cost) => parseFloat(cost.price)))
}

export default function EstimateScenarioViz({ estimate, fullEstimate }) {
  const { t } = useTranslation(["translation", "enums"])
  const [type, setType] = useCurrentEstimateOption("viz.scenario.type", "task")
  const [isNormalized, setNormalized] = useCurrentEstimateOption(
    "viz.scenario.isNormalized",
    false
  )
  const [isPriceVisible, setPriceVisible] = useCurrentEstimateOption(
    "viz.scenario.isPriceVisible",
    false
  )
  const [isBaseVisible, setBaseVisible] = useCurrentEstimateOption(
    "viz.scenario.isBaseVisible",
    true
  )
  const adaptedScenarios =
    isNormalized && type === "task"
      ? [
          {
            estimate: markForScenarios(fullEstimate, []),
            scenario: null,
          },
          ...estimate.scenarios.map((scenario) => {
            return {
              estimate: markForScenarios(fullEstimate, [scenario.id]),
              scenario: scenario,
            }
          }),
        ]
      : [
          {
            estimate: adaptForScenarios(fullEstimate, []),
            scenario: null,
            mappedResources: mapResources(adaptForScenarios(fullEstimate, [])),
            totalFixedCosts: calcTotalFixedCosts(
              adaptForScenarios(fullEstimate, [])
            ),
          },
          ...estimate.scenarios.map((scenario) => {
            const estimate = adaptForScenarios(fullEstimate, [scenario.id])
            return {
              estimate: estimate,
              scenario: scenario,
              mappedResources: mapResources(estimate),
              totalFixedCosts: calcTotalFixedCosts(estimate),
            }
          }),
        ]

  const maxTaskPrice = max(
    adaptedScenarios.map((adapted) => parseFloat(adapted.estimate.price))
  )

  const maxResourcePrice =
    isNormalized && type === "task"
      ? 0
      : max(
          adaptedScenarios.map((adapted) =>
            parseFloat(
              sum(
                adapted.mappedResources.map((resource) =>
                  parseFloat(resource.total_price)
                )
              ) + parseFloat(adapted.totalFixedCosts)
            )
          )
        )

  return (
    <div>
      <div className="mt-8">
        <p>{t("tasks.scenario_viz_description")}</p>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-row align-items-center">
            <FilterButton
              onClick={() => setType("task")}
              className={"mr-4"}
              selected={type === "task"}
            >
              {t("enums:scenario_viz_type.task")}
            </FilterButton>
            <FilterButton
              onClick={() => setType("resource")}
              className={"mr-4"}
              selected={type === "resource"}
            >
              {t("enums:scenario_viz_type.resource")}
            </FilterButton>
          </div>
          <div className="d-flex flex-row align-items-center">
            {type === "task" && (
              <>
                <input
                  type="checkbox"
                  checked={isNormalized}
                  onChange={(e) => setNormalized(e.target.checked)}
                />
                <label className="mb-0 mx-3">
                  {t("tasks.show_normalized")}
                </label>
              </>
            )}
            <input
              type="checkbox"
              checked={isBaseVisible}
              onChange={(e) => setBaseVisible(e.target.checked)}
            />
            <label className="mb-0 mx-3">{t("tasks.show_base")}</label>
            <input
              type="checkbox"
              checked={isPriceVisible}
              onChange={(e) => setPriceVisible(e.target.checked)}
            />
            <label className="mb-0 mx-3">{t("tasks.show_price")}</label>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-8">
          <div className="flex-4">
            <ReactResizeDetector handleWidth>
              {({ width }) => (
                <div>
                  {type === "task" && (
                    <>
                      {adaptedScenarios
                        .filter(
                          (elem) =>
                            (isBaseVisible && elem.scenario === null) ||
                            elem.scenario !== null
                        )
                        .map((adapted) => (
                          <ScenarioTaskViz
                            estimate={adapted.estimate}
                            scenario={adapted.scenario}
                            width={width}
                            maxPrice={
                              isNormalized
                                ? parseFloat(fullEstimate.price)
                                : maxTaskPrice
                            }
                            isPriceVisible={isPriceVisible}
                            isNormalized={isNormalized}
                          />
                        ))}
                    </>
                  )}

                  {type === "resource" && (
                    <>
                      {adaptedScenarios
                        .filter(
                          (elem) =>
                            (isBaseVisible && elem.scenario === null) ||
                            elem.scenario !== null
                        )
                        .map((adapted) => (
                          <>
                            <ScenarioResourceViz
                              estimate={adapted.estimate}
                              scenario={adapted.scenario}
                              width={width}
                              maxPrice={maxResourcePrice}
                              isPriceVisible={isPriceVisible}
                              mappedResources={adapted.mappedResources}
                              totalCosts={adapted.totalFixedCosts}
                            />
                          </>
                        ))}
                    </>
                  )}
                </div>
              )}
            </ReactResizeDetector>
          </div>
          <div className="flex-1">
            <div style={{ position: "sticky", top: 110, zIndex: 1 }}>
              {type === "task" && (
                <ScenarioTaskViz.Legend estimate={estimate} />
              )}

              {type === "resource" && (
                <ScenarioResourceViz.Legend estimate={estimate} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { useTrackerPeople } from "../../hooks/trackerPeople"
import useCurrentOrganization from "../../hooks/useCurrentOrganization"
import DropdownList from "../DropdownList"

function getLabel(member, short) {
  if (!short) {
    return member.full_name
      ? `${member.full_name} (${member.email})`
      : member.email
  } else {
    return member.full_name || member.email
  }
}

export default function OrgUserSelector({
  orgId,
  userId,
  onMemberChange,
  extraOptions = [],
  clearable,
  useShortNames = false,
  useShortSelectors = false,
  ...props
}) {
  const [{ data: members }] = useTrackerPeople()

  const taskOptions =
    members?.map((member) => ({
      label: getLabel(member, useShortSelectors),
      value: member.id,
    })) ?? []

  const options = [...extraOptions, ...taskOptions]

  const selectedUser = members?.find((m) => m.id === userId)

  let caption = undefined

  if (userId) {
    if (userId > 0) {
      caption = selectedUser ? getLabel(selectedUser, useShortNames) : "..."
    } else {
      caption = extraOptions.find((opt) => opt.value === userId)?.label
    }
  }

  return (
    <DropdownList
      {...props}
      value={userId}
      caption={caption}
      options={options}
      onChange={(nextUserId) => {
        const nextUser = members?.find((t) => t.id === nextUserId)
        onMemberChange(nextUser)
      }}
      onSearchChange={() => {
        /* Ignore this, since org members are not paginated */
      }}
      clearable={clearable}
      onClear={() => {
        onMemberChange(null)
      }}
      itemWidth={300}
      capitalize={false}
    />
  )
}

function OrgUserSelectorField({ form, field, label, ...props }) {
  const org = useCurrentOrganization()

  return (
    <>
      {label && (
        <label className="font-weight-semibold mb-3 h3 text-uppercase">
          {label}
        </label>
      )}
      <OrgUserSelector
        orgId={org.id}
        userId={field.value}
        onMemberChange={(member) => form.setFieldValue(field.name, member?.id)}
        {...props}
      />
    </>
  )
}

OrgUserSelector.Field = OrgUserSelectorField

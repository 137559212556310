import classNames from "classnames"
import dayjs from "dayjs"
import { max, min, range } from "lodash"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import Layout from "../../../components/Layout"
import { TrackerLandmarks } from "../../../components/TrackerLandmarks"
import { ProjectContext } from "../../../context"
import { useProjectTaskList } from "../../../hooks/projectTasks"
import ProjectBottomBarContent from "../ProjectBottomBarContent"
import ProjectTabBarContent from "../ProjectTabBarContent"
import S from "./ProjectGantt.module.scss"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import "dayjs/locale/it"
import "dayjs/locale/en"
import { useAuthUser } from "use-eazy-auth"
import Button from "../../../components/Button/Button"
import Icon from "../../../components/Icon"
import { useTranslation } from "react-i18next"
import useWpHistoryMethods from "../../../hooks/useWpHistoryMethods"
import { BsListTask } from "react-icons/bs"
import { useDeadlines } from "../../../hooks/deadline"
import { arc } from "d3-shape"

dayjs.extend(isSameOrBefore)

export function ProjectGantt() {
  const [{ project }] = useContext(ProjectContext)
  const filters = useMemo(
    () => ({
      scope_project: project.id,
      page_size: 1000,
      page: 1,
    }),
    [project.id]
  )

  const deadlinesFilter = useMemo(
    () => ({
      project: project.id,
      page_size: 1000,
    }),
    [project.id]
  )

  const [{ deadlines }] = useDeadlines(deadlinesFilter)

  const [workingSet, setWorkingSet] = useState({})
  const isWorkingSetEmpty = Object.entries(workingSet).filter(([, val]) => !!val).length === 0
  const [editable, setEditable] = useState(false)
  const [{ tasks }, { createSprint, updateSprint, removeSprint }] = useProjectTaskList(filters)
  const { t } = useTranslation()
  const history = useWpHistoryMethods()

  const deleteWorkingSet = useCallback(async () => {
    for (const sprintId of Object.keys(workingSet)) {
      await removeSprint.asPromise(undefined, parseInt(sprintId, 10))
    }
    setWorkingSet({})
  }, [removeSprint, workingSet])

  const { start, end } = useMemo(() => {
    if (!tasks || tasks.length === 0) {
      return { start: null, end: null }
    }
    let start = null,
      end = null
    for (const task of tasks) {
      for (const sprint of task.sprints) {
        if (start === null || sprint.date_start < start) {
          start = sprint.date_start
        }
        if (end === null || sprint.date_end > end) {
          end = sprint.date_end
        }
      }
    }
    return { start, end: dayjs(end).add(1, "month").format("YYYY-MM-DD") }
  }, [tasks])

  const valid = start !== null && end !== null

  return (
    <Layout displayRawContent>
      <div className="container-fluid pt-5 px-page">
        <div className="d-flex flex-row justify-content-between align-items-center mb-6">
          <div className="d-flex flex-row aling-items-center">
            <Button className="d-inline-flex align-items-center px-5 mr-5" disabled>
              <Icon name="plus-rounded" className="mr-2" height={16} width={16} />
              {t("action:add_project_task")}
            </Button>
            <Button className="d-inline-flex align-items-center px-5 mr-5" disabled>
              <Icon name="filter" className="mr-2" height={16} width={16} />
              {t("action:filter_project_tasks")}
            </Button>
            <Button
              className="d-inline-flex align-items-center px-5 mr-5"
              onClick={() => history.push(`/projects/${project.id}/tasks`)}
            >
              <BsListTask className="mr-2" height={16} width={16} />
              {t("action:display_list")}
            </Button>
          </div>
          <div>
            <Button
              className="d-inline-flex align-items-center px-5 mr-5"
              disabled={isWorkingSetEmpty}
              color="danger"
              onClick={deleteWorkingSet}
            >
              {t("action:delete_sprint")}
            </Button>
            {!editable && (
              <Button className="d-inline-flex align-items-center px-5 mr-5" onClick={() => setEditable(true)}>
                {t("action:enable_edit")}
              </Button>
            )}
            {editable && (
              <Button className="d-inline-flex align-items-center px-5 mr-5" onClick={() => setEditable(false)}>
                {t("action:disable_edit")}
              </Button>
            )}
          </div>
        </div>
      </div>
      {tasks && !valid && (
        <div>
          <i>Nessun elemento pianificato, impossibile costruire il Gantt</i>
        </div>
      )}
      {tasks && valid && (
        <TasksGantt
          tasks={tasks}
          start={start}
          end={end}
          onSprintUpdate={(sprintId, data) => {
            updateSprint(sprintId, data)
          }}
          onSprintCreate={(data) => {
            createSprint(data)
          }}
          workingSet={workingSet}
          setWorkingSet={setWorkingSet}
          editable={editable}
          deadlines={deadlines}
        />
      )}
      <Layout.TabBar>
        <ProjectTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <ProjectBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

const W_CELL = 24
const H_CELL = 42
const D_HEIGHT = 50
const RECT_HEIGHT = 20
const RECT_PAD_V = (H_CELL - RECT_HEIGHT) / 2
const RECT_PAD_H = 2
const W_HANDLE = W_CELL / 2
const isMACOS = navigator.appVersion.indexOf("Mac") !== -1
const DEADLINES_HEIGHT = 20
const DEADLINES_RADIUS = 6

function TasksGantt({
  tasks,
  start,
  end,
  editable = true,
  onSprintUpdate,
  onSprintCreate,
  workingSet,
  setWorkingSet,
  deadlines,
}) {
  const { user } = useAuthUser()
  const { t } = useTranslation()

  const startDate = useMemo(() => dayjs(start).locale(user.lang), [start, user.lang])
  const endDate = useMemo(() => dayjs(end).locale(user.lang), [end, user.lang])
  const holidays = useMemo(() => computeHolidayOffsets(startDate, endDate), [startDate, endDate])
  const boundaries = useMemo(() => computeMonthBoundaries(startDate, endDate), [startDate, endDate])

  const [editing, setEditing] = useState({
    sprint: { id: 0 },
    handle: "left",
    tx: 0,
    t0: 0,
    row: 0,
  })

  const numOfDays = endDate.diff(startDate, "day") + 1

  const W = numOfDays * W_CELL
  const H = tasks.length * H_CELL

  useEffect(() => {
    if (editing.sprint.id) {
      document.body.style.cursor = "ew-resize"
      const moveHandler = (e) => {
        setEditing((edt) => ({ ...edt, tx: clamp(quantize(e.screenX - edt.t0, W_CELL), editing.txMin, editing.txMax) }))
      }
      document.addEventListener("mousemove", moveHandler)
      return () => {
        document.body.style.cursor = "auto"
        document.removeEventListener("mousemove", moveHandler)
      }
    }
  }, [editing.sprint.id, editing.txMax, editing.txMin, onSprintUpdate])

  useEffect(() => {
    if (editing.sprint.id) {
      const upHandler = (e) => {
        const deltaDays = editing.tx / W_CELL
        if (deltaDays !== 0) {
          if (editing.handle === "left") {
            onSprintUpdate(editing.sprint.id, {
              date_start: dayjs(editing.sprint.date_start).add(deltaDays, "day").format("YYYY-MM-DD"),
            })
          } else if (editing.handle === "both") {
            onSprintUpdate(editing.sprint.id, {
              date_start: dayjs(editing.sprint.date_start).add(deltaDays, "day").format("YYYY-MM-DD"),
              date_end: dayjs(editing.sprint.date_end).add(deltaDays, "day").format("YYYY-MM-DD"),
            })
          } else if (editing.handle === "right") {
            onSprintUpdate(editing.sprint.id, {
              date_end: dayjs(editing.sprint.date_end).add(deltaDays, "day").format("YYYY-MM-DD"),
            })
          } else if (editing.handle === "create") {
            let start = dayjs(editing.sprint.date_start)
            let end = dayjs(editing.sprint.date_end)
            if (deltaDays < 0) {
              start = start.add(deltaDays, "day")
            } else {
              end = end.add(deltaDays, "day")
            }
            onSprintCreate({
              project_task: tasks[editing.row].id,
              date_start: start.format("YYYY-MM-DD"),
              date_end: end.format("YYYY-MM-DD"),
            })
          }
        } else if (editing.handle !== "create") {
          if (e.shiftKey || (isMACOS && e.metaKey) || (!isMACOS && e.ctrlKey)) {
            setWorkingSet((s) => ({ ...s, [editing.sprint.id]: !s[editing.sprint.id] }))
          } else {
            setWorkingSet((s) => {
              if (s[editing.sprint.id] && Object.entries(s).filter(([, val]) => !!val).length === 1) {
                return {}
              } else {
                return { [editing.sprint.id]: true }
              }
            })
          }
        }
        setEditing((edt) => ({ sprint: { id: 0 }, tx: 0, t0: 0 }))
      }
      document.addEventListener("mouseup", upHandler)
      return () => {
        document.removeEventListener("mouseup", upHandler)
      }
    }
  }, [editing, onSprintCreate, onSprintUpdate, setWorkingSet, tasks])

  return (
    <div className="w-100 d-flex flex-row">
      <div style={{ width: 300 }}>
        <div style={{ height: D_HEIGHT }}>&nbsp;</div>
        <div style={{ height: DEADLINES_HEIGHT }} className={S["gantt-task-label"]}>
          <i>{t("navbar.deadlines")}</i>
        </div>
        {tasks.map((task) => (
          <div className={S["gantt-task-label"]} key={task.id}>
            <span className={S["code"]}>
              ({task.estimate_code}) {task.code}
            </span>
            <span className={S["text"]}>{task.title}</span>
          </div>
        ))}
      </div>
      <div className={classNames("flex-1", S["overflow-scroll"])}>
        <svg width={W} height={H + D_HEIGHT + DEADLINES_HEIGHT}>
          {boundaries
            .filter((b) => b.width > 5)
            .map((monthBoundary, i) => (
              <text
                key={monthBoundary.year + "-" + monthBoundary.month}
                x={(monthBoundary.offset + monthBoundary.width / 2) * W_CELL}
                y={20}
                textAnchor="middle"
              >
                {dayjs(monthBoundary.year + "-" + (monthBoundary.month + 1) + "-01").format("MMMM YYYY")}
              </text>
            ))}
          {range(numOfDays).map((n) => (
            <React.Fragment key={n}>
              <text x={(n + 0.5) * W_CELL} y={35} textAnchor="middle">
                {startDate.add(n, "days").format("DD")}
              </text>
              <text x={(n + 0.5) * W_CELL} y={47} textAnchor="middle" fontSize={10}>
                {startDate.add(n, "days").format("ddd").substring(0, 2).toUpperCase()}
              </text>
            </React.Fragment>
          ))}
          <g transform={`translate(0 ${D_HEIGHT})`}>
            {/* DEADLINES BACKGROUND */}
            <rect x={0} y={0} width={W} height={H_CELL} fill={"#F5F5F5"}></rect>

            {/* STRIPED BACKGROUND FOR TASKS */}
            {tasks.map((task, i) => (
              <rect
                key={task.id}
                x={0}
                y={i * 42 + DEADLINES_HEIGHT}
                width={W}
                height={H_CELL}
                fill={i % 2 === 0 ? "#FFFFFF" : "#F5F5F5"}
                onMouseDown={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  const refDate = dayjs(start)
                    .add(Math.floor((e.pageX - e.target.getBoundingClientRect().x) / W_CELL), "days")
                    .format("YYYY-MM-DD")
                  const row = i
                  const sprint = { id: "new", date_start: refDate, date_end: refDate }
                  const boundaries = computeDragBoundaries(tasks[row].sprints, sprint)
                  setEditing({
                    sprint: sprint,
                    handle: "create",
                    tx: 0,
                    t0: e.screenX,
                    row,
                    txMin: boundaries.create.lower,
                    txMax: boundaries.create.upper,
                  })
                }}
              ></rect>
            ))}

            {/* GRID - LINES SEPARATING DAYS */}
            {range(1, numOfDays).map((n) => (
              <line
                key={n}
                x1={n * W_CELL - 0.5}
                x2={n * W_CELL - 0.5}
                y1={0}
                y2={H + DEADLINES_HEIGHT}
                stroke="#CECECE"
                strokeWidth={0.5}
                style={{ pointerEvents: "none" }}
              />
            ))}

            {/* HOLIDAY BACKGROUND */}
            {holidays.map((h) => (
              <rect
                key={h}
                x={h * W_CELL}
                y={0}
                width={W_CELL}
                height={H + DEADLINES_HEIGHT}
                fill="var(--primary)"
                fillOpacity={0.3}
                style={{ pointerEvents: "none" }}
              />
            ))}

            {/* DEADLINE DOTS */}
            {deadlines
              ?.filter((d) => d.date >= start && d.date <= end)
              ?.map((deadline) => {
                const x = dayjs(deadline.date).diff(startDate, "days") * W_CELL + W_CELL / 2
                const y = DEADLINES_HEIGHT / 2
                const sec = arc()({
                  innerRadius: 0,
                  outerRadius: DEADLINES_RADIUS,
                  startAngle: 0,
                  endAngle: deadline.completion_percent / 100 * 2 * Math.PI,
                })
                const color = deadline.completion_percent === 100 ? "var(--success)" : "var(--danger)"
                return (
                  <React.Fragment key={deadline.id}>
                    <g transform={`translate(${x}, ${y})`}>
                      <circle cx={0} cy={0} r={DEADLINES_RADIUS} stroke={color} strokeWidth={1.5} fill="transparent" />
                      <path d={sec} fill={color} />
                    </g>
                  </React.Fragment>
                )
              })}

            {/* SPRINT ELEMENTS */}
            {tasks.map((task, i) => {
              return (
                <React.Fragment key={task.id}>
                  {task.sprints.map((sprint) => {
                    let leftDelta = 0
                    let widthDelta = 0
                    if (editing.sprint.id === sprint.id) {
                      if (editing.handle === "left") {
                        leftDelta = editing.tx
                        widthDelta = -editing.tx
                      } else if (editing.handle === "both") {
                        leftDelta = editing.tx
                      } else {
                        widthDelta = editing.tx
                      }
                    }
                    const isSelected = workingSet[sprint.id]
                    return (
                      <SprintRect
                        sprint={sprint}
                        key={sprint.id}
                        startDate={startDate}
                        leftDelta={leftDelta}
                        widthDelta={widthDelta}
                        i={i}
                        editable={editable}
                        setEditing={(conf) => {
                          const boundaries = computeDragBoundaries(task.sprints, sprint)
                          setEditing({ ...conf, txMin: boundaries[conf.handle].lower, txMax: boundaries[conf.handle].upper })
                        }}
                        isSelected={isSelected}
                      />
                    )
                  })}
                </React.Fragment>
              )
            })}

            {/* SPRINT THAT IS BEING CREATED */}
            {editing.sprint.id === "new" && (
              <SprintRect
                sprint={editing.sprint}
                startDate={startDate}
                leftDelta={editing.tx < 0 ? editing.tx : 0}
                widthDelta={editing.tx > 0 ? editing.tx : -editing.tx}
                i={editing.row}
                editable={editable}
                setEditing={setEditing}
              />
            )}
          </g>

          {/* MONTH BOUNDARIES */}
          {boundaries.slice(0, -1).map((monthBoundary, i) => (
            <line
              key={monthBoundary.year + "-" + monthBoundary.month}
              x1={(monthBoundary.offset + monthBoundary.width) * W_CELL - 0.5}
              x2={(monthBoundary.offset + monthBoundary.width) * W_CELL - 0.5}
              y1={5}
              y2={H + D_HEIGHT + DEADLINES_HEIGHT}
              strokeWidth={0.5}
              stroke="#000"
            >
              {dayjs(monthBoundary.year + "-" + (monthBoundary.month + 1) + "-01").format("MMMM YYYY")}
            </line>
          ))}
        </svg>
      </div>
    </div>
  )
}

function SprintRect({ sprint, startDate, leftDelta, widthDelta, i, editable, setEditing, isSelected }) {
  const x = dayjs(sprint.date_start).diff(startDate, "days") * W_CELL + RECT_PAD_H + leftDelta
  const y = i * H_CELL + RECT_PAD_V + DEADLINES_HEIGHT
  const width = (dayjs(sprint.date_end).diff(dayjs(sprint.date_start), "days") + 1) * W_CELL - RECT_PAD_H * 2 + widthDelta

  return (
    <React.Fragment key={sprint.id}>
      <rect
        key={sprint.id}
        x={x}
        y={y}
        height={RECT_HEIGHT}
        width={width}
        fill={!isSelected ? "var(--primary)" : "var(--danger)"}
        onMouseDown={(e) => {
          if (editable) {
            e.stopPropagation()
            e.preventDefault()
            setEditing({ sprint: sprint, handle: "both", tx: 0, t0: e.screenX })
          }
        }}
        style={{ cursor: editable ? "ew-resize" : "auto" }}
      />
      {editable && (
        <>
          {/* START HANDLE */}
          <g transform={`translate(${x}, ${y})`}>
            <rect
              x={0}
              y={0}
              height={RECT_HEIGHT}
              width={W_HANDLE}
              fill="transparent"
              onMouseDown={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setEditing({ sprint: sprint, handle: "left", tx: 0, t0: e.screenX })
              }}
              style={{ cursor: "ew-resize" }}
            />
            <line x1={4.5} x2={4.5} y1={5} y2={15} stroke="white" strokeWidth={1} style={{ pointerEvents: "none" }} />
            <line x1={6.5} x2={6.5} y1={5} y2={15} stroke="white" strokeWidth={1} style={{ pointerEvents: "none" }} />
          </g>
          {/* END HANDLE */}
          <g transform={`translate(${x + width - W_HANDLE}, ${y})`}>
            <rect
              x={0}
              y={0}
              height={RECT_HEIGHT}
              width={W_HANDLE}
              fill="transparent"
              onMouseDown={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setEditing({ sprint: sprint, handle: "right", tx: 0, t0: e.screenX })
              }}
              style={{ cursor: "ew-resize" }}
            />
            <line
              x1={W_HANDLE - 4.5}
              x2={W_HANDLE - 4.5}
              y1={5}
              y2={15}
              stroke="white"
              strokeWidth={1}
              style={{ pointerEvents: "none" }}
            />
            <line
              x1={W_HANDLE - 6.5}
              x2={W_HANDLE - 6.5}
              y1={5}
              y2={15}
              stroke="white"
              strokeWidth={1}
              style={{ pointerEvents: "none" }}
            />
          </g>
        </>
      )}
    </React.Fragment>
  )
}

const HOLIDAYS = ["01-01", "01-06", "04-25", "05-01", "06-02", "08-15", "11-01", "12-08", "12-25", "12-26"]

function computeHolidayOffsets(startDate, endDate) {
  let x = startDate
  let n = 0
  const result = []
  while (x.isSameOrBefore(endDate)) {
    if (x.get("day") === 0 || x.get("day") === 6) {
      result.push(n)
    } else {
      const k = x.format("MM-DD")
      if (HOLIDAYS.includes(k)) {
        result.push(n)
      } else {
        const easter = getEaster(x.get("year"))
        if (x.format("YYYY-MM-DD") === easter || x.subtract(1, "day").format("YYYY-MM-DD") === easter) {
          result.push(n)
        }
      }
    }
    n++
    x = x.add(1, "day")
  }
  return result
}

function computeMonthBoundaries(startDate, endDate) {
  let x = startDate
  let n = 0
  let month = x.get("month")
  let year = x.get("year")
  let w = 0
  const result = []
  while (x.isSameOrBefore(endDate)) {
    if (x.get("month") !== month) {
      result.push({ month, year, offset: n - w, width: w })
      w = 1
      month = x.get("month")
      year = x.get("year")
    } else {
      w++
    }
    n++
    x = x.add(1, "day")
  }
  result.push({ month, year, offset: n - w, width: w })
  return result
}

function getEaster(Y) {
  var C = Math.floor(Y / 100)
  var N = Y - 19 * Math.floor(Y / 19)
  var K = Math.floor((C - 17) / 25)
  var I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15
  I = I - 30 * Math.floor(I / 30)
  I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11))
  var J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4)
  J = J - 7 * Math.floor(J / 7)
  var L = I - J
  var M = 3 + Math.floor((L + 40) / 44)
  var D = L + 28 - 31 * Math.floor(M / 4)

  return Y + "-" + padout(M) + "-" + padout(D)
}

function padout(number) {
  return number < 10 ? "0" + number : number
}

function quantize(x, step) {
  return Math.round(x / step) * step
}

function computeDragBoundaries(sprints, sprint) {
  const endDatesBeforeMyStart = sprints.map((sprint) => sprint.date_end).filter((date) => date < sprint.date_start)
  const leftBoundary = endDatesBeforeMyStart.length > 0 ? max(endDatesBeforeMyStart) : null

  const startDatesAfterMyEnd = sprints.map((sprint) => sprint.date_start).filter((date) => date > sprint.date_end)
  const rightBoundary = startDatesAfterMyEnd.length > 0 ? min(startDatesAfterMyEnd) : null

  const allBoundaries = {
    left: {
      lower: -Infinity,
      upper: dayjs(sprint.date_end).diff(dayjs(sprint.date_start), "day") * W_CELL,
    },
    right: {
      lower: dayjs(sprint.date_start).diff(dayjs(sprint.date_end), "day") * W_CELL,
      upper: Infinity,
    },
    both: {
      lower: -Infinity,
      upper: Infinity,
    },
    create: {
      lower: -Infinity,
      upper: Infinity,
    },
  }

  if (leftBoundary) {
    allBoundaries.left.lower = (dayjs(leftBoundary).diff(dayjs(sprint.date_start), "day") + 1) * W_CELL
    allBoundaries.both.lower = (dayjs(leftBoundary).diff(dayjs(sprint.date_start), "day") + 1) * W_CELL
    allBoundaries.create.lower = (dayjs(leftBoundary).diff(dayjs(sprint.date_start), "day") + 1) * W_CELL
  }
  if (rightBoundary) {
    allBoundaries.right.upper = (dayjs(rightBoundary).diff(dayjs(sprint.date_end), "day") - 1) * W_CELL
    allBoundaries.both.upper = (dayjs(rightBoundary).diff(dayjs(sprint.date_end), "day") - 1) * W_CELL
    allBoundaries.create.upper = (dayjs(rightBoundary).diff(dayjs(sprint.date_end), "day") - 1) * W_CELL
  }

  return allBoundaries
}

function clamp(val, min, max) {
  if (val < min) {
    return min
  }
  if (val > max) {
    return max
  }
  return val
}

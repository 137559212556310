import { Field, Formik } from "formik"
import * as Yup from "yup"
import React from "react"
import FieldTextarea from "../../../components/fields/FieldTextarea"
import FieldColorPicker from "../../../components/fields/FieldColorPicker"
import FormAutoSave from "../../../components/FormAutoSave"
import Separator from "../../../components/Separator"
import { useTranslation } from "react-i18next"

const ScenarioSchema = Yup.object({
  name: Yup.string().required(),
  description: Yup.string(),
})

export default function ScenarioEditForm({ scenario, onSave, readOnly }) {
  const { t } = useTranslation(["field"])
  return (
    <Formik
      validationSchema={ScenarioSchema}
      onSubmit={(values) =>
        onSave({
          ...values,
          id: scenario.id,
          options: {
            color: values.color,
          },
        })
      }
      initialValues={{
        // Flatten for cause more simple \w auto save stuff ...
        name: scenario.name,
        description: scenario.description,
        color: scenario.options?.color || "#FFFFFF",
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="w-100 d-flex">
          <FormAutoSave />
          <div className="flex-1">
            <Field
              readOnly={readOnly}
              name="name"
              component={FieldTextarea}
              label={t("field:scenario.name")}
              readOnlyPlaceholder={"--"}
            />
            <div className="mt-3 d-inline-block">
              <Field
                left
                size="md"
                label={t("field:scenario.color")}
                disabled={readOnly}
                name="color"
                component={FieldColorPicker}
              />
            </div>
          </div>
          <Separator className="mx-6" vertical />
          <div className="flex-1">
            <Field
              readOnly={readOnly}
              rows={6}
              name="description"
              component={FieldTextarea}
              label={t("field:scenario.description")}
              readOnlyPlaceholder={"--"}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}

import React from "react"
import flatMap from "lodash/flatMap"

export function makeTaskTreeOptionsRecursive(taskTree, parentTask) {
  return flatMap(taskTree, (task) => {
    const paddingLeft = (task.path.split(".").length - 1) * 10
    return [
      {
        value: task.id,
        parent: parentTask?.id ?? null,
        label: (
          <span style={{ paddingLeft }}>
            {task.path} {task.title}
          </span>
        ),
      },
    ].concat(makeTaskTreeOptionsRecursive(task.children, task))
  })
}

export function makeTaskTreeOptions(taskTree) {
  return [
    {
      label: "-- root --",
      value: "",
    },
  ].concat(makeTaskTreeOptionsRecursive(taskTree))
}

export function makeTaskTreeMoveOptionsRecursive(
  taskTree,
  movingNode,
  parentTask
) {
  return flatMap(
    taskTree.filter((node) => node.id !== movingNode.id),
    (task) => {
      const paddingLeft = (task.path.split(".").length - 1) * 10
      return [
        {
          value: task.id,
          parent: parentTask?.id ?? null,
          label: (
            <span style={{ paddingLeft }}>
              {task.path} {task.title}
            </span>
          ),
        },
      ].concat(
        makeTaskTreeMoveOptionsRecursive(task.children, movingNode, task)
      )
    }
  )
}

export function makeTaskTreeMoveOptions(taskTree, movingNode) {
  return [
    {
      label: "-- root --",
      value: "",
    },
  ].concat(makeTaskTreeMoveOptionsRecursive(taskTree, movingNode))
}

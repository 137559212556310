import classNames from "classnames"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import ColorPickerPopover from "../../../components/ColorPickerPopover"
import Dropdown from "../../../components/Dropdown"
import FilterButton from "../../../components/FilterButton"
import FontPicker from "../../../components/FontPicker"
import Separator from "../../../components/Separator"
import WpSwitch from "../../../components/WpSwitch/WpSwitch"
import { AVAILABLE_FONTS, AVAILABLE_FONTS_HEADINGS } from "../../../consts"

export default function DocumentSettings({
  doc,
  readonly,
  hasCover,
  isCoverDisabled,
  footerConfig,
  setFooterConfig,
  handleEnableCover,
  handleDisableCover,
  createCover,
  color,
  setColor,
  defaultColor,
  font,
  setFont,
  defaultFont,
  fontHeadings,
  setFontHeadings,
  defaultHeadersFont,
  lang,
  setLang,
}) {
  const { t } = useTranslation(["translation", "enums"])
  const footerOptions = useMemo(
    () => [
      {
        value: "logo",
        label: t("enums:document_footer.logo"),
      },
      {
        value: "title",
        label: t("enums:document_footer.title"),
      },
      {
        value: "code",
        label: t("enums:document_footer.code"),
      },
      {
        value: "pagination",
        label: t("enums:document_footer.pagination"),
      },
      {
        value: "",
        label: t("enums:document_footer.empty"),
      },
    ],
    [t]
  )

  return (
    <div>
      <h2
        className={classNames(
          "text-uppercase font-size-semibold mt-8 mb-6 mx-5"
        )}
        style={{ fontSize: 16 }}
      >
        {t("documents.overview.settings")}
      </h2>

      <div className="px-5">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <h3 className="text-uppercase font-weight-semibold m-0">
            {t("field:document.overview.set_color")}
          </h3>
          <ColorPickerPopover
            value={color}
            onChange={setColor}
            disabled={readonly}
          />
        </div>
        <p className="m-0">{t("field:document.overview.set_color_message")}</p>
        <p
          className="m-0 pointer"
          onClick={() => {
            setColor(defaultColor)
          }}
        >
          <i>{t("action:reset_color")}</i>
        </p>
      </div>

      <Separator className="mx-0 my-4" />

      <div className="px-5">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <h3 className="text-uppercase font-weight-semibold m-0">
            {t("field:document.overview.lang")}
          </h3>
          <div>
            <FilterButton
              className={"mr-2"}
              selected={lang === "it"}
              onClick={() => {
                setLang("it")
              }}
            >
              ITA
            </FilterButton>
            <FilterButton
              selected={lang === "en"}
              onClick={() => {
                setLang("en")
              }}
            >
              ENG
            </FilterButton>
          </div>
        </div>
      </div>

      <Separator className="mx-0 my-4" />

      <div className="px-5">
        <h3 className="text-uppercase font-weight-semibold mb-1">
          {t("field:document.overview.set_fonts")}
        </h3>
        <div className="d-flex flex-row justify-content-start align-items-center mb-2">
          <div className="m-0 flex-1">
            {t("field:document.overview.font_heading")}
          </div>
          <div className="d-flex flex-column flex-1">
            <FontPicker
              className="px-2 py-2"
              onChange={(v) => setFontHeadings(v)}
              value={fontHeadings}
              options={AVAILABLE_FONTS_HEADINGS}
              disabled={readonly}
              onReset={() => setFontHeadings(defaultHeadersFont)}
            />
          </div>
        </div>
        <div className="d-flex flex-row justify-content-start align-items-center">
          <div className="m-0 flex-1">
            {t("field:document.overview.font_body")}
          </div>
          <div className="d-flex flex-column flex-1">
            <FontPicker
              className="px-2 py-2"
              onChange={(v) => setFont(v)}
              value={font}
              options={AVAILABLE_FONTS}
              disabled={readonly}
              onReset={() => setFont(defaultFont)}
            />
          </div>
        </div>
      </div>

      <Separator className="mx-0 my-4" />

      <div className="px-5 d-flex flex-row justify-content-between align-items-center">
        <h3 className="text-uppercase font-weight-semibold m-0">
          {t("field:document.overview.enable_cover_message")}
        </h3>
        {hasCover && (
          <WpSwitch
            checked={!isCoverDisabled}
            onChange={(heWantsACover) => {
              if (heWantsACover) {
                handleEnableCover()
              } else {
                handleDisableCover()
              }
            }}
            disabled={readonly}
          />
        )}
        {!hasCover && !readonly && (
          <WpSwitch
            checked={false}
            onChange={() => {
              createCover()
            }}
            disabled={readonly}
          />
        )}
      </div>

      <Separator className="mx-0 my-4" />

      <div className="px-5">
        <h3 className="text-uppercase font-weight-semibold m-0">
          {t("field:document.overview.config_footer")}
        </h3>
        <p className="mb-4">
          {t("field:document.overview.config_footer_message")}
        </p>
        <div className="d-flex flex-row justify-content-start align-items-start">
          <div className="d-flex flex-column flex-1">
            <label className="font-weight-semibold font-size-small text-uppercase mb-2">
              {t("field:document.overview.left_footer")}
            </label>
            <Dropdown
              className="w-100 px-2 py-2"
              onChange={(v) => setFooterConfig("left", v)}
              value={footerConfig.left}
              options={footerOptions}
              disabled={readonly}
            />
          </div>
          <div className="mx-4 d-flex flex-column flex-1">
            <label className="font-weight-semibold font-size-small text-uppercase mb-2">
              {t("field:document.overview.middle_footer")}
            </label>
            <Dropdown
              className="w-100 px-2 py-2"
              onChange={(v) => setFooterConfig("middle", v)}
              value={footerConfig.middle}
              options={footerOptions}
              disabled={readonly}
            />
          </div>
          <div className="d-flex flex-column flex-1">
            <label className="font-weight-semibold font-size-small text-uppercase mb-2">
              {t("field:document.overview.right_footer")}
            </label>
            <Dropdown
              className="w-100 px-2 py-2"
              onChange={(v) => setFooterConfig("right", v)}
              value={footerConfig.right}
              options={footerOptions}
              disabled={readonly}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

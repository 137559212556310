import { keyBy, range } from "lodash"
import Slider from "rc-slider"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { getDepth } from "react-sortable-tree"
import useCurrentEstimateOption from "../../../../hooks/useCurrentEstimateOption"
import { cutTree } from "../../EstimateTasksTree/TaskTable/utils"
import CirclePack from "./CirclePack"
import ReactResizeDetector from "react-resize-detector"
import classNames from "classnames"
import S from "./EstimateBubble.module.scss"
import { ColorScheme } from "../colorScheme"
import { compressTreeAtDepth, countNodes, sliceExtent } from "../../treeOperations"
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter"
function traverseRecursive(node, fn) {
  const self = fn(node)
  return {
    ...self,
    children: self.children.map((_node) => traverseRecursive(_node, fn)),
  }
}

export function transformTree(tree, maxDepth, extent) {
  const usefulBranches = cutTree(
    sliceExtent(tree, extent),
    maxDepth ?? 99
  ).children

  return {
    children: usefulBranches.map((node) => {
      return traverseRecursive(node, (_node) => {
        if (_node.children.length === 0) {
          return _node
        } else {
          return {
            ..._node,
            children: [
              {
                ..._node,
                children: [],
                _isOwnResources: true,
                price: _node.own_price,
                cost: _node.own_cost,
              },
              ..._node.children,
            ],
          }
        }
      })
    }),
  }
}

function LegendRecursive({ node, colorScheme, limit = Infinity, offset = 0 }) {
  const currencyFmt = useCurrencyFormatter()
  const pathParts = node.path.split(".")
  const borderClass = colorScheme.border(
    pathParts.length - 1,
    parseInt(pathParts[0]) - 1
  )
  let childrenParams = node.children.map((child) => ({
    node: child,
    limit: countNodes(child),
    offset: 0,
  }))

  let i = 0

  if (offset > 1) {
    let accumulatedOffset = 1
    i = 0

    while (accumulatedOffset <= offset) {
      accumulatedOffset += childrenParams[i].limit
      i++
    }

    i--
    accumulatedOffset -= childrenParams[i].limit
    childrenParams[i].offset = offset - accumulatedOffset
    childrenParams[i].limit -= offset - accumulatedOffset

    childrenParams = childrenParams.slice(i)
  }

  let accumulatedLimit = offset === 0 ? 1 : 0
  i = 0

  while (accumulatedLimit <= limit && i < childrenParams.length) {
    accumulatedLimit += childrenParams[i].limit
    i++
  }

  if (i < childrenParams.length) {
    i--
    accumulatedLimit -= childrenParams[i].limit
    childrenParams[i].limit = limit - accumulatedLimit
    if (childrenParams[i].limit === 0) {
      childrenParams = childrenParams.slice(0, i)
    } else {
      childrenParams = childrenParams.slice(0, i + 1)
    }
  }

  return (
    <div className={classNames(S["legend-item"], borderClass)}>
      {offset === 0 && (
        <div className="d-flex flex-row justify-content-between">
          <p className={S["legend-line-title"]}>
            {node.path} {node.title}
          </p>
          {node.own_price > 0 && (
            <span>{currencyFmt.format(node.own_price)}</span>
          )}
          {node.own_price <= 0 && <span></span>}
        </div>
      )}
      {childrenParams.map(({ node, limit, offset }) => (
        <LegendRecursive
          key={node.path}
          node={node}
          colorScheme={colorScheme}
          limit={limit}
          offset={offset}
        />
      ))}
    </div>
  )
}

export function EstimateBubbleLegend({ estimate, maxDepth, extent, columns }) {
  const { t } = useTranslation()

  const usefulTree = useMemo(
    () => sliceExtent(estimate.task_tree, extent),
    [estimate.task_tree, extent]
  )

  const colorScheme = useMemo(() => {
    return new ColorScheme("area", getDepth({ children: usefulTree }) - 1)
  }, [usefulTree])

  const tree = compressTreeAtDepth({ children: usefulTree }, maxDepth + 1)

  // tree.children.length ==> 1 extra line (of blank space) for each first level child
  const lines = countNodes(tree) - 1 + tree.children.length
  const linesPerColumn = Math.ceil(lines / columns)

  let currentColLength = 0

  const cols = [[]]

  for (const node of tree.children) {
    const size = countNodes(node)
    if (currentColLength + size + 1 <= linesPerColumn) {
      cols[0].push({ node, limit: size, offset: 0 })
      currentColLength += size + 1
      if (currentColLength === linesPerColumn) {
        cols.unshift([])
        currentColLength = 0
      }
    } else {
      let offset = 0
      while (offset < size) {
        const chunkSize = Math.min(
          linesPerColumn - currentColLength,
          size - offset
        )
        cols[0].push({ node, offset, limit: chunkSize })
        if (chunkSize === linesPerColumn - currentColLength) {
          cols.unshift([])
          currentColLength = 0
        }
        offset += chunkSize
      }
      currentColLength = (cols[0]?.[0]?.limit ?? -1) + 1
    }
  }

  if (cols[0].length === 0) {
    cols.shift()
  }

  return (
    <div
      className="d-flex flex-row align-items-start"
      style={{ marginLeft: -10, marginRight: -10 }}
    >
      {cols.reverse().map((col, i) => (
        <div
          key={i}
          style={{
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: `${100 / columns}%`,
            minWidth: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div className="d-flex flex-row justify-content-between align-items-center mb-4">
            <span className="text-uppercase font-weight-semibold">
              {t("viz.legend")}
            </span>
            <span className="text-uppercase font-weight-semibold">
              {t("viz.price")}
            </span>
          </div>
          {col.map(({ node, limit, offset }) => (
            <div className="mb-4" key={node.path}>
              <LegendRecursive
                node={node}
                colorScheme={colorScheme}
                limit={limit}
                offset={offset}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default function EstimateBubble({ estimate }) {
  const { t } = useTranslation(["translation", "enums"])

  const depth = useMemo(() => getDepth({ children: estimate.task_tree }), [
    estimate.task_tree,
  ])

  const marks = useMemo(() => {
    const values = range(1, depth + 1).map((v) => {
      if (v < depth) {
        return {
          id: v,
          label: t("tasks.level", { level: v }),
        }
      }
      return {
        id: v,
        label: t("tasks.all"),
      }
    })
    return keyBy(values, "id")
  }, [depth, t])

  const [maxDepth, setMaxDepth] = useState(depth)

  const tree = useMemo(() => transformTree(estimate.task_tree, maxDepth), [
    estimate.task_tree,
    maxDepth,
  ])

  const [isCostVisible, setCostVisible] = useCurrentEstimateOption(
    "viz.bubble.isCostVisible",
    false
  )
  const options = useMemo(
    () => ({
      displayPrices: isCostVisible,
    }),
    [isCostVisible]
  )

  return (
    <div>
      <div className="mt-8">
        <p>{t("tasks.bubble_description")}</p>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            {depth > 1 && (
              <Slider
                min={1}
                marks={marks}
                step={null}
                max={depth}
                onChange={setMaxDepth}
                defaultValue={depth}
                style={{ width: 90 * (depth - 1) }}
                trackStyle={{ backgroundColor: "var(--primary)" }}
                handleStyle={{
                  border: "2px solid var(--primary)",
                  height: 16,
                  width: 16,
                  marginTop: -6,
                }}
                activeDotStyle={{
                  borderColor: "transparent",
                  backgroundColor: "var(--primary)",
                }}
              />
            )}
          </div>
          <div className="d-flex flex-row align-items-center">
            <input
              type="checkbox"
              checked={isCostVisible}
              onChange={(e) => setCostVisible(e.target.checked)}
            />
            <label className="mb-0 mx-3">{t("tasks.show_cost")}</label>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-8">
          <div className="flex-4">
            <ReactResizeDetector handleWidth>
              {({ width }) => (
                <div>
                  <CirclePack
                    width={width ?? 300}
                    height={((width ?? 300) * 2) / 3}
                    data={tree}
                    options={options}
                  />
                </div>
              )}
            </ReactResizeDetector>
          </div>
          <div className="flex-1">
            <div style={{ position: "sticky", top: 110, zIndex: 1 }}>
              <EstimateBubbleLegend
                estimate={estimate}
                maxDepth={maxDepth}
                columns={1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { sortBy } from "lodash"
import { rj, useRunRj } from "react-rocketjump"
import api from "../api"

const TemplateDocFragmentsState = rj({
  name: "TemplateDocFragments",
  effectCaller: rj.configured(),
  effect: (wpAuth) => (params = {}) =>
    api.auth(wpAuth).get("/api/document-fragment-stored/", params),
  computed: {
    fragments: "getData",
  },
  mutations: {
    createFrag: {
      effect: (wpAuth) => (frag = {}) =>
        api.auth(wpAuth).post("/api/document-fragment-stored/", frag),
      // For now we reload from server list.......
      updater: (state, result) => {
        return {
          ...state,
          data: sortBy([...state.data, result], "title"),
        }
      },
    },
    deleteFrag: {
      effect: (wpAuth) => (frag = {}) =>
        api
          .auth(wpAuth)
          .mapResponse(() => frag)
          .delete(`/api/document-fragment-stored/${frag.id}`),

      updater: (state, result) => {
        return {
          ...state,
          data: state.data.filter((frag) => frag.id !== result.id),
        }
      },
    },
    updateFrag: {
      takeEffect: "concatLatest",
      effect: (wpAuth) => (frag = {}) =>
        api
          .auth(wpAuth)
          .patch(`/api/document-fragment-stored/${frag.id}`, frag),
      updater: (state, result) => {
        return {
          ...state,
          data: state.data.map((frag) =>
            frag.id === result.id ? result : frag
          ),
        }
      },
    },
    setFragAsDefault: {
      effect: (wpAuth) => (frag = {}) =>
        api
          .auth(wpAuth)
          .put(`/api/document-fragment-stored/${frag.id}/default`),
      optimisticResult: (frag) => frag,
      optimisticUpdater: (state, result) => {
        return {
          ...state,
          data: state.data.map((frag) => {
            // Mark frag as default
            if (frag.id === result.id) {
              return {
                ...frag,
                is_default: true,
              }
            }
            // Remove defaults from others
            if (frag.frament_type === result.frament_type && frag.is_default) {
              return {
                ...frag,
                is_default: false,
              }
            }
            return frag
          }),
        }
      },
    },
    removeFragAsDefault: {
      effect: (wpAuth) => (frag = {}) =>
        api
          .auth(wpAuth)
          .delete(`/api/document-fragment-stored/${frag.id}/default`),
      optimisticResult: (frag) => frag,
      optimisticUpdater: (state, result) => {
        return {
          ...state,
          data: state.data.map((frag) => {
            // Mark frag as default
            if (frag.id === result.id) {
              return {
                ...frag,
                is_default: false,
              }
            }
            return frag
          }),
        }
      },
    },
  },
})

export function useTemplateDocFragments(params) {
  return useRunRj(TemplateDocFragmentsState, [params], false)
}

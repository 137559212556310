import { useTranslation } from "react-i18next"
import { BsTags } from "react-icons/bs"
import Layout from "./Layout"

export function SettingsLandmarks() {
  const { t } = useTranslation()

  return (
    <>
      <Layout.FirstLevelNavi.Tab
        text={t("navbar.tags")}
        svgIcon={<BsTags size={16} />}
        to={"/tags"}
      />
      <Layout.FirstLevelNavi.Divider />
    </>
  )
}

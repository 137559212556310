/* eslint-disable no-unused-vars */
import dayjs from "dayjs"
import { Field, Formik } from "formik"
import { isEqual } from "lodash"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Badge } from "reactstrap"
import * as yup from "yup"
import { useInteraction, useInteractionHistory } from "../hooks/interaction"
import useWpHistoryMethods from "../hooks/useWpHistoryMethods"
import Button from "./Button"
import EstimateSelector from "./EstimateSelector/EstimateSelector"
import FieldInput from "./fields/FieldInput"
import FieldSwitch from "./fields/FieldSwitch"
import FieldTextarea from "./fields/FieldTextarea"
import FormAutoSave from "./FormAutoSave"
import Loader from "./Loader/Loader"
import OrgUserSelector from "./OrgUserSelector/OrgUserSelector"
import ProjectSelector from "./ProjectSelector"
import Table from "./Table"

export function BusinessInteractionDetail({ interactionId, initialEditMode = false, enableEditMode = false }) {
  const [{ interaction, saving }, { update }] = useInteraction(interactionId)
  const [{ interactionHistory }] = useInteractionHistory(interactionId)
  const [editMode, setEditMode] = useState(initialEditMode)
  const { t } = useTranslation()
  const history = useWpHistoryMethods()

  const schema = yup.object().shape({
    date: yup
      .date()
      .typeError(
        t("field:errors.date", {
          field: t("field:project_activity.date_ref"),
        })
      )
      .required(t("field:errors.mandatory", { field: t("field:project.name") })),
    description: yup.string().required(),
    assigned_to: yup.number().required(),
  })

  const columnsHistory = useMemo(
    () => [
      {
        label: t("field:customer_interaction.date"),
        name: "date",
        size: 2,
        orderingField: "date",
        render: (selectedDatum, colName, datum) => {
          return <span>{dayjs(selectedDatum).format("DD/MM/YYYY")}</span>
        },
      },
      {
        label: t("field:customer_interaction.description"),
        name: "description",
        size: 8,
        render: (selectedDatum, colName, datum) => {
          return (
            <div>
              <p className="m-0">{selectedDatum}</p>
              <p className="m-0">
                {datum.estimate && (
                  <Badge
                    className="mr-3 pointer"
                    color="teal"
                    onClick={() => {
                      history.push(`/estimates/${datum.estimate}`)
                    }}
                  >
                    {datum.estimate_name}
                  </Badge>
                )}
                {datum.project && (
                  <Badge
                    className="mr-3 pointer"
                    color="cyan"
                    onClick={() => {
                      history.push(`/projects/${datum.project}`)
                    }}
                  >
                    {datum.project_name}
                  </Badge>
                )}
              </p>
            </div>
          )
        },
      },
      {
        label: t("field:customer_interaction.assigned_to"),
        name: "assigned_to",
        size: 2,
        render: (selectedDatum, colName, datum) => {
          return <span>{datum.assigned_to_name}</span>
        },
      },
    ],
    [history, t]
  )

  return (
    <div className="container pt-5 px-half-page pb-page-bottom">
      {!interaction && <Loader />}
      {interaction && (
        <>
          <Formik
            initialValues={interaction}
            validationSchema={schema}
            onSubmit={(values, actions) => {
              return update.asPromise(interactionId, values)
            }}
            enableReinitialize={true}
          >
            {(formik) => (
              <>
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                  <h3 className="text-right mt-8 mb-5 text-separator" style={{ opacity: editMode ? 1 : 0 }}>
                    {saving && t("message.saving")}
                    {!saving && !formik.dirty && t("message.saved")}
                    {!saving && formik.dirty && <span className="text-danger">{t("message.not_saved")}</span>}
                  </h3>
                  <h2 className="text-center text-uppercase mb-8 font-weight-semibold">
                    {t("customer_interaction.title_generic")}
                  </h2>
                  <div className="d-flex flex-row justify-content-center">
                    <div className="flex-1 pr-5">
                      <Field
                        name="date"
                        component={FieldInput}
                        type="date"
                        label={t("field:customer_interaction.date")}
                        className={"mb-5"}
                        readOnly={!editMode}
                      />
                    </div>
                    <div className="flex-1 pl-5">
                      <Field
                        name="assigned_to"
                        component={OrgUserSelector.Field}
                        containerClassName={"w-100 mb-5"}
                        className="py-3 px-4"
                        label={t("field:customer_interaction.assigned_to")}
                        readOnly={!editMode}
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-row justify-content-center">
                    <div className="flex-1">
                      <Field
                        name="description"
                        component={FieldTextarea}
                        className={"mb-5"}
                        placeholder={t("field:customer_interaction.placeholder.description")}
                        label={t("field:customer_interaction.description")}
                        readOnly={!editMode}
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-row justify-content-center">
                    <div className="flex-1 pr-5">
                      <Field
                        name="estimate"
                        component={EstimateSelector.Field}
                        containerClassName={"w-100 mb-5"}
                        className="py-3 px-4"
                        clearable
                        label={t("field:customer_interaction.estimate")}
                        readOnly={!editMode}
                      />
                    </div>
                    <div className="flex-1 pl-5">
                      <Field
                        name="project"
                        component={ProjectSelector.Field}
                        containerClassName={"w-100 mb-5"}
                        className="py-3 px-4"
                        clearable
                        label={t("field:customer_interaction.project")}
                        readOnly={!editMode}
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-row justify-content-center">
                    <div className="flex-1 pr-5 mt-8">
                      <Field
                        name="completed"
                        component={FieldSwitch}
                        className={"mb-5"}
                        message={t("field:customer_interaction.completed")}
                        readOnly={!editMode}
                      />
                    </div>
                    <div className="flex-1 pl-5" />
                  </div>

                  <div className="d-flex flex-row justify-content-center mt-5">
                    {enableEditMode && (
                      <>
                        {editMode && (
                          <>
                            <Button
                              onClick={() => {
                                formik.resetForm()
                                setEditMode(false)
                              }}
                              className="mx-4"
                            >
                              {t("action:discard_and_lock")}
                            </Button>
                            <Button type="submit" className="mx-4">
                              {t("action:save")}
                            </Button>
                            <Button
                              onClick={() => {
                                formik.submitForm().then(() => {
                                  setEditMode(false)
                                })
                              }}
                              className="mx-4"
                            >
                              {t("action:save_and_lock")}
                            </Button>
                          </>
                        )}
                        {!editMode && (
                          <>
                            <Button
                              onClick={() => {
                                formik.resetForm()
                                setEditMode(true)
                              }}
                              className="mx-4"
                            >
                              {t("action:edit")}
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </form>
              </>
            )}
          </Formik>
          {interactionHistory && interactionHistory.length > 0 && (
            <>
              <h2 className="text-center text-uppercase mb-8 font-weight-semibold mt-9">{t("customer_interaction.history")}</h2>
              <Table columns={columnsHistory} data={interactionHistory} className="pb-8" highlightRows={[]} />
            </>
          )}
          {interactionHistory && interactionHistory.length === 0 && (
            <>
              <h2 className="text-center text-uppercase mb-8 font-weight-semibold mt-9">{t("customer_interaction.history")}</h2>
              <p className="text-center">
                <i>{t("customer_interaction.no_history")}</i>
              </p>
            </>
          )}
        </>
      )}
    </div>
  )
}

import { keyBy } from "lodash"
import React, { useMemo } from "react"
import { useHrResources } from "../../hooks/useHrResources"
import DropdownList from "../DropdownList"

export default function HrResourceSelector({
  idOrIds,
  emptyCaption,
  onChange,
  extraOptions = [],
  clearable,
  useShortNames = false,
  useShortSelectors = false,
  filterActiveResources = true,
  ...props
}) {
  const filter = useMemo(() => {
    const f = {}
    if (filterActiveResources) {
      f.is_active = true
    }
    return f
  }, [filterActiveResources])
  const [{ data: humanResources }] = useHrResources(filter)

  const defaultOptions =
    humanResources?.map((member) => ({
      label: member.full_name,
      value: member.id,
    })) ?? []

  const options = [...extraOptions, ...defaultOptions]

  const lookupTable = keyBy(options, "value")
  const lookupInstancesTable = keyBy(humanResources, "id")

  let caption = undefined

  if (idOrIds) {
    if (Array.isArray(idOrIds)) {
      caption = idOrIds.map((id) => lookupTable[id]?.label).join(", ")
    } else {
      caption = lookupTable[idOrIds]?.label
    }
  }

  return (
    <DropdownList
      {...props}
      value={idOrIds}
      caption={caption || emptyCaption}
      options={options}
      onChange={(nextUserId) => {
        if (Array.isArray(nextUserId)) {
          onChange(
            nextUserId,
            nextUserId.map((id) => lookupInstancesTable[id])
          )
        } else {
          onChange(nextUserId, lookupInstancesTable[nextUserId])
        }
      }}
      onSearchChange={() => {
        /* Ignore this, since org members are not paginated */
      }}
      clearable={clearable}
      onClear={() => {
        onChange(null)
      }}
      itemWidth={300}
      capitalize={false}
    />
  )
}

import { Field, Formik } from "formik"
import { isEqual, omit, sortBy, uniqBy } from "lodash"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { BsPencil } from "react-icons/bs"
import * as yup from "yup"
import Button from "../../../components/Button/Button"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import { TagCollection } from "../../../components/TagCollection"
import { TrackerLandmarks } from "../../../components/TrackerLandmarks"
import FieldCustomerSelector from "../../../components/fields/FieldCustomerSelector"
import FieldDropdown from "../../../components/fields/FieldDropdown"
import FieldFavorite from "../../../components/fields/FieldFavorite"
import FieldInput from "../../../components/fields/FieldInput"
import FieldTextarea from "../../../components/fields/FieldTextarea"
import FieldYesNo from "../../../components/fields/FieldYesNo"
import { ProjectContext } from "../../../context"
import useModalTrigger from "../../../hooks/useModalTrigger"
import useWpHistoryMethods from "../../../hooks/useWpHistoryMethods"
import { fixNullIfEmpty, notifyError } from "../../../utils"
import { AuditTrailModal } from "../AuditTrailModal"
import ProjectBottomBarContent from "../ProjectBottomBarContent"
import ProjectTabBarContent from "../ProjectTabBarContent"

const COMPUTED_PROPS = ["customer_name", "linked_estimates"]

function shouldSubmit(values, prevValues) {
  const shouldSave = !isEqual(omit(values, ...COMPUTED_PROPS), omit(prevValues, ...COMPUTED_PROPS))
  return shouldSave
}

function getInitialValue(project) {
  return {
    ...project,
    money_amount: project.money_amount === "0.00" ? "" : project.money_amount,
    tags: project.tags_data,
  }
}

export default function ProjectInfo() {
  const { t } = useTranslation(["translation", "tab", "action"])
  const history = useWpHistoryMethods()

  const [{ project, saving }, { updateProject, writeAuditTrail }] = useContext(ProjectContext)

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .required(t("field:errors.mandatory", { field: t("field:project.name") }))
          .max(512, t("field:errors.max", { max_value: 512 })),
        currency: yup
          .string()
          .required(t("field:errors.mandatory", { field: t("field:project.currency") }))
          .max(100, t("field:errors.max", { max_value: 100 })),
      }),
    [t]
  )

  const statusOptions = useMemo(() => {
    const opts = ["configuring", "open", "closed", "on_hold", "canceled"]

    return opts.map((opt) => ({
      value: opt,
      label: t("enums:project_status." + opt),
    }))
  }, [t])

  const kindOptions = useMemo(() => {
    const opts = ["development", "maintenance", "commercial", "generic"]

    return opts.map((opt) => ({
      value: opt,
      label: t("enums:project_kind." + opt),
    }))
  }, [t])

  const supportPeriodOptions = useMemo(() => {
    const opts = ["Nessun supporto", "2 settimane", "1 mese", "3 mesi", "6 mesi", "1 anno"]

    return opts.map((opt) => ({
      value: opt,
      label: t("enums:support_period." + opt),
    }))
  }, [t])

  const [auditTrailModalState, auditTrailModalActions] = useModalTrigger()

  return (
    <Layout>
      <div className="container pt-5 px-half-page pb-page-bottom">
        {!project && <Loader />}
        {project && (
          <>
            <Formik
              initialValues={getInitialValue(project)}
              initialStatus={getInitialValue(project)}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                const { id, tags, ...data } = values
                fixNullIfEmpty(data, "date_begin", "date_end", "estimated_delivery_date")
                if (data.money_amount === "") {
                  data.money_amount = "0.00"
                }
                data.tags = tags.map((t) => t.id)
                return updateProject
                  .onFailure(notifyError)
                  .onSuccess((result) => {
                    history.goBack()
                  })
                  .asPromise(id, data)
              }}
              enableReinitialize={true}
            >
              {(formik) => {
                const dirty = shouldSubmit(formik.values, formik.status)
                return (
                  <form autoComplete="off">
                    {/* <FormAutoSave shouldSubmit={shouldSubmit} /> */}
                    <div className="d-flex flex-column px-page">
                      <h3 className="text-right mt-8 mb-5 text-separator">
                        {saving && t("message.saving")}
                        {!saving && !dirty && t("message.saved")}
                        {!saving && dirty && <span className="text-danger">{t("message.not_saved")}</span>}
                      </h3>
                      <h2 className="text-center text-uppercase mb-5 font-weight-semibold">{t("project_info.title")}</h2>
                      <div className="d-flex flex-row justify-content-center">
                        <div className="flex-1 pr-5">
                          <Field
                            name="name"
                            label={t("field:project.name")}
                            placeholder={t("field:project.placeholder.name")}
                            component={FieldInput}
                          />
                        </div>
                        <div className="flex-1 pl-5 d-flex flex-row">
                          <div style={{ flex: "1 1 0%" }} className="mr-3">
                            <Field
                              name="code"
                              label={t("field:project.code")}
                              placeholder={t("field:project.placeholder.code")}
                              component={FieldInput}
                              readOnly={true}
                            />
                          </div>
                          <div style={{ flex: "0 0" }}>
                            <Field
                              name="favorite"
                              label={t("field:project.favorite")}
                              component={FieldFavorite}
                              containerClassName="justify-content-center align-items-center"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-center mt-3">
                        <div className="flex-1 pr-5">
                          <Field
                            name="customer"
                            label={t("field:project.customer_name")}
                            placeholder={t("field:project.placeholder.customer_name")}
                            component={FieldCustomerSelector}
                            caption={formik.values.customer_name}
                            controlClassName="px-4 py-3 border-radius-xl"
                            enableNone
                          />
                        </div>
                        <div className="flex-1 pl-5 d-flex flex-row">
                          <div className="flex-1 pr-5">
                            <Field
                              name="status"
                              label={
                                <div className="d-flex justify-content-between">
                                  {t("field:project.status")}
                                  <BsPencil
                                    className="pointer"
                                    onClick={() => auditTrailModalActions.open({ field: "status" })}
                                  />
                                </div>
                              }
                              component={FieldDropdown}
                              options={statusOptions}
                              readOnly
                            />
                          </div>
                          <div className="flex-1 pl-5">
                            <Field
                              name="money_amount"
                              label={t("field:project.money_amount_forced")}
                              placeholder={t("field:project.placeholder.money_amount")}
                              component={FieldInput}
                              type="number"
                              step="0.01"
                              min="0.00"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1 pr-5">
                          <Field
                            name="created_at"
                            label={t("field:project.created_at")}
                            component={FieldInput}
                            type="date"
                            readOnly
                          />
                        </div>
                        <div className="flex-1 px-5">
                          <Field name="date_begin" label={t("field:project.date_begin")} component={FieldInput} type="date" />
                        </div>
                        <div className="flex-1 px-5">
                          <Field
                            name="estimated_delivery_date"
                            label={
                              <div className="d-flex justify-content-between">
                                {t("field:project.estimated_delivery_date")}
                                <BsPencil
                                  className="pointer"
                                  onClick={() => auditTrailModalActions.open({ field: "estimated_delivery_date" })}
                                />
                              </div>
                            }
                            component={FieldInput}
                            type="date"
                            readOnly
                          />
                        </div>
                        <div className="flex-1 pl-5">
                          <Field name="date_end" label={t("field:project.date_end")} component={FieldInput} type="date" />
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1 pr-5 d-flex flex-row">
                          <div className="flex-1 pr-5">
                            <Field
                              name="support_period"
                              label={t("field:project.support_period")}
                              component={FieldDropdown}
                              options={supportPeriodOptions}
                            />
                          </div>
                          <div className="flex-1 pl-5">
                            <Field
                              name="support_period_end"
                              label={t("field:project.support_period_end")}
                              component={FieldInput}
                              type="date"
                            />
                          </div>
                        </div>
                        <div className="flex-1 pl-5 d-flex flex-row">
                          <div className="flex-1 pr-5">
                            <Field
                              name="kind"
                              label={t("field:project.kind")}
                              component={FieldDropdown}
                              options={kindOptions}
                            />
                          </div>
                          <div style={{ flex: "0 0" }} className="pr-5">
                            <Field name="is_consumptive" label={t("field:project.is_consumptive")} component={FieldYesNo} />
                          </div>
                          <div style={{ flex: "0 0" }}>
                            <Field
                              name="include_in_indicators"
                              label={t("field:project.include_in_indicators")}
                              component={FieldYesNo}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1">
                          <Field
                            name="material_link"
                            label={t("field:project.material_link")}
                            placeholder={t("field:project.placeholder.material_link")}
                            component={FieldInput}
                            type="url"
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1">
                          <Field
                            name="description"
                            label={t("field:project.description")}
                            placeholder={t("field:project.placeholder.description")}
                            component={FieldTextarea}
                            rows={5}
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1">
                          <Field
                            name="notes"
                            label={t("field:project.notes")}
                            placeholder={t("field:project.placeholder.notes")}
                            component={FieldTextarea}
                            rows={5}
                          />
                        </div>
                      </div>
                    </div>
                    <h2 className="text-center text-uppercase mt-8 mb-5 font-weight-semibold">{t("estimate_info.tags")}</h2>
                    <div className="text-center">
                      <TagCollection
                        tags={formik.values.tags}
                        className="mr-2"
                        onRemove={(tag) => {
                          formik.setFieldValue(
                            "tags",
                            formik.values.tags.filter((t) => t.id !== tag.id)
                          )
                        }}
                        onAdd={(tag) => {
                          formik.setFieldValue("tags", sortBy(uniqBy(formik.values.tags.concat([tag]), "id"), "id"))
                        }}
                      />
                    </div>
                    <div className="text-center mt-8">
                      <Button
                        onClick={() => {
                          formik.handleSubmit()
                        }}
                        className="mr-2"
                      >
                        {t("action:save")}
                      </Button>
                    </div>
                  </form>
                )
              }}
            </Formik>
          </>
        )}
      </div>
      {auditTrailModalState.value?.field === "status" && (
        <AuditTrailModal
          isOpen={auditTrailModalState.isOpen}
          toggle={auditTrailModalActions.close}
          onClosed={auditTrailModalActions.onClose}
          onSave={(values) => {
            auditTrailModalActions.close()
            writeAuditTrail(project.id, {
              field: "status",
              new_value: values.newValue,
              reason: values.reason,
              attachment: values.attachment,
            })
          }}
          initialValue={project.status}
          widget={<Field name="newValue" label={t("field:project.status")} component={FieldDropdown} options={statusOptions} />}
        />
      )}
      {auditTrailModalState.value?.field === "estimated_delivery_date" && (
        <AuditTrailModal
          isOpen={auditTrailModalState.isOpen}
          toggle={auditTrailModalActions.close}
          onClosed={auditTrailModalActions.onClose}
          onSave={(values) => {
            auditTrailModalActions.close()
            writeAuditTrail(project.id, {
              field: "estimated_delivery_date",
              new_value: values.newValue,
              reason: values.reason,
              attachment: values.attachment,
            })
          }}
          initialValue={project.estimated_delivery_date}
          widget={
            <Field name="newValue" label={t("field:project.estimated_delivery_date")} component={FieldInput} type="date" />
          }
        />
      )}
      <Layout.TabBar>
        <ProjectTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <ProjectBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

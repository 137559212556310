import React, { useCallback, useMemo, useState } from "react"
import { Field, Formik } from "formik"
import * as Yup from "yup"
import find from "lodash/find"
import uniqueId from "lodash/uniqueId"
import Button from "../../../../components/Button"
import FieldInput from "../../../../components/fields/FieldInput"
import FormAutoSave from "../../../../components/FormAutoSave"
import { RESOURCE_TYPE } from "../../../../consts"
import Icon from "../../../../components/Icon"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import S from "../columns.module.scss"
import Separator from "../../../../components/Separator"
import ResourceInnerForm from "../forms/ResourceInnerForm"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { keyBy } from "lodash"
import { useSearchResources } from "../../../../hooks/resources"
import { shouldAutoSubmitFixedCost, shouldAutoSubmitResource } from "./utils"
import { numberPrettyFormat } from "../../../../utils"
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter"

const initialResourceAllocationValues = {
  cost_unit: "",
  resource_id: "",
  size: "",
}

const ResourceRowForm = ({
  resourceAllocation,
  resourceType,
  resources: resourcesFromParent,
  // resourcesById,
  onSubmit,
  onRemove,
  estimate,
  isLoading,
  readOnly = false,
}) => {
  const [{ resources: myResources }, actions] = useSearchResources()

  const resources = myResources || resourcesFromParent
  const allResources = useMemo(() => [...resources, ...estimate.resources], [
    estimate.resources,
    resources,
  ])

  const resourcesById = useMemo(() => keyBy(allResources, "id"), [allResources])

  const resourcesOptions = useMemo(
    () =>
      resources
        .filter((r) => r.resource_type === resourceType)
        .map((r) => ({
          value: r.id,
          label: r.name,
        })),
    [resources, resourceType]
  )

  const { t } = useTranslation(["translation", "field"])

  const ResourceSchema = useMemo(
    () =>
      Yup.object({
        cost_unit: Yup.string().required(
          t("field:errors.mandatory", {
            field: t("field:task.resource.cost_unit"),
          })
        ),
        resource_id: Yup.number().required(
          t("field:errors.mandatory", { field: t("field:task.resource.name") })
        ),
        size: Yup.number().required(
          t("field:errors.mandatory", {
            field: t("field:task.resource.quantity"),
          })
        ),
      }),
    [t]
  )

  const initialValues = useMemo(() => {
    if (resourceAllocation) {
      return {
        ...resourceAllocation,
        size: numberPrettyFormat(resourceAllocation.size),
      }
    }
    return initialResourceAllocationValues
  }, [resourceAllocation])

  return (
    <Formik
      validationSchema={ResourceSchema}
      onSubmit={(values) => {
        return onSubmit({
          ...values,
          resource: resourcesById[values.resource_id],
        })
      }}
      initialValues={initialValues}
    >
      <>
        <FormAutoSave shouldSubmit={shouldAutoSubmitResource} />
        <ResourceInnerForm
          resourcesOptions={resourcesOptions}
          resourcesById={resourcesById}
          onRemove={onRemove}
          resourceType={resourceType}
          onSearchChange={(searchString) =>
            actions.runDebounced({
              name__icontains: searchString,
              embed_prices: 1,
              estimate: "none",
              resource_type: resourceType,
              price_list: estimate.price_list
            })
          }
          estimate={estimate}
          resourceAllocation={resourceAllocation}
          isLoading={isLoading}
          readOnly={readOnly}
        />
        <Separator className="my-0 mx-0" />
      </>
    </Formik>
  )
}

const initialFixedCostValues = {
  cost: "",
  price: "",
  description: "",
}

const FixedCostForm = ({ fixedCost, onRemove, onSubmit, readOnly = false }) => {
  const { t } = useTranslation(["translation", "field"])
  const currencyFmt = useCurrencyFormatter()

  const FixedCostSchema = useMemo(
    () =>
      Yup.object({
        description: Yup.string().required(
          t("field:errors.mandatory", {
            field: t("field:task.fixed_cost.description"),
          })
        ),
        cost: Yup.number()
          .transform((currentValue, originalValue) => {
            if (isNaN(currentValue)) {
              return null
            }
            return currentValue
          })
          .nullable()
          .lessThan(
            10 ** 14,
            t("field:errors.less_than", { max_value: 10 ** 14 })
          )
          .moreThan(
            -(10 ** 14),
            t("field:errors.more_than", { min_value: -(10 ** 14) })
          ),
        price: Yup.number()
          .required(
            t("field:errors.mandatory", {
              field: t("field:task.fixed_cost.price"),
            })
          )
          .lessThan(
            10 ** 14,
            t("field:errors.less_than", { max_value: 10 ** 14 })
          )
          .moreThan(
            -(10 ** 14),
            t("field:errors.more_than", { min_value: -(10 ** 14) })
          ),
      }),
    [t]
  )

  return (
    <Formik
      validationSchema={FixedCostSchema}
      onSubmit={(values, actions) =>
        onSubmit(FixedCostSchema.cast(values), actions)
      }
      initialTouched={{
        description: true,
        price: true,
      }}
      initialValues={fixedCost ?? initialFixedCostValues}
    >
      {({ handleSubmit }) => (
        <>
          <form
            onSubmit={handleSubmit}
            className="d-flex flex-row justify-content-between align-items-center"
          >
            <FormAutoSave shouldSubmit={shouldAutoSubmitFixedCost} />
            <div className={classNames(S["col-title"])}>
              <div className="d-flex flex-row w-100">
                <Icon
                  name={"fixed-cost"}
                  title={t(`enums:fixed_cost`)}
                  style={{ width: 24, height: 24, marginRight: 4 }}
                />
                <div className="flex-1">
                  <Field
                    type="text"
                    name="description"
                    placeholder={t("field:task.fixed_cost.description")}
                    component={FieldInput}
                    tiny
                    clear
                    readOnly={readOnly}
                  />
                </div>
              </div>
            </div>
            <Separator vertical className="my-3 mx-0 align-self-stretch" />
            <div className={classNames(S["col-unit"])}></div>
            <Separator vertical className="my-3 mx-0 align-self-stretch" />
            <div className={classNames(S["col-quantity"])}></div>
            <Separator vertical className="my-3 mx-0 align-self-stretch" />
            <div
              className={classNames(
                S["col-cost"],
                "d-flex flex-row align-items-center justify-content-end"
              )}
            >
              <div className="flex-1">
                <Field
                  type="number"
                  name="cost"
                  placeholder={t("field:task.fixed_cost.cost")}
                  component={FieldInput}
                  className="text-right flex-1"
                  tiny
                  clear
                  readOnly={readOnly}
                  readOnlyPlaceholder={"--"}
                />
              </div>
              {" " + currencyFmt.currencySymbol}
            </div>
            <Separator vertical className="my-3 mx-0 align-self-stretch" />
            <div
              className={classNames(
                S["col-price"],
                "d-flex flex-row align-items-center justify-content-end"
              )}
            >
              <div className="flex-1">
                <Field
                  type="number"
                  name="price"
                  className="text-right"
                  placeholder={t("field:task.fixed_cost.price")}
                  component={FieldInput}
                  tiny
                  clear
                  readOnly={readOnly}
                />
              </div>
              {" " + currencyFmt.currencySymbol}
            </div>
            <Separator vertical className="my-3 mx-0 align-self-stretch" />
            <div className={classNames(S["col-action"])}>
              {!readOnly && (
                <UncontrolledDropdown
                  className={"action-icon-container-primary-hover"}
                >
                  <DropdownToggle caret={false} tag={"span"}>
                    <Icon
                      name="vdots"
                      className="text-dark pointer"
                      title={t("action:other_options")}
                      placement="right"
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    right
                    modifiers={{ offset: { offset: "0, 12" } }}
                    className="border-primary"
                  >
                    <DropdownItem
                      className={
                        "text-capitalize px-0 dropdown-item-primary-active"
                      }
                      onClick={onRemove}
                    >
                      <div className="d-flex flex-row align-items-center mx-4">
                        <Icon
                          name="delete"
                          className="pointer mr-4"
                        />
                        <span>{t("action:delete")}</span>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </div>
          </form>
          <Separator className="my-0 mx-0" />
        </>
      )}
    </Formik>
  )
}

export default function TaskResourcesAndCostsTable({
  estimate,
  updatingAllocations,
  task,
  resources,
  resourcesById,
  patchTaskResource,
  addTaskResource,
  removeTaskResource,
  addTaskFixedCost,
  patchTaskFixedCost,
  removeTaskFixedCost,
  readOnly = false,
}) {
  const [addingResources, setAddingResource] = useState([])
  const addResource = useCallback(
    (type) =>
      setAddingResource((resList) =>
        resList.concat({
          id: uniqueId("addResource"),
          type,
        })
      ),
    []
  )
  const removeAddResource = useCallback(
    (res) => setAddingResource((resList) => resList.filter((r) => r !== res)),
    []
  )

  const [addingFixedCost, setAddingFixedCost] = useState([])
  const addFixedCost = useCallback(
    () =>
      setAddingFixedCost((costList) =>
        costList.concat({
          id: uniqueId("addFixedCost"),
        })
      ),
    []
  )
  const removeFixedCost = useCallback(
    (cost) =>
      setAddingFixedCost((costList) => costList.filter((r) => r !== cost)),
    []
  )

  const { t } = useTranslation(["translation"])

  const elementsNumber =
    task.resources.length +
    task.fixed_costs.length +
    addingFixedCost.length +
    addingResources.length

  const makeResourceAllocationPayload = useCallback((values) => {
    const resource = values.resource
    const pricedResource = find(resource.price_list, {
      cost_unit: values.cost_unit,
    })
    return {
      priced_resource: pricedResource.id,
      size: values.size,
    }
  }, [])

  return (
    <div>
      {!readOnly && (
        <div className="mb-5 d-flex flex-row align-items-center">
          <span className="pr-4">{t("tasks.add-resource")}</span>
          <Button size="sm" onClick={() => addResource(RESOURCE_TYPE.HR)}>
            {t("tasks.add-hr")}
          </Button>
          <Button
            className="ml-4"
            size="sm"
            onClick={() => addResource(RESOURCE_TYPE.SERVICE)}
          >
            {t("tasks.add-service")}
          </Button>
          <Button
            className="ml-4"
            size="sm"
            onClick={() => addResource(RESOURCE_TYPE.MATERIAL)}
          >
            {t("tasks.add-material")}
          </Button>
          <Button className="ml-4" size="sm" onClick={() => addFixedCost()}>
            {t("tasks.add-fixedcost")}
          </Button>
        </div>
      )}
      {elementsNumber > 0 && (
        <div className={classNames("pt-2")}>
          <div className="text-uppercase font-weight-semibold border-bottom border-dark d-flex flex-row justify-content-between align-items-start">
            <div className={S["col-title"]}>
              {t("field:task.resource.name")}
            </div>
            <div className={S["col-unit"]}>
              {t("field:task.resource.cost_unit")}
            </div>
            <div className={S["col-quantity"]}>
              {t("field:task.resource.quantity")}
            </div>
            <div
              className={classNames(
                "text-right",
                S["col-cost"],
                S["col-header"]
              )}
            >
              {t("field:task.resource.cost")}
            </div>
            <div
              className={classNames(
                "text-right",
                S["col-price"],
                S["col-header"]
              )}
            >
              {t("field:task.resource.price")}
            </div>
            <div className={S["col-action"]}></div>
          </div>
          {task.resources.map((resourceAllocation) => (
            <ResourceRowForm
              key={resourceAllocation.allocation_id}
              estimate={estimate}
              resourceAllocation={resourceAllocation}
              resourceType={
                resourcesById[resourceAllocation.resource_id].resource_type
              }
              resources={resources}
              resourcesById={resourcesById}
              onRemove={() =>
                removeTaskResource(task, resourceAllocation.allocation_id)
              }
              onSubmit={(values) =>
                patchTaskResource.asPromise(
                  task.id,
                  values.allocation_id,
                  makeResourceAllocationPayload(values)
                )
              }
              isLoading={updatingAllocations[resourceAllocation.allocation_id]}
              readOnly={readOnly}
            />
          ))}
          {addingResources.map((res) => (
            <ResourceRowForm
              key={res.id}
              estimate={estimate}
              resourceType={res.type}
              resources={resources}
              resourcesById={resourcesById}
              onRemove={() => removeAddResource(res)}
              onSubmit={(values) =>
                addTaskResource
                  .onSuccess(() => removeAddResource(res))
                  .asPromise(task.id, makeResourceAllocationPayload(values))
              }
              readOnly={readOnly}
            />
          ))}
          {task.fixed_costs.map((cost) => (
            <FixedCostForm
              key={cost.id}
              fixedCost={cost}
              onRemove={() => removeTaskFixedCost(task.id, cost.id)}
              onSubmit={(values) =>
                patchTaskFixedCost.asPromise(task.id, cost.id, values)
              }
              readOnly={readOnly}
            />
          ))}
          {addingFixedCost.map((cost) => (
            <FixedCostForm
              key={cost.id}
              onRemove={() => removeFixedCost(cost)}
              onSubmit={(values) =>
                addTaskFixedCost
                  .onSuccess(() => removeFixedCost(cost))
                  .asPromise(task.id, values)
              }
              readOnly={readOnly}
            />
          ))}
        </div>
      )}
    </div>
  )
}

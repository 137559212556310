import { deps, rj, useRunRj } from "react-rocketjump"
import rjDebounce from "react-rocketjump/plugins/debounce"
import api from "../api"

const ProjectResourcesRj = rj(rjDebounce(), {
  effectCaller: rj.configured(),
  effect: (wpAuth) => (projectId, taskId, filters) => {
    return api
      .auth(wpAuth)
      .get(`/api/project/${projectId}/suitable-resources/`, {
        ...filters,
        project_task: taskId,
      })
  },
})

export function useProjectResources(projectId, taskId) {
  return useRunRj(ProjectResourcesRj, [deps.maybe(projectId), taskId])
}

const ProjectResourceRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (projectId, resourceId) => {
    return api
      .auth(wpAuth)
      .get(`/api/project/${projectId}/suitable-resources/${resourceId}/`)
  },
})

export function useProjectResource(projectId, resourceId) {
  return useRunRj(ProjectResourceRj, [
    deps.maybe(projectId),
    deps.maybe(resourceId),
  ])
}

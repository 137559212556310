import React from "react"
import classNames from "classnames"
import S from "./Table.module.scss"
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa"

function getOrInvoke(stringOrFunction, ...args) {
  if (typeof stringOrFunction === "function") {
    return stringOrFunction(...args)
  } else {
    return stringOrFunction
  }
}

function Cell({
  capitalize = false,
  colPrefix,
  size = 1,
  element = null,
  cellClassname = "",
  disableCellPadding,
  centered,
  children,
  onClick,
}) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        { "text-capitalize": capitalize },
        colPrefix + size,
        S["wp-cell"],
        "py-5",
        cellClassname
      )}
    >
      <div
        className={classNames("h-100", {
          "px-4": !disableCellPadding,
          "d-flex align-items-center": centered,
        })}
      >
        {element !== null && element}
        {element === null && children}
      </div>
    </div>
  )
}

function _Table({
  columns,
  data,
  className,
  highlightRows = [],
  rowClassname,
  use24 = false,
  disableCellPadding = false,
  centered = false,
  stickyHeader = true,
  stickyTopBar = "toolbar", // "toolbar" | "tabbar"
  valueCheckFn = (val) => val,
  ordering,
  onOrderChange = () => {},
}) {
  const colPrefix = use24 ? "col-24-" : "col-"
  return (
    <div
      className={classNames(className, "container-fluid px-0", S["wp-table"])}
    >
      <div
        className={classNames(
          "row border-bottom border-dark no-gutters bg-white",
          S["wp-header"],
          { [S["wp-sticky-row"]]: stickyHeader },
          {
            [S["wp-sticky-tabbar"]]: stickyTopBar === "tabbar" && stickyHeader,
          }
        )}
      >
        {columns.map((column, i) => (
          <div
            key={i}
            className={classNames(
              "py-3 px-4 h3 font-semibold text-uppercase",
              colPrefix + column.size,
              { "text-right": column.alignTitle === "right" },
              { "text-center": column.alignTitle === "center" }
            )}
          >
            {column.label}
            {column.orderingField && (
              <span
                style={{
                  display: "inline-block",
                  transform: "translateY(-2px)",
                }}
              >
                {ordering === column.orderingField && (
                  <span
                    className="pointer"
                    onClick={() => onOrderChange("-" + column.orderingField)}
                  >
                    <FaSortUp size={14} />
                  </span>
                )}
                {ordering === "-" + column.orderingField && (
                  <span className="pointer" onClick={() => onOrderChange("")}>
                    <FaSortDown size={14} />
                  </span>
                )}
                {ordering !== column.orderingField &&
                  ordering !== "-" + column.orderingField && (
                    <span
                      className="pointer"
                      onClick={() => onOrderChange(column.orderingField)}
                    >
                      <FaSort size={14} color="#ccc" />
                    </span>
                  )}
              </span>
            )}
          </div>
        ))}
      </div>
      {data &&
        data.map((datum, i) => (
          <div
            key={i}
            className={classNames(
              "row border-bottom no-gutters",
              S["wp-row"],
              { [S["wp-row-active"]]: highlightRows.includes(i) },
              typeof rowClassname === "function" ? rowClassname(datum, i) : ""
            )}
          >
            {columns.map((col, j) => {
              const clickHandler = col.onClick
                ? (e) => {
                    col.onClick(e, datum)
                  }
                : undefined
              if (valueCheckFn(datum[col.name])) {
                if (col.render) {
                  return (
                    <Cell
                      onClick={clickHandler}
                      size={col.size}
                      colPrefix={colPrefix}
                      key={j}
                      centered
                      disableCellPadding={
                        col.disableCellPadding || disableCellPadding
                      }
                      capitalize={col.capitalize}
                      cellClassname={getOrInvoke(
                        col.cellClassname,
                        datum[col.name],
                        col.name,
                        datum
                      )}
                    >
                      {col.render(datum[col.name], col.name, datum)}
                    </Cell>
                  )
                } else {
                  return (
                    <Cell
                      onClick={clickHandler}
                      size={col.size}
                      colPrefix={colPrefix}
                      capitalize={col.capitalize}
                      key={j}
                      centered
                      element={datum[col.name]}
                      disableCellPadding={
                        col.disableCellPadding || disableCellPadding
                      }
                      cellClassname={getOrInvoke(
                        col.cellClassname,
                        datum[col.name],
                        col.name,
                        datum
                      )}
                    />
                  )
                }
              } else {
                if (col.render) {
                  return (
                    <Cell
                      onClick={clickHandler}
                      size={col.size}
                      colPrefix={colPrefix}
                      key={j}
                      centered
                      disableCellPadding={
                        col.disableCellPadding || disableCellPadding
                      }
                      capitalize={col.capitalize}
                      cellClassname={getOrInvoke(
                        col.cellClassname,
                        datum[col.name],
                        col.name,
                        datum
                      )}
                    >
                      {col.render(null, col.name, datum)}
                    </Cell>
                  )
                } else {
                  return (
                    <Cell
                      key={j}
                      size={col.size}
                      colPrefix={colPrefix}
                      centered
                      disableCellPadding={
                        col.disableCellPadding || disableCellPadding
                      }
                      capitalize={col.capitalize}
                      cellClassname={getOrInvoke(
                        col.cellClassname,
                        datum[col.name],
                        col.name,
                        datum
                      )}
                    />
                  )
                }
              }
            })}
          </div>
        ))}
    </div>
  )
}

const Table = React.memo(_Table)
export default Table

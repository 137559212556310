import React from "react"
import { Link, useRouteMatch } from "react-router-dom"
import Button from "./Button"
import Icon from "./Icon"

export default function ChangeViewButton({
  match,
  iconName,
  path,
  exact,
  activeClassName,
  title,
  className = "",
  ...props
}) {
  const ownPath = path ? `${match.path}/${path}` : match.path
  const ownLink = path ? `${match.url}/${path}` : match.url

  const _innerMatch = useRouteMatch({ path: ownPath, exact })

  return (
    <Button
      color="primary"
      size="lg"
      rounded
      active={!!_innerMatch}
      to={ownLink}
      // TODO: Maybe make Button Link component or eanble better style to
      // easy switch from button to link and vice versa
      Component={Link}
      className={`text-center d-inline-flex justify-content-center align-items-center ${className}`}
      {...props}
    >
      <Icon name={iconName} title={title} />
    </Button>
  )
}

import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { deps } from "react-rocketjump"
import { CreateCustomerInteractionModal } from "../../components/CreateCustomerInteractionModal"
import { IndicatorsLandmarks } from "../../components/IndicatorsLandmarks"
import Layout from "../../components/Layout"
import { useInteraction } from "../../hooks/interaction"
import { useOverview } from "../../hooks/overview"
import { useAnalysisFilters } from "../../hooks/useAnalysisFilters"
import useModalTrigger from "../../hooks/useModalTrigger"
import { Card } from "./Card"
import { Column } from "./Column"

export function Overview({ history }) {
  const { filters, renderFilters } = useAnalysisFilters()

  const [{ data }, { run: reload }] = useOverview(filters)
  const { t } = useTranslation()

  const [, { run: loadInteraction, update }] = useInteraction(deps.maybe(null))
  const [editModalState, editModalActions] = useModalTrigger()

  const editInteraction = useCallback(
    (interactionId) => {
      loadInteraction
        .onSuccess((interaction) => {
          editModalActions.open(interaction)
        })
        .run(interactionId)
    },
    [editModalActions, loadInteraction]
  )

  return (
    <Layout className="flex-1 d-flex flex-column justify-content-start align-items-stretch" displayRawContent>
      <div className="d-flex flex-row justify-content-start align-items-center px-4 py-3">{renderFilters()}</div>
      {data && (
        <div class="max-h-100 w-100 overflow-auto flex-1">
          <div className="d-flex flex-row justify-content-start align-items-start max-h-100" style={{ fontSize: 15 }}>
            <Column title={t("overview.col_open_requests")} length={data.open_requests.length}>
              {data.open_requests.map((request) => (
                <Card
                  key={request.id}
                  title={request.title}
                  link={`/customers/${request.customer}/requests/${request.id}`}
                  nextInteractionId={request.next_interaction_id}
                  editInteraction={editInteraction}
                  nextInteractionDate={request.next_interaction_date}
                  nextInteractionDescription={request.next_interaction_description}
                  customerId={request.customer}
                />
              ))}
            </Column>
            <Column title={t("overview.col_open_estimates")} length={data.working_requests.length}>
              {data.working_requests.map((request) => (
                <Card
                  key={request.id}
                  title={request.title}
                  link={`/customers/${request.customer}/requests/${request.id}`}
                  nextInteractionId={request.next_interaction_id}
                  editInteraction={editInteraction}
                  nextInteractionDate={request.next_interaction_date}
                  nextInteractionDescription={request.next_interaction_description}
                  customerId={request.customer}
                />
              ))}
            </Column>
            <Column title={t("overview.col_sent_estimates")} length={data.sent_estimates.length}>
              {data.sent_estimates.map((estimate) => (
                <Card
                  key={estimate.id}
                  title={estimate.title}
                  link={`/estimates/${estimate.id}`}
                  value={parseFloat(estimate.price).toFixed(2) + " €"}
                  expireDate={estimate.expire_date}
                  nextInteractionId={estimate.next_interaction_id}
                  editInteraction={editInteraction}
                  nextInteractionDate={estimate.next_interaction_date}
                  nextInteractionDescription={estimate.next_interaction_description}
                  customerId={estimate.customer}
                  tags={estimate.tags_data}
                />
              ))}
            </Column>
            <Column title={t("overview.col_accepted_estimates")} length={data.accepted_estimates.length}>
              {data.accepted_estimates.map((estimate) => (
                <Card
                  key={estimate.id}
                  title={estimate.title}
                  link={`/estimates/${estimate.id}`}
                  value={parseFloat(estimate.price).toFixed(2) + " €"}
                  nextInteractionId={estimate.next_interaction_id}
                  editInteraction={editInteraction}
                  nextInteractionDate={estimate.next_interaction_date}
                  nextInteractionDescription={estimate.next_interaction_description}
                  customerId={estimate.customer}
                  tags={estimate.tags_data}
                />
              ))}
            </Column>
            <Column title={t("overview.col_configuring_projects")} length={data.configuring_projects.length}>
              {data.configuring_projects.map((project) => {
                return (
                  <Card
                    key={project.id}
                    title={project.name}
                    link={`/projects/${project.id}`}
                    value={parseFloat(project.project_value).toFixed(2) + " €"}
                    expireDate={project.estimated_delivery_date}
                    nextInteractionId={project.next_interaction_id}
                    editInteraction={editInteraction}
                    nextInteractionDate={project.next_interaction_date}
                    nextInteractionDescription={project.next_interaction_description}
                    missedDeadlineId={project.missed_deadline_id}
                    missedDeadlineDate={project.missed_deadline_date}
                    missedDeadlineDescription={project.missed_deadline_description}
                    customerId={project.customer}
                    tags={project.tags_data}
                  />
                )
              })}
            </Column>
            <Column title={t("overview.col_open_projects")} length={data.open_projects.length}>
              {data.open_projects.map((project) => {
                const annotations = []
                if (project.support_period_ended) {
                  annotations.push({
                    type: "danger",
                    message: t("overview.support_period_ended"),
                  })
                }
                return (
                  <Card
                    key={project.id}
                    title={project.name}
                    link={`/projects/${project.id}`}
                    value={parseFloat(project.project_value).toFixed(2) + " €"}
                    expireDate={project.estimated_delivery_date}
                    nextInteractionId={project.next_interaction_id}
                    editInteraction={editInteraction}
                    nextInteractionDate={project.next_interaction_date}
                    nextInteractionDescription={project.next_interaction_description}
                    missedDeadlineId={project.missed_deadline_id}
                    missedDeadlineDate={project.missed_deadline_date}
                    missedDeadlineDescription={project.missed_deadline_description}
                    billableAmount={project.billable_amount}
                    customerId={project.customer}
                    borderDanger={project.support_period_ended}
                    annotations={annotations}
                    tags={project.tags_data}
                    nextBillDate={project.next_bill_date}
                  />
                )
              })}
            </Column>
            {/* COLUMN closed_projects hidden due to #339 */}
            {/* <Column title={t("overview.col_closed_projects")} length={data.closed_projects.length}>
              {data.closed_projects.map((project) => (
                <Card
                  key={project.id}
                  title={project.name}
                  link={`/projects/${project.id}`}
                  value={parseFloat(project.project_value).toFixed(2) + " €"}
                  nextInteractionId={project.next_interaction_id}
                  editInteraction={editInteraction}
                  nextInteractionDate={project.next_interaction_date}
                  nextInteractionDescription={project.next_interaction_description}
                  billableAmount={project.billable_amount}
                  customerId={project.customer}
                  tags={project.tags_data}
                />
              ))}
            </Column> */}
          </div>
        </div>
      )}
      {/* <pre>{JSON.stringify(data, undefined, 2)}</pre> */}
      {editModalState.value && (
        <CreateCustomerInteractionModal
          isOpen={editModalState.isOpen}
          onClosed={editModalActions.onClose}
          toggle={editModalActions.close}
          onSave={(result) => {
            editModalActions.close()
            return update
              .onSuccess(() => {
                reload()
              })
              .asPromise(editModalState.value.id, result)
          }}
          interaction={editModalState.value}
          relatedFilterDefault={{ customer: editModalState.value.customer }}
        />
      )}
      <Layout.FirstLevelNavi>
        <IndicatorsLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import React from "react"
import { useUnreadNotificationCount } from "../../hooks/notifications"
import Icon from "../Icon/Icon"
import S from "./NotificationsCenter.module.scss"
import classnames from "classnames"
import useWpHistoryMethods from "../../hooks/useWpHistoryMethods"

export default function NotificationCenter({ className }) {
  const unreadCount = useUnreadNotificationCount()
  const history = useWpHistoryMethods()

  return (
    <div
      className={classnames(S["wrapper"], className)}
      onClick={() => {
        history.push("/notifications")
      }}
    >
      <Icon name="notification" className={S["icon"]} />
      {unreadCount > 0 && <div className={S["badge"]}>{unreadCount}</div>}
    </div>
  )
}

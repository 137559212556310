import dayjs from "dayjs"
import { truncate } from "lodash"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import Button from "../../components/Button"
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import { CreateIncomingRequestModal } from "../../components/CreateIncomingRequestModal"
import { CRMLandmarks } from "../../components/CRMLandmarks"
import { CustomerSelector } from "../../components/CustomerSelector/CustomerSelector"
import Dropdown from "../../components/Dropdown"
import { DropdownItemCreateInteraction } from "../../components/DropdownItemCreateInteraction"
import { DropdownItemDelete } from "../../components/DropdownItemDelete"
import Icon from "../../components/Icon"
import Layout from "../../components/Layout"
import LongBorder from "../../components/LongBorder"
import Pagination from "../../components/Pagination/Pagination"
import Separator from "../../components/Separator"
import Table from "../../components/Table"
import WpInput from "../../components/WpInput"
import WpLink from "../../components/WpLink"
import { useIncomingRequests } from "../../hooks/incomingRequest"
import useCurrentOrganization from "../../hooks/useCurrentOrganization"
import useModalTrigger from "../../hooks/useModalTrigger"

export function Requests() {
  const { t } = useTranslation(["translation", "tab", "action"])

  const [
    { page, status, customer, code, title },
    setQueryParams,
    { page: debPage, status: debStatus, customer: debCustomer, code: debCode, title: debTitle },
    setDebouncedQueryParams,
  ] = useQueryParams({
    page: {
      encode: (intValue) => intValue.toString(10),
      decode: (strValue) => (strValue ? parseInt(strValue, 10) : 1),
    },
    status: {
      encode: (val) => val,
      decode: (val) => val || "active",
    },
  })

  const filter = useMemo(() => {
    let s = debStatus
    if (s === "all") {
      s = undefined
    } else if (s === "active") {
      s = "active"
    }
    return {
      page: debPage,
      status: s,
      code: debCode,
      title: debTitle,
      customer: debCustomer,
    }
  }, [debCode, debCustomer, debPage, debStatus, debTitle])

  const [createModalState, createModalActions] = useModalTrigger()
  const [deleteModalState, deleteModalActions] = useModalTrigger()

  const [{ requests, count, pageCount, hasNext, hasPrev }, { create, remove, run: reload }] = useIncomingRequests(filter)

  const org = useCurrentOrganization()

  const columns = useMemo(
    () => [
      {
        label: t("field:incoming_request.created_at"),
        name: "created_at",
        size: 1,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-column h-100 w-100 align-items-start justify-content-center">
              {dayjs(selectedDatum).format("DD/MM/YYYY")}
            </div>
          )
        },
      },
      {
        label: t("field:incoming_request.customer"),
        name: "customer_name",
        size: 3,
        render: (selectedDatum, colName, datum) => {
          return <WpLink to={`/customers/${datum.customer}`}>{selectedDatum}</WpLink>
        },
      },
      {
        label: t("field:incoming_request.code"),
        name: "code",
        size: 2,
      },
      {
        label: t("field:incoming_request.title"),
        name: "title",
        size: 3,
      },
      {
        label: t("field:incoming_request.status"),
        name: "status",
        size: 2,
        render: (selectedDatum, colName, datum) => {
          return <span>{t(`enums:incoming_request_status.${selectedDatum}`)}</span>
        },
      },
      {
        label: t("actions"),
        name: "actions",
        size: 1,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <WpLink
                className={"action-icon-container-primary-hover"}
                to={`/customers/${datum.customer}/requests/${datum.id}`}
              >
                <Icon name="edit" title={t("action:edit_incoming_request")} className="pointer text-dark" />
              </WpLink>
              <div className={"action-icon-container-primary-hover"}>
                <UncontrolledDropdown>
                  <DropdownToggle caret={false} tag={"span"}>
                    <Icon
                      role="button"
                      name="vdots"
                      className="text-dark pointer"
                      title={t("action:other_options")}
                      placement="right"
                    />
                  </DropdownToggle>
                  <DropdownMenu right modifiers={{ offset: { offset: "0, 12" } }} className="border-primary">
                    <DropdownItemCreateInteraction
                      initialValues={{
                        request: datum.id,
                        customer: datum.customer,
                      }}
                      lockedFields={["request"]}
                    />

                    <Separator className="my-0 mx-4 border-primary" />
                    <DropdownItemDelete
                      onClick={() => {
                        deleteModalActions.open(datum)
                      }}
                    />
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )
        },
      },
    ],
    [deleteModalActions, t]
  )

  return (
    <Layout>
      <div className="container-fluid pt-5 px-page pb-page-bottom">
        <div className="d-flex flex-row justify-content-between align-items-end mt-8 mb-5">
          <h1 className="text-primary font-weight-semibold">{t("incoming_requests.title")}</h1>
          <Button onClick={() => createModalActions.open("dummy")}>{t("action:new_incoming_request")}</Button>
        </div>
        <div className="d-flex flex-column mt-4 mb-7">
          <div className="d-flex flex-row align-items-center justify-content-start mt-3 ml-3">
            <div className="d-flex flex-row justify-content-start">
              <div className="pr-5 d-flex flex-column">
                <span className="mr-3">{t("incoming_requests.filter_code")}</span>
                <WpInput
                  medium
                  placeholder={t("field:incoming_request.code")}
                  value={code}
                  style={{ width: 150 }}
                  onChange={(e) =>
                    setDebouncedQueryParams({
                      code: e.target.value,
                      page: 1,
                    })
                  }
                  className="pl-3"
                />
              </div>
              <div className="pr-5 d-flex flex-column">
                <span className="mr-3">{t("incoming_requests.filter_title")}</span>
                <WpInput
                  medium
                  placeholder={t("field:incoming_request.title")}
                  style={{ width: 150 }}
                  value={title}
                  onChange={(e) =>
                    setDebouncedQueryParams({
                      title: e.target.value,
                      page: 1,
                    })
                  }
                  className="pl-3"
                />
              </div>
              <div className="pr-5 d-flex flex-column">
                <span className="mr-3">{t("incoming_requests.filter_status")}</span>
                <Dropdown
                  value={status}
                  className="px-3 py-2"
                  options={[
                    {
                      value: "active",
                      label: t("enums:incoming_request_status.active"),
                    },
                    {
                      value: "pending",
                      label: t("enums:incoming_request_status.pending"),
                    },
                    {
                      value: "waiting-estimate",
                      label: t("enums:incoming_request_status.waiting-estimate"),
                    },
                    {
                      value: "completed",
                      label: t("enums:incoming_request_status.completed"),
                    },
                    {
                      value: "dismissed",
                      label: t("enums:incoming_request_status.dismissed"),
                    },
                    {
                      value: "all",
                      label: t("enums:incoming_request_status.all"),
                    },
                  ]}
                  onChange={(val) => {
                    setQueryParams({ status: val, page: 1 })
                  }}
                  itemWidth={200}
                  style={{ width: 200 }}
                />
              </div>
              <div className="pr-5 d-flex flex-column">
                <span className="mr-3">{t("incoming_requests.filter_customer")}</span>
                <CustomerSelector
                  orgId={org.id}
                  customerId={customer}
                  enableNone
                  caption={
                    !customer
                      ? () => <span className="text-muted">{t("estimate_list.filter_customer_placeholder")}</span>
                      : undefined
                  }
                  onChange={(nextCustomerId) => setQueryParams({ customer: nextCustomerId })}
                  itemWidth={200}
                  style={{ width: 200 }}
                />
              </div>
            </div>
          </div>
        </div>
        {requests && count > 0 && (
          <>
            <LongBorder topBar="toolbar" />

            <Table columns={columns} data={requests} className="pb-8" highlightRows={[]} />

            <Pagination
              current={page}
              pageCount={pageCount}
              goToPage={(page) => {
                setQueryParams({ page })
                window.scrollTo(0, 10)
              }}
              hasNext={hasNext}
              hasPrev={hasPrev}
            />
          </>
        )}
      </div>
      {createModalState.value && (
        <CreateIncomingRequestModal
          isOpen={createModalState.isOpen}
          onClosed={createModalActions.onClose}
          toggle={createModalActions.toggle}
          selectCustomer
          onSave={(result) => {
            return create
              .onSuccess(() => {
                createModalActions.close()
                reload(filter)
              })
              .asPromise(result)
          }}
        />
      )}
      {deleteModalState.value && (
        <ConfirmDeleteModal
          toggle={deleteModalActions.toggle}
          isOpen={deleteModalState.isOpen}
          onConfirm={() => {
            remove
              .onSuccess(() => {
                reload(filter)
                deleteModalActions.close()
              })
              .run(deleteModalState.value.id)
          }}
          onClosed={deleteModalActions.onClose}
          item={truncate(deleteModalState.value.title, { length: 20 })}
        />
      )}
      <Layout.FirstLevelNavi>
        <CRMLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

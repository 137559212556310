import React from "react"
import { useTranslation } from "react-i18next"
import { Spinner } from "reactstrap"

function BaseLoader({ message, position = "fixed" }) {
  return (
    <div
      className={`position-${position} left-0 right-0 top-50 d-flex flex-column justify-content-start align-items-center`}
    >
      <Spinner size="lg" color="primary" />
      {message && (
        <p className="font-weight-semibold text-primary mt-4">{message}</p>
      )}
    </div>
  )
}

function Loader(props) {
  const { t } = useTranslation(["translation"])

  return <BaseLoader {...props} message={t("estimate_list.loading")} />
}

Loader.BaseLoader = BaseLoader

export default Loader

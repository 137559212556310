import React from "react"
import classNames from "classnames"
import S from "./ImagePreview.module.scss"

/**
 *
 * @typedef {Object} ImagePreviewProps
 * @property {string|null} src
 * @property {"circle"|"square"} shape
 * @property {string} className
 * @property {boolean} active
 * @property {"xs"|"sm"|"md"} size
 * @property {string} placeholder
 */

/**
 *
 * @param {ImagePreviewProps} props
 */
export default function ImagePreview({
  src = null,
  shape = "circle",
  active = false,
  className,
  size = "md",
  disableHover = false,
  placeholder = null,
  title = "",
}) {
  return (
    <div
      className={classNames(
        S["image-preview-container"],
        S[shape],
        { [S["active"]]: active },
        S[size],
        { [S["allow-hover"]]: !disableHover },
        className
      )}
    >
      <img src={src || placeholder} alt="profile" title={title} />
    </div>
  )
}

import { keyBy } from "lodash"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { getDepth } from "react-sortable-tree"
import Table from "../../../../components/Table/Table"
import { flattenCutTree, useColumns, useEditableColumns } from "./utils"
import classNames from "classnames"
import S from "./TaskTable.module.scss"
import { adaptForScenarios, computeScenarioPrices } from "../../treeOperations"
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter"

export default function TaskTable({
  taskTree,
  maxDepth,
  resources,
  isResourceVisible,
  isCostVisible,
  isScenarioVisible,
  estimateId,
  onEditTaskClick,
  editable = true,
  stickyHeader = true,
  visibleTasks = [],
  isDotsVisible = true,
  isTotalVisible = true,
  readOnly = false,
  scenarios = [],
  selectedScenarios = [],
  fullEstimate,
  use24 = false,
  arePricesVisible=true,
  sliceRowStart = undefined,
  sliceRowEnd = undefined,
  ...props
}) {
  const { t } = useTranslation(["translation", "field", "action"])
  const currencyFmt = useCurrencyFormatter()

  const depth = getDepth({ children: taskTree })

  const flattenedTasks = useMemo(() => {
    return flattenCutTree(taskTree, maxDepth, scenarios)
  }, [maxDepth, taskTree, scenarios])

  const tasks = useMemo(() => {
    return flattenedTasks.slice(sliceRowStart, sliceRowEnd)
  }, [flattenedTasks, sliceRowEnd, sliceRowStart])

  const boundaryElements = Object.values(keyBy(tasks, (t) => t.path.split(".")[0])).map((task) => task.id)

  const history = useHistory()

  const columns = useColumns(
    isResourceVisible,
    isScenarioVisible,
    resources,
    scenarios,
    selectedScenarios,
    t,
    isDotsVisible,
    depth,
    maxDepth,
    arePricesVisible
  )

  const editableColumns = useEditableColumns(
    isResourceVisible,
    isCostVisible,
    isScenarioVisible,
    resources,
    scenarios,
    selectedScenarios,
    t,
    depth,
    history,
    estimateId,
    onEditTaskClick,
    readOnly,
  )

  const scenarioPrices = useMemo(() => computeScenarioPrices(fullEstimate), [fullEstimate])
  const estimateForBase = useMemo(() => adaptForScenarios(fullEstimate, []), [fullEstimate])

  return (
    <>
      <Table
        columns={editable ? editableColumns : columns}
        resources={resources}
        data={tasks}
        centered
        highlightRows={[]}
        rowClassname={(datum) => (boundaryElements.includes(datum.id) ? "border-2" : "")}
        stickyHeader={stickyHeader}
        stickyTopBar="tabbar"
        use24={use24}
      />
      {isTotalVisible && (
        <div className={classNames("row no-gutters py-6 page-break-inside-avoid", S["total-row"])}>
          <div className={classNames("col pl-6 font-weight-bold text-uppercase")}>{t("field:task.total")}</div>
          <div
            className={classNames("font-weight-bold text-right pr-4", {
              "col-2": !editable && !use24,
              "col-1": editable && !use24,
              "col-24-4": !editable && use24,
              "col-24-3": editable && use24,
            })}
          >
            <div className="d-flex flex-column h-100 w-100">
              {Math.abs(estimateForBase.price) > 0.01 &&
                !editable &&
                (selectedScenarios === null || selectedScenarios.length === 0 || selectedScenarios.includes(0)) &&
                currencyFmt.format(estimateForBase.price)}
              {fullEstimate.scenarios.map((scenario) => {
                if (selectedScenarios === null || selectedScenarios.includes(scenario.id)) {
                  if (Math.abs(scenarioPrices[scenario.id] ?? 0) < 0.01) {
                    return null
                  }
                  return (
                    <span key={scenario.id}>
                      <small>{scenario.code + ": "}</small>
                      {currencyFmt.format(scenarioPrices[scenario.id] ?? 0)}
                    </span>
                  )
                } else {
                  return null
                }
              })}
            </div>
          </div>
          {editable && <div className={classNames({ "col-1": !use24, "col-24-1": use24 })}></div>}
        </div>
      )}
    </>
  )
}

import { Field } from "formik"
import { keyBy } from "lodash"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import FieldColorPicker from "../../../../../components/fields/FieldColorPicker"
import FieldImageLinear from "../../../../../components/fields/FieldImageLinear"
import FieldInput from "../../../../../components/fields/FieldInput"
import FieldRichText from "../../../../../components/fields/FieldRichText"
import FieldSlider from "../../../../../components/fields/FieldSlider"
import FieldSwitch from "../../../../../components/fields/FieldSwitch"
import Separator from "../../../../../components/Separator"
import { useAttachmentUploader } from "../../../../../hooks/attachment"
import EditDataFrame from "../../EditDataFrame/EditDataFrame"

const marks = keyBy(
  [
    { id: 0, label: "0%" },
    { id: 25, label: "25%" },
    { id: 33, label: "33%" },
    { id: 50, label: "50%" },
    { id: 75, label: "75%" },
    { id: 100, label: "100%" },
  ],
  "id"
)

const EditDataForm = ({
  fullEstimate,
  frag,
  save,
  remove,
  saveTemplate,
  pickCover,
  toggle,
  scrollFragIntoView,
}) => {
  const { t } = useTranslation(["translation", "field"])
  const readOnly = fullEstimate.readonly

  const { upload } = useAttachmentUploader()

  const saveWithUpload = useCallback(
    (values) => {
      if (values.data.coverImage instanceof File) {
        return upload.asPromise(values.data.coverImage).then((uploaded) =>
          save({
            ...values,
            data: {
              ...values.data,
              // TODO: Keep in mind also for rich text should keep some
              // soft reference to uploaded stuff 2 a future garabage collector
              // to dead images??? For now no lol but can be a good thought
              // for now is only in this comment but who knows
              coverImage: uploaded.file,
            },
          })
        )
      }
      return save(values)
    },
    [save, upload]
  )

  return (
    <EditDataFrame
      frag={frag}
      save={saveWithUpload}
      toggle={toggle}
      saveTemplate={saveTemplate}
      pickCover={pickCover}
      scrollFragIntoView={scrollFragIntoView}
      readOnly={readOnly}
      scenarios={fullEstimate.scenarios}
      enableShowTitle={false}
      allowMargin={false}
      enableScenarioSelector={false}
    >
      {({ values }) => (
        <>
          <Field
            name="data.displayLogo"
            label={t("field:document.cover.show_logo")}
            message={t("field:document.cover.show_logo_message")}
            component={FieldSwitch}
            readOnly={readOnly}
          />

          {values.data?.displayLogo && (
            <>
              <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                <div className="">{t("field:document.cover.logo_width")}</div>
                <div className="d-flex flex-row align-items-center justify-content-end">
                  <Field
                    name="data.logoWidth"
                    component={FieldInput}
                    type="number"
                    tiny
                    className="text-right"
                    min={10}
                    readOnly={readOnly}
                  />
                  <span className="ml-1">px</span>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                <div className="">{t("field:document.cover.logo_height")}</div>
                <div className="d-flex flex-row align-items-center justify-content-end">
                  <Field
                    name="data.logoHeight"
                    component={FieldInput}
                    type="number"
                    tiny
                    className="text-right"
                    min={10}
                    readOnly={readOnly}
                  />
                  <span className="ml-1">px</span>
                </div>
              </div>
            </>
          )}

          <Separator className="mx-0" />

          <Field
            name="data.pageFillFactor"
            label={t("field:document.cover.dimension")}
            component={FieldSlider}
            min={0}
            max={100}
            step={1}
            marks={marks}
            readOnly={readOnly}
          />

          <Separator className="mx-0" />

          <Field
            name="data.footerCols"
            label={t("field:document.cover.show_footer")}
            message={t("field:document.cover.show_footer_message")}
            component={FieldSwitch}
            readOnly={readOnly}
          />
          <Separator className="mx-0" />

          <div className="d-flex flex-row justify-content-between align-items-start">
            <div className="font-weight-semibold h3 text-uppercase">
              {t("field:document.cover.full_cover_image")}
            </div>
            <div className="position-relative">
              <Field
                name="data.coverImage"
                component={FieldImageLinear}
                readOnly={readOnly}
                shape="square"
                enableDelete
              />
            </div>
          </div>

          <Separator className="mx-0 mt-0" />

          <div className="d-flex flex-row justify-content-between align-items-start">
            <div className="font-weight-semibold h3 text-uppercase">
              {t("field:document.cover.full_cover_color")}
            </div>
            <div className="position-relative">
              <Field
                name="data.coverColor"
                component={FieldColorPicker}
                readOnly={readOnly}
                bottom={true}
              />
            </div>
          </div>

          <Separator className="mx-0" />

          <Field
            name="data.mainText"
            label={t("field:document.cover.main_text")}
            component={FieldRichText}
            readOnly={readOnly}
          />

          <Separator className="mx-0" />

          <Field
            name="data.footerText1"
            label={
              frag.data?.footerCols
                ? t("field:document.cover.footer_1")
                : t("field:document.cover.footer_1")
            }
            component={FieldRichText}
            readOnly={readOnly}
          />

          <Separator className="mx-0" />

          {frag.data?.footerCols && (
            <>
              <Field
                name="data.footerText2"
                label={t("field:document.cover.footer_2")}
                component={FieldRichText}
                readOnly={readOnly}
              />
              <Separator className="mx-0" />
            </>
          )}
        </>
      )}
    </EditDataFrame>
  )
}

export default EditDataForm

import React, { useMemo } from "react"
import classNames from "classnames"
import ScenarioSymbol from "../../../../../components/ScenarioSymbol"
import {
  adaptForScenarios,
  computeScenarioPrices,
} from "../../../treeOperations"
import { useTranslation } from "react-i18next"
import { pagerLog } from "../../Pager"
import useCurrencyFormatter from "../../../../../hooks/useCurrencyFormatter"

const ScenarioBox = ({
  frag,
  scenario,
  price,
  showSymbol = true,
  showDescription,
}) => {
  const currencyFmt = useCurrencyFormatter()
  return (
    <div className="__pager-node">
      <div
        className={classNames(
          "d-flex justify-content-between align-items-center pb-5"
        )}
      >
        <div className="d-flex flex-row align-items-center">
          {showSymbol && <ScenarioSymbol scenario={scenario} />}
          <h2 className={classNames({ "ml-3": showSymbol })}>
            <span>{scenario.name}</span>
          </h2>
        </div>
        {frag.data.showPrice && (
          <div>
            <span>{currencyFmt.format(price)}</span>
          </div>
        )}
      </div>
      {showDescription && <p className="m-0 pb-5">{scenario.description}</p>}
    </div>
  )
}

const FragComponent = React.memo(
  ({
    frag,
    fullEstimate,
    sliceRowStart: sliceBlockStart,
    sliceRowEnd: sliceBlockEnd,
    chunkIsFirst,
    inPage,
  }) => {
    if (inPage) {
      pagerLog("[Pager] Scenario list rendered")
    }
    const { t } = useTranslation(["translation"])
    const scenarioPrices = useMemo(() => computeScenarioPrices(fullEstimate), [
      fullEstimate,
    ])
    const estimateForBase = useMemo(() => adaptForScenarios(fullEstimate, []), [
      fullEstimate,
    ])
    const scenariobase = useMemo(
      () => ({
        name: t("documents.frag.scenario_list.scenario_base"),
        description: t(
          "documents.frag.scenario_list.scenario_base_description"
        ),
      }),
      [t]
    )

    return (
      <div className="bg-white ">
        {frag?.data?.showTitle && chunkIsFirst && (
          <h1 className="__pager-title m-0 pb-3">{frag?.title}</h1>
        )}
        {frag.data.showBaseScenario &&
          (sliceBlockStart === undefined || sliceBlockStart === 0) && (
            <ScenarioBox
              frag={frag}
              scenario={scenariobase}
              price={estimateForBase.price}
              showSymbol={false}
              showDescription={frag.data.showDescription}
            />
          )}
        {fullEstimate.scenarios.map((scenario, i) => {
          const baseIndex = frag.data.showBaseScenario ? 1 : 0
          const idx = i + baseIndex
          if (
            (sliceBlockStart !== undefined && idx < sliceBlockStart) ||
            (sliceBlockEnd !== undefined && idx >= sliceBlockEnd)
          ) {
            return null
          }
          return (
            <ScenarioBox
              key={scenario.id}
              frag={frag}
              scenario={scenario}
              price={scenarioPrices[scenario.id] ?? 0}
              showDescription={frag.data.showDescription}
            />
          )
        })}
      </div>
    )
  },
  (prevProps, nextProps) => {
    const checkProps = [
      "frag",
      "fullEstimate",
      "sliceRowStart",
      "sliceRowEnd",
      "chunkIsFirst",
    ]
    for (let i = 0; i < checkProps.length; i++) {
      const prop = checkProps[i]
      if (prevProps[prop] !== nextProps[prop]) {
        return false
      }
    }
    return true
  }
)

export default FragComponent

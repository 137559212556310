import React, { Fragment } from "react"
import classNames from "classnames"
import Icon from "../../../../components/Icon"
import { useTranslation } from "react-i18next"
import Separator from "../../../../components/Separator"
import S from "./ResourceCard.module.scss"
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter"
import { useLocalizer } from "../../../../hooks/useLocalizer"

export function ResourceUnitTable({ data, isCostVisible, showTitle = true }) {
  const { t, i18n } = useTranslation(["translation", "field"])
  const l = useLocalizer(i18n.language)

  const currencyFmt = useCurrencyFormatter()
  return (
    <div className="flex-fill">
      {showTitle && <span className="font-weight-semibold text-uppercase pl-2">
        {t("tasks.price_and_quantity")}
      </span>}
      {data.length > 0 && (
        <div
          className={classNames(
            "row no-gutters pt-3 pb-2 border-bottom border-dark"
          )}
        >
          <div className="col text-uppercase font-weight-semibold">
            {t("field:resource.price_list.cost_unit")}
          </div>
          <div className="col text-right text-uppercase font-weight-semibold">
            {t("field:resource.price_list.size")}
          </div>
          {isCostVisible && (
            <div className="col text-right text-uppercase font-weight-semibold">
              {t("field:resource.price_list.unitary_cost")}
            </div>
          )}
          <div className="col text-right text-uppercase font-weight-semibold">
            {t("field:resource.price_list.unitary_price")}
          </div>
        </div>
      )}
      {data.map((unit, i) => {
        return (
          <Fragment key={i}>
            <div className={classNames("row no-gutters py-2")}>
              <div className="col">{unit[l`cost_unit`] || unit.cost_unit}</div>
              <div className="col text-right">{unit.size}</div>
              {isCostVisible && (
                <div className="col text-right">
                  {currencyFmt.format(unit.unitary_cost)}
                </div>
              )}
              <div className="col text-right">
                {currencyFmt.format(unit.unitary_price)}
              </div>
            </div>
            {i !== data.length - 1 && <Separator className="mx-3 my-0" />}
          </Fragment>
        )
      })}
    </div>
  )
}

export default function ResourceCard({
  resource,
  isCostVisible,
  isUnitTableVisible,
  forceOpen,
  isOpen = false,
  toggleResourceOpen,
}) {
  const { t, i18n } = useTranslation(["translation", "enums"])
  const l = useLocalizer(i18n.language)

  const currencyFmt = useCurrencyFormatter()
  return (
    <div className={classNames(S["resource-row"], "page-break-inside-avoid")}>
      <div
        className={classNames(S["resource-header"], {
          [S["no-resource-border"]]: isOpen,
        })}
        onClick={() => toggleResourceOpen(resource.resource_id)}
      >
        <div
          className={classNames(
            "d-flex flex-row h-100 w-100",
            S["header-container"]
          )}
        >
          <div className={classNames(S["resource-header-section"], "py-5")}>
            <Icon
              name={resource.resource_type}
              title={t(`enums:resource_type.${resource.resource_type}`)}
              style={{ width: 24, height: 24, marginLeft: 12 }}
            />
          </div>

          <Separator vertical className="my-4 mx-4" />

          <div
            className={classNames(
              S["resource-header-section"],
              S["resource-header-section-title"]
            )}
          >
            <span>{resource[l`name`] || resource.name}</span>
          </div>

          {isCostVisible && (
            <>
              <Separator vertical className="my-4 mr-4 ml-2" />
              <div
                className={classNames(S["resource-header-section"], S["cost"])}
              >
                <span>{currencyFmt.format(resource.total_cost)}</span>
              </div>
            </>
          )}

          <div className={classNames(S["resource-header-section-money"])}>
            <span>{currencyFmt.format(resource.total_price)}</span>
          </div>
        </div>
      </div>
      {(forceOpen !== undefined ? forceOpen : isOpen) && (resource.description !== "" || isUnitTableVisible) && (
        <div className="border-bottom">
          <div className={classNames(S["resource-detail-card"])}>
            {(resource[l`description`] || resource.description) !== "" && (
              <div
                className={classNames(S["resource-description"], {
                  [S["long"]]: !isUnitTableVisible,
                })}
              >
                <span className="font-weight-semibold text-uppercase pl-2">
                  {t("field:resource.description")}
                </span>
                <p className="text-justify pt-2">{resource[l`description`] || resource.description}</p>
              </div>
            )}
            {resource.description !== "" && isUnitTableVisible && (
              <Separator vertical className={classNames("mx-4 mt-0 mb-0")} />
            )}
            {isUnitTableVisible && (
              <div className={classNames(S["resource-unit-table"])}>
                <ResourceUnitTable
                  data={resource.details}
                  isCostVisible={isCostVisible}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

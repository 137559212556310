import { isPlainObject, get, mapValues } from "lodash"
import { loadStripe } from "@stripe/stripe-js"
import i18n from "i18next"
import { toast } from "react-toastify"
import reduce from "lodash/reduce"
import { AjaxError } from "rxjs/ajax"

export function fromRichText2Text(richState) {
  return reduce(richState?.blocks, (res, block) => res + " " + block.text, "")
    .replace(/ +/g, " ")
    .trim()
}

export function flattenErrors(obj) {
  if (Array.isArray(obj)) {
    if (obj.every((item) => typeof item === "string")) {
      return obj
    }
    const out = {}
    for (let i = 0; i < obj.length; i++) {
      const flattened = flattenErrors(obj[i])
      if (typeof flattened === "object" && !Array.isArray(flattened)) {
        for (const key in flattened) {
          out[`${i}.${key}`] = flattened[key]
        }
      } else {
        out[i.toString()] = flattened
      }
    }
    return out
  } else if (typeof obj === "object" && obj !== null) {
    const out = {}
    for (const k in obj) {
      const value = flattenErrors(obj[k])
      if (typeof value === "object" && !Array.isArray(value)) {
        for (const key in value) {
          out[`${k}.${key}`] = value[key]
        }
      } else {
        out[k] = value
      }
    }
    return out
  } else {
    return obj
  }
}

export function arrayze(arg) {
  if (Array.isArray(arg)) {
    return arg
  }
  if (arg !== undefined) {
    return [arg]
  }
  return []
}

function concatErrors(errorData) {
  if (!errorData || Object.keys(errorData).length === 0) {
    return undefined
  }
  return mapValues(errorData, (listOfErrors) => {
    if (listOfErrors.every((item) => typeof item === "string")) {
      return arrayze(listOfErrors).join(",")
    } else {
      return listOfErrors.map((item) => concatErrors(item))
    }
  })
}

export function convertErrorToForm(error) {
  if (error.status === 400) {
    let errorData
    if (error instanceof AjaxError && isPlainObject(error.response)) {
      errorData = error.response
    } else if (isPlainObject(get(error, "response.body"))) {
      errorData = error.response.body
    }
    // TODO: Better joins of errors...
    if (errorData) {
      return concatErrors(errorData)
    }
  }
  // When no 400 (not related 2 form)
  // add a special __noFieldsServerError key \w original error
  return {
    __noFieldsServerError: error,
  }
}

// Combine it with enableReinitialize
export function onResetHackReinitializeToKeepDirtyUpdate(values, methods) {
  // Force update of formik
  methods.setFormikState((state) => ({ ...state }))
  // Prevent erasing values
  // https://github.com/formium/formik/blob/master/packages/formik/src/Formik.tsx#L438
  const noopPromise = new Promise(() => {})
  return noopPromise
}

export function calcMargin(cost, price) {
  const _cost = parseFloat(cost)
  const _price = parseFloat(price)
  if (_price !== 0 && !isNaN(_price) && !isNaN(_cost)) {
    return (((price - cost) / price) * 100).toFixed(2)
  }
  return "-"
}

const prettyNumberFormatter = new Intl.NumberFormat("en-En", {
  style: "decimal",
  maximumFractionDigits: 2,
})

export function numberPrettyFormat(n) {
  return prettyNumberFormatter.format(n)
}

export function cmpFloat(a, b) {
  if (a === b) {
    return true
  }
  const fa = parseFloat(a)
  const fb = parseFloat(b)
  if (fa === fb) {
    return true
  }
  if (isNaN(fa) && isNaN(fb)) {
    return true
  }
  return false
}

export function notifyError(error) {
  // TODO: Maybe write different sentence from error status or something else...
  // NOTE: Cause we don't use a i18n Provider is safe to use i18n.t here
  toast.error(i18n.t("error_notify_message"))
}

export function prefixLocationWithOrg(location, organizationId) {
  if (organizationId) {
    const orgPrefix = `/org/${organizationId}`
    if (typeof location === "string") {
      return orgPrefix + location
    } else if (typeof location === "object" && location !== null) {
      return {
        ...location,
        pathname: orgPrefix + (location?.pathname ?? ""),
      }
    } else {
      // NOTE: `to` prop can be a function for now we ignore this branch
      // if in future we need org inject in to as function return here my boy
      return location
    }
  } else {
    return location
  }
}

export function getStripe() {
  return loadStripe(process.env.REACT_APP_STRIPE_KEY)
}

export function fixNullIfEmpty(context, ...fields) {
  for (const field of fields) {
    if (!context[field]) {
      context[field] = null
    }
  }
}

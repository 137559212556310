import React from "react"
import { useTranslation } from "react-i18next"
import EditDataFrame from "../../EditDataFrame/EditDataFrame"

const EditDataForm = ({
  fullEstimate,
  frag,
  save,
  remove,
  toggle,
  scrollFragIntoView,
}) => {
  const readOnly = fullEstimate.readonly
  const { t } = useTranslation(["action"])
  return (
    <EditDataFrame
      contentType={t("action:page_break")}
      frag={frag}
      save={save}
      remove={remove}
      toggle={toggle}
      scrollFragIntoView={scrollFragIntoView}
      readOnly={readOnly}
      enableScenarioSelector={false}
      enableShowTitle={false}
      allowMargin={false}
    ></EditDataFrame>
  )
}

export default EditDataForm

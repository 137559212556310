import React from "react"
import S from "./style.module.scss"

export function Column({ title, children, length }) {
  return (
    <div className={S.column}>
      <div className={S.title}>
        <span>{title}</span>
        <span>{length}</span>
      </div>
      {React.Children.count(children) > 0 && (
        <div className={S.children}>{children}</div>
      )}
    </div>
  )
}

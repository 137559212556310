import React, { Component } from "react"
import openlink from "./openlink.svg"
import S from "./RichTextEditor.module.scss"

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    )
  }, callback)
}

class Link extends Component {

  openLink = () => {
    const { entityKey, contentState } = this.props
    const { url } = contentState.getEntity(entityKey).getData()
    const linkTab = window.open(url, "blank") // eslint-disable-line no-undef
    // linkTab can be null when the window failed to open.
    if (linkTab) {
      linkTab.focus()
    }
  }

  render() {
    const { children, entityKey, contentState } = this.props
    const { url, targetOption } = contentState.getEntity(entityKey).getData()

    return (
      <span className="rdw-link-decorator-wrapper">
        <a
          href={url}
          target={targetOption}
          className={S["rich-text-editor-link"]}
        >
          {children}
          <img
            src={openlink}
            alt=""
            onClick={this.openLink}
          />
        </a>
      </span>
    )
  }
}

const LinkDecorator = {
  strategy: findLinkEntities,
  component: Link,
}

export default LinkDecorator

import React, { useContext } from "react"
import { ResourcesOpensContext } from "../../../../context"
import ResourceCard from "../ResourceCard"

export default function ResourceList({
  isCostVisible,
  isUnitTableVisible,
  visibleResourceType,
  resources,
  forceOpen,
  sliceRowStart,
  sliceRowEnd,
}) {
  const [opens, toggleOpen] = useContext(ResourcesOpensContext)
  return (
    <div>
      {resources.slice(sliceRowStart, sliceRowEnd).map((r) => (
        <div className="__pager-node">
          <ResourceCard
            isOpen={!!opens[r.resource_id]}
            toggleResourceOpen={toggleOpen}
            key={r.resource_id}
            resource={r}
            isCostVisible={isCostVisible}
            isUnitTableVisible={isUnitTableVisible}
            forceOpen={forceOpen}
          />
        </div>
      ))}
    </div>
  )
}

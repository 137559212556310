import React, { useRef, useState } from "react"
import { getIn } from "formik"
import { useTranslation } from "react-i18next"
import WpInput from "../WpInput"
import ErrorPopover from "../ErrorPopover/ErrorPopover"
import useIsMount from "../../hooks/useIsMount"
import Icon from "../Icon"
import classNames from "classnames"
import AutoDebWpInput from "../AutoDebWpInput"

export default function FieldInput({
  field,
  form: { touched, errors },
  label,
  type = "text",
  enableShowHide = "false",
  className = "",
  containerClassName = "",
  debounce=false,
  ...props
}) {
  const { t } = useTranslation(["field"])
  const input = useRef()
  const isMount = useIsMount()
  const fieldError = getIn(errors, field.name)
  const fieldTouched = getIn(touched, field.name)
  const showError = fieldError && fieldTouched

  const [showPassword, setShowPassword] = useState(false)
  const [currentType, setCurrentType] = useState("password")

  const Impl = debounce ? AutoDebWpInput : WpInput

  return (
    <div className={`d-flex flex-column ${containerClassName}`}>
      {label && (
        <label className="font-weight-semibold mb-3 h3 text-uppercase">
          {label}
        </label>
      )}
      {(type !== "password" || (type === "password" && !enableShowHide)) && (
        <Impl
          ref={input}
          invalid={showError}
          type={type}
          className={className}
          {...field}
          {...props}
        />
      )}
      {type === "password" && enableShowHide && (
        <div
          className={classNames(
            "d-flex flex-row align-items-center justify-content-start w-100",
            className
          )}
        >
          <Impl
            ref={input}
            invalid={showError}
            type={currentType}
            className={classNames("mr-3 flex-1")}
            {...field}
            {...props}
          />
          <Icon
            name={showPassword ? "hide" : "view"}
            title={
              showPassword
                ? t("action:hide_password")
                : t("action:show_password")
            }
            className="action-icon-container-primary-hover pointer"
            onClick={() => {
              setShowPassword(!showPassword)
              if (showPassword) {
                setCurrentType("password")
              } else {
                setCurrentType("text")
              }
            }}
          />
        </div>
      )}

      {showError && !isMount && (
        <ErrorPopover
          placement={"top-start"}
          target={input.current}
          title={t("field:errors.general")}
          description={fieldError}
        />
      )}
    </div>
  )
}

export const defaultData = {
  isPriceVisible: true,
  isBaseVisible: true,
  enableCaption: true,
  caption: null
}

export { default as EditDataForm } from "./EditDataForm"
export { default as FragComponent } from "./FragComponent"
export { default as pageSplitter } from "./pageSplitter"

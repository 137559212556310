import { isEqual } from "lodash"
import React, { useMemo } from "react"
import RichTextEditor from "../../../../../components/RichTextEditor/RichTextEditor"
import { pagerLog } from "../../Pager";

const FragComponent = React.memo(
  ({ estimate, frag, readOnly, inPage }) => {
    if (inPage) {
      pagerLog("[PAGER] Text frag rendered")
    }
    const ctx = useMemo(() => ({ estimate }), [estimate])

    return (
      <RichTextEditor
        content={frag?.data?.content}
        readOnly={true}
        placeholder={readOnly ? "" : undefined}
        templatingContext={ctx}
        minHeight0
      />
    )
  },
  (prevProps, nextProps) => {
    const checkProps = ["frag", "estimate", "readOnly"]
    for (let i = 0; i < checkProps.length; i++) {
      const prop = checkProps[i]
      if (prop === "frag" && !isEqual(prevProps.frag, nextProps.frag)) {
        return false
      }
      else if (prop !== "frag" && prevProps[prop] !== nextProps[prop]) {
        return false
      }
    }
    return true
  }
)

export default FragComponent

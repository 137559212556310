import React, { useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { OrganizationDetailContext } from "../../context"
import * as yup from "yup"
import { Field, Formik } from "formik"
import { toast } from "react-toastify"
import { convertErrorToForm } from "../../utils"
import FieldInput from "../../components/fields/FieldInput"
import FieldImage from "../../components/fields/FieldImage"
import FieldTextarea from "../../components/fields/FieldTextarea"
import FieldColorPicker from "../../components/fields/FieldColorPicker"
import classNames from "classnames"
import Button from "../../components/Button"
import S from "./OrganizationSettings.module.scss"
// import useWpHistoryMethods from "../../hooks/useWpHistoryMethods"
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import useModalTrigger from "../../hooks/useModalTrigger"
import { useProfile } from "../../hooks/profile"
import { useHistory } from "react-router-dom"
import ConfirmLeaveModal from "./ConfirmLeaveModal"
import ImagePlaceholder from "../../components/ImagePlaceholder"
import FieldFontPicker from "../../components/fields/FieldFontPicker"
import {
  AVAILABLE_FONTS,
  AVAILABLE_FONTS_HEADINGS,
  DEFAULT_THEME_FONT,
} from "../../consts"
import BillingFieldSet from "../../components/CreateEstimateModal/BillingFieldSet"
import { CAP, useCapabilities, CAPLEVEL } from "../../hooks/capabilities"

export default function Settings() {
  const [, { hasCapability }] = useCapabilities()

  const allowEdits = hasCapability(CAP.ORG_META, CAPLEVEL.WRITE)

  const [{ organization }, { updateOrganization }] = useContext(
    OrganizationDetailContext
  )
  const [{ profile }, { deleteOrganization, leaveOrganization }] = useProfile()
  const history = useHistory()

  const onOrganizationDeletion = useCallback(() => {
    deleteOrganization
      .onSuccess(() =>
        history.push(`/org/${profile.personal_organization}/settings`)
      )
      .run(organization.id)
  }, [
    deleteOrganization,
    history,
    organization.id,
    profile.personal_organization,
  ])

  const onOrganizationLeave = useCallback(() => {
    leaveOrganization
      .onSuccess(() =>
        history.push(`/org/${profile.personal_organization}/settings`)
      )
      .run(organization.id)
  }, [
    history,
    leaveOrganization,
    organization.id,
    profile.personal_organization,
  ])

  const { t } = useTranslation(["translation", "action"])
  // const { push } = useWpHistoryMethods()
  const [deleteModal, deleteModalActions] = useModalTrigger()
  const [leaveModal, leaveModalActions] = useModalTrigger()

  const schema = useMemo(() => {
    return yup.object().shape({
      address: yup.string().max(256, t("field:errors.max", { max_value: 256 })),
      business_name: yup
        .string()
        .max(256, t("field:errors.max", { max_value: 256 })),
      city: yup.string().max(128, t("field:errors.max", { max_value: 128 })),
      email: yup.string().email(t("field:errors.email")),
      name: yup
        .string()
        .max(512, t("field:errors.max", { max_value: 512 }))
        .required(
          t("field:errors.mandatory", { field: t("field:organization.name") })
        ),
      country: yup.string().max(64, t("field:errors.max", { max_value: 64 })),
      it_tax_identifier: yup
        .string()
        .max(256, t("field:errors.max", { max_value: 256 })),
      billing_type: yup
        .string()
        .max(256, t("field:errors.max", { max_value: 256 })),
      pec: yup.string().email(t("field:errors.email")),
      postal_code: yup
        .string()
        .max(16, t("field:errors.max", { max_value: 16 })),
      province: yup
        .string()
        .max(128, t("field:errors.max", { max_value: 128 })),
      street_number: yup
        .string()
        .max(256, t("field:errors.max", { max_value: 256 })),
      tax_code: yup.string().max(20, t("field:errors.max", { max_value: 20 })),
      vat_number: yup
        .string()
        .max(20, t("field:errors.max", { max_value: 20 })),
      website: yup.string().max(256, t("field:errors.max", { max_value: 256 })),
    })
  }, [t])

  return (
    <div className="w-100 h-100 overflow-hidden">
      <Formik
        initialValues={{
          ...organization,
        }}
        validationSchema={schema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          const { logo, ...payload } = values
          const formData = new FormData()
          if (logo instanceof File || logo instanceof Blob || logo === null) {
            payload.logo = logo
          }
          for (const k in payload) {
            const value = payload[k]
            if (value !== null && value !== undefined) {
              formData.append(k, value)
            } else {
              formData.append(k, "")
            }
          }
          updateOrganization
            .onSuccess(() => {
              actions.setSubmitting(false)
              toast.success(t("organization.updated_message"))
            })
            .onFailure((error) => {
              actions.setSubmitting(false)
              actions.setErrors(convertErrorToForm(error))
            })
            .run(payload.id, formData)
        }}
      >
        {(formik) => (
          <form
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            className="h-100"
          >
            <div className="w-100 h-100 d-flex flex-column justify-content-start align-items-stretch ">
              <div className="flex-1 d-flex flex-column min-height-0 container pt-5 px-half-page pb-page-bottom overflow-auto">
                <div className="container">
                  <div className="px-page pt-5">
                    <div className="d-flex flex-column px-half-page">
                      <h1 className="pb-5">
                        {t("organization.settings.title")}
                      </h1>
                      <div className="d-flex flex-row justify-content-center mb-6">
                        <div className="flex-1">
                          <Field
                            name="name"
                            label={t("field:organization.name")}
                            component={FieldInput}
                            className="mr-8"
                            disabled={!allowEdits}
                          />
                        </div>
                        <div className="flex-1">
                          <Field
                            name="logo"
                            label={t("field:organization.logo")}
                            component={FieldImage}
                            shape="square"
                            enableDelete={allowEdits}
                            readOnly={!allowEdits}
                            placeholder={ImagePlaceholder(
                              "tile",
                              formik.values.id
                            )}
                            //centered
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mb-6">
                        <div className="flex-1">
                          <Field
                            name="email"
                            label={t("field:organization.email")}
                            component={FieldInput}
                            className="mr-8"
                            disabled={!allowEdits}
                          />
                        </div>
                        <div className="flex-1">
                          <Field
                            name="company_website"
                            label={t("field:organization.website")}
                            component={FieldInput}
                            disabled={!allowEdits}
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mb-6">
                        <div className="flex-1">
                          <Field
                            name="description"
                            label={t("field:organization.description")}
                            component={FieldTextarea}
                            disabled={!allowEdits}
                            auto
                            rows={4}
                          />
                        </div>
                      </div>

                      {allowEdits && <BillingFieldSet />}

                      <div className="d-flex flex-row justify-content-center mb-6">
                        <div className="flex-1">
                          <Field
                            name="theme_font"
                            className="mr-8 pr-2"
                            label={t("field:organization.theme_font")}
                            component={FieldFontPicker}
                            options={AVAILABLE_FONTS}
                            onReset={() => {
                              formik.setFieldValue(
                                "theme_font",
                                DEFAULT_THEME_FONT
                              )
                            }}
                          />
                        </div>
                        <div className="flex-1">
                          <Field
                            name="theme_headers_font"
                            className="pr-2"
                            label={t("field:organization.theme_headers_font")}
                            component={FieldFontPicker}
                            options={AVAILABLE_FONTS_HEADINGS}
                            onReset={() => {
                              formik.setFieldValue(
                                "theme_headers_font",
                                DEFAULT_THEME_FONT
                              )
                            }}
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mb-6">
                        <div className="flex-1">
                          <Field
                            name="brand_color"
                            label={t("field:organization.brand_color")}
                            component={FieldColorPicker}
                            left
                            bottom
                            size="lg"
                            disabled={!allowEdits}
                          />
                        </div>
                      </div>

                      {/* {isOwner && (
                        <>
                          <div className="d-flex flex-row justify-content-center mb-6">
                            <div className="flex-1">
                              <label className="font-weight-semibold mb-3 h3 text-uppercase">
                                {t("field:organization.billing_plan")}
                              </label>
                              <div className="d-flex flex-row align-items-center">
                                <span className="mr-3" style={{ width: 88 }}>
                                  {organization.plan}
                                </span>
                                <Button
                                  color="primary"
                                  size="sm"
                                  onClick={() => push("/settings/billing")}
                                >
                                  {t("action:upgrade")}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </>
                      )} */}
                    </div>
                  </div>
                  <div className="px-page">
                    <div className="d-flex flex-row mb-6 border-danger border rounded-lg mx-2">
                      <div className="py-4 px-8 w-100">
                        <label className="font-weight-semibold mb-3 h3 text-uppercase">
                          {t("field:organization.danger_zone")}
                        </label>
                        {allowEdits && (
                          <div className="d-flex flex-row align-items-center w-100">
                            <p className="flex-1 mb-0">
                              {t("organization.settings.danger_message")}
                            </p>
                            <Button
                              color="danger"
                              size="sm"
                              className="ml-8"
                              onClick={() => {
                                deleteModalActions.open()
                              }}
                            >
                              {t("action:delete_org")}
                            </Button>
                          </div>
                        )}
                        {!allowEdits && (
                          <div className="d-flex flex-row align-items-center w-100">
                            <p className="flex-1 mb-0">
                              {t("organization.settings.leave_message")}
                            </p>
                            <Button
                              color="danger"
                              size="sm"
                              className="ml-8"
                              onClick={() => {
                                leaveModalActions.open()
                              }}
                            >
                              {t("action:leave_org")}
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  "d-flex flex-row justify-content-center py-3",
                  S["save-bar"]
                )}
              >
                <Button
                  color="none"
                  size="lg"
                  className="mr-8"
                  onClick={formik.resetForm}
                >
                  {t("action:cancel")}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  disabled={!formik.isValid}
                  size="lg"
                >
                  {t("action:save")}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <ConfirmDeleteModal
        toggle={deleteModalActions.toggle}
        isOpen={deleteModal.isOpen}
        onConfirm={onOrganizationDeletion}
        item={organization?.name ?? ""}
        challenge={organization?.name ?? ""}
        onClosed={deleteModalActions.onClose}
      />

      <ConfirmLeaveModal
        toggle={leaveModalActions.toggle}
        isOpen={leaveModal.isOpen}
        onConfirm={onOrganizationLeave}
        item={organization?.name ?? ""}
        challenge={organization?.name ?? ""}
        onClosed={leaveModalActions.onClose}
      />
    </div>
  )
}

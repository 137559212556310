import React from "react"
import {
  useIncomingRequest,
  useIncomingRequests,
} from "../../hooks/incomingRequest"
import DropdownList from "../DropdownList"

const AUTO_FILTERS_DEFAULT = {}

export default function IncomingRequestSelector({
  requestId,
  onRequestChange,
  clearable,
  extraOptions = [],
  autoFilters = AUTO_FILTERS_DEFAULT,
  ...props
}) {
  const [{ request }] = useIncomingRequest(requestId)

  const [{ requests }, { runDebounced: searchRequests }] = useIncomingRequests(
    autoFilters
  )

  const requestOptions =
    requests?.map((request) => ({
      label: request.title,
      value: request.id,
    })) ?? []

  const options = [...extraOptions, ...requestOptions]

  let caption = undefined

  if (requestId) {
    if (requestId > 0) {
      caption = request?.title ?? "..."
    } else {
      caption = extraOptions.find((opt) => opt.value === requestId)?.label
    }
  }

  return (
    <DropdownList
      {...props}
      value={requestId}
      caption={caption}
      options={options}
      autoFilterIgnoreCase
      onChange={(nextId) => {
        const nextItem = requests.find((t) => t.id === nextId)
        onRequestChange(nextItem)
      }}
      onSearchChange={(searchStr) => {
        searchRequests({ ...autoFilters, search: searchStr })
      }}
      clearable={clearable}
      onClear={() => {
        onRequestChange(null)
      }}
    />
  )
}

function FieldRequestSelector({ form, field, label, ...props }) {
  return (
    <>
      {label && (
        <label className="font-weight-semibold mb-3 h3 text-uppercase">
          {label}
        </label>
      )}
      <IncomingRequestSelector
        requestId={field.value}
        onRequestChange={(request) =>
          form.setFieldValue(field.name, request?.id ?? null)
        }
        {...props}
      />
    </>
  )
}

IncomingRequestSelector.Field = FieldRequestSelector

import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import useCurrentOrganization from "../hooks/useCurrentOrganization"
import Button from "./Button"

export default function BadQuotaAlert({ checkQuota, className }) {
  const { t } = useTranslation()
  const org = useCurrentOrganization()

  return (
    <div className={className}>
      <div className="alert alert-warning">
        <p className="m-0 text-center">{t(`quota.scope.${checkQuota}`)}</p>
      </div>
      <div className="text-center">
        <Button
          className="no-link"
          color="primary"
          Component={Link}
          to={`/org/${org.id}/settings`}
        >
          {t("quota.upgrade_message")}
        </Button>
      </div>
    </div>
  )
}

import classNames from "classnames"
import { omit } from "lodash"
import React, { useCallback, useMemo, useState } from "react"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import Scrollbars from "react-custom-scrollbars"
import { useTranslation } from "react-i18next"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import Button from "../../../components/Button"
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import Icon from "../../../components/Icon"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import Separator from "../../../components/Separator/Separator"
import WpInput from "../../../components/WpInput"
import { useTemplateDocFragments } from "../../../hooks/templateDocFragments"
import useModalTrigger from "../../../hooks/useModalTrigger"
import { fromRichText2Text, notifyError } from "../../../utils"
import { defaultData } from "../../Estimate/EstimateDocument/Frags/CoverFrag"
import TemplateTabBarContent from "../TemplateTabBarContent"
import FragCoverPreviewCard from "./FragCoverPreviewCard"
import S from "./CoverTemplate.module.scss"
import CreateFragModal from "../CreateFragModal"
import EditCoverModal from "./EditCoverModal"
import FilterButton from "../../../components/FilterButton"
import RichTextEditor from "../../../components/RichTextEditor/RichTextEditor"
import useCurrentOrganization from "../../../hooks/useCurrentOrganization"
import useModalTriggerQuota from "../../../hooks/useModalTriggerQuota"
import WpModalQuota from "../../../components/WpModalQuota"
import { useQuota } from "../../../hooks/quota"
import { EstimateLandmarks } from "../../../components/EstimateLandmarks"

const TEMPLATE_FILTERS = { fragment_type: "cover" }

export default function CoverTemplate() {
  const { t } = useTranslation(["translation", "action", "field"])

  const [
    params,
    setQueryParams,
    debParams,
    setDebouncedQueryParams,
  ] = useQueryParams()
  const filter = useMemo(() => {
    return {
      ...TEMPLATE_FILTERS,
      ...debParams,
    }
  }, [debParams])

  const [
    { fragments },
    {
      createFrag,
      deleteFrag,
      updateFrag,
      setFragAsDefault,
      removeFragAsDefault,
      run: reload,
    },
  ] = useTemplateDocFragments(filter)

  const [selectedTemplateId, setSelectedTemplateId] = useState(null)
  const [editable, setEditable] = useState(false)

  const [, { refreshQuota }] = useQuota()
  // Don't use useModalTriggerQuota here since quota check is performed before opening
  const [exhaustedQuotaModal, exhaustedQuotaModalActions] = useModalTrigger()

  const [deleteModal, deleteModalActions] = useModalTrigger()

  const [createFragModal, createFragModalActions] = useModalTriggerQuota()

  const [edtiConverModal, edtiConverModalActions] = useModalTrigger()

  const onCreate = useCallback(() => {
    createFragModalActions.close()
  }, [createFragModalActions])

  const onDelete = useCallback(() => {
    deleteModalActions.close()
  }, [deleteModalActions])

  const onTemplateCreation = useCallback(
    ({ title }) => {
      return createFrag
        .onSuccess((frag) => {
          createFragModalActions.close()
          setSelectedTemplateId(frag.id)
          setEditable(true)
        })
        .asPromise({ title, data: defaultData, fragment_type: "cover" })
    },
    [createFrag, createFragModalActions]
  )

  const onTemplateArchive = useCallback(
    (toArchive) => {
      updateFrag
        .onFailure(notifyError)
        .onSuccess(() => {
          reload(filter)
        })
        .run({ id: toArchive.id, archived: true })
    },
    [filter, reload, updateFrag]
  )

  const onTemplateRestore = useCallback(
    (toRestore) => {
      updateFrag
        .onFailure(notifyError)
        .onSuccess(() => {
          reload(filter)
        })
        .run({ id: toRestore.id, archived: false })
    },
    [filter, reload, updateFrag]
  )

  const onTemplateDuplication = useCallback(
    (frag) => {
      return refreshQuota
        .onSuccess((quota) => {
          if (quota.templates > 0) {
            return createFrag
              .onSuccess((frag) => {
                createFragModalActions.close()
                setSelectedTemplateId(frag.id)
                setEditable(true)
              })
              .asPromise(
                omit(
                  { ...frag, title: frag.title + "_copy", archived: "false" },
                  "id"
                )
              )
          } else {
            exhaustedQuotaModalActions.open()
          }
        })
        .run()
    },
    [
      createFrag,
      createFragModalActions,
      exhaustedQuotaModalActions,
      refreshQuota,
    ]
  )

  const onTemplateDeletion = useCallback(() => {
    setSelectedTemplateId(null)
    return deleteFrag
      .onFailure(notifyError)
      .onSuccess(() => {
        deleteModalActions.close()
        setEditable(false)
      })
      .run(deleteModal.value)
  }, [deleteFrag, deleteModal, deleteModalActions])

  const handleSubmit = useCallback((values) => updateFrag.asPromise(values), [
    updateFrag,
  ])

  const mappedCoverFrags = useMemo(() => {
    if (fragments === null) {
      return []
    }
    return fragments.map((frag) => {
      return {
        title: frag.title,
        id: frag.id,
        isDefault: frag.is_default,
        ...frag.data,
        mainText: fromRichText2Text(frag.data.mainText),
        footerText1: fromRichText2Text(frag.data.footerText1),
        footerText2: fromRichText2Text(frag.data.footerText2),
        archived: frag.archived,
      }
    })
  }, [fragments])

  const organization = useCurrentOrganization()

  const logoUrl = organization.logo

  if (fragments === null) {
    return (
      <Layout>
        <Loader />
        <Layout.TabBar>
          <TemplateTabBarContent />
        </Layout.TabBar>

        <Layout.FirstLevelNavi>
          <EstimateLandmarks />
        </Layout.FirstLevelNavi>
      </Layout>
    )
  }

  const selectedTemplate =
    selectedTemplateId !== null
      ? fragments.find((elem) => elem.id === selectedTemplateId)
      : null

  const handleChangeFrag = (id) => {
    setSelectedTemplateId(id)
    if (id !== selectedTemplateId) {
      setEditable(false)
    }
  }

  return (
    <Layout
      displayRawContent
      className="flex-1 d-flex flex-column justify-content-start align-items-stretch"
    >
      <div className="flex-1 d-flex flex-row">
        <div className={S["left-container"]}>
          <Scrollbars style={{ width: "100%", height: "100%" }}>
            <div className="sticky-top bg-gray-bg-light mr-4">
              <div className="pt-7 pb-5 ml-4 px-4 border-bottom">
                <Button onClick={createFragModalActions.open}>
                  {t("action:new_cover_frag")}
                </Button>
              </div>
            </div>
            {mappedCoverFrags.map((coverFrag) => (
              <FragCoverPreviewCard
                key={coverFrag.id}
                frag={coverFrag}
                onClick={handleChangeFrag}
                selected={selectedTemplateId === coverFrag.id}
              />
            ))}
            {mappedCoverFrags.length === 0 && (
              <div className="text-center w-100 pt-8">
                <span>
                  <i>{t("template.cover.empty_list")}</i>
                </span>
              </div>
            )}
          </Scrollbars>
        </div>
        <div className="flex-1">
          <Scrollbars style={{ width: "100%", height: "100%" }}>
            <div className="sticky-top bg-white px-4">
              <div className="d-flex flex-row justify-content-end align-items-center px-5 py-8">
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <div className="d-flex flex-row align-items-center mr-4">
                    <FilterButton
                      onClick={() => setQueryParams({ archived: undefined })}
                      className={"mr-4"}
                      selected={debParams.archived === undefined}
                    >
                      {t("enums:cover_template_state.all")}
                    </FilterButton>
                    <FilterButton
                      onClick={() => setQueryParams({ archived: "false" })}
                      className={"mr-4"}
                      selected={debParams.archived === "false"}
                    >
                      {t("enums:cover_template_state.active")}
                    </FilterButton>
                    <FilterButton
                      onClick={() => setQueryParams({ archived: "true" })}
                      className={"mr-4"}
                      selected={debParams.archived === "true"}
                    >
                      {t("enums:cover_template_state.archived")}
                    </FilterButton>

                    {/* TODO search tag */}
                  </div>
                  <span className="pr-3">{t("action:search")}:</span>
                  <WpInput
                    placeholder={t("field:template.text.placeholder.search")}
                    medium
                    style={{ width: 235 }}
                    value={params.search}
                    onChange={(e) => {
                      setSelectedTemplateId(null)
                      setDebouncedQueryParams({ search: e.target.value })
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              {selectedTemplate && (
                <div className="d-flex flex-column align-items-stretch justify-content-start">
                  <div className="d-flex flex-row align-items-center justify-content-between px-8">
                    <h2 className="text-uppercase">{selectedTemplate.title}</h2>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                      {!selectedTemplate.archived && (
                        <Icon
                          name={
                            selectedTemplate.is_default ? "star-filled" : "star"
                          }
                          // NOTE: ehehe trick cause Icon can handle title changes
                          key={selectedTemplate.is_default ? "X" : "Y"}
                          className={classNames(
                            "action-icon-container-primary-hover pointer fill-primary"
                          )}
                          onClick={() => {
                            if (selectedTemplate.is_default) {
                              removeFragAsDefault
                                .onFailure(notifyError)
                                .run(selectedTemplate)
                            } else {
                              setFragAsDefault
                                .onFailure(notifyError)
                                .run(selectedTemplate)
                            }
                          }}
                          title={t(
                            `action:${
                              selectedTemplate.is_default
                                ? "unset_default"
                                : "set_default"
                            }`
                          )}
                        />
                      )}
                      {editable && (
                        <Icon
                          name="view"
                          onClick={() => setEditable(false)}
                          className={
                            "action-icon-container-primary-hover pointer ml-4"
                          }
                          title={t("action:view_template_mode")}
                        />
                      )}
                      {!editable && !selectedTemplate.archived && (
                        <Icon
                          name="edit"
                          onClick={() => setEditable(true)}
                          className={
                            "action-icon-container-primary-hover pointer ml-4"
                          }
                          title={t("action:edit_template_mode")}
                        />
                      )}
                      <Icon
                        name="duplicate"
                        className={classNames(
                          "action-icon-container-primary-hover pointer",
                          "ml-4"
                        )}
                        onClick={() => onTemplateDuplication(selectedTemplate)}
                        title={t("action:duplicate")}
                      />
                      {!selectedTemplate.archived && (
                        <Icon
                          name="settings"
                          className={classNames(
                            "action-icon-container-primary-hover pointer",
                            "ml-4"
                          )}
                          onClick={() => edtiConverModalActions.toggle()}
                        />
                      )}
                      <div
                        className={classNames(
                          "action-icon-container-primary-hover",
                          "ml-4"
                        )}
                      >
                        <UncontrolledDropdown>
                          <DropdownToggle caret={false} tag={"span"}>
                            <Icon
                              role="button"
                              name="vdots"
                              className="text-dark pointer"
                              title={t("action:other_options")}
                            />
                          </DropdownToggle>
                          <DropdownMenu
                            right
                            modifiers={{ offset: { offset: "0, 12" } }}
                            className="border-primary"
                          >
                            {!selectedTemplate.archived && (
                              <DropdownItem
                                className={classNames(
                                  "text-capitalize px-0",
                                  "dropdown-item-primary-active"
                                )}
                                disabled={selectedTemplate.is_default}
                              >
                                <div
                                  className="d-flex flex-row align-items-center mx-4"
                                  onClick={() => {
                                    onTemplateArchive(selectedTemplate)
                                    setEditable(false)
                                  }}
                                >
                                  <Icon
                                    name="archive"
                                    className={classNames("pointer mr-4", {
                                      "text-separator":
                                        selectedTemplate.is_default,
                                    })}
                                  />
                                  <span>{t("action:archive")}</span>
                                </div>
                              </DropdownItem>
                            )}
                            {selectedTemplate.archived && (
                              <DropdownItem
                                className={classNames(
                                  "text-capitalize px-0",
                                  "dropdown-item-primary-active"
                                )}
                              >
                                <div
                                  className="d-flex flex-row align-items-center mx-4"
                                  onClick={() =>
                                    onTemplateRestore(selectedTemplate)
                                  }
                                >
                                  <Icon
                                    name="restore_archive"
                                    className={classNames("pointer mr-4")}
                                  />
                                  <span>{t("action:restore")}</span>
                                </div>
                              </DropdownItem>
                            )}
                            <Separator className="my-0 mx-4 border-primary" />
                            <DropdownItem
                              className={classNames(
                                "text-capitalize px-0",
                                "dropdown-item-primary-active"
                              )}
                            >
                              <div
                                className="d-flex flex-row align-items-center mx-4"
                                onClick={() => {
                                  deleteModalActions.open(selectedTemplate)
                                }}
                              >
                                <Icon name="delete" className="pointer mr-4" />
                                <span>{t("action:delete")}</span>
                              </div>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                  <Separator />
                  <div className="mx-8">
                    <div className={S["cover"]}>
                      <div
                        className={classNames(
                          "flex-1 d-flex flex-column justify-content-center align-items-center",
                          S["main"]
                        )}
                      >
                        {selectedTemplate.data?.displayLogo && logoUrl && (
                          <img
                            className="mb-8"
                            src={logoUrl}
                            style={{
                              width: selectedTemplate.data?.logoWidth,
                              height: selectedTemplate.data?.logoHeight,
                            }}
                            alt="Logo"
                          />
                        )}
                        {selectedTemplate.data?.displayLogo && !logoUrl && (
                          <div
                            className="mb-8 d-flex flex-column justify-content-center align-items-center"
                            style={{
                              width: selectedTemplate.data?.logoWidth,
                              height: selectedTemplate.data?.logoHeight,
                              borderRadius: "50%",
                              border: "1px solid var(--separator)",
                              color: "var(--separator)",
                            }}
                          >
                            {"No uploaded logo"}
                          </div>
                        )}
                        <div className="w-100">
                          <RichTextEditor
                            key={selectedTemplate.id}
                            initialContent={selectedTemplate?.data?.mainText}
                            readOnly={!editable}
                            save={(content) => {
                              handleSubmit({
                                ...selectedTemplate,
                                data: {
                                  ...selectedTemplate.data,
                                  mainText: content,
                                },
                              })
                            }}
                            placeholder={"Estimate title"}
                          />
                        </div>
                      </div>
                      <div
                        className={classNames(
                          "d-flex flex-row justify-content-start align-items-stretch w-100",
                          S["footer"]
                        )}
                      >
                        <div
                          style={{
                            flex: "1 1 0%",
                            minWidth: 0,
                            paddingRight: 4,
                          }}
                        >
                          <RichTextEditor
                            key={selectedTemplate.id}
                            initialContent={selectedTemplate?.data?.footerText1}
                            readOnly={!editable}
                            placeholder={
                              selectedTemplate.data?.footerCols
                                ? "Piè di pagina sinistro"
                                : "Piè di pagina"
                            }
                            save={(content) => {
                              handleSubmit({
                                ...selectedTemplate,
                                data: {
                                  ...selectedTemplate.data,
                                  footerText1: content,
                                },
                              })
                            }}
                          />
                        </div>
                        {selectedTemplate.data?.footerCols && (
                          <div
                            style={{
                              flex: "1 1 0%",
                              minWidth: 0,
                              paddingLeft: 4,
                            }}
                          >
                            <RichTextEditor
                              key={selectedTemplate.id}
                              initialContent={
                                selectedTemplate?.data?.footerText2
                              }
                              readOnly={!editable}
                              placeholder={
                                selectedTemplate.data?.footerCols
                                  ? "Piè di pagina sinistro"
                                  : "Piè di pagina"
                              }
                              save={(content) => {
                                handleSubmit({
                                  ...selectedTemplate,
                                  data: {
                                    ...selectedTemplate.data,

                                    footerText2: content,
                                  },
                                })
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <Separator />
                </div>
              )}
              {!selectedTemplate && (
                <p className="pt-8 text-center">
                  <i>{t("template.cover.empty_selection")}</i>
                </p>
              )}
            </div>
          </Scrollbars>
        </div>
      </div>

      {deleteModal.value && (
        <ConfirmDeleteModal
          toggle={onDelete}
          isOpen={deleteModal.isOpen}
          onConfirm={onTemplateDeletion}
          item={deleteModal.value.title}
          onClosed={deleteModalActions.onClose}
        />
      )}

      {createFragModal.value && (
        <CreateFragModal
          fragmentType="cover"
          toggle={onCreate}
          isOpen={createFragModal.isOpen}
          onConfirm={onTemplateCreation}
          onClosed={createFragModalActions.onClose}
        />
      )}

      {selectedTemplate && (
        <EditCoverModal
          save={handleSubmit}
          isOpen={edtiConverModal.isOpen}
          toggle={edtiConverModalActions.toggle}
          onClosed={edtiConverModalActions.onClose}
          frag={selectedTemplate}
        />
      )}

      <WpModalQuota
        checkQuota="templates"
        title={t(`template.text.duplicate_frag`)}
        isOpen={exhaustedQuotaModal.isOpen}
        toggle={exhaustedQuotaModalActions.toggle}
        onClosed={exhaustedQuotaModalActions.onClose}
      />

      <Layout.TabBar>
        <TemplateTabBarContent />
      </Layout.TabBar>

      <Layout.FirstLevelNavi>
        <EstimateLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

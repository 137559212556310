import { Field, Formik } from "formik"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import FieldRichText from "../../../../components/fields/FieldRichText"
import FieldTextarea from "../../../../components/fields/FieldTextarea"
import FormAutoSave from "../../../../components/FormAutoSave"

export default function EditTaskForm({ task, onSubmit, readOnly=false }) {
  const { t } = useTranslation(["translation", "field"])

  const EditTaskSchema = useMemo(
    () =>
      Yup.object({
        title: Yup.string().required(
          t("field:errors.mandatory", { field: t("field:task.title") })
        ),
      }),
    [t]
  )

  return (
    <Formik
      initialValues={task}
      onSubmit={onSubmit}
      validationSchema={EditTaskSchema}
    >
      {({ handleSubmit, ...formik }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <FormAutoSave />
          <Field
            className="mb-5"
            label={t("field:task.title")}
            placeholder={t("field:task.placeholder.title")}
            name="title"
            auto
            rows={2}
            component={FieldTextarea}
            readOnly={readOnly}
          />
          <Field
            name="description_rich"
            label={t("field:task.description")}
            placeholder={t("field:task.placeholder.description")}
            component={FieldRichText}
            readOnly={readOnly}
            readOnlyPlaceholder={"--"}
          />
        </form>
      )}
    </Formik>
  )
}

import React from "react"
import { NavLink } from "react-router-dom"
import useCurrentOrganization from "../hooks/useCurrentOrganization"
import { prefixLocationWithOrg } from "../utils"

/**
 * Hacked version of <NavLink /> used to inject org segment
 */
export default function WpNavLink({ to, ...props }) {
  const organization = useCurrentOrganization()
  return <NavLink to={prefixLocationWithOrg(to, organization?.id)} {...props} />
}

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { WpClipboardCopyContext, WpClipboardValueContext } from "../context"

import "broadcastchannel-polyfill"

const wpClipboardChannel = new BroadcastChannel("wpClipboard")

export function WpClipboardProvider({ children }) {
  const [clipboardData, setClipboardData] = useState(null)
  const clipDataRef = useRef(clipboardData)

  const copy = useCallback((data) => {
    clipDataRef.current = data
    setClipboardData(data)
    wpClipboardChannel.postMessage({
      type: "COPY",
      payload: data,
    })
  }, [])

  useEffect(() => {
    wpClipboardChannel.postMessage({ type: "BOOT" })
    function handleClipCopy(e) {
      if (e.data.type === "COPY") {
        clipDataRef.current = e.data.payload
        setClipboardData(e.data.payload)
      } else if (e.data.type === "BOOT") {
        if (clipDataRef.current !== null) {
          const data = clipDataRef.current
          wpClipboardChannel.postMessage({
            type: "COPY",
            payload: data,
          })
        }
      }
    }
    wpClipboardChannel.addEventListener("message", handleClipCopy)
    return () => {
      wpClipboardChannel.removeEventListener("message", handleClipCopy)
    }
  }, [])

  return (
    <WpClipboardCopyContext.Provider value={copy}>
      <WpClipboardValueContext.Provider value={clipboardData}>
        {children}
      </WpClipboardValueContext.Provider>
    </WpClipboardCopyContext.Provider>
  )
}

export function useWpCopyClipboard() {
  return useContext(WpClipboardCopyContext)
}

export function useWpClipboardValue() {
  return useContext(WpClipboardValueContext)
}

import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { CRMLandmarks } from "../../../components/CRMLandmarks"
import { InteractionList } from "../../../components/InteractionsList"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import { CustomerContext } from "../../../context"
import CustomerBottomBarContent from "../CustomerBottomBarContent"
import CustomerTabBarContent from "../CustomerTabBarContent"

export default function CustomerInteractions() {
  const { t } = useTranslation(["translation", "tab", "action"])

  const [{ customer }] = useContext(CustomerContext)

  return (
    <Layout>
      <div className="container-fluid pt-5 px-page pb-page-bottom">
        {!customer && <Loader />}
        {customer && (
          <>
            <h1 className="text-primary font-weight-semibold">
              {t("customer_interactions.title", { name: customer.name })}
            </h1>
            <InteractionList
              filter={{ customer: customer.id }}
              initial={{ customer: customer.id }}
              customer={customer}
            />
          </>
        )}
      </div>
      <Layout.TabBar>
        <CustomerTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <CustomerBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <CRMLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

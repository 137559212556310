import React, { useMemo } from "react"
import classNames from "classnames"
import S from "./ScenarioResourceViz.module.scss"
import { useTranslation } from "react-i18next"
import ScenarioSymbol from "../../../../../components/ScenarioSymbol"
import { scaleLinear } from "d3-scale"
import { chunk, sum } from "lodash"
import { ColorSchemeAssociative } from "../../colorScheme"
import useCurrencyFormatter from "../../../../../hooks/useCurrencyFormatter"
import { useLocalizer } from "../../../../../hooks/useLocalizer"

function ScenarioResourceViz({
  estimate,
  scenario,
  width,
  maxPrice,
  isPriceVisible,
  mappedResources,
  totalCosts,
}) {
  const { t } = useTranslation(["translation"])
  const currencyFmt = useCurrencyFormatter()
  const isBase = scenario === null

  const scale = scaleLinear()
    .domain([0, maxPrice])
    .range([0, width - 180])

  const colorScheme = useMemo(() => {
    return new ColorSchemeAssociative(
      "sequential",
      1,
      estimate.resources.map((r) => r.id)
    )
  }, [estimate.resources])

  const totalResources = sum(
    mappedResources.map((resource) => resource.total_price)
  )

  return (
    <div className="w-100 h-100 d-flex flex-column pb-7">
      <div
        className={classNames(
          "d-flex flex-row align-items-center justify-content-start",
          S["viz-container"]
        )}
      >
        <div
          className={classNames(
            "d-flex flex-column align-items-end text-right",
            S["title"]
          )}
        >
          <span className="h2 font-weight-semibold prevent-overflow">
            {!isBase && scenario.name}
            {isBase && t("documents.frag.scenario_list.scenario_base")}
          </span>
          <span className="h3 font-weight-semibold">
            {currencyFmt.format(estimate.price)}
          </span>
          {!isBase && <ScenarioSymbol scenario={scenario} />}
          {isBase && (
            <span className="badge badge-separator ml-4 py-2">
              {t("scenarios.base")}
            </span>
          )}
        </div>
        <div
          className={classNames(S["resource-container"], "h-100 ml-4")}
          style={{
            width: scale(totalResources),
          }}
        >
          {mappedResources.map((resource) => {
            return (
              <div
                className={classNames(
                  S["resource"],
                  "h-100",
                  colorScheme.border(0, resource.resource_id),
                  colorScheme.background(0, resource.resource_id)
                )}
                style={{
                  width: scale(parseFloat(resource.total_price)),
                }}
              ></div>
            )
          })}
        </div>
        {totalCosts > 0 && (
          <div
            className={classNames(S["cost-container"], "h-100 mr-4")}
            style={{
              width: scale(totalCosts),
            }}
          ></div>
        )}
      </div>
      <div
        className="d-flex flex-row align-items-center"
        style={{ marginLeft: 162 }}
      >
        {mappedResources.map((resource) => {
          const code = String.fromCharCode(
            "a".charCodeAt(0) + colorScheme.getOffset(resource.resource_id)
          )
          return (
            <div
              className={classNames("text-center d-flex flex-column justify-content-start align-items-center line-height-1")}
              style={{
                width: scale(parseFloat(resource.total_price)),
              }}
            >
              <span
                style={{
                  maxWidth: scale(parseFloat(resource.total_price)) - 32,
                }}
                className="font-weight-semibold "
              >
                {"(" + code + ")"}
              </span>
              {isPriceVisible && (
                <span>{currencyFmt.format(resource.total_price)}</span>
              )}
            </div>
          )
        })}
        <div
          className={classNames("text-center")}
          style={{
            width: scale(parseFloat(totalCosts)),
          }}
        >
          {scale(parseFloat(totalCosts)) > 80 && (
            <>
              <span
                style={{
                  maxWidth: scale(parseFloat(totalCosts)) - 32,
                }}
                className="prevent-overflow mx-5 font-weight-semibold "
              >
                {t("tasks.fixed_costs")}
              </span>
              {isPriceVisible && (
                <span>{currencyFmt.format(totalCosts)}</span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

function Legend({ estimate, columns = 1 }) {
  const { t, i18n } = useTranslation()
  const l = useLocalizer(i18n.language)

  const colorScheme = useMemo(() => {
    return new ColorSchemeAssociative(
      "sequential",
      1,
      estimate.resources.map((r) => r.id)
    )
  }, [estimate.resources])

  const resourcesPerCol = Math.ceil((estimate.resources.length + 1) / columns)

  const chunks = chunk(estimate.resources, resourcesPerCol)

  return (
    <div className="d-flex flex-row align-items-start">
      {chunks.map((resources, n) => (
        <div className="flex-1">
          <div style={{ opacity: n === 0 ? 1 : 0 }}>
            <b>{t("viz.legend")}</b>
          </div>
          {resources.map((resource, i) => {
            const code = String.fromCharCode("a".charCodeAt(0) + i + n * resourcesPerCol)
            return (
              <div
                key={resource.id}
                className="d-flex flex-row justify-content-start align-items-center mb-3"
              >
                <div
                  className={classNames(
                    S["legend-square"],
                    colorScheme.border(0, resource.id),
                    colorScheme.background(0, resource.id)
                  )}
                />
                {`(${code}) ${resource[l`name`] || resource.name}`}
              </div>
            )
          })}
          {n === chunks.length - 1 && (
            <div className="d-flex flex-row justify-content-start align-items-center mb-3">
              <div
                className={classNames(
                  S["legend-square"],
                  S["legend-fixed-costs"]
                )}
              />
              {t("viz.fixed_costs")}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

ScenarioResourceViz.Legend = Legend

export default ScenarioResourceViz

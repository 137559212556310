import { get } from "lodash"
import S from "./colorScheme.module.scss"

const USABLE_LEVELS = [
  "A",
  "AC",
  "ACE",
  "ACDE",
  "ABCDE"
]

function mod(value, base) {
  if (value < 0) {
    return value + Math.ceil((-value) / base)
  } else {
    return value % base
  }
}

function limit(value, min, max) {
  return Math.max(min, Math.min(value, max))
}

const prefixes = {
  area: "a",
  sequential: "s"
}

const SchemeSizes = {
  area: 8,
  sequential: 24
}

export class ColorScheme {
  constructor(schemaName = "area", levels = 5) {
    this.prefix = get(prefixes, schemaName, prefixes.area)
    this.depths = USABLE_LEVELS[limit(levels, 0, 4)]
    this.size = SchemeSizes[schemaName]
  }

  border(depth=0, index=0) {
    const prefix = this.prefix
    const scheme = this.depths[limit(depth, 0, this.depths.length - 1)]
    const offset = mod(index, this.size) + 1
    return S[`wpviz-border-${prefix}${scheme}${offset}`]
  }

  stroke(depth=0, index=0) {
    const prefix = this.prefix
    const scheme = this.depths[limit(depth, 0, this.depths.length - 1)]
    const offset = mod(index, this.size) + 1
    return S[`wpviz-stroke-${prefix}${scheme}${offset}`]
  }

  texture(depth=0, index=0) {
    const prefix = this.prefix
    const scheme = this.depths[limit(depth, 0, this.depths.length - 1)]
    const offset = mod(index, this.size) + 1
    return S[`wpviz-texture-${prefix}${scheme}${offset}`]
  }

  fill(depth=0, index=0) {
    const prefix = this.prefix
    const scheme = this.depths[limit(depth, 0, this.depths.length - 1)]
    const offset = mod(index, this.size) + 1
    return S[`wpviz-fill-${prefix}${scheme}${offset}`]
  }

  background(depth=0, index=0) {
    const prefix = this.prefix
    const scheme = this.depths[limit(depth, 0, this.depths.length - 1)]
    const offset = mod(index, this.size) + 1
    return S[`wpviz-bg-${prefix}${scheme}${offset}`]
  }
}

export class ColorSchemeAssociative {
  constructor(schemaName = "area", levels = 5, domain = []) {
    this.scheme = new ColorScheme(schemaName, levels)
    this.mapping = new Map()
    for(const item of domain) {
      this.mapping.set(item, this.mapping.size)
    }
  }

  border(depth=0, item) {
    const index = this.mapping.get(item)
    return this.scheme.border(depth, index)
  }

  stroke(depth=0, item) {
    const index = this.mapping.get(item)
    return this.scheme.stroke(depth, index)
  }

  texture(depth=0, item) {
    const index = this.mapping.get(item)
    return this.scheme.texture(depth, index)
  }

  fill(depth=0, item) {
    const index = this.mapping.get(item)
    return this.scheme.fill(depth, index)
  }

  background(depth=0, item) {
    const index = this.mapping.get(item)
    return this.scheme.background(depth, index)
  }

  getOffset(item) {
    return this.mapping.get(item)
  }

}

import { useContext, useMemo } from "react"
import { CurrentEstimateStateContext } from "../context"

class CustomNumberFormatter extends Intl.NumberFormat {
  formatNaN(arg) {
    if (!isNaN(arg)) {
      return super.format(arg)
    }
    return "-"
  }
  get currencySymbol() {
    if (this._symbol === undefined) {
      this._symbol = this.formatToParts(1).find(p => p.type === "currency").value
    }
    return this._symbol
  }
  formatNoUnit(value) {
    const parts = this.formatToParts(value)
    const integer = parts.find(p => p.type === "integer").value
    const decimal = parts.find(p => p.type === "decimal").value
    const fraction = parts.find(p => p.type === "fraction").value
    return [integer, decimal, fraction].filter(i => i).join("")
  }
}

export function getCurrencyFormatter(estimateOrCurrency) {
  return new CustomNumberFormatter("it-IT", {
    style: "currency",
    currency:
      typeof estimateOrCurrency === "string"
        ? estimateOrCurrency
        : estimateOrCurrency?.currency,
    currencyDisplay: "narrowSymbol",
  })
}

export default function useCurrencyFormatter() {
  const [{ fullEstimate }] = useContext(CurrentEstimateStateContext)

  const currency = fullEstimate?.currency ?? "EUR"

  // TODO: i18n
  const formatter = useMemo(() => {
    return getCurrencyFormatter(currency)
  }, [currency])

  return formatter
}

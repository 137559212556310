import { get } from "lodash"
import { rj, useRunRj } from "react-rocketjump"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import rjDebounce from "react-rocketjump/plugins/debounce"
import api from "../api"

const getOrSelect = (context, pathOrFunction) => {
  if (typeof pathOrFunction === "function") {
    return pathOrFunction(context)
  }
  return get(context, pathOrFunction)
}

const IncomingRequestsListRj = rj(
  rjDebounce(),
  rjList({
    pageSize: 100,
    pagination: nextPreviousPaginationAdapter,
    customPaginationReducer: (prevState, { payload: { data } }) => {
      return {
        ...prevState,
        count: getOrSelect(data, nextPreviousPaginationAdapter.count),
        current: getOrSelect(data, nextPreviousPaginationAdapter.current),
        next: getOrSelect(data, nextPreviousPaginationAdapter.next),
        previous: getOrSelect(data, nextPreviousPaginationAdapter.previous),
        full_count: data.full_count,
      }
    },
  }),
  {
    name: "Requests",
    effectCaller: rj.configured(),
    effect: (wpAuth) => (params = {}) =>
      api.auth(wpAuth).get(`/api/crm/request/`, params),
    selectors: ({ getPagination }) => ({
      getFullCount: (state) => {
        if (state.root) {
          return get(state, "root.data.pagination.full_count", 0)
        }
        return get(state, "data.pagination.full_count", 0)
      },
    }),
    mutations: {
      create: rj.mutation.single({
        effect: (wpAuth) => (data) => {
          return api.auth(wpAuth).post(`/api/crm/request`, data)
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: [...state.data.list, result],
            },
          }
        },
      }),
      remove: rj.mutation.single({
        effect: (wpAuth) => (itemId) => {
          return api
            .auth(wpAuth)
            .mapResponse(() => itemId)
            .delete(`/api/crm/request/${itemId}`)
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.filter((item) => item.id !== result),
            },
          }
        },
      }),
    },
    computed: {
      requests: "getList",
      count: "getCount",
      fullCount: "getFullCount",
      loading: "isPending",
      error: "getError",
      pageCount: "getNumPages",
      hasNext: "hasNext",
      hasPrev: "hasPrev",
    },
  }
)

export function useIncomingRequests(params) {
  return useRunRj(IncomingRequestsListRj, [params], false)
}

const RequestRj = rj({
  name: "Request",
  effectCaller: rj.configured(),
  effect: (wpAuth) => (id) => api.auth(wpAuth).get(`/api/crm/request/${id}`),
  mutations: {
    update: rj.mutation.single({
      effect: (wpAuth) => (id, data) => {
        return api.auth(wpAuth).patch(`/api/crm/request/${id}`, data)
      },
      updater: "updateData",
    }),
  },
  computed: {
    request: "getData",
    loading: "isPending",
    error: "getError",
  },
})

export function useIncomingRequest(id) {
  return useRunRj(RequestRj, [id], true)
}

const RequestFlowRj = rj({
  name: "RequestFlow",
  effectCaller: rj.configured(),
  effect: (wpAuth) => (id) =>
    api.auth(wpAuth).get(`/api/crm/request/${id}/flow`),
  computed: {
    graph: "getData",
    loading: "isPending",
    error: "getError",
  },
})

export function useIncomingRequestFlow(id) {
  return useRunRj(RequestFlowRj, [id], true)
}

import React from "react"
import { useTranslation } from "react-i18next"
import { DropdownItem } from "reactstrap"
import Icon from "./Icon"

export function DropdownItemDelete({ onClick }) {
  const { t } = useTranslation()
  return (
    <DropdownItem
      className={"text-capitalize px-0 dropdown-item-primary-active"}
    >
      <div
        className="d-flex flex-row align-items-center mx-4"
        onClick={onClick}
      >
        <Icon name="delete" className="pointer mr-4" />
        <span>{t("action:delete")}</span>
      </div>
    </DropdownItem>
  )
}

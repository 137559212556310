import { useMemo } from "react"
import { deps, rj, useRunRj } from "react-rocketjump"
import api from "../api"

const IndicatorsRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (filters) =>
    api.auth(wpAuth).get("/api/overview/indicators", filters),
})

export function useIndicators(fromDate, toDate, params) {
  const filters = useMemo(() => {
    if (fromDate && toDate) {
      return {
        ...params,
        from_date: fromDate,
        to_date: toDate,
      }
    } else {
      return null
    }
  }, [fromDate, toDate, params])

  return useRunRj(IndicatorsRj, [deps.maybe(filters)], false)
}

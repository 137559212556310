import classNames from "classnames"
import dayjs from "dayjs"
import { identity, truncate } from "lodash"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal"
import { CustomerSelector } from "../../components/CustomerSelector"
import Dropdown from "../../components/Dropdown"
import Icon from "../../components/Icon"
import Layout from "../../components/Layout"
import LongBorder from "../../components/LongBorder"
import Pagination from "../../components/Pagination/Pagination"
import ProjectSelector from "../../components/ProjectSelector"
import Table from "../../components/Table"
import { TrackerLandmarks } from "../../components/TrackerLandmarks"
import WpInput from "../../components/WpInput"
import WpLink from "../../components/WpLink"
import { useDeadlines } from "../../hooks/deadline"
import useCurrentOrganization from "../../hooks/useCurrentOrganization"
import useModalTrigger from "../../hooks/useModalTrigger"
import { EditDeadlineModal } from "./EditDeadlineModal"

export function Deadlines() {
  const { t } = useTranslation(["translation", "tab", "action"])

  const [
    { customer, project, fromDate, toDate, completed },
    setQueryParams,
    {
      page: debPage,
      search: debSearch,
      customer: debCustomer,
      project: debProject,
      fromDate: debFromDate,
      toDate: debToDate,
      completed: debCompleted,
    },
    setDebQueryParams,
  ] = useQueryParams({
    page: {
      encode: (intValue) => intValue.toString(10),
      decode: (strValue) => (strValue ? parseInt(strValue, 10) : 1),
    },
    completed: {
      encode: identity,
      decode: (rawVal) => rawVal || "",
    },
  })

  const filter = useMemo(() => {
    const filters = {
      page: debPage,
      search: debSearch,
      customer: debCustomer,
      project: debProject,
      date__gte: debFromDate,
      date__lte: debToDate,
    }
    if (debCompleted === "yes") {
      filters.completion_percent = 100
    }
    if (debCompleted === "no") {
      filters.completion_percent__lt = 100
    }
    return filters
  }, [
    debCompleted,
    debCustomer,
    debFromDate,
    debPage,
    debProject,
    debSearch,
    debToDate,
  ])

  const [
    { deadlines, pageCount, hasNext, hasPrev },
    { create, update, remove, run: refresh },
  ] = useDeadlines(filter)

  const org = useCurrentOrganization()

  const [editModalState, editModalActions] = useModalTrigger()
  const [removeModalState, removeModalActions] = useModalTrigger()

  const columns = useMemo(() => {
    return [
      {
        label: t("field:deadline.customer"),
        name: "customer_name",
        size: 4,
        render: (selectedDatum, index, datum) => {
          return (
            <WpLink to={`/customers/${datum.customer}`}>{selectedDatum}</WpLink>
          )
        },
      },
      {
        label: t("field:deadline.project"),
        name: "project_name",
        size: 4,
        render: (selectedDatum, index, datum) => {
          return (
            <WpLink to={`/projects/${datum.project}`}>{selectedDatum}</WpLink>
          )
        },
      },
      {
        label: t("field:deadline.date"),
        name: "date",
        size: 2,
        render: (selectedDatum, index, datum) => {
          return (
            <span>
              {dayjs(selectedDatum).format("DD/MM/YYYY")}
              {dayjs(datum.date).isBefore(dayjs()) &&
                datum.completion_percent < 100 &&
                " 🔴"}
            </span>
          )
        },
      },
      {
        label: t("field:deadline.description"),
        name: "description",
        size: 9,
      },
      {
        label: t("field:deadline.completion_percent"),
        name: "completion_percent",
        size: 3,
        render: (selectedDatum, index, datum) => {
          return (
            <span>
              {selectedDatum ? selectedDatum + " %" : ""}
              {dayjs(datum.date).isBefore(dayjs()) &&
                selectedDatum < 100 &&
                " 🔴"}
            </span>
          )
        },
      },
      {
        label: t("actions"),
        name: "actions",
        size: 2,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <div className={"action-icon-container-primary-hover"}>
                <Icon
                  name="edit"
                  title={t("action:edit_project_task")}
                  className="pointer text-dark"
                  onClick={() => {
                    editModalActions.open(datum)
                  }}
                />
              </div>
              <div className={"action-icon-container-primary-hover"}>
                <UncontrolledDropdown>
                  <DropdownToggle caret={false} tag={"span"}>
                    <Icon
                      role="button"
                      name="vdots"
                      className="text-dark pointer"
                      title={t("action:other_options")}
                      placement="right"
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    right
                    modifiers={{ offset: { offset: "0, 12" } }}
                    className="border-primary"
                  >
                    {!datum.archived && (
                      <DropdownItem
                        className={classNames(
                          "text-capitalize px-0",
                          "dropdown-item-primary-active"
                        )}
                      >
                        <div
                          className="d-flex flex-row align-items-center mx-4"
                          onClick={() => {
                            removeModalActions.open(datum)
                          }}
                        >
                          <Icon
                            name="delete"
                            className={classNames("pointer mr-4")}
                          />
                          <span>{t("action:delete")}</span>
                        </div>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )
        },
      },
    ]
  }, [editModalActions, removeModalActions, t])

  return (
    <Layout>
      <div className="container-fluid pt-5 px-page pb-page-bottom">
        <div className="d-flex flex-row justify-content-between align-items-center mb-6">
          <h1 className="text-primary">{t("tab:project.deadline")}</h1>
        </div>
        <div>
          <div className="d-flex flex-column mt-4 mb-7">
            <div className="d-flex flex-row align-items-center justify-content-between mt-3">
              <div className="d-flex flex-row">
                <div className="pr-5">
                  <span className="mr-3">{t("deadlines.filter_customer")}</span>
                  <br />
                  <CustomerSelector
                    orgId={org.id}
                    customerId={customer}
                    enableNone
                    caption={
                      !customer
                        ? () => (
                            <span className="text-muted">
                              {t("deadlines.filter_customer_placeholder")}
                            </span>
                          )
                        : undefined
                    }
                    onChange={(nextCustomerId) =>
                      setQueryParams({ customer: nextCustomerId })
                    }
                    itemWidth={200}
                    style={{ width: 200 }}
                  />
                </div>

                <div className="pr-5">
                  <span className="mr-3">{t("deadlines.filter_project")}</span>
                  <br />
                  <ProjectSelector
                    orgId={org.id}
                    projectId={project}
                    enableNone
                    caption={
                      !project
                        ? () => (
                            <span className="text-muted">
                              {t("deadlines.filter_project_placeholder")}
                            </span>
                          )
                        : undefined
                    }
                    onProjectChange={(nextProject) =>
                      setQueryParams({ project: nextProject?.id })
                    }
                    itemWidth={200}
                    style={{ width: 200 }}
                  />
                </div>

                <div className="pr-5">
                  <span className="mr-3">
                    {t("deadlines.period_filter_from")}
                  </span>
                  <br />
                  <WpInput
                    medium
                    type="date"
                    value={fromDate}
                    onChange={(e) =>
                      setDebQueryParams({ fromDate: e.target.value, page: 1 })
                    }
                  />
                </div>

                <div className="pr-5">
                  <span>{t("deadlines.period_filter_to")}</span>
                  <br />
                  <WpInput
                    medium
                    type="date"
                    value={toDate}
                    onChange={(e) =>
                      setDebQueryParams({ toDate: e.target.value, page: 1 })
                    }
                  />
                </div>

                <div className="pr-5">
                  <span className="mr-3">
                    {t("deadlines.filter_completed_percent")}
                  </span>
                  <br />
                  <Dropdown
                    value={completed}
                    className="px-3 py-2"
                    options={[
                      {
                        value: "yes",
                        label: t("deadlines.filter_completed_100"),
                      },
                      {
                        value: "no",
                        label: t("deadlines.filter_completed_lt_100"),
                      },
                      {
                        value: "",
                        label: t("deadlines.filter_completed_all"),
                      },
                    ]}
                    onChange={(val) => {
                      setQueryParams({ completed: val, page: 1 })
                    }}
                    itemWidth={200}
                    style={{ width: 200 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {deadlines && (
          <>
            <LongBorder topBar="tabbar" />
            <Table
              data={deadlines}
              columns={columns}
              stickyHeader
              stickyTopBar="tabbar"
              className="pb-8"
              valueCheckFn={(val) => val !== undefined && val !== null}
              use24
            />
            {deadlines.length > 0 && (
              <Pagination
                current={debPage}
                pageCount={pageCount}
                goToPage={(page) => {
                  setQueryParams({ page })
                  window.scrollTo(0, 10)
                }}
                hasNext={hasNext}
                hasPrev={hasPrev}
              />
            )}
          </>
        )}
        {editModalState.value && (
          <EditDeadlineModal
            isOpen={editModalState.isOpen}
            deadline={editModalState.value}
            onClosed={editModalActions.onClose}
            toggle={editModalActions.toggle}
            onSave={(result) => {
              if (result.id) {
                return update
                  .onSuccess(() => {
                    editModalActions.close()
                  })
                  .asPromise(result.id, result)
              } else {
                return create
                  .onSuccess(() => {
                    editModalActions.close()
                  })
                  .asPromise(result)
              }
            }}
          />
        )}
        {removeModalState.value && (
          <ConfirmDeleteModal
            isOpen={removeModalState.isOpen}
            toggle={removeModalActions.toggle}
            onClosed={removeModalActions.onClose}
            item={truncate(removeModalState.value.description, 20)}
            onConfirm={() => {
              remove
                .onSuccess(() => {
                  refresh(filter)
                })
                .run(removeModalState.value.id)
            }}
          />
        )}
      </div>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

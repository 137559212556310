import React, { useContext, useMemo } from "react"
import { ComplianceIssueReportList as Impl } from "../../../components/ComplianceIssueReportList"
import Layout from "../../../components/Layout"
import { TrackerLandmarks } from "../../../components/TrackerLandmarks"
import { ProjectContext } from "../../../context"
import ProjectBottomBarContent from "../ProjectBottomBarContent"
import ProjectTabBarContent from "../ProjectTabBarContent"

export function ComplianceIssueReportList() {
  const [{ project }] = useContext(ProjectContext)

  const reportsFilters = useMemo(
    () => ({
      project: project.id,
    }),
    [project.id]
  )

  return (
    <Layout>
      <Impl filters={reportsFilters} defaults={reportsFilters} />
      <Layout.TabBar>
        <ProjectTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <ProjectBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useProjectEnum, useProjectEnumDetail } from "../../hooks/projectEnum"
import DropdownList from "../DropdownList"

export default function ProjectSelector({
  projectId,
  onProjectChange,
  clearable,
  caption,
  extraOptions = [],
  defaultFilters = {},
  filtersReinitialize = false,
  enableNone = false,
  ...props
}) {
  const { t } = useTranslation()
  const [{ project }, { updateData: updateSelectedEstimate }] = useProjectEnumDetail(projectId)

  const [_externalFilters] = useState(defaultFilters)

  const externalFilters = filtersReinitialize ? defaultFilters : _externalFilters

  const [{ projects }, { runDebounced: searchEstimates }] = useProjectEnum(externalFilters)

  const projectOptions =
    projects?.map((project) => ({
      label: project.name,
      value: project.id,
    })) ?? []

  const options = [...extraOptions, ...projectOptions]

  let autoCaption = undefined

  if (projectId) {
    if (projectId > 0) {
      autoCaption = project?.name ?? "..."
    } else {
      autoCaption = extraOptions.find((opt) => opt.value === projectId)?.label
    }
  }

  if (enableNone) {
    options.unshift({ value: null, label: t("none") })
  }

  return (
    <DropdownList
      {...props}
      value={projectId}
      caption={caption || autoCaption}
      options={options}
      autoFilterIgnoreCase
      onChange={(nextResourceId) => {
        const nextResource = projects.find((t) => t.id === nextResourceId)
        updateSelectedEstimate(nextResource)
        onProjectChange(nextResource)
      }}
      onSearchChange={(searchStr) => {
        searchEstimates({ ...externalFilters, search: searchStr })
      }}
      clearable={clearable}
      onClear={() => {
        updateSelectedEstimate(null)
        onProjectChange(null)
      }}
    />
  )
}

function FieldProjectSelector({ form, field, label, ...props }) {
  return (
    <>
      {label && <label className="font-weight-semibold mb-3 h3 text-uppercase">{label}</label>}
      <ProjectSelector
        projectId={field.value}
        onProjectChange={(estimate) => form.setFieldValue(field.name, estimate?.id ?? null)}
        {...props}
      />
    </>
  )
}

ProjectSelector.Field = FieldProjectSelector

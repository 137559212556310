import classNames from "classnames"
import React, { useCallback, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useAuthUser } from "use-eazy-auth"
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import CreateEstimateModal from "../../components/CreateEstimateModal"
import { DropdownItemCreateInteraction } from "../../components/DropdownItemCreateInteraction"
import Icon from "../../components/Icon"
import ImagePreview from "../../components/ImagePreview"
import ScenarioSymbol from "../../components/ScenarioSymbol"
import Separator from "../../components/Separator"
import { CurrentEstimateStateContext } from "../../context"
import { useEstimateConcurrency } from "../../hooks/estimateConcurrency"
import useCurrencyFormatter from "../../hooks/useCurrencyFormatter"
import useModalTrigger from "../../hooks/useModalTrigger"
import useModalTriggerQuota from "../../hooks/useModalTriggerQuota"
import useWpHistoryMethods from "../../hooks/useWpHistoryMethods"
import { notifyError } from "../../utils"
import ScenarioSelector from "./ScenarioSelector"

export default function EstimateBottomBarContent() {
  const { t } = useTranslation(["translation"])
  const { user } = useAuthUser()
  const currencyFmt = useCurrencyFormatter()
  const history = useWpHistoryMethods()

  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const toggle = useCallback(() => {
    setDropdownOpen((v) => !v)
  }, [])

  const [
    { estimate, selectedScenarios },
    { setSelectedScenarios, updateEstimate, deleteEstimate, templetizeEstimate },
  ] = useContext(CurrentEstimateStateContext)

  const [toTplModal, toTplModalActions] = useModalTriggerQuota()
  const [deleteModal, deleteModalActions] = useModalTrigger()

  const isTemplate = estimate.is_template

  const onEstimateDeletion = useCallback(() => {
    return deleteEstimate
      .onFailure(notifyError)
      .onSuccess(() => {
        deleteModalActions.close()
        history.push(isTemplate ? `/templates` : `/estimates`)
      })
      .run()
  }, [deleteEstimate, deleteModalActions, history, isTemplate])

  const onEstimateToTemplate = useCallback(
    (estimateData) => {
      const { cloneId, ...cloneData } = estimateData
      return (
        templetizeEstimate
          .onSuccess((estimate) => {
            history.push(`/estimates/${estimate.id}/`)
          })
          // NOTE: cloneId is curried cause we templetize *CURRENT* estimate
          .asPromise(cloneData)
      )
    },
    [history, templetizeEstimate]
  )

  const onEstimateArchive = useCallback(() => {
    updateEstimate.onFailure(notifyError).run({
      archived: true,
    })
  }, [updateEstimate])

  const onEstimateRestore = useCallback(() => {
    updateEstimate.onFailure(notifyError).run({
      archived: false,
    })
  }, [updateEstimate])

  const onEstimateLock = useCallback(() => {
    updateEstimate.onFailure(notifyError).run({
      editable_state: "closed",
    })
  }, [updateEstimate])

  const onEstimateUnlock = useCallback(() => {
    updateEstimate.onFailure(notifyError).run({
      editable_state: "open",
    })
  }, [updateEstimate])

  const concurrentUsers = useEstimateConcurrency(estimate.id)

  if (!estimate) {
    return null
  }

  return (
    <div className="d-flex flex-row justify-content-between align-items-stretch  w-100 h-100">
      <div className="h-100 py-5 px-page text-uppercase font-weight-semibold d-flex align-items-center">
        <div className="align-self-center">
          <UncontrolledDropdown
            disabled={estimate === null}
            direction="up"
            className={"action-icon-container-primary-hover"}
          >
            <DropdownToggle caret={false} tag={"span"}>
              <Icon
                role="button"
                name="vdots"
                className="text-dark pointer"
                title={t("action:other_options")}
                placement="right"
              />
            </DropdownToggle>
            <DropdownMenu modifiers={{ offset: { offset: "0, 12" } }} className="border-primary">
              <DropdownItemCreateInteraction
                disabled={!estimate.customer}
                initialValues={{
                  estimate: estimate.id,
                  customer: estimate.customer,
                }}
                lockedFields={["estimate"]}
              />
              <Separator className="my-0 mx-4 border-primary" />
              <DropdownItem className={"text-capitalize px-0 dropdown-item-primary-active"}>
                <div
                  className="d-flex flex-row align-items-center mx-4"
                  onClick={() => {
                    toTplModalActions.open({
                      title: estimate.title,
                      code: estimate.code,
                      cloneId: estimate.id,
                      price_list: estimate.price_list,
                    })
                  }}
                >
                  <Icon name="duplicate" className="pointer mr-4" />
                  <span>{t("action:tempetize")}</span>
                </div>
              </DropdownItem>
              <Separator className="my-0 mx-4 border-primary" />
              {!estimate.archived && (
                <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                  <div className="d-flex flex-row align-items-center mx-4" onClick={() => onEstimateArchive()}>
                    <Icon name="archive" className={classNames("pointer mr-4")} />
                    <span>{t("action:archive")}</span>
                  </div>
                </DropdownItem>
              )}
              {estimate.archived && (
                <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                  <div className="d-flex flex-row align-items-center mx-4" onClick={() => onEstimateRestore()}>
                    <Icon name="restore_archive" className={classNames("pointer mr-4")} />
                    <span>{t("action:restore")}</span>
                  </div>
                </DropdownItem>
              )}
              <Separator className="my-0 mx-4 border-primary" />
              {!estimate.archived && (
                <>
                  {estimate.editable_state === "open" && (
                    <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                      <div className="d-flex flex-row align-items-center mx-4" onClick={onEstimateLock}>
                        <Icon name="lock" className={classNames("pointer mr-4")} />
                        <span>{t("action:lock")}</span>
                      </div>
                    </DropdownItem>
                  )}
                  {estimate.editable_state === "closed" && (
                    <DropdownItem className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}>
                      <div className="d-flex flex-row align-items-center mx-4" onClick={onEstimateUnlock}>
                        <Icon name="unlock" className={classNames("pointer mr-4")} />
                        <span>{t("action:unlock")}</span>
                      </div>
                    </DropdownItem>
                  )}
                  <Separator className="my-0 mx-4 border-primary" />
                </>
              )}
              <DropdownItem className={"text-capitalize px-0 dropdown-item-primary-active"}>
                <div
                  className="d-flex flex-row align-items-center mx-4"
                  onClick={() => {
                    deleteModalActions.open()
                  }}
                >
                  <Icon name="delete" className="pointer mr-4" />
                  <span>{t("action:delete")}</span>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        {t("tasks.project", { title: estimate.title })}
        {estimate.is_template && <span className="badge badge-primary ml-4">{t("estimate_info.template_badge")}</span>}
        {estimate.archived && <span className="badge badge-separator ml-4">{t("estimate_info.archived_badge")}</span>}
        {!estimate.archived && estimate.editable_state === "closed" && (
          <span className="badge badge-secondary ml-4">{t("estimate_info.locked_badge")}</span>
        )}
        <div className="ml-3 d-flex flex-row">
          <ImagePreview src={user.initials} title={user.full_name} disableHover />
          {concurrentUsers.map((u) => {
            return <ImagePreview className="mx-1" key={u.user_id} src={u.user_icon} title={u.user_name} disableHover />
          })}
        </div>
      </div>
      <div className="d-flex flex-row justify-content-end align-items-center">
        {estimate.scenarios.length > 0 && (
          <div className="d-flex flex-row justify-content-end align-items-center pointer">
            <ScenarioSelector
              scenariosList={estimate.scenarios}
              selectedScenarios={selectedScenarios}
              onSave={setSelectedScenarios}
              title={t("tasks.scenario.select_scenario_message")}
              enableAll={true}
              enableBase={true}
              reducer={(tempState, selected) => {
                if (selected === null) {
                  return null
                } else if (selected?.length === 0) {
                  return []
                } else {
                  if (tempState === null) {
                    return [selected.id]
                  } else if (tempState.includes(selected.id)) {
                    return tempState.filter((i) => i !== selected.id)
                  } else {
                    return [...tempState, selected.id]
                  }
                }
              }}
              isOpen={isDropdownOpen}
              toggle={toggle}
            >
              <span className="font-weight-semibold text-uppercase">{t("scenarios.filter_message") + ":"}</span>
              {selectedScenarios === null &&
                estimate.scenarios.map((scenario) => (
                  <ScenarioSymbol key={scenario.id} scenario={scenario} isInferred={false} className="mx-2" />
                ))}
              {selectedScenarios !== null &&
                selectedScenarios.length > 0 &&
                estimate.scenarios.map((scenario) => {
                  if (!selectedScenarios.includes(scenario.id)) {
                    return null
                  }
                  return <ScenarioSymbol key={scenario.id} scenario={scenario} isInferred={false} className="mx-2" />
                })}
              {selectedScenarios !== null && selectedScenarios.length === 0 && (
                <span className="badge badge-separator ml-4 py-2">{t("scenarios.filter_base_only")}</span>
              )}
            </ScenarioSelector>
          </div>
        )}
        <div className="h-100 bg-primary-15 py-5 px-6">
          {t("tasks.total", {
            price: currencyFmt.format(estimate.price),
          })}
        </div>
      </div>
      <ConfirmDeleteModal
        toggle={deleteModalActions.toggle}
        isOpen={deleteModal.isOpen}
        onConfirm={onEstimateDeletion}
        item={estimate?.title ?? ""}
        onClosed={deleteModalActions.onClose}
      />

      {toTplModal.value && (
        <CreateEstimateModal
          mode="templetize"
          cloneEstimate={toTplModal.value}
          toggle={toTplModalActions.toggle}
          isOpen={toTplModal.isOpen}
          onSave={onEstimateToTemplate}
          onClosed={toTplModalActions.onClose}
        />
      )}
    </div>
  )
}

import { rj, useRunRj } from "react-rocketjump"
import api from "../api"

const DocFragments = rj({
  name: "DocFragments",
  effectCaller: rj.configured(),
  effect: (wpAuth) => (params = {}) =>
    api.auth(wpAuth).get(`/api/document-fragment`, params),
  computed: {
    frags: "getData",
  },
})

export function useDocFragments(params) {
  return useRunRj(DocFragments, [params])
}

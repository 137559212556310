export const defaultData = {
  maxDepth: 5,
  isResourceVisible: true,
  isDotsVisible: true,
  visibleTasks: null,
  isTotalVisible: true,
  isScenarioVisible: true,
  arePricesVisible: true,
}

export { default as EditDataForm } from "./EditDataForm"
export { default as FragComponent } from "./FragComponent"
export { default as pageSplitter } from "./pageSplitter"

import { range, keyBy } from "lodash"
import Slider from "rc-slider"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { getDepth } from "react-sortable-tree"
import Dropdown from "../../../../components/Dropdown"
import useCurrentEstimateOption from "../../../../hooks/useCurrentEstimateOption"
import useWindowSize from "../../../../hooks/useWindowSize"
import { cutTree } from "../../EstimateTasksTree/TaskTable/utils"
import {
  BezierConnection,
  LinearConnection,
  StepConnection,
} from "./Connections"
import Dendrogram from "./Dendrogram"
import { calcHorizontalHeight, calcVerticalHeight } from "./utils"

export default function EstimateTreeViz({ estimate }) {
  const [orientation, setOrientation] = useCurrentEstimateOption(
    "viz.dendogram.orientation",
    "horizontal"
  )
  const [linkType, setLinkType] = useCurrentEstimateOption(
    "viz.dendogram.linkType",
    "diagonal"
  )

  const { t } = useTranslation(["translation", "enums"])

  const depth = useMemo(() => getDepth({ children: estimate.task_tree }), [
    estimate.task_tree,
  ])

  const marks = useMemo(() => {
    const values = range(1, depth + 1).map((v) => {
      if (v < depth) {
        return {
          id: v,
          label: t("tasks.level", { level: v }),
        }
      }
      return {
        id: v,
        label: t("tasks.all"),
      }
    })
    return keyBy(values, "id")
  }, [depth, t])

  const orientationOptions = useMemo(
    () => [
      {
        label: t("enums:orientation.horizontal"),
        value: "horizontal",
      },
      {
        label: t("enums:orientation.vertical"),
        value: "vertical",
      },
    ],
    [t]
  )

  const linkTypeOptions = useMemo(
    () => [
      {
        label: <BezierConnection width="19px" height="19px" />,
        value: "diagonal",
      },
      {
        label: <StepConnection width="19px" height="19px" />,
        value: "step",
      },
      {
        label: <LinearConnection width="19px" height="19px" />,
        value: "line",
      },
    ],
    []
  )

  const [maxDepth, setMaxDepth] = useState(depth)

  const tree = useMemo(() => cutTree(estimate.task_tree, maxDepth), [
    estimate.task_tree,
    maxDepth,
  ])

  const { width } = useWindowSize()
  let height
  if (orientation && orientation === "horizontal") {
    height = calcHorizontalHeight(tree.children)
  } else {
    height = calcVerticalHeight(tree.children)
  }

  return (
    <div>
      <div className="mt-8">
        <p>{t("tasks.tree_description")}</p>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            {depth > 1 && (
              <Slider
                min={1}
                marks={marks}
                step={null}
                max={depth}
                onChange={setMaxDepth}
                defaultValue={depth}
                style={{ width: 90 * (depth - 1) }}
                trackStyle={{ backgroundColor: "var(--primary)" }}
                handleStyle={{
                  border: "2px solid var(--primary)",
                  height: 16,
                  width: 16,
                  marginTop: -6,
                }}
                activeDotStyle={{
                  borderColor: "transparent",
                  backgroundColor: "var(--primary)",
                }}
              />
            )}
          </div>
          <div className="d-flex flex-row align-items-center justify-content-end">
            <div className="d-flex flex-row align-items-center mr-8">
              <span className="mr-3">
                {t("documents.frag.viz.orientation") + ":"}
              </span>
              <Dropdown
                value={orientation}
                style={{ width: 140 }}
                itemWidth={140}
                options={orientationOptions}
                onChange={(v) => setOrientation(v)}
              />
            </div>
            <div>
              <span className="mr-3">
                {t("documents.frag.viz.link_type") + ":"}
              </span>
              <Dropdown
                value={linkType}
                style={{ width: 80 }}
                itemWidth={80}
                options={linkTypeOptions}
                onChange={(v) => setLinkType(v)}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Dendrogram
            width={
              orientation === "horizontal"
                ? Math.min(1100, width - 120)
                : Math.min(calcHorizontalHeight(tree.children), width - 120)
            }
            height={height}
            orientation={orientation}
            linkType={linkType}
            data={tree}
          />
        </div>
      </div>
    </div>
  )
}

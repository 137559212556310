import React from "react"
import { useTranslation } from "react-i18next"
import Icon from "../../../components/Icon"
import { usePdfActivityReport } from "../../../hooks/pdf"
import S from "./DownloadButton.module.scss"

export default function DownloadButton({ project, filters }) {
  const { downloading, downloadPdf } = usePdfActivityReport()
  const { t } = useTranslation(["action"])

  return (
    <svg width={64} height={64} viewBox={"-32 -32 64 64"} className={S["wrapper"]}>
      <circle
        cx={0}
        cy={0}
        r={29}
        className={S["button"]}
        onClick={() => {
          if (!downloading) {
            downloadPdf(project.id, filters, `${project.code}.pdf`)
          }
        }}
      />
      {downloading && (
        <path d="M29 0 A29 29 0 0 0 20.5 -20.5" className={S["arc"]} />
      )}
      <Icon
        name="pdf"
        title={t("action:pdf_export")}
        className={S["icon"]}
        width={40}
        height={40}
        x={-20}
        y={-20}
      />
    </svg>
  )
}

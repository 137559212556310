import { getIn } from "formik"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import useCurrentOrganization from "../../hooks/useCurrentOrganization"
import useIsMount from "../../hooks/useIsMount"
import { CustomerSelector } from "../CustomerSelector"
import ErrorPopover from "../ErrorPopover/ErrorPopover"

export default function FieldCustomerSelector({
  field,
  form: { touched, errors, setFieldValue },
  label,
  className = "",
  containerClassName = "",
  controlClassName = "",
  caption = "",
  enableNone = false,
  enableAll = false,
  ...props
}) {
  const { t } = useTranslation(["field"])
  const input = useRef()
  const isMount = useIsMount()
  const fieldError = getIn(errors, field.name)
  const fieldTouched = getIn(touched, field.name)
  const showError = fieldError && fieldTouched

  const org = useCurrentOrganization()

  return (
    <div className={`d-flex flex-column ${containerClassName}`}>
      {label && (
        <label className="font-weight-semibold mb-3 h3 text-uppercase">
          {label}
        </label>
      )}
      <CustomerSelector
        {...props}
        className={controlClassName}
        orgId={org.id}
        customerId={field.value}
        onChange={(nextId) => {
          setFieldValue("customer", nextId)
        }}
        caption={caption}
        enableNone={enableNone}
        enableAll={enableAll}
      />
      {showError && !isMount && (
        <ErrorPopover
          placement={"top-start"}
          target={input.current}
          title={t("field:errors.general")}
          description={fieldError}
        />
      )}
    </div>
  )
}

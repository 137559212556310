import React from "react"
import {
  adaptForScenarios,
  markForScenarios,
  sliceExtent,
} from "../../../treeOperations"
import { max } from "lodash"
import ScenarioTaskViz from "../../../EstimateVisualization/EstimateScenarioViz/ScenarioTaskViz"
import { cutTree } from "../../../EstimateTasksTree/TaskTable/utils"
import RichTextEditor from "../../../../../components/RichTextEditor/RichTextEditor"
import { pagerLog } from "../../Pager"

const adaptScenarios = (
  visibleTasks,
  isNormalized,
  scenario,
  fullEstimate,
  maxDepth
) => {
  const isBase = scenario === null

  const estimate = isNormalized
    ? markForScenarios(fullEstimate, isBase ? [] : [scenario.id])
    : adaptForScenarios(fullEstimate, isBase ? [] : [scenario.id])

  const selectTree = sliceExtent(estimate.task_tree, visibleTasks)

  const cutSelectTree = cutTree(selectTree, maxDepth).children

  const estimatePrice = cutSelectTree.reduce(
    (acc, firstLevelChild) =>
      parseFloat(
        isNormalized ? firstLevelChild.fullTaskPrice : firstLevelChild.price
      ) + acc,
    0
  )

  return {
    estimate: { ...estimate, price: estimatePrice, task_tree: cutSelectTree },
    scenario: scenario,
  }
}

const FragComponent = React.memo(
  ({
    frag,
    fullEstimate,
    sliceRowStart,
    sliceRowEnd,
    inPage,
    width,
    chunkIsFirst,
  }) => {
    if (inPage) {
      pagerLog("[PAGER] Scenario task viz rendered")
    }

    const visibleTasks = frag?.data?.visibleTasks ?? null
    const maxDepth = frag?.data?.maxDepth

    const adaptedScenarios = [
      adaptScenarios(
        visibleTasks,
        frag?.data?.isNormalized,
        null,
        fullEstimate,
        maxDepth
      ),
      ...fullEstimate.scenarios.map((scenario) =>
        adaptScenarios(
          visibleTasks,
          frag?.data?.isNormalized,
          scenario,
          fullEstimate,
          maxDepth
        )
      ),
    ]

    const maxTaskPrice = max(
      adaptedScenarios.map((adapted) => parseFloat(adapted.estimate.price))
    )

    // First node is legend, so don't account for it when slicing node list
    sliceRowStart = sliceRowStart && sliceRowStart - 1
    sliceRowEnd = sliceRowEnd && sliceRowEnd - 1

    return (
      <div className="">
        <>
          {frag?.data?.showTitle && chunkIsFirst && (
            <h1 className="__pager-title m-0 pb-3">{frag?.title}</h1>
          )}
          {frag?.data?.showLegend && chunkIsFirst && (
            <div className="__pager-node m-0 pb-3">
              <ScenarioTaskViz.Legend
                estimate={{
                  ...fullEstimate,
                  task_tree: sliceExtent(fullEstimate.task_tree, visibleTasks),
                }}
                columns={2}
              />
            </div>
          )}
          {adaptedScenarios
            .filter(
              (elem) =>
                (frag?.data?.isBaseVisible && elem.scenario === null) ||
                elem.scenario !== null
            )
            .map((adapted, i) => {
              if (
                (sliceRowStart !== undefined && i < sliceRowStart) ||
                (sliceRowEnd !== undefined && i >= sliceRowEnd)
              ) {
                return null
              }
              return (
                <div className="__pager-node">
                  <ScenarioTaskViz
                    estimate={adapted.estimate}
                    scenario={adapted.scenario}
                    width={width}
                    maxPrice={maxTaskPrice}
                    isPriceVisible={frag?.data?.isPriceVisible}
                    isNormalized={frag?.data?.isNormalized}
                  />
                </div>
              )
            })}
          {frag.data.enableCaption && (
            <div
              className="__pager-caption"
              style={{ flex: "1 1 0%", minWidth: 0, paddingLeft: 4 }}
            >
              <RichTextEditor
                content={frag?.data?.caption}
                readOnly={true}
                placeholder={""}
                enableImages={false}
                minHeight0
              />
            </div>
          )}
        </>
      </div>
    )
  },
  (prevProps, nextProps) => {
    const checkProps = [
      "frag",
      "fullEstimate",
      "width",
      "sliceRowStart",
      "sliceRowEnd",
      "chunkIsFirst",
    ]
    for (let i = 0; i < checkProps.length; i++) {
      const prop = checkProps[i]
      if (prevProps[prop] !== nextProps[prop]) {
        return false
      }
    }
    return true
  }
)

export default FragComponent

import React from "react"
import { useTranslation } from "react-i18next"
import { BsCalendar2Check } from "react-icons/bs"
import { FaRegMoneyBillAlt } from "react-icons/fa"
import { TiFlowSwitch } from "react-icons/ti"
import { useParams } from "react-router-dom"
import Icon from "../../components/Icon"
import Layout from "../../components/Layout"

export default function EstimateTabBarContent() {
  const { t } = useTranslation(["translation", "tab", "action"])
  const { id } = useParams()

  return (
    <div className="w-100 d-flex justify-content-between">
      <div className="d-flex align-items-start justify-content-start">
        <Layout.TabBar.TabBarTab
          text={t("tab:estimate.info")}
          exact
          to={`/estimates/${id}`}
          svgIcon={<Icon name="info" />}
          activeUrlPrefixes={[`/estimates/${id}/edit`]}
        />
        <Layout.TabBar.Divider />
        <Layout.TabBar.TabBarTab
          text={t("tab:estimate.tasks")}
          to={`/estimates/${id}/tasks`}
          svgIcon={<Icon name="task" style={{ width: 23, height: 21 }} />}
        />
        <Layout.TabBar.Divider />
        <Layout.TabBar.TabBarTab
          text={t("tab:estimate.billing")}
          to={`/estimates/${id}/billing`}
          svgIcon={<FaRegMoneyBillAlt style={{ width: 16 }} />}
        />
        <Layout.TabBar.Divider />
        <Layout.TabBar.TabBarTab
          text={t("tab:estimate.viz")}
          to={`/estimates/${id}/viz`}
          svgIcon={<Icon name="graph" style={{ width: 23, height: 21 }} />}
        />
        <Layout.TabBar.Divider />
        <Layout.TabBar.TabBarTab
          text={t("tab:estimate.resources")}
          to={`/estimates/${id}/resources`}
          svgIcon={<Icon name="resource" style={{ width: 23, height: 21 }} />}
        />
        <Layout.TabBar.Divider />
        <Layout.TabBar.TabBarTab
          text={t("tab:estimate.document")}
          to={`/estimates/${id}/document`}
          svgIcon={<Icon name="document" style={{ width: 23, height: 21 }} />}
        />
        <Layout.TabBar.Divider />
        <Layout.TabBar.TabBarTab
          text={t("tab:estimate.interactions")}
          to={`/estimates/${id}/interactions`}
          svgIcon={<BsCalendar2Check style={{ width: 16 }} />}
        />
        <Layout.TabBar.Divider />
        <Layout.TabBar.TabBarTab
          text={t("tab:estimate.flow")}
          to={`/estimates/${id}/flow`}
          svgIcon={<TiFlowSwitch style={{ width: 16 }} />}
        />
        <Layout.TabBar.Divider />
      </div>
    </div>
  )
}

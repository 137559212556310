import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../components/Button"
import PasswordShowHide from "../../components/PasswordShowHide/PasswordShowHide"
import WpModal from "../../components/WpModal"

export default function ConfirmDeleteModal({
  isOpen,
  toggle,
  onClosed,
  onConfirm,
}) {
  const { t } = useTranslation(["translation"])
  const [challengeInput, setChallengeInput] = useState("")

  return (
    <WpModal
      isOpen={isOpen}
      toggle={toggle}
      title={t("confirm_delete.title")}
      onClosed={onClosed}
    >
      <div className="d-flex flex-column">
        <div className="pb-5">
          <p className="pb-4 m-0" style={{ userSelect: "none" }}>
            {t("profile.delete_challenge")}
          </p>
          <PasswordShowHide className="w-100" onChange={(e) => setChallengeInput(e.target.value)} />
        </div>
        <div className="d-flex flex-row align-items-center justify-content-end">
          <Button size="sm" color="none" onClick={toggle}>
            {t("action:cancel")}
          </Button>
          <Button
            size="sm"
            color="dark"
            className="ml-8"
            disabled={challengeInput === ""}
            onClick={() => {
              onConfirm(challengeInput)
              toggle()
            }}
          >
            {t("action:confirm")}
          </Button>
        </div>
      </div>
    </WpModal>
  )
}

import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { notifyError } from "../../../../utils"
import TaskTree from "./TaskTree"

export default function TreeView({
  estimate,
  updatingAllocations,
  saving,
  removeTask,
  resources,
  resourcesById,
  patchTaskResource,
  addTaskResource,
  removeTaskResource,
  patchTask,
  addTaskFixedCost,
  patchTaskFixedCost,
  removeTaskFixedCost,
  ...props
}) {
  const { t } = useTranslation(["translation"])

  const handleSubmitUpdateTask = useCallback(
    (task) => patchTask.onFailure(notifyError).asPromise(task),
    [patchTask]
  )

  // TODO: This sucks a lot i know i'll find a better solution...
  const {
    handlePatchTaskResource,
    handleAddTaskResource,
    handleRemoveTaskResource,
    handleAddTaskFixedCost,
    handlePatchTaskFixedCost,
    handleRemoveTaskFixedCost,
  } = useMemo(
    () => ({
      handlePatchTaskResource: patchTaskResource.onFailure(notifyError).curry(),
      handleAddTaskResource: addTaskResource.onFailure(notifyError).curry(),
      handleRemoveTaskResource: removeTaskResource
        .onFailure(notifyError)
        .curry(),
      handleAddTaskFixedCost: addTaskFixedCost.onFailure(notifyError).curry(),
      handlePatchTaskFixedCost: patchTaskFixedCost
        .onFailure(notifyError)
        .curry(),
      handleRemoveTaskFixedCost: removeTaskFixedCost
        .onFailure(notifyError)
        .curry(),
    }),
    [
      addTaskFixedCost,
      addTaskResource,
      patchTaskFixedCost,
      patchTaskResource,
      removeTaskFixedCost,
      removeTaskResource,
    ]
  )

  return (
    <div>
      <div className="text-right text-separator h3 mb-2">
        {saving && t("message.saving")}
        {!saving && t("message.saved")}
      </div>
      {/* 
        Popper has some issues flipping dropdowns, so just add padding to ensure
        they fit in the viewport. 

        https://github.com/inmagik/workplan2-web/issues/169
      */}
      <div className="mt-1" style={{ paddingBottom: 200 }}>
        <TaskTree
          estimate={estimate}
          updatingAllocations={updatingAllocations}
          onSubmitUpdateTask={handleSubmitUpdateTask}
          taskTree={estimate.task_tree}
          removeTask={removeTask}
          resources={resources}
          resourcesById={resourcesById}
          patchTaskResource={handlePatchTaskResource}
          addTaskResource={handleAddTaskResource}
          removeTaskResource={handleRemoveTaskResource}
          addTaskFixedCost={handleAddTaskFixedCost}
          patchTaskFixedCost={handlePatchTaskFixedCost}
          removeTaskFixedCost={handleRemoveTaskFixedCost}
          {...props}
        />
      </div>
    </div>
  )
}

import { deps, rj, useRunRj } from "react-rocketjump"
import api from "../api"

const ProjectInsightActivity = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (projectId, filters) => {
    return api
      .auth(wpAuth)
      .get(`/api/project/${projectId}/insights/activities/`, filters)
  },
})

export function useProjectInsightsActivity(projectId, params) {
  return useRunRj(ProjectInsightActivity, [deps.maybe(projectId), params])
}

import { Field, Formik } from "formik"
import { mapValues } from "lodash"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import Button from "../../components/Button"
import FieldColorPicker from "../../components/fields/FieldColorPicker"
import FieldInput from "../../components/fields/FieldInput"
import WpModal from "../../components/WpModal"
import { convertErrorToForm } from "../../utils"

const INITIAL_VALUES = {
  name: "",
  color: "",
}

export function EditCategoryModal({ isOpen, toggle, onSave, onClosed, category }) {
  const { t } = useTranslation(["translation", "field", "action"])

  const schema = yup.object().shape({
    name: yup.string().required(t("field:errors.mandatory", { field: t("field:tag_category.name") })),
    color: yup.string().required(t("field:errors.mandatory", { field: t("field:tag_category.color") })),
  })

  return (
    <WpModal
      onClosed={onClosed}
      isOpen={isOpen}
      toggle={toggle}
      title={category?.id ? t("action:edit_tag_category") : t("action:new_tag_category")}
    >
      <div>
        <div className="d-flex flex-row align-items-start my-4">
          <div className="d-flex flex-column ml-2 flex-1">
            <Formik
              initialValues={{ ...INITIAL_VALUES, ...category }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                return onSave(mapValues(values, (val) => (val === undefined ? null : val))).catch((error) => {
                  actions.setErrors(convertErrorToForm(error))
                })
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <>
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Field name="name" component={FieldInput} label={t("field:tag_category.name")} className={"mb-5"} />
                    <Field
                      name="description"
                      component={FieldInput}
                      label={t("field:tag_category.description")}
                      className={"mb-5"}
                    />
                    <Field
                      name="color"
                      component={FieldColorPicker}
                      className={"mb-5 align-self-start"}
                      label={t("field:tag_category.color")}
                    />
                    <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                      <Button size="sm" color="none" onClick={toggle}>
                        {t("action:cancel")}
                      </Button>
                      <Button size="sm" color="primary" type="submit" className="ml-5" disabled={!formik.isValid}>
                        {category?.id ? t("action:save") : t("action:add")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </WpModal>
  )
}

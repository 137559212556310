import { Field, Formik } from "formik"
import React, { useMemo } from "react"
import find from "lodash/find"
import Button from "../../../../components/Button"
import FieldDropdown from "../../../../components/fields/FieldDropdown"
import WpModal from "../../../../components/WpModal"
import { useTranslation } from "react-i18next"

export const INITIAL_TASK_VALUES = {
  title: "",
  target: "",
  position: "after",
}

export default function MoveTaskModalForm({
  isOpen,
  toggle,
  taskTreeOptions,
  onSubmit,
  onClosed,
  task = {},
}) {
  const { t } = useTranslation(["translation", "field", "action"])

  const POSITION_OPTIONS = useMemo(() => {
    return [
      {
        label: t("tasks.after"),
        value: "after",
      },
      {
        label: t("tasks.child_of"),
        value: "children",
      },
    ]
  }, [t])

  return (
    <WpModal
      isOpen={isOpen}
      toggle={toggle}
      title={t("tasks.move_task_title")}
      onClosed={onClosed}
    >
      <Formik
        onSubmit={(values, actions) => {
          const { target, position, ...task } = values
          const taskPayload = {
            after: null,
            parent: null,
            ids: [task.id],
          }
          if (target && position) {
            if (position === "after") {
              taskPayload.after = target
              taskPayload.parent = find(taskTreeOptions, {
                value: target,
              }).parent
            } else if (position === "children") {
              taskPayload.parent = target
            }
          }
          return onSubmit(taskPayload, actions)
        }}
        initialValues={{ ...INITIAL_TASK_VALUES, ...task }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <p>{t("tasks.move_task_modal_message", { item: task.title })}</p>
            <Field
              label={t("field:task.position")}
              name="position"
              placeholder="Select a postion"
              className="mb-5"
              component={FieldDropdown}
              capitalize={false}
              options={POSITION_OPTIONS}
            />
            <Field
              label={t("field:task.target")}
              name="target"
              placeholder="Select a target"
              component={FieldDropdown}
              capitalize={false}
              options={taskTreeOptions}
            />
            <div className="d-flex flex-row align-items-center justify-content-end pt-7">
              <Button size="lg" color="none" onClick={toggle}>
                {t("action:cancel")}
              </Button>
              <Button
                size="lg"
                disabled={isSubmitting}
                onClick={handleSubmit}
                className="ml-5"
              >
                {t("action:save")}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </WpModal>
  )
}

import { Field, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { Form } from "reactstrap"
import Button from "../../components/Button"
import WpModal from "../../components/WpModal"
import FieldInput from "../../components/fields/FieldInput"
import FieldFile from "../../components/fields/FieldFile"
import FieldDropdown from "../../components/fields/FieldDropdown"

function returnTrue() {
  return true
}

export function AuditTrailModal({
  isOpen,
  toggle,
  onClosed,
  onSave,
  initialValue,
  widget,
  reasonRequired = returnTrue,
  reasonOptions,
}) {
  const { t } = useTranslation()

  const formInitValue = {
    newValue: initialValue,
    reason: "",
    attachment: null,
  }

  return (
    <WpModal isOpen={isOpen} toggle={toggle} size="sm" onClosed={onClosed} title={t("auditTrailModal.title")}>
      <div className="d-flex flex-column">
        <Formik
          initialValues={formInitValue}
          onSubmit={(values) => {
            onSave(values)
            toggle()
          }}
        >
          {(formik) => {
            const isReasonRequired =
              typeof reasonRequired === "function" && reasonRequired(formik.values.newValue, initialValue)
            return (
              <Form onSubmit={formik.handleSubmit}>
                {widget}
                {isReasonRequired && (
                  <>
                    {reasonOptions && (
                      <Field
                        name="reason"
                        containerClassName="mt-3"
                        label={t("field:project_audit_trail.reason")}
                        component={FieldDropdown}
                        options={reasonOptions}
                      />
                    )}
                    {!reasonOptions && (
                      <Field
                        name="reason"
                        containerClassName="mt-3"
                        label={t("field:project_audit_trail.reason")}
                        placeholder={t("field:project_audit_trail.placeholder.reason")}
                        component={FieldInput}
                      />
                    )}
                  </>
                )}
                <Field
                  name="attachment"
                  containerClassName="mt-3"
                  label={t("field:project_audit_trail.attachment")}
                  placeholder={t("field:project_audit_trail.placeholder.attachment")}
                  component={FieldFile}
                  type="file"
                />
                <div className="d-flex flex-row justify-content-end mt-5">
                  <Button size="sm" color="none" onClick={toggle}>
                    {t("action:cancel")}
                  </Button>
                  <Button
                    size="sm"
                    color="primary"
                    type="submit"
                    className="ml-5"
                    disabled={!formik.values.newValue || (isReasonRequired && !formik.values.reason)}
                  >
                    {t("action:save")}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </WpModal>
  )
}

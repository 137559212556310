import { rj, useRj, useRunRj } from "react-rocketjump"
import rjDebounce from "react-rocketjump/plugins/debounce"
import rjPlainList from "react-rocketjump/plugins/plainList"
import api from "../api"

const tagCategoriesRj = rj(rjDebounce(), rjPlainList(), {
  name: "tag-category",
  effectCaller: rj.configured(),
  effect: (wpAuth) => (params = {}) =>
    api.auth(wpAuth).get(`/api/tag-category/with-tags/`, params),
  mutations: {
    createCategory: rj.mutation.single({
      effect: (wpAuth) => (data) => {
        return api.auth(wpAuth).post(`/api/tag-category`, data)
      },
      updater: (state, result) => ({
        ...state,
        data: [...state.data, { tags: [], ...result }],
      }),
    }),
    updateCategory: rj.mutation.single({
      effect: (wpAuth) => (id, data) => {
        return api.auth(wpAuth).patch(`/api/tag-category/${id}`, data)
      },
      updater: (state, result) => {
        return {
          ...state,
          data: state.data.map((item) =>
            item.id === result.id ? { ...item, ...result } : item
          ),
        }
      },
    }),
    removeCategory: rj.mutation.single({
      effect: (wpAuth) => (itemId) => {
        return api
          .auth(wpAuth)
          .mapResponse(() => itemId)
          .delete(`/api/tag-category/${itemId}`)
      },
      updater: (state, result) => {
        return {
          ...state,
          data: state.data.filter((item) => item.id !== result),
        }
      },
    }),

    createTag: rj.mutation.single({
      effect: (wpAuth) => (data) => {
        return api.auth(wpAuth).post(`/api/tag`, data)
      },
      updater: (state, result) => ({
        ...state,
        data: state.data.map((item) =>
          item.id === result.category
            ? { ...item, tags: [...item.tags, result] }
            : item
        ),
      }),
    }),
    updateTag: rj.mutation.single({
      effect: (wpAuth) => (id, data) => {
        return api.auth(wpAuth).patch(`/api/tag/${id}`, data)
      },
      updater: (state, result) => {
        return {
          ...state,
          data: state.data.map((item) =>
            item.id === result.category
              ? {
                  ...item,
                  tags: item.tags.map((tag) =>
                    tag.id === result.id ? result : tag
                  ),
                }
              : item
          ),
        }
      },
    }),
    removeTag: rj.mutation.single({
      effect: (wpAuth) => (itemId) => {
        return api
          .auth(wpAuth)
          .mapResponse(() => itemId)
          .delete(`/api/tag/${itemId}`)
      },
      updater: (state, result) => {
        return {
          ...state,
          data: state.data.map((item) => ({
            ...item,
            tags: item.tags.filter((tag) => tag.id !== result),
          })),
        }
      },
    }),
  },
  computed: {
    tagCategories: "getData",
    loading: "isPending",
    error: "getError",
  },
})

export function useTags(params) {
  return useRunRj(tagCategoriesRj, [params], false)
}

function useTagsControlled() {
  return useRj(tagCategoriesRj)
}

useTags.controlled = useTagsControlled

import ProjectInsightActivityBarchart from "../../../components/ProjectInsightActivityBarchart"
import classNames from "classnames"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import ReactResizeDetector from "react-resize-detector"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import ProjectInsightActivityTable from "../../../components/ProjectInsightActivityTable"
import ProjectTaskSelector from "../../../components/ProjectTaskSelector"
import WpInput from "../../../components/WpInput"
import { ProjectContext } from "../../../context"
import { useProjectInsightsActivity } from "../../../hooks/projectInsights"
import S from "../CommonStyle.module.scss"
import ProjectBottomBarContent from "../ProjectBottomBarContent"
import ProjectTabBarContent from "../ProjectTabBarContent"
import DownloadButton from "./DownloadButton"
import Button from "../../../components/Button/Button"
import Icon from "../../../components/Icon"
import { Table } from "reactstrap"
import dayjs from "dayjs"
import { isNil } from "lodash"
import { TrackerLandmarks } from "../../../components/TrackerLandmarks"

export default function ProjectInsight({ history, location }) {
  const { t } = useTranslation(["translation", "tab", "action"])

  const [{ project }] = useContext(ProjectContext)

  const [
    { fromDate, toDate, resourceName, subjectName, projectTask, showSubjects, exportTasks },
    setQueryParams,
    {
      fromDate: debFromDate,
      toDate: debToDate,
      resourceName: debResourceName,
      subjectName: debSubjectName,
      projectTask: debProjectTask,
    },
    setDebQueryParams,
  ] = useQueryParams({})

  const filters = useMemo(
    () => ({
      project: project.id,
      date_start__gte: debFromDate,
      date_start__lte: debToDate,
      resource_name: debResourceName,
      subject_name: debSubjectName,
      project_task: debProjectTask,
    }),
    [project.id, debFromDate, debToDate, debResourceName, debSubjectName, debProjectTask]
  )

  const [{ data }] = useProjectInsightsActivity(project.id, filters)

  return (
    <Layout>
      <div className="container-fluid pt-8 px-page pb-page-bottom">
        <div className="d-flex flex-row justify-content-start align-items-center mb-6">
          <div className="mr-8">
            <label className={classNames(S["filter-label"], "d-block")}>{t("project_insights.period_filter")}</label>
            <WpInput
              medium
              className="mr-5"
              type="date"
              value={fromDate}
              onChange={(e) => setDebQueryParams({ fromDate: e.target.value })}
            />
            <WpInput medium type="date" value={toDate} onChange={(e) => setDebQueryParams({ toDate: e.target.value })} />
          </div>
          <div className="mr-8">
            <label className={classNames(S["filter-label"], "d-block")}>{t("project_insights.resource_name_filter")}</label>
            <WpInput
              medium
              placeholder={t("project_insights.resource_name_filter_placeholder")}
              value={resourceName}
              onChange={(e) => setDebQueryParams({ resourceName: e.target.value })}
            />
          </div>
          <div className="mr-8">
            <label className={classNames(S["filter-label"], "d-block")}>{t("project_insights.subject_name_filter")}</label>
            <WpInput
              medium
              placeholder={t("project_insights.subject_name_filter_placeholder")}
              value={subjectName}
              onChange={(e) => setDebQueryParams({ subjectName: e.target.value })}
            />
          </div>
          <div className="mr-8">
            <label className={classNames(S["filter-label"], "d-block")}>{t("project_insights.project_task_filter")}</label>
            <ProjectTaskSelector
              projectId={project.id}
              taskId={projectTask}
              onTaskChange={(nextTask) => {
                setQueryParams({ projectTask: nextTask?.id ?? undefined })
              }}
              placeholder={t("project_insights.project_task_filter_placeholder")}
              capitalize={false}
              emptyMessage={t("field:errors.no_match")}
              clearable
              style={{ width: 250 }}
              oneLine
            />
          </div>
          <Button
            className="d-inline-flex align-items-center px-5 mr-5"
            onClick={() => history.push(location.pathname.replace("insight", "activity") + location.search)}
            style={{ marginTop: 22 }}
          >
            <Icon name="project-activity" className="mr-2" height={16} width={16} />
            {t("action:display_activities")}
          </Button>
        </div>
        {data && (
          <>
            <div className="mt-5 d-flex flex-column justify-content-start align-items-center">
              <div className="w-50">
                <h2 className="mb-3">{t("project_insights.summary")}</h2>
                <div className="d-flex flex-row justify-content-end align-items-center mb-5">
                  <input
                    type="checkbox"
                    checked={showSubjects === "1"}
                    onChange={(e) =>
                      setQueryParams({
                        showSubjects: e.target.checked ? "1" : "0",
                      })
                    }
                  />
                  <label className="my-0 ml-2">{t("project_insights.show_subjects")}</label>
                </div>
                <ProjectInsightActivityTable activityAggregates={data} showSubjects={showSubjects === "1"} />
              </div>
              <div className="w-50 mt-3">
                <h2 className="mb-3">{t("project_insights.time_effort")}</h2>
                <Table striped size="sm" style={{ tableLayout: "fixed" }}>
                  <tbody>
                    {data.by_cost_unit.map((record) => (
                      <tr key={record.cost_unit}>
                        <td>{record.cost_unit}</td>
                        <td>{record.sum}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>{" "}
              <div className="w-50 mt-3">
                <h2 className="mb-3">{t("project_insights.indicators")}</h2>
                <Table striped size="sm" style={{ tableLayout: "fixed" }}>
                  <tbody>
                    <tr>
                      <td>{t("project_insights.indicators_start")}</td>
                      <td>{formatDate(project.date_begin)}</td>
                    </tr>
                    <tr>
                      <td>{t("project_insights.indicators_planned_end")}</td>
                      <td>{formatDate(project.estimated_delivery_date)}</td>
                    </tr>
                    <tr>
                      <td>{t("project_insights.indicators_real_end")}</td>
                      <td>{formatDate(project.date_end)}</td>
                    </tr>
                    <tr>
                      <td>{t("project_insights.indicators_duration_estimated")}</td>
                      <td>{formatDays(data.indicators.duration_estimated, t)}</td>
                    </tr>
                    <tr>
                      <td>{t("project_insights.indicators_duration_real")}</td>
                      <td>{formatDays(data.indicators.duration_real, t)}</td>
                    </tr>
                    <tr>
                      <td>{t("project_insights.indicators_delay")}</td>
                      <td>{formatDays(data.indicators.delay, t)}</td>
                    </tr>
                    <tr>
                      <td>{t("project_insights.indicators_value")}</td>
                      <td>{formatEuro(project.project_value)}</td>
                    </tr>
                    <tr>
                      <td>{t("project_insights.indicators_billed_amount")}</td>
                      <td>{formatEuro(data.indicators.billed_amount)}</td>
                    </tr>
                    <tr>
                      <td>{t("project_insights.indicators_total_cost")}</td>
                      <td>{formatEuro(data.indicators.total_cost)}</td>
                    </tr>
                    <tr>
                      <td>{t("project_insights.indicators_neat_margin")}</td>
                      <td>{formatPercent(data.indicators.neat_margin)}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="w-50 mt-8">
                <ReactResizeDetector handleWidth>
                  {({ width, targetRef }) => {
                    return (
                      <div ref={targetRef}>
                        <h2>{t("project_insights.chart")}</h2>
                        <ProjectInsightActivityBarchart activityAggregates={data} width={width} />
                      </div>
                    )
                  }}
                </ReactResizeDetector>
              </div>
              <div className="w-50 mt-8">
                <div>
                  <h2>{t("project_insights.export_options")}</h2>
                </div>
                <div className="d-flex flex-row justify-content-start align-items-center mb-5">
                  <input
                    type="checkbox"
                    checked={exportTasks === "1"}
                    onChange={(e) =>
                      setQueryParams({
                        exportTasks: e.target.checked ? "1" : "0",
                      })
                    }
                  />
                  <label className="my-0 ml-2">{t("project_insights.export_tasks")}</label>
                </div>
              </div>
            </div>
            <div style={{ position: "fixed", bottom: 76, right: 32 }}>
              <DownloadButton
                project={project}
                filters={{
                  ...filters,
                  opt_show_tasks: exportTasks === "1" ? "yes" : "no",
                }}
              />
            </div>
          </>
        )}
        {!data && <Loader />}
      </div>
      <Layout.TabBar>
        <ProjectTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <ProjectBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

function formatDate(value) {
  if (isNil(value)) {
    return <i>Non calcolabile</i>
  } else {
    return <span>{dayjs(value).format("DD/MM/YYYY")}</span>
  }
}

function formatEuro(value) {
  if (isNil(value)) {
    return <i>Non calcolabile</i>
  } else {
    return <span>{parseFloat(value).toFixed(2) + " €"}</span>
  }
}

function formatDays(value, t) {
  if (isNil(value)) {
    return <i>Non calcolabile</i>
  } else {
    return <span>{value + " " + t("days")}</span>
  }
}

function formatPercent(value) {
  if (isNil(value)) {
    return <i>Non calcolabile</i>
  } else {
    return <span>{parseFloat(value).toFixed(2) + " %"}</span>
  }
}

import React from "react"
import { UncontrolledPopover, PopoverBody, Popover } from "reactstrap"

export default function ErrorPopover({
  placement,
  target,
  title,
  description,
  trigger = "hover",
}) {
  if (trigger === "always") {
    return (
      <Popover
        isOpen={true}
        placement={placement}
        target={target}
        className="custom-popover"
      >
        <PopoverBody>
          <h3 className="text-danger text-uppercase font-weight-semibold">
            {title}
          </h3>
          <div>{description}</div>
        </PopoverBody>
      </Popover>
    )
  }
  return (
    <UncontrolledPopover
      trigger={trigger}
      placement={placement}
      target={target}
      className="custom-popover"
    >
      <PopoverBody>
        <h3 className="text-danger text-uppercase font-weight-semibold">
          {title}
        </h3>
        <div>{description}</div>
      </PopoverBody>
    </UncontrolledPopover>
  )
}

import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import Icon from "../../../components/Icon"

export default function EmptyFrags({ onClick, readOnly, _pager }) {
  const { t } = useTranslation(["translation", "action"])
  return (
    <div className="ml-7 mt-4" {..._pager}>
      {!readOnly && (
        <>
          <div className="d-flex align-items-center">
            <Button color="primary" size="lg" rounded onClick={onClick}>
              <Icon
                name="plus"
                className="pointer"
                title={t("action:new_doc_frag")}
              />
            </Button>
            <div className="ml-3">{t("documents.create_frag")}</div>
          </div>
          <div className="mt-7">{t("documents.empty_frag_message")}</div>
        </>
      )}
      {readOnly && (
        <div className="mt-7 text-center">
          <i>{t("documents.empty")}</i>
        </div>
      )}
    </div>
  )
}

import React, { useMemo } from "react"
import { arc } from "d3-shape"
import chroma from "chroma-js"

const pathsGenerator = (percent, innerRadius, outerRadius, startAngle = 0) => {
  const _arc = arc().cornerRadius((outerRadius - innerRadius) / 2)
  const progressPath = _arc({
    innerRadius,
    outerRadius,
    startAngle,
    endAngle: (percent / 100) * 2 * Math.PI,
  })
  const missingPath = _arc({
    innerRadius,
    outerRadius,
    startAngle,
    endAngle: 2 * Math.PI,
  })

  return [progressPath, missingPath]
}

export default function ProgressChart({
  innerRadius,
  outerRadius,
  startAngle = 0,
  percent,
  chartColor: chartColorProp = undefined,
  textColor: textColorProp = undefined,
  noContent = false,
}) {
  const [progressPath, missingPath] = useMemo(() => {
    return pathsGenerator(percent, innerRadius, outerRadius, startAngle)
  }, [innerRadius, outerRadius, percent, startAngle])

  const size = (outerRadius + 1) * 2
  const colorScale = chroma.scale(["green", "yellow", "red"])

  const pathColor = chartColorProp ?? colorScale(percent / 100).hex("rgb")
  const textColor = textColorProp ?? colorScale(percent / 100).hex("rgb")

  return (
    <svg
      height={size}
      width={size}
      viewBox={`${-size / 2} ${-size / 2} ${size} ${size}`}
    >
      <path d={missingPath} fill="lightgray" />
      <path d={progressPath} fill={pathColor} />
      {!noContent && (
        <text
          fill={textColor}
          textAnchor="middle"
          alignmentBaseline="middle"
          fontSize={innerRadius / 2 + 1}
          fontWeight="bold"
        >
          {parseFloat(percent).toFixed(0) + "%"}
        </text>
      )}
    </svg>
  )
}

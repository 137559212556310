import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import Icon from "../../../components/Icon"
import ImagePlaceholder from "../../../components/ImagePlaceholder"
import ImagePreview from "../../../components/ImagePreview"
import S from "./Members.module.scss"

function InvitationRow({ invitation, estimateRoleOptions, trackerRoleOptions, onDelete, onChange }) {
  const { t } = useTranslation(["action"])

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <ImagePreview
            shape="circle"
            size="sm"
            disableHover
            className="mr-4"
            placeholder={ImagePlaceholder("avatar", invitation.id)}
          />
          <div>{invitation.email}</div>
        </div>
      </td>
      <td>-</td>
      <td>-</td>
      <td className="text-center">
        <div className={classNames(S["status"], S["status-invitation"])} />
      </td>
      <td className="text-center">
        <span onClick={() => onDelete(invitation.id)}>
          <Icon name="close" title={t("actions:delete")} style={{ width: 18, height: 18, cursor: "pointer" }} />
        </span>
      </td>
    </tr>
  )
}

export default React.memo(InvitationRow)

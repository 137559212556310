import { Field, Formik } from "formik"
import { mapValues } from "lodash"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import Button from "./Button"
import FieldInput from "./fields/FieldInput"
import FieldTextarea from "./fields/FieldTextarea"
import WpModal from "./WpModal"
import { convertErrorToForm } from "../utils"

const INITIAL_VALUES = {
  detection_date: "",
  description: "",
}

export function CreateReportModal({ isOpen, toggle, onSave, onClosed, report }) {
  const { t } = useTranslation(["translation", "field", "action"])

  const schema = yup.object().shape({
    detection_date: yup
      .date()
      .typeError(
        t("field:errors.date", {
          field: t("field:complianceIssueReport.detection_date"),
        })
      )
      .required(t("field:errors.mandatory", { field: t("field:complianceIssueReport.detection_date") })),
    description: yup
      .string()
      .required(t("field:errors.mandatory", { field: t("field:complianceIssueReport.description") })),
  })

  return (
    <WpModal onClosed={onClosed} isOpen={isOpen} toggle={toggle} title={t("action:new_compliance_issue_report")}>
      <div>
        <div className="d-flex flex-row align-items-start my-4">
          <div className="d-flex flex-column ml-2 flex-1">
            <Formik
              initialValues={{ ...INITIAL_VALUES, ...report }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                return onSave(mapValues(values, (val) => (val === undefined ? null : val))).catch((error) => {
                  actions.setErrors(convertErrorToForm(error))
                })
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <>
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Field
                      name="detection_date"
                      component={FieldInput}
                      type="date"
                      label={t("field:complianceIssueReport.detection_date")}
                      className={"mb-5"}
                    />
                    <Field
                      name="description"
                      component={FieldTextarea}
                      className={"mb-5"}
                      label={t("field:complianceIssueReport.description")}
                    />
                    <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                      <Button size="sm" color="none" onClick={toggle}>
                        {t("action:cancel")}
                      </Button>
                      <Button size="sm" color="primary" type="submit" className="ml-5" disabled={!formik.isValid}>
                        {t("action:add")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </WpModal>
  )
}

import { rj, useRunRj } from "react-rocketjump"
import api from "../api"

const CommercialEffortTrackerRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => () => api.auth(wpAuth).get("/api/crm/effort-tracker/"),
})

export function useEffortTrackerInfo() {
  return useRunRj(CommercialEffortTrackerRj, [], false)
}

import { Field, Formik } from "formik"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Button from "../../../components/Button"
import FieldInput from "../../../components/fields/FieldInput"
import FieldTextarea from "../../../components/fields/FieldTextarea"
import FieldYesNo from "../../../components/fields/FieldYesNo"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader"
import OrgUserSelector from "../../../components/OrgUserSelector"
import { TrackerLandmarks } from "../../../components/TrackerLandmarks"
import { useComplianceIssueReport } from "../../../hooks/complianceIssueReport"
import ProjectBottomBarContent from "../ProjectBottomBarContent"
import ProjectTabBarContent from "../ProjectTabBarContent"

export function ComplianceIssueReport() {
  const { reportId } = useParams()

  const { t } = useTranslation()

  const [{ report }, { update }] = useComplianceIssueReport(reportId)
  const [enableEdits, setEditsEnabled] = useState(false)

  return (
    <Layout>
      <div className="container pt-5 px-page pb-page-bottom">
        <div className="d-flex flex-row justify-content-between align-items-center mb-8 mt-4">
          <h1 className="text-primary font-weight-semibold">{t("project_compliance_issue_report.title")}</h1>
          {!enableEdits && <Button onClick={() => setEditsEnabled(true)}>{t("action:unlock")}</Button>}
        </div>
        {!report && <Loader />}
        {report && (
          <>
            <Formik
              initialValues={report}
              onSubmit={(values, actions) => {
                return update.onSuccess(() => {
                  toast.success(t("project_compliance_issue_report.save_success"))
                }).onFailure(() => {
                  toast.error(t("project_compliance_issue_report.save_error"))
                }).asPromise(report.id, values)
              }}
            >
              {(formik) => {
                return (
                  <form autoComplete="off" onSubmit={formik.handleSubmit}>
                    <div className="d-flex flex-column">
                      {/* <h2 className="text-center text-uppercase mb-5 font-weight-semibold">
                        {t("project_info.title")}
                      </h2> */}
                      <div className="d-flex flex-row justify-content-center">
                        <div className="flex-1 pr-5">
                          <Field name="code" label={t("field:complianceIssueReport.id")} component={FieldInput} readOnly />
                        </div>
                        <div className="flex-1 pl-5">
                          <Field
                            name="detection_date"
                            label={t("field:complianceIssueReport.detection_date")}
                            component={FieldInput}
                            type="date"
                            readOnly={!enableEdits}
                          />
                        </div>
                      </div>

                      <div className="mt-6">
                        <div>
                          <label className="font-weight-semibold text-uppercase h3 mb-3">
                            {t("project_compliance_issue_report.detection_info")}
                          </label>
                        </div>

                        <div className="d-flex flex-row align-items-center mb-0">
                          <Field name="is_detected_by_customer" component={FieldYesNo} type="date" readOnly={!enableEdits} />
                          <span className="ml-2 d-block mt-1">{t("field:complianceIssueReport.is_detected_by_customer")}</span>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-0">
                          <Field name="is_detected_by_operator" component={FieldYesNo} type="date" readOnly={!enableEdits} />
                          <span className="ml-2 d-block mt-1">{t("field:complianceIssueReport.is_detected_by_operator")}</span>
                          {formik.values.is_detected_by_operator && (
                            <div className="ml-3 flex-0" style={{ width: 250 }}>
                              <Field
                                name="detected_by_operator"
                                component={OrgUserSelector.Field}
                                itemWidth={350}
                                style={{ width: 200 }}
                                readOnly={!enableEdits}
                                clearable
                                placeholder={t("field:complianceIssueReport.placeholder.detected_by_operator")}
                              />
                            </div>
                          )}
                        </div>
                        <div className="d-flex flex-row align-items-center mb-0">
                          <Field name="is_detected_by_audit" component={FieldYesNo} type="date" readOnly={!enableEdits} />
                          <span className="ml-2 d-block mt-1">{t("field:complianceIssueReport.is_detected_by_audit")}</span>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-0">
                          <Field name="is_detected_by_other" component={FieldYesNo} type="date" readOnly={!enableEdits} />
                          <span className="ml-2 d-block mt-1">{t("field:complianceIssueReport.is_detected_by_other")}</span>
                          {formik.values.is_detected_by_other && (
                            <div className="ml-3 flex-0" style={{ width: 250 }}>
                              <Field
                                name="detected_by_other"
                                component={FieldInput}
                                className="flex-1"
                                readOnly={!enableEdits}
                                placeholder={t("field:complianceIssueReport.placeholder.detected_by_other")}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1">
                          <Field
                            name="description"
                            label={t("field:complianceIssueReport.description")}
                            component={FieldTextarea}
                            readOnly={!enableEdits}
                            rows={4}
                          />
                        </div>
                      </div>

                      <h2 className="text-center text-uppercase mb-5 font-weight-semibold mt-8">
                        {t("project_compliance_issue_report.section_analysis")}
                      </h2>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1">
                          <Field
                            name="detailed_analyses"
                            label={t("field:complianceIssueReport.detailed_analyses")}
                            component={FieldTextarea}
                            readOnly={!enableEdits}
                            rows={4}
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1">
                          <Field
                            name="presumed_causes"
                            label={t("field:complianceIssueReport.presumed_causes")}
                            component={FieldTextarea}
                            readOnly={!enableEdits}
                            rows={4}
                          />
                        </div>
                      </div>

                      <h2 className="text-center text-uppercase mb-5 font-weight-semibold mt-8">
                        {t("project_compliance_issue_report.section_resolution")}
                      </h2>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1">
                          <Field
                            name="resolution_steps"
                            label={t("field:complianceIssueReport.resolution_steps")}
                            component={FieldTextarea}
                            readOnly={!enableEdits}
                            rows={4}
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1 pr-5 d-flex flex-column align-items-stretch">
                          <Field
                            name="resolution_agent"
                            label={t("field:complianceIssueReport.resolution_agent")}
                            component={OrgUserSelector.Field}
                            readOnly={!enableEdits}
                            className="py-3 px-4"
                          />
                        </div>
                        <div className="flex-1 pl-5">
                          <label className="font-weight-semibold text-uppercase h3">
                            {t("field:complianceIssueReport.corrective_action_required")}
                          </label>
                          <div className="d-flex flex-row">
                            <Field
                              name="corrective_action_required"
                              component={FieldYesNo}
                              readOnly={!enableEdits}
                              style={{ alignItems: "flex-start" }}
                              labelRight={t("yes")}
                              dualMode
                            />
                            <span className="mx-8" />
                            <Field
                              name="corrective_action_required"
                              component={FieldYesNo}
                              readOnly={!enableEdits}
                              style={{ alignItems: "flex-start" }}
                              labelRight={t("no")}
                              positiveValue={false}
                              dualMode
                            />
                          </div>
                        </div>
                      </div>

                      {formik.values.corrective_action_required && (
                        <div className="d-flex flex-row justify-content-center mt-6">
                          <div className="flex-1">
                            <Field
                              name="corrective_action"
                              label={t("field:complianceIssueReport.corrective_action")}
                              placeholder={t("field:complianceIssueReport.placeholder.corrective_action")}
                              component={FieldTextarea}
                              readOnly={!enableEdits}
                              rows={4}
                            />
                          </div>
                        </div>
                      )}

                      <h2 className="text-center text-uppercase mb-5 font-weight-semibold mt-8">
                        {t("project_compliance_issue_report.section_check")}
                      </h2>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1">
                          <Field
                            name="resolution_check_notes"
                            label={t("field:complianceIssueReport.resolution_check_notes")}
                            component={FieldTextarea}
                            readOnly={!enableEdits}
                            rows={4}
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1 pr-5">
                          <label className="font-weight-semibold text-uppercase h3">
                            {t("field:complianceIssueReport.is_resolution_effective")}
                          </label>
                          <div className="d-flex flex-row">
                            <Field
                              name="is_resolution_effective"
                              component={FieldYesNo}
                              readOnly={!enableEdits}
                              style={{ alignItems: "flex-start" }}
                              labelRight={t("yes")}
                              dualMode
                            />
                            <span className="mx-8" />
                            <Field
                              name="is_resolution_effective"
                              component={FieldYesNo}
                              readOnly={!enableEdits}
                              style={{ alignItems: "flex-start" }}
                              labelRight={t("no")}
                              positiveValue={false}
                              dualMode
                            />
                          </div>
                        </div>

                        <div className="flex-1 px-5 d-flex flex-column align-items-stretch">
                          <Field
                            name="resolution_check_agent"
                            label={t("field:complianceIssueReport.resolution_check_agent")}
                            component={OrgUserSelector.Field}
                            readOnly={!enableEdits}
                            className="py-3 px-4"
                          />
                        </div>

                        <div className="flex-1 pl-5 d-flex flex-column align-items-stretch">
                          <Field
                            name="resolution_check_date"
                            label={t("field:complianceIssueReport.resolution_check_date")}
                            component={FieldInput}
                            type="date"
                            readOnly={!enableEdits}
                          />
                        </div>
                      </div>
                    </div>

                    {enableEdits && (
                      <div className="text-center mt-6">
                        <Button
                          onClick={() => {
                            formik.resetForm()
                            setEditsEnabled(false)
                          }}
                          className="mx-4"
                        >
                          {t("action:discard_and_lock")}
                        </Button>
                        <Button type="submit" className="mx-4">
                          {t("action:save")}
                        </Button>
                        <Button
                          onClick={() => {
                            formik.submitForm().then(() => {
                              setEditsEnabled(false)
                            })
                          }}
                          className="mx-4"
                        >
                          {t("action:save_and_lock")}
                        </Button>
                      </div>
                    )}
                  </form>
                )
              }}
            </Formik>
          </>
        )}
      </div>
      <Layout.TabBar>
        <ProjectTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <ProjectBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import { Field, Formik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import currencies from "../../data/currency.json"
import { convertErrorToForm } from "../../utils"
import Button from "../Button"
import FieldDropdown from "../fields/FieldDropdown"
import FieldInput from "../fields/FieldInput"
import WpModalQuota from "../WpModalQuota"
import FieldCustomerSelector from "../fields/FieldCustomerSelector"

const INITIAL_VALUES = {
  title: "",
  customer: null,
  currency: "EUR",
}

const currencyOpts = currencies.map((cur) => ({
  label: cur.name + ` (${cur.symbol})`,
  value: cur.code,
}))

export default function CreateProjectModal({ isOpen, toggle, onSave, onClosed }) {
  const { t } = useTranslation(["translation", "field", "action"])

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(t("field:errors.mandatory", { field: t("field:project.name") }))
      .max(512, t("field:errors.max", { max_value: 512 })),
    currency: yup
      .string()
      .required(t("field:errors.mandatory", { field: t("field:project.currency") }))
      .max(100, t("field:errors.max", { max_value: 100 })),
  })

  return (
    <WpModalQuota
      checkQuota={"projects"}
      onClosed={onClosed}
      isOpen={isOpen}
      toggle={toggle}
      title={t("projects_list.create_project")}
    >
      <div>
        <p>{t("projects_list.create_project_message")}</p>
        <div className="d-flex flex-row align-items-start my-4">
          <div className="d-flex flex-column ml-2 flex-1">
            <Formik
              initialValues={INITIAL_VALUES}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                return onSave(values).catch((error) => {
                  actions.setErrors(convertErrorToForm(error))
                })
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <>
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Field
                      name="name"
                      component={FieldInput}
                      placeholder={t("field:project.placeholder.name")}
                      label={t("field:project.name")}
                      className={"mb-5"}
                    />
                    <Field
                      name="customer"
                      component={FieldCustomerSelector}
                      className={"mb-5"}
                      placeholder={t("field:project.placeholder.customer")}
                      label={t("field:estimate.customer")}
                      containerClassName={"mb-5"}
                      controlClassName="px-4 py-3 border-radius-xl"
                    />
                    <Field
                      name="currency"
                      component={FieldDropdown}
                      placeholder={t("field:project.placeholder.currency")}
                      label={t("field:project.currency")}
                      options={currencyOpts}
                      capitalize={false}
                      dropdownStyle={{
                        maxHeight: 160,
                        overflowY: "auto",
                      }}
                    />
                    <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                      <Button size="sm" color="none" onClick={toggle}>
                        {t("action:cancel")}
                      </Button>
                      <Button size="sm" color="primary" type="submit" className="ml-5" disabled={!formik.isValid}>
                        {t("action:add")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </WpModalQuota>
  )
}

import React from "react"
import { find, keyBy } from "lodash"
import { useTranslation } from "react-i18next"
import Separator from "../../../../../components/Separator"
import { flattenCutTree } from "../../../EstimateTasksTree/TaskTable/utils"
import classNames from "classnames"
import S from "../../DocFrag.module.scss"
import RichTextEditor from "../../../../../components/RichTextEditor/RichTextEditor"
import RichTextStyle from "./style.module.scss"
import ScenarioSymbol from "../../../../../components/ScenarioSymbol"
import { pagerLog } from "../../Pager"
import { sliceExtent } from "../../../treeOperations"
import useCurrencyFormatter from "../../../../../hooks/useCurrencyFormatter"
import { useLocalizer } from "../../../../../hooks/useLocalizer"

const FragComponent = React.memo(
  ({
    frag,
    estimate,
    sliceRowStart: sliceBlockStart,
    sliceRowEnd: sliceBlockEnd,
    inPage,
    chunkIsFirst,
  }) => {
    if (inPage) {
      pagerLog("[PAGER] Task list rendered")
    }
    const cutSelectTree = sliceExtent(
      estimate.task_tree,
      frag.data.visibleTasks
    )

    const resourcesById = keyBy(estimate.resources, "id")
    const { t, i18n } = useTranslation(["translation"])
    const l = useLocalizer(i18n.language)
    const currencyFmt = useCurrencyFormatter()
    const flattenedCutTree = flattenCutTree(cutSelectTree, frag.data.maxDepth)

    const hasTasks = estimate.task_tree.length > 0

    return (
      <div className={classNames(RichTextStyle["task-list-frag"])}>
        <div className="bg-white">
          {frag?.data?.showTitle && chunkIsFirst && (
            <h1 className="__pager-title m-0 pb-3">{frag?.title}</h1>
          )}
          {!hasTasks && (
            <div className="__pager-disclaimer disclaimer">
              {t("documents.no_visible_tasks")}
            </div>
          )}
          {flattenedCutTree.map((n, i) => {
            const nodeDepth = n.path.split(".").length
            const nodeDepthForHeader = nodeDepth + 1
            const isFirst = n.path.split(".")[0] === "1"
            const hasPrice = find(
              n.priceMap,
              (price) => Math.abs(parseFloat(price)) > 0.001
            )
            if (
              (sliceBlockStart !== undefined && i < sliceBlockStart) ||
              (sliceBlockEnd !== undefined && i >= sliceBlockEnd)
            ) {
              return null
            }
            return (
              <div
                key={n.path}
                className={classNames("__pager-node", {
                  "pt-4": nodeDepth === 1 && !isFirst,
                })}
              >
                <div className="d-flex flex-row justify-content-between align-items-start mb-3 page-break-inside-avoid page-break-after-avoid">
                  <div
                    className={classNames(
                      S["header-" + nodeDepthForHeader],
                      "d-flex flex-row"
                    )}
                  >
                    <div className="d-flex flex-row align-items-start justify-content-center">
                      <div>
                        {n.path} {n.title}
                      </div>
                      {frag.data.isScenarioVisible && (
                        <div className="d-flex ml-2">
                          {estimate.scenarios.map((scenario) => {
                            if (
                              !n.inferredScenarios.includes(scenario.id) ||
                              !(
                                frag.selected_scenarios === null ||
                                frag.selected_scenarios.includes(scenario.id)
                              )
                            ) {
                              return null
                            }
                            return (
                              <div className="mr-2">
                                <ScenarioSymbol
                                  scenario={scenario}
                                  isInferred={n.inferredOnlyScenarios.includes(
                                    scenario.id
                                  )}
                                />
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  {frag.data.isPriceVisible && (n.isLeaf || hasPrice) && (
                    <div className="font-weight-bold">
                      <span className="d-inline-flex flex-column align-items-end">
                        {/* Note: the selected scenario, if any, is one */}
                        {((frag.data.isScenarioVisible &&
                          Array.isArray(frag.selected_scenarios) &&
                          frag.selected_scenarios.length > 1) ||
                          frag.selected_scenarios === null ||
                          frag.selected_scenarios
                            .map((s) => n.priceMap[s])
                            .filter((p) => p !== undefined).length === 0) &&
                          n.priceMap[""] !== undefined && (
                            <span>{currencyFmt.format(n.priceMap[""])}</span>
                          )}
                        {estimate.scenarios.map((scenario) => {
                          if (
                            scenario.id in n.priceMap &&
                            (frag.selected_scenarios === null ||
                              frag.selected_scenarios.includes(scenario.id))
                          ) {
                            return (
                              <span key={scenario.id}>
                                {(frag.data.isScenarioVisible ||
                                  frag.selected_scenarios !== []) && (
                                  <small>{scenario.code + ": "}</small>
                                )}
                                {currencyFmt.format(
                                  n.priceMap[scenario.id] +
                                    (n.priceMap[""] ?? 0)
                                )}
                              </span>
                            )
                          } else {
                            return null
                          }
                        })}
                      </span>
                    </div>
                  )}
                </div>
                {frag.data.isDescriptionVisible &&
                  n.description_rich !== null && (
                    <RichTextEditor
                      initialContent={n.description_rich}
                      placeholder=""
                      templatingContext={{ estimate }}
                      readOnly
                      editorClassName={RichTextStyle["rich-text-block"]}
                      wrapperClassName={RichTextStyle["rich-text-wrapper"]}
                    />
                  )}
                {frag.data.isResourcesVisible && (
                  <div className="d-flex flex-row align-items-start justify-content-start">
                    {n.resources.length > 0 && (
                      <div className="flex-1">
                        <span className="font-weight-semibold">
                          {t("documents.frag.task_list.used_resources")}
                        </span>
                        <ul>
                          {n.resources.map((r) => (
                            <li key={r.resource_id}>
                              {resourcesById[r.resource_id][l`name`] || resourcesById[r.resource_id].name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {n.resources.length > 0 && n.fixed_costs.length > 0 && (
                      <Separator vertical className="my-0 align-self-stretch" />
                    )}
                    {n.fixed_costs.length > 0 && (
                      <div className="flex-1">
                        <span className="font-weight-semibold">
                          {t("documents.frag.task_list.fixed_costs")}
                        </span>
                        <ul>
                          {n.fixed_costs.map((c) => (
                            <li key={c.id}>{c.description}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => {
    const checkProps = [
      "frag",
      "estimate",
      "sliceRowStart",
      "sliceRowEnd",
      "chunkIsFirst",
    ]
    for (let i = 0; i < checkProps.length; i++) {
      const prop = checkProps[i]
      if (prevProps[prop] !== nextProps[prop]) {
        return false
      }
    }
    return true
  }
)

export default FragComponent

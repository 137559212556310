import classNames from "classnames"
import dayjs from "dayjs"
import { get, groupBy, orderBy } from "lodash"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import S from "../style.module.scss"
import { makeCalendarMatrix, makeDate } from "../utils"

export function Calendar({ month, year, allocs, onDateSelect, aggregateAllocations = false }) {
  const { i18n } = useTranslation()
  const calendarMatrix = useMemo(() => makeCalendarMatrix(year, month), [month, year])

  return (
    <div className={classNames(S["calendar-row"], "bg-primary-10 rounded p-4 h-100 d-flex flex-column")}>
      {calendarMatrix.map((row, i) => (
        <div className="d-flex flex-row flex-1" key={i}>
          {row.map((cell, j) => {
            const date = dayjs(makeDate(year, month, cell)).locale(i18n.language)
            // eslint-disable-next-line no-unused-vars
            const { alerts: dateAlerts, work_day: workDay, allocations } = get(allocs, date.format("YYYY-MM-DD"), {
              allocations: [],
              alerts: [],
              work_day: true,
            })
            const isFilled = allocations.length > 0
            const hasAlerts = dateAlerts.length > 0
            const groupedAllocations = groupBy(allocations, "kind")
            const aggregatedAllocations = Object.values(groupedAllocations).map((allocs) =>
              allocs.reduce(
                (acc, alloc) => {
                  return { ...alloc, hours: acc.hours + alloc.hours }
                },
                { hours: 0 }
              )
            )
            const sortedAllocations = orderBy(
              aggregateAllocations ? aggregatedAllocations : allocations,
              ["kind_group", "kind_order"],
              ["asc", "asc"]
            )
            return (
              <div
                key={j}
                className={S["calendar-cell"]}
                onClick={() => {
                  onDateSelect(date)
                }}
              >
                {cell > 0 && (
                  <div>
                    <div
                      className={classNames("d-flex flex-row justify-content-center align-items-center mb-3", {
                        "text-primary": isFilled && !hasAlerts,
                        "text-danger": hasAlerts,
                      })}
                    >
                      <div className={classNames("mr-3", S["calendar-cell-day"])}>{cell.toString().padStart(2, "0")}</div>
                      <div className="text-uppercase">{date.format("ddd")}</div>
                    </div>
                    <div
                      className="d-flex flex-row flex-wrap justify-content-start align-items-center"
                      style={{ rowGap: 2, columnGap: 2 }}
                    >
                      {sortedAllocations.map((alloc) => {
                        return (
                          <div
                            className={S["calendar-badge"]}
                            style={{
                              width: Math.min(1, alloc.hours / 8) * 100 + "%",
                              backgroundColor: alloc.kind_color,
                              color: alloc.kind_text_color,
                              minWidth: 20,
                            }}
                            title={`${alloc.kind_verbose} ${alloc.hours}h`}
                            key={alloc.id}
                          >
                            {alloc.hours}h
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

import React, { useMemo, useState } from "react"
import keyBy from "lodash/keyBy"
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import classNames from "classnames"
import S from "./Dropdown.module.scss"
import Icon from "../Icon"
import Separator from "../Separator"
import { useTranslation } from "react-i18next"
import { identity } from "lodash"
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md"

function Dropdown(
  {
    value,
    options,
    onChange,
    className = "px-3 py-3",
    style,
    capitalize = true,
    placeholder = null,
    itemWidth,
    right,
    positionFixed,
    toggleTag,
    onReset,
    disabled,
    dropdownStyle = {},
    invalid,
    ...props
  },
  ref
) {
  const [dropdownOpen, setOpen] = useState(false)
  const toggle = () => setOpen(!dropdownOpen)
  const optionsByValue = useMemo(() => keyBy(options, "value"), [options])

  const isMultiple = Array.isArray(value)

  let showingPlaceholder = false
  let selectedLabel = null

  if (!isMultiple) {
    if (value !== undefined && value !== null) {
      selectedLabel = optionsByValue[value]?.label ?? null
    }
    if (selectedLabel === null) {
      selectedLabel = placeholder
      showingPlaceholder = true
    }
  } else {
    selectedLabel = value
      .map((val) => optionsByValue[val]?.label ?? null)
      .filter(identity)
      .join(", ")
    if (selectedLabel === "") {
      showingPlaceholder = true
      selectedLabel = null
    }
  }
  const { t } = useTranslation(["action"])

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} disabled={disabled} {...props}>
      <DropdownToggle
        style={style}
        caret={false}
        className={classNames(className, "d-flex flex-row align-items-center justify-content-between", S["dropdown"], {
          "text-capitalize": capitalize,
          [S["disabled"]]: disabled,
          [S["invalid"]]: invalid,
        })}
        tag={toggleTag}
      >
        <div
          ref={ref}
          className={classNames("flex-1 d-block text-left", S["wp-nowrap"], {
            [S["placeholder"]]: showingPlaceholder,
          })}
        >
          {selectedLabel}
        </div>
        <Icon name="arrow-down" className="text-dark" />
        {typeof onReset === "function" && (
          <>
            <Separator vertical style={{ height: "1.25em" }} className="my-0 mx-1" />
            <span
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                onReset()
              }}
            >
              <Icon name="times" width="1.25em" height="1.25em" className="text-dark" title={t("action:reset_default")} />
            </span>
          </>
        )}
      </DropdownToggle>
      <DropdownMenu
        style={{
          ...dropdownStyle,
          width: itemWidth,
          minWidth: itemWidth !== undefined ? itemWidth + 1 : undefined,
          maxWidth: "100%",
        }}
        right={right}
        positionFixed={positionFixed}
      >
        {options.map((r) => {
          const isActive = !isMultiple ? value === r.value : value.includes(r.value)
          return (
            <DropdownItem
              active={isActive}
              disabled={!!r.disabled}
              className={classNames(
                {
                  "text-capitalize": capitalize,
                },
                S["dropdown-item"],
                S["wp-nowrap"]
              )}
              key={r.value}
              onClick={() => {
                if (!isMultiple) {
                  onChange(r.value)
                } else {
                  if (isActive) {
                    onChange(value.filter((item) => item !== r.value))
                  } else {
                    onChange(value.concat([r.value]))
                  }
                }
              }}
              style={{ width: itemWidth }}
              toggle={!isMultiple}
            >
              {isMultiple && !isActive && <MdOutlineCheckBoxOutlineBlank className="mr-2" />}
              {isMultiple && isActive && <MdOutlineCheckBox className="mr-2" />}
              {r.label}
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    </ButtonDropdown>
  )
}

export default React.forwardRef(Dropdown)

import React from "react"
import { adaptForScenarios } from "../../../treeOperations"
import { flatten, groupBy, keyBy, map, max } from "lodash"
import {
  aggregateResources,
  flattenCloneTree,
} from "../../../EstimateTasksTree/TaskTable/utils"
import RichTextEditor from "../../../../../components/RichTextEditor/RichTextEditor"
import ScenarioResourceViz from "../../../EstimateVisualization/EstimateScenarioViz/ScenarioResourceViz"
import { sum } from "lodash"
import { pagerLog } from "../../Pager"

const mapResources = (estimate) => {
  const flattenedTree = flattenCloneTree(estimate.task_tree)

  const flattenedResources = flatten(
    flattenedTree.map((node) => node.resources)
  )

  const aggregatedResources = aggregateResources(flattenedResources)

  const groupedByIdResources = groupBy(aggregatedResources, "resource_id")

  const resourcesById = keyBy(estimate.resources, "id")

  return map(groupedByIdResources, (resource) => {
    return {
      resource_id: resource[0].resource_id,
      name: resourcesById[resource[0].resource_id].name,
      total_price: sum(
        resource
          .map((r) => parseFloat(r.unitary_price) * parseFloat(r.size))
          .filter((n) => !isNaN(n))
      ),
      resource_type: resourcesById[resource[0].resource_id].resource_type,
    }
  })
}

const calcTotalFixedCosts = (estimate) => {
  const flattenedTree = flattenCloneTree(estimate.task_tree)

  const flattenedCosts = flatten(flattenedTree.map((node) => node.fixed_costs))

  return sum(flattenedCosts.map((cost) => parseFloat(cost.price)))
}

const FragComponent = React.memo(
  ({
    frag,
    fullEstimate,
    sliceRowStart,
    sliceRowEnd,
    inPage,
    width,
    chunkIsFirst,
  }) => {
    if (inPage) {
      pagerLog("[PAGER] Scenario resource viz rendered")
    }

    const adaptedScenarios = [
      {
        estimate: adaptForScenarios(fullEstimate, []),
        scenario: null,
        mappedResources: mapResources(adaptForScenarios(fullEstimate, [])),
        totalFixedCosts: calcTotalFixedCosts(
          adaptForScenarios(fullEstimate, [])
        ),
      },
      ...fullEstimate.scenarios.map((scenario) => {
        const estimate = adaptForScenarios(fullEstimate, [scenario.id])
        return {
          estimate: estimate,
          scenario: scenario,
          mappedResources: mapResources(estimate),
          totalFixedCosts: calcTotalFixedCosts(estimate),
        }
      }),
    ]

    const maxResourcePrice = max(
      adaptedScenarios.map((adapted) =>
        parseFloat(
          sum(
            adapted.mappedResources.map((resource) =>
              parseFloat(resource.total_price)
            )
          ) + parseFloat(adapted.totalFixedCosts)
        )
      )
    )

    // First node is legend, so don't account for it when slicing node list
    sliceRowStart = sliceRowStart && sliceRowStart - 1
    sliceRowEnd = sliceRowEnd && sliceRowEnd - 1

    return (
      <div className="">
        <>
          {frag?.data?.showTitle && chunkIsFirst && (
            <h1 className="__pager-title m-0 pb-3">{frag?.title}</h1>
          )}
          {frag?.data?.showLegend && chunkIsFirst && (
            <div className="__pager-node m-0 pb-3">
              <ScenarioResourceViz.Legend
                estimate={fullEstimate}
                columns={2}
              />
            </div>
          )}
          {adaptedScenarios
            .filter(
              (elem) =>
                (frag?.data?.isBaseVisible && elem.scenario === null) ||
                elem.scenario !== null
            )
            .map((adapted, i) => {
              if (
                (sliceRowStart !== undefined && i < sliceRowStart) ||
                (sliceRowEnd !== undefined && i >= sliceRowEnd)
              ) {
                return null
              }
              return (
                <div className="__pager-node">
                  <ScenarioResourceViz
                    estimate={adapted.estimate}
                    scenario={adapted.scenario}
                    width={width}
                    maxPrice={maxResourcePrice}
                    isPriceVisible={frag?.data?.isPriceVisible}
                    mappedResources={adapted.mappedResources}
                    totalCosts={adapted.totalFixedCosts}
                  />
                </div>
              )
            })}
          {frag.data.enableCaption && (
            <div
              className="__pager-caption"
              style={{ flex: "1 1 0%", minWidth: 0, paddingLeft: 4 }}
            >
              <RichTextEditor
                content={frag?.data?.caption}
                readOnly={true}
                placeholder={""}
                enableImages={false}
                minHeight0
              />
            </div>
          )}
        </>
      </div>
    )
  },
  (prevProps, nextProps) => {
    const checkProps = [
      "frag",
      "fullEstimate",
      "width",
      "sliceRowStart",
      "sliceRowEnd",
      "chunkIsFirst"
    ]
    for (let i = 0; i < checkProps.length; i++) {
      const prop = checkProps[i]
      if (prevProps[prop] !== nextProps[prop]) {
        return false
      }
    }
    return true
  }
)

export default FragComponent

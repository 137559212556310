import dayjs from "dayjs"
import { get } from "lodash"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { RichTextEditorContext } from "./RichTextEditor"
import S from "./RichTextEditor.module.scss"
import "dayjs/locale/it"
import "dayjs/locale/en"
import localizedFormat from "dayjs/plugin/localizedFormat"

const TEMPLATING_REGEX = /\{\{ ?[^}]+ ?\}\}/g

dayjs.extend(localizedFormat)

function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText()
  let matchArr, start
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index
    callback(start, start + matchArr[0].length)
  }
}

function findLinkEntities(contentBlock, callback, contentState) {
  findWithRegex(TEMPLATING_REGEX, contentBlock, callback)
}

function filterDate(value, lang, format = "LL") {
  const tryDate = dayjs(value)
  if (tryDate.isValid()) {
    return tryDate.locale(lang).format(format)
  }
  return value
}

const FILTERS = {
  date: filterDate,
}

function TemplatingExpression({ children, entityKey, contentState }) {
  const child = children[0]
  const expression = child?.props?.text.replace(/[{}]/g, "")
  const { readOnly, templatingContext } = useContext(RichTextEditorContext)
  const { i18n } = useTranslation()

  let [expr, ...filters] = expression.split("|")
  expr = expr.trim()

  if (readOnly) {
    let val = get(templatingContext, expr, `{{ ${expr} }}`)?.toString() ?? ""
    for (const filter of filters) {
      const [name, argStr] = filter.split(":")
      const args = argStr.split(",")
      if (name in FILTERS) {
        val = FILTERS[name](val, i18n.language, ...args)
      }
    }
    const newChild = React.cloneElement(children[0], {
      text: val ?? "",
    })
    const newChildren = [newChild, ...children.slice(1)]
    return <span>{newChildren}</span>
  }
  return <span className={S["templating-decorator-wrapper"]}>{children}</span>
}

const TemplatingDecorator = {
  strategy: findLinkEntities,
  component: TemplatingExpression,
}

export default TemplatingDecorator

import { range } from "lodash"

export default function DendrogramSplitter(
  node,
  props,
  firstChunkSpace,
  sheetHeight
) {
  const blocks = node.querySelectorAll(".__pager-node")
  const captionNode = node.querySelector(".__pager-treemap-caption")
  const captionHeight = captionNode?.offsetHeight ?? 0
  const title = node.querySelector(".__pager-title")
  const legend = node.querySelector(".__pager-treemap-legend")
  const titleHeight = (title?.offsetHeight ?? 0) + (legend?.offsetHeight ?? 0)
  const firstBlockHeight = blocks[0].offsetHeight
  
  const disclaimerNode = node.querySelector(".__pager-disclaimer")
  if (disclaimerNode) {
    return [
      null,
      {
        props: {},
        height: captionNode?.offsetHeight + titleHeight + disclaimerNode.offsetHeight,
      },
    ]
  }

  let currentChunkHeight = titleHeight + captionHeight
  let currentChunkStart = undefined
  let currentChunkEnd = undefined
  const chunks = []
  let maxChunkHeight = firstChunkSpace

  // If there is not enough space on first page for header + footer
  // just push a null chunk to tell the paginator to break page before
  // rendering this component
  if (currentChunkHeight + firstBlockHeight > maxChunkHeight) {
    chunks.push(null)
    maxChunkHeight = sheetHeight
  }

  let visibleTasks = props.frag.data.visibleTasks
  if (visibleTasks === null) {
    visibleTasks = range(1, blocks.length + 1)
  } else {
    visibleTasks = range(visibleTasks[0], visibleTasks[1] + 1)
  }

  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i]
    const height = block.offsetHeight
    if (currentChunkHeight + height > maxChunkHeight) {
      // Another page has been filled, push it in the chunks array
      // Remember: all chunks must measure their height before returning
      chunks.push({
        props: {
          currentChunkStart,
          currentChunkEnd: currentChunkEnd + 1,
        },
        height: currentChunkHeight,
      })
      currentChunkStart = i
      currentChunkEnd = i
      currentChunkHeight = height + captionHeight
      maxChunkHeight = sheetHeight
    } else {
      currentChunkEnd = i
      currentChunkHeight += height
    }
  }

  chunks.push({
    props: {
      currentChunkStart,
      currentChunkEnd: undefined,
    },
    height: currentChunkHeight,
  })

  return chunks
}

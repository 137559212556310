import { Field, Formik } from "formik"
import { isEqual } from "lodash"
import { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import { CRMLandmarks } from "../../../components/CRMLandmarks"
import FieldInput from "../../../components/fields/FieldInput"
import FieldTextarea from "../../../components/fields/FieldTextarea"
import FormAutoSave from "../../../components/FormAutoSave"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import { CustomerContext } from "../../../context"
import {
  notifyError,
  onResetHackReinitializeToKeepDirtyUpdate,
} from "../../../utils"
import CustomerBottomBarContent from "../CustomerBottomBarContent"
import CustomerTabBarContent from "../CustomerTabBarContent"

function shouldSubmit(values, prevValues) {
  return !isEqual(values, prevValues)
}

export default function CustomerInfo() {
  const { t } = useTranslation(["translation", "tab", "action"])

  const [{ customer, saving }, { update }] = useContext(CustomerContext)

  const schema = useMemo(
    () =>
      yup.object().shape({
        name: yup
          .string()
          .required(
            t("field:errors.mandatory", { field: t("field:project.name") })
          )
          .max(256, t("field:errors.max", { max_value: 256 })),
        code: yup
          .string()
          .required(
            t("field:errors.mandatory", { field: t("field:project.code") })
          )
          .max(10, t("field:errors.max", { max_value: 10 })),
        tax_code: yup
          .string()
          .notRequired()
          .max(100, t("field:errors.max", { max_value: 100 })),
        document_header: yup.string().notRequired(),
        notes: yup.string().notRequired(),
      }),
    [t]
  )

  return (
    <Layout>
      <div className="container pt-5 px-half-page pb-page-bottom">
        {!customer && <Loader />}
        {customer && (
          <>
            <Formik
              initialValues={customer}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                const { id, ...data } = values
                return update.onFailure(notifyError).asPromise(id, data)
              }}
              onReset={onResetHackReinitializeToKeepDirtyUpdate}
              enableReinitialize={true}
            >
              {(formik) => (
                <form autoComplete="off">
                  <FormAutoSave shouldSubmit={shouldSubmit} />
                  <div className="d-flex flex-column px-page">
                    <h3 className="text-right mt-8 mb-5 text-separator">
                      {saving && t("message.saving")}
                      {!saving && !formik.dirty && t("message.saved")}
                      {!saving && formik.dirty && (
                        <span className="text-danger">
                          {t("message.not_saved")}
                        </span>
                      )}
                    </h3>
                    <h2 className="text-center text-uppercase mb-5 font-weight-semibold">
                      {t("project_info.title")}
                    </h2>
                    <div className="d-flex flex-row justify-content-center">
                      <div className="flex-1">
                        <Field
                          name="name"
                          label={t("field:customer.name")}
                          placeholder={t("field:customer.placeholder.name")}
                          component={FieldInput}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center mt-6">
                      <div className="flex-1 d-flex flex-row">
                        <div className="flex-1 pr-5">
                          <Field
                            name="code"
                            label={t("field:customer.code")}
                            placeholder={t("field:customer.placeholder.code")}
                            component={FieldInput}
                          />
                        </div>
                      </div>
                      <div className="flex-1 pl-5 d-flex flex-row">
                        <div className="flex-1">
                          <Field
                            name="tax_code"
                            label={t("field:customer.tax_code")}
                            placeholder={t(
                              "field:customer.placeholder.tax_code"
                            )}
                            component={FieldInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center mt-6">
                      <div className="flex-1 pr-5">
                        <Field
                          name="address"
                          label={t("field:customer.address")}
                          placeholder={t("field:customer.placeholder.address")}
                          component={FieldInput}
                        />
                      </div>
                      <div className="pl-5 d-flex flex-row">
                        <div className="flex-1 pr-5">
                          <Field
                            name="house_number"
                            label={t("field:customer.house_number")}
                            placeholder={t(
                              "field:customer.placeholder.house_number"
                            )}
                            component={FieldInput}
                          />
                        </div>
                      </div>
                      <div className="pl-5 d-flex flex-row">
                        <div className="flex-1">
                          <Field
                            name="postal_code"
                            label={t("field:customer.postal_code")}
                            placeholder={t(
                              "field:customer.placeholder.postal_code"
                            )}
                            component={FieldInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center mt-6">
                      <div className="flex-1 pr-5">
                        <Field
                          name="city"
                          label={t("field:customer.city")}
                          placeholder={t("field:customer.placeholder.city")}
                          component={FieldInput}
                        />
                      </div>
                      <div className="pl-5 d-flex flex-row">
                        <div className="flex-1 pr-5">
                          <Field
                            name="province"
                            label={t("field:customer.province")}
                            placeholder={t(
                              "field:customer.placeholder.province"
                            )}
                            component={FieldInput}
                          />
                        </div>
                      </div>
                      <div className="pl-5 d-flex flex-row">
                        <div className="flex-1">
                          <Field
                            name="nation"
                            label={t("field:customer.nation")}
                            placeholder={t("field:customer.placeholder.nation")}
                            component={FieldInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center mt-6">
                      <div className="flex-1">
                        <Field
                          name="website"
                          label={t("field:customer.website")}
                          placeholder={t("field:customer.placeholder.website")}
                          component={FieldInput}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center mt-6">
                      <div className="flex-1 d-flex flex-row">
                        <div className="flex-1 pr-5">
                          <Field
                            name="info_email"
                            label={t("field:customer.info_email")}
                            placeholder={t(
                              "field:customer.placeholder.info_email"
                            )}
                            component={FieldInput}
                          />
                        </div>
                      </div>
                      <div className="flex-1 pl-5 d-flex flex-row">
                        <div className="flex-1">
                          <Field
                            name="phone"
                            label={t("field:customer.phone")}
                            placeholder={t("field:customer.placeholder.phone")}
                            component={FieldInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center mt-6">
                      <div className="flex-1">
                        <Field
                          name="notes"
                          label={t("field:customer.notes")}
                          placeholder={t("field:customer.placeholder.notes")}
                          component={FieldTextarea}
                          rows={5}
                        />
                      </div>
                    </div>
                    <div className="border-bottom border-dark mt-9" />
                    <p className="mt-6">
                      <i>Legacy</i>
                    </p>
                    <div className="d-flex flex-row justify-content-center">
                      <div className="flex-1">
                        <Field
                          name="document_header"
                          label={t("field:customer.document_header")}
                          placeholder={t(
                            "field:customer.placeholder.document_header"
                          )}
                          component={FieldTextarea}
                          rows={5}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </div>
      <Layout.TabBar>
        <CustomerTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <CustomerBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <CRMLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

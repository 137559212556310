import { useTranslation } from "react-i18next"
import {
  BsCalendar2Check,
  BsQuestionDiamond,
  BsTelephone,
} from "react-icons/bs"
import { useParams } from "react-router-dom"
import Icon from "../../components/Icon"
import Layout from "../../components/Layout"

export default function CustomerTabBarContent() {
  const { t } = useTranslation(["translation", "tab", "action"])
  const { id } = useParams()

  return (
    <div className="w-100 d-flex justify-content-between">
      <div className="d-flex align-items-start justify-content-start">
        <Layout.TabBar.TabBarTab
          text={t("tab:customer.detail")}
          exact
          to={`/customers/${id}`}
          svgIcon={<Icon name="info" />}
        />
        <Layout.TabBar.Divider />
        <Layout.TabBar.TabBarTab
          text={t("tab:customer.info")}
          exact
          to={`/customers/${id}/info`}
          svgIcon={<Icon name="info" />}
        />
        <Layout.TabBar.Divider />
        <Layout.TabBar.TabBarTab
          text={t("tab:customer.contact")}
          exact
          to={`/customers/${id}/contacts`}
          svgIcon={<BsTelephone style={{ width: 16 }} />}
        />
        <Layout.TabBar.Divider />
        <Layout.TabBar.TabBarTab
          text={t("tab:customer.interactions")}
          to={`/customers/${id}/interactions`}
          svgIcon={<BsCalendar2Check style={{ width: 16 }} />}
        />
        <Layout.TabBar.Divider />
        <Layout.TabBar.TabBarTab
          text={t("tab:customer.requests")}
          to={`/customers/${id}/requests`}
          svgIcon={<BsQuestionDiamond style={{ width: 18 }} />}
        />
        <Layout.TabBar.Divider />
        <Layout.TabBar.TabBarTab
          text={t("tab:customer.estimates")}
          exact
          to={`/customers/${id}/estimates`}
          svgIcon={<Icon name="estimate-list" />}
        />
        <Layout.TabBar.Divider />
        <Layout.TabBar.TabBarTab
          text={t("tab:customer.projects")}
          exact
          to={`/customers/${id}/projects`}
          svgIcon={<Icon name="reporting" />}
        />
        <Layout.TabBar.Divider />
      </div>
    </div>
  )
}

import { useCallback, useContext, useMemo } from "react"
import { makeMutationType, rj, useRunRj, FAILURE } from "react-rocketjump"
import { mergeMap, catchError } from "rxjs/operators"
import { throwError } from "rxjs"
import api from "../api"
import { CurrentEstimateStateContext } from "../context"

const EstimateDocument = rj({
  name: "EstimateDocument",
  effectCaller: rj.configured(),
  mutations: {
    patchDocument: {
      effect: (t) => (doc) => {
        if (!doc.id) {
          return api.auth(t).post(`/api/document`, doc)
        }
        return api.auth(t).patch(`/api/document/${doc.id}`, doc)
      },
      optimisticResult: (doc) => doc,
      optimisticUpdater: "updateData",
    },
    addFragment: {
      effect: (wpAuth) => (estimateId, id, fragmentPayload) => {
        if (id === null) {
          // Create ma doc!
          return api
            .auth(wpAuth)
            .post(`/api/document`, {
              estimate: estimateId,
              meta: {
                color: null,
              },
            })
            .pipe(
              mergeMap((data) => {
                return api
                  .auth(wpAuth)
                  .post(
                    `/api/document/${data.id}/add_fragment`,
                    fragmentPayload
                  )
                  .pipe(
                    // If Create fragment fails we need
                    // to update the state because now we have DOC!
                    catchError((error) => {
                      // This is the tricky part we inject the created doc
                      // into mutation error payload ...
                      error.createdDoc = data
                      return throwError(error)
                    })
                  )
              })
            )
        } else {
          return api
            .auth(wpAuth)
            .post(`/api/document/${id}/add_fragment/`, fragmentPayload)
        }
      },
      updater: "updateData",
    },
    removeFragment: {
      effect: (wpAuth) => (docId, id) =>
        api.auth(wpAuth).delete(`/api/document/${docId}/fragments/${id}`),
      optimisticResult: (docId, id) => id,
      optimisticUpdater: (state, id) => ({
        ...state,
        data: {
          ...state.data,
          fragments: state.data.fragments.filter((frag) => frag.id !== +id),
        },
      }),
      updater: "updateData",
    },
    moveFragment: {
      effect: (wpAuth) => (docId, id, moveAfterNodeId) =>
        api.auth(wpAuth).patch(`/api/document/${docId}/fragments/${id}`, {
          after: moveAfterNodeId,
        }),
      optimisticResult: (docId, id, moveAfterNodeId) => [id, moveAfterNodeId],
      optimisticUpdater: (state, [id, moveAfterNodeId]) => {
        const me = state.data.fragments.find((frag) => frag.id === id)
        let nextFragments = state.data.fragments.filter(
          (frag) => frag.id !== id
        )
        const refNodeIndex = nextFragments.findIndex(
          (frag) => frag.id === moveAfterNodeId
        )
        nextFragments.splice(refNodeIndex + 1, 0, me)
        return {
          ...state,
          data: {
            ...state.data,
            fragments: nextFragments,
          },
        }
      },
      updater: "updateData",
    },
    patchFragment: {
      effect: (wpAuth) => (frag) =>
        api.auth(wpAuth).patch(`/api/document-fragment/${frag.id}`, frag),
      optimisticResult: (frag) => frag,
      updater: (state, patchedFrag) => ({
        ...state,
        data: {
          ...state.data,
          fragments: state.data.fragments.map((frag) =>
            frag.id === patchedFrag.id ? patchedFrag : frag
          ),
        },
      }),
    },
  },
  effect: (wpAuth) => (id) =>
    api
      .auth(wpAuth)
      .mapResponse((r) => {
        // No docs 4 ma estimate fill \w fake one \w same shape
        if (r.response.length === 0) {
          return {
            estimate: parseInt(id),
            id: null,
            fragments: [],
          }
        }
        // Use first as main doc
        return r.response[0]
      })
      .get(`/api/document`, {
        estimate: id,
      }),
  composeReducer: (state, action) => {
    // If add frag fails but we create the doc in meantime update the state \w
    // related doc from error payload
    if (
      action.type === makeMutationType("addFragment", FAILURE) &&
      action.payload.createdDoc
    ) {
      return {
        ...state,
        data: action.payload.createdDoc,
      }
    }
    return state
  },
  computed: {
    doc: "getData",
  },
})

export function useEstimateDocument(id) {
  const [state, actions] = useRunRj(EstimateDocument, [id])

  const docId = state.doc?.id ?? null

  const [, ctxEstimateActions] = useContext(CurrentEstimateStateContext)

  const syncMyCreatedDoc = useCallback(
    (docId, created) => {
      if (docId === null && created && ctxEstimateActions !== null) {
        ctxEstimateActions.addCreatedDoc(created)
      }
    },
    [ctxEstimateActions]
  )

  const memoCurryIdActions = useMemo(
    () => ({
      addFragment: actions.addFragment
        .onSuccess((created) => syncMyCreatedDoc(docId, created))
        .onFailure((err) => syncMyCreatedDoc(docId, err.createdDoc))
        .curry(id, docId),
      removeFragment: actions.removeFragment.curry(docId),
    }),
    [actions, id, docId, syncMyCreatedDoc]
  )

  return [
    state,
    {
      ...actions,
      ...memoCurryIdActions,
    },
  ]
}

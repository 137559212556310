import { AxisBottom, AxisLeft } from "@visx/axis"
import { Group } from "@visx/group"
import { BarStackHorizontal } from "@visx/shape"
import { scaleBand, scaleLinear } from "d3-scale"
import { keyBy } from "lodash"
import React from "react"

export default function ProjectInsightActivityBarchart({
  activityAggregates: data,
  width,
}) {
  const resourcesMap = keyBy(data?.resources_data, "id")

  const height = data.by_resource.length * 50 + 20
  const margin = { top: 10, left: 5, right: 10, bottom: 20 }

  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const xScale = scaleLinear()
    .domain([0, Math.max(...data.by_resource.map((r) => r.total * 1.1))])
    .range([0, xMax])

  const yScale = scaleBand()
    .domain(Object.keys(resourcesMap))
    .range([0, yMax])
    .padding(0.2)

  if (!width || width < 10) {
    return null
  }

  return (
    <svg width={width} height={height}>
      <Group top={margin.top} left={margin.left}>
        <BarStackHorizontal
          data={data.by_resource}
          keys={["total"]}
          height={yMax}
          y={(datum) => datum.priced_resource}
          xScale={xScale}
          yScale={yScale}
          color={() => "black"}
        >
          {(barStacks) =>
            barStacks.map((barStack) =>
              barStack.bars.map((bar) => (
                <>
                  <rect
                    key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                    x={bar.x}
                    y={bar.y}
                    width={bar.width}
                    height={bar.height}
                    fill="var(--primary-50)"
                  />
                  <text
                    x={bar.x + 10}
                    y={bar.y + bar.height / 2}
                    alignmentBaseline="middle"
                    fontStyle="italic"
                    fontSize={12}
                  >
                    {resourcesMap[bar.bar.data.priced_resource].resource_name}
                    {" ("}
                    {resourcesMap[bar.bar.data.priced_resource].cost_unit}
                    {")"}
                  </text>
                </>
              ))
            )
          }
        </BarStackHorizontal>
        <AxisLeft
          hideTicks
          scale={yScale}
          stroke={"black"}
          tickStroke={"black"}
          tickLabelProps={() => ({
            fill: "none",
            fontSize: 11,
            textAnchor: "end",
            dy: "0.33em",
          })}
        />
        <AxisBottom
          hideTicks
          hideZero
          top={yMax}
          scale={xScale}
          stroke={"black"}
          tickFormat={(v) => parseFloat(v).toFixed(2)}
          tickStroke={"black"}
          tickLabelProps={() => ({
            fill: "black",
            fontSize: 11,
            textAnchor: "middle",
          })}
        />
      </Group>
    </svg>
  )
}

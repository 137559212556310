import { Field } from "formik"
import { keyBy, range } from "lodash"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { getDepth } from "react-sortable-tree"
import FieldRange from "../../../../../components/fields/FieldRange"
import FieldSlider from "../../../../../components/fields/FieldSlider"
import FieldSwitch from "../../../../../components/fields/FieldSwitch"
import EditDataFrame, { EditDataSection } from "../../EditDataFrame/EditDataFrame"

export const EditDataForm = ({ fullEstimate, frag, save, remove, toggle, scrollFragIntoView }) => {
  const depth = getDepth({ children: fullEstimate.task_tree })

  const { t } = useTranslation(["translation"])
  const readOnly = fullEstimate.readonly

  const sliderMarks = useMemo(() => {
    const values = range(1, depth + 1).map((v) => {
      if (v < depth) {
        return {
          id: v,
          label: t("tasks.level", { level: v }),
        }
      }
      return {
        id: v,
        label: t("tasks.all"),
      }
    })
    return keyBy(values, "id")
  }, [depth, t])

  const rangeMarks = useMemo(() => {
    const height = fullEstimate.task_tree.length
    const values = range(1, height + 1).map((v) => {
      return {
        id: v,
        label: v,
      }
    })
    return keyBy(values, "id")
  }, [fullEstimate.task_tree.length])

  const fragWithDefaults = useMemo(() => {
    return { ...frag, data: {arePricesVisible: true, ...frag.data}}
  }, [frag])

  return (
    <EditDataFrame
      contentType={t("documents.frag.task.table.title")}
      frag={fragWithDefaults}
      save={save}
      remove={remove}
      toggle={toggle}
      scrollFragIntoView={scrollFragIntoView}
      readOnly={readOnly}
      scenarios={fullEstimate.scenarios}
      scenariosConfigurationExtra={
        <>
          <div className="mt-4">
            <Field
              name="data.isScenarioVisible"
              message={t("field:document.task_table.show_scenarios_message")}
              component={FieldSwitch}
              readOnly={readOnly}
            />
          </div>
          <div className="mt-4">
            <Field
              name="data.display_base_forced"
              message={t("field:document.task_table.always_show_base_scenario")}
              component={FieldSwitch}
              readOnly={readOnly}
            />
          </div>
        </>
      }
    >
      <EditDataSection title={t("field:document.task_table.task_section_title")}>
        {fullEstimate.task_tree.length > 1 && (
          <div className="overflow-x-visible">
            <p className="mb-2 text-uppercase">{t("field:document.task_table.task_selection")}</p>
            <div className="mx-3 overflow-x-visible">
              <Field
                name="data.visibleTasks"
                marks={rangeMarks}
                component={FieldRange}
                readOnly={readOnly}
                style={{ width: "100%" }}
                className="mb-5"
              />
            </div>
          </div>
        )}
        {depth > 1 && (
          <div className="mt-4 overflow-x-visible">
            <p className="mb-2 text-uppercase">{t("field:document.task_table.depth_level")}</p>
            <div className="mx-3 overflow-x-visible">
              <Field
                name="data.maxDepth"
                marks={sliderMarks}
                max={depth}
                component={FieldSlider}
                readOnly={readOnly}
                style={{ width: "100%" }}
                className="mb-5"
              />
            </div>
          </div>
        )}
        <div className="mt-4">
          <Field
            name="data.isResourceVisible"
            message={t("field:document.task_table.show_resources_message")}
            component={FieldSwitch}
            readOnly={readOnly}
          />
        </div>
        {depth > 1 && (
          <div className="mt-4">
            <Field
              name="data.isDotsVisible"
              message={t("field:document.task_table.show_dots_message")}
              component={FieldSwitch}
              readOnly={readOnly}
            />
          </div>
        )}
      </EditDataSection>
      <EditDataSection>
        <div className="mb-4">
          <Field
            name="data.arePricesVisible"
            message={t("field:document.task_table.show_prices_message")}
            component={FieldSwitch}
            readOnly={readOnly}
          />
        </div>
        <Field
          name="data.isTotalVisible"
          message={t("field:document.task_table.show_total_message")}
          component={FieldSwitch}
          readOnly={readOnly}
        />
      </EditDataSection>
    </EditDataFrame>
  )
}

export default EditDataForm

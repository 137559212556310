import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Field, Formik } from "formik"
import * as yup from "yup"
import { convertErrorToForm } from "../../utils"
import FieldInput from "../fields/FieldInput"
import Button from "../Button"
import WpModalQuota from "../WpModalQuota"
import { usePriceLists } from "../../hooks/priceList"
import FieldDropdown from "../fields/FieldDropdown"
import { getCurrencyFormatter } from "../../hooks/useCurrencyFormatter"
import FieldCustomerSelector from "../fields/FieldCustomerSelector"

const INITIAL_VALUES = {
  title: "",
  customer: null,
}

export default function CreateEstimateModal({
  isOpen,
  toggle,
  onSave,
  onClosed,
  cloneEstimate,
  mode = "create", // create | templetize | instance_template | duplicate | create_template
}) {
  const { t } = useTranslation(["translation", "field", "action"])
  const [{ data: priceLists }] = usePriceLists()

  const schema = yup.object().shape({
    title: yup
      .string()
      .required(t("field:errors.mandatory", { field: t("field:estimate.title") }))
      .max(512, t("field:errors.max", { max_value: 512 })),
  })

  const initialValues = useMemo(() => {
    if (!cloneEstimate) {
      return {
        ...INITIAL_VALUES,
        price_list: priceLists?.[0]?.id ?? -1,
      }
    }
    if (mode === "duplicate" || mode === "templetize") {
      console.log(cloneEstimate)
      return {
        cloneId: cloneEstimate.cloneId,
        title: t(`estimate_list.${mode}_title`, {
          estimate: cloneEstimate,
        }),
        customer: cloneEstimate.customer,
        price_list: cloneEstimate.price_list,
      }
    } else if (mode === "instance_template") {
      return {
        ...INITIAL_VALUES,
        cloneId: cloneEstimate.cloneId,
        price_list: cloneEstimate.price_list,
      }
    }
    return INITIAL_VALUES
  }, [cloneEstimate, mode, priceLists, t])

  if (!cloneEstimate && !priceLists) {
    // AWAIT PRICE LISTS TO LOAD
    return null
  }

  return (
    <WpModalQuota
      checkQuota={mode === "templetize" || mode === "create_template" ? "templates" : "estimates"}
      onClosed={onClosed}
      isOpen={isOpen}
      toggle={toggle}
      title={
        cloneEstimate ? t(`estimate_list.${mode}_estimate`, { estimate: cloneEstimate }) : t(`estimate_list.${mode}_estimate`)
      }
    >
      <div>
        <p className="mx-5">
          {cloneEstimate
            ? t(`estimate_list.${mode}_estimate_message`, {
                estimate: cloneEstimate,
              })
            : t(`estimate_list.${mode}_estimate_message`)}
        </p>
        <div className="d-flex flex-row align-items-start my-4">
          <div className="d-flex flex-column ml-2 flex-1">
            <Formik
              initialValues={initialValues}
              initialErrors={
                cloneEstimate
                  ? undefined
                  : {
                      title: "",
                    }
              }
              validationSchema={schema}
              onSubmit={(values, actions) => {
                return onSave({
                  cloneId: values.cloneId,
                  title: values.title,
                  price_list: values.price_list,
                  customer: values.customer,
                }).catch((error) => {
                  actions.setErrors(convertErrorToForm(error))
                })
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <>
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Field
                      name="title"
                      component={FieldInput}
                      placeholder={t("field:estimate.placeholder.title")}
                      label={t("field:estimate.title")}
                      className={"mb-5"}
                    />
                    {(mode === "create" || mode === "instance_template" || mode === "duplicate") && (
                      <Field
                        name="customer"
                        component={FieldCustomerSelector}
                        placeholder={t("field:estimate.placeholder.customer")}
                        label={t("field:estimate.customer")}
                        containerClassName={"mb-5"}
                        controlClassName="px-4 py-3 border-radius-xl"
                      />
                    )}
                    {!(mode === "duplicate" || mode === "templetize" || mode === "instance_template") && (
                      <Field
                        name="price_list"
                        component={FieldDropdown}
                        placeholder={t("field:estimate.placeholder.price_list")}
                        label={t("field:estimate.price_list")}
                        options={priceLists.map((lst) => {
                          return {
                            value: lst.id,
                            label: lst.title + " (" + getCurrencyFormatter(lst.currency).currencySymbol + ")",
                          }
                        })}
                        capitalize={false}
                      />
                    )}
                    <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                      <Button size="sm" color="none" onClick={toggle}>
                        {t("action:cancel")}
                      </Button>
                      <Button size="sm" color="primary" type="submit" className="ml-5" disabled={!formik.isValid}>
                        {t("action:add")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </WpModalQuota>
  )
}

import classNames from "classnames"
import React, { useCallback, useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import BadQuotaAlert from "../../../components/BadQuotaAlert"
import { ORGANIZATION_ROLES, ORGANIZATION_TRACKER_ROLES } from "../../../consts"
import { OrganizationDetailContext } from "../../../context"
import { useQuota } from "../../../hooks/quota"
import InvitationRowForm from "./InvitationRowForm"
import InvitationRow from "./InvitationRow"
import MemberInnerRowForm from "./MemberInnerForm"
import S from "./Members.module.scss"

export default function Members() {
  const { t } = useTranslation(["translation", "action", "enums", "field"])

  const [
    { organization },
    {
      inviteMembers,
      cancelInvitation,
      updateInvitation,
      updateMember,
      removeMember,
    },
  ] = useContext(OrganizationDetailContext)

  const [newInvitations, setNewInvitations] = useState([1])

  const handleNewInvitationEntry = useCallback(() => {
    setNewInvitations((keys) => keys.concat(Math.max(...keys) + 1))
  }, [])

  const [quota] = useQuota({ checkQuota: true })
  const showBadQuota = quota ? quota.collaborators === 0 : false
  const canAddCollaborators = quota ? quota.collaborators !== 0 : false

  const handleInvite = useCallback(
    (idx, values) => {
      return inviteMembers
        .onSuccess(() => {
          setNewInvitations((keys) => {
            // When only 1 row left replace with a fresh new row!
            if (keys.length === 1) {
              return [Math.max(...keys) + 1]
            }
            return keys.filter((k) => k !== idx)
          })
        })
        .asPromise(organization.id, [values])
    },
    [inviteMembers, organization.id]
  )

  const handleCancelInvitation = useCallback(
    (invitationId) => {
      return cancelInvitation.asPromise(organization.id, invitationId)
    },
    [cancelInvitation, organization.id]
  )

  const handleUpdateInvitation = useCallback(
    (invitationId, role) => {
      return updateInvitation.asPromise(organization.id, invitationId, role)
    },
    [updateInvitation, organization.id]
  )

  const handleUpdateMember = useCallback(
    (memberId, updates) => {
      return updateMember.asPromise(organization.id, memberId, updates)
    },
    [organization.id, updateMember]
  )

  const handleRemoveMember = useCallback(
    (memberId) => {
      return removeMember.asPromise(organization.id, memberId)
    },
    [removeMember, organization.id]
  )

  const estimateRoleOptions = useMemo(
    () =>
      ORGANIZATION_ROLES.map((role) => ({
        label: t(`enums:organization_role.${role}`),
        value: role,
      })),
    [t]
  )

  const trackerRoleOptions = useMemo(
    () =>
      ORGANIZATION_TRACKER_ROLES.map((role) => ({
        label: t(`enums:organization_tracker_role.${role}`),
        value: role,
      })),
    [t]
  )

  return (
    <div className="w-100 h-100 overflow-hidden">
      <div className="w-100 h-100 overflow-auto d-flex flex-column justify-content-start align-items-stretch">
        <div className=" pb-page-bottom flex-1 px-9 container">
          <div className="d-flex align-items-center mx-9 px-9">
            <div className="mt-7 mx-9">
              <h1 className="mb-4">{t("organization.members.title")}</h1>
              <p style={{ whiteSpace: "pre-line" }}>
                {t("organization.members.message")}
              </p>
            </div>
          </div>
          <div className="mx-9">
            <table
              className={classNames(
                "table table-borderless",
                S["members-table"]
              )}
            >
              <thead>
                <tr>
                  <th>{t("field:organization_member.email")}</th>
                  <th>{t("field:organization_member.is_active")}</th>
                  <th>{t("field:organization_member.capabilities")}</th>
                  <th className="text-center">
                    {t("field:organization_member.status")}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {organization.members.map((member, index) => (
                  <MemberInnerRowForm
                    estimateRoleOptions={estimateRoleOptions}
                    trackerRoleOptions={trackerRoleOptions}
                    member={member}
                    key={member.id}
                    index={index}
                    onChange={handleUpdateMember}
                    onDelete={handleRemoveMember}
                  />
                ))}
                <>
                  {organization.invitations.map((invitation) => (
                    <InvitationRow
                      invitation={invitation}
                      key={invitation.id}
                      onChange={handleUpdateInvitation}
                      onDelete={handleCancelInvitation}
                      estimateRoleOptions={estimateRoleOptions}
                      trackerRoleOptions={trackerRoleOptions}
                    />
                  ))}
                </>
                {canAddCollaborators && (
                  <>
                    {newInvitations.map((key, i) => (
                      <InvitationRowForm
                        estimateRoleOptions={estimateRoleOptions}
                        trackerRoleOptions={trackerRoleOptions}
                        showAdd={i === newInvitations.length - 1}
                        onAddClick={handleNewInvitationEntry}
                        idx={key}
                        key={key}
                        save={handleInvite}
                        enabled={canAddCollaborators}
                      />
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {showBadQuota && (
          <BadQuotaAlert className="mx-9 mb-3" checkQuota="collaborators" />
        )}
      </div>
    </div>
  )
}

import { useMemo } from "react"
import {
  walk as walkTree,
  removeNodeAtPath,
  changeNodeAtPath,
  toggleExpandedForAll,
} from "react-sortable-tree"
import keyBy from "lodash/keyBy"
import { rj, useRunRj } from "react-rocketjump"
import rjMutationsPending from "react-rocketjump/plugins/mutationsPending"
import api from "../api"
import { useResourcesList } from "./resources"

// Global default for our tree
const getNodeKey = ({ node }) => node.id

// Merge client task tree with incoming server tree
// keep expanded tasks from client
function fillExpandTree(localTaskTree, remoteTaskTree) {
  const expandedById = {}
  walkTree({
    treeData: localTaskTree,
    getNodeKey,
    ignoreCollapsed: false,
    callback: ({ node }) => {
      expandedById[node.id] = node.expanded ?? false
    },
  })
  walkTree({
    treeData: remoteTaskTree,
    getNodeKey,
    ignoreCollapsed: false,
    callback: ({ node }) => {
      node.expanded = expandedById[node.id] ?? false
    },
  })
  return remoteTaskTree
}

// Re-calculate server index based path on changed tree
// iter over all tree but bail out the nodes with same old path
// return the old instance so won't re-render
function fixPathTree(taskTree) {
  function recursiveFixPathTree(taskTree, parentTask) {
    if (taskTree.length === 0) {
      return taskTree
    }
    let hasChanged = false
    const newTree = taskTree.map((task) => {
      let nextTask = task
      const currentPath = task.path
      let fixedPath = parentTask?.path ? parentTask.path + "." : ""
      fixedPath += (parentTask?.children ?? taskTree).indexOf(task) + 1
      if (currentPath !== fixedPath) {
        nextTask = {
          ...nextTask,
          path: fixedPath,
        }
        hasChanged = true
      }
      const nextChildren = recursiveFixPathTree(nextTask.children, nextTask)
      if (task.children !== nextChildren) {
        hasChanged = true
        nextTask = {
          ...nextTask,
          children: nextChildren,
        }
      }
      return nextTask
    })

    if (hasChanged) {
      return newTree
    } else {
      return taskTree
    }
  }

  return recursiveFixPathTree(taskTree)
}

// Take a task and a tree and return an ID based path
function makeTaskPathIds(task, taskTree) {
  let pathArray = []
  let iterTree = taskTree
  task.path.split(".").forEach((index1) => {
    const iterTask = iterTree[index1 - 1]
    pathArray.push(iterTask.id)
    iterTree = iterTask.children
  })
  return pathArray
}

function replaceTaskTreeState(state, newTaskTree) {
  return {
    ...state,
    data: {
      ...state.data,
      task_tree: newTaskTree,
    },
  }
}

function updateFromServerEstimate(state, estimateFresh) {
  return {
    ...state,
    data: {
      ...estimateFresh,
      task_tree: fillExpandTree(state.data.task_tree, estimateFresh.task_tree),
    },
  }
}

function addCreatedDoc(state, doc) {
  return {
    ...state,
    data: {
      ...state.data,
      documents: state.data.documents.concat({
        id: doc.id,
        fragment_count: doc.fragments.length,
      }),
    },
  }
}

function removeScenarioFromTree(taskTree, rmId) {
  function recursiveFixPathTree(taskTree) {
    if (taskTree.length === 0) {
      return taskTree
    }
    let hasChanged = false
    const newTree = taskTree.map((task) => {
      let nextTask = task
      const currentScenarios = task.scenarios
      const nextScenarios = currentScenarios.filter((id) => id !== rmId)

      if (currentScenarios.length !== nextScenarios.length) {
        nextTask = {
          ...nextTask,
          scenarios: nextScenarios,
        }
        hasChanged = true
      }
      const nextChildren = recursiveFixPathTree(nextTask.children)
      if (task.children !== nextChildren) {
        hasChanged = true
        nextTask = {
          ...nextTask,
          children: nextChildren,
        }
      }
      return nextTask
    })

    if (hasChanged) {
      return newTree
    } else {
      return taskTree
    }
  }

  return recursiveFixPathTree(taskTree)
}

function indexToCode(index) {
  if (index < 26) {
    return String.fromCharCode(65 + index)
  }
  return (
    String.fromCharCode(65 + parseInt(index / 26)) +
    String.fromCharCode(65 + (index % 26))
  )
}

const EstimateTreeState = rj(rjMutationsPending(), {
  effectCaller: rj.configured(),
  name: "EstimateTree",
  effect: (wpAuth) => (id) => api.auth(wpAuth).get(`/api/estimate/${id}`),
  actions: () => ({
    addCreatedDoc: (doc) => ({
      type: "CREATED_DOC",
      payload: doc,
    }),
    updateTaskTree: (taskTree) => ({
      type: "UPDATE_TASK_TREE",
      payload: taskTree,
    }),
    collapseAllTasksTree: (taskTree) => ({
      type: "COLLAPSE_ALL",
    }),
  }),
  composeReducer: (state, action) => {
    if (action.type === "CREATED_DOC") {
      return addCreatedDoc(state, action.payload)
    } else if (action.type === "UPDATE_TASK_TREE") {
      return replaceTaskTreeState(state, action.payload)
    } else if (action.type === "COLLAPSE_ALL") {
      return {
        ...state,
        data: {
          ...state.data,
          task_tree: toggleExpandedForAll({
            treeData: state.data.task_tree,
            expanded: false,
          }),
        },
      }
    }
    return state
  },
  mutations: {
    updateEstimate: {
      takeEffect: "concatLatest",
      effect: (wpAuth) => (id, data) => {
        return api.auth(wpAuth).patch(`/api/estimate/${id}/`, data)
      },
      updater: updateFromServerEstimate,
    },
    patchEstimateOptions: {
      effect: (wpAuth) => (id, options) => {
        return api.auth(wpAuth).patch(`/api/estimate/${id}/`, {
          options,
        })
      },
      optimisticResult: (id, options) => options,
      optimisticUpdater: (state, options) => ({
        ...state,
        data: {
          ...state.data,
          options,
        },
      }),
    },
    addTask: {
      effect: (wpAuth) => (id, task) =>
        api.auth(wpAuth).post(`/api/estimate/${id}/tasks/`, task),
      updater: updateFromServerEstimate,
    },
    removeTask: {
      effect: (wpAuth) => (id, task) =>
        api.auth(wpAuth).post(`/api/estimate/${id}/tasks/delete/`, {
          ids: [task.id],
        }),
      optimisticResult: (id, task) => task,
      optimisticUpdater: (state, task) => {
        const treeData = state.data.task_tree
        const path = makeTaskPathIds(task, treeData)
        const nextTreeData = fixPathTree(
          removeNodeAtPath({
            treeData,
            getNodeKey,
            path,
          })
        )
        return replaceTaskTreeState(state, nextTreeData)
      },
      updater: updateFromServerEstimate,
    },
    moveTask: {
      effect: (wpAuth) => (id, payload) =>
        api.auth(wpAuth).post(`/api/estimate/${id}/tasks/move`, payload),
      optimisticResult: (id, payload, treeData) => treeData,
      optimisticUpdater: (state, taskTree) =>
        replaceTaskTreeState(state, fixPathTree(taskTree)),
      updater: updateFromServerEstimate,
    },
    pasteTask: {
      effect: (wpAuth) => (id, payload) =>
        api.auth(wpAuth).post(`/api/estimate/${id}/tasks/paste`, payload),
      updater: updateFromServerEstimate,
    },
    patchTask: {
      takeEffect: ["groupByConcatLatest", (action) => action.meta.params[0].id],
      effect: (wpAuth) => (task) =>
        api
          .auth(wpAuth)
          // FIXME: Path can change in meantime ....
          .mapResponse((r) => ({ ...r.response, path: task.path }))
          .patch(`/api/task/${task.id}`, task),
      updater: (state, task) => {
        const treeData = state.data.task_tree
        const path = makeTaskPathIds(task, treeData)
        const nextTreeData = changeNodeAtPath({
          treeData,
          getNodeKey,
          path,
          newNode: ({ node }) => ({ ...node, ...task }),
        })
        return replaceTaskTreeState(state, nextTreeData)
      },
    },
    addTaskFixedCost: {
      takeEffect: ["groupByExhaust", (action) => action.meta.params[1]],
      effect: (wpAuth) => (id, taskId, fixedCost) =>
        api
          .auth(wpAuth)
          .post(`/api/estimate/${id}/tasks/${taskId}/fixed-costs/`, fixedCost),
      updater: updateFromServerEstimate,
    },
    patchTaskFixedCost: {
      effect: (wpAuth) => (id, taskId, costId, fixedCost) =>
        api
          .auth(wpAuth)
          .patch(
            `/api/estimate/${id}/tasks/${taskId}/fixed-costs/${costId}`,
            fixedCost
          ),
      updater: updateFromServerEstimate,
    },
    removeTaskFixedCost: {
      effect: (wpAuth) => (id, taskId, fixedCostId) =>
        api
          .auth(wpAuth)
          .delete(
            `/api/estimate/${id}/tasks/${taskId}/fixed-costs/${fixedCostId}`
          ),
      updater: updateFromServerEstimate,
    },
    addTaskResource: {
      effect: (wpAuth) => (id, taskId, pricedResource) =>
        api
          .auth(wpAuth)
          .post(
            `/api/estimate/${id}/tasks/${taskId}/resources`,
            pricedResource
          ),
      updater: updateFromServerEstimate,
    },
    patchTaskResource: rj.mutation.multi(
      (id, taskId, allocationId) => allocationId,
      {
        takeEffect: ["groupByConcatLatest", (action) => action.meta.params[2]],
        effect: (wpAuth) => (id, taskId, allocationId, resourcePayload) =>
          api
            .auth(wpAuth)
            .patch(
              `/api/estimate/${id}/tasks/${taskId}/resources/${allocationId}`,
              resourcePayload
            ),
        updater: updateFromServerEstimate,
      }
    ),
    removeTaskResource: {
      effect: (wpAuth) => (id, task, allocationId) =>
        api
          .auth(wpAuth)
          .delete(
            `/api/estimate/${id}/tasks/${task.id}/resources/${allocationId}`
          ),
      optimisticResult: (id, task, allocationId) => [task, allocationId],
      optimisticUpdater: (state, [task, allocationId]) => {
        const treeData = state.data.task_tree
        const path = makeTaskPathIds(task, treeData)

        const nextTreeData = changeNodeAtPath({
          treeData,
          getNodeKey,
          path,
          newNode: ({ node }) => ({
            ...node,
            resources: node.resources.filter(
              (r) => r.allocation_id !== allocationId
            ),
          }),
        })
        return replaceTaskTreeState(state, nextTreeData)
      },
      updater: updateFromServerEstimate,
    },
    overrideResource: {
      effect: (wpAuth) => (id, resource) =>
        api.auth(wpAuth).patch(
          `/api/estimate/${id}/resources/`,
          resource.price_list.map((item) => ({
            id: item.id,
            unitary_cost: item.unitary_cost,
            unitary_price: item.unitary_price,
          }))
        ),
      updater: updateFromServerEstimate,
    },
    resetResource: rj.mutation.single({
      effect: (wpAuth) => (id, resource) =>
        api.auth(wpAuth).post(
          `/api/estimate/${id}/resources/reset`,
          resource.price_list.map((item) => item.id)
        ),
      updater: updateFromServerEstimate,
    }),
    templetizeEstimate: {
      effect: (wpAuth) => (id, estimateData) => {
        return api
          .auth(wpAuth)
          .post(`/api/estimate/${id}/templetize?dataformat=flat`, estimateData)
      },
      updater: (s) => s, // Nothing to do
    },
    deleteEstimate: {
      takeEffect: ["groupByExhaust", (action) => action.payload.params[0]],
      effect: (wpAuth) => (id) => {
        return api.auth(wpAuth).delete(`/api/estimate/${id}`)
      },
      updater: (s) => s, // Nothing to do
    },
    patchScenario: {
      takeEffect: ["groupByConcatLatest", (a) => a.meta.params[0].id],
      effect: (wpAuth) => (scenarioPayload) =>
        api
          .auth(wpAuth)
          .patch(`/api/scenario/${scenarioPayload.id}`, scenarioPayload),
      updater: (state, result) => ({
        ...state,
        data: {
          ...state.data,
          scenarios: state.data.scenarios.map((s) =>
            s.id === result.id ? result : s
          ),
        },
      }),
    },
    addScenario: {
      effect: (wpAuth) => (scenarioPayload) =>
        api.auth(wpAuth).post(`/api/scenario/`, scenarioPayload),
      updater: (state, result) => ({
        ...state,
        data: {
          ...state.data,
          scenarios: state.data.scenarios.concat(result),
        },
      }),
    },
    removeScenario: {
      effect: (wpAuth) => (id) =>
        api
          .auth(wpAuth)
          .mapResponse(() => ({ id }))
          .delete(`/api/scenario/${id}`),
      updater: (state, result) => ({
        ...state,
        data: {
          ...state.data,
          task_tree: removeScenarioFromTree(state.data.task_tree, result.id),
          scenarios: state.data.scenarios
            .filter((s) => s.id !== result.id)
            .map((s, i) => ({
              ...s,
              code: indexToCode(i),
            })),
        },
      }),
    },


    createBillingTranche: rj.mutation.single({
      effect: (wpAuth) => (data) => {
        return api.auth(wpAuth).post(`/api/billing-tranche`, data)
      },
      updater: (state, result) => {
        if (state?.data) {
          return {
            ...state,
            data: {
              ...state.data,
              billing_tranches: [...state.data.billing_tranches, result],
            },
          }
        } else {
          return state
        }
      },
    }),
    updateBillingTranche: rj.mutation.single({
      effect: (wpAuth) => (id, data) => {
        return api.auth(wpAuth).patch(`/api/billing-tranche/${id}`, data)
      },
      updater: (state, result) => {
        return {
          ...state,
          data: {
            ...state.data,
            billing_tranches: state.data.billing_tranches.map((item) =>
              item.id !== result.id ? item : result
            ),
          },
        }
      },
    }),
    removeBillingTranche: rj.mutation.single({
      effect: (wpAuth) => (itemId) => {
        return api
          .auth(wpAuth)
          .mapResponse(() => itemId)
          .delete(`/api/billing-tranche/${itemId}`)
      },
      updater: (state, result) => {
        if (state?.data) {
          return {
            ...state,
            data: {
              ...state.data,
              billing_tranches: state.data.billing_tranches.filter((item) => item.id !== result),
            },
          }
        } else {
          return state
        }
      },
    }),
  },
  selectors: () => ({
    getScenarios: (s) => s.root.data?.scenarios ?? null,
  }),
  computed: {
    resettingResource: (s) => s.mutations.resetResource.pending,
    updatingAllocations: (s) => s.mutations.patchTaskResource.pendings,
    estimate: "getData",
    scenarios: "getScenarios",
    saving: "anyMutationPending",
  },
})

export function useEstimateTree(id) {
  const [state, actions] = useRunRj(EstimateTreeState, [id])

  const memoWithCurryIdActions = useMemo(
    () => ({
      ...actions,
      updateEstimate: actions.updateEstimate.curry(id),
      patchEstimateOptions: actions.patchEstimateOptions.curry(id),
      removeTask: actions.removeTask.curry(id),
      addTask: actions.addTask.curry(id),
      moveTask: actions.moveTask.curry(id),
      pasteTask: actions.pasteTask.curry(id),
      addTaskFixedCost: actions.addTaskFixedCost.curry(id),
      removeTaskFixedCost: actions.removeTaskFixedCost.curry(id),
      addTaskResource: actions.addTaskResource.curry(id),
      patchTaskResource: actions.patchTaskResource.curry(id),
      removeTaskResource: actions.removeTaskResource.curry(id),
      patchTaskFixedCost: actions.patchTaskFixedCost.curry(id),
      overrideResource: actions.overrideResource.curry(id),
      resetResource: actions.resetResource.curry(id),
      templetizeEstimate: actions.templetizeEstimate.curry(id),
      deleteEstimate: actions.deleteEstimate.curry(id),
    }),
    [id, actions]
  )

  return useMemo(() => [state, memoWithCurryIdActions], [
    state,
    memoWithCurryIdActions,
  ])
}

const resourcesFiltersDefault = { embed_prices: true, estimate: "none" }
export function useEstimateResources(estimate) {
  const resourcesFilters = useMemo(() => ({
    ...resourcesFiltersDefault,
    price_list: estimate.price_list
  }), [estimate.price_list])
  const [{ resources, ...state }, actions] = useResourcesList(resourcesFilters)

  const estimateResources = estimate?.resources
  const estimateAllResources = useMemo(() => {
    if (estimateResources && resources) {
      const estimateResourcesById = keyBy(estimateResources, "id")
      return resources.map((r) => {
        if (estimateResourcesById[r.id]) {
          return estimateResourcesById[r.id]
        }
        return r
      })
    }
    return []
  }, [estimateResources, resources])

  return [{ ...state, resources: estimateAllResources }, actions]
}

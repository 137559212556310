import classNames from "classnames"
import dayjs from "dayjs"
import { isEqual } from "lodash"
import { forwardRef, useMemo, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import { BsCalendar, BsDash, BsPlus, BsTools, BsXCircle } from "react-icons/bs"
import { Input } from "reactstrap"
import Button from "../../components/Button"
import HrResourceSelector from "../../components/HrResourceSelector"
import WpModal from "../../components/WpModal"
import { CAP, CAPLEVEL, useCapabilities } from "../../hooks/capabilities"
import S from "./style.module.scss"

export function ManageAllocationsModal({
  initialUserIds,
  kinds,
  initialStartDate,
  initialEndDate,
  dateMode = "single",
  initialValue,
  isOpen,
  onClosed,
  onCancel,
  onSubmit,
}) {
  const initialAllocationsNormalized = useMemo(() => {
    const norm = {}
    for (const alloc of initialValue) {
      norm[alloc.kind] = norm[alloc.kind] ?? { hours: 0, notes: "" }
      norm[alloc.kind].hours += alloc.hours
      if (alloc.notes) {
        norm[alloc.kind].notes += " " + alloc.notes
      }
    }
    return norm
  }, [initialValue])
  const [users, setUsers] = useState(initialUserIds)
  const [startDate, setStartDate] = useState(initialStartDate)
  const [endDate, setEndDate] = useState(initialEndDate)
  const [initialAllocations] = useState(initialAllocationsNormalized)
  const [allocations, setAllocations] = useState(initialAllocationsNormalized)
  const [, { hasCapability }] = useCapabilities()
  const [includeVacations, setVacationsIncluded] = useState(dateMode === "single")

  const kindsInUseInitial = kinds.filter((kind) => initialAllocations[kind.id]?.hours > 0)

  const { t, i18n } = useTranslation()

  return (
    <WpModal isOpen={isOpen} onClosed={onClosed} toggle={onCancel} contentClassName="bg-gray-light" size="lg">
      <div className="d-flex flex-row justify-content-between align-items-center mb-5">
        <h2 className="text-primary mt-0 mb-0">{t("hr_workflow_new.manage_allocations")}</h2>
        <BsXCircle className="pointer" onClick={onCancel} style={{ fontSize: 16 }} />
      </div>
      <div className="mb-5">
        <HrResourceSelector
          containerClassName="w-100"
          idOrIds={users}
          onChange={(result) => {
            setUsers(result)
          }}
          disabled={!hasCapability(CAP.WORKFLOW, CAPLEVEL.ADMIN)}
          emptyCaption={t("hr_workflow_new.select_resources")}
        />
      </div>
      {dateMode === "single" && (
        <div className="mb-5 p-3 bg-primary-10 d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex flex-row justify-content-start align-items-center flex-1">
            <div className={classNames(S["calendar-cell-day"], "bg-primary text-white mr-3")}>{startDate.format("DD")}</div>
            <div className="text-primary font-weight-bold text-capitalize">{startDate.format("dddd")}</div>
            <div className="mx-2 text-primary-50">
              <span style={{ fontSize: 16 }}>|</span>
            </div>
            <div className="text-capitalize">{startDate.format("MMMM")}</div>
            <div className="mx-2 text-primary-50">
              <span style={{ fontSize: 16 }}>|</span>
            </div>
            <div className="text-capitalize">{startDate.format("YYYY")}</div>
          </div>
          <ReactDatePicker
            locale={i18n.language}
            selected={startDate.toDate()}
            onChange={(date) => setStartDate(dayjs(date).locale(i18n.language))}
            customInput={<DatePickerCustomInput />}
            popperPlacement="bottom-end"
          />
        </div>
      )}
      {dateMode === "range" && (
        <div className="d-flex flex-row justify-content-start align-items-center mb-5 align-self-stretch">
          <div className="mr-3">{t("hr_workflow_new.range_from")}</div>
          <div className="flex-1 rounded bg-white p-3 d-flex flex-row justify-content-start align-items-center mr-3">
            <div className={classNames(S["calendar-cell-day"], "bg-gray-light text-black mr-3")}>{startDate.format("DD")}</div>
            <div className="text-black font-weight-bold text-capitalize">{startDate.format("dddd")}</div>
            <div className="mx-2 text-primary-50">
              <span style={{ fontSize: 16 }}>|</span>
            </div>
            <div className="text-capitalize">{startDate.format("MMMM")}</div>
            <div className="mx-2 text-primary-50">
              <span style={{ fontSize: 16 }}>|</span>
            </div>
            <div className="text-capitalize">{startDate.format("YYYY")}</div>
          </div>
          <div className="mr-3">{t("hr_workflow_new.range_to")}</div>
          <div className="flex-1 rounded bg-white p-3 d-flex flex-row justify-content-start align-items-center mr-3">
            {endDate && (
              <>
                <div className={classNames(S["calendar-cell-day"], "bg-gray-light text-black mr-3")}>
                  {endDate.format("DD")}
                </div>
                <div className="text-black font-weight-bold text-capitalize">{endDate.format("dddd")}</div>
                <div className="mx-2 text-primary-50">
                  <span style={{ fontSize: 16 }}>|</span>
                </div>
                <div className="text-capitalize">{endDate.format("MMMM")}</div>
                <div className="mx-2 text-primary-50">
                  <span style={{ fontSize: 16 }}>|</span>
                </div>
                <div className="text-capitalize">{endDate.format("YYYY")}</div>
              </>
            )}
          </div>
          <ReactDatePicker
            locale={i18n.language}
            startDate={startDate.toDate()}
            endDate={endDate?.toDate()}
            selectsRange
            onChange={(dates) => {
              const [start, end] = dates
              if (start) setStartDate(dayjs(start).locale(i18n.language))
              if (end) {
                setEndDate(dayjs(end).locale(i18n.language))
              } else {
                setEndDate(null)
              }
            }}
            customInput={<DatePickerCustomInputIcon />}
            popperPlacement="bottom-end"
          />
          <div
            className={classNames("rounded d-flex flex-column justify-content-center align-items-center pointer ml-3", {
              "bg-primary-50": !includeVacations,
              "bg-gray-bg-light": includeVacations,
            })}
            title={includeVacations ? t("hr_workflow_new.include_vacations") : t("hr_workflow_new.exclude_vacations")}
            style={{ height: 40, width: 40 }}
            onClick={() => setVacationsIncluded((inc) => !inc)}
          >
            <BsTools className="text-white" style={{ fontSize: 16 }} />
          </div>
        </div>
      )}
      <div className="rounded bg-white p-3 mb-7">
        <h3 className="text-primary-50 mt-0 mb-3">{t("hr_workflow_new.current_allocations")}</h3>
        {kindsInUseInitial.length === 0 && (
          <div>
            <i>No allocations</i>
          </div>
        )}
        {kindsInUseInitial.length > 0 && (
          <table className="w-100">
            <tbody>
              {kindsInUseInitial.map((kind) => {
                const { hours, notes } = initialAllocations[kind.id]
                return (
                  <tr key={kind.id}>
                    <td className="w-25">{kind.verbose_name}</td>
                    <td className="w-25">
                      <b>{hours} </b>
                      {t("hr_workflow_new.hour", { count: hours })}
                    </td>
                    <td>
                      {t("hr_workflow_new.notes")}: {notes || "--"}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
        <Button
          size="sm"
          className="mt-3"
          onClick={() => setAllocations(initialAllocations)}
          disabled={isEqual(initialAllocations, allocations)}
        >
          {t("hr_workflow_new.reset")}
        </Button>
      </div>
      <div className="rounded bg-white container-fluid py-1 px-3 mb-7">
        <div className="row" style={{ marginLeft: -8, marginRight: -8 }}>
          <div className="col-4 font-weight-bold px-3">{t("hr_workflow_new.allocation")}</div>
          <div className="col-3 font-weight-bold px-3 text-center">{t("hr_workflow_new.hour_other")}</div>
          <div className="col-5 font-weight-bold px-3">{t("hr_workflow_new.notes")}</div>
        </div>
      </div>
      <div className="container-fluid px-3">
        {kinds.map((kind) => {
          return (
            <div className="row mb-4 align-items-start" style={{ marginLeft: -8, marginRight: -8 }}>
              <div className="col-4 px-3">{kind.verbose_name}</div>
              <div className="col-3 px-3 d-flex flex-row justify-content-center align-items-center">
                <BsDash
                  className="pointer mr-2 text-primary font-weight-bold"
                  style={{ fontSize: 24 }}
                  onClick={() =>
                    setAllocations((alloc) => ({
                      ...alloc,
                      [kind.id]: {
                        notes: "",
                        ...allocations[kind.id],
                        hours: Math.max(0, (allocations[kind.id]?.hours ?? 0) - 1),
                      },
                    }))
                  }
                />
                <Input
                  type="number"
                  size="sm"
                  className="text-center"
                  value={allocations[kind.id]?.hours ?? "0"}
                  onChange={(e) => {
                    setAllocations((alloc) => ({
                      ...alloc,
                      [kind.id]: { notes: "", ...allocations[kind.id], hours: e.target.value },
                    }))
                  }}
                  style={{ width: 45 }}
                />
                <BsPlus
                  className="pointer ml-2 text-primary font-weight-bold"
                  style={{ fontSize: 24 }}
                  onClick={() =>
                    setAllocations((alloc) => ({
                      ...alloc,
                      [kind.id]: { notes: "", ...allocations[kind.id], hours: (allocations[kind.id]?.hours ?? 0) + 1 },
                    }))
                  }
                />
              </div>
              <div className="col-5 px-3">
                <Input
                  type="textarea"
                  size="sm"
                  rows={1}
                  className="w-100 resize-vertical"
                  value={allocations[kind.id]?.notes ?? ""}
                  onChange={(e) => {
                    setAllocations((alloc) => ({
                      ...alloc,
                      [kind.id]: { hours: 0, ...allocations[kind.id], notes: e.target.value },
                    }))
                  }}
                />
              </div>
            </div>
          )
        })}
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        {dateMode === "single" && <div />}
        {dateMode === "range" && (
          <small>{includeVacations ? t("hr_workflow_new.include_vacations") : t("hr_workflow_new.exclude_vacations")}</small>
        )}
        <Button
          size="sm"
          className="mr-3"
          onClick={() => {
            onSubmit({
              users: users,
              startDate: startDate,
              endDate: dateMode === "single" ? startDate : endDate,
              allocations: Object.entries(allocations)
                .map(([kind, { hours, notes }]) => ({ kind, hours, notes }))
                .filter((alloc) => (alloc.hours || 0) > 0),
              includeVacations: includeVacations,
            })
          }}
        >
          {t("action:save")}
        </Button>
      </div>
    </WpModal>
  )
}

function DatePickerCustomInputRef({ onClick }, ref) {
  const { t } = useTranslation()
  return (
    <Button size="sm" className="bg-white" innerRef={ref} onClick={onClick}>
      {t("hr_workflow_new.change_date")}
    </Button>
  )
}

const DatePickerCustomInput = forwardRef(DatePickerCustomInputRef)

function DatePickerCustomInputIconRef({ onClick }, ref) {
  return (
    <div
      className="bg-primary-50 rounded d-flex flex-column justify-content-center align-items-center pointer"
      style={{ height: 40, width: 40 }}
      ref={ref}
      onClick={onClick}
    >
      <BsCalendar className="text-white" style={{ fontSize: 16 }} />
    </div>
  )
}

const DatePickerCustomInputIcon = forwardRef(DatePickerCustomInputIconRef)

const LEGEND_SHEET_PERCENT = 0.4

export default function ChartBubbleSplitter(
  node,
  props,
  firstChunkSpace,
  sheetHeight
) {
  const chart = node.querySelector(".__pager-chart")
  const caption = node.querySelector(".__pager-caption")
  const legend = node.querySelector(".__pager-legend")
  const disclaimer = node.querySelector(".__pager-disclaimer")

  const chartHeight = chart?.offsetHeight ?? 0
  const captionHeight = caption?.offsetHeight ?? 0
  const legendHeight = legend?.offsetHeight ?? 0

  if (disclaimer) {
    return [
      null,
      {
        props: {},
        height: disclaimer.offsetHeight
      }
    ]
  }

  if (legend && legendHeight < LEGEND_SHEET_PERCENT * sheetHeight) {
    return [
      null,
      {
        props: {
          showChart: true,
          showLegend: true,
          width: sheetHeight * (1 - LEGEND_SHEET_PERCENT),
        },
        height: sheetHeight,
      },
    ]
  } else if (legend) {
    if (chartHeight + captionHeight < firstChunkSpace) {
      // if chart fits, then just push down the legend
      // for sure the legend exists (if chart fits and there is no legend, this splitter is not called)
      return [
        {
          props: {
            showChart: true,
            showLegend: false,
          },
          height: chartHeight + captionHeight,
        },
        {
          props: {
            showChart: false,
            showLegend: true,
          },
          height: legendHeight,
        },
      ]
    }
    return [
      null,
      {
        props: {
          showChart: true,
          showLegend: false,
        },
        height: chartHeight + captionHeight,
      },
      {
        props: {
          showChart: false,
          showLegend: true,
        },
        height: legendHeight,
      },
    ]
  } else {
    return [
      null,
      {
        props: {
          showChart: true,
          showLegend: false,
        },
        height: chartHeight + captionHeight,
      },
    ]
  }
}

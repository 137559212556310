import { useCallback, useState } from "react"

export default function useOpensMap(initialValue = {}) {
  const [opens, setOpens] = useState(initialValue)
  const toggleOpen = useCallback(
    (id, value) =>
      setOpens((o) => ({
        ...o,
        [id]: value ?? !o[id],
      })),
    []
  )
  return [opens, toggleOpen, setOpens]
}
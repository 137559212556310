import React from "react"
import classNames from "classnames"
import S from "./WpInput.module.scss"

function WpInput(
  {
    invalid = false,
    tiny = false,
    medium = false,
    clear = false,
    pill = false,
    type = "text",
    className = "",
    errorOnBackground = true,
    readOnly = false,
    placeholder = undefined,
    readOnlyPlaceholder = null,
    disabled,
    ...props
  },
  ref
) {
  return (
    <input
      ref={ref}
      type={type}
      disabled={disabled || readOnly}
      placeholder={readOnly ? readOnlyPlaceholder : placeholder}
      className={classNames(S["wp-input"], String(className), {
        "px-4 py-3": !tiny && !medium,
        [S["medium"]]: medium,
        [S["tiny"]]: tiny,
        [S["clear"]]: clear,
        [S["pill"]]: pill,
        "invalid-input": errorOnBackground && invalid,
        "invalid-input-border": !errorOnBackground && invalid,
      })}
      {...props}
    />
  )
}

export default React.forwardRef(WpInput)

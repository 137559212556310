import { rj, useRunRj } from "react-rocketjump"
import api from "../api"

const ProjectHomeRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => () => {
    return api.auth(wpAuth).get("/api/tracker/highlight/projects/")
  },
  computed: {
    projects: "getData",
    loading: "isPending",
    error: "getError",
  },
  mutations: {
    create: rj.mutation.single({
      effect: (wpAuth) => (data) => {
        return api.auth(wpAuth).post(`/api/project/`, data)
      },
      updater: (state, result) => {
        return state
      },
    }),
    createActivity: rj.mutation.single({
      effect: (wpAuth) => (data) => {
        return api
          .auth(wpAuth)
          .post(`/api/activity/`, data)
      },
      updater: (state, result) => {
        return state
      },
    }),
  },
})

export function useProjectHomeList() {
  return useRunRj(ProjectHomeRj)
}

import React from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import { Field, Formik } from "formik"
import { convertErrorToForm } from "../../utils"
import FieldInput from "../../components/fields/FieldInput"
import Button from "../../components/Button"
import WpModalQuota from "../../components/WpModalQuota"

export default function CreateFragModal({
  toggle,
  isOpen,
  onConfirm,
  fragmentType
}) {
  const { t } = useTranslation(["translation", "field", "action"])

  const schema = yup.object().shape({
    title: yup
      .string()
      .required(
        t("field:errors.mandatory", { field: t("field:estimate.title") })
      )
      .max(512, t("field:errors.max", { max_value: 512 })),
  })

  return (
    <WpModalQuota
      checkQuota='templates'
      isOpen={isOpen}
      toggle={toggle}
      title={t(`template.${fragmentType}.create_frag`)}
    >
      <div>
        <p className="mx-5">{t(`template.${fragmentType}.create_frag_message`)}</p>
        <div className="d-flex flex-row align-items-start my-4">
          <div className="d-flex flex-column ml-2 flex-1">
            <Formik
              initialValues={{
                title: ""
              }}
              initialErrors={{
                title: ""
              }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                onConfirm({
                  title: values.title
                }).then(
                  () => {
                    actions.setSubmitting(false)
                    toggle()
                  },
                  (error) => {
                    actions.setSubmitting(false)
                    actions.setErrors(convertErrorToForm(error))
                  }
                )
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <>
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Field
                      name="title"
                      component={FieldInput}
                      placeholder={t("field:template.text.placeholder.title")}
                      label={t("field:template.text.title")}
                    />
                    <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                      <Button size="sm" color="none" onClick={toggle}>
                        {t("action:cancel")}
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        type="submit"
                        className="ml-5"
                        disabled={!formik.isValid}
                      >
                        {t("action:add")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </WpModalQuota>
  )
}

import React, { useCallback, useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { useDrop } from "react-dnd"
import { useDrag } from "react-dnd"
import S from "./DocumentStructure.module.scss"
import Icon from "../../../components/Icon"
import { last, zip } from "lodash"
import { DOC_FRAGMENT_TYPE } from "../../../consts"

const ItemTypes = {
  DOCUMENT_FRAGMENT: "document-fragment",
}

function DraggableFragment({
  frag,
  index,
  moveFrag,
  handleDrop,
  handleCanDrop,
  handleDropCancel,
  className,
  disabled,
  scrollFragIntoView,
  onConfig,
  onAddFrag,
  ...props
}) {
  const ref = useRef(null)
  const [{ canDrop }, drop] = useDrop({
    accept: ItemTypes.DOCUMENT_FRAGMENT,
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
    hover(item, monitor) {
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveFrag(dragIndex, hoverIndex, false)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
    drop(item, monitor) {
      handleDrop()
    },
    canDrop(item, monitor) {
      const dragIndex = item.index
      const hoverIndex = index
      return handleCanDrop(dragIndex, hoverIndex)
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.DOCUMENT_FRAGMENT, id: frag.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (!monitor.didDrop() && handleCanDrop(item.index, index)) {
        handleDrop()
      } else {
        handleDropCancel()
      }
    },
  })

  const [displayAddHandle, setDisplayAddHandle] = useState(false)

  const handleMouseIn = useCallback(() => {
    setDisplayAddHandle(true)
  }, [])

  const handleMouseOut = useCallback(() => {
    setDisplayAddHandle(false)
  }, [])

  preview(drop(ref))

  return (
    <div
      ref={ref}
      className={classNames(
        S["fragment"],
        { [S["dragging"]]: isDragging },
        className
      )}
      onClick={() => {
        if (!isDragging) {
          scrollFragIntoView(frag)
        }
      }}
      onMouseEnter={handleMouseIn}
      onMouseLeave={handleMouseOut}
      {...props}
      style={{
        ...props.style,
        zIndex: 10000 - index,
      }}
    >
      {!isDragging && (
        <>
          <div className={S["fragment-title"]}>{frag.title}</div>
          <div
            className={S["customize-handle"]}
            onClick={(e) => {
              onConfig(e)
            }}
          >
            <Icon name="customize" title="Personalizza" />
          </div>
          {!disabled && (
            <div className={S["drag-handle"]} ref={drag}>
              <Icon name="drag-handle" />
            </div>
          )}
          {!disabled && !isDragging && displayAddHandle && (
            <div className={S["add-after-handle"]} onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              onAddFrag()
            }}>
              <Icon name="plus" />
            </div>
          )}
        </>
      )}
      {isDragging && (
        <div className={S["fragment-title"]}>
          <div className="d-flex flex-row justify-content-start align-items-center">
            <svg width={6} height={6}>
              <circle
                cx={3}
                cy={3}
                r={3}
                className={classNames({
                  [S["fill-primary"]]: canDrop,
                  [S["fill-danger"]]: !canDrop,
                })}
              />
            </svg>
            <div
              className={classNames({
                [S["line-primary"]]: canDrop,
                [S["line-danger"]]: !canDrop,
              })}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default function DocumentStructure({
  document: doc,
  moveFragment,
  initialId = null,
  disabled = false,
  scrollFragIntoView,
  goToConfig,
  onAddFrag,
}) {
  const { t } = useTranslation()

  const [elements, setElements] = useState(doc.fragments)

  const persistRef = useRef(null)

  useEffect(() => {
    setElements(doc.fragments)
  }, [doc.fragments])

  const moveElement = useCallback(
    (dragIndex, hoverIndex, persist) => {
      const element = elements[dragIndex]
      const nextElements = elements.slice(0)
      nextElements.splice(dragIndex, 1)
      const refElementId =
        hoverIndex > 0 ? nextElements[hoverIndex - 1].id : initialId
      nextElements.splice(hoverIndex, 0, element)
      setElements(nextElements)
      persistRef.current = [element.id, refElementId]
    },
    [elements, initialId]
  )

  const handleDragEnd = useCallback(() => {
    if (persistRef.current) {
      moveFragment(doc.id, ...persistRef.current)
    }
    persistRef.current = null
  }, [doc.id, moveFragment])

  const handleCanDrop = useCallback(
    (dragIndex, hoverIndex) => {
      const element = elements[dragIndex]
      const nextElements = elements.slice(0)
      nextElements.splice(dragIndex, 1)
      nextElements.splice(hoverIndex, 0, element)
      if (
        nextElements[0].fragment_type !== DOC_FRAGMENT_TYPE.PAGE_BREAK &&
        last(nextElements).fragment_type !== DOC_FRAGMENT_TYPE.PAGE_BREAK
      ) {
        return !zip(nextElements.slice(1), nextElements.slice(0, -1)).some(
          (item) => {
            return (
              item[0].fragment_type === DOC_FRAGMENT_TYPE.PAGE_BREAK &&
              item[1].fragment_type === DOC_FRAGMENT_TYPE.PAGE_BREAK
            )
          }
        )
      }
      return false
    },
    [elements]
  )

  const handleDropCancel = useCallback(() => {
    persistRef.current = null
    setElements(doc.fragments)
  }, [doc.fragments])

  return (
    <div>
      <h2
        className={classNames(
          "text-uppercase font-size-semibold ml-5 mt-8 mb-3"
        )}
        style={{ fontSize: 16 }}
      >
        {t("documents.overview.structure")}
      </h2>
      {elements.length === 0 && (
        <p className="pl-5">{t("documents.overview.no_content")}</p>
      )}
      {elements.length > 0 && (
        <div>
          {elements.map((frag, i) => (
            <DraggableFragment
              key={frag.id}
              frag={frag}
              index={i}
              moveFrag={moveElement}
              handleDrop={handleDragEnd}
              handleCanDrop={handleCanDrop}
              handleDropCancel={handleDropCancel}
              disabled={disabled}
              scrollFragIntoView={scrollFragIntoView}
              onConfig={(e) => {
                goToConfig(frag, e)
              }}
              onAddFrag={() => {
                onAddFrag(frag.id)
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
}

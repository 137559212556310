import React from "react"
import { DEFAULT_FOOTER_CONFIG } from "../../../consts"

/**
 * @param {{ type: "pagination" | "title" | "code" | "logo", estimate: any, currentPage: number, numPages: number }} props
 */
function FooterBlock({ type, estimate, currentPage, numPages, organization }) {
  const logoUrl = organization.logo

  if (type === "code") {
    return <span>{estimate.code}</span>
  }
  if (type === "title") {
    return <span>{estimate.title}</span>
  }
  if (type === "logo") {
    if (logoUrl) {
      return (
        <img
          alt="Footer"
          src={logoUrl}
          height={30}
          style={{ verticalAlign: "middle" }}
        />
      )
    }
    return null
  }
  if (type === "pagination") {
    return (
      <>
        <span>Pagina </span>
        <span className="pageNumber">{currentPage}</span>
        <span> di </span>
        <span className="totalPages">{numPages}</span>
      </>
    )
  }
  return null
}

export default function Footer({
  estimate,
  organization,
  doc,
  currentPage,
  numPages,
}) {
  const footerConfig = doc?.meta?.footer ?? DEFAULT_FOOTER_CONFIG
  return (
    <div
      style={{
        width: "100%",
        height: "1cm",
        bottom: "0",
        margin: "0",
        paddingLeft: "10mm",
        paddingRight: "10mm",
        justifyContent: "space-between",
        alignItems: "center",
        fontFamily: "Helvetica",
        fontSize: "10px",
        display: "flex",
        color: "#666",
        position: "relative",
        backgroundColor: "#f2f2f2",
        WebkitPrintColorAdjust: "exact",
      }}
    >
      <div>
        <FooterBlock
          type={footerConfig.left}
          estimate={estimate}
          currentPage={currentPage}
          numPages={numPages}
          organization={organization}
        />
      </div>
      <div
        style={{ position: "absolute", left: 0, right: 0, textAlign: "center" }}
      >
        <FooterBlock
          type={footerConfig.middle}
          estimate={estimate}
          currentPage={currentPage}
          numPages={numPages}
          organization={organization}
        />
      </div>
      <div>
        <FooterBlock
          type={footerConfig.right}
          estimate={estimate}
          currentPage={currentPage}
          numPages={numPages}
          organization={organization}
        />
      </div>
    </div>
  )
}

import { Field } from "formik"
import { keyBy, range } from "lodash"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { getDepth } from "react-sortable-tree"
import FieldInput from "../../../../../components/fields/FieldInput"
import FieldRange from "../../../../../components/fields/FieldRange"
import FieldRichText from "../../../../../components/fields/FieldRichText"
import FieldSlider from "../../../../../components/fields/FieldSlider"
import FieldSwitch from "../../../../../components/fields/FieldSwitch"
import EditDataFrame, {
  EditDataSection,
} from "../../EditDataFrame/EditDataFrame"

const EditDataForm = ({
  fullEstimate,
  frag,
  save,
  remove,
  toggle,
  scrollFragIntoView,
}) => {
  const depth = getDepth({ children: fullEstimate.task_tree })

  const { t } = useTranslation(["translation"])
  const ctx = useMemo(() => ({ estimate: fullEstimate }), [fullEstimate])

  const readOnly = fullEstimate.readonly

  const sliderMarks = useMemo(() => {
    const values = range(1, depth + 1).map((v) => {
      if (v < depth) {
        return {
          id: v,
          label: t("tasks.level", { level: v }),
        }
      }
      return {
        id: v,
        label: t("tasks.all"),
      }
    })
    return keyBy(values, "id")
  }, [depth, t])

  const rangeMarks = useMemo(() => {
    const height = fullEstimate.task_tree.length
    const values = range(1, height + 1).map((v) => {
      return {
        id: v,
        label: v,
      }
    })
    return keyBy(values, "id")
  }, [fullEstimate.task_tree.length])

  return (
    <EditDataFrame
      contentType={t("documents.frag.viz.bubble.title")}
      frag={frag}
      save={save}
      remove={remove}
      toggle={toggle}
      scrollFragIntoView={scrollFragIntoView}
      readOnly={readOnly}
      scenarios={fullEstimate.scenarios}
    >
      {(formik) => {
        return (
          <>
            <EditDataSection title={t("field:document.bubble.show_caption")}>
              <Field
                name="data.enableCaption"
                message={t("field:document.bubble.show_caption_message")}
                component={FieldSwitch}
                readOnly={readOnly}
              />
              {formik?.values?.data?.enableCaption && (
                <div className="mt-4">
                  <Field
                    name="data.caption"
                    component={FieldRichText}
                    readOnly={readOnly}
                    placeholder={readOnly ? "" : undefined}
                    enableImages={false}
                    templatingContext={ctx}
                    enableBlockFormatting={false}
                    enableUndoRedo={false}
                    enableFontSize={false}
                    enableLists={false}
                  />
                </div>
              )}
            </EditDataSection>
            <EditDataSection
              title={t("field:document.bubble.task_section_title")}
            >
              {fullEstimate.task_tree.length > 1 && (
                <div className="overflow-x-visible">
                  <p className="mb-2 text-uppercase">
                    {t("field:document.bubble.task_selection")}
                  </p>
                  <div className="mx-3 overflow-x-visible">
                    <Field
                      name="data.visibleTasks"
                      marks={rangeMarks}
                      component={FieldRange}
                      readOnly={readOnly}
                      style={{ width: "100%"}}
                      className="mb-5"
                    />
                  </div>
                </div>
              )}
              {depth > 1 && (
                <div className="mt-4 overflow-x-visible">
                  <p className="mb-2 text-uppercase">
                    {t("field:document.bubble.depth_level")}
                  </p>
                  <div className="mx-3 overflow-x-visible">
                    <Field
                      name="data.maxDepth"
                      marks={sliderMarks}
                      max={depth}
                      component={FieldSlider}
                      readOnly={readOnly}
                      style={{ width: "100%"}}
                      className="mb-5"
                    />
                  </div>
                </div>
              )}
              {/* <div className="mt-4">
                <Field
                  name="data.showPrices"
                  message={t("field:document.bubble.show_prices_message")}
                  component={FieldSwitch}
                  readOnly={readOnly}
                />
              </div> */}
              <div className="mt-4">
                <Field
                  name="data.showLegend"
                  message={t("field:document.bubble.show_legend")}
                  component={FieldSwitch}
                  readOnly={readOnly}
                />
              </div>
            </EditDataSection>
            <EditDataSection title={t("field:document.bubble.height_mm")}>
              <div className="d-flex flex-row justify-content-end align-items-center">
                <div className="flex-1">
                  {t("field:document.bubble.height_mm_message")}
                </div>
                <Field
                  name={"data.height"}
                  component={FieldInput}
                  type="number"
                  min={0}
                  tiny
                  className="text-right"
                  placeholder="auto"
                  readOnly={readOnly}
                />
                <span className="ml-2">{"mm"}</span>
              </div>
            </EditDataSection>
          </>
        )
      }}
    </EditDataFrame>
  )
}

export default EditDataForm

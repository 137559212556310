import { Field } from "formik"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import FieldRichText from "../../../../../components/fields/FieldRichText"
import FieldSwitch from "../../../../../components/fields/FieldSwitch"
import EditDataFrame, {
  EditDataSection,
} from "../../EditDataFrame/EditDataFrame"

export const EditDataForm = ({
  fullEstimate,
  frag,
  save,
  remove,
  toggle,
  scrollFragIntoView,
}) => {
  const { t } = useTranslation(["translation"])
  const readOnly = fullEstimate.readonly
  const ctx = useMemo(() => ({ estimate: fullEstimate }), [fullEstimate])

  return (
    <EditDataFrame
      contentType={t("documents.frag.viz.scenario_resource_viz.title")}
      frag={frag}
      save={save}
      remove={remove}
      toggle={toggle}
      scrollFragIntoView={scrollFragIntoView}
      readOnly={readOnly}
      scenarios={fullEstimate.scenarios}
      enableScenarioSelector={false}
    >
      {(formik) => (
        <>
          <EditDataSection
            title={t("field:document.scenario_resource_viz.show_caption")}
          >
            <div>
              <Field
                name="data.enableCaption"
                message={t(
                  "field:document.scenario_resource_viz.show_caption_message"
                )}
                component={FieldSwitch}
                readOnly={readOnly}
              />
            </div>
            {formik.values.data.enableCaption && (
              <div className="mt-4">
                <Field
                  name="data.caption"
                  component={FieldRichText}
                  readOnly={readOnly}
                  placeholder={readOnly ? "" : undefined}
                  enableImages={false}
                  templatingContext={ctx}
                  enableBlockFormatting={false}
                  enableUndoRedo={false}
                  enableFontSize={false}
                  enableLists={false}
                />
              </div>
            )}
            <div className="mt-4"></div>
          </EditDataSection>
          <EditDataSection>
            <div>
              <Field
                name="data.showLegend"
                message={t(
                  "field:document.scenario_resource_viz.show_legend"
                )}
                component={FieldSwitch}
                readOnly={readOnly}
              />
            </div>
            <div className="mt-4">
              <Field
                name="data.isBaseVisible"
                message={t(
                  "field:document.scenario_resource_viz.show_base_message"
                )}
                component={FieldSwitch}
                readOnly={readOnly}
              />
            </div>
            <div className="mt-4">
              <Field
                name="data.isPriceVisible"
                message={t(
                  "field:document.scenario_resource_viz.show_price_message"
                )}
                component={FieldSwitch}
                readOnly={readOnly}
              />
            </div>
          </EditDataSection>
        </>
      )}
    </EditDataFrame>
  )
}

export default EditDataForm

import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { EstimateLandmarks } from "../../../components/EstimateLandmarks"
import { InteractionList } from "../../../components/InteractionsList"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import { CurrentEstimateStateContext } from "../../../context"
import EstimateBottomBarContent from "../EstimateBottomBarContent"
import EstimateTabBarContent from "../EstimateTabBarContent"

export function EstimateInteractions() {
  const { t } = useTranslation(["translation", "tab", "action"])

  const [{ estimate }] = useContext(CurrentEstimateStateContext)

  return (
    <Layout>
      <div className="container-fluid pt-5 px-page pb-page-bottom">
        {!estimate && <Loader />}
        {estimate && (
          <>
            <h1 className="text-primary font-weight-semibold">
              {t("estimate_interactions.title", { name: estimate.title })}
            </h1>
            <InteractionList
              filter={{ estimate: estimate.id }}
              initial={{ estimate: estimate.id, customer: estimate.customer }}
              customer={estimate.customer}
              disableCreate={!estimate.customer}
              disableEstimateFilter
            />
          </>
        )}
      </div>
      <Layout.TabBar>
        <EstimateTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <EstimateBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <EstimateLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

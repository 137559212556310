import { useEffect, useRef } from "react"
import { useFormikContext } from "formik"

export default function FormAutoSave({ debounceTime = 500, shouldSubmit }) {
  const { values, handleSubmit } = useFormikContext()
  const prevValuesRef = useRef(null)

  const didMount = useRef(false)
  useEffect(() => {
    if (didMount.current) {
      const prevValues = prevValuesRef.current
      let runDebSubmit = true
      if (typeof shouldSubmit === 'function') {
        runDebSubmit = shouldSubmit(values, prevValues)
      }
      prevValuesRef.current = values
      if (!runDebSubmit) {
        return
      }
      const timeoutId = setTimeout(() => {
        handleSubmit()
      }, debounceTime)
      return () => clearTimeout(timeoutId)
    } else {
      prevValuesRef.current = values
      didMount.current = true
    }
  }, [values, handleSubmit, debounceTime, shouldSubmit])

  return null
}

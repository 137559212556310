import { rj, useRunRj } from "react-rocketjump"
import rjPlainList from "react-rocketjump/plugins/plainList"
import api from "../api"

const ProductBundle = rj()
  .plugins(rjPlainList())
  .computed({
    bundles: "getList",
  })
  .effect({
    name: 'ProductBundle',
    effectCaller: rj.configured(),
    effect: (wpAuth) => () => api.auth(wpAuth).get("/api/product-bundle/"),
  })

export default function useProductBundle() {
  return useRunRj(ProductBundle)
}

import React, { Fragment } from "react"
import classNames from "classnames"
import Separator from "../../../../components/Separator"

export default function FixedCostTable({ nodeFixedCosts }) {
  return (
    <div className="flex-fill">
      {nodeFixedCosts.map((nodeFixedCost, i) => {
        return (
          <Fragment key={i}>
            <div className={classNames("d-flex justify-content-start py-2")}>
              <div>{nodeFixedCost.description}</div>
            </div>
            {i !== nodeFixedCosts.length - 1 && (
              <Separator className="mx-3 my-0" />
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

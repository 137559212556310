import React, { useContext, useMemo } from "react"
import dayjs from "dayjs"
import useCustomerPortal from "../../hooks/useCustomerPortal"
import Button from "../../components/Button"
import useProductBundle from "../../hooks/useProductBundle"
import { useTranslation } from "react-i18next"
import useQueryParams from "magik-react-hooks/useRouterQueryParams"
import Alert from "reactstrap/lib/Alert"
import { usePendingPaymentOrders } from "../../hooks/payments"
import { OrganizationDetailContext } from "../../context"
import Table from "../../components/Table/Table"
import { getCurrencyFormatter } from "../../hooks/useCurrencyFormatter"
import { getStripe } from "../../utils"
import { useAuthUser } from "use-eazy-auth"

function ProductBundleCard({ bundle }) {
  const { t } = useTranslation()
  // TODO: Maybe writw an helper...
  const features = [
    bundle.resources === -1
      ? t("plans.unlimited_resources")
      : t("plans.limited_resources", { limit: bundle.resources }),
    bundle.estimates === -1
      ? t("plans.unlimited_estimates")
      : t("plans.limited_estimates", { limit: bundle.estimates }),
    bundle.templates === -1
      ? t("plans.unlimited_templates")
      : t("plans.limited_templates", { limit: bundle.templates }),
    bundle.projects === -1
      ? t("plans.unlimited_projects")
      : t("plans.limited_projects", { limit: bundle.projects }),
    bundle.collaborators === -1
      ? t("plans.unlimited_collaborators")
      : t("plans.limited_collaborators", { limit: bundle.collaborators }),
  ]
  return (
    <div className="border border-primary rounded p-3">
      <h2>{bundle.name}</h2>
      <div>
        <b>From</b> <i>{dayjs(bundle.valid_from).format("DD-MM-YYYY HH:mm")}</i>{" "}
        <b>To</b> <i>{dayjs(bundle.valid_to).format("DD-MM-YYYY HH:mm")}</i>
      </div>
      <div className="mt-2">
        <ul>
          {features.map((feature, i) => (
            <li key={i}>{feature}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

function PendingPayments() {
  const [{ payments: pendingPayments }] = usePendingPaymentOrders()

  const columns = useMemo(
    () => [
      {
        label: "Piano",
        name: "product_name",
        size: 3,
      },
      {
        label: "Prezzo",
        size: 3,
        render: (price, col, row) =>
          getCurrencyFormatter(row.currency).format(row.price),
      },
      {
        label: "Data",
        name: "created_at",
        size: 3,
        render: (date, col, row) => dayjs(date).format("DD-MM-YYYY HH:mm"),
      },
      {
        label: "Paga",
        size: 3,
        render: (date, col, row) => {
          if (row.invoice_url) {
            return (
              <Button
                className="no-link"
                Component={"a"}
                href={row.invoice_url}
                color="dark"
              >
                Paga
              </Button>
            )
          }
          return (
            <Button
              onClick={() => {
                getStripe().then((stripe) => {
                  stripe.redirectToCheckout({
                    sessionId: row.checkout_session_id,
                  })
                })
              }}
              color="dark"
            >
              Paga
            </Button>
          )
        },
      },
    ],
    []
  )

  return (
    <div className="mt-4">
      <Alert color="warning">Ci sono alcuni pagamenti in sospeso.</Alert>
      <Table columns={columns} data={pendingPayments} />
    </div>
  )
}

export default function Billing() {
  const [{ organization }] = useContext(OrganizationDetailContext)
  const { user } = useAuthUser()
  const { t } = useTranslation()
  const getCustomerPortalUrl = useCustomerPortal()
  const [{ bundles }] = useProductBundle()
  const [{ outcome, product_name: productNameBuyed }] = useQueryParams()

  if (user.personal_organization === organization.id) {
    return (
      <div className="px-page mt-4">
        <Alert color="info">{t("billing.personal_organization_free")}</Alert>

        <div className="mt-5">
          {bundles &&
            bundles.map((bundle) => (
              <ProductBundleCard key={bundle.id} bundle={bundle} />
            ))}
        </div>
      </div>
    )
  }

  return (
    <div className="px-page mt-4">
      {outcome === "success" && productNameBuyed && (
        <Alert color="success">
          {t("billing.product_success_buy", { product: productNameBuyed })}
        </Alert>
      )}
      {outcome === "failure" && productNameBuyed && (
        <Alert color="danger">
          {t("billing.product_failure_buy", { product: productNameBuyed })}
        </Alert>
      )}
      <Button
        onClick={() => {
          const returnUrl = window.location.href
          getCustomerPortalUrl(returnUrl, (portal) => {
            window.location.href = portal.url
          })
        }}
      >
        {t("billing.customer_portal")}
      </Button>

      {organization.has_pending_payments && <PendingPayments />}

      <div className="mt-5">
        {bundles &&
          bundles.map((bundle) => (
            <ProductBundleCard key={bundle.id} bundle={bundle} />
          ))}
      </div>
    </div>
  )
}

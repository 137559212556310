import { Field, Formik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { BsCalendar2Check } from "react-icons/bs"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { FormText } from "reactstrap"
import Button from "../../../components/Button"
import { CRMLandmarks } from "../../../components/CRMLandmarks"
import { CustomerSelector } from "../../../components/CustomerSelector"
import EstimateSelector from "../../../components/EstimateSelector/EstimateSelector"
import FieldDropdown from "../../../components/fields/FieldDropdown"
import FieldInput from "../../../components/fields/FieldInput"
import FieldTextarea from "../../../components/fields/FieldTextarea"
import FieldYesNo from "../../../components/fields/FieldYesNo"
import { FlowGraph } from "../../../components/FlowGraph"
import { useInteractionTools } from "../../../components/InteractionModalContext"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader"
import { useIncomingRequest, useIncomingRequestFlow } from "../../../hooks/incomingRequest"
import useWpHistoryMethods from "../../../hooks/useWpHistoryMethods"
import CustomerBottomBarContent from "../CustomerBottomBarContent"
import CustomerTabBarContent from "../CustomerTabBarContent"

function fixNullOnEmptyString(value, fields) {
  const result = { ...value }
  for (const field of fields) {
    if (result[field] === "") {
      result[field] = null
    }
  }
  return result
}

export function CustomerRequestDetail() {
  const { requestId, id: custId } = useParams()
  const history = useWpHistoryMethods()
  const { t } = useTranslation()

  const { createInteraction } = useInteractionTools()

  const [{ request }, { update }] = useIncomingRequest(requestId)
  const [{ graph }, { run: reloadGraph }] = useIncomingRequestFlow(requestId)

  const isReadonly = false

  return (
    <Layout>
      <div className="container pt-5 px-half-page pb-page-bottom">
        {!request && <Loader />}
        {request && (
          <>
            <Formik
              initialValues={request}
              onSubmit={(values, actions) => {
                const { id, ...data } = values
                return update
                  .onFailure(() => {
                    toast.error(t("customer_incoming_request.save_error"))
                  })
                  .onSuccess((result) => {
                    toast.success(t("customer_incoming_request.save_success"))
                    if (result.customer !== custId) {
                      history.replace(`/customers/${result.customer}/requests/${result.id}`)
                    }
                    reloadGraph(requestId)
                  })
                  .asPromise(id, fixNullOnEmptyString(data, ["specs_ready_at", "estimate_sent_at", "canceled_at"]))
              }}
              enableReinitialize={true}
            >
              {(formik) => {
                let statusComputed = "pending"
                if (formik.values.canceled_at) {
                  statusComputed = "dismissed"
                } else {
                  if (formik.values.estimate_sent_at) {
                    statusComputed = "completed"
                  } else {
                    if (formik.values.specs_ready_at) {
                      statusComputed = "waiting-estimate"
                    }
                  }
                }
                return (
                  <form autoComplete="off" onSubmit={formik.handleSubmit}>
                    <div className="d-flex flex-column">
                      <h2 className="text-center text-uppercase mb-5 font-weight-semibold">{t("project_info.title")}</h2>
                      <div className="d-flex flex-row justify-content-center">
                        <div className="flex-1 pr-5">
                          <Field
                            name="code"
                            label={t("field:incoming_request.code")}
                            placeholder={t("field:incoming_request.placeholder.code")}
                            component={FieldInput}
                            readOnly={true}
                          />
                          <FormText>{t("field:incoming_request.hints.code")}</FormText>
                        </div>
                        <div className="flex-1 pl-5">
                          <div className="d-flex flex-row">
                            <div className="flex-1 pr-5">
                              <Field
                                name="customer"
                                label={t("field:incoming_request.customer")}
                                placeholder={t("field:incoming_request.placeholder.customer")}
                                component={CustomerSelector.Field}
                                readOnly={isReadonly}
                                className="px-4 py-3"
                              />
                            </div>
                            <div className="pl-5">
                              <Field
                                name="new_customer"
                                label={t("field:incoming_request.new_customer")}
                                component={FieldYesNo}
                                readOnly={isReadonly}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1">
                          <Field
                            name="title"
                            label={t("field:incoming_request.title")}
                            placeholder={t("field:incoming_request.placeholder.title")}
                            component={FieldInput}
                            readOnly={isReadonly}
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1 d-flex flex-row">
                          <div className="flex-1">
                            <Field
                              name="description"
                              label={t("field:incoming_request.description")}
                              placeholder={t("field:incoming_request.placeholder.description")}
                              component={FieldTextarea}
                              readOnly={isReadonly}
                              rows={8}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1 pr-5">
                          <div className="d-flex flex-row">
                            <div className="flex-1 pr-5">
                              <Field
                                name="created_at"
                                label={t("field:incoming_request.created_at")}
                                placeholder={t("field:incoming_request.placeholder.created_at")}
                                component={FieldInput}
                                readOnly={isReadonly}
                                type="date"
                              />
                            </div>
                            <div className="flex-1 pl-5">
                              <Field
                                name="specs_ready_at"
                                label={t("field:incoming_request.specs_ready_at")}
                                placeholder={t("field:incoming_request.placeholder.specs_ready_at")}
                                component={FieldInput}
                                readOnly={isReadonly}
                                type="date"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex-1 pl-5">
                          <div className="d-flex flex-row">
                            <div className="flex-1 pr-5">
                              <Field
                                name="estimate_sent_at"
                                label={t("field:incoming_request.estimate_sent_at")}
                                placeholder={t("field:incoming_request.placeholder.estimate_sent_at")}
                                component={FieldInput}
                                readOnly={isReadonly}
                                type="date"
                              />
                            </div>
                            <div className="flex-1 pl-5">
                              <Field
                                name="canceled_at"
                                label={t("field:incoming_request.canceled_at")}
                                placeholder={t("field:incoming_request.placeholder.canceled_at")}
                                component={FieldInput}
                                readOnly={isReadonly}
                                type="date"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex flex-row justify-content-center mt-6">
                        <div className="flex-1 pr-5">
                          <FieldDropdown
                            field={{ name: "status", value: statusComputed }}
                            label={t("field:incoming_request.status")}
                            options={["pending", "waiting-estimate", "completed", "dismissed"].map((x) => ({
                              value: x,
                              label: t(`enums:incoming_request_status.${x}`),
                            }))}
                            readOnly={true}
                            form={formik}
                          />
                        </div>
                        <div className="flex-1 pl-5">
                          {(statusComputed === "completed" || statusComputed === "waiting-estimate") && (
                            <Field
                              name="resulting_estimate"
                              label={t("field:incoming_request.resulting_estimate")}
                              component={EstimateSelector.Field}
                              containerClassName={"w-100 mb-5"}
                              className="py-3 px-4"
                              clearable
                              defaultFilters={{ customer: request?.customer }}
                            />
                          )}
                          {statusComputed === "dismissed" && (
                            <Field
                              name="dismiss_reason"
                              label={t("field:incoming_request.dismiss_reason")}
                              component={FieldInput}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {graph && (
                      <div className="d-flex flex-row justify-content-center mt-9">
                        <FlowGraph width={1000} nodes={graph[0]} arcs={graph[1]} current={`r${request.id}`}></FlowGraph>
                      </div>
                    )}

                    <div className="text-center mt-6">
                      <Button
                        onClick={() => {
                          formik.resetForm()
                        }}
                        className="mx-4"
                      >
                        {t("action:cancel")}
                      </Button>
                      <Button type="submit" className="mx-4">
                        {t("action:save")}
                      </Button>
                    </div>

                    <div style={{ height: 100 }}>{/* Make some room to allow dropdown to properly open */}</div>
                  </form>
                )
              }}
            </Formik>
          </>
        )}
      </div>

      <Layout.LeftBar>
        {request && (
          <div className="d-flex flex-column justify-content-between align-items-center h-100 mt-9">
            <Button
              color="primary"
              size="lg"
              rounded
              onClick={() => {
                createInteraction({ customer: request.customer, request: request.id }, ["request"])
              }}
              className="ml-4"
            >
              <BsCalendar2Check style={{ height: 18 }} className="pointer" title={t("action:new_task")} />
            </Button>
          </div>
        )}
      </Layout.LeftBar>

      <Layout.TabBar>
        <CustomerTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <CustomerBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <CRMLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

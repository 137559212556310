import React, { useMemo, useState } from "react"
import { flattenCutTree } from "../../EstimateTasksTree/TaskTable/utils"
import S from "./EstimateTreeMap.module.scss"
import { keyBy, range } from "lodash"
import { getDepth } from "react-sortable-tree"
import { useTranslation } from "react-i18next"
import Slider from "rc-slider"
import TreeMapGroup, { computeDomain } from "./TreeMapGroup"
import classNames from "classnames"
import useCurrentEstimateOption from "../../../../hooks/useCurrentEstimateOption"
import MultiLineText from "../../../../components/MultiLineText"
import { ColorSchemeAssociative } from "../colorScheme"
import ReactResizeDetector from "react-resize-detector"
import { computeResourcesTable, getTotalFixedCost, sliceExtent } from "../../treeOperations"
import useCurrencyFormatter from "../../../../hooks/useCurrencyFormatter"
import { useLocalizer } from "../../../../hooks/useLocalizer"

const ENTRY_TYPE_RESOURCE = "resource"
const ENTRY_TYPE_FIXED_COST = "fixed-cost"

function transformTasksForTreeMap(rootedTaskTree, maxDepth) {
  const flattened = flattenCutTree([rootedTaskTree], maxDepth)

  const tasksWithSomeValue = flattened.filter((task) => {
    return task.resources.length > 0 || task.fixed_costs.length > 0
  })
  return tasksWithSomeValue.map((task) => {
    return {
      title: task.id === rootedTaskTree.id ? "" : task.title,
      items: [
        ...task.resources.map((res) => ({
          ...res,
          _entryType: ENTRY_TYPE_RESOURCE,
          _treeMapSize: parseFloat(res.unitary_price) * parseFloat(res.size),
        })),
        ...task.fixed_costs.map((fixedCost) => ({
          ...fixedCost,
          _entryType: ENTRY_TYPE_FIXED_COST,
          _treeMapSize: parseFloat(fixedCost.price),
        })),
      ],
    }
  })
}

function WrapperComponent({ width, height, top, left }) {
  return (
    <rect
      x={left + 1}
      y={top + 1}
      width={width - 2}
      height={height - 2}
      strokeWidth={2}
      className={S["main-task-element"]}
    />
  )
}

/**
 *
 * @param {{
 *   width: number,
 *   height: number,
 *   top: number,
 *   left: number,
 *   isLast: boolean,
 *   isFirst: boolean,
 *   item: {
 *     cost_unit: string,
 *     priced_resource_id: number,
 *     resource_id: number,
 *     size: number,
 *     unitary_cost: string,
 *     unitary_price: string,
 *   },
 *   resourcesTable: Object.<number, any>,
 *   colorScheme: ColorSchemeAssociative,
 *   showPrices: boolean,
 *   padding: number
 * }} props\
 */
function NodeComponent({
  width,
  height,
  top,
  left,
  isLast,
  isFirst,
  item,
  resourcesTable,
  colorScheme,
  showPrices,
  padding = 0,
}) {
  const offset = colorScheme.getOffset(item.resource_id ?? 0)
  const code = String.fromCharCode("a".charCodeAt(0) + offset)

  return (
    <>
      <rect
        x={left + padding}
        y={top + padding}
        width={width - padding * 2}
        height={height - padding * 2}
        className={classNames(
          S["resource-task-element-fill"],
          colorScheme.fill(0, item.resource_id ?? 0),
          colorScheme.stroke(0, item.resource_id ?? 0)
        )}
      />
      <MultiLineText
        className={S["resource-description"]}
        width={0.8 * (width - 16)}
        x={left + width / 2}
        y={top + height / 2}
        textAnchor="middle"
        verticalAnchor="middle"
        maxLines={1}
      >
        {`(${code})`}
      </MultiLineText>
    </>
  )
}

function NodeTextComponent({ top, left, width, item, height }) {
  return (
    <MultiLineText
      width={width}
      x={left + 8}
      y={top + height / 2}
      verticalAnchor="middle"
      textAnchor="start"
      maxLines={2}
    >
      {item.title}
    </MultiLineText>
  )
}

function WrapperTextComponent({ top, left, width, item, height }) {
  return (
    <MultiLineText
      width={width}
      x={left + width - 8}
      y={top + height / 2}
      verticalAnchor="middle"
      textAnchor="end"
      fontSize={15}
      maxLines={2}
    >
      {item.title}
    </MultiLineText>
  )
}

export function EstimateTreeMap({
  estimate,
  showPrices,
  maxDepth,
  visibleTasks = null,
  width,
  className,
}) {
  const resourcesTable = useMemo(() => keyBy(estimate.resources, "id"), [
    estimate.resources,
  ])

  const colorScheme = useMemo(() => {
    return new ColorSchemeAssociative("sequential", 1, [
      ...estimate.resources.map((item) => item.id),
      0,
    ])
  }, [estimate.resources])

  const cutTree = sliceExtent(estimate.task_tree, visibleTasks)

  const extent = computeDomain(
    estimate.task_tree
      .map((taskGroup) => transformTasksForTreeMap(taskGroup, maxDepth))
      .filter((data) => data !== null),
    "_treeMapSize"
  )

  const dataset = cutTree
    .map((taskGroup) => transformTasksForTreeMap(taskGroup, maxDepth))
    .filter((data) => data !== null)
  return (
    <div className={className}>
      <TreeMapGroup
        width={width}
        dataset={dataset}
        minRowHeight={50}
        maxRowHeight={150}
        padding={3}
        minDomain={extent[0]}
        maxDomain={extent[1]}
        wrapperComponent={WrapperComponent}
        nodeComponent={(props) => (
          <NodeComponent
            {...props}
            colorScheme={colorScheme}
            resourcesTable={resourcesTable}
            showPrices={showPrices}
            padding={2}
          />
        )}
        metric="_treeMapSize"
        textSpaceLeft={100}
        textSpaceRight={100}
        wrapperTextComponent={({ index, ...props }) => (
          <WrapperTextComponent {...props} item={cutTree[index]} />
        )}
        nodeTextComponent={NodeTextComponent}
        eachWrappedIn={(treeMap, i) => (
          <div
            key={i}
            className={classNames("pb-4", "__pager-node", S["atomic-block"])}
          >
            {treeMap}
          </div>
        )}
      />
    </div>
  )
}

export function TreeMapLegendVertical({ estimate, columns = 1 }) {
  const colorScheme = useMemo(() => {
    return new ColorSchemeAssociative("sequential", 1, [
      ...estimate.resources.map((item) => item.id),
      0,
    ])
  }, [estimate.resources])

  const { t, i18n } = useTranslation()
  const l = useLocalizer(i18n.language)
  const currencyFmt = useCurrencyFormatter()

  const resourceExtraInfo = keyBy(
    computeResourcesTable(estimate),
    "resource_id"
  )

  const fixedCostsCode = String.fromCharCode(
    "a".charCodeAt(0) + estimate.resources.length
  )

  const resourcesPerCol = Math.ceil((estimate.resources.length + 1) / columns)

  return (
    <div
      className="d-flex flex-row align-items-start"
      style={{ marginLeft: -10, marginRight: -10 }}
    >
      {range(columns).map((n) => (
        <div style={{ paddingLeft: 10, paddingRight: 10 }} className="flex-1" key={n}>
          <table className={S["legend"]}>
            <thead>
              <tr>
                <th className="text-uppercase font-weight-semibold">
                  {t("viz.legend")}
                </th>
                <th className="text-uppercase font-weight-semibold text-right">
                  {t("viz.price")}
                </th>
              </tr>
            </thead>
            <tbody>
              {estimate.resources
                .slice(n * resourcesPerCol, (n + 1) * resourcesPerCol)
                .map((resource, i) => {
                  const code = String.fromCharCode(
                    "a".charCodeAt(0) + i + n * resourcesPerCol
                  )
                  return (
                    <tr key={resource.id}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div
                            className={classNames(
                              S["legend-square"],
                              colorScheme.border(0, resource.id),
                              colorScheme.background(0, resource.id)
                            )}
                          />
                          {`(${code}) ${resource[l`name`] || resource.name}`}
                        </div>
                      </td>
                      <td className="text-right">
                        {currencyFmt.format(
                          resourceExtraInfo[resource.id].total_price
                        )}
                      </td>
                    </tr>
                  )
                })}
              {n === columns - 1 && (
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <div
                        className={classNames(
                          S["legend-square"],
                          colorScheme.border(1, 0),
                          colorScheme.background(1, 0)
                        )}
                      />
                      {`(${fixedCostsCode}) ${t("viz.fixed_costs")}`}
                    </div>
                  </td>
                  <td className="text-right">
                    {currencyFmt.format(
                      getTotalFixedCost({ children: estimate.task_tree })
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  )
}

export default function EstimateTreeMapViz({ estimate }) {
  const { t } = useTranslation()

  const depth = useMemo(() => getDepth({ children: estimate.task_tree }), [
    estimate.task_tree,
  ])
  const marks = useMemo(() => {
    const values = range(1, depth + 1).map((v) => {
      if (v < depth) {
        return {
          id: v,
          label: t("tasks.level", { level: v }),
        }
      }
      return {
        id: v,
        label: t("tasks.all"),
      }
    })
    return keyBy(values, "id")
  }, [depth, t])

  const [maxDepth, setMaxDepth] = useState(depth)
  const [isCostVisible, setCostVisible] = useCurrentEstimateOption(
    "viz.treeMap.isCostVisible",
    false
  )

  return (
    <>
      <div className="mt-8">
        <p>{t("tasks.treemap_description")}</p>
      </div>
      <div className="d-flex flex-row align-items-center justify-content-between pb-4">
        <div>
          {depth > 1 && (
            <Slider
              min={1}
              marks={marks}
              step={null}
              max={depth}
              onChange={setMaxDepth}
              defaultValue={depth}
              style={{ width: 90 * (depth - 1) }}
              trackStyle={{ backgroundColor: "var(--primary)" }}
              handleStyle={{
                border: "2px solid var(--primary)",
                height: 16,
                width: 16,
                marginTop: -6,
              }}
              activeDotStyle={{
                borderColor: "transparent",
                backgroundColor: "var(--primary)",
              }}
            />
          )}
        </div>
        <div className="d-flex flex-row align-items-center">
          <input
            type="checkbox"
            checked={isCostVisible}
            onChange={(e) => setCostVisible(e.target.checked)}
          />
          <label className="mb-0 mx-3">{t("tasks.show_cost")}</label>
        </div>
      </div>
      <div
        style={{ width: "calc(100% - 250px)", float: "left" }}
        className="mt-8"
      ></div>
      <div className="d-flex justify-content-center mt-8">
        <div className="flex-4">
          <ReactResizeDetector handleWidth>
            {({ width }) => (
              <EstimateTreeMap
                estimate={estimate}
                showPrices={isCostVisible}
                maxDepth={maxDepth}
                width={(width ?? 200) - 100}
              />
            )}
          </ReactResizeDetector>
        </div>
        <div className="flex-1">
          <div style={{ position: "sticky", top: 110, zIndex: 1 }}>
            <TreeMapLegendVertical estimate={estimate} />
          </div>
        </div>
      </div>
    </>
  )
}

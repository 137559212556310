import classNames from "classnames"
import dayjs from "dayjs"
import { truncate } from "lodash"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import Button from "../../../components/Button"
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal"
import Icon from "../../../components/Icon"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import LongBorder from "../../../components/LongBorder"
import Pagination from "../../../components/Pagination/Pagination"
import Table from "../../../components/Table"
import { TrackerLandmarks } from "../../../components/TrackerLandmarks"
import { ProjectContext } from "../../../context"
import { useBills } from "../../../hooks/bill"
import useModalTrigger from "../../../hooks/useModalTrigger"
import ProjectBottomBarContent from "../ProjectBottomBarContent"
import ProjectTabBarContent from "../ProjectTabBarContent"
import { EditBillModal } from "./EditBillModal"

export function Bills() {
  const { t } = useTranslation(["translation", "tab", "action"])

  const [
    ,
    setQueryParams,
    { page: debPage, search: debSearch },
    ,
  ] = useQueryParams({
    page: {
      encode: (intValue) => intValue.toString(10),
      decode: (strValue) => (strValue ? parseInt(strValue, 10) : 1),
    },
  })

  const [{ project }] = useContext(ProjectContext)

  const filter = useMemo(
    () => ({
      project: project.id,
      page: debPage,
      search: debSearch,
    }),
    [debPage, debSearch, project.id]
  )

  const [
    { bills, pageCount, hasNext, hasPrev },
    { create, update, remove, run: refresh },
  ] = useBills(filter)

  const [editModalState, editModalActions] = useModalTrigger()
  const [removeModalState, removeModalActions] = useModalTrigger()

  const columns = useMemo(() => {
    return [
      {
        label: t("field:bill.date"),
        name: "date",
        size: 2,
        render: (selectedDatum, index, datum) => {
          return <span>{dayjs(selectedDatum).format("DD/MM/YYYY")}</span>
        },
      },
      {
        label: t("field:bill.amount"),
        name: "amount",
        size: 2,
        render: (selectedDatum, index, datum) => {
          const f = parseFloat(selectedDatum)
          if (!isNaN(f)) {
            return <span>{f.toFixed(2) + " €"}</span>
          } else {
            return <span />
          }
        },
      },
      {
        label: t("field:bill.description"),
        name: "description",
        size: 5,
      },
      {
        label: t("field:bill.code"),
        name: "code",
        size: 2,
      },
      {
        label: t("actions"),
        name: "actions",
        size: 1,
        render: (selectedDatum, colName, datum) => {
          return (
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <div className={"action-icon-container-primary-hover"}>
                <Icon
                  name="edit"
                  title={t("action:edit_bill")}
                  className="pointer text-dark"
                  onClick={() => {
                    editModalActions.open(datum)
                  }}
                />
              </div>
              <div className={"action-icon-container-primary-hover"}>
                <UncontrolledDropdown>
                  <DropdownToggle caret={false} tag={"span"}>
                    <Icon
                      role="button"
                      name="vdots"
                      className="text-dark pointer"
                      title={t("action:other_options")}
                      placement="right"
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    right
                    modifiers={{ offset: { offset: "0, 12" } }}
                    className="border-primary"
                  >
                    {!datum.archived && (
                      <DropdownItem
                        className={classNames(
                          "text-capitalize px-0",
                          "dropdown-item-primary-active"
                        )}
                      >
                        <div
                          className="d-flex flex-row align-items-center mx-4"
                          onClick={() => {
                            removeModalActions.open(datum)
                          }}
                        >
                          <Icon
                            name="delete"
                            className={classNames("pointer mr-4")}
                          />
                          <span>{t("action:delete")}</span>
                        </div>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          )
        },
      },
    ]
  }, [editModalActions, removeModalActions, t])

  return (
    <Layout>
      <div className="container-fluid pt-5 px-page pb-page-bottom">
        <div className="d-flex flex-row justify-content-between align-items-center mb-6">
          <h1 className="text-primary">{t("tab:project.bills")}</h1>
          <div>
            <Button
              className="d-inline-flex align-items-center px-5 mr-5"
              onClick={() => {
                editModalActions.open({ project: project.id })
              }}
            >
              <Icon
                name="plus-rounded"
                className="mr-2"
                height={16}
                width={16}
              />
              {t("action:new_bill")}
            </Button>
          </div>
        </div>

        {(!project || !bills) && <Loader />}
        {project && bills && (
          <>
            <LongBorder topBar="tabbar" />
            <Table
              data={bills}
              columns={columns}
              stickyHeader
              stickyTopBar="tabbar"
              className="pb-8"
              valueCheckFn={(val) => val !== undefined && val !== null}
            />
            {bills.length > 0 && (
              <Pagination
                current={debPage}
                pageCount={pageCount}
                goToPage={(page) => {
                  setQueryParams({ page })
                  window.scrollTo(0, 10)
                }}
                hasNext={hasNext}
                hasPrev={hasPrev}
              />
            )}
          </>
        )}
        {editModalState.value && (
          <EditBillModal
            isOpen={editModalState.isOpen}
            bill={editModalState.value}
            onClosed={editModalActions.onClose}
            toggle={editModalActions.toggle}
            onSave={(result) => {
              if (result.id) {
                return update
                  .onSuccess(() => {
                    editModalActions.close()
                  })
                  .asPromise(result.id, result)
              } else {
                return create
                  .onSuccess(() => {
                    editModalActions.close()
                  })
                  .asPromise(result)
              }
            }}
          />
        )}
        {removeModalState.value && (
          <ConfirmDeleteModal
            isOpen={removeModalState.isOpen}
            toggle={removeModalActions.toggle}
            onClosed={removeModalActions.onClose}
            item={truncate(removeModalState.value.description, 20)}
            onConfirm={() => {
              remove
                .onSuccess(() => {
                  refresh(filter)
                })
                .run(removeModalState.value.id)
            }}
          />
        )}
      </div>
      <Layout.TabBar>
        <ProjectTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <ProjectBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

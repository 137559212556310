import React from "react"
import classNames from "classnames"
import S from "./FilterButton.module.scss"

export default function FilterButton({
  selected = false,
  color = "primary",
  className,
  children,
  ...props
}) {
  return (
    <button
      className={classNames(
        className,
        S["wp-filter-btn"],
        S["wp-filter-btn-" + color],
        { [S["wp-filter-btn-selected"]]: selected }
      )}
      {...props}
    >
      {children}
    </button>
  )
}

import React from "react"
import ReactCountryFlag from "react-country-flag"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import S from "./InlineLangSelector.module.scss"
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { get, map } from "lodash"
import Separator from "../Separator/Separator"

const langs = {
  it: {
    caption: "Italiano",
    countryCode: "IT",
  },
  en: {
    caption: "English",
    countryCode: "GB",
  },
}

export default function InlineLangSelector({ className, ...props }) {
  const { i18n } = useTranslation()

  const lang = get(langs, i18n.language, langs.en)

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        caret={false}
        tag={"span"}
        className={S["dropdown"]}
      >
        <span className="d-inline-flex align-items-center">
          <ReactCountryFlag
            svg
            countryCode={lang.countryCode}
            className="mr-2"
          />
          {lang.caption}
        </span>
      </DropdownToggle>
      <DropdownMenu right positionFixed className="border-primary">
        {map(langs, ({ caption, countryCode }, langCode) => (
          <React.Fragment key={langCode}>
            <DropdownItem
              key={langCode}
              className={classNames("dropdown-item-primary-active")}
              onClick={() => {
                i18n.changeLanguage(langCode)
              }}
            >
              <span className="d-inline-flex align-items-center">
                <ReactCountryFlag
                  svg
                  countryCode={countryCode}
                  className="mr-2"
                />
                {caption}
              </span>
            </DropdownItem>
            <Separator
              className={classNames("my-0 mx-4 border-primary", S["hide-last"])}
            />
          </React.Fragment>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { DropdownItemCreateInteraction } from "../../components/DropdownItemCreateInteraction"
import Icon from "../../components/Icon"
import { ProjectContext } from "../../context"

export default function EstimateBottomBarContent() {
  const { t } = useTranslation(["translation"])

  const [{ project }] = useContext(ProjectContext)

  if (!project) {
    return null
  }

  return (
    <div className="d-flex flex-row justify-content-between align-items-stretch  w-100 h-100">
      <div className="h-100 py-5 px-page text-uppercase font-weight-semibold d-flex align-items-center">
        <div className="align-self-center">
          <UncontrolledDropdown
            disabled={project === null}
            direction="up"
            className={"action-icon-container-primary-hover"}
          >
            <DropdownToggle caret={false} tag={"span"}>
              <Icon
                role="button"
                name="vdots"
                className="text-dark pointer"
                title={t("action:other_options")}
                placement="right"
              />
            </DropdownToggle>
            <DropdownMenu
              modifiers={{ offset: { offset: "0, 12" } }}
              className="border-primary"
            >
              <DropdownItemCreateInteraction
                disabled={!project.customer}
                initialValues={{
                  project: project.id,
                  customer: project.customer,
                }}
                lockedFields={["project"]}
              />
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        {t("project.project", { name: project.name })}
      </div>
    </div>
  )
}

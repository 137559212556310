import React, { useCallback, useContext, useMemo, useState } from "react"
import keyBy from "lodash/keyBy"
import { useEstimateResources } from "../../../hooks/estimateTree"
import { Redirect, Route, useLocation, useRouteMatch } from "react-router-dom"
import Layout from "../../../components/Layout"
import TaskTreePreview from "./TaskTreePreview"
import AddTaskModalForm from "./modals/AddTaskModalForm"
import Button from "../../../components/Button"
import { makeTaskTreeMoveOptions, makeTaskTreeOptions } from "./treeUtils"
import { useTranslation } from "react-i18next"
import Icon from "../../../components/Icon"
import ChangeViewButton from "../../../components/ChangeViewButton"
import { TaskOperationsContext } from "./context"
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import MoveTaskModalForm from "./modals/MoveTaskModalForm"
import S from "./EstimateTasksTree.module.scss"
import classNames from "classnames"
import EmptyTasks from "./TreeView/EmptyTasks"
import TreeView from "./TreeView/TreeView"
import TableView from "./TableView/TableView"
import useModalTrigger from "../../../hooks/useModalTrigger"
import EditResoucePricesModalForm from "./modals/EditResoucePricesModalForm"
import EstimateTabBarContent from "../EstimateTabBarContent"
import EstimateBottomBarContent from "../EstimateBottomBarContent"
import {
  CurrentEstimateStateContext,
  TasksOpensContext,
} from "../../../context"
import { useWpClipboardValue } from "../../../hooks/wpClipboard"
import PasteTaskModalForm from "./modals/PasteTaskModalForm"
import EstimateScenarios from "../EstimateScenarios/EstimateScenarios"
import { notifyError } from "../../../utils"
import { EstimateLandmarks } from "../../../components/EstimateLandmarks"

export default function EstimateTasksTree({ match }) {
  const { t } = useTranslation(["translation", "tab", "action"])

  const [
    { estimate, fullEstimate, saving, resettingResource, updatingAllocations, selectedScenarios },
    {
      updateTaskTree,
      moveTask,
      removeTask,
      addTask,
      overrideResource,
      resetResource,
      collapseAllTasksTree,
      pasteTask,
      ...estimateTools
    },
  ] = useContext(CurrentEstimateStateContext)

  const [opens, toggleOpen] = useContext(TasksOpensContext)

  const location = useLocation()
  const initialOpen = location?.state?.initialOpen ?? null

  const handleOnEditTaskClickTable = useCallback(
    (task) => {
      toggleOpen(task.id, true)
    },
    [toggleOpen]
  )

  const [{ resources }] = useEstimateResources(estimate)
  const estimateResources = useMemo(() => estimate?.resources ?? [], [
    estimate?.resources,
  ])
  const allResources = useMemo(() => [...resources, ...estimateResources], [
    estimateResources,
    resources,
  ])
  const resourcesById = useMemo(() => keyBy(allResources, "id"), [allResources])

  const [modalAddTask, modalAddTaskActions] = useModalTrigger()
  const [modalMoveTask, modalMoveTaskActions] = useModalTrigger()
  const [modalDeleteTask, modalDeleteTaskActions] = useModalTrigger()
  const [modalEditResource, modalEditResourceActions] = useModalTrigger()

  const [isOverviewOpen, setOverviewOpen] = useState(false)

  const taskTree = estimate?.task_tree
  const taskTreeOptions = useMemo(() => {
    if (taskTree) {
      return makeTaskTreeOptions(taskTree)
    }
    return []
  }, [taskTree])

  const taskTreeMoveOptions = useMemo(() => {
    if (taskTree && modalMoveTask.value) {
      return makeTaskTreeMoveOptions(taskTree, modalMoveTask.value)
    }
    return []
  }, [taskTree, modalMoveTask.value])

  const handleAddSubmitTask = useCallback(
    (taskPayload) =>
      addTask
        .onSuccess(() => modalAddTaskActions.close())
        .asPromise(taskPayload),
    [addTask, modalAddTaskActions]
  )

  const handleDeleteTask = useCallback(
    () =>
      removeTask
        .onFailure(notifyError)
        .onSuccess(() => modalDeleteTaskActions.close())
        .run(modalDeleteTask.value),
    [removeTask, modalDeleteTaskActions, modalDeleteTask.value]
  )

  const handleMoveTask = useCallback(
    (taskPayload) =>
      moveTask
        .onSuccess(() => modalMoveTaskActions.close())
        .asPromise(taskPayload, taskTree),
    [moveTask, taskTree, modalMoveTaskActions]
  )

  const handleEditResoure = useCallback(
    (resource) =>
      overrideResource
        .onSuccess(() => modalEditResourceActions.close())
        .asPromise(resource),
    [modalEditResourceActions, overrideResource]
  )

  const clipboardValue = useWpClipboardValue()
  const [modalPasteTask, modalPasteTaskActions] = useModalTrigger()
  function handlePasteSubmitTask(values) {
    return pasteTask.onSuccess(modalPasteTaskActions.close).asPromise({
      ...values,
      ids: [modalPasteTask.value.task.id],
    })
  }

  const currentEditResource = modalEditResource.value
    ? resourcesById[modalEditResource.value]
    : null

  const handleResetResource = useMemo(
    () => resetResource.curry(currentEditResource),
    [currentEditResource, resetResource]
  )

  const ctx = useMemo(
    () => ({
      addTask: (taskProps) => modalAddTaskActions.open(taskProps),
      deleteTask: (task) => modalDeleteTaskActions.open(task),
      moveTask: (task) => modalMoveTaskActions.open(task),
      editResource: (resource) => modalEditResourceActions.open(resource.id),
    }),
    [
      modalMoveTaskActions,
      modalAddTaskActions,
      modalDeleteTaskActions,
      modalEditResourceActions,
    ]
  )

  const isTreeView = useRouteMatch({ path: match.path, exact: true })

  return (
    <Layout>
      <div className="container-fluid pt-5 px-half-page pb-page-bottom">
        <Route path={match.path} exact>
          <TaskOperationsContext.Provider value={ctx}>
            {estimate && (
              <>
                <AddTaskModalForm
                  isOpen={modalAddTask.isOpen}
                  toggle={modalAddTaskActions.toggle}
                  taskTreeOptions={taskTreeOptions}
                  onSubmit={handleAddSubmitTask}
                  taskProps={modalAddTask.value}
                  onClosed={modalMoveTask.onClose}
                />
                <PasteTaskModalForm
                  pasteTask={modalPasteTask.value}
                  isOpen={modalPasteTask.isOpen}
                  toggle={modalPasteTaskActions.toggle}
                  taskTreeOptions={taskTreeOptions}
                  onSubmit={handlePasteSubmitTask}
                  onClosed={modalPasteTaskActions.onClose}
                />
                <ConfirmDeleteModal
                  toggle={modalDeleteTaskActions.toggle}
                  isOpen={modalDeleteTask.isOpen}
                  onConfirm={handleDeleteTask}
                  item={modalDeleteTask.value?.title ?? ""}
                  message={t("message.delete_item_task", {
                    item: modalDeleteTask.value?.title ?? "",
                  })}
                  onClosed={modalDeleteTaskActions.onClose}
                />
                <MoveTaskModalForm
                  isOpen={modalMoveTask.isOpen}
                  toggle={modalMoveTaskActions.toggle}
                  taskTreeOptions={taskTreeMoveOptions}
                  onSubmit={handleMoveTask}
                  task={modalMoveTask.value}
                  onClosed={modalMoveTaskActions.onClose}
                />
                <EditResoucePricesModalForm
                  onSubmit={handleEditResoure}
                  isOpen={modalEditResource.isOpen}
                  toggle={modalEditResourceActions.toggle}
                  resource={currentEditResource}
                  onClosed={modalEditResourceActions.onClose}
                  onReset={handleResetResource}
                  resetting={resettingResource}
                />
                {estimate.task_tree.length === 0 && (
                  <EmptyTasks
                    toggleShowAddTask={modalAddTaskActions.toggle}
                    readOnly={estimate.readonly}
                  />
                )}
                {estimate.task_tree.length > 0 && (
                  <TreeView
                    estimate={estimate}
                    updatingAllocations={updatingAllocations}
                    saving={saving}
                    removeTask={removeTask}
                    resources={resources}
                    resourcesById={resourcesById}
                    opens={opens}
                    toggleOpen={toggleOpen}
                    initialTaskViewed={initialOpen}
                    selectedScenarios={selectedScenarios}
                    {...estimateTools}
                  />
                )}
              </>
            )}
          </TaskOperationsContext.Provider>
        </Route>
        <Route path={`${match.path}/table`}>
          <TaskOperationsContext.Provider value={ctx}>
            {estimate?.task_tree?.length === 0 && <Redirect to={match.url} />}
            {estimate && (
              <>
                {estimate.task_tree.length > 0 && (
                  <TableView
                    estimate={estimate}
                    fullEstimate={fullEstimate}
                    selectedScenarios={selectedScenarios}
                    onEditTaskClick={handleOnEditTaskClickTable}
                  />
                )}
              </>
            )}
          </TaskOperationsContext.Provider>
        </Route>
        <Route path={`${match.path}/scenarios`}>
          <EstimateScenarios estimate={estimate} />
        </Route>
      </div>
      <Layout.TabBar>
        <EstimateTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <EstimateBottomBarContent />
      </Layout.BottomBar>
      {estimate && (
        <Layout.LeftBar>
          <div className="d-flex flex-column justify-content-between align-items-center h-100">
            <Route path={match.path} exact>
              {({ match }) => (
                <div className="d-flex flex-column mt-8">
                  {!estimate.readonly && (
                    <Button
                      color="primary"
                      size="lg"
                      rounded
                      onClick={modalAddTaskActions.toggle}
                      className="ml-4"
                      style={{
                        visibility:
                          estimate.task_tree.length > 0 && match
                            ? "visible"
                            : "hidden",
                        pointerEvents:
                          estimate.task_tree.length > 0 && match
                            ? "auto"
                            : "none",
                      }}
                    >
                      <Icon
                        name="plus"
                        className="pointer"
                        title={t("action:new_task")}
                      />
                    </Button>
                  )}

                  {!estimate.readonly && (
                    <Button
                      color="primary"
                      size="lg"
                      rounded
                      onClick={() => modalPasteTaskActions.open(clipboardValue)}
                      className="ml-4 mt-4"
                      disabled={!clipboardValue}
                    >
                      <Icon
                        height={18}
                        width={18}
                        name="paste"
                        className={`pointer ${
                          clipboardValue ? "fill-primary" : "fill-gray-light"
                        }`}
                        title={t("action:paste_task")}
                      />
                    </Button>
                  )}
                </div>
              )}
            </Route>

            <div className="d-flex flex-column justify-content-between">
              <ChangeViewButton
                match={match}
                path=""
                iconName="list"
                activeClassName="active"
                exact
                className="ml-4 mb-8"
                title={t("action:estimate_list")}
              />
              <ChangeViewButton
                match={match}
                path="table"
                iconName="table"
                activeClassName="active"
                disabled={estimate.task_tree.length === 0}
                exact
                className="ml-4 mt-2 mb-8"
                title={
                  estimate.task_tree.length !== 0 && t("action:estimate_table")
                }
              />
              <ChangeViewButton
                match={match}
                path="scenarios"
                iconName="scenario"
                activeClassName="active"
                disabled={estimate.task_tree.length === 0}
                exact
                className="ml-4 mt-2 mb-8"
                title={
                  estimate.task_tree.length !== 0 && t("action:estimate_scenarios")
                }
              />
            </div>

            <div>
              {!estimate.readonly && (
                <>
                  {/* placeholders to ensure proper alignment */}
                  <Button
                    color="primary"
                    size="lg"
                    rounded
                    style={{ visibility: "hidden", pointerEvents: "none" }}
                    className="mb-8"
                  >
                    <Icon name="plus" className="pointer" />
                  </Button>
                  <Button
                    color="primary"
                    size="lg"
                    rounded
                    style={{ visibility: "hidden", pointerEvents: "none" }}
                    className="mb-8"
                  >
                    <Icon name="plus" className="pointer" />
                  </Button>
                </>
              )}
            </div>
          </div>
        </Layout.LeftBar>
      )}
      {estimate && (
        <Layout.OverviewPanel
          isOpen={isOverviewOpen}
          width={350}
          closedWidth={40}
          isVisible={isTreeView}
          onClose={() => setOverviewOpen((v) => !v)}
        >
          {isOverviewOpen && (
            <div
              style={{
                width: "100%",
                height: "100%",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              <div className="p-5">
                <TaskTreePreview
                  taskTree={estimate.task_tree}
                  updateTaskTree={updateTaskTree}
                  moveTask={moveTask}
                  disableMove={estimate.readonly}
                />
              </div>
            </div>
          )}
          {!isOverviewOpen && (
            <h3
              className={classNames(
                S["overview-title"],
                "text-uppercase font-size-semibold"
              )}
            >
              {t("tasks.overview")}
            </h3>
          )}
        </Layout.OverviewPanel>
      )}
      <Layout.FirstLevelNavi>
        <EstimateLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

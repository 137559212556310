import { fromPairs, toPairs } from "lodash"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import Dropdown from "../../../components/Dropdown"
import WpModal from "../../../components/WpModal"
import { CAP, CAPLEVEL } from "../../../hooks/capabilities"

function CapabilityRow({ capx, setCapx, cap, lr = false, r = false, w = false, a = false }) {
  const { t } = useTranslation()
  return (
    <tr>
      <td className="pr-4">{t("enums:cap." + cap)}</td>
      <td>
        <Dropdown
          value={capx[cap]}
          className="px-3 py-2"
          options={[
            {
              value: CAPLEVEL.NONE,
              label: t("enums:cap_level.none"),
              enable: true,
            },
            {
              value: CAPLEVEL.LIMITED_READ,
              label: t("enums:cap_level.limited_read"),
              enable: lr,
            },
            {
              value: CAPLEVEL.READ,
              label: t("enums:cap_level.read"),
              enable: r,
            },
            {
              value: CAPLEVEL.WRITE,
              label: t("enums:cap_level.write"),
              enable: w,
            },
            {
              value: CAPLEVEL.ADMIN,
              label: t("enums:cap_level.admin"),
              enable: a,
            },
          ].filter((x) => x.enable)}
          onChange={(val) => {
            setCapx((x) => ({ ...x, [cap]: val }))
          }}
          itemWidth={200}
          style={{ width: 200 }}
        />
      </td>
    </tr>
  )
}

function CapabilityGroupRow({ title }) {
  return (
    <tr>
      <th colSpan={2}>{title}</th>
    </tr>
  )
}

export function CapabilitiesModal({ isOpen, onSubmit, onCancel, onClosed, capabilities }) {
  const { t } = useTranslation()
  const [capx, setCapx] = useState({
    ...fromPairs(Object.values(CAP).map((c) => [c, 0])),
    ...fromPairs(capabilities),
  })

  return (
    <WpModal onClosed={onClosed} isOpen={isOpen} toggle={onCancel} title={t("action:edit_capabilities")}>
      <div style={{ maxHeight: "60vh", overflow: "scroll", paddingBottom: 150 }}>
        <table>
          <tbody>
            <CapabilityGroupRow title={t("enums:cap_section.org")} />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.ORG_META} r w />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.ORG_MEMBERS} r w />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.ORG_BILLING} w />
            <CapabilityGroupRow title={t("enums:cap_section.estimates")} />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.ESTIMATES} lr r w />
            <CapabilityGroupRow title={t("enums:cap_section.template")} />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.TEMPLATES} r w />
            <CapabilityGroupRow title={t("enums:cap_section.resources")} />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.RESOURCES} r w />
            <CapabilityGroupRow title={t("enums:cap_section.projects")} />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.PROJECTS} lr r w />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.DEADLINES} lr r w />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.CALENDAR} r a />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.NC_REPORT} r w />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.TEAM} r w />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.TASKS} r w />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.ACTIVITIES} r w a />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.BILLS} r w />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.PROJECT_INDICATORS} r />
            <CapabilityGroupRow title={t("enums:cap_section.crm")} />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.CUSTOMER} r w />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.OVERVIEW} lr r />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.BUSINESS} r />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.INTERACTIONS} r w />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.REQUESTS} r w />
            <CapabilityGroupRow title={t("enums:cap_section.insights")} />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.INDICATORS} r />
            <CapabilityGroupRow title={t("enums:cap_section.settings")} />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.TAGS} r w />
            <CapabilityGroupRow title={t("enums:cap_section.hr")} />
            <CapabilityRow capx={capx} setCapx={setCapx} cap={CAP.WORKFLOW} r w a />
          </tbody>
        </table>
      </div>
      <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
        <Button size="sm" color="none" onClick={onCancel}>
          {t("action:cancel")}
        </Button>
        <Button
          size="sm"
          color="primary"
          type="button"
          className="ml-5"
          onClick={() => {
            onSubmit(toPairs(capx).filter((pair) => pair[1] > 0))
          }}
        >
          {t("action:save")}
        </Button>
      </div>
    </WpModal>
  )
}

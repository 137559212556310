import React, { useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import Icon from "../Icon"
import ErrorPopover from "../ErrorPopover"

export default function JumpToPage({ goToPage, max }) {
  const [page, setPage] = useState("")
  const [error, setError] = useState(null)

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      if (page !== "" && page <= max && page >= 1) {
        setPage("")
        setError(null)
        goToPage(page)
      } else {
        setError(page)
      }
    },
    [goToPage, page, max]
  )

  const { t } = useTranslation(["translation", "action", "field"])

  return (
    <form onSubmit={handleSubmit}>
      <label className="mr-3">{t("pagination.go")}</label>
      <input
        type="number"
        id="pageSelector"
        step={1}
        value={page}
        onChange={(e) => setPage(e.target.value)}
        style={{ height: 24, width: 32 }}
      />
      {!!error && (
        <ErrorPopover
          placement={"top-start"}
          target={"pageSelector"}
          title={t("field:errors.general")}
          description={t("field:errors.max_page", { max_value: max })}
          trigger={"always"}
        />
      )}
      <Icon
        name="arrow-right"
        className="text-dark pointer ml-3"
        onClick={handleSubmit}
        title={t("action:go")}
      />
    </form>
  )
}

import { rj, useRj, useRunRj } from "react-rocketjump"
import rjList, {
  nextPreviousPaginationAdapter,
} from "react-rocketjump/plugins/list"
import rjDebounce from "react-rocketjump/plugins/debounce"
import api from "../api"

const deadlineListRj = rj(
  rjDebounce(),
  rjList({
    pageSize: 100,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    name: "deadlines",
    effectCaller: rj.configured(),
    effect: (wpAuth) => (params = {}) =>
      api.auth(wpAuth).get(`/api/deadline`, params),
    mutations: {
      create: rj.mutation.single({
        effect: (wpAuth) => (data) => {
          return api.auth(wpAuth).post(`/api/deadline`, data)
        },
        updater: (state, result) => {
          if (state?.data?.list) {
            return {
              ...state,
              data: {
                ...state.data,
                list: [...state.data.list, result],
              },
            }
          } else {
            return state
          }
        },
      }),
      update: rj.mutation.single({
        effect: (wpAuth) => (id, data) => {
          return api.auth(wpAuth).patch(`/api/deadline/${id}`, data)
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.map((item) =>
                item.id !== result.id ? item : result
              ),
            },
          }
        },
      }),
      remove: rj.mutation.single({
        effect: (wpAuth) => (itemId) => {
          return api
            .auth(wpAuth)
            .mapResponse(() => itemId)
            .delete(`/api/deadline/${itemId}`)
        },
        updater: (state, result) => {
          if (state?.data?.list) {
            return {
              ...state,
              data: {
                ...state.data,
                list: state.data.list.filter((item) => item.id !== result),
              },
            }
          } else {
            return state
          }
        },
      }),
    },
    computed: {
      deadlines: "getList",
      count: "getCount",
      loading: "isPending",
      error: "getError",
      pageCount: "getNumPages",
      hasNext: "hasNext",
      hasPrev: "hasPrev",
    },
  }
)

export function useDeadlines(params) {
  return useRunRj(deadlineListRj, [params], false)
}

function useDeadlinesControlled() {
  return useRj(deadlineListRj)
}

useDeadlines.controlled = useDeadlinesControlled

import React, { useCallback, useContext, useMemo, useState } from "react"
import { useInteractions } from "../hooks/interaction"
import useModalTrigger from "../hooks/useModalTrigger"
import { CreateCustomerInteractionModal } from "./CreateCustomerInteractionModal"

const Context = React.createContext()

export function InteractionModalProvider({ children }) {
  const [
    createInteractionModalState,
    createInteractionModalActions,
  ] = useModalTrigger()

  const [suspense, setSuspense] = useState(null)
  const [, { create }] = useInteractions.controlled()

  const createInteraction = useCallback(
    (initialValues = {}, lockedFields = []) => {
      return new Promise((resolve, reject) => {
        setSuspense({ resolve, reject })
        createInteractionModalActions.open({ initialValues, lockedFields })
      })
    },
    [createInteractionModalActions]
  )

  const ctx = useMemo(
    () => ({
      createInteraction,
    }),
    [createInteraction]
  )

  return (
    <Context.Provider value={ctx}>
      {children}
      {createInteractionModalState.value && (
        <CreateCustomerInteractionModal
          isOpen={createInteractionModalState.isOpen}
          interaction={createInteractionModalState.value.initialValues}
          onClosed={createInteractionModalActions.onClose}
          toggle={() => {
            createInteractionModalActions.close()
            setSuspense(null)
            suspense.reject()
          }}
          readOnlyFields={createInteractionModalState.value.lockedFields}
          onSave={(data) => {
            return create
              .onSuccess((result) => {
                createInteractionModalActions.close()
                suspense.resolve(result)
              })
              .asPromise({
                ...createInteractionModalState.value.initialValues,
                ...data,
              })
          }}
        />
      )}
    </Context.Provider>
  )
}

export function useInteractionTools() {
  return useContext(Context)
}

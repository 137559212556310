import { DOC_FRAGMENT_TYPE } from "../../../../consts"

import * as TaskTableFrag from "./TaskTableFrag"
import * as TaskListFrag from "./TaskListFrag"
import * as TextFrag from "./TextFrag"
import * as ChartDendrogramFrag from "./ChartDendrogramFrag"
import * as ChartTreemapFrag from "./ChartTreemapFrag"
import * as ChartBubbleFrag from "./ChartBubbleFrag"
import * as ResourceTableFrag from "./ResourceTableFrag"
import * as ResourceListFrag from "./ResourceListFrag"
import * as CoverFrag from "./CoverFrag"
import * as PageBreakFrag from "./PageBreakFrag"
import * as ScenarioListFrag from "./ScenarioListFrag"
import * as ScenarioTaskVizFrag from "./ScenarioTaskVizFrag"
import * as ScenarioResourceVizFrag from "./ScenarioResourceVizFrag"
import * as BillingFrag from "./BillingFrag"

const Frags = {
  [DOC_FRAGMENT_TYPE.TASK_TABLE]: TaskTableFrag,
  [DOC_FRAGMENT_TYPE.TASK_LIST]: TaskListFrag,
  [DOC_FRAGMENT_TYPE.TEXT]: TextFrag,
  [DOC_FRAGMENT_TYPE.CHART_DENDROGRAM]: ChartDendrogramFrag,
  [DOC_FRAGMENT_TYPE.CHART_TREEMAP]: ChartTreemapFrag,
  [DOC_FRAGMENT_TYPE.CHART_BUBBLE]: ChartBubbleFrag,
  [DOC_FRAGMENT_TYPE.RESOURCE_TABLE]: ResourceTableFrag,
  [DOC_FRAGMENT_TYPE.RESOURCE_LIST]: ResourceListFrag,
  [DOC_FRAGMENT_TYPE.COVER]: CoverFrag,
  [DOC_FRAGMENT_TYPE.PAGE_BREAK]: PageBreakFrag,
  [DOC_FRAGMENT_TYPE.SCENARIO_LIST]: ScenarioListFrag,
  [DOC_FRAGMENT_TYPE.SCENARIO_TASK_VIZ]: ScenarioTaskVizFrag,
  [DOC_FRAGMENT_TYPE.SCENARIO_RESOURCE_VIZ]: ScenarioResourceVizFrag,
  [DOC_FRAGMENT_TYPE.BILLING]: BillingFrag,
}

export default Frags

import { last, range } from "lodash"
import React from "react"
import RichTextEditor from "../../../../../components/RichTextEditor/RichTextEditor"
import { pagerLog } from "../../Pager"
import {
  EstimateTreeMap,
  TreeMapLegendVertical,
} from "../../../EstimateVisualization/EstimateTreeMap/EstimateTreeMap"
import { useTranslation } from "react-i18next"

const FragComponent = React.memo(
  ({
    frag,
    estimate,
    width,
    currentChunkStart,
    currentChunkEnd,
    inPage,
    chunkIsFirst,
  }) => {
    if (inPage) {
      pagerLog("[PAGER] Treemap rendered")
    }

    const { t } = useTranslation(["translation"])

    let visibleTasks = frag.data.visibleTasks
    if (visibleTasks === null) {
      visibleTasks = range(1, estimate.task_tree.length + 1)
    } else {
      visibleTasks = range(visibleTasks[0], visibleTasks[1] + 1)
    }

    const visibleTasksInChunk = visibleTasks.slice(
      currentChunkStart,
      currentChunkEnd
    )

    const hasTasks = estimate.task_tree.length > 0

    return (
      <div className="">
        {frag?.data?.showTitle && chunkIsFirst && (
          <h1 className="__pager-title m-0 pb-3">{frag?.title}</h1>
        )}
        {chunkIsFirst && frag.data.showLegend && hasTasks && (
          <div className="__pager-treemap-legend pb-5">
            <TreeMapLegendVertical estimate={estimate} columns={2} />
          </div>
        )}
        {!hasTasks && (
          <div className="__pager-disclaimer disclaimer">
            {t("documents.no_visible_tasks")}
          </div>
        )}
        <div className="bg-white d-flex flex-row justify-content-center align-items-start">
          <EstimateTreeMap
            estimate={estimate}
            maxDepth={frag.data.maxDepth}
            visibleTasks={[visibleTasksInChunk[0], last(visibleTasksInChunk)]}
            width={width - 140} // Leave some space to handle eventual text overflow
          />
        </div>
        {frag.data.enableCaption && (
          <div style={{ flex: "1 1 0%", minWidth: 0, paddingLeft: 4 }}>
            <RichTextEditor
              content={frag?.data?.caption}
              readOnly={true}
              placeholder={""}
              enableImages={false}
              minHeight0
              wrapperClassName="__pager-treemap-caption"
            />
          </div>
        )}
      </div>
    )
  },
  (prevProps, nextProps) => {
    const checkProps = [
      "frag",
      "estimate",
      "width",
      "currentChunkStart",
      "currentChunkEnd",
      "chunkIsFirst",
    ]
    for (let i = 0; i < checkProps.length; i++) {
      const prop = checkProps[i]
      if (prevProps[prop] !== nextProps[prop]) {
        return false
      }
    }
    return true
  }
)

export default FragComponent

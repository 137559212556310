import React, { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useEstimatesList } from "../../hooks/estimates"
import classNames from "classnames"
import S from "./CreateEstimateTemplateModal.module.scss"
import Button from "../Button"
import Icon from "../Icon"
import useDebounce from "../../hooks/useDebounce"
import WpInput from "../WpInput"
import Scrollbars from "react-custom-scrollbars"
import Table from "../Table"
import Pagination from "../Pagination/Pagination"
import { Field, Formik } from "formik"
import * as yup from "yup"
import { convertErrorToForm } from "../../utils"
import FieldInput from "../fields/FieldInput"
import WpModalQuota from "../WpModalQuota"
import FieldCustomerSelector from "../fields/FieldCustomerSelector"

export default function CreateEstimateTemplateModal({ isOpen, toggle, onSave, ...props }) {
  const [page, setPage] = useState(1)

  const [templateFilter, setTemplateFilter] = useState("")
  const debTemplateFilter = useDebounce(templateFilter, 200)

  const estimateFilters = useMemo(
    () => ({
      page: page,
      is_template: true,
      archived: false,
      search: debTemplateFilter,
    }),
    [debTemplateFilter, page]
  )

  const [{ estimates, count, pageCount, hasNext, hasPrev }] = useEstimatesList(estimateFilters)

  const { t } = useTranslation(["translation", "action"])

  const [formName, setFormName] = useState("pick-template")
  const [selectedTemplate, setSelectedTemplate] = useState(null)

  const handleCancel = useCallback(() => {
    setSelectedTemplate(null)
    setFormName("pick-template")
    toggle()
  }, [toggle])

  const templateColumns = useMemo(
    () => [
      {
        label: t("field:estimate.code"),
        name: "code",
        size: 4,
        cellClassname: "pointer",
        onClick: (e, datum) => {
          setFormName("set-info")
          setSelectedTemplate(datum)
        },
      },
      {
        label: t("field:estimate.title"),
        name: "title",
        size: 8,
        onClick: (e, datum) => {
          setFormName("set-info")
          setSelectedTemplate(datum)
        },
        cellClassname: "pointer",
      },
    ],
    [t]
  )

  const schema = yup.object().shape({
    title: yup
      .string()
      .required(t("field:errors.mandatory", { field: t("field:estimate.title") }))
      .max(512, t("field:errors.max", { max_value: 512 })),
  })

  return (
    <WpModalQuota isOpen={isOpen} toggle={toggle} checkQuota="estimates" {...props} size="lg" title={t("action:new_doc_frag")}>
      <div>
        <div className={classNames(S["frag-animator"], S[formName])}>
          <div className={classNames(S["add-frag-select-template"])}>
            <div className="d-flex flex-row align-items-center justify-content-end mb-5">
              <div className="d-flex flex-row align-items-center justify-content-center">
                <span className="pr-3">{t("action:search")}:</span>
                <WpInput
                  placeholder={t("field:template.text.placeholder.search")}
                  style={{ width: 235 }}
                  value={templateFilter}
                  onChange={(e) => setTemplateFilter(e.target.value)}
                />
              </div>
            </div>
            <Scrollbars style={{ width: "100%", height: "75%" }}>
              {estimates !== null && estimates.length > 0 && (
                <>
                  <Table columns={templateColumns} data={estimates} highlightRows={[]} stickyHeader={false} />
                  {count !== 0 && (
                    <div className="pt-4">
                      <Pagination
                        current={page}
                        pageCount={pageCount}
                        goToPage={(page) => {
                          setPage(page)
                        }}
                        hasNext={hasNext}
                        hasPrev={hasPrev}
                      />
                    </div>
                  )}
                </>
              )}
              {estimates !== null && estimates.length === 0 && (
                <div className="w-100 text-center pt-5">
                  <span>{t("template.estimate.empty_list")}</span>
                </div>
              )}
            </Scrollbars>
            <div className="text-right">
              <Button size="sm" color="none" onClick={handleCancel}>
                {t("action:cancel")}
              </Button>
            </div>
          </div>

          <div className={classNames(S["add-frag-set-info"], "d-flex flex-column")}>
            <Button size="sm" color="none" onClick={() => setFormName("pick-template")} className="mb-5 align-self-start">
              <div className="d-flex flex-row align-items-center">
                <Icon name="back" className="mr-3" />
                {t("action:back")}
              </div>
            </Button>
            <div className="d-flex flex-column flex-1">
              <p className="mx-5">
                {t(`estimate_list.instance_template_estimate_message`, {
                  estimate: selectedTemplate ?? { title: "" },
                })}
              </p>
              <div className="d-flex flex-column ml-2 flex-1 my-4">
                <Formik
                  key={selectedTemplate?.id ?? "not selected"}
                  initialValues={{
                    title: selectedTemplate?.title,
                    customer: null,
                    price_list: selectedTemplate?.price_list,
                  }}
                  initialErrors={
                    selectedTemplate
                      ? undefined
                      : {
                          title: "",
                        }
                  }
                  validationSchema={schema}
                  onSubmit={(values, actions) => {
                    return onSave({
                      title: values.title,
                      customer: values.customer,
                      cloneId: selectedTemplate?.id,
                      price_list: selectedTemplate?.price_list,
                    }).catch((error) => {
                      actions.setErrors(convertErrorToForm(error))
                    })
                  }}
                  enableReinitialize={true}
                >
                  {(formik) => (
                    <>
                      <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="d-flex flex-column justify-content-start align-items-stretch flex-1"
                      >
                        <Field
                          name="customer"
                          component={FieldCustomerSelector}
                          className={"mb-5"}
                          placeholder={t("field:estimate.placeholder.customer")}
                          label={t("field:estimate.customer")}
                          containerClassName={"mb-5"}
                          controlClassName="px-4 py-3 border-radius-xl"
                        />
                        <Field
                          name="title"
                          component={FieldInput}
                          placeholder={t("field:estimate.placeholder.title")}
                          label={t("field:estimate.title")}
                          containerClassName={"mb-5"}
                        />
                        <div className="flex-1"></div>
                        <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                          <Button size="sm" color="none" onClick={handleCancel}>
                            {t("action:cancel")}
                          </Button>
                          <Button size="sm" color="primary" type="submit" className="ml-5" disabled={!formik.isValid}>
                            {t("action:add")}
                          </Button>
                        </div>
                      </form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WpModalQuota>
  )
}

import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import DropdownList from "./DropdownList"
import { useTags } from "../hooks/tags"
import { TagCollection } from "./TagCollection"

export function TagSelector({ tagId, onChange, ...props }) {
  const [{ tagCategories }] = useTags()
  const [searchStr, setSearchStr] = useState("")
  const { t } = useTranslation()

  const options = []
  const lookup = {}
  let val = null
  for (const category of tagCategories ?? []) {
    for (const tag of category.tags) {
      lookup[tag.id] = tag
      let visible = true
      if (searchStr) {
        visible = searchStr
          .toLowerCase()
          .split(" ")
          .every((piece) => tag.name.toLowerCase().includes(piece))
      }
      if (visible) {
        options.push({
          value: tag.id,
          label: <TagCollection tags={[tag]} />,
        })
      }
      if (tag.id === tagId) {
        val = tag
      }
    }
  }

  let caption = t("tagSelector.placeholder")
  if (val) {
    caption = () => <TagCollection tags={[val]} />
  }

  return (
    <DropdownList
      {...props}
      value={tagId}
      caption={caption}
      options={options}
      onChange={(nextTagId) => {
        onChange(lookup[nextTagId])
      }}
      onSearchChange={(search) => {
        setSearchStr(search)
      }}
      autoFilterOptions={false}
      itemWidth={300}
    />
  )
}

import React from "react"
import { Switch, Route, useRouteMatch } from "react-router-dom"
import EstimateTemplate from "./EstimateTemplate"
import TextTemplate from "./TextTemplate"
import CoverTemplate from "./CoverTemplate"

export default function Templates() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}`} exact component={EstimateTemplate} />
      <Route path={`${path}/text`} component={TextTemplate} />
      <Route path={`${path}/cover`} component={CoverTemplate} />
    </Switch>
  )
}

import dayjs from "dayjs"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import Table from "../../../../../components/Table"

function BillingTranches({ showDates, tranches }) {
  const { t } = useTranslation(["translation", "tab", "action"])

  const columns = useMemo(() => {
    return [
      {
        label: t("field:billing_tranche.date"),
        name: "date",
        size: 2,
        render: (selectedDatum, index, datum) => {
          return <span>{dayjs(selectedDatum).format("DD/MM/YYYY")}</span>
        },
        visible: showDates,
      },
      {
        label: t("field:billing_tranche.description"),
        name: "description",
        size: 8,
      },
      {
        label: t("field:billing_tranche.amount"),
        name: "amount",
        size: 2,
        render: (selectedDatum, index, datum) => {
          const f = parseFloat(selectedDatum)
          if (!isNaN(f)) {
            return <span>{f.toFixed(2) + " €"}</span>
          } else {
            return <span />
          }
        },
      },
    ].filter((col) => col.visible !== false)
  }, [t, showDates])

  return (
    <Table
      data={tranches}
      columns={columns}
      valueCheckFn={(val) => val !== undefined && val !== null}
    />
  )
}

const FragComponent = React.memo(
  ({
    frag,
    estimate,
    sliceRowStart,
    sliceRowEnd,
    chunkIsLast,
    inPage,
    chunkIsFirst,
  }) => {
    const tranches = estimate.billing_tranches.slice(sliceRowStart, sliceRowEnd)

    return (
      <div className="">
        {frag?.data?.showTitle && chunkIsFirst && (
          <h1 className="__pager-title m-0 pb-3">{frag?.title}</h1>
        )}
        <BillingTranches
          tranches={tranches}
          showDates={frag?.data?.displayDates ?? undefined}
        />
      </div>
    )
  },
  (prevProps, nextProps) => {
    const checkProps = [
      "frag",
      "estimate",
      "sliceRowStart",
      "sliceRowEnd",
      "chunkIsLast",
      "chunkIsFirst",
    ]
    for (let i = 0; i < checkProps.length; i++) {
      const prop = checkProps[i]
      if (prevProps[prop] !== nextProps[prop]) {
        return false
      }
    }
    return true
  }
)

export default FragComponent

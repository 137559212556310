import { Field, Formik } from "formik"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import { convertErrorToForm } from "../utils"
import Button from "./Button"
import FieldInput from "./fields/FieldInput"
import FieldTextarea from "./fields/FieldTextarea"
import WpModal from "./WpModal"
import { CustomerSelector } from "./CustomerSelector"

const INITIAL_VALUES = {
  customer: null,
  created_at: "",
  title: "",
  description: "",
}

export function CreateIncomingRequestModal({ isOpen, toggle, onSave, onClosed, selectCustomer = false }) {
  const { t } = useTranslation(["translation", "field", "action"])

  const customerSchemaRequired = yup.number().required(t("field:errors.mandatory", { field: t("field:project.customer") }))
  const customerSchemaOptional = yup.number().optional().nullable()

  const schema = yup.object().shape({
    customer: selectCustomer ? customerSchemaRequired : customerSchemaOptional,
    created_at: yup
      .date()
      .typeError(
        t("field:errors.date", {
          field: t("field:project_activity.date_ref"),
        })
      )
      .required(t("field:errors.mandatory", { field: t("field:project.name") })),
    title: yup.string().required(),
  })

  return (
    <WpModal onClosed={onClosed} isOpen={isOpen} toggle={toggle} title={t("action:new_incoming_request")}>
      <div>
        <div className="d-flex flex-row align-items-start my-4">
          <div className="d-flex flex-column ml-2 flex-1">
            <Formik
              initialValues={INITIAL_VALUES}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                return onSave(values).catch((error) => {
                  actions.setErrors(convertErrorToForm(error))
                })
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <>
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    {selectCustomer && (
                      <Field
                        name="customer"
                        component={CustomerSelector.Field}
                        label={t("field:incoming_request.customer")}
                        className="px-4 py-3 mb-5"
                      />
                    )}
                    <Field
                      name="created_at"
                      component={FieldInput}
                      type="date"
                      label={t("field:incoming_request.created_at")}
                      className={"mb-5"}
                    />
                    <Field
                      name="title"
                      component={FieldInput}
                      className={"mb-5"}
                      placeholder={t("field:incoming_request.placeholder.title")}
                      label={t("field:incoming_request.title")}
                    />
                    <Field
                      name="description"
                      component={FieldTextarea}
                      rows={4}
                      className={"mb-5"}
                      placeholder={t("field:incoming_request.placeholder.description")}
                      label={t("field:incoming_request.description")}
                    />
                    <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                      <Button size="sm" color="none" onClick={toggle}>
                        {t("action:cancel")}
                      </Button>
                      <Button size="sm" color="primary" type="submit" className="ml-5" disabled={!formik.isValid}>
                        {t("action:add")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </WpModal>
  )
}

import React from "react"
import classNames from "classnames"
import S from "./Separator.module.scss"

export default function Separator({ className, vertical = false, ...props }) {
  return (
    <>
      {vertical && (
        <div
          className={classNames(className, S["v-separator"])}
          {...props}
        ></div>
      )}
      {!vertical && (
        <div
          className={classNames(className, S["h-separator"])}
          {...props}
        ></div>
      )}
    </>
  )
}

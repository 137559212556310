import { deps, rj, useRunRj } from "react-rocketjump"
import api from "../api"

const ProjectTeamRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (projectId) => {
    return api.auth(wpAuth).get(`/api/project/${projectId}/team`)
  },
  mutations: {
    update: rj.mutation.single({
      effect: (wpAuth) => (projectId, data) => {
        return api
          .auth(wpAuth)
          .put(`/api/project/${projectId}/team/member/`, data)
      },
      updater: (state, result) => {
        return {
          ...state,
          data: state.data.map((member) => {
            if (member.user.id === result.user.id) {
              return result
            } else {
              return member
            }
          }),
        }
      },
    }),
    create: rj.mutation.single({
      effect: (wpAuth) => (projectId, data) => {
        return api
          .auth(wpAuth)
          .put(`/api/project/${projectId}/team/member/`, data)
      },
      updater: (state, result) => {
        return {
          ...state,
          data: [...state.data, result],
        }
      },
    }),
    remove: rj.mutation.single({
      effect: (wpAuth) => (projectId, data) => {
        return api
          .auth(wpAuth)
          .mapResponse(() => data)
          .put(`/api/project/${projectId}/team/member/`, {
            ...data,
            permissions: [],
          })
      },
      updater: (state, result) => {
        return {
          ...state,
          data: state.data.filter((item) => item.user.id !== result.user.id),
        }
      },
    }),
  },
})

export function useProjectTeam(projectId) {
  return useRunRj(ProjectTeamRj, [deps.maybe(projectId)])
}

// Same API of modal trigger plus check refresh quota on open
import { useMemo } from "react"
import { useQuota } from "./quota"
import useModalTrigger from "./useModalTrigger"

export default function useModalTriggerQuota() {
  const [state, actions] = useModalTrigger()

  const [, { refreshQuota }] = useQuota()

  const memoActions = useMemo(
    () => ({
      ...actions,
      open: (value) => {
        actions.open(value)
        refreshQuota()
      },
    }),
    [actions, refreshQuota]
  )

  return [state, memoActions]
}

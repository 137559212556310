import React from "react"
import S from "./FragCardButton.module.scss"
import classNames from "classnames"

export default function FragCardButton({ elem, className, isSelected }) {
  const Icon = elem.icon
  return (
    <div
      className={classNames(
        "d-flex flex-column border border-radius-xl border-primary",
        S["modal-add-frag"],
        {[S["active"]]: isSelected},
        className
      )}
      onClick={elem.onClick}
    >
      <div
        className={classNames(
          "d-flex w-100 align-items-end justify-content-center",
          S["modal-card-header"],
        )}
      >
        <Icon />
      </div>
      <h3 className="text-uppercase font-weight-semibold text-center pt-3">
        {elem.title}
      </h3>
      <p className="mx-4 my-2" style={{fontSize: 11}}>{elem.description}</p>
    </div>
  )
}

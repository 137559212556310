export const defaultData = {
  displayLogo: true,
  mainText: null,
  footerText1: null,
  footerText2: null,
  footerCols: true,
  logoHeight: 150,
  logoWidth: 150,
  enabled: true,
}

export const preventAutomaticPagination = true

export { default as EditDataForm } from "./EditDataForm"
export { default as FragComponent } from "./FragComponent"
export { default as pageSplitter } from "./pageSplitter"

export const requestSpacing = (props) =>
  (props.frag?.data?.pageFillFactor ?? 100) === 100 ? 0 : 15

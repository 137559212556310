import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { CustomerContext } from "../../context"

export default function CustomerBottomBarContent() {
  const { t } = useTranslation(["translation"])

  const [{ customer }] = useContext(CustomerContext)

  if (!customer) {
    return null
  }

  return (
    <div className="d-flex flex-row justify-content-between align-items-stretch  w-100 h-100">
      <div className="h-100 py-5 px-page text-uppercase font-weight-semibold d-flex align-items-center">
        {t("customer.customer", { name: customer.name, code: customer.code || "??" })}
      </div>
    </div>
  )
}

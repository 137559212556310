import { deps, rj, useRunRj } from "react-rocketjump"
import rjDebounced from "react-rocketjump/plugins/debounce"
import api from "../api"

const EstimateEnumRj = rj(rjDebounced(), {
  effectCaller: rj.configured(),
  effect: (wpAuth) => (filters) => {
    return api
      .auth(wpAuth)
      .mapResponse((r) => r.response.results)
      .get(`/api/estimate-fast/`, filters)
  },
  computed: {
    estimates: "getData",
  },
})

export function useEstimateEnum(filters = {}) {
  return useRunRj(EstimateEnumRj, [filters])
}

const EstimateEnumDetailRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (estimateId) => {
    return api.auth(wpAuth).get(`/api/estimate/${estimateId}/`)
  },
  computed: {
    estimate: "getData",
  },
})

export function useEstimateEnumDetail(estimateId) {
  return useRunRj(EstimateEnumDetailRj, [deps.maybe(estimateId)])
}

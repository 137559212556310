export const defaultData = {
  maxDepth: 5,
  visibleTasks: null,
  enableCaption: false,
  caption: null,
  height: null
}

export { default as EditDataForm } from './EditDataForm'
export { default as FragComponent } from './FragComponent'
export { default as pageSplitter } from './pageSplitter'
import React, {
  useContext
} from "react"
import { useParams } from "react-router-dom"
import {
  DEFAULT_THEME_COLOR,
  DEFAULT_THEME_FONT,
  DOC_FRAGMENT_TYPE
} from "../../../consts"
import { CurrentEstimateStateContext } from "../../../context"
import { useEstimateDocument } from "../../../hooks/estimateDocument"
import useModalTrigger from "../../../hooks/useModalTrigger"
import DocFrag from "./DocFrag"
import Footer from "./Footer"
import Frags from "./Frags"
import Pager from "./Pager"
import { convertColorToTheme } from "./utils"

export default function PrintEstimateDocument() {
  const { id } = useParams()

  const [{ estimate, fullEstimate }] = useContext(CurrentEstimateStateContext)
  const [
    { doc },
  ] = useEstimateDocument(id)

  const [editFragModal, editFragModalActions] = useModalTrigger()

  const color = doc?.meta?.color ?? DEFAULT_THEME_COLOR
  const font = doc?.meta?.font ?? DEFAULT_THEME_FONT
  const fontHeadings = doc?.meta?.fontHeadings ?? DEFAULT_THEME_FONT

  const theme = convertColorToTheme(color)

  return (
    <>
      {doc && estimate && (
        <div style={{ "--theme": theme, "--font": font,  "--fontHeadings": fontHeadings }}>
          <Pager
            footer={(page, totPages) => (
              <Footer
                estimate={fullEstimate}
                doc={doc}
                currentPage={page}
                numPages={totPages}
              />
            )}
            footerSkipBeginning={1}
            pretty={false}
          >
            {({ width, fullWidth, height, fullHeight }) => {
              const elements = []
              elements.push(
                ...doc.fragments
                  .filter(
                    (frag) =>
                      (frag.fragment_type !== DOC_FRAGMENT_TYPE.COVER ||
                        frag.data.enabled) &&
                      frag.fragment_type in Frags
                  )
                  .map((frag, i) => (
                    <Pager.Element
                      id={frag.id}
                      as={DocFrag}
                      onEdit={editFragModalActions.open}
                      isCurrent={editFragModal.value === frag.id}
                      estimate={estimate}
                      fullEstimate={fullEstimate}
                      isFirst={i === 0}
                      isCover={frag.fragment_type === DOC_FRAGMENT_TYPE.COVER}
                      key={frag.id}
                      frag={frag}
                      width={width}
                      fullWidth={fullWidth}
                      height={height}
                      fullHeight={fullHeight}
                      isPdf={true}
                    />
                  ))
              )
              return elements
            }}
          </Pager>
        </div>
      )}
    </>
  )
}

import { useEffect, useState } from "react"
import ReconnectingWebSocket from "reconnectingwebsocket"
import { useAuthUser } from "use-eazy-auth"

export function useEstimateConcurrency(estimateId) {
  const { token } = useAuthUser()

  const [concurrentUsers, setConcurrentUsers] = useState([])

  useEffect(() => {
    const proto = window.location.protocol.startsWith("https") ? "wss" : "ws"
    const hostname = process.env.NODE_ENV === "development" ? "localhost:8000" : window.location.host
    const ws = new ReconnectingWebSocket(
      `${proto}://${hostname}/ws/core/estimate/concurrency/${estimateId}/?token=${token}`
    )
    ws.addEventListener("message", (e) => {
      const { type, ...data } = JSON.parse(e.data)
      if (type === "join") {
        setConcurrentUsers((ux) => [...ux, data])
      }
      if (type === "leave") {
        setConcurrentUsers((ux) => ux.filter((u) => u.user_id !== data.user_id))
      }
    })
    return () => {
      setConcurrentUsers([])
      ws.close()
    }
  }, [estimateId, token])

  return concurrentUsers
}

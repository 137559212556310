import React, { useState } from "react"
import {
  useEstimateEnumDetail,
  useEstimateEnum,
} from "../../hooks/estimateEnum"
import DropdownList from "../DropdownList"

function mkLabel(estimate) {
  return `[${estimate.code}] ${estimate.title}`
}

export default function EstimateSelector({
  estimateId,
  onEstimateChange,
  clearable,
  extraOptions = [],
  defaultFilters = {},
  filtersReinitialize = false,
  ...props
}) {
  const [
    { estimate },
    { updateData: updateSelectedEstimate },
  ] = useEstimateEnumDetail(estimateId)

  const [_externalFilters] = useState(defaultFilters)

  const externalFilters = filtersReinitialize ? defaultFilters : _externalFilters

  const [{ estimates }, { runDebounced: searchEstimates }] = useEstimateEnum(
    externalFilters
  )

  const estimateOptions =
    estimates?.map((estimate) => ({
      label: mkLabel(estimate),
      value: estimate.id,
    })) ?? []

  const options = [...extraOptions, ...estimateOptions]

  let caption = undefined

  if (estimateId) {
    if (estimateId > 0) {
      caption = estimate ? mkLabel(estimate) : "..."
    } else {
      caption = extraOptions.find((opt) => opt.value === estimateId)?.label
    }
  }

  return (
    <DropdownList
      {...props}
      value={estimateId}
      caption={caption}
      options={options}
      autoFilterIgnoreCase
      onChange={(nextResourceId) => {
        const nextResource = estimates.find((t) => t.id === nextResourceId)
        updateSelectedEstimate(nextResource)
        onEstimateChange(nextResource)
      }}
      onSearchChange={(searchStr) => {
        searchEstimates({ ...externalFilters, search: searchStr })
      }}
      clearable={clearable}
      onClear={() => {
        updateSelectedEstimate(null)
        onEstimateChange(null)
      }}
    />
  )
}

function FieldEstimateSelector({ form, field, label, ...props }) {
  return (
    <>
      {label && (
        <label className="font-weight-semibold mb-3 h3 text-uppercase">
          {label}
        </label>
      )}
      <EstimateSelector
        estimateId={field.value}
        onEstimateChange={(estimate) =>
          form.setFieldValue(field.name, estimate?.id ?? null)
        }
        {...props}
      />
    </>
  )
}

EstimateSelector.Field = FieldEstimateSelector

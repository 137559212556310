import { rj, useRunRj } from "react-rocketjump"
import rjDebounce from "react-rocketjump/plugins/debounce"
import rjList, { nextPreviousPaginationAdapter } from "react-rocketjump/plugins/list"
import api from "../api"

const ComplianceIssueReportListRj = rj(
  rjDebounce(),
  rjList({
    pageSize: 100,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    name: "Requests",
    effectCaller: rj.configured(),
    effect: (wpAuth) => (params = {}) => api.auth(wpAuth).get(`/api/compliance-issue-report/`, params),
    mutations: {
      create: rj.mutation.single({
        effect: (wpAuth) => (data) => {
          return api.auth(wpAuth).post(`/api/compliance-issue-report`, data)
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: [...state.data.list, result],
            },
          }
        },
      }),
      remove: rj.mutation.single({
        effect: (wpAuth) => (itemId) => {
          return api
            .auth(wpAuth)
            .mapResponse(() => itemId)
            .delete(`/api/compliance-issue-report/${itemId}`)
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.filter((item) => item.id !== result),
            },
          }
        },
      }),
    },
    computed: {
      reports: "getList",
      count: "getCount",
      loading: "isPending",
      error: "getError",
      pageCount: "getNumPages",
      hasNext: "hasNext",
      hasPrev: "hasPrev",
    },
  }
)

export function useComplianceIssueReports(params) {
  return useRunRj(ComplianceIssueReportListRj, [params], false)
}

const ComplianceIssueReportRj = rj({
  name: "Request",
  effectCaller: rj.configured(),
  effect: (wpAuth) => (id) => api.auth(wpAuth).get(`/api/compliance-issue-report/${id}`),
  mutations: {
    update: rj.mutation.single({
      effect: (wpAuth) => (id, data) => {
        return api.auth(wpAuth).patch(`/api/compliance-issue-report/${id}`, data)
      },
      updater: "updateData",
    }),
  },
  computed: {
    report: "getData",
    loading: "isPending",
    error: "getError",
  },
})

export function useComplianceIssueReport(id) {
  return useRunRj(ComplianceIssueReportRj, [id], true)
}

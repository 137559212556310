import { useTranslation } from "react-i18next"
import { BsBookmarkCheck, BsBug, BsCalendar, BsCalendar4Week } from "react-icons/bs"
import { CAP, useCapabilities } from "../hooks/capabilities"
import Icon from "./Icon"
import Layout from "./Layout"

export function TrackerLandmarks() {
  const { t } = useTranslation()
  const [, { hasCapability }] = useCapabilities()
  return (
    <>
      {hasCapability(CAP.PROJECTS) && (
        <>
          <Layout.FirstLevelNavi.Tab text={t("navbar.projects")} svgIcon={<Icon name="reporting" />} to={"/projects"} />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
      {hasCapability(CAP.DEADLINES) && (
        <>
          <Layout.FirstLevelNavi.Tab
            text={t("navbar.deadlines")}
            svgIcon={<BsCalendar4Week style={{ width: 18 }} />}
            to={"/deadlines"}
          />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
      {hasCapability(CAP.CALENDAR) && (
        <>
          <Layout.FirstLevelNavi.Tab
            text={t("navbar.tracker_calendar")}
            svgIcon={<BsCalendar style={{ width: 18 }} />}
            to={"/tracker-calendar"}
          />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
      {hasCapability(CAP.NC_REPORT) && (
        <>
          <Layout.FirstLevelNavi.Tab
            text={t("navbar.compliance_issue_report")}
            svgIcon={<BsBug style={{ width: 18 }} />}
            to={"/compliance-issue-report"}
          />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
      {hasCapability(CAP.WORKFLOW) && (
        <>
          <Layout.FirstLevelNavi.Tab
            text={t("navbar.hr_workflow")}
            svgIcon={<BsBookmarkCheck style={{ width: 18 }} />}
            to={"/hr/workflow"}
          />
          <Layout.FirstLevelNavi.Divider />
        </>
      )}
    </>
  )
}

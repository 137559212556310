import React from "react"
import { useTranslation } from "react-i18next"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import Button from "../Button"

export default function WpModal({
  modal,
  toggle,
  title,
  modified,
  onSave,
  onDiscard,
  disableSave = false,
}) {
  const { t } = useTranslation(["translation", "action"])
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      size="sm"
      contentClassName={"border-2 border-primary border-radius-xl"}
      centered
    >
      <ModalHeader
        className="text-uppercase justify-content-center bg-primary-50 text-white py-3"
        tag="h3"
      >
        {title}
      </ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column">
          <span>{t("message.save_modified", { modified: modified })}</span>
          <span className="py-4">{t("message.save_unsaved")}</span>
          <div className="d-flex flex-row align-items-center justify-content-end">
            <Button size="sm" color="none" onClick={toggle}>
              {t("action:cancel")}
            </Button>
            <Button
              size="sm"
              color="dark"
              className="ml-8"
              onClick={() => {
                onDiscard()
                toggle()
              }}
            >
              {t("action:discard")}
            </Button>
            <Button
              size="sm"
              color="primary"
              type="submit"
              className="ml-5"
              disabled={disableSave}
              onClick={() => {
                onSave()
                toggle()
              }}
            >
              {t("action:save")}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

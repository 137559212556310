import React, { useEffect, useMemo, useRef, useState } from "react"
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom"
import Loader from "../../components/Loader/Loader"
import NotFound from "../../components/NotFound"
import {
  CurrentEstimateStateContext,
  ResourcesOpensContext,
  TasksOpensContext,
} from "../../context"
import { useEstimateTree } from "../../hooks/estimateTree"
import useOpensMap from "../../hooks/useOpensMap"
import { BillingTranches } from "./EstimateBillingTranches"
import EstimateDocument from "./EstimateDocument"
import PrintEstimateDocument from "./EstimateDocument/Print"
import { EstimateFlow } from "./EstimateFlow"
import EstimateInfo from "./EstimateInfo"
import EstimateInfoStatic from "./EstimateInfoStatic/EstimateInfoStatic"
import { EstimateInteractions } from "./EstimateInteractions"
// import EstimatePreview from "./EstimatePreview"
import EstimateResources from "./EstimateResources"
import EstimateTasksTree from "./EstimateTasksTree"
import EstimateVisualization from "./EstimateVisualization"
import { adaptForScenarios, computeScenarioPrices } from "./treeOperations"

export default function Estimate() {
  const { path } = useRouteMatch()
  const { id } = useParams()

  // Selected scenarios
  const [selectedScenarios, setSelectedScenarios] = useState(null)
  // Server estimate tree
  const estimateActionsState = useEstimateTree(id)
  const [estimateState, estimateActions] = estimateActionsState
  const { estimate: fullEstimate } = estimateState
  // ++ Scenarios
  const estimate = useMemo(
    () =>
      fullEstimate === null
        ? null
        : adaptForScenarios(fullEstimate, selectedScenarios),
    [selectedScenarios, fullEstimate]
  )
  const scenarioPrices = useMemo(() => {
    return fullEstimate === null ? {} : computeScenarioPrices(fullEstimate)
  }, [fullEstimate])

  // Estimate shared ctx
  const estimateCtxValue = useMemo(
    () => [
      {
        ...estimateState,
        fullEstimate,
        estimate,
        selectedScenarios,
        scenarioPrices,
      },
      {
        ...estimateActions,
        setSelectedScenarios,
        removeScenario: estimateActions.removeScenario
          .onSuccess((result) => {
            setSelectedScenarios((selected) => {
              if (selected === null) {
                return null
              }
              const nextSelected = selected.filter((id) => id !== result.id)
              // NOTE: AVOID RE-RUNNING HEAVY COMPUTATION WHEN ACTUALLY SCENARIO
              // IS NOTE SELECTED
              if (nextSelected.length !== selected.length) {
                return nextSelected
              }
              return selected
            })
          })
          .curry(),
      },
    ],
    [
      estimate,
      estimateActions,
      estimateState,
      fullEstimate,
      scenarioPrices,
      selectedScenarios,
    ]
  )

  // Track task opens statek for ALL Estimate
  const [opensTasks, toggleOpenTasks, setOpensTasks] = useOpensMap()
  const opensTasksCtx = useMemo(() => [opensTasks, toggleOpenTasks], [
    opensTasks,
    toggleOpenTasks,
  ])

  // Track resources opens statek for ALL Estimate
  const [opensRes, toggleOpenRes, setOpensRes] = useOpensMap()
  const opensResCtx = useMemo(() => [opensRes, toggleOpenRes], [
    opensRes,
    toggleOpenRes,
  ])

  // Empty opens task on task id changes
  // NOTE: ref is used to skip just mounted branch...
  const isMountedRef = useRef(false)
  useEffect(() => {
    if (isMountedRef.current) {
      setOpensTasks({})
      setOpensRes({})
    } else {
      isMountedRef.current = true
    }
  }, [id, setOpensTasks, setOpensRes])

  if (estimate === null) {
    return <Loader />
  }

  return (
    <CurrentEstimateStateContext.Provider value={estimateCtxValue}>
      <TasksOpensContext.Provider value={opensTasksCtx}>
        <ResourcesOpensContext.Provider value={opensResCtx}>
          <Switch>
            <Route path={`${path}`} exact component={EstimateInfoStatic} />
            <Route path={`${path}/edit`} exact component={EstimateInfo} />
            <Route path={`${path}/document`} component={EstimateDocument} />
            <Route path={`${path}/print`} component={PrintEstimateDocument} />
            <Route path={`${path}/tasks`} component={EstimateTasksTree} />
            <Route path={`${path}/viz`} component={EstimateVisualization} />
            <Route path={`${path}/resources`} component={EstimateResources} />
            <Route path={`${path}/billing`} component={BillingTranches} />
            <Route path={`${path}/flow`} component={EstimateFlow} />
            <Route
              path={`${path}/interactions`}
              component={EstimateInteractions}
            />
            {/* <Route path={`${path}/preview`} component={EstimatePreview} /> */}
            <Route component={NotFound} />
          </Switch>
        </ResourcesOpensContext.Provider>
      </TasksOpensContext.Provider>
    </CurrentEstimateStateContext.Provider>
  )
}

import { deps, rj, useRunRj } from "react-rocketjump"
import rjDebounced from "react-rocketjump/plugins/debounce"
import api from "../api"

const ProjectEnumRj = rj(rjDebounced(), {
  effectCaller: rj.configured(),
  effect: (wpAuth) => (filters) => {
    return api
      .auth(wpAuth)
      .mapResponse((r) => r.response.results)
      .get(`/api/project-fast/`, filters)
  },
  computed: {
    projects: "getData",
  },
})

export function useProjectEnum(filters = {}) {
  return useRunRj(ProjectEnumRj, [filters])
}

const ProjectEnumDetailRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (projectId) => {
    return api.auth(wpAuth).get(`/api/project-fast/${projectId}/`)
  },
  computed: {
    project: "getData",
  },
})

export function useProjectEnumDetail(projectId) {
  return useRunRj(ProjectEnumDetailRj, [deps.maybe(projectId)])
}

import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { BsBug, BsCalendar2Check, BsCalendar4Week } from "react-icons/bs"
import { FaRegMoneyBillAlt } from "react-icons/fa"
import { TiFlowSwitch } from "react-icons/ti"
import { useParams } from "react-router-dom"
import Icon from "../../components/Icon"
import Layout from "../../components/Layout"
import { TextWithBadge } from "../../components/TextWithBadge"
import { ProjectContext } from "../../context"
import { CAP, useCapabilities } from "../../hooks/capabilities"
import { TbCertificate } from "react-icons/tb"

export default function ProjectTabBarContent() {
  const { t } = useTranslation(["translation", "tab", "action"])
  const { id } = useParams()

  const [{ project }] = useContext(ProjectContext)

  const [, { hasCapability }] = useCapabilities()

  return (
    <div className="w-100 d-flex justify-content-between">
      <div className="d-flex align-items-start justify-content-start">
        <Layout.TabBar.TabBarTab
          text={t("tab:project.info")}
          exact
          to={`/projects/${id}`}
          svgIcon={<Icon name="info" />}
          activeUrlPrefixes={[`/projects/${id}/edit`]}
        />
        <Layout.TabBar.Divider />
        {hasCapability(CAP.TEAM) && (
          <>
            <Layout.TabBar.TabBarTab
              text={t("tab:project.team")}
              to={`/projects/${id}/team`}
              svgIcon={<Icon name="project-team" style={{ width: 23, height: 21 }} />}
            />
            <Layout.TabBar.Divider />
          </>
        )}
        {hasCapability(CAP.TASKS) && (
          <>
            <Layout.TabBar.TabBarTab
              text={t("tab:project.tasks")}
              to={`/projects/${id}/tasks`}
              svgIcon={<Icon name="task" style={{ width: 23, height: 21 }} />}
              activeUrlPrefixes={[`/projects/${id}/gantt`]}
            />
            <Layout.TabBar.Divider />
          </>
        )}
        {hasCapability(CAP.DEADLINES) && (
          <>
            <Layout.TabBar.TabBarTab
              text={
                <TextWithBadge enable={project.missed_deadlines > 0} badge={project.missed_deadlines}>
                  {t("tab:project.deadline")}
                </TextWithBadge>
              }
              to={`/projects/${id}/deadlines`}
              svgIcon={<BsCalendar4Week style={{ width: 16 }} />}
            />
            <Layout.TabBar.Divider />
          </>
        )}
        {hasCapability(CAP.ACTIVITIES) && (
          <>
            <Layout.TabBar.TabBarTab
              text={t("tab:project.activity")}
              to={`/projects/${id}/activity`}
              svgIcon={<Icon name="project-activity" style={{ width: 23, height: 21 }} />}
            />
            <Layout.TabBar.Divider />
          </>
        )}
        {hasCapability(CAP.BILLS) && (
          <>
            <Layout.TabBar.TabBarTab
              text={t("tab:project.bills")}
              to={`/projects/${id}/bills`}
              svgIcon={<FaRegMoneyBillAlt style={{ width: 16 }} />}
            />
            <Layout.TabBar.Divider />
          </>
        )}
        {hasCapability(CAP.PROJECT_INDICATORS) && (
          <>
            <Layout.TabBar.TabBarTab
              text={t("tab:project.insight")}
              to={`/projects/${id}/insight`}
              svgIcon={<Icon name="project-insight" style={{ width: 23, height: 21 }} />}
            />
            <Layout.TabBar.Divider />
          </>
        )}
        {hasCapability(CAP.INTERACTIONS) && (
          <>
            <Layout.TabBar.TabBarTab
              text={t("tab:project.interactions")}
              to={`/projects/${id}/interactions`}
              svgIcon={<BsCalendar2Check style={{ width: 16 }} />}
            />
            <Layout.TabBar.Divider />
          </>
        )}
        {hasCapability(CAP.REQUESTS) && hasCapability(CAP.ESTIMATES) && (
          <>
            <Layout.TabBar.TabBarTab
              text={t("tab:project.flow")}
              to={`/projects/${id}/flow`}
              svgIcon={<TiFlowSwitch style={{ width: 16 }} />}
            />
            <Layout.TabBar.Divider />
          </>
        )}
        {hasCapability(CAP.NC_REPORT) && (
          <>
            <Layout.TabBar.TabBarTab
              text={t("tab:project.compliance_issue_report")}
              to={`/projects/${id}/compliance-issue-report`}
              svgIcon={<BsBug style={{ width: 16 }} />}
            />
            <Layout.TabBar.Divider />
          </>
        )}
        <Layout.TabBar.TabBarTab
          text={t("tab:project.audit_trail")}
          exact
          to={`/projects/${id}/audit-trail`}
          svgIcon={<TbCertificate style={{ width: 16 }} />}
          activeUrlPrefixes={[`/projects/${id}/audit-trail`]}
        />
      </div>
    </div>
  )
}

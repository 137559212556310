import React from "react"
import { deps } from "react-rocketjump"
import {
  useProjectResource,
  useProjectResources,
} from "../../hooks/projectResources"
import DropdownList from "../DropdownList"

const PROJECT_TASK_SEARCH_FILTER = {}

export default function ProjectResourceSelector({
  projectId,
  taskId,
  resourceId,
  onResourceChange,
  clearable,
  extraOptions = [],
  ...props
}) {
  const [
    { data: resource },
    { updateData: updateSelectedResource },
  ] = useProjectResource(
    projectId,
    deps.maybe(resourceId > 0 ? resourceId : null)
  )

  const [
    { data: resources },
    { runDebounced: searchResources },
  ] = useProjectResources(
    projectId,
    taskId && taskId > 0 ? taskId : undefined,
    PROJECT_TASK_SEARCH_FILTER
  )

  const taskOptions =
    resources?.map((resource) => ({
      label: `${resource.price_list_name}: ${resource.resource_name} (${resource.cost_unit})`,
      value: resource.id,
    })) ?? []

  const options = [...extraOptions, ...taskOptions]

  let caption = undefined

  if (resourceId) {
    if (resourceId > 0) {
      caption = resource?.resource_name ?? "..."
    } else {
      caption = extraOptions.find((opt) => opt.value === resourceId)?.label
    }
  }

  return (
    <DropdownList
      {...props}
      value={taskId}
      caption={caption}
      options={options}
      onChange={(nextResourceId) => {
        const nextResource = resources.find((t) => t.id === nextResourceId)
        updateSelectedResource(nextResource)
        onResourceChange(nextResource)
      }}
      onSearchChange={(searchStr) => {
        searchResources(projectId, taskId ?? undefined, { search: searchStr })
      }}
      clearable={clearable}
      onClear={() => {
        updateSelectedResource(null)
        onResourceChange(null)
      }}
    />
  )
}

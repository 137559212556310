import React, { useCallback, useEffect, useState } from "react"
import { SketchPicker } from "react-color"
import S from "./ColorPickerPopover.module.scss"
import classNames from "classnames"

export default function ColorPickerPopover({
  value,
  onChange,
  left = false,
  bottom = false,
  size = "sm",
  disabled = false,
  className = undefined,
}) {
  const [showPicker, setShowPicker] = useState(false)

  const togglePicker = useCallback(() => {
    setShowPicker((v) => !v)
  }, [])

  useEffect(() => {
    if (showPicker) {
      const handler = (e) => {
        togglePicker()
      }
      window.addEventListener("click", handler)
      return () => window.removeEventListener("click", handler)
    }
  }, [showPicker, togglePicker])

  return (
    <div
      className={classNames(S["color-picker-popover"], className)}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={classNames(S["color-picker-swatch"], {
          pointer: !disabled,
          "not-allowed": disabled,
        })}
        onClick={() => {
          if (disabled) {
            return
          } else {
            togglePicker()
          }
        }}
      >
        <div
          className={classNames(S["color-picker-color"], {
            [S["lg"]]: size === "lg",
            [S["md"]]: size === "md",
            [S["richtext"]]: size === "richtext",
          })}
          style={{ backgroundColor: value }}
        />
      </div>
      {showPicker && (
        <div
          className={classNames(
            S["color-picker-popup"],
            { [S["left-picker"]]: left },
            { [S["bottom-picker"]]: bottom },
            { [S["right-picker"]]: !left },
            { [S["top-picker"]]: !bottom }
          )}
        >
          <SketchPicker
            disableAlpha
            color={value}
            onChangeComplete={(color) => onChange(color.hex)}
          />
        </div>
      )}
    </div>
  )
}

import React from "react"
import { useQuota } from "../hooks/quota"
import BadQuotaAlert from "./BadQuotaAlert"
import Loader from "./Loader/Loader"
import WpModal from "./WpModal/WpModal"

export default function WpModalQuota({
  checkQuota = null, // string | null the quota field 2 check
  ...props
}) {
  const [quota] = useQuota()

  if (checkQuota !== null) {
    // Waiting 4 ma quota
    if (quota === null) {
      return (
        <WpModal {...props}>
          <div style={{ minHeight: 100 }}>
            <Loader />
          </div>
        </WpModal>
      )
    }
    if (quota[checkQuota] === 0) {
      return (
        <WpModal {...props} size="md">
          <BadQuotaAlert checkQuota={checkQuota} />
        </WpModal>
      )
    }
  }

  return <WpModal {...props} />
}

export const RESOURCE_TYPES = ["hr", "service", "material"]

export const RESOURCE_TYPE = Object.freeze({
  HR: "hr",
  SERVICE: "service",
  MATERIAL: "material",
})

export const DOC_FRAGMENT_TYPE = Object.freeze({
  TASK_TABLE: "task_table",
  TASK_LIST: "task_list",
  TEXT: "text",
  CHART: "chart",
  CHART_DENDROGRAM: "chart_dendrogram",
  CHART_TREEMAP: "chart_treemap",
  CHART_BUBBLE: "chart_bubble",
  RESOURCE_TABLE: "resources_table",
  RESOURCE_LIST: "resource_list",
  SCENARIO_LIST: "scenario_list",
  SCENARIO_TASK_VIZ: "scenario_task_viz",
  SCENARIO_RESOURCE_VIZ: "scenario_resource_viz",
  COVER: "cover",
  PAGE_BREAK: "page-break",
  BILLING: "billing",
})

export const BILLING_TYPES = ["private_person", "company"]
export const BILLING_TYPE = Object.freeze({
  PRIVATE_PERSON: "private_person",
  COMPANY: "company",
})

export const PDF_BASE_URL =
  process.env.REACT_APP_PDF_URL || "http://localhost:3040"

export const ORGANIZATION_ROLES = ["none", "editor", "viewer", "manager"]
export const ORGANIZATION_TRACKER_ROLES = ["none", "accounting_user", "manager"]

export const ORGANIZATION_ROLE = Object.freeze({
  NONE: "none",
  EDITOR: "editor",
  VIEWER: "viewer",
  MANAGER: "manager",
})

export const ORGANIZATION_TRACKER_ROLE = Object.freeze({
  NONE: "none",
  ACCOUNTING_USER: "accounting_user",
  MANAGER: "manager",
})

export const DEFAULT_THEME_COLOR = "#6369D1"

export const DEFAULT_FOOTER_CONFIG = {
  left: "logo",
  middle: "code",
  right: "pagination",
}

/**
 * Put here any font you want to load in the PDF generator
 * Please note that you still have to add these fonts to public/index.html to be able
 *   to use them in frontend
 */
export const LOAD_FONTS_FOR_PDF = [
  "https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&family=IBM+Plex+Sans:ital,wght@0,200;0,400;0,600;1,200;1,400;1,600&family=Karla:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Poppins:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Raleway:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto+Slab:wght@200;400;600&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Saira+Semi+Condensed:wght@300;400;600&family=Source+Code+Pro:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap",
]

export const DEFAULT_THEME_FONT =
  'Roboto, system-ui, -apple-system, "Helvetica Neue", Arial'
export const AVAILABLE_FONTS = [
  {
    label: "System font",
    value: DEFAULT_THEME_FONT,
  },
  {
    label: "IBM Plex Sans",
    value: "'IBM Plex Sans', sans-serif",
  },
  {
    label: "IBM Plex Mono",
    value: "'IBM Plex Mono', monospace",
  },
  {
    label: "Karla",
    value: "'Karla', sans-serif",
  },
  {
    label: "Open Sans",
    value: "'Open Sans', sans-serif",
  },
  {
    label: "Poppins",
    value: "'Poppins', sans-serif",
  },
  {
    label: "Raleway",
    value: "'Raleway', sans-serif",
  },
  {
    label: "Roboto",
    value: "'Roboto', sans-serif",
  },
  {
    label: "Roboto Slab",
    value: "'Roboto Slab', serif",
  },
  {
    label: "Saira",
    value: "'Saira Semi Condensed', sans-serif",
  },
  {
    label: "Source Code",
    value: "'Source Code Pro', monospace",
  },
  // {
  //   label: "Arial",
  //   value: "Arial, Helvetica, sans-serif",
  // },
  // {
  //   label: "Bookman Old Style",
  //   value: "'Bookman Old Style', serif",
  // },
  // {
  //   label: "Comic Sans MS",
  //   value: "'Comic Sans MS', cursive",
  // },
  // {
  //   label: "Courier New",
  //   value: "'Courier New', Courier, monospace",
  // },
  // {
  //   label: "Lucida Console",
  //   value: "Lucida Console",
  // },
  // {
  //   label: "Times New Roman",
  //   value: "'Times New Roman', Times, serif",
  // },
  // {
  //   label: "Verdana",
  //   value: "Verdana, Geneva, sans-serif",
  // },
]

export const AVAILABLE_FONTS_HEADINGS = [
  {
    label: "System font",
    value: DEFAULT_THEME_FONT,
  },
  {
    label: "IBM Plex Sans",
    value: "'IBM Plex Sans', sans-serif",
  },
  {
    label: "IBM Plex Mono",
    value: "'IBM Plex Mono', monospace",
  },
  {
    label: "Karla",
    value: "'Karla', sans-serif",
  },
  {
    label: "Open Sans",
    value: "'Open Sans', sans-serif",
  },
  {
    label: "Poppins",
    value: "'Poppins', sans-serif",
  },
  {
    label: "Raleway",
    value: "'Raleway', sans-serif",
  },
  {
    label: "Roboto",
    value: "'Roboto', sans-serif",
  },
  {
    label: "Roboto Slab",
    value: "'Roboto Slab', serif",
  },
  {
    label: "Saira",
    value: "'Saira Semi Condensed', sans-serif",
  },
  {
    label: "Source Code",
    value: "'Source Code Pro', monospace",
  },
  // {
  //   label: "Arial",
  //   value: "Arial, Helvetica, sans-serif",
  // },
  // {
  //   label: "Arial Black",
  //   value: "'Arial Black', Gadget, sans-serif",
  // },
  // {
  //   label: "Bookman Old Style",
  //   value: "'Bookman Old Style', serif",
  // },
  // {
  //   label: "Comic Sans MS",
  //   value: "'Comic Sans MS', cursive",
  // },
  // {
  //   label: "Courier New",
  //   value: "'Courier New', Courier, monospace",
  // },
  // {
  //   label: "Impact",
  //   value: "Impact, Charcoal, sans-serif",
  // },
  // {
  //   label: "Lucida Console",
  //   value: "'Lucida Console', Monaco, monospace",
  // },
  // {
  //   label: "Potta One",
  //   value: "'Potta One', cursive",
  // },
  // {
  //   label: "Times New Roman",
  //   value: "'Times New Roman', Times, serif",
  // },
  // {
  //   label: "Verdana",
  //   value: "Verdana, Geneva, sans-serif",
  // },
]

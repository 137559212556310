import React, { useMemo } from "react"
import useNotifications from "../../hooks/notifications"
import useRouterQueryParam from "magik-react-hooks/useRouterQueryParam"
import { qpInt } from "magik-react-hooks/qpUtils"
import S from "./Notifications.module.scss"
import Layout from "../../components/Layout/Layout"
import Loader from "../../components/Loader/Loader"
import { useTranslation } from "react-i18next"
import classnames from "classnames"
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip"
import Pagination from "../../components/Pagination/Pagination"
import dayjs from "dayjs"

export default function Notifications() {
  const [pageNr, setPageNr] = useRouterQueryParam("page", 1, qpInt)
  const filters = useMemo(
    () => ({
      page: pageNr,
    }),
    [pageNr]
  )
  const [
    { notifications, pageCount, hasPrev, hasNext },
    { markAsRead },
  ] = useNotifications(filters)
  const { t } = useTranslation()

  if (notifications === null) {
    return (
      <Layout>
        <Loader />
      </Layout>
    )
  }

  return (
    <Layout>
      <div className="container">
        <h1 className="mt-4 mb-6">{t("notifications.title")}</h1>
        {notifications.map((notification) => (
          <div className={S["panel"]} key={notification.id}>
            <div className="d-flex flex-row justify-content-start align-items-center">
              <svg
                width={16}
                height={16}
                className={classnames(S["read-icon"], {
                  [S["unread"]]: !notification.read,
                })}
                id={`Notif${notification.id}`}
              >
                <circle
                  cx={8}
                  cy={8}
                  r={8}
                  fill={notification.read ? "var(--white)" : "var(--primary)"}
                  onClick={() => {
                    if (!notification.read) {
                      markAsRead(notification.id)
                    }
                  }}
                />
              </svg>
              <UncontrolledTooltip
                key={notification.id}
                placement="bottom"
                target={`Notif${notification.id}`}
                fade={false}
              >
                {notification.read
                  ? t("notifications.read")
                  : t("notifications.markAsRead")}
              </UncontrolledTooltip>
              <div className="flex-1">
                <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                  <h3>{notification.title}</h3>
                  <i>{dayjs(notification.created).format("DD/MM/YYYY HH:mm")}</i>
                </div>
                {notification.content.split("\n").map((line, i) => (
                  <p key={i}>{line}</p>
                ))}
              </div>
            </div>
          </div>
        ))}
        <div className="mt-6">
          <Pagination
            current={pageNr}
            pageCount={pageCount}
            goToPage={(page) => {
              setPageNr(page)
              window.scrollTo(0, 10)
            }}
            hasNext={hasNext}
            hasPrev={hasPrev}
          />
        </div>
      </div>
    </Layout>
  )
}

import { Field, Formik } from "formik"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import { convertErrorToForm } from "../../utils"
import Button from "../Button"
import FieldInput from "../fields/FieldInput"
import WpModal from "../WpModal"

const INITIAL_VALUES = {
  name: "",
  tax_code: "",
}

export function CreateCustomerModal({ isOpen, toggle, onSave, onClosed, cloneEstimate }) {
  const { t } = useTranslation(["translation", "field", "action"])

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(t("field:errors.mandatory", { field: t("field:project.name") }))
      .max(256, t("field:errors.max", { max_value: 256 })),
    code: yup
      .string()
      .required(t("field:errors.mandatory", { field: t("field:project.code") }))
      .max(10, t("field:errors.max", { max_value: 10 })),
    tax_code: yup
      .string()
      .notRequired()
      .max(512, t("field:errors.max", { max_value: 100 })),
  })

  return (
    <WpModal onClosed={onClosed} isOpen={isOpen} toggle={toggle} title={t("customers_list.create_customer")}>
      <div>
        <p>{t("customers_list.create_customer_message")}</p>
        <div className="d-flex flex-row align-items-start my-4">
          <div className="d-flex flex-column ml-2 flex-1">
            <Formik
              initialValues={INITIAL_VALUES}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                return onSave(values).catch((error) => {
                  actions.setErrors(convertErrorToForm(error))
                })
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <>
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Field
                      name="name"
                      component={FieldInput}
                      placeholder={t("field:customer.placeholder.name")}
                      label={t("field:customer.name")}
                      className={"mb-5"}
                    />
                    <Field
                      name="code"
                      component={FieldInput}
                      placeholder={t("field:customer.placeholder.code")}
                      label={t("field:customer.code")}
                      className={"mb-5"}
                    />
                    <Field
                      name="tax_code"
                      component={FieldInput}
                      className={"mb-5"}
                      placeholder={t("field:customer.placeholder.tax_code")}
                      label={t("field:customer.tax_code")}
                    />
                    <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                      <Button size="sm" color="none" onClick={toggle}>
                        {t("action:cancel")}
                      </Button>
                      <Button size="sm" color="primary" type="submit" className="ml-5" disabled={!formik.isValid}>
                        {t("action:add")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </WpModal>
  )
}

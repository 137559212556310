import { flatten, groupBy, keyBy, map, pick, sortBy, sum } from "lodash"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import ResourceList from "../../../EstimateResources/ResourceList"
import {
  aggregateResources,
  flattenCloneTree,
} from "../../../EstimateTasksTree/TaskTable/utils"
import { pagerLog } from "../../Pager"

const FragComponent = React.memo(
  ({ frag, estimate, sliceRowStart, sliceRowEnd, inPage, chunkIsFirst }) => {
    if (inPage) {
      pagerLog("[PAGER] Resource table rendered")
    }

    const { t } = useTranslation()
    const mappedResources = useMemo(() => {
      const flattenedTree = flattenCloneTree(estimate.task_tree)

      const flattenedResources = flatten(
        flattenedTree.map((node) => node.resources)
      )

      const aggregatedResources = aggregateResources(flattenedResources)

      const groupedByIdResources = groupBy(aggregatedResources, "resource_id")

      const resourcesById = keyBy(estimate.resources, "id")

      return map(groupedByIdResources, (resource) => {
        return {
          resource_id: resource[0].resource_id,
          name: resourcesById[resource[0].resource_id].name,
          name_en: resourcesById[resource[0].resource_id].name_en,
          total_cost: sum(
            resource
              .map((r) => parseFloat(r.unitary_cost) * parseFloat(r.size))
              .filter((n) => !isNaN(n))
          ),
          total_price: sum(
            resource
              .map((r) => parseFloat(r.unitary_price) * parseFloat(r.size))
              .filter((n) => !isNaN(n))
          ),
          details: resource.map((r) =>
            pick(r, "cost_unit", "cost_unit_en", "size", "unitary_cost", "unitary_price")
          ),
          description: resourcesById[resource[0].resource_id].description,
          description_en: resourcesById[resource[0].resource_id].description_en,
          resource_type: resourcesById[resource[0].resource_id].resource_type,
        }
      })
    }, [estimate.resources, estimate.task_tree])

    const filteredMappedResources = useMemo(
      () =>
        mappedResources
          .filter((r) => {
            if (!frag.data.showHR) {
              if (r.resource_type === "hr") {
                return false
              }
            }
            return true
          })
          .filter((r) => {
            if (!frag.data.showService) {
              if (r.resource_type === "service") {
                return false
              }
            }
            return true
          })
          .filter((r) => {
            if (!frag.data.showMaterial) {
              if (r.resource_type === "material") {
                return false
              }
            }
            return true
          }),
      [
        frag.data.showHR,
        frag.data.showMaterial,
        frag.data.showService,
        mappedResources,
      ]
    )

    const sortedResources = useMemo(() => {
      if (frag.data.sortByType) {
        return sortBy(
          filteredMappedResources,
          ["resource_type", "resource_id"],
          ["asc", "asc"]
        )
      }
      return filteredMappedResources
    }, [filteredMappedResources, frag.data.sortByType])

    const hasTasks = estimate.task_tree.length

    return (
      <div className="">
        {frag?.data?.showTitle && chunkIsFirst && (
          <h1 className="__pager-title m-0 pb-3">{frag?.title}</h1>
        )}
        {!hasTasks && (
          <div className="__pager-node disclaimer">
            {t("documents.no_visible_tasks")}
          </div>
        )}
        <ResourceList
          resources={sortedResources}
          isCostVisible={frag.data.isCostVisible}
          isUnitTableVisible={frag.data.isUnitTableVisible}
          forceOpen={frag.data.forceOpen}
          sliceRowStart={sliceRowStart}
          sliceRowEnd={sliceRowEnd}
        />
      </div>
    )
  },
  (prevProps, nextProps) => {
    const checkProps = [
      "frag",
      "estimate",
      "sliceRowStart",
      "sliceRowEnd",
      "chunkIsFirst",
    ]
    for (let i = 0; i < checkProps.length; i++) {
      const prop = checkProps[i]
      if (prevProps[prop] !== nextProps[prop]) {
        return false
      }
    }
    return true
  }
)

export default FragComponent

import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import Icon from "../../../components/Icon"
import Frags from "./Frags"
import S from "./DocFrag.module.scss"
import { get } from "lodash"
import { adaptForScenarios } from "../treeOperations"

function IndirectFrag({ fullEstimate, frag, ...props }) {
  const Component = Frags[frag.fragment_type].FragComponent
  const scenarios = frag.selected_scenarios

  const estimate = useMemo(() => adaptForScenarios(fullEstimate, scenarios), [
    fullEstimate,
    scenarios,
  ])
  return React.createElement(Component, {
    estimate,
    fullEstimate,
    frag,
    isPdf: false,
    readOnly: estimate.readonly,
    ...props,
    chunkIsFirst: props.chunkIsFirst ?? true,
    chunkIsMiddle: props.chunkIsMiddle ?? true,
    chunkIsLast: props.chunkIsLast ?? true,
  })
}

function DocFrag({
  frag,
  onAdd,
  isCurrent,
  onChange,
  onEdit,
  fullEstimate,
  width,
  fullWidth,
  height,
  fullHeight,
  _pager,
  _pagerProps,
  _pagerMeta,
  isPdf,
  logoUrl,
}) {
  const { t } = useTranslation(["translation", "action"])

  return (
    <div className="position-relative" {..._pager}>
      {/* {isFirst && !isCover && !estimate.readonly && (
        <div className="d-flex flex-row justify-content-start align-items-center">
          <Button
            color="primary"
            size="sm"
            rounded
            className={S["add-button"]}
            onClick={() => onAdd(null)}
          >
            <Icon
              name="plus"
              className="pointer"
              title={t("action:new_doc_frag")}
            />
          </Button>
        </div>
      )} */}
      <div id={_pagerProps?.inPage ? `wp-doc-frag-${frag.id}` : ""} />
      <div
        className={S["frag-wrapper"]}
        onClick={() => {
          onEdit(frag.id)
        }}
      >
        <div className="bg-white">
          {width && (
            <IndirectFrag
              fullEstimate={fullEstimate}
              frag={frag}
              save={onChange}
              width={width}
              fullWidth={fullWidth}
              height={height}
              fullHeight={fullHeight}
              {..._pagerProps}
              chunkIsFirst={_pagerMeta?.isFirst}
              chunkIsMiddle={_pagerMeta?.isMiddle}
              chunkIsLast={_pagerMeta?.isLast}
              isPdf={isPdf}
              logoUrl={logoUrl}
            />
          )}
        </div>
      </div>
      {!fullEstimate.readonly &&
        (_pagerMeta === undefined || _pagerMeta.isLast) &&
        typeof onAdd === "function" &&
        !isPdf && (
          <div style={{ position: "absolute", bottom: -12, left: "-0.9cm" }}>
            <Button
              color="primary"
              size="sm"
              rounded
              className={S["add-button"]}
              onClick={() => onAdd(frag.id)}
            >
              <Icon
                name="plus"
                className="pointer"
                title={t("action:new_doc_frag")}
              />
            </Button>
          </div>
        )}
    </div>
  )
}

const DocFragMemo = React.memo(DocFrag)

DocFragMemo.splitContent = function (
  node,
  props,
  firstChunkSpace,
  sheetHeight
) {
  const frag = props.frag
  const splitter = Frags[frag.fragment_type].pageSplitter

  return splitter(node, props, firstChunkSpace, sheetHeight)
}

DocFragMemo.preventAutomaticPagination = function (node, props) {
  const frag = props.frag
  return get(Frags[frag.fragment_type], "preventAutomaticPagination", false)
}

DocFragMemo.requestSpacing = function (props) {
  const frag = props.frag
  const fn = get(Frags[frag.fragment_type], "requestSpacing", null)
  if (fn) {
    return fn(props)
  } else {
    const r = props.frag?.data?.page_margin
    if (r === null || r === undefined || r === "") {
      return null
    }
    return r
  }
}

export default DocFragMemo

import { rj, useRunRj } from "react-rocketjump"
import { mergeMap } from "rxjs/operators"
import api from "../api"

const ProjectRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (id) => {
    return api.auth(wpAuth).get(`/api/project/${id}/`)
  },
  computed: {
    project: "getData",
    pending: "isPending",
    error: "getError",
  },
  mutations: {
    updateProject: rj.mutation.single({
      effect: (wpAuth) => (id, data) => {
        return api.auth(wpAuth).patch(`/api/project/${id}/`, data)
      },
      updater: "updateData",
      optimisticResult: (id, data) => data,
      optimisticUpdater: (state, data) => ({
        ...state,
        data: {
          ...state.data,
          ...data,
        },
      }),
    }),
    importEstimate: rj.mutation.single({
      effect: (wpAuth) => (projectId, estimateAndTasks) => {
        return api
          .auth(wpAuth)
          .post(`/api/project/${projectId}/import_estimate/`, estimateAndTasks)
          .pipe(
            mergeMap(() => {
              return api.auth(wpAuth).get(`/api/project/${projectId}`)
            })
          )
      },
      updater: (state, result) => ({
        ...state,
        data: {
          ...state.data,
          linked_estimates: result.linked_estimates,
        },
      }),
    }),
    importBills: rj.mutation.single({
      effect: (wpAuth) => (projectId, data) => {
        return api.auth(wpAuth).post(`/api/project/${projectId}/import_estimate_bills/`, data)
      },
      updater: (state, result) => state,
    }),
    writeAuditTrail: rj.mutation.single({
      effect: (wpAuth) => (projectId, data) => {
        const fd = new FormData()
        for (const key in data) {
          if (data[key]) {
            fd.append(key, data[key])
          }
        }
        return api.auth(wpAuth).post(`/api/project/${projectId}/audit_trail_edit/`, fd)
      },
      updater: "updateData",
    }),
  },
})

export function useProject(id) {
  return useRunRj(ProjectRj, [id])
}

const ProjectFlowRj = rj({
  name: "ProjectFlow",
  effectCaller: rj.configured(),
  effect: (wpAuth) => (id) => api.auth(wpAuth).get(`/api/project/${id}/flow`),
  computed: {
    graph: "getData",
    loading: "isPending",
    error: "getError",
  },
})

export function useProjectFlow(id) {
  return useRunRj(ProjectFlowRj, [id], true)
}

const ProjectAuditTrailRj = rj({
  name: "ProjectAuditTrail",
  effectCaller: rj.configured(),
  effect: (wpAuth) => (id) => api.auth(wpAuth).get(`/api/project/${id}/audit-trail/`),
  computed: {
    data: "getData",
    loading: "isPending",
    error: "getError",
  },
})

export function useProjectAuditTrail(id) {
  return useRunRj(ProjectAuditTrailRj, [id], true)
}
import React, { Suspense, useEffect } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useTranslation } from "react-i18next"
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { useAuthUser } from "use-eazy-auth"
import { AuthRoute, AuthRoutesProvider, GuestRoute, MaybeAuthRoute } from "use-eazy-auth/routes"
import WpAuth from "./auth/WpAuth"
import { InteractionModalProvider } from "./components/InteractionModalContext"
import Loader from "./components/Loader/Loader"
import MinScreenSize from "./components/MinScreenSize"
import NotFound from "./components/NotFound"
import { CAP, CAPLEVEL, useCapabilities } from "./hooks/capabilities"
import { QuotaProvider } from "./hooks/quota"
import useCurrentOrganization from "./hooks/useCurrentOrganization"
import { WpClipboardProvider } from "./hooks/wpClipboard"
import BusinessInteractions from "./pages/BusinessInteractions"
import { BusinessMonitoring } from "./pages/BusinessMonitoring"
import Customer from "./pages/Customer"
import { CustomersList } from "./pages/CustomersList"
import { Deadlines } from "./pages/Deadlines"
import Estimate from "./pages/Estimate"
import EstimatesList from "./pages/EstimatesList"
import { GlobalComplianceIssueReportList } from "./pages/GlobalComplianceIssueReportList"
import Home from "./pages/Home"
import { HrWorkflowListNew } from "./pages/HrWorkflow/HrWorkflowListNew"
import { HrWorkflowNew } from "./pages/HrWorkflow/HrWorkflowNew"
import { HrWorkflowResources } from "./pages/HrWorkflow/HrWorkflowResources"
import { Indicators } from "./pages/Indicators"
import JoinOrganization from "./pages/JoinOrganization"
import Login from "./pages/Login"
import { ManageTags } from "./pages/ManageTags"
import NewOrganization from "./pages/NewOrganization"
import Notifications from "./pages/Notifications"
import OrganizationSettings from "./pages/OrganizationSettings"
import { Overview } from "./pages/Overview"
import PasswordRecover from "./pages/PasswordRecover/PasswordRecover"
import PasswordReset from "./pages/PasswordReset/PasswordReset"
import PriceLists from "./pages/PriceLists"
import Profile from "./pages/Profile"
import Project from "./pages/Project"
import ProjectsList from "./pages/ProjectsList/ProjectsList"
import { Requests } from "./pages/Requests"
import ResourcesList from "./pages/ResourcesList"
import Templates from "./pages/Templates"
import { TrackerCalendar } from "./pages/TrackerCalendar"
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail"
import { HrWorkflowAnomalyDetectionRules } from "./pages/HrWorkflow/HrWorkflowAnomalyDetectionRules"

// NOTE: We can use <Route /> instead of <AuthRoute />
// cause parent alredy it wrap  in <AuthRoute />
const Organization = ({ match }) => {
  const organization = useCurrentOrganization()
  const [, { hasCapability }] = useCapabilities()

  const showEstimates = hasCapability(CAP.ESTIMATES)
  const showResources = hasCapability(CAP.RESOURCES)
  const showTemplates = hasCapability(CAP.TEMPLATES)
  const showProjects = hasCapability(CAP.PROJECTS)
  const showDeadlines = hasCapability(CAP.DEADLINES)
  const showCalendar = hasCapability(CAP.CALENDAR)
  const showNCReports = hasCapability(CAP.NC_REPORT)
  const showCustomers = hasCapability(CAP.CUSTOMER)
  const showRequests = hasCapability(CAP.REQUESTS)
  const showInteractions = hasCapability(CAP.INTERACTIONS)
  const showIndicators = hasCapability(CAP.INDICATORS, CAPLEVEL.LIMITED_READ)
  const showOverview = hasCapability(CAP.OVERVIEW, CAPLEVEL.LIMITED_READ)
  const showBusinessMonitor = hasCapability(CAP.BUSINESS, CAPLEVEL.LIMITED_READ)
  const showTags = hasCapability(CAP.TAGS, CAPLEVEL.LIMITED_READ)

  if (!organization) {
    return <Redirect to="/" />
  }

  return (
    <Switch>
      <Route path={`${match.path}/`} exact>
        {/* NOTE: Force mount/unmount of home when org changes */}
        <Home key={organization.id} />
      </Route>
      {showResources && <Route path={`${match.path}/resources`} exact component={ResourcesList} />}
      {showEstimates && <Route path={`${match.path}/estimates`} exact component={EstimatesList} />}
      {showEstimates && <Route path={`${match.path}/estimates/:id`} component={Estimate} />}
      {showResources && <Route path={`${match.path}/pricelists`} component={PriceLists} />}
      {showTemplates && <Route path={`${match.path}/templates`} component={Templates} />}
      <Route path={`${match.path}/settings`} component={OrganizationSettings} />
      {showProjects && <Route path={`${match.path}/projects`} exact component={ProjectsList} />}
      {showProjects && <Route path={`${match.path}/projects/:id`} component={Project} />}
      <Route path={`${match.path}/notifications`} component={Notifications} />
      {showCustomers && <Route path={`${match.path}/customers`} exact component={CustomersList} />}
      {showCustomers && <Route path={`${match.path}/customers/:id`} component={Customer} />}
      {showOverview && <Route path={`${match.path}/overview`} component={Overview} />}
      {showRequests && <Route path={`${match.path}/requests`} component={Requests} />}
      {showIndicators && <Route path={`${match.path}/indicators`} component={Indicators} />}
      {showBusinessMonitor && <Route path={`${match.path}/business`} component={BusinessMonitoring} />}
      {showProjects && showDeadlines && <Route path={`${match.path}/deadlines`} component={Deadlines} />}
      {showTags && <Route path={`${match.path}/tags`} component={ManageTags} />}
      {showCalendar && <Route path={`${match.path}/tracker-calendar`} component={TrackerCalendar} />}
      {showProjects && showNCReports && (
        <Route path={`${match.path}/compliance-issue-report`} component={GlobalComplianceIssueReportList} />
      )}
      {showInteractions && <Route path={`${match.path}/businessinteractions`} exact component={BusinessInteractions} />}
      <Route path={`${match.path}/hr/workflow`} exact component={HrWorkflowNew} />
      <Route path={`${match.path}/hr/workflow-list`} exact component={HrWorkflowListNew} />
      <Route path={`${match.path}/hr/workflow-resources`} exact component={HrWorkflowResources} />
      <Route path={`${match.path}/hr/workflow-rules`} exact component={HrWorkflowAnomalyDetectionRules} />
      <Route component={NotFound} />
    </Switch>
  )
}

function SyncLang() {
  const { user } = useAuthUser()
  const { i18n } = useTranslation()
  const userLanguage = user?.lang ?? null
  const actualLanguage = i18n.language

  useEffect(() => {
    if (userLanguage && userLanguage !== actualLanguage) {
      // When user has language set user language
      i18n.changeLanguage(userLanguage)
    }
  }, [userLanguage, actualLanguage, i18n])

  return null
}

function Routes() {
  const [, { hasCapability }] = useCapabilities()

  const showEstimates = hasCapability(CAP.ESTIMATES)
  const showResources = hasCapability(CAP.RESOURCES)
  const showTemplates = hasCapability(CAP.TEMPLATES)
  const showProjects = hasCapability(CAP.PROJECTS)
  const showDeadlines = hasCapability(CAP.DEADLINES)
  const showCalendar = hasCapability(CAP.CALENDAR)
  const showNCReports = hasCapability(CAP.NC_REPORT)
  const showCustomers = hasCapability(CAP.CUSTOMER)
  const showRequests = hasCapability(CAP.REQUESTS)
  const showInteractions = hasCapability(CAP.INTERACTIONS)
  const showIndicators = hasCapability(CAP.INDICATORS, CAPLEVEL.LIMITED_READ)
  const showOverview = hasCapability(CAP.OVERVIEW, CAPLEVEL.LIMITED_READ)
  const showBusinessMonitor = hasCapability(CAP.BUSINESS, CAPLEVEL.LIMITED_READ)
  const showTags = hasCapability(CAP.TAGS, CAPLEVEL.LIMITED_READ)

  return (
    <Switch>
      <GuestRoute path="/login" exact component={Login} />
      <GuestRoute path="/signup" exact>
        <Login openOnSignup={true} />
      </GuestRoute>
      <AuthRoute path="/" exact component={Home} />
      <AuthRoute path="/profile" exact component={Profile} />
      {showResources && <AuthRoute path="/resources" exact component={ResourcesList} />}
      {showEstimates && <AuthRoute path="/estimates" exact component={EstimatesList} />}
      {showEstimates && <AuthRoute path="/estimates/:id" component={Estimate} />}
      {showResources && <AuthRoute path="/pricelists" component={PriceLists} />}
      {showTemplates && <AuthRoute path="/templates" component={Templates} />}
      {showInteractions && <AuthRoute path="/businessinteractions" exact component={BusinessInteractions} />}
      {showCustomers && <AuthRoute path="/customers" exact component={CustomersList} />}
      {showCustomers && <AuthRoute path="/customers/:id" component={Customer} />}
      {showRequests && <AuthRoute path="/requests" exact component={Requests} />}
      {showOverview && <AuthRoute path="/overview" exact component={Overview} />}
      {showBusinessMonitor && <AuthRoute path="/business" exact component={BusinessMonitoring} />}
      {showProjects && showDeadlines && <AuthRoute path="/deadlines" exact component={Deadlines} />}
      {showProjects && showNCReports && (
        <AuthRoute path="/compliance-issue-report" exact component={GlobalComplianceIssueReportList} />
      )}
      {showTags && <AuthRoute path="/tags" exact component={ManageTags} />}
      {showProjects && <AuthRoute path="/projects" exact component={ProjectsList} />}
      {showProjects && <AuthRoute path="/projects/:id" component={Project} />}
      {showIndicators && <AuthRoute path="/indicators" exact component={Indicators} />}
      {showCalendar && <AuthRoute path="/tracker-calendar" exact component={TrackerCalendar} />}
      <AuthRoute path="/org/new" component={NewOrganization} />
      <AuthRoute path="/org/join/:token" component={JoinOrganization} />
      <AuthRoute path="/verify/:token" component={VerifyEmail} />
      <AuthRoute path="/org/:organization" component={Organization} />
      <AuthRoute path="/notifications" component={Notifications} />
      <Route path="/recoverpasswd" component={PasswordRecover} />
      <Route path="/resetpasswd/:token" component={PasswordReset} />
      <MaybeAuthRoute component={NotFound} />
    </Switch>
  )
}

function App() {
  return (
    <Suspense fallback={<Loader.BaseLoader />}>
      <MinScreenSize>
        <DndProvider backend={HTML5Backend}>
          <AuthRoutesProvider spinner={<Loader.BaseLoader />}>
            <WpClipboardProvider>
              <ToastContainer position="bottom-right" autoClose={2000} />
              <Router>
                <WpAuth>
                  <SyncLang />
                  <InteractionModalProvider>
                    <QuotaProvider>
                      <Routes />
                    </QuotaProvider>
                  </InteractionModalProvider>
                </WpAuth>
              </Router>
            </WpClipboardProvider>
          </AuthRoutesProvider>
        </DndProvider>
      </MinScreenSize>
    </Suspense>
  )
}

export default App

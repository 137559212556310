import { get, omit } from "lodash"
import find from "lodash/find"
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react"
import Scrollbars from "react-custom-scrollbars"
import { I18nextProvider, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal"
import Icon from "../../../components/Icon"
import Layout from "../../../components/Layout"
import TabButton from "../../../components/TabButton"
import {
  DEFAULT_FOOTER_CONFIG,
  DEFAULT_THEME_COLOR,
  DEFAULT_THEME_FONT,
  DOC_FRAGMENT_TYPE,
} from "../../../consts"
import { CurrentEstimateStateContext } from "../../../context"
import { useEstimateDocument } from "../../../hooks/estimateDocument"
import { useTemplateDocFragments } from "../../../hooks/templateDocFragments"
import useCurrentOrganization from "../../../hooks/useCurrentOrganization"
import useModalTrigger from "../../../hooks/useModalTrigger"
import useModalTriggerQuota from "../../../hooks/useModalTriggerQuota"
import useWindowSize from "../../../hooks/useWindowSize"
import { notifyError } from "../../../utils"
import EstimateBottomBarContent from "../EstimateBottomBarContent"
import EstimateTabBarContent from "../EstimateTabBarContent"
import AddFragModal from "./AddFragModal/AddFragModal"
import DocFrag from "./DocFrag"
import DocumentSettings from "./DocumentSettings"
import DocumentStructure from "./DocumentStructure"
import DownloadButton from "./DownloadButton"
import EmptyFrags from "./EmptyFrags"
import S from "./EstimateDocument.module.scss"
import TS from "./Typography.module.scss"
import Footer from "./Footer"
import Frags from "./Frags"
import Pager from "./Pager"
import PickCoverModal from "./PickCoverModal"
import SaveTemplateModal from "./SaveTemplateModal"
import { convertColorToTheme } from "./utils"
import { EstimateLandmarks } from "../../../components/EstimateLandmarks"

// TODO: Implement default filters
const TPL_COVER_FILTERS = { fragment_type: "cover" }

function isPageBreakAllowed(fragments, refFragId) {
  const refFragIdx = fragments.findIndex((frag) => frag.id === refFragId)
  if (refFragIdx >= 0) {
    const refFrag = fragments[refFragIdx]
    if (refFrag.fragment_type === DOC_FRAGMENT_TYPE.PAGE_BREAK) {
      // Fragment before me is a page-break
      return false
    }
    const fragAfterRef =
      refFragIdx < fragments.length - 1 ? fragments[refFragIdx + 1] : undefined
    if (fragAfterRef === undefined) {
      // I am trying to add a page break at the end of the document
      return false
    }
    if (fragAfterRef?.fragment_type === DOC_FRAGMENT_TYPE.PAGE_BREAK) {
      // Fragment after me is a page-break
      return false
    }
    return true
  } else {
    return false
  }
}

export default function EstimateDocument() {
  const { id } = useParams()
  const { t, i18n } = useTranslation(["translation", "tab", "action", "enums"])
  const [innerI18n] = useState(i18n.cloneInstance())

  const [{ estimate, fullEstimate }] = useContext(CurrentEstimateStateContext)
  const [
    { doc },
    { addFragment, removeFragment, patchFragment, moveFragment, patchDocument },
  ] = useEstimateDocument(id)

  const [editFragModal, editFragModalActions] = useModalTrigger()
  const editFragId = editFragModal.value

  const scrollbarsRef = useRef()
  const scrollReferenceRef = useRef()

  const handleTerminateFragEdit = useCallback(() => {
    // TODO find a better strategy
    editFragModalActions.close()
    editFragModalActions.onClose()
  }, [editFragModalActions])

  const [addFragModal, addFragModalActions] = useModalTrigger()
  const [saveTemplateModal, saveTemplateModalActions] = useModalTriggerQuota()
  const [deleteFragModal, deleteFragModalActions] = useModalTrigger()
  const [pickCoverModal, pickCoverModalActions] = useModalTrigger()

  const editFrag = useMemo(() => {
    if (editFragId === null) {
      return null
    }
    return find(doc.fragments, { id: editFragId })
  }, [editFragId, doc])

  const nextFragmentNr = doc?.fragments?.length

  const handleAddFrag = useCallback(
    (type, props = {}, title = undefined) => {
      addFragment
        .onSuccess((created) => {
          addFragModalActions.close()
        })
        .run({
          fragment_type: type,
          title: title ?? type + "_" + nextFragmentNr,
          selected_scenarios: [],
          after: addFragModal.value,
          data: {
            ...Frags[type]?.defaultData,
            ...props,
          },
        })
    },
    [addFragModal.value, addFragModalActions, addFragment, nextFragmentNr]
  )

  const [
    { fragments: coverTplFragments },
    { createFrag },
  ] = useTemplateDocFragments(TPL_COVER_FILTERS)

  const handleSaveTemplate = useCallback(
    (title, frag) => {
      return createFrag
        .onSuccess((frag) => {
          saveTemplateModalActions.close()
        })
        .asPromise(omit({ ...frag, title: title }, "id"))
    },
    [createFrag, saveTemplateModalActions]
  )

  const handleRemoveFragment = useCallback(
    (frag) => {
      if (get(Frags[frag.fragment_type], "uncheckedDelete", false)) {
        removeFragment(frag.id)
      } else {
        deleteFragModalActions.open(frag)
      }
    },
    [deleteFragModalActions, removeFragment]
  )

  const createCover = () => {
    const defaultCover = find(coverTplFragments ?? [], {
      is_default: true,
      fragment_type: DOC_FRAGMENT_TYPE.COVER,
    })?.data
    handleAddFrag(DOC_FRAGMENT_TYPE.COVER, defaultCover)
  }

  const handleSaveFrag = useCallback(
    (values) => {
      return patchFragment.onFailure(notifyError).asPromise(values)
    },
    [patchFragment]
  )

  const hasCover = doc?.fragments[0]?.fragment_type === "cover"
  const isCoverDisabled = hasCover && doc?.fragments[0]?.data?.enabled === false
  const hasOnlyDisabledCover =
    hasCover && isCoverDisabled && doc?.fragments.length === 1

  const handleDisableCover = useCallback(() => {
    if (doc?.fragments[0].fragment_type === "cover") {
      return handleSaveFrag({
        ...doc.fragments[0],
        data: {
          ...doc.fragments[0].data,
          enabled: false,
        },
      })
    }
  }, [doc?.fragments, handleSaveFrag])

  const handleEnableCover = useCallback(() => {
    if (doc?.fragments?.[0]?.fragment_type === "cover") {
      return handleSaveFrag({
        ...doc.fragments[0],
        data: {
          ...doc.fragments[0].data,
          enabled: true,
        },
      })
    }
  }, [doc?.fragments, handleSaveFrag])

  const documentWithoutCover = useMemo(() => {
    if (!doc) {
      return doc
    }
    return {
      ...doc,
      fragments: doc.fragments.filter((frag) => frag.fragment_type !== "cover"),
    }
  }, [doc])

  const handleAddFragClick = useCallback(() => {
    addFragModalActions.open(hasCover ? doc.fragments[0].id : null)
  }, [addFragModalActions, doc?.fragments, hasCover])

  const scrollFragIntoView = useCallback((frag) => {
    const baseTop = scrollReferenceRef.current.getBoundingClientRect().top
    const top = document
      .getElementById(`wp-doc-frag-${frag.id}`)
      .getBoundingClientRect().top
    scrollbarsRef.current.scrollTop(Math.abs(baseTop - top))
  }, [])

  //#region THEMING
  const org = useCurrentOrganization()

  const defaultColor = org.brand_color ?? DEFAULT_THEME_COLOR
  const defaultFont = org.theme_font ?? DEFAULT_THEME_FONT
  const defaultHeadersFont = org.theme_headers_font ?? DEFAULT_THEME_FONT

  const color = doc?.meta?.color ?? defaultColor
  const setColor = useCallback(
    (color) => {
      patchDocument.onFailure(notifyError).run({
        ...doc,
        meta: {
          ...doc.meta,
          color,
        },
      })
    },
    [patchDocument, doc]
  )

  const font = doc?.meta?.font ?? defaultFont
  const setFont = useCallback(
    (font) => {
      patchDocument.onFailure(notifyError).run({
        ...doc,
        meta: {
          ...doc.meta,
          font,
        },
      })
    },
    [patchDocument, doc]
  )

  const lang = doc?.meta?.lang ?? i18n.language
  const setLang = useCallback(
    (lang) => {
      innerI18n.changeLanguage(lang)
      patchDocument.onFailure(notifyError).run({
        ...doc,
        meta: {
          ...doc.meta,
          lang,
        },
      })
    },
    [innerI18n, patchDocument, doc]
  )

  const fontHeadings = doc?.meta?.fontHeadings ?? defaultHeadersFont
  const setFontHeadings = useCallback(
    (fontHeadings) => {
      patchDocument.onFailure(notifyError).run({
        ...doc,
        meta: {
          ...doc.meta,
          fontHeadings,
        },
      })
    },
    [patchDocument, doc]
  )

  const onCoverPicked = useCallback(
    (cover) => {
      const actualCover = doc.fragments[0]
      patchFragment
        .onSuccess(() => setForceCover((a) => a + 1))
        .run({
          ...actualCover,
          data: cover.data,
        })
    },
    [doc?.fragments, patchFragment]
  )

  // NOTE: Sorry for the workaround but for now is the cheapest solution...
  const [forceCover, setForceCover] = useState(0)

  const theme = convertColorToTheme(color)

  const [overviewTab, setOverviewTab] = useState("options")

  const organization = useCurrentOrganization()

  const logoUrl = organization.logo

  const footerConfig = doc?.meta?.footer ?? DEFAULT_FOOTER_CONFIG
  const setFooterConfig = useCallback(
    (pos, value) => {
      patchDocument.onFailure(notifyError).run({
        ...doc,
        meta: {
          ...doc.meta,
          footer: {
            ...DEFAULT_FOOTER_CONFIG,
            ...doc.meta?.footer,
            [pos]: value,
          },
        },
      })
    },
    [patchDocument, doc]
  )

  const { width: windowWidth } = useWindowSize()

  return (
    <Layout
      displayRawContent
      className="flex-1 d-flex flex-column justify-content-start align-items-stretch"
    >
      <div className="flex-1 d-flex flex-row justify-content-start align-items-stretch">
        <div
          style={{
            flex: "0 1 40%",
            position: "relative",
            overflow: "visible",
            zIndex: 2,
          }}
          className="border-right border-dark"
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              overflow: "visible",
            }}
          >
            {!editFrag && (
              <div className="d-flex flex-row w-100 h-100 justify-content-start align-items-stretch">
                <div className="d-flex flex-column justify-content-start align-items-start pt-8 pl-4 pr-5">
                  <TabButton
                    iconName="settings"
                    onClick={() => setOverviewTab("options")}
                    active={overviewTab === "options"}
                    className="mb-6"
                  >
                    {t("documents.overview.options_tab")}
                  </TabButton>
                  <TabButton
                    iconName="document"
                    onClick={() => setOverviewTab("structure")}
                    active={overviewTab === "structure"}
                    className="mb-6"
                  >
                    {t("documents.overview.structure_tab")}
                  </TabButton>
                </div>
                <div className="flex-1 h-100">
                  <Scrollbars style={{ width: "100%", height: "100%" }}>
                    <div
                      className="h-100"
                      style={{
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingRight: 12,
                      }}
                    >
                      {overviewTab === "options" && (
                        <DocumentSettings
                          doc={doc}
                          readonly={estimate.readonly}
                          patchDocument={patchDocument}
                          handleSaveFrag={handleSaveFrag}
                          createCover={createCover}
                          hasCover={hasCover}
                          isCoverDisabled={isCoverDisabled}
                          color={color}
                          setColor={setColor}
                          defaultColor={defaultColor}
                          font={font}
                          setFont={setFont}
                          defaultFont={defaultFont}
                          fontHeadings={fontHeadings}
                          setFontHeadings={setFontHeadings}
                          defaultHeadersFont={defaultHeadersFont}
                          footerConfig={footerConfig}
                          setFooterConfig={setFooterConfig}
                          handleEnableCover={handleEnableCover}
                          handleDisableCover={handleDisableCover}
                          lang={lang}
                          setLang={setLang}
                        />
                      )}
                      {overviewTab === "structure" && (
                        <div className="pl-3">
                          <DocumentStructure
                            document={documentWithoutCover}
                            moveFragment={moveFragment}
                            initialId={hasCover ? doc.fragments[0].id : null}
                            disabled={estimate.readonly}
                            scrollFragIntoView={scrollFragIntoView}
                            goToConfig={(frag) => {
                              editFragModalActions.open(frag.id)
                            }}
                            onAddFrag={addFragModalActions.open}
                          />
                        </div>
                      )}
                    </div>
                  </Scrollbars>
                </div>
              </div>
            )}
            {editFrag &&
              React.createElement(Frags[editFrag.fragment_type].EditDataForm, {
                key:
                  editFrag.fragment_type === DOC_FRAGMENT_TYPE.COVER
                    ? editFrag.id + "_" + forceCover
                    : editFrag.id,
                fullEstimate: fullEstimate,
                frag: editFrag,
                save: handleSaveFrag,
                remove: handleRemoveFragment,
                saveTemplate: saveTemplateModalActions.open,
                pickCover: pickCoverModalActions.open,
                toggle: handleTerminateFragEdit,
                scrollFragIntoView: scrollFragIntoView,
              })}
            {editFrag && (
              <div
                className={S["close-edit-panel-icon"]}
                onClick={() => {
                  handleTerminateFragEdit()
                }}
              >
                <Icon name="close" />
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            flex: "1 0 60%",
            position: "relative",
            zIndex: 1,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <I18nextProvider i18n={innerI18n}>
              <Scrollbars ref={scrollbarsRef}>
                {doc && estimate && (
                  <div
                    ref={scrollReferenceRef}
                    style={{
                      "--theme": theme,
                      "--font": font,
                      "--fontHeadings": fontHeadings,
                    }}
                  >
                    <Pager
                      footer={(page, totPages) => (
                        <Footer
                          organization={organization}
                          estimate={fullEstimate}
                          doc={doc}
                          currentPage={page}
                          numPages={totPages}
                        />
                      )}
                      footerSkipBeginning={hasCover ? 1 : 0}
                      pageClassName={TS["styledPage"]}
                      fitWidth={windowWidth * 0.6}
                      paddingCm={1}
                    >
                      {({ width, fullWidth, height, fullHeight }) => {
                        const elements = []
                        if (
                          doc.fragments.length === 0 ||
                          hasOnlyDisabledCover
                        ) {
                          elements.push(
                            <Pager.Element
                              id={"0"}
                              as={EmptyFrags}
                              onClick={handleAddFragClick}
                              readOnly={estimate.readonly}
                            />
                          )
                        }
                        elements.push(
                          ...doc.fragments
                            .filter(
                              (frag) =>
                                (frag.fragment_type !==
                                  DOC_FRAGMENT_TYPE.COVER ||
                                  frag.data.enabled) &&
                                frag.fragment_type in Frags
                            )
                            .map((frag, i) => (
                              <Pager.Element
                                id={frag.id}
                                as={DocFrag}
                                onEdit={editFragModalActions.open}
                                isCurrent={editFragModal.value === frag.id}
                                onChange={handleSaveFrag}
                                estimate={estimate}
                                fullEstimate={fullEstimate}
                                isFirst={i === 0}
                                isCover={
                                  frag.fragment_type === DOC_FRAGMENT_TYPE.COVER
                                }
                                onAdd={addFragModalActions.open}
                                key={frag.id}
                                frag={frag}
                                onRemove={removeFragment}
                                onSaveTemplate={createFrag}
                                width={width}
                                fullWidth={fullWidth}
                                height={height}
                                fullHeight={fullHeight}
                                logoUrl={logoUrl}
                              />
                            ))
                        )
                        return elements
                      }}
                    </Pager>
                    <AddFragModal
                      onAdd={handleAddFrag}
                      isOpen={addFragModal.isOpen}
                      toggle={addFragModalActions.toggle}
                      onClosed={addFragModalActions.onClose}
                      allowPageBreak={isPageBreakAllowed(
                        documentWithoutCover.fragments,
                        addFragModal.value
                      )}
                    />
                    <SaveTemplateModal
                      onSave={(title) =>
                        handleSaveTemplate(title, saveTemplateModal.value)
                      }
                      isOpen={saveTemplateModal.isOpen}
                      toggle={saveTemplateModalActions.toggle}
                      onClosed={saveTemplateModalActions.onClose}
                      type={saveTemplateModal.value?.fragment_type}
                    />
                    {deleteFragModal.value && (
                      <ConfirmDeleteModal
                        toggle={deleteFragModalActions.toggle}
                        isOpen={deleteFragModal.isOpen}
                        onConfirm={() => {
                          const frag = deleteFragModal.value
                          handleTerminateFragEdit()
                          removeFragment(frag.id)
                        }}
                        item={deleteFragModal.value?.title ?? ""}
                        onClosed={deleteFragModalActions.onClose}
                      />
                    )}
                    <PickCoverModal
                      toggle={pickCoverModalActions.toggle}
                      isOpen={pickCoverModal.isOpen}
                      onPick={onCoverPicked}
                      onClosed={pickCoverModalActions.onClose}
                    />
                  </div>
                )}
              </Scrollbars>
            </I18nextProvider>
          </div>
          <div style={{ position: "absolute", bottom: 32, right: 32 }}>
            <DownloadButton estimate={estimate} document={doc} />
          </div>
        </div>
      </div>

      <Layout.TabBar>
        <EstimateTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <EstimateBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <EstimateLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import React, { useEffect, useRef } from "react"
import { useAuthActions, useAuthState } from "use-eazy-auth"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import { Button } from "reactstrap"
import { useTranslation } from "react-i18next"
import logo from "../assets/social/google.png"
import classNames from "classnames"

export default function GoogleAuthButton({ joinToken, className, ...props }) {
  const googleBtnRef = useRef()
  const { login } = useAuthActions()
  const { loginLoading } = useAuthState()
  const { t } = useTranslation()

  const [{ token }] = useQueryParams()

  useEffect(() => {
    if (token) {
      login({ provider: "__wooplan", socialToken: token })
    }
  }, [login, token])

  return (
    <Button
      disabled={loginLoading}
      innerRef={googleBtnRef}
      type="button"
      outline
      color="primary"
      className={classNames("d-flex flex-row justify-content-center align-items-center", className)}
      onClick={() => {
        window.location.href = `https://app.wooplan.com/api/connect/google/login/?redirect_url=${window.location.href}`
      }}
      {...props}
    >
      <img src={logo} style={{ width: 16, height: 16, marginRight: 4 }} alt="" />
      {t("login.google")}
    </Button>
  )
}

import React from "react"
import { useTranslation } from "react-i18next"
import RichTextEditor from "../../../../../components/RichTextEditor/RichTextEditor"
import { cutTree } from "../../../EstimateTasksTree/TaskTable/utils"
import Dendrogram from "../../../EstimateVisualization/EstimateTreeViz/Dendrogram"
import {
  calcHorizontalHeight,
  calcVerticalHeight,
} from "../../../EstimateVisualization/EstimateTreeViz/utils"
import { sliceExtent } from "../../../treeOperations"
import { pagerLog } from "../../Pager"

const FragComponent = React.memo(
  ({ frag, estimate, width, sheetHeight, inPage, chunkIsFirst }) => {
    if (inPage) {
      pagerLog("[PAGER] Dendrogram rendered")
    }
    const orientation = frag.data.orientation
    const cutInDepthTree = cutTree(estimate.task_tree, frag.data.maxDepth)
    const cutSelectTree = sliceExtent(cutInDepthTree, frag.data.visibleTasks)

    let height
    if (orientation && orientation === "horizontal") {
      height = calcHorizontalHeight(cutSelectTree.children)
    } else {
      height = calcVerticalHeight(cutSelectTree.children)
    }

    const { t } = useTranslation()

    // A4 sheet with 1cm margin
    const guessSheetHeightPx = (width / 19) * (27.7 - 5)
    height = Math.min(height, guessSheetHeightPx, sheetHeight || Infinity)

    const hasTasks = estimate.task_tree.length > 0

    return (
      <div className="">
        {frag?.data?.showTitle && chunkIsFirst && (
          <h1 className="__pager-title m-0 pb-3">{frag?.title}</h1>
        )}
        {!hasTasks && (
          <div className="__pager-disclaimer disclaimer">
            {t("documents.no_visible_tasks")}
          </div>
        )}
        {hasTasks && (
          <div className="bg-white overflow-hidden text-center page-break-inside-avoid">
            <Dendrogram
              width={
                orientation === "horizontal"
                  ? Math.min(1100, width)
                  : Math.min(
                      calcHorizontalHeight(cutSelectTree.children),
                      width
                    )
              }
              height={height}
              orientation={frag.data.orientation}
              linkType={frag.data.link_type}
              data={cutSelectTree}
            />
          </div>
        )}
        {frag.data.enableCaption && (
          <div
            className="__pager-dendro-caption"
            style={{ flex: "1 1 0%", minWidth: 0, paddingLeft: 4 }}
          >
            <RichTextEditor
              content={frag?.data?.caption}
              readOnly={true}
              placeholder={""}
              enableImages={false}
              minHeight0
            />
          </div>
        )}
      </div>
    )
  },
  (prevProps, nextProps) => {
    const checkProps = [
      "frag",
      "estimate",
      "width",
      "sheetHeight",
      "chunkIsFirst",
    ]
    for (let i = 0; i < checkProps.length; i++) {
      const prop = checkProps[i]
      if (prevProps[prop] !== nextProps[prop]) {
        return false
      }
    }
    return true
  }
)

export default FragComponent

import { debounce, get, uniqueId } from "lodash"
import useConstant from "magik-react-hooks/useConstant"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import classNames from "classnames"
import { mm2px } from "./utils"

const ENABLE_PAGER_LOGS = false

export function pagerLog(...args) {
  if (ENABLE_PAGER_LOGS) {
    console.log(...args)
  }
}

function Element(props) {
  return null
}

const PagerContext = React.createContext({
  requestPagination: () => {},
})

export function usePager() {
  return useContext(PagerContext)
}

export default function Pager({
  footer,
  footerSkipBeginning,
  children,
  spacing: spacingMm = 5,
  pretty = true,
  pageClassName,
  fitWidth = undefined,
  paddingCm = 0
}) {
  const width = useConstant(() => mm2px(190))
  const fullWidth = useConstant(() => mm2px(210))
  const height = useConstant(() => mm2px(277))
  const fullHeight = useConstant(() => mm2px(297))
  const oneCm = useConstant(() => mm2px(10))

  const childrenArray = children({ width, fullWidth, height, fullHeight })
  const domRef = useRef()
  const storeRef = useRef({
    elements: [],
    pages: null,
  })
  const sheetRef = useRef()

  const [pass, setPass] = useState(0)
  const [pages, setPages] = useState(null)
  const prevPass = useRef(0)
  const awaitFontRef = useRef(true)

  const doPaginate = useCallback(() => {
    const sheetBBox = sheetRef.current.getBoundingClientRect()
    const footerBBox =
      footer && sheetRef.current.firstChild.getBoundingClientRect()
    const sheetHeight = Math.floor(sheetBBox.height)
    let currentPageHeight = 0
    const pages = [[]]
    for (let i = 0; i < childrenArray.length; i++) {
      const currentPage = pages.length
      const currentSheetHeight =
        currentPage > footerSkipBeginning
          ? sheetHeight - footerBBox?.height + oneCm
          : sheetHeight
      const child = childrenArray[i]
      if (child.type === Element) {
        const elementId = storeRef.current.elements[i]
        const node = document.getElementById(elementId)
        const bbox = node.getBoundingClientRect()
        const height = bbox.height
        const { as, id, ...others } = child.props
        pagerLog("Found element with key " + id)
        pagerLog("Element has height " + height)
        pagerLog("Available space is " + (currentSheetHeight - currentPageHeight))
        const autoPaginationCheck = get(
          as,
          "preventAutomaticPagination",
          () => false
        )
        let autoPaginationPrevented = false
        if (typeof autoPaginationCheck === "function") {
          autoPaginationPrevented = autoPaginationCheck(node, others)
        }
        if (
          currentPageHeight + height > currentSheetHeight ||
          autoPaginationPrevented
        ) {
          pagerLog("Breaking chunks")
          const firstChunkSpace = currentSheetHeight - currentPageHeight
          const chunks = as.splitContent(
            node,
            others,
            firstChunkSpace,
            currentSheetHeight
          )
          if (chunks[0] !== null) {
            pagerLog("Chunk 0 has height " + chunks[0].height)
            pages[0].push(
              React.createElement(as, {
                ...others,
                key: id,
                _pagerProps: {
                  ...chunks[0].props,
                  inPage: true,
                },
                _pagerMeta: {
                  isFirst: true,
                  isMiddle: false,
                  isLast: chunks.length === 1,
                },
              })
            )
          }
          for (let j = 1; j < chunks.length; j++) {
            pagerLog(`Chunk ${j} has height ` + chunks[j].height)
            pages.unshift([
              React.createElement(as, {
                ...others,
                key: id,
                _pagerProps: {
                  ...chunks[j].props,
                  inPage: true,
                },
                _pagerMeta: {
                  isFirst: j === 1 && chunks[0] === null,
                  isMiddle: j < chunks.length - 1,
                  isLast: j === chunks.length - 1,
                },
              }),
            ])
          }
          if (chunks.length === 1) {
            currentPageHeight = currentPageHeight + chunks[0].height
          } else {
            currentPageHeight = chunks[chunks.length - 1].height
          }
          pagerLog("Final page is filled for " + currentPageHeight)
        } else {
          pagerLog("Insert into page")
          pages[0].push(
            React.createElement(as, {
              ...others,
              key: id,
              _pagerProps: {
                inPage: true,
              },
              _pagerMeta: {
                isFirst: true,
                isMiddle: false,
                isLast: true,
              },
            })
          )
          currentPageHeight += height
        }
        const spacingGetter = get(as, "requestSpacing", () => spacingMm)
        const requestedSpacing = mm2px(
          spacingGetter ? spacingGetter(others) ?? spacingMm : spacingMm
        )
        pagerLog("Requested spacing of " + requestedSpacing + "px")
        if (currentSheetHeight - currentPageHeight > requestedSpacing) {
          pages[0].push(
            React.createElement("div", {
              className: "__pagerauto-spacing",
              key: uniqueId("Spacer"),
              style: { height: 0, paddingBottom: requestedSpacing },
            })
          )
          currentPageHeight += requestedSpacing
        } else {
          pagerLog("Wrapping to new page")
          pages.unshift([])
          currentPageHeight = 0
        }
      }
    }
    if (pages[0].length === 0) {
      pages.shift()
    }
    setPages(pages.reverse())
  }, [childrenArray, footer, footerSkipBeginning, oneCm, spacingMm])

  const ctx = useMemo(
    () => ({
      requestPagination: () => {
        debounce(doPaginate, 100)
      },
    }),
    [doPaginate]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (prevPass.current === pass) {
      doPaginate()
      setPass((p) => (p + 1) % 2)
      pagerLog("[PAGER] Run effect to paginate content")
    } else {
      pagerLog("[PAGER] Skip effect due to pass check")
    }
  })

  useEffect(() => {
    prevPass.current = pass
  }, [pass])

  useEffect(() => {
    try {
      window.document.fonts.ready.then(() => {
        if (awaitFontRef.current) {
          awaitFontRef.current = false
          doPaginate()
        }
      })
    } catch(e) {
      // skip
    }
  }, [doPaginate])

  const nextChildren = []

  storeRef.current.elements = []

  for (const child of childrenArray) {
    if (child.type === Element) {
      const { as, id, ...others } = child.props
      const elementId = `__pager_${uniqueId()}`
      storeRef.current.elements.push(elementId)
      nextChildren.push(
        React.createElement(as, {
          key: id,
          ...others,
          _pager: {
            id: elementId,
          },
        })
      )
    }
  }

  const outerWidth = fullWidth + paddingCm * 2 * oneCm

  const applyScale = fitWidth && fitWidth < outerWidth

  return (
    <div style={{ backgroundColor: pretty ? "var(--gray-light)" : "" }}>
      <div
        className={classNames("no-printer", pageClassName)}
        style={{
          width: Math.round(mm2px(190)),
          margin: "0 auto",
          position: "fixed",
          bottom: window.innerHeight * 2,
        }}
        ref={domRef}
      >
        <PagerContext.Provider value={ctx}>
          {nextChildren}
        </PagerContext.Provider>
      </div>
      {pages !== null && (
        <div
          style={{
            margin: 0,
            paddingTop: pretty ? 1 : undefined,
            paddingBottom: pretty ? 1 : undefined,
            transform:
              applyScale
                ? `scale(${fitWidth / outerWidth})`
                : undefined,
            transformOrigin: "top left",
          }}
        >
          {pages.map((page, i) => (
            <div
              key={i}
              className={classNames("page", pageClassName)}
              style={{
                width: Math.round(mm2px(210)),
                height: Math.round(mm2px(297)),
                padding: Math.round(mm2px(10)),
                margin: pretty ? applyScale ? paddingCm * oneCm : "0 auto" : undefined,
                marginTop: pretty ? Math.round(mm2px(10)) : undefined,
                marginBottom: pretty ? Math.round(mm2px(10)) : undefined,
                backgroundColor: "white",
                boxShadow: pretty
                  ? "0 7px 8px 2px rgba(51, 51, 51, 0.3)"
                  : undefined,
                position: "relative",
                pageBreakBefore: "always",
                pageBreakAfter: "always",
              }}
            >
              {page}
              {i + 1 > footerSkipBeginning && (
                <div
                  key={`Footer${i}`}
                  style={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    right: "0",
                  }}
                >
                  {footer(i + 1, pages.length)}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <div
        className="no-printer"
        style={{
          width: Math.round(mm2px(190)),
          height: Math.round(mm2px(277)),
          position: "fixed",
          top: "-100cm",
        }}
        ref={sheetRef}
      >
        {footer && footer(0, 0)}
      </div>
    </div>
  )
}

Pager.Element = Element

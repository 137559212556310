import { createContext } from "react"

export const CurrentEstimateStateContext = createContext([null, null])

export const TasksOpensContext = createContext([{}, {}])

export const ResourcesOpensContext = createContext([{}, {}])

export const WpClipboardValueContext = createContext(null)
export const WpClipboardCopyContext = createContext(null)

export const OrganizationDetailContext = createContext([null, null])

export const ProjectContext = createContext([null, null])

export const CustomerContext = createContext([null, null])

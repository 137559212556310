import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import Layout from "../../../components/Layout"
import { TrackerLandmarks } from "../../../components/TrackerLandmarks"
import { ProjectContext } from "../../../context"
import { useProjectAuditTrail } from "../../../hooks/project"
import ProjectBottomBarContent from "../ProjectBottomBarContent"
import ProjectTabBarContent from "../ProjectTabBarContent"
import Table from "../../../components/Table"
import LongBorder from "../../../components/LongBorder"
import dayjs from "dayjs"
import { FaDownload } from "react-icons/fa"

export function AuditTrail() {
  const [{ project }] = useContext(ProjectContext)
  const [{ data }] = useProjectAuditTrail(project.id)
  const { t } = useTranslation()

  const columns = useMemo(() => {
    return [
      {
        label: t("field:project_audit_trail.datetime"),
        name: "datetime",
        size: 3,
        render: (val) => dayjs(val).format("DD/MM/YYYY HH:mm"),
      },
      {
        label: t("field:project_audit_trail.author_name"),
        name: "author_name",
        size: 3,
      },
      {
        label: t("field:project_audit_trail.field"),
        name: "field",
        size: 4,
        render: (val) => t(`field:project.${val}`),
      },
      {
        label: t("field:project_audit_trail.old_value"),
        name: "old_value",
        size: 3,
        render: (val, _name, obj) => {
          if (obj.field === "status") {
            return t(`enums:project_status.${val}`)
          } else if (obj.field === "estimated_delivery_date") {
            return dayjs(val).format("DD/MM/YYYY")
          }
          return val
        },
      },
      {
        label: t("field:project_audit_trail.new_value"),
        name: "new_value",
        size: 3,
        render: (val, _name, obj) => {
          if (obj.field === "status") {
            return t(`enums:project_status.${val}`)
          } else if (obj.field === "estimated_delivery_date") {
            return dayjs(val).format("DD/MM/YYYY")
          }
          return val
        },
      },
      {
        label: t("field:project_audit_trail.reason"),
        name: "reason",
        size: 6,
      },
      {
        label: t("field:project_audit_trail.attachment"),
        name: "attachment",
        size: 2,
        render: (val) => {
          if (val) {
            return (
              <a href={val} target="_blank" rel="noreferrer">
                <FaDownload />
              </a>
            )
          } else {
            return null
          }
        },
      },
    ].filter((col) => col.visible !== false)
  }, [t])

  return (
    <Layout>
      <div className="container-fluid pt-5 px-page pb-page-bottom">
        <h1 className="text-primary mb-5">Log operazioni</h1>
        <LongBorder topBar="tabbar" />
        <Table
          data={data}
          columns={columns}
          stickyHeader
          stickyTopBar="tabbar"
          className="pb-8"
          valueCheckFn={(val) => val !== undefined && val !== null}
          use24
        />
      </div>
      <Layout.TabBar>
        <ProjectTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <ProjectBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import { rj, useRunRj } from "react-rocketjump"
import api from "../api"

const HrResourcesRj = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (params) => {
    return api.auth(wpAuth).get(`/api/hr/human-resource/`, params)
  },
  mutations: {
    create: {
      effect: (wpAuth) => (data) => {
        return api.auth(wpAuth).post(`/api/hr/human-resource/`, data)
      },
      updater: (state, result) => state,
    },
    update: {
      effect: (wpAuth) => (id, data) => {
        return api.auth(wpAuth).patch(`/api/hr/human-resource/${id}/`, data)
      },
      updater: (state, result) => state,
    },
    destroy: {
      effect: (wpAuth) => (id) => {
        return api.auth(wpAuth).delete(`/api/hr/human-resource/${id}/`)
      },
      updater: (state, result) => state,
    },
  },
})

export function useHrResources(params) {
  return useRunRj(HrResourcesRj, [params])
}

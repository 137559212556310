import React from "react"
import { accumulate } from "./utils"
const { scaleLinear } = require("d3-scale")
const { sum, last } = require("lodash")

export function TreeMap({
  data,
  minRowHeight = 50,
  maxRowHeight = 250,
  width = 900,
  padding = 8,
  wrapperComponent: WrapperComponent,
  nodeComponent: NodeComponent,
  metric,
  textSpaceLeft = 100,
  textSpaceRight = 100,
  wrapperTextComponent: WrapperTextComponent,
  nodeTextComponent: NodeTextComponent,
  rowScale = undefined,
  index = undefined,
}) {
  const treemapWidth = width - textSpaceLeft - textSpaceRight

  const metricMatrix = data.map((row) => {
    return row.items.map((item) => item[metric], 0)
  })

  const accumulatedMetricMatrix = metricMatrix.map((row) => accumulate(row))

  const heightMetricVector = accumulatedMetricMatrix.map((row) => last(row))

  const minHeightMetricValue = Math.min(...heightMetricVector)
  const maxHeightMetricValue = Math.max(...heightMetricVector)

  const heightScale =
    typeof rowScale === "function"
      ? rowScale
      : minHeightMetricValue < maxHeightMetricValue
      ? scaleLinear()
          .domain([minHeightMetricValue, maxHeightMetricValue])
          .range([minRowHeight, maxRowHeight])
      : scaleLinear()
          .domain([minHeightMetricValue, minHeightMetricValue])
          .range([
            (minRowHeight + maxRowHeight) / 2,
            (minRowHeight + maxRowHeight) / 2,
          ])

  const heightVector = heightMetricVector.map((value) => heightScale(value))
  const accumulatedHeightVector = accumulate(heightVector)

  const totalHeight = sum(heightVector) + 2 * padding

  return (
    <svg width={width} height={totalHeight} style={{ overflow: "visible" }}>
      <WrapperTextComponent
        top={0}
        left={0}
        width={textSpaceLeft}
        height={totalHeight}
        index={index}
      />
      <WrapperComponent
        width={treemapWidth}
        height={totalHeight}
        top={0}
        left={textSpaceLeft}
        index={index}
      />
      {data.map((row, i) => {
        const rowValue = last(accumulatedMetricMatrix[i])
        const scale = scaleLinear()
          .domain([0, rowValue])
          .range([0, treemapWidth - 2 * padding])
        return (
          <React.Fragment key={i}>
            <NodeTextComponent
              width={textSpaceRight}
              left={treemapWidth + textSpaceLeft}
              top={accumulatedHeightVector[i] + padding}
              height={heightVector[i]}
              item={row}
              index={index}
            />
            {row.items.map((item, j) => {
              return (
                <React.Fragment key={j}>
                  <NodeComponent
                    item={item}
                    height={heightVector[i]}
                    width={scale(item[metric])}
                    left={
                      scale(accumulatedMetricMatrix[i][j]) +
                      padding +
                      textSpaceLeft
                    }
                    top={accumulatedHeightVector[i] + padding}
                    isFirst={j === 0}
                    isLast={j === row.items.length - 1}
                    index={index}
                  />
                </React.Fragment>
              )
            })}
          </React.Fragment>
        )
      })}
      <rect x={100} y={0} width={1} height={1} fill="red" />
    </svg>
  )
}

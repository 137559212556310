import { flatten, groupBy, keyBy, map, pick, sum } from "lodash"
import React, { useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import Layout from "../../../components/Layout"
import { CurrentEstimateStateContext } from "../../../context"
import EstimateBottomBarContent from "../EstimateBottomBarContent"
import EstimateTabBarContent from "../EstimateTabBarContent"
import {
  flattenCloneTree,
  aggregateResources,
} from "../EstimateTasksTree/TaskTable/utils"
import ResourceList from "./ResourceList"
import Button, { ButtonGroup } from "../../../components/Button"
import Icon from "../../../components/Icon"
import useCurrentEstimateOption from "../../../hooks/useCurrentEstimateOption"
import { EstimateLandmarks } from "../../../components/EstimateLandmarks"

export default function EstimateResources() {
  const [{ estimate }] = useContext(CurrentEstimateStateContext)

  const [isCostVisible, setCostVisible] = useCurrentEstimateOption(
    "usedResources.isCostVisible",
    true
  )
  const [isUnitTableVisible, setUnitTableVisible] = useCurrentEstimateOption(
    "usedResources.isUnitTableVisible",
    true
  )

  const [showHR, setShowHR] = useState(true)
  const [showMaterial, setShowMaterial] = useState(true)
  const [showService, setShowService] = useState(true)

  const mappedResources = useMemo(() => {
    const flattenedTree = flattenCloneTree(estimate.task_tree)

    const flattenedResources = flatten(
      flattenedTree.map((node) => node.resources)
    )

    const aggregatedResources = aggregateResources(flattenedResources)

    const groupedByIdResources = groupBy(aggregatedResources, "resource_id")

    const resourcesById = keyBy(estimate.resources, "id")

    return map(groupedByIdResources, (resource) => {
      return {
        resource_id: resource[0].resource_id,
        name: resourcesById[resource[0].resource_id].name,
        total_cost: sum(
          resource
            .map((r) => parseFloat(r.unitary_cost) * parseFloat(r.size))
            .filter((n) => !isNaN(n))
        ),
        total_price: sum(
          resource
            .map((r) => parseFloat(r.unitary_price) * parseFloat(r.size))
            .filter((n) => !isNaN(n))
        ),
        details: resource.map((r) =>
          pick(r, "cost_unit", "size", "unitary_cost", "unitary_price")
        ),
        description: resourcesById[resource[0].resource_id].description,
        resource_type: resourcesById[resource[0].resource_id].resource_type,
      }
    })
  }, [estimate.resources, estimate.task_tree])

  const { t } = useTranslation(["translation", "enums"])

  const filteredMappedResources = useMemo(
    () =>
      mappedResources
        .filter((r) => {
          if (!showHR) {
            if (r.resource_type === "hr") {
              return false
            }
          }
          return true
        })
        .filter((r) => {
          if (!showService) {
            if (r.resource_type === "service") {
              return false
            }
          }
          return true
        })
        .filter((r) => {
          if (!showMaterial) {
            if (r.resource_type === "material") {
              return false
            }
          }
          return true
        }),
    [mappedResources, showHR, showMaterial, showService]
  )

  return (
    <Layout>
      {mappedResources.length > 0 && (
        <div className="container-fluid pt-5 px-half-page pb-page-bottom">
          <p className="mt-6">{t("tasks.resource_description")}</p>
          <div className="d-flex flex-row pb-5  justify-content-between">
            <div className="d-flex flex-row justify-content-start alig-items-start">
              <ButtonGroup>
                <Button active={showHR} onClick={() => setShowHR((v) => !v)}>
                  <div className="d-flex flex-row align-items-center justify-content-start">
                    <Icon
                      name="hr"
                      style={{ width: 20, height: 20, marginRight: 8 }}
                    />
                    {t(`enums:resource_type.hr`, { count: 2 })}
                  </div>
                </Button>
                <Button
                  active={showService}
                  onClick={() => setShowService((v) => !v)}
                >
                  <div className="d-flex flex-row align-items-center justify-content-start">
                    <Icon
                      name="service"
                      style={{ width: 20, height: 20, marginRight: 8 }}
                    />
                    {t(`enums:resource_type.service`, { count: 2 })}
                  </div>
                </Button>
                <Button
                  active={showMaterial}
                  onClick={() => setShowMaterial((v) => !v)}
                >
                  <div className="d-flex flex-row align-items-center justify-content-start">
                    <Icon
                      name="material"
                      style={{ width: 20, height: 20, marginRight: 8 }}
                    />
                    {t(`enums:resource_type.material`, { count: 2 })}
                  </div>
                </Button>
              </ButtonGroup>
            </div>
            <div className="d-flex flex-row">
              <div className="d-flex flex-row align-items-center mx-3">
                <input
                  type="checkbox"
                  checked={isCostVisible}
                  onChange={(e) => setCostVisible(e.target.checked)}
                />
                <label className="mb-0 mx-4">
                  {t("tasks.resource.show_cost")}
                </label>
              </div>
              <div className="d-flex flex-row align-items-center mx-3">
                <input
                  type="checkbox"
                  checked={isUnitTableVisible}
                  onChange={(e) => setUnitTableVisible(e.target.checked)}
                />
                <label className="mb-0 mx-4">
                  {t("tasks.resource.show_unit")}
                </label>
              </div>
            </div>
          </div>

          <ResourceList
            resources={filteredMappedResources}
            isCostVisible={isCostVisible}
            isUnitTableVisible={isUnitTableVisible}
          />
        </div>
      )}
      {mappedResources.length === 0 && (
        <div className="container-fluid pt-5 px-half-page pb-page-bottom">
          <p className="mt-6">{t("tasks.empty_resource_description")}</p>
        </div>
      )}

      <Layout.TabBar>
        <EstimateTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <EstimateBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <EstimateLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../components/Button"
import Table from "../../../components/Table"
import WpInput from "../../../components/WpInput"
import WpModal from "../../../components/WpModal"
import { useTemplateDocFragments } from "../../../hooks/templateDocFragments"
import useDebounce from "../../../hooks/useDebounce"

const TEMPLATE_FILTERS = {
  fragment_type: "cover",
}

function PickCoverList({ onPick }) {
  const { t } = useTranslation("action", "field")

  const [search, setSearch] = useState("")
  const debSearch = useDebounce(search, 200)

  const filter = useMemo(() => {
    return {
      ...TEMPLATE_FILTERS,
      search: debSearch,
    }
  }, [debSearch])

  const [{ fragments }] = useTemplateDocFragments(filter)

  const columns = useMemo(
    () => [
      {
        label: t("field:document.title"),
        name: "title",
        onClick: (e, datum) => {
          onPick(datum)
        },
        cellClassname: "pointer",
      },
    ],
    [onPick, t]
  )

  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-end">
        <span className="pr-3">{t("action:search")}:</span>
        <WpInput
          placeholder={t("field:template.text.placeholder.search")}
          style={{ width: 235 }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div style={{ height: 340 }} className="overflow-y-auto">
        {fragments !== null && fragments.length > 0 && (
          <Table columns={columns} data={fragments} stickyHeader={false} />
        )}
      </div>
    </>
  )
}

export default function PickCoverModal({ isOpen, onClosed, toggle, onPick }) {
  const { t } = useTranslation("action", "field")

  return (
    <WpModal
      title={t("action:pick_cover")}
      size={"lg"}
      isOpen={isOpen}
      onClosed={onClosed}
      toggle={toggle}
    >
      <PickCoverList
        onPick={(cover) => {
          onPick(cover)
          toggle()
        }}
      />
      <div className="d-flex flex-row align-items-center justify-content-start pt-5">
        <Button size="sm" color="none" onClick={toggle}>
          {t("action:cancel")}
        </Button>
      </div>
    </WpModal>
  )
}

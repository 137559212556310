export default function DendrogramSplitter(
  node,
  props,
  firstChunkSpace,
  sheetHeight
) {
  const captionNode = node.querySelector(".__pager-dendro-caption")
  const title = node.querySelector(".__pager-title")
  const titleHeight = title?.offsetHeight ?? 0
  
  const disclaimerNode = node.querySelector(".__pager-disclaimer")
  if (disclaimerNode) {
    return [
      null,
      {
        props: {},
        height: captionNode?.offsetHeight + titleHeight + disclaimerNode.offsetHeight,
      },
    ]
  }
  // If this is invoked, it means that chart could not fit in available space
  // so, skip to a new page, and force the chart to fit the available space in that page
  // (i.e. maxHeight = sheetHeight)

  return [
    null,
    {
      props: {
        sheetHeight:
          sheetHeight - (captionNode?.offsetHeight ?? 0) - titleHeight,
      },
      height: Math.min(node.offsetHeight, sheetHeight),
    },
  ]
}

import { Field, useFormikContext } from "formik"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import find from "lodash/find"
import { BILLING_TYPES } from "../../consts"
import { useCountires } from "../../hooks/countries"
import FieldDropdown from "../fields/FieldDropdown"
import FieldDropdownList from "../fields/FieldDropdownList"
import FieldInput from "../fields/FieldInput"

export default function BillingFieldSet() {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext()
  const [{ countiresOptions }] = useCountires()
  const getCountryCaption = useCallback(
    (value) => {
      if (value === "") {
        return null
      }
      return find(countiresOptions, { value })?.label ?? null
    },
    [countiresOptions]
  )

  const billingTypeOptions = useMemo(() => {
    return BILLING_TYPES.map((type) => ({
      value: type,
      label: t(`enums:billing_type.${type}`),
    }))
  }, [t])

  return (
    <>
      <div className="d-flex flex-row justify-content-center mb-6">
        <div className="flex-1">
          <Field
            name="business_name"
            label={t("field:organization.business_name")}
            component={FieldInput}
            className="mr-8"
          />
        </div>
        <div className="flex-1">
          <Field
            name="tax_code"
            label={t("field:organization.tax_code")}
            component={FieldInput}
          />
        </div>
      </div>

      <div className="d-flex flex-row justify-content-center mb-6">
        <div className="flex-1">
          <Field
            name="billing_type"
            label={t("field:organization.billing_type")}
            placeholder={t("field:organization.placeholder.billing_type")}
            component={FieldDropdown}
            onChange={(value) => {
              if (value === "private_person") {
                setFieldValue("vat_number", "", false)
                setFieldValue("pec", "", false)
                setFieldValue("it_tax_identifier", "", false)
              }
            }}
            options={billingTypeOptions}
            className="mr-8"
          />
        </div>
        <div className="flex-1">
          <Field
            disabled={values.billing_type !== "company"}
            name="vat_number"
            label={t("field:organization.vat_number")}
            component={FieldInput}
          />
        </div>
      </div>

      <div className="d-flex flex-row justify-content-center mb-6">
        <div className="flex-1">
          <Field
            name="country"
            autoFilterIgnoreCase
            placeholder={t("field:organization.placeholder.nation")}
            label={t("field:organization.nation")}
            options={countiresOptions}
            component={FieldDropdownList}
            caption={getCountryCaption}
            containerClassName="mr-8"
            onChange={(value) => {
              if (value !== "IT") {
                setFieldValue("pec", "", false)
                setFieldValue("it_tax_identifier", "", false)
              }
            }}
            className="py-3"
          />
        </div>
        <div className="flex-1" />
      </div>

      {values.country === "IT" && values.billing_type === "company" && (
        <div className="d-flex flex-row justify-content-center mb-6">
          <div className="flex-1">
            <Field
              name="it_tax_identifier"
              label={t("field:organization.it_tax_identifier")}
              component={FieldInput}
              className="mr-8"
            />
          </div>
          <div className="flex-1">
            <Field
              name="pec"
              label={t("field:organization.pec")}
              component={FieldInput}
            />
          </div>
        </div>
      )}

      <div className="d-flex flex-row justify-content-center mb-6">
        <div className="flex-1">
          <Field
            name="city"
            label={t("field:organization.city")}
            component={FieldInput}
            className="mr-8"
          />
        </div>
        <div className="flex-1">
          <Field
            name="province"
            label={t("field:organization.province")}
            component={FieldInput}
          />
        </div>
      </div>

      <div className="d-flex flex-row justify-content-center mb-6">
        <div className="flex-1">
          <Field
            name="address"
            label={t("field:organization.address")}
            component={FieldInput}
            className="mr-8"
          />
        </div>
        <div className="flex-1">
          <Field
            name="street_number"
            label={t("field:organization.street_number")}
            component={FieldInput}
          />
        </div>
      </div>

      <div className="d-flex flex-row justify-content-center mb-6">
        <div className="flex-1">
          <Field
            name="postal_code"
            label={t("field:organization.postal_code")}
            component={FieldInput}
            className="mr-8"
          />
        </div>
        <div className="flex-1" />
      </div>
    </>
  )
}
import React, { useMemo, useState } from "react"
import classNames from "classnames"
import { Formik, FieldArray, Field } from "formik"
import { RESOURCE_TYPES } from "../../consts"
import S from "./ResourceDetail.module.scss"
import Icon from "../../components/Icon"
import Separator from "../../components/Separator/Separator"
import Button from "../../components/Button"
import * as yup from "yup"
import { calcMargin, convertErrorToForm } from "../../utils"
import SaveModal from "../../components/SaveModal/SaveModal"
import Layout from "../../components/Layout"
import Scrollbars from "react-custom-scrollbars/lib/Scrollbars"
import { useTranslation } from "react-i18next"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import FieldTextarea from "../../components/fields/FieldTextarea"
import FieldDropdown from "../../components/fields/FieldDropdown"
import FieldInput from "../../components/fields/FieldInput"
import { getCurrencyFormatter } from "../../hooks/useCurrencyFormatter"

const DEFAULT_RESOURCE = {
  price_list: [],
  name: "",
  name_en: "",
  description: "",
  description_en: "",
  resource_type: null,
}

const DEFAULT_PRICE_LIST_ITEM = {
  cost_unit: "",
  unitary_cost: 0,
  unitary_price: 0,
}

export default function ResourceDetail({ resource, priceList, onSave, onCancel, onDelete, onArchive }) {
  const { t } = useTranslation(["translation", "field", "enums"])
  const currency = useMemo(() => {
    return getCurrencyFormatter(priceList?.currency ?? "EUR").currencySymbol
  }, [priceList?.currency])

  const schema = useMemo(() => {
    return yup.object().shape({
      name: yup
        .string()
        .required(t("field:errors.mandatory", { field: t("field:resource.name") }))
        .max(100, t("field:errors.max", { max_value: 100 })),
      description: yup.string().nullable(),
      resource_type: yup.string(),
      price_list: yup.array().of(
        yup.object().shape({
          cost_unit: yup
            .string()
            .required(
              t("field:errors.mandatory", {
                field: t("field:resource.price_list.cost_unit"),
              })
            )
            .max(10, t("field:errors.max", { max_value: 10 })),
          unitary_cost: yup
            .number()
            .transform((currentValue, originalValue) => {
              if (isNaN(currentValue)) {
                return null
              }
              return currentValue
            })
            .nullable()
            .lessThan(10 ** 14, t("field:errors.less_than", { max_value: 10 ** 14 }))
            .moreThan(-(10 ** 14), t("field:errors.more_than", { min_value: -(10 ** 14) })),
          unitary_price: yup
            .number()
            .required(
              t("field:errors.mandatory", {
                field: t("field:resource.price_list.unitary_price"),
              })
            )
            .lessThan(10 ** 14, t("field:errors.less_than", { max_value: 10 ** 14 }))
            .moreThan(-(10 ** 14), t("field:errors.more_than", { min_value: -(10 ** 14) })),
        })
      ),
    })
  }, [t])

  const [deleted, setDeleted] = useState([])
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)
  const onDiscard = () => {
    setDeleted([])
    onCancel()
  }

  return (
    <div id="resource_editor_panel" className="h-100 d-flex flex-column justify-content-start align-items-stretch">
      <Formik
        initialValues={{
          ...DEFAULT_RESOURCE,
          ...resource,
        }}
        validationSchema={schema}
        onSubmit={(values, actions) => {
          onSave
            .onSuccess(() => {
              setDeleted([])
              actions.setSubmitting(false)
              onCancel()
            })
            .onFailure((error) => {
              actions.setSubmitting(false)
              actions.setErrors(convertErrorToForm(error))
            })
            .run(resource.id, schema.cast(values))
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <>
            <div className={"flex-1"}>
              <Scrollbars style={{ width: "100%", height: "100%" }}>
                <div className={classNames(S["page-viewport"])}>
                  <div className="d-flex flex-row justify-content-between align-items-start">
                    <h2 className="px-4 mb-8 text-uppercase">{t("resource_detail.title")}</h2>
                    <UncontrolledDropdown className={"action-icon-container-primary-hover"}>
                      <DropdownToggle caret={false} tag={"span"}>
                        <Icon name="vdots" className="text-dark pointer" title={t("action:other_options")} placement="right" />
                      </DropdownToggle>
                      <DropdownMenu right modifiers={{ offset: { offset: "0, 12" } }} className="border-primary">
                        <DropdownItem
                          className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}
                          onClick={onArchive}
                        >
                          <div className="d-flex flex-row align-items-center mx-4">
                            <Icon name="archive" className={classNames("pointer mr-4")} />
                            <span>{t("action:archive")}</span>
                          </div>
                        </DropdownItem>
                        <Separator className="my-0 mx-4 border-primary" />
                        <DropdownItem
                          className={classNames("text-capitalize px-0", "dropdown-item-primary-active")}
                          onClick={onDelete}
                          disabled={resource.is_allocated}
                        >
                          <div className="d-flex flex-row align-items-center mx-4">
                            <Icon
                              name="delete"
                              className={classNames("pointer mr-4", {
                                "text-separator": resource.is_allocated,
                              })}
                            />
                            <span>{t("action:delete")}</span>
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>

                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row">
                        <div className="d-flex flex-column flex-1">
                          <Field
                            name="name"
                            auto
                            rows={2}
                            component={FieldInput}
                            label={t("field:resource.name") + " (it)"}
                            placeholder={t("field:resource.placeholder.name")}
                          />
                          <div className="pb-3" />
                          <Field
                            name="name_en"
                            auto
                            rows={2}
                            component={FieldInput}
                            label={t("field:resource.name") + " (en)"}
                            placeholder={t("field:resource.placeholder.name")}
                          />
                        </div>

                        <div className="d-flex flex-column mr-4">
                          <Field
                            name={"resource_type"}
                            label={t("field:resource.resource_type")}
                            labelClassname={"px-4"}
                            style={{ width: 150 }}
                            className="ml-4"
                            placeholder={t("action:select_type")}
                            options={RESOURCE_TYPES.map((resType) => ({
                              value: resType,
                              label: t(`enums:resource_type.${resType}`),
                            }))}
                            component={FieldDropdown}
                          />
                        </div>
                      </div>
                      <Separator className="mx-0" />
                      <div className="d-flex flex-column">
                        <Field
                          name="description"
                          label={t("field:resource.description") + " (it)"}
                          auto
                          placeholder={t("field:resource.placeholder.description")}
                          component={FieldTextarea}
                        />
                        <div className="pb-3" />
                        <Field
                          name="description_en"
                          label={t("field:resource.description") +  " (en)"}
                          auto
                          placeholder={t("field:resource.placeholder.description")}
                          component={FieldTextarea}
                        />
                      </div>
                      <Separator className="mx-0" />
                      <div className="d-flex flex-column">
                        <label className="font-weight-semibold h3 pb-3">{t("field:resource.price_list.title")}</label>
                        <FieldArray name="price_list">
                          {({ push, remove }) => (
                            <>
                              <div className="container-fluid px-0 px-2">
                                <div className="row no-gutters">
                                  <div className="col-2 font-weight-semibold text-left pl-2 pb-3 h3 text-uppercase">
                                    {t("field:resource.price_list.cost_unit")}
                                  </div>
                                  <div className="col-3 font-weight-semibold text-left pl-2 pb-3 h3 text-uppercase">
                                    {t("field:resource.price_list.cost_unit") + " (en)"}
                                  </div>
                                  <div className="col-2 font-weight-semibold text-right pb-3 h3 text-uppercase">
                                    {t("field:resource.price_list.unitary_cost")}
                                  </div>
                                  <div className="col-2 font-weight-semibold text-right pb-3 h3 text-uppercase">
                                    {t("field:resource.price_list.unitary_price")}
                                  </div>
                                  <div className="col-2 font-weight-semibold text-right pb-3 h3 text-uppercase">
                                    {t("field:resource.price_list.margin")}
                                  </div>
                                  <div className="col-1"></div>
                                </div>

                                {formik.values.price_list.map((priced, i) => {
                                  if (priced.price_list !== priceList?.id) {
                                    return null
                                  }
                                  return (
                                    <div key={i} className={classNames("row no-gutters border-bottom", S["costs-table-row"])}>
                                      <div className="d-flex col-2 pl-2 align-items-center">
                                        <div className="flex-grow-1">
                                          <Field
                                            name={`price_list.${i}.cost_unit`}
                                            className={classNames("w-100 my-3")}
                                            tiny
                                            clear
                                            component={FieldInput}
                                          />
                                        </div>
                                      </div>
                                      <div className="d-flex col-3 pl-2 align-items-center">
                                        <div className="flex-grow-1">
                                          <Field
                                            name={`price_list.${i}.cost_unit_en`}
                                            className={classNames("w-100 my-3")}
                                            tiny
                                            clear
                                            component={FieldInput}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-2 d-flex flex-row align-items-center pl-2">
                                        <div className="flex-grow-1">
                                          <Field
                                            name={`price_list.${i}.unitary_cost`}
                                            type="number"
                                            tiny
                                            clear
                                            className={classNames("w-100 text-right")}
                                            component={FieldInput}
                                          />
                                        </div>
                                        {currency}
                                      </div>
                                      <div className="col-2 d-flex flex-row align-items-center pl-2">
                                        <div className="flex-grow-1">
                                          <Field
                                            name={`price_list.${i}.unitary_price`}
                                            type="number"
                                            tiny
                                            clear
                                            className={classNames("w-100 text-right")}
                                            component={FieldInput}
                                          />
                                        </div>
                                        {currency}
                                      </div>

                                      <div className="d-flex flex-row justify-content-end align-items-center col-2 pl-2">
                                        <span className={classNames("text-right text-muted")}>
                                          {calcMargin(priced.unitary_cost, priced.unitary_price)} {"%"}
                                        </span>
                                      </div>
                                      <div className="col-1 pl-3 d-flex flex-row align-items-center">
                                        {!!formik.errors?.price_list?.[i] && (
                                          <Icon name="alert" className={classNames("text-danger", S["error-icon"])} />
                                        )}
                                        <Icon
                                          name="resource-delete"
                                          title={t("action:delete_price_list")}
                                          className={classNames("pointer text-primary", S["delete-row-action"])}
                                          onClick={() => {
                                            setDeleted([
                                              {
                                                data: priced,
                                                position: i,
                                              },
                                              ...deleted,
                                            ])
                                            remove(i)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                              {priceList && (
                                <Icon
                                  name="resource-add"
                                  title={t("action:add_price_list")}
                                  className={classNames("mt-3 ml-7 pointer")}
                                  onClick={() => {
                                    push({
                                      ...DEFAULT_PRICE_LIST_ITEM,
                                      price_list: priceList?.id,
                                    })
                                  }}
                                />
                              )}
                            </>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                  </form>
                </div>
              </Scrollbars>
            </div>
            <div className="w-100 bg-white">
              {Object.keys(formik.errors || {}).length > 0 && (
                <div className="w-100 bg-danger-35">
                  <div className="d-flex align-items-center py-2 pl-7">
                    <Icon name="alert" className="text-danger ml-4 mr-3" />
                    <span>{t("error.error_info")}</span>
                  </div>
                </div>
              )}
              {deleted.length > 0 && (
                <div
                  className="w-100 bg-danger-35 mt-1"
                  onClick={() => {
                    let newPriceList = formik.values.price_list.slice(0)
                    newPriceList.splice(deleted[0].position, 0, deleted[0].data)
                    setDeleted(deleted.slice(1))
                    formik.setFieldValue("price_list", newPriceList)
                  }}
                >
                  <div className="d-flex align-items-center py-2 pl-7 pointer">
                    <Icon name="revert" className="text-dark ml-4 mr-3" />
                    <span>
                      {deleted[0].data.cost_unit !== "" &&
                        t("error.restore_price_list_item", {
                          item: deleted[0].data.cost_unit,
                        })}
                      {deleted[0].data.cost_unit === "" && t("error.restore_price_list_last")}
                    </span>
                  </div>
                </div>
              )}
              <SaveModal
                toggle={toggle}
                modal={modal}
                title={t("resource_detail.title")}
                modified={formik.values.name}
                onDiscard={onDiscard}
                onSave={formik.handleSubmit}
                disableSave={!formik.isValid}
              />
              <Layout.DrawerCollapse
                onClick={() => {
                  if (formik.dirty) {
                    toggle()
                  } else {
                    onDiscard()
                  }
                }}
              />
              <div className="w-100 bg-gray-bg">
                <div className="d-flex justify-content-center align-items-center py-3">
                  <Button
                    size="lg"
                    color="none"
                    className="mr-8"
                    onClick={() => {
                      if (formik.dirty) {
                        toggle()
                      } else {
                        onDiscard()
                      }
                    }}
                  >
                    {t("action:cancel")}
                  </Button>
                  <Button size="lg" color="primary" onClick={formik.handleSubmit} disabled={!formik.isValid}>
                    {t("action:save")}
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  )
}

import React from "react"
import Button from "./Button"
import Icon from "./Icon"

export default function TabButton({
  active,
  onClick,
  iconName,
  title,
  className = "",
  ...props
}) {
  return (
    <Button
      color="primary"
      size="lg"
      rounded
      active={active}
      onClick={onClick}
      className={`text-center d-inline-flex justify-content-center align-items-center ${className}`}
      {...props}
    >
      <Icon name={iconName} title={title} style={{ width: 24, height: 24 }} />
    </Button>
  )
}

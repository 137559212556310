import React, { useMemo, useState } from "react"
import { Field, Formik, useFormikContext } from "formik"
import * as Yup from "yup"
import find from "lodash/find"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import Button from "../../components/Button"
import FieldInput from "../../components/fields/FieldInput"
import Layout from "../../components/Layout"
import { useProfile } from "../../hooks/profile"
import { convertErrorToForm, getStripe } from "../../utils"
import PlanCard from "../../components/PlanCard/PlanCard"
import S from "./NewOrganization.module.scss"
import FieldImageLinear from "../../components/fields/FieldImageLinear"
import usePlans from "../../hooks/usePlans"
import FieldTextarea from "../../components/fields/FieldTextarea"
import ImagePlaceholder from "../../components/ImagePlaceholder"
import Alert from "reactstrap/lib/Alert"
import { getCurrencyFormatter } from "../../hooks/useCurrencyFormatter"
import BillingFieldSet from "../../components/CreateEstimateModal/BillingFieldSet"

const INITIAL_VALUES = {
  name: "",
  description: "",
  logo: null,
  plan: null,
  business_name: "",
  tax_code: "",
  vat_number: "",
  pec: "",
  address: "",
  street_number: "",
  city: "",
  province: "",
  postal_code: "",
  counry: "",
  billing_type: "",
  it_tax_identifier: "",
}

function StepBilling({ plans }) {
  const { isValid, isSubmitting, values } = useFormikContext()
  const { t } = useTranslation()
  const selectedPlan = useMemo(() => find(plans, { code: values.plan }), [
    plans,
    values.plan,
  ])

  return (
    <div className="row">
      <div className="col-md-8 offset-md-2">
        <BillingFieldSet />
        <div className="text-center">
          <Button disabled={!isValid || isSubmitting} type="submit">
            {t("plans.buy_plan", { plan: selectedPlan.name })}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default function NewOrganization() {
  const history = useHistory()
  const { t, i18n } = useTranslation(["translation", "field", "enums"])
  const [, { createOrganization }] = useProfile()
  const [step, setStep] = useState("create")
  const plans = usePlans()
  const [{ profile }] = useProfile()

  const withBillingValidation = step === "billing"
  const OrganizationSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          name: Yup.string()
            .max(512, t("field:errors.max", { max_value: 512 }))
            .required(
              t("field:errors.mandatory", {
                field: t("field:organization.name"),
              })
            ),
          description: Yup.string(),
          // Add billing validation on billing step only ....
          ...(withBillingValidation
            ? {
                city: Yup.string()
                  .required(
                    t("field:errors.mandatory", {
                      field: t("field:organization.city"),
                    })
                  )
                  .max(128, t("field:errors.max", { max_value: 128 })),
                address: Yup.string()
                  .required(
                    t("field:errors.mandatory", {
                      field: t("field:organization.address"),
                    })
                  )
                  .max(256, t("field:errors.max", { max_value: 256 })),
                business_name: Yup.string()
                  .required(
                    t("field:errors.mandatory", {
                      field: t("field:organization.business_name"),
                    })
                  )
                  .max(256, t("field:errors.max", { max_value: 256 })),
                country: Yup.string()
                  .required(
                    t("field:errors.mandatory", {
                      field: t("field:organization.nation"),
                    })
                  )
                  .max(64, t("field:errors.max", { max_value: 64 })),
                pec: Yup.string()
                  .email(t("field:errors.email"))
                  .when(["country", "billing_type", "it_tax_identifier"], {
                    is: (country, type, id) =>
                      country === "IT" && type === "company" && !id,
                    then: Yup.string().required(
                      t("field:errors.mandatory", {
                        field: t("field:organization.pec"),
                      })
                    ),
                  }),
                it_tax_identifier: Yup.string().when(
                  ["country", "billing_type", "pec"],
                  {
                    is: (country, type, pec) =>
                      country === "IT" && type === "company" && !pec,
                    then: Yup.string().required(
                      t("field:errors.mandatory", {
                        field: t("field:organization.it_tax_identifier"),
                      })
                    ),
                  }
                ),
                postal_code: Yup.string()
                  .required(
                    t("field:errors.mandatory", {
                      field: t("field:organization.postal_code"),
                    })
                  )
                  .max(16, t("field:errors.max", { max_value: 16 })),
                province: Yup.string()
                  .required(
                    t("field:errors.mandatory", {
                      field: t("field:organization.province"),
                    })
                  )
                  .max(128, t("field:errors.max", { max_value: 128 })),
                street_number: Yup.string()
                  .required(
                    t("field:errors.mandatory", {
                      field: t("field:organization.street_number"),
                    })
                  )
                  .max(256, t("field:errors.max", { max_value: 256 })),
                tax_code: Yup.string()
                  .required(
                    t("field:errors.mandatory", {
                      field: t("field:organization.tax_code"),
                    })
                  )
                  .max(20, t("field:errors.max", { max_value: 20 })),
                vat_number: Yup.string()
                  .when("billing_type", (type, schema) => {
                    return type === "company"
                      ? schema.required(
                          t("field:errors.mandatory", {
                            field: t("field:organization.vat_number"),
                          })
                        )
                      : schema
                  })
                  .max(20, t("field:errors.max", { max_value: 20 })),
                billing_type: Yup.string()
                  .required(
                    t("field:errors.mandatory", {
                      field: t("field:organization.billing_type"),
                    })
                  )
                  .max(256, t("field:errors.max", { max_value: 256 })),
              }
            : undefined),
        },
        // https://github.com/jquense/yup/issues/176#issuecomment-369925782
        [
          ["pec", "it_tax_identifier"],
          ["it_tax_identifier", "pec"],
        ]
      ),
    [t, withBillingValidation]
  )

  return (
    <Layout>
      <div className="estimate border-top mb-5">
        <div className="text-center mt-8">
          {t("field:organization.new_organization")}
          <h1 className={S["big-title"]}>
            {step === "create" &&
              t("field:organization.create_new_organization")}
            {step === "plans" && t("field:organization.show_plans")}
            {step === "billing" && t("field:organization.billing")}
          </h1>
        </div>
        {profile.email_verified && (
          <Formik
            validateOnMount
            validationSchema={OrganizationSchema}
            onSubmit={(values, actions) => {
              return createOrganization
                .onSuccess((org) => {
                  if (org.checkout_session) {
                    getStripe().then((stripe) => {
                      stripe.redirectToCheckout({
                        sessionId: org.checkout_session,
                      })
                    })
                  } else {
                    history.push(`/org/${org.id}`)
                  }
                })
                .asPromise(values)
                .catch((error) => {
                  actions.setErrors(convertErrorToForm(error))
                })
            }}
            initialValues={INITIAL_VALUES}
          >
            {({
              handleSubmit,
              isSubmitting,
              isValid,
              setFieldValue,
              setTouched,
              values,
              validateForm,
            }) => (
              <form
                onSubmit={
                  step === "create"
                    ? (e) => {
                        // NOTE: On step plan when submit with enter
                        // go to next step (if can) instead of submit
                        e.preventDefault()
                        if (values.name) {
                          setStep("plans")
                        }
                      }
                    : handleSubmit
                }
                className="mt-8"
                autoComplete="off"
              >
                {step === "create" && (
                  <div className="row">
                    <div className="col-md-4 offset-md-4">
                      <div className="d-flex justify-content-center">
                        <Field
                          shape="square"
                          component={FieldImageLinear}
                          name="logo"
                          enableDelete
                          placeholder={ImagePlaceholder("tile", 6)}
                        />
                      </div>
                      <Field
                        label={t("field:organization.name")}
                        component={FieldInput}
                        name="name"
                        className="mb-5"
                      />
                      <Field
                        name="description"
                        label={t("field:organization.description")}
                        component={FieldTextarea}
                        auto
                        rows={4}
                      />
                      {/* <Field
                    containerClassName='mt-5'
                    label={t("field:organization.description")}
                    component={FieldTextarea}
                    type="email"
                    name="email"
                  /> */}
                      <div className="mt-8 text-center">
                        <Button
                          onClick={() => setStep("plans")}
                          className="w-50"
                          type="button"
                          disabled={!values.name}
                        >
                          {t("field:organization.continue")}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {step === "plans" && (
                  <div className="d-flex flex-column justify-content-center align-items-center pb-8">
                    <div className="d-flex justify-content-center">
                      {plans.map((plan, i) => (
                        <PlanCard
                          title={plan.name}
                          period={plan.period}
                          features={plan.translatedFeatures}
                          code={plan.code}
                          price={getCurrencyFormatter(plan.currency).format(
                            plan.price
                          )}
                          onClick={(code) => {
                            setFieldValue("plan", code)
                            if (plan.price_id) {
                              // NOTE: Avoid errors on new fields set
                              // cause is ugly
                              setTouched(
                                {
                                  city: false,
                                  business_name: false,
                                  tax_code: false,
                                  vat_number: false,
                                  pec: false,
                                  address: false,
                                  street_number: false,
                                  province: false,
                                  postal_code: false,
                                  nation: false,
                                },
                                false
                              )
                              setStep("billing")
                            } else {
                              handleSubmit()
                            }
                          }}
                          disabled={isSubmitting || plan.disabled}
                          key={plan.name}
                          className={i !== plans.length - 1 ? "mr-9" : ""}
                        />
                      ))}
                    </div>
                    <span className="h3 pt-5">
                      {t("plans.agreement_message")}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://wooplan.com/${i18n.language}/terms-of-service/`}
                      >
                        {t("plans.terms_of_service")}
                      </a>
                    </span>
                  </div>
                )}
                {step === "billing" && <StepBilling plans={plans} />}
              </form>
            )}
          </Formik>
        )}
        {!profile.email_verified && (
          <div className="container text-center mt-8">
            <Alert color="warning" className="d-inline-block">
              {t("email-verification.deny-create-org")}
            </Alert>
          </div>
        )}
      </div>
    </Layout>
  )
}

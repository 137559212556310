import { useMemo } from "react"
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom"
import Loader from "../../components/Loader/Loader"
import NotFound from "../../components/NotFound"
import { CustomerContext } from "../../context"
import { useCustomer } from "../../hooks/customers"
import CustomerContacts from "./CustomerContacts"
import { CustomerEstimates } from "./CustomerEstimates"
import CustomerInfo from "./CustomerInfo"
import CustomerInteractionDetail from "./CustomerInteractionDetail"
import CustomerInteractions from "./CustomerInteractions"
import CustomerProjects from "./CustomerProjects"
import { CustomerRequestDetail } from "./CustomerRequestDetail"
import { CustomerRequests } from "./CustomerRequests"
import CustomerStats from "./CustomerStats"

export default function Customer() {
  const { path } = useRouteMatch()
  const { id } = useParams()

  const [customerState, customerActions] = useCustomer(id)
  const { customer } = customerState

  // Shared ctx
  const ctx = useMemo(
    () => [
      {
        ...customerState,
      },
      {
        ...customerActions,
      },
    ],
    [customerActions, customerState]
  )

  if (customer === null) {
    return <Loader />
  }

  return (
    <CustomerContext.Provider value={ctx}>
      <Switch>
        <Route path={path} exact component={CustomerStats} />
        <Route path={`${path}/info`} exact component={CustomerInfo} />
        <Route path={`${path}/contacts`} exact component={CustomerContacts} />
        <Route path={`${path}/estimates`} exact component={CustomerEstimates} />
        <Route
          path={`${path}/interactions`}
          exact
          component={CustomerInteractions}
        />
        <Route
          path={`${path}/interactions/:interactionID`}
          exact
          component={CustomerInteractionDetail}
        />
        <Route
          path={`${path}/interactions/:interactionID/:operation`}
          exact
          component={CustomerInteractionDetail}
        />
        <Route path={`${path}/requests`} exact component={CustomerRequests} />
        <Route
          path={`${path}/requests/:requestId`}
          exact
          component={CustomerRequestDetail}
        />
        <Route path={`${path}/projects`} exact component={CustomerProjects} />
        <Route component={NotFound} />
      </Switch>
    </CustomerContext.Provider>
  )
}

import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { ReactComponent as Artwork } from "../../assets/img/login-artwork.svg"
import Button from "../../components/Button"
import InlineLangSelector from "../../components/InlineLangSelector"
import PasswordShowHide from "../../components/PasswordShowHide"
import { usePasswordReset } from "../../hooks/passwordRecover"
import S from "./PasswordReset.module.scss"
import logo from "../../assets/logo@2x.png"

export default function PasswordReset() {
  const { t } = useTranslation()
  const [{ pending }, { run: resetPassword }] = usePasswordReset()

  const [password, setPassword] = useState("")

  const { token } = useParams()

  function handleSubmit(e) {
    e.preventDefault()
    if (!pending) {
      resetPassword
        .onSuccess(() => {
          toast.success(t("manage_password.reset_success"))
        })
        .onFailure(() => {
          toast.success(t("manage_password.reset_error"))
        })
        .run(token, password)
    }
  }

  return (
    <div className="flex-1 d-flex flex-row justify-content-start align-items-stretch">
      <div className={S["artwork-container"]}>
        <Artwork preserveAspectRatio="xMinYMin slice" />
      </div>
      <div className={S["main-container"]}>
        <div className={S["top"]}>
          <img src={logo} className={S["logo"]} alt="" />
          <div className={S["tools"]}>
            <InlineLangSelector />
          </div>
        </div>

        <div className={S["form"]}>
          <div style={{ width: 350 }}>
            <h1>{t("manage_password.reset")}</h1>
            <p className="mb-8">{t("manage_password.reset_message")}</p>
            <form onSubmit={handleSubmit}>
              <div className="d-flex flex-column">
                <label for="password">{t("manage_password.password")}</label>
                <PasswordShowHide
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border-primary w-100"
                />
              </div>
              <div className="mt-8">
                <Button className="w-100" type="submit">
                  {t("manage_password.reset_button")}
                </Button>
              </div>
              <div className="mt-8 text-center">
                <Link to="/login">{t("manage_password.go_to_login")}</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { Fragment } from "react"
import classNames from "classnames"
import Separator from "../../../../components/Separator"
import { keyBy } from "lodash"
import { useTranslation } from "react-i18next"
import { useLocalizer } from "../../../../hooks/useLocalizer"

export default function ResourceTable({ nodeResources, estimateResources }) {
  const resourcesMap = keyBy(estimateResources, "id")
  const { t, i18n } = useTranslation()
  const l = useLocalizer(i18n.language)

  return (
    <div className="flex-fill">
      {nodeResources.map((nodeResource, i) => {
        let u = t(`enums:unit.${nodeResource.cost_unit.toLowerCase()}`)
        if (u.startsWith("unit.")) {
          u = nodeResource.cost_unit
        }
        if (nodeResource[l`cost_unit`]) {
          u = nodeResource[l`cost_unit`]
        }
        let name = resourcesMap[nodeResource.resource_id].name
        if (resourcesMap[nodeResource.resource_id][l`name`]) {
          name = resourcesMap[nodeResource.resource_id][l`name`]
        }
        return (
          <Fragment key={i}>
            <div className={classNames("d-flex justify-content-between py-2")}>
              <div>{name}</div>
              <div style={{ whiteSpace: "nowrap" }}>{`${nodeResource.size.toFixed(2)} / ${u}`}</div>
            </div>
            {i !== nodeResources.length - 1 && <Separator className="mx-3 my-0" />}
          </Fragment>
        )
      })}
    </div>
  )
}

import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { InteractionList } from "../../../components/InteractionsList"
import Layout from "../../../components/Layout"
import Loader from "../../../components/Loader/Loader"
import { TrackerLandmarks } from "../../../components/TrackerLandmarks"
import { ProjectContext } from "../../../context"
import ProjectBottomBarContent from "../ProjectBottomBarContent"
import ProjectTabBarContent from "../ProjectTabBarContent"

export function ProjectInteractions() {
  const { t } = useTranslation(["translation", "tab", "action"])

  const [{ project }] = useContext(ProjectContext)

  return (
    <Layout>
      <div className="container-fluid pt-5 px-page pb-page-bottom">
        {!project && <Loader />}
        {project && (
          <>
            <h1 className="text-primary font-weight-semibold">
              {t("project_interactions.title", { name: project.name })}
            </h1>
            <InteractionList
              filter={{ project: project.id }}
              initial={{ project: project.id, customer: project.customer }}
              customer={project.customer}
              disableCreate={!project.customer}
              disableProjectFilter
            />
          </>
        )}
      </div>
      <Layout.TabBar>
        <ProjectTabBarContent />
      </Layout.TabBar>
      <Layout.BottomBar className="border-top border-separator">
        <ProjectBottomBarContent />
      </Layout.BottomBar>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

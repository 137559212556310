import classNames from "classnames"
import { getIn } from "formik"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import useIsMount from "../../hooks/useIsMount"
import ErrorPopover from "../ErrorPopover/ErrorPopover"
import S from "./FieldFile.module.scss"

export default function FieldFile({
  field,
  form: { touched, errors, setFieldValue },
  label,
  className = "",
  containerClassName = "",
  ...props
}) {
  const { t } = useTranslation(["field"])
  const input = useRef()
  const isMount = useIsMount()
  const fieldError = getIn(errors, field.name)
  const fieldTouched = getIn(touched, field.name)
  const showError = fieldError && fieldTouched

  return (
    <div className={`d-flex flex-column ${containerClassName}`}>
      {label && <label className="font-weight-semibold mb-3 h3 text-uppercase">{label}</label>}
      <input
        ref={input}
        invalid={showError}
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          setFieldValue(field.name, e.target.files[0])
        }}
      />
      <div onClick={() => input.current.click()} className={classNames(S["wp-input"], String(className), "px-4 py-3")}>
        {field.value?.name ?? <i>Allega un file</i>}
      </div>
      {showError && !isMount && (
        <ErrorPopover
          placement={"top-start"}
          target={input.current}
          title={t("field:errors.general")}
          description={fieldError}
        />
      )}
    </div>
  )
}

import { Field, Formik } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../components/Button"
import FieldInput from "../../components/fields/FieldInput"
import WpModal from "../../components/WpModal"
import * as Yup from "yup"
import { convertErrorToForm } from "../../utils"

export default function SetPasswordModal({
  isOpen,
  onClosed,
  toggle,
  onConfirm,
  hasPassword,
}) {
  const { t } = useTranslation(["translation", "action", "field"])

  const schema = Yup.object({
    old_password: Yup.string().nullable(),
    new_password: Yup.string().required(
      t("field:errors.mandatory", { field: t("field:profile.new_password") })
    ),
    control_password: Yup.string()
      .required(
        t("field:errors.mandatory", {
          field: t("field:profile.control_password"),
        })
      )
      .oneOf(
        [Yup.ref("new_password"), null],
        t("field:errors.match_fields", {
          field1: t("field:profile.new_password"),
          field2: t("field:profile.control_password"),
        })
      ),
  })

  return (
    <WpModal
      title={
        hasPassword ? t("profile.change_password") : t("profile.set_password")
      }
      size={"md"}
      isOpen={isOpen}
      onClosed={onClosed}
      toggle={toggle}
    >
      <div className="flex-1 d-flex flex-column">
        <p style={{ whiteSpace: "pre-line" }}>
          {hasPassword
            ? t("profile.change_password_message")
            : t("profile.set_password_message")}
        </p>

        <Formik
          initialValues={{
            old_password: !hasPassword ? null : "",
            new_password: "",
            control_password: "",
          }}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            return onConfirm(values.old_password, values.new_password).catch(
              (error) => {
                actions.setSubmitting(false)
                actions.setErrors(convertErrorToForm(error))
              }
            )
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              {hasPassword && (
                <Field
                  name="old_password"
                  type="password"
                  className="mb-5"
                  label={t("field:profile.old_password")}
                  placeholder={t("field:profile.placeholder.old_password")}
                  component={FieldInput}
                />
              )}

              <Field
                name="new_password"
                type="password"
                className="mb-5"
                label={t("field:profile.new_password")}
                placeholder={t("field:profile.placeholder.new_password")}
                component={FieldInput}
              />

              <Field
                name="control_password"
                type="password"
                className="mb-5"
                label={t("field:profile.control_password")}
                placeholder={t("field:profile.placeholder.control_password")}
                component={FieldInput}
              />

              <div className="d-flex flex-row align-items-center justify-content-end pt-5">
                <Button size="sm" color="none" onClick={toggle}>
                  {t("action:cancel")}
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  type="submit"
                  disabled={!formik.isValid}
                  className="ml-5"
                >
                  {t("action:save")}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </WpModal>
  )
}

import React, { useCallback, useEffect, useRef, useState } from "react"
import classNames from "classnames"
import S from "./WpImageInput.module.scss"
import { ReactComponent as Camera } from "../../assets/icons/camera.svg"

export default function WpImageInput({
  src = null,
  shape = "circle",
  className,
  onChange,
  readOnly,
  disabled,
  placeholder = null
}) {
  const inputRef = useRef()
  const [url, setUrl] = useState(src || placeholder)

  const handleChange = useCallback(
    (e) => {
      if (e.target.files.length === 0) {
        return
      }

      const file = e.target.files[0]

      e.target.value = ""

      if (typeof onChange === "function") {
        onChange(file)
      }
    },
    [onChange]
  )

  const handleClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }, [])

  useEffect(() => {
    if (src instanceof File || src instanceof Blob) {
      setUrl((url) => {
        try {
          URL.revokeObjectURL(url)
        } catch (e) {}
        return URL.createObjectURL(src)
      })
    } else {
      setUrl((url) => {
        try {
          URL.revokeObjectURL(url)
        } catch (e) {}
        return src || placeholder
      })
    }
  }, [src, placeholder])

  return (
    <div
      className={classNames(S["image-input-container"], S[shape], className)}
      onClick={handleClick}
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleChange}
        ref={inputRef}
        disabled={disabled || readOnly}
      />
      <img src={url} alt="profile" />
      {!readOnly && (
        <div className={S["overlay"]}>
          <Camera />
        </div>
      )}
    </div>
  )
}

import magikApi from "magik-api"

const api = magikApi()
  .trailingSlash(true)
  .authHeaders((authPayload) => ({
    Authorization: `Token ${authPayload.token}`,
    "X-WP-ORGANIZATION": authPayload.organizationId ?? '',
  }))

export default api

import React from "react"
import { useHistory, useParams } from "react-router-dom"
import Layout from "../../components/Layout"
import Button from "../../components/Button"
import Loader from "../../components/Loader/Loader"
import { useJoinOrganization } from "../../hooks/organization"
import S from "./JoinOrganization.module.scss"
import { useTranslation } from "react-i18next"

export default function JoinOrganization() {
  const history = useHistory()
  const { token } = useParams()

  const { t } = useTranslation(["translation", "action"])
  const [
    { data, error },
    { joinOrganization, dismissInvitation },
  ] = useJoinOrganization(token)

  if (data === null && error === null) {
    return (
      <Layout>
        <Loader />
      </Layout>
    )
  }

  if (error !== null) {
    return (
      <Layout>
        <div className="container">
          <div className={S.panel}>
            <p>
              {error?.response?.code !== "e_user" && t("join-organization.error")}
              {error?.response?.code === "e_user" && t("join-organization.error_email")}
            </p>
            <div className="mt-8">
              <Button
                className="mx-8"
                onClick={() => history.push(`/`)}
              >
                {t("join-organization.fallback")}
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <div className="container">
        <div className={S.panel}>
          <h1>
            {t("join-organization.title", { org: data.organization.name })}
          </h1>
          <p className="my-5">
            {t("join-organization.message", { org: data.organization.name })}
          </p>
          <div className="mt-8">
            <Button
              color="none"
              className="mx-8"
              onClick={() =>
                dismissInvitation
                  .onSuccess(() => {
                    history.push(`/`)
                  })
                  .run()
              }
            >
              {t("action:dismiss-org")}
            </Button>
            <Button
              className="mx-8"
              onClick={() =>
                joinOrganization
                  .onSuccess(() => {
                    history.push(`/org/${data.organization.id}`)
                  })
                  .run()
              }
            >
              {t("action:join-org")}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

import React from "react"
import classNames from "classnames"
import Button from "../../components/Button"
import Layout from "../../components/Layout"
import { useProfile } from "../../hooks/profile"
import OrganizationPreviewLink from "./OrganizationPreviewLink"
import S from "./OrganizationSettings.module.scss"
import Icon from "../../components/Icon"
import { Link, NavLink, Route, Switch } from "react-router-dom"
import { useOrganizationDetail } from "../../hooks/organization"
import Loader from "../../components/Loader/Loader"
import Settings from "./Settings"
import Members from "./Members"
import Billing from "./Billing"
import { useTranslation } from "react-i18next"
import Scrollbars from "react-custom-scrollbars"
import { OrganizationDetailContext } from "../../context"
import { CAP, useCapabilities } from "../../hooks/capabilities"

export default function OrganizationSettings({ match }) {
  const { t } = useTranslation(["translation", "tab"])
  const [{ profile }] = useProfile()
  const { organization: orgId } = match.params
  const orgDetailStateActions = useOrganizationDetail(orgId)
  const [{ organization }] = orgDetailStateActions
  const [, { hasCapability }] = useCapabilities()

  return (
    <Layout displayRawContent className="d-flex flex-column justify-content-start align-items-stretch flex-1">
      <div className="border-top d-flex flex-row justify-content-start align-items-stretch flex-1">
        <div className={classNames(S["layout-left"], "d-flex flex-column")}>
          <div className="mt-8">
            <div className="mx-4 border-bottom d-flex justify-content-center pb-5">
              <Button Component={Link} to="/org/new" className="no-link">
                <span className="text-primary">{t("organization.create_new_organization")}</span>
              </Button>
            </div>
          </div>
          <div className="flex-1 overflow-y-auto">
            <Scrollbars style={{ width: "100%", height: "100%" }}>
              {profile.organizationsIds.map((orgId) => (
                <OrganizationPreviewLink key={orgId} organization={profile.organizationsById[orgId]} />
              ))}
            </Scrollbars>
          </div>
        </div>
        <div className="flex-1 position-relative">
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            className="d-flex flex-column justify-content-start align-items-stretch"
          >
            <div className="d-flex justify-content-between">
              {hasCapability(CAP.ORG_META) && (
                <NavLink
                  exact
                  activeClassName={S["active"]}
                  to={`/org/${orgId}/settings`}
                  className={classNames(S["tabbar-tab"])}
                >
                  <Icon height={23} width={23} name="info" className="mr-2" />
                  {t("tab:organizations.datum")}
                  <div className={S["tabbar-divider-invisible"]} />
                </NavLink>
              )}
              {hasCapability(CAP.ORG_MEMBERS) && (
                <>
                  <div className={S["tabbar-divider"]} />
                  <NavLink
                    activeClassName={S["active"]}
                    to={`/org/${orgId}/settings/members`}
                    className={classNames(S["tabbar-tab"])}
                  >
                    <Icon height={23} width={23} name="organization" className="mr-2" />
                    {t("tab:organizations.members")}
                  </NavLink>
                  <div className={S["tabbar-divider"]} />
                </>
              )}
              {hasCapability(CAP.ORG_BILLING) && (
                <>
                  <NavLink
                    activeClassName={S["active"]}
                    to={`/org/${orgId}/settings/billing`}
                    className={classNames(S["tabbar-tab"])}
                  >
                    <Icon height={23} width={23} name="billing" className="mr-2" />
                    {t("tab:organizations.billing")}
                  </NavLink>
                </>
              )}
            </div>
            <div className="flex-1 position-relative">
              {!organization && <Loader position="absolute" />}
              {organization && (
                <OrganizationDetailContext.Provider value={orgDetailStateActions}>
                  <Switch>
                    {hasCapability(CAP.ORG_META) && (
                      <Route exact path={`${match.path}`}>
                        <Settings />
                      </Route>
                    )}
                    {hasCapability(CAP.ORG_MEMBERS) && (
                      <Route exact path={`${match.path}/members`}>
                        <Members />
                      </Route>
                    )}
                    {hasCapability(CAP.ORG_BILLING) && (
                      <Route exact path={`${match.path}/billing`}>
                        <Billing />
                      </Route>
                    )}
                    <Route>
                      <div>Nothing to see here</div>
                    </Route>
                  </Switch>
                </OrganizationDetailContext.Provider>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

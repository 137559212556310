import { Field, Formik } from "formik"
import { mapValues } from "lodash"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import Button from "../../../components/Button"
import FieldDropdown from "../../../components/fields/FieldDropdown"
import FieldInput from "../../../components/fields/FieldInput"
import FieldTextarea from "../../../components/fields/FieldTextarea"
import WpModal from "../../../components/WpModal"
import { convertErrorToForm } from "../../../utils"

const INITIAL_VALUES = {
  date: "",
  description: "",
  amount_percent: "",
  amount_fixed: "",
  value_mode: "percent",
}

export function EditBillingTrancheModal({
  isOpen,
  toggle,
  onSave,
  onClosed,
  tranche,
}) {
  const { t } = useTranslation(["translation", "field", "action"])

  const schema = yup.object().shape({
    date: yup
      .date()
      .typeError(
        t("field:errors.date", {
          field: t("field:project_activity.date_ref"),
        })
      )
      .required(
        t("field:errors.mandatory", { field: t("field:project.name") })
      ),
    description: yup.string().required(),
  })

  return (
    <WpModal
      onClosed={onClosed}
      isOpen={isOpen}
      toggle={toggle}
      title={
        tranche?.id
          ? t("action:edit_billing_tranche")
          : t("action:new_billing_tranche")
      }
    >
      <div>
        <div className="d-flex flex-row align-items-start my-4">
          <div className="d-flex flex-column ml-2 flex-1">
            <Formik
              initialValues={{ ...INITIAL_VALUES, ...tranche }}
              validationSchema={schema}
              onSubmit={(values, actions) => {
                const cleanedValues = mapValues(values, (val) =>
                  val === undefined ? null : val
                )
                if (cleanedValues.value_mode === "fixed") {
                  delete cleanedValues.amount_percent
                } else {
                  delete cleanedValues.amount_fixed
                }
                return onSave(cleanedValues).catch((error) => {
                  actions.setErrors(convertErrorToForm(error))
                })
              }}
              enableReinitialize={true}
            >
              {(formik) => (
                <>
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Field
                      name="date"
                      component={FieldInput}
                      type="date"
                      label={t("field:billing_tranche.date")}
                      className={"mb-5"}
                    />
                    <Field
                      name="value_mode"
                      component={FieldDropdown}
                      className={"mb-5"}
                      placeholder={t(
                        "field:billing_tranche.placeholder.value_mode"
                      )}
                      label={t("field:billing_tranche.value_mode")}
                      options={[
                        {
                          label: t("enums:billing_tranche_mode.fixed"),
                          value: "fixed",
                        },
                        {
                          label: t("enums:billing_tranche_mode.percent"),
                          value: "percent",
                        },
                      ]}
                    />
                    {formik.values.value_mode === "percent" && (
                      <Field
                        name="amount_percent"
                        component={FieldInput}
                        min="0.00"
                        step="0.01"
                        className={"mb-5"}
                        placeholder={t(
                          "field:billing_tranche.placeholder.amount_percent"
                        )}
                        label={t("field:billing_tranche.amount_percent")}
                      />
                    )}
                    {formik.values.value_mode === "fixed" && (
                      <Field
                        name="amount_fixed"
                        component={FieldInput}
                        min="0.00"
                        step="0.01"
                        className={"mb-5"}
                        placeholder={t(
                          "field:billing_tranche.placeholder.amount_fixed"
                        )}
                        label={t("field:billing_tranche.amount_fixed")}
                      />
                    )}
                    <Field
                      name="description"
                      component={FieldTextarea}
                      className={"mb-5"}
                      placeholder={t(
                        "field:billing_tranche.placeholder.description"
                      )}
                      label={t("field:billing_tranche.description")}
                    />
                    <div className="d-flex flex-row align-items-center justify-content-end mt-6 mr-2">
                      <Button size="sm" color="none" onClick={toggle}>
                        {t("action:cancel")}
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        type="submit"
                        className="ml-5"
                        disabled={!formik.isValid}
                      >
                        {tranche?.id ? t("action:save") : t("action:add")}
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </WpModal>
  )
}

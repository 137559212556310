import React, { useMemo } from "react"
import RichTextEditor from "../../../../../components/RichTextEditor/RichTextEditor"
import CirclePack from "../../../EstimateVisualization/EstimateBubble/CirclePack"
import { pagerLog } from "../../Pager"
import {
  EstimateBubbleLegend,
  transformTree,
} from "../../../EstimateVisualization/EstimateBubble/EstimateBubble"
import { mm2px } from "../../utils"
import { useTranslation } from "react-i18next"

const FragComponent = React.memo(
  ({
    frag,
    estimate,
    width,
    inPage,
    chunkIsFirst,
    showChart = true,
    showLegend = true,
  }) => {
    if (inPage) {
      pagerLog("[PAGER] Bubble chart rendered")
    }
    const { t } = useTranslation(["translation"])
    const options = useMemo(
      () => ({
        displayPrices: false, // frag.data.showPrices ?? false,
      }),
      []
    )

    let height = width
    if (frag.data?.height) {
      height = mm2px(frag.data.height)
    }

    const hasTasks = estimate.task_tree.length > 0

    return (
      <div className="">
        {frag?.data?.showTitle && chunkIsFirst && (
          <h1 className="__pager-title m-0 pb-3">{frag?.title}</h1>
        )}
        {!hasTasks && (
          <div className="__pager-disclaimer disclaimer">
            {t("documents.no_visible_tasks")}
          </div>
        )}
        {showChart && hasTasks && (
          <div className="bg-white overflow-hidden text-center">
            <div className="__pager-chart">
              <CirclePack
                width={width}
                height={height}
                data={transformTree(
                  estimate.task_tree,
                  frag.data.maxDepth,
                  frag.data.visibleTasks
                )}
                options={options}
              />
            </div>
          </div>
        )}
        {showChart && frag.data.enableCaption && hasTasks && (
          <div
            style={{ flex: "1 1 0%", minWidth: 0, paddingLeft: 4 }}
            className="__pager-caption"
          >
            <RichTextEditor
              content={frag?.data?.caption}
              readOnly={true}
              placeholder={""}
              enableImages={false}
              minHeight0
            />
          </div>
        )}
        {showLegend && frag.data.showLegend && hasTasks && (
          <div className="__pager-legend">
            <EstimateBubbleLegend
              estimate={estimate}
              maxDepth={frag.data.maxDepth}
              extent={frag.data.visibleTasks}
              columns={2}
            />
          </div>
        )}
      </div>
    )
  },
  (prevProps, nextProps) => {
    const checkProps = [
      "frag",
      "estimate",
      "width",
      "chunkIsFirst",
      "showChart",
      "showLegend",
    ]
    for (let i = 0; i < checkProps.length; i++) {
      const prop = checkProps[i]
      if (prevProps[prop] !== nextProps[prop]) {
        return false
      }
    }
    return true
  }
)

export default FragComponent

import Avatar1 from "../assets/avatar/avatar1.png"
import Avatar2 from "../assets/avatar/avatar2.png"
import Avatar3 from "../assets/avatar/avatar3.png"
import Avatar4 from "../assets/avatar/avatar4.png"
import Avatar5 from "../assets/avatar/avatar5.png"
import Avatar6 from "../assets/avatar/avatar6.png"

import Placeholder1 from "../assets/placeholder/placeholder1.png"
import Placeholder2 from "../assets/placeholder/placeholder2.png"
import Placeholder3 from "../assets/placeholder/placeholder3.png"
import Placeholder4 from "../assets/placeholder/placeholder4.png"
import Placeholder5 from "../assets/placeholder/placeholder5.png"
import Placeholder6 from "../assets/placeholder/placeholder6.png"
import Placeholder7 from "../assets/placeholder/placeholder7.png"
import Placeholder8 from "../assets/placeholder/placeholder8.png"
import Placeholder9 from "../assets/placeholder/placeholder9.png"
import Placeholder10 from "../assets/placeholder/placeholder10.png"
import Placeholder11 from "../assets/placeholder/placeholder11.png"

const Avatars = [Avatar1, Avatar2, Avatar3, Avatar4, Avatar5, Avatar6]
const Placeholders = [
  Placeholder1,
  Placeholder2,
  Placeholder3,
  Placeholder4,
  Placeholder5,
  Placeholder6,
  Placeholder7,
  Placeholder8,
  Placeholder9,
  Placeholder10,
  Placeholder11,
]

/**
 * @param {"avatar"|"tile"} kind
 * @param {number} entropy
 */
export default function ImagePlaceholder(kind, entropy) {
  if (kind === "avatar") {
    return Avatars[entropy % Avatars.length]
  }
  if (kind === "tile") {
    return Placeholders[entropy % Placeholders.length]
  }
}

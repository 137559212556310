import classNames from "classnames"
import { keyBy, uniq } from "lodash"
import useQueryParams from "magik-react-hooks/useRouterDebounceQueryParams"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../components/Button"
import { EstimateLandmarks } from "../../components/EstimateLandmarks"
import Icon from "../../components/Icon"
import Layout from "../../components/Layout"
import Loader from "../../components/Loader/Loader"
import Pagination from "../../components/Pagination/Pagination"
import PriceListCreateModal from "../../components/PriceListCreateModal"
import Separator from "../../components/Separator/Separator"
import WpInput from "../../components/WpInput"
import { usePriceLists } from "../../hooks/priceList"
import { useResourcesList } from "../../hooks/resources"
import { getCurrencyFormatter } from "../../hooks/useCurrencyFormatter"
import useModalTrigger from "../../hooks/useModalTrigger"
import ResourceDetail from "../ResourcesList/ResourceDetail"
import S from "./PriceLists.module.scss"
import ButtonFavorite from "../../components/ButtonFavorite"

function PriceRow({ unit, initialCost, initialPrice, currencyFmt, isLast, displayCost = true }) {
  const [price, setPrice] = useState(initialPrice ?? "")
  const [cost, setCost] = useState(initialCost ?? "")

  const { t } = useTranslation()

  useEffect(() => {
    setPrice(initialPrice ?? "")
  }, [initialPrice])

  useEffect(() => {
    setCost(initialCost ?? "")
  }, [initialCost])

  return (
    <React.Fragment key={unit}>
      <div className={classNames("d-flex justify-content-between align-items-center py-2")}>
        <div className="flex-3">{unit}</div>
        <div className="flex-2 d-flex flex-row justify-content-end align-items-center mr-4">
          {displayCost && (
            <>
              <WpInput
                value={cost}
                onChange={(e) => {
                  setCost(e.target.value)
                }}
                placeholder={currencyFmt.formatNoUnit(0.0)}
                type="number"
                tiny
                clear
                className={classNames("w-100 text-right")}
                disabled
                title={t("field:resource.price_list.unitary_cost")}
              />
              {" " + currencyFmt.currencySymbol}
            </>
          )}
        </div>
        <div className="flex-2 d-flex flex-row justify-content-end align-items-center">
          <WpInput
            value={price}
            onChange={(e) => {
              setPrice(e.target.value)
            }}
            placeholder={currencyFmt.formatNoUnit(0)}
            type="number"
            tiny
            clear
            className={classNames("w-100 text-right")}
            disabled
            title={t("field:resource.price_list.unitary_price")}
          />
          {" " + currencyFmt.currencySymbol}
        </div>
      </div>
      {!isLast && <Separator className="px-3 my-0" />}
    </React.Fragment>
  )
}

export default function PriceLists() {
  const [
    { page, resourceFilter },
    setQueryParams,
    { resourceFilter: debResourceFilter },
    setDebouncedQueryParams,
  ] = useQueryParams({
    page: {
      encode: (intValue) => intValue.toString(10),
      decode: (strValue) => (strValue ? parseInt(strValue, 10) : 1),
    },
  })

  const resourcesFilters = useMemo(
    () => ({
      embed_prices: true,
      estimate: "none",
      page,
      search: debResourceFilter,
      show_archived: false,
    }),
    [page, debResourceFilter]
  )

  const { t } = useTranslation(["translation", "field", "enums", "action"])

  const [{ data: priceLists }, { create, update, run: refresh }] = usePriceLists()

  const [{ resources, pageCount, hasNext, hasPrev }, { updateResource }] = useResourcesList(resourcesFilters)

  const [selectedResource, setSelectedResource] = useState(null)
  const [selectedPriceList, setSelectedPriceList] = useState(null)

  const [createModalState, createModalActions] = useModalTrigger()

  return (
    <Layout showDrawer={selectedResource !== null}>
      {resources === null && <Loader />}
      {resources !== null && (
        <div className="pt-8 pb-page-bottom">
          <h1 className="text-primary font-weight-semibold px-page">{t("price_lists.title")}</h1>
          <div className="d-flex flex-row justify-content-start align-items-center px-page mt-6">
            <Button
              onClick={() => {
                createModalActions.open("dummy")
              }}
            >
              {t("action:create_price_list")}
            </Button>
            <div className="flex-1 filler" />
            <div className="d-flex flex-row align-items-center justify-content-center ml-8">
              <Icon name="search" className="mr-3" width={16} height={16} />
              <WpInput
                placeholder={t("field:resource.placeholder.search")}
                style={{ width: 235 }}
                value={resourceFilter}
                medium
                onChange={(e) =>
                  setDebouncedQueryParams({
                    resourceFilter: e.target.value,
                    page: 1,
                  })
                }
              />
            </div>
          </div>
          <div className={S["scrolling-viewport"]}>
            {priceLists?.map((priceList) => {
              const currencyFmt = getCurrencyFormatter(priceList.currency)
              return (
                <div className={S["card"]} key={priceList.id}>
                  <div className={S["header"]}>
                    <h2>{priceList.title}</h2>
                    <ButtonFavorite
                      active={priceList.is_default}
                      className="text-white"
                      positiveTooltip={t("action:set_default")}
                      negativeTooltip={t("action:unset_default")}
                      onChange={(isDefault) => {
                        update.asPromise(priceList.id, { is_default: isDefault }).then(() => {
                          refresh()
                        })
                      }}
                    />
                  </div>
                  {resources.map((resource) => {
                    const units = uniq(resource.price_list.map((entry) => entry.cost_unit))
                    const pricings = resource.price_list.filter((entry) => entry.price_list === priceList.id)
                    const pricingsMap = keyBy(pricings, "cost_unit")
                    return (
                      <div className={S["body"]} key={resource.id}>
                        <h3>
                          <Icon
                            name={resource.resource_type}
                            title={t(`enums:resource_type.${resource.resource_type}`)}
                            style={{ width: 24, height: 24, marginRight: 4 }}
                          />
                          {resource.name}
                          <span className="flex-1" />
                          <Icon
                            name="edit"
                            title={t("action:edit_resource")}
                            className={classNames("pointer text-dark", S["edit-icon"])}
                            onClick={() => {
                              setSelectedResource(resource)
                              setSelectedPriceList(priceList)
                            }}
                          />
                        </h3>
                        {units.map((unit, i) => {
                          const price = pricingsMap[unit]?.unitary_price ?? null
                          const cost = pricingsMap[unit]?.unitary_cost ?? null
                          return (
                            <PriceRow
                              key={unit}
                              unit={unit}
                              resourceType={resource.resource_type}
                              initialCost={cost}
                              initialPrice={price}
                              currencyFmt={currencyFmt}
                              isLast={i === units.length - 1}
                            />
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>

          <div className="px-page">
            <Pagination
              current={page}
              pageCount={pageCount}
              goToPage={(page) => {
                setQueryParams({ page })
                window.scrollTo(0, 10)
              }}
              hasNext={hasNext}
              hasPrev={hasPrev}
            />
          </div>
        </div>
      )}
      {createModalState.value && (
        <PriceListCreateModal
          isOpen={createModalState.isOpen}
          toggle={createModalActions.toggle}
          onClosed={createModalActions.onClose}
          onSave={(payload) => {
            return create
              .onSuccess(() => {
                createModalActions.close()
              })
              .asPromise(payload)
          }}
        />
      )}
      <Layout.Drawer>
        {selectedResource && selectedPriceList && (
          <ResourceDetail
            resource={selectedResource}
            priceList={selectedPriceList}
            onSave={updateResource}
            onCancel={() => {
              setSelectedResource(null)
              setSelectedPriceList(null)
            }}
            // onDelete={() => deleteModalActions.open(selectedResource)}
            // onArchive={() => onResourceArchive(selectedResource)}
          />
        )}
      </Layout.Drawer>
      <Layout.FirstLevelNavi>
        <EstimateLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

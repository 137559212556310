import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import Slider from "rc-slider"
import { getDepth } from "react-sortable-tree"
import { keyBy, range } from "lodash"
import TaskTable from "../TaskTable/TaskTable"

function TableView({ estimate, fullEstimate, selectedScenarios, onEditTaskClick }) {
  const { t } = useTranslation(["translation"])

  const depth = useMemo(() => getDepth({ children: estimate.task_tree }), [
    estimate.task_tree,
  ])
  const marks = useMemo(() => {
    const values = range(1, depth + 1).map((v) => {
      if (v < depth) {
        return {
          id: v,
          label: t("tasks.level", { level: v }),
        }
      }
      return {
        id: v,
        label: t("tasks.all"),
      }
    })
    return keyBy(values, "id")
  }, [depth, t])
  const [maxDepth, setMaxDepth] = useState(depth)
  const [isResourceVisible, setResourceVisible] = useState(true)
  const [isCostVisible, setCostVisible] = useState(true)
  const [isScenarioVisible, setScenarioVisible] = useState(true)

  return (
    <>
      <div className="mt-8 mb-7">
        <p>{t("tasks.table_description")}</p>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            {depth > 1 && (
              <Slider
                min={1}
                marks={marks}
                step={null}
                max={depth}
                onChange={setMaxDepth}
                defaultValue={depth}
                style={{ width: 90 * (depth - 1) }}
                trackStyle={{ backgroundColor: "var(--primary)" }}
                // dotStyle={{
                //   backgroundColor: "var(--separator)",
                //   width: 2,
                //   marginLeft: -1,
                //   borderRadius: 1,
                //   border: 0
                // }}
                handleStyle={{
                  border: "2px solid var(--primary)",
                  height: 16,
                  width: 16,
                  marginTop: -6,
                }}
                activeDotStyle={{
                  borderColor: "transparent",
                  backgroundColor: "var(--primary)",
                }}
              />
            )}
          </div>

          <div className="d-flex flex-row align-items-center">
            <input
              type="checkbox"
              checked={isResourceVisible}
              onChange={(e) => setResourceVisible(e.target.checked)}
            />
            <label className="mb-0 mx-3 pr-8">
              {t("tasks.show_resources")}
            </label>
            <input
              type="checkbox"
              checked={isCostVisible}
              onChange={(e) => setCostVisible(e.target.checked)}
            />
            <label className="mb-0 mx-3">{t("tasks.show_cost")}</label>
            <input
              type="checkbox"
              checked={isScenarioVisible}
              onChange={(e) => setScenarioVisible(e.target.checked)}
            />
            <label className="mb-0 mx-3">{t("tasks.show_scenario")}</label>
          </div>
        </div>
      </div>
      <TaskTable
        taskTree={estimate.task_tree}
        resources={estimate.resources}
        maxDepth={maxDepth}
        isResourceVisible={isResourceVisible}
        isCostVisible={isCostVisible}
        isScenarioVisible={isScenarioVisible}
        estimateId={estimate.id}
        onEditTaskClick={onEditTaskClick}
        readOnly={estimate.readonly}
        scenarios={estimate.scenarios}
        selectedScenarios={selectedScenarios}
        fullEstimate={fullEstimate}
        use24={true}
      />
    </>
  )
}

export default React.memo(TableView)

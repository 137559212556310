import * as chroma from "chroma-js"
import { scaleLinear } from "d3-scale"

export function convertColorToTheme(color) {
  const c = chroma(color)
  return [c.get("rgb.r"), c.get("rgb.g"), c.get("rgb.b")].join(",")
}

let mm2pxScale = null

export function mm2px(mm) {
  if (mm2pxScale === null) {
    const div = document.createElement("div")
    div.style.width = "300mm"
    document.body.appendChild(div)
    const width = parseInt(getComputedStyle(div).width, 10)
    div.remove()
    mm2pxScale = scaleLinear().domain([0, 300]).range([0, width])
  }
  return mm2pxScale(mm)
}
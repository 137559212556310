export function TextWithBadge({ children, badge, enable = true }) {
  return (
    <span className="position-relative">
      {children}
      {enable && (
        <small
          style={{
            position: "absolute",
            top: -8,
            right: -12,
            background: "var(--danger)",
            display: "flex",
            color: "white",
            width: 16,
            height: 16,
            borderRadius: 8,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          {badge}
        </small>
      )}
    </span>
  )
}

import React from "react"
import classNames from "classnames"
import ColorPickerPopover from "../ColorPickerPopover"

export default function FieldColorPicker({
  label,
  labelClassname,
  field,
  form,
  ...props
}) {
  return (
    <div className="d-flex flex-column">
      {label && (
        <label
          className={classNames(
            "font-weight-semibold mb-3 h3 text-uppercase",
            labelClassname
          )}
        >
          {label}
        </label>
      )}
      <ColorPickerPopover
        value={field.value}
        onChange={(value) => form.setFieldValue(field.name, value)}
        {...props}
      />
    </div>
  )
}

import classNames from "classnames"
import dayjs from "dayjs"
import { groupBy, map } from "lodash"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"
import { useAuthUser } from "use-eazy-auth"
import Layout from "../../components/Layout"
import OrgUserSelector from "../../components/OrgUserSelector/OrgUserSelector"
import { TrackerLandmarks } from "../../components/TrackerLandmarks"
import { useProjectActivityCalendar } from "../../hooks/projectActivities"
import useCurrentOrganization from "../../hooks/useCurrentOrganization"
import S from "./style.module.scss"

export function TrackerCalendar() {
  const { t } = useTranslation()
  const { user } = useAuthUser()
  const org = useCurrentOrganization()

  const today = dayjs()
  const [[month, year], setMonthAndYear] = useState([today.get("month"), today.get("year")])
  const [selectedDate, setSelectedDate] = useState(today)
  const [selectedUserId, setSelectedUserId] = useState(user.id)

  const firstDayOfMonth = dayjs().set("date", 1).set("month", month).set("year", year)
  const lastDayOfMonth = firstDayOfMonth.endOf("month")

  const firstDayOfMonthISO = firstDayOfMonth.format("YYYY-MM-DD")
  const lastDayOfMonthISO = lastDayOfMonth.format("YYYY-MM-DD")

  const firstDateOfMonth = firstDayOfMonth.get("day")
  const firstDateOfMonthNormalized = (firstDateOfMonth + 6) % 7

  const daysInMonth = firstDayOfMonth.daysInMonth()

  const calendar = new Array(6).fill(0).map(() => {
    return new Array(7).fill("")
  })

  for (let i = 1; i <= daysInMonth; i++) {
    const offset = firstDateOfMonthNormalized + i - 1
    const row = Math.floor(offset / 7)
    const cell = offset % 7
    calendar[row][cell] = i
  }

  const filter = useMemo(
    () => ({
      date_start__gte: firstDayOfMonthISO,
      date_start__lte: lastDayOfMonthISO,
      subject: selectedUserId,
    }),
    [firstDayOfMonthISO, lastDayOfMonthISO, selectedUserId]
  )

  const [{ activities }] = useProjectActivityCalendar(filter)

  const indexedActivities = useMemo(() => groupBy(activities, "date_start"), [activities])

  const selectedDateISO = selectedDate.format("YYYY-MM-DD")

  return (
    <Layout>
      <div className="container-fluid pt-5 px-page pb-page-bottom">
        <div className="d-flex flex-row justify-content-between align-items-center mb-6">
          <h1 className="text-primary">{t("navbar.tracker_calendar")}</h1>
        </div>
        <div className="my-5">
          <span className="mr-3">{t("calendar.filter_user")}</span>
          <OrgUserSelector
            orgId={org.id}
            userId={selectedUserId}
            onMemberChange={(member) => {
              setSelectedUserId(member?.id)
            }}
          />
        </div>
        <div className={S.calendarWrapper}>
          <div className={S.calendar}>
            <div className={classNames(S.calendarRow, S.calendarHeader)}>
              <div
                className={classNames(S.calendarCell, "pointer")}
                onClick={() => {
                  if (month === 0) {
                    setMonthAndYear([11, year - 1])
                  } else {
                    setMonthAndYear([month - 1, year])
                  }
                }}
              >
                <FaArrowLeft />
              </div>
              <div
                className={classNames(S.calendarCell, "pointer")}
                onClick={() => {
                  setMonthAndYear([today.get("month"), today.get("year")])
                }}
              >
                {firstDayOfMonth.locale(user.lang).format("MMMM YYYY")}
              </div>
              <div
                className={classNames(S.calendarCell, "pointer")}
                onClick={() => {
                  if (month === 11) {
                    setMonthAndYear([0, year + 1])
                  } else {
                    setMonthAndYear([month + 1, year])
                  }
                }}
              >
                <FaArrowRight />
              </div>
            </div>
            <div className={classNames(S.calendarRow, S.calendarDays)}>
              <div className={S.calendarCell}>{dayjs("2022-08-01").locale(user.lang).format("dd")}</div>
              <div className={S.calendarCell}>{dayjs("2022-08-02").locale(user.lang).format("dd")}</div>
              <div className={S.calendarCell}>{dayjs("2022-08-03").locale(user.lang).format("dd")}</div>
              <div className={S.calendarCell}>{dayjs("2022-08-04").locale(user.lang).format("dd")}</div>
              <div className={S.calendarCell}>{dayjs("2022-08-05").locale(user.lang).format("dd")}</div>
              <div className={S.calendarCell}>{dayjs("2022-08-06").locale(user.lang).format("dd")}</div>
              <div className={S.calendarCell}>{dayjs("2022-08-07").locale(user.lang).format("dd")}</div>
            </div>
            {calendar.map((row, i) => {
              return (
                <div className={S.calendarRow} key={i}>
                  {row.map((cell, j) => {
                    if (cell === "") {
                      return (
                        <div className={S.calendarCell} key={j}>
                          {cell}
                        </div>
                      )
                    }
                    const dateJs = firstDayOfMonth.add(cell - 1, "day")
                    const dateISO = dateJs.format("YYYY-MM-DD")
                    const isSelected = selectedDate.isSame(dateJs, "day")
                    return (
                      <div
                        className={classNames(S.calendarCell, "pointer", {
                          [S.selected]: isSelected,
                          [S.active]: indexedActivities[dateISO] !== undefined,
                        })}
                        onClick={() => {
                          setSelectedDate(dateJs)
                        }}
                        key={j}
                      >
                        {cell}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
        <div>
          <h2 className="text-primary">
            {t("calendar.activities_title", { date: selectedDate.locale(user.lang).format("L") })}
          </h2>
          {indexedActivities[selectedDateISO] &&
            map(groupBy(indexedActivities[selectedDateISO], "project"), (sameProjActivities, projectId) => {
              return (
                <div key={projectId} className="mt-4">
                  <h3>
                    [{sameProjActivities[0].project_code}] {sameProjActivities[0].project_name}
                  </h3>
                  <table className="w-100 table table-sm bordered">
                    <tbody>
                      {sameProjActivities.map((activity) => (
                        <tr key={activity.id}>
                          <td className="w-25">
                            {activity.size} {activity.priced_resource_data.cost_unit}
                          </td>
                          <td className="w-25">{activity.priced_resource_data.resource_name}</td>
                          <td className="w-50">{activity.description}</td>
                        </tr>
                      ))}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            })}
        </div>
      </div>
      <Layout.FirstLevelNavi>
        <TrackerLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

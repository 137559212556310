import React from "react"
import { useTranslation } from "react-i18next"
import Button from "../../../../components/Button"
import Icon from "../../../../components/Icon"

export default function EmptyTasks({ toggleShowAddTask, readOnly }) {
  const { t } = useTranslation(["translation", "action", "field"])
  return (
    <div className="mt-8 ml-8">
      {!readOnly && (
        <>
          <p className="mt-7">{t("tasks.create_task_message")}</p>
          <div
            className="d-flex flex-row align-items-center pointer"
            onClick={toggleShowAddTask}
          >
            <Button color="primary" size="lg" rounded>
              <Icon
                name="plus"
                className="pointer"
                title={t("action:new_task")}
              />
            </Button>
            <span className="ml-4">{t("tasks.create_task")}</span>
          </div>
        </>
      )}
      {readOnly && (
        <p className="mt-7 text-center">
          <i>{t("tasks.empty_task_message_read_only")}</i>
        </p>
      )}
    </div>
  )
}

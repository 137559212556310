import chroma from "chroma-js"
import React from "react"
import { useTranslation } from "react-i18next"
import { BsPencilFill, BsTrash } from "react-icons/bs"
import Button from "../../components/Button"
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal"
import Layout from "../../components/Layout"
import { SettingsLandmarks } from "../../components/SettingsLandmarks"
import { useTags } from "../../hooks/tags"
import useCurrentOrganization from "../../hooks/useCurrentOrganization"
import useModalTrigger from "../../hooks/useModalTrigger"
import { EditCategoryModal } from "./EditCategoryModal"
import { EditTagModal } from "./EditTagModal"

export function ManageTags() {
  const { t } = useTranslation()
  const org = useCurrentOrganization()

  const [
    { tagCategories },
    { createCategory, updateCategory, createTag, updateTag, removeCategory, removeTag },
  ] = useTags()

  const [editCategoryModalState, editCategoryModalActions] = useModalTrigger()
  const [editTagModalState, editTagModalActions] = useModalTrigger()
  const [deleteCategoryModalState, deleteCategoryModalActions] = useModalTrigger()
  const [deleteTagModalState, deleteTagModalActions] = useModalTrigger()

  return (
    <Layout>
      <div className="container-fluid pt-5 px-page pb-page-bottom">
        <div className="d-flex flex-row justify-content-between align-items-center mb-6">
          <h1 className="text-primary">{t("tags.title")}</h1>
        </div>
        <div>
          {tagCategories?.map((cat) => {
            return (
              <div key={cat.id} className="mb-5">
                <div className="d-flex flex-row align-items-center mb-2">
                  <h2>{cat.name}</h2>
                  <BsPencilFill
                    className="ml-6 pointer"
                    onClick={() => {
                      editCategoryModalActions.open(cat)
                    }}
                  />
                  <BsTrash
                    className="ml-6 pointer"
                    onClick={() => {
                      deleteCategoryModalActions.open(cat)
                    }}
                  />
                </div>
                <div className="d-flex flex-row flex-wrap align-items-center px-3" style={{ gap: 10 }}>
                  {cat.tags.map((tag) => {
                    let textColor = "black"
                    if (chroma.contrast("white", tag.color) > chroma.contrast("black", tag.color)) {
                      textColor = "white"
                    }
                    return (
                      <div
                        key={tag.id}
                        className="px-3 py-2 rounded"
                        style={{ backgroundColor: cat.color, color: textColor }}
                      >
                        <span>{tag.name}</span>
                        <BsPencilFill
                          className="ml-4 pointer"
                          onClick={() => {
                            editTagModalActions.open(tag)
                          }}
                          style={{ color: textColor }}
                        />
                        <BsTrash
                          className="ml-4 pointer"
                          onClick={() => {
                            deleteTagModalActions.open(tag)
                          }}
                          style={{ color: textColor }}
                        />
                      </div>
                    )
                  })}
                  <Button
                    size="sm"
                    onClick={() => {
                      editTagModalActions.open({ category: cat.id })
                    }}
                  >
                    Nuovo tag
                  </Button>
                </div>
              </div>
            )
          })}
          <Button
            size="sm"
            onClick={() => {
              editCategoryModalActions.open({ owner_org: org.id })
            }}
          >
            Nuova categoria
          </Button>
        </div>
      </div>
      {editCategoryModalState.value && (
        <EditCategoryModal
          category={editCategoryModalState.value}
          isOpen={editCategoryModalState.isOpen}
          onClosed={editCategoryModalActions.onClose}
          toggle={editCategoryModalActions.toggle}
          onSave={(result) => {
            return (result.id ? updateCategory.curry(result.id) : createCategory)
              .onSuccess(() => {
                editCategoryModalActions.close()
              })
              .asPromise(result)
          }}
        />
      )}
      {editTagModalState.value && (
        <EditTagModal
          tag={editTagModalState.value}
          isOpen={editTagModalState.isOpen}
          onClosed={editTagModalActions.onClose}
          toggle={editTagModalActions.toggle}
          onSave={(result) => {
            return (result.id ? updateTag.curry(result.id) : createTag)
              .onSuccess(() => {
                editTagModalActions.close()
              })
              .asPromise(result)
          }}
        />
      )}
      {deleteCategoryModalState.value && (
        <ConfirmDeleteModal
          toggle={deleteCategoryModalActions.toggle}
          isOpen={deleteCategoryModalState.isOpen}
          onConfirm={() => {
            removeCategory
              .onSuccess(() => {
                deleteCategoryModalActions.close()
              })
              .run(deleteCategoryModalState.value.id)
          }}
          onClosed={deleteCategoryModalActions.onClose}
          item={deleteCategoryModalState.value.name}
        />
      )}
      {deleteTagModalState.value && (
        <ConfirmDeleteModal
          toggle={deleteTagModalActions.toggle}
          isOpen={deleteTagModalState.isOpen}
          onConfirm={() => {
            removeTag
              .onSuccess(() => {
                deleteTagModalActions.close()
              })
              .run(deleteTagModalState.value.id)
          }}
          onClosed={deleteTagModalActions.onClose}
          item={deleteTagModalState.value.name}
        />
      )}
      <Layout.FirstLevelNavi>
        <SettingsLandmarks />
      </Layout.FirstLevelNavi>
    </Layout>
  )
}

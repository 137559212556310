import React from "react"
import { Link } from "react-router-dom"
import useCurrentOrganization from "../hooks/useCurrentOrganization"
import { prefixLocationWithOrg } from "../utils"

/**
 * Hacked version of <Link /> used to inject org segment
 */
export default function WpLink({ to, ...props }) {
  const organization = useCurrentOrganization()
  return <Link to={prefixLocationWithOrg(to, organization?.id)} {...props} />
}

import { useCallback } from "react"
import { rj, useRj } from "react-rocketjump"
import api from "../api"

const CustomerPortalState = rj({
  effectCaller: rj.configured(),
  effect: (wpAuth) => (returnUrl) =>
    api.auth(wpAuth).get("/api/payments/stripe/portal", {
      return_url: returnUrl,
    }),
})

/**
 * @returns {(returnUrl: string, cb: (portal: { url: string }) => void) => void}
 */
export default function useCustomerPortal() {
  const [, { run }] = useRj(CustomerPortalState)
  return useCallback(
    (returnUrl, cb) => {
      run.onSuccess(cb).run(returnUrl)
    },
    [run]
  )
}

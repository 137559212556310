import { useMemo } from "react"
import omit from "lodash/omit"
import { useAuthUser, useAuthActions } from "use-eazy-auth"
import { rj, useRj } from "react-rocketjump"
import api from "../api"

const UpdateUserState = rj({
  effectCaller: rj.configured(),
  name: "UpdateUser",
  effect: (wpAuth) => (userData) => {
    const formData = new FormData()
    for (const k in userData) {
      const value = userData[k]
      if (value !== null && value !== undefined) {
        formData.append(k, value)
      } else {
        formData.append(k, "")
      }
    }
    return api.auth(wpAuth).patch("/api/me", formData)
  },
})

const ChangeUserPassword = rj({
  effectCaller: rj.configured(),
  name: "ChangePassword",
  effect: (wpAuth) => (oldPassword, newPassword) => {
    return api.auth(wpAuth).post(`/api/account/change-password/`, {
      old_password: oldPassword,
      new_password: newPassword,
    })
  },
})

const CreateOrganization = rj({
  effectCaller: rj.configured(),
  name: "CreateOrganization",
  effect: (wpAuth) => (orgData) => {
    // TODO: Make an helper 4 form data
    const formData = new FormData()
    for (const k in orgData) {
      const value = orgData[k]
      if (value !== null && value !== undefined) {
        formData.append(k, value)
      } else {
        formData.append(k, "")
      }
    }
    return api.auth(wpAuth).post(`/api/organization`, formData)
  },
})

const DeleteOrganization = rj({
  effectCaller: rj.configured(),
  name: "DeleteOrganization",
  effect: (wpAuth) => (id) => {
    return api
      .auth(wpAuth)
      .mapResponse(() => id)
      .delete(`/api/organization/${id}`)
  },
})

const DeleteProfile = rj({
  effectCaller: rj.configured(),
  name: "DeleteProfile",
  effect: (wpAuth) => (password) => {
    return api.auth(wpAuth).post(`/api/account/delete/`, {
      password,
    })
  },
})

const ResendVerificationEmail = rj({
  effectCaller: rj.configured(),
  name: "ResendVerificationEmail",
  effect: (wpAuth) => () => {
    return api.auth(wpAuth).post(`/api/account/resend-verification/`)
  },
})

const LeaveOrganization = rj({
  effectCaller: rj.configured(),
  name: "LeaveOrganization",
  effect: (wpAuth) => (id) => {
    return api
      .auth(wpAuth)
      .mapResponse(() => id)
      .post(`/api/organization/${id}/leave/`)
  },
})

const VerifyEmail = rj({
  effectCaller: rj.configured(),
  name: "VerifyEmail",
  effect: (wpAuth) => (token) => {
    return api.auth(wpAuth).post(`/api/account/verify/`, { token })
  },
})

// TODO(giovanni): Questo è copiato da authCalls.js, forse
// è meglio fare un helper?
function normalizeUserOrgs(user) {
  const { organizations, ...userData } = user
  return {
    ...userData,
    ...organizations.reduce(
      (normalizedOrgs, org) => ({
        organizationsIds: normalizedOrgs.organizationsIds.concat(org.id),
        organizationsById: {
          ...normalizedOrgs.organizationsById,
          [org.id]: org,
        },
      }),
      {
        organizationsIds: [],
        organizationsById: {},
      }
    ),
  }
}

function removeUserOrgs(user, rmOrgId) {
  return {
    ...user,
    organizationsIds: user.organizationsIds.filter(
      (orgId) => orgId !== rmOrgId
    ),
    organizationsById: omit(user.organizationsById, rmOrgId),
  }
}

export function useProfile() {
  const { user: profile } = useAuthUser()
  const { updateUser: updateUserState, setTokens, logout } = useAuthActions()

  const [{ pending: updating, error }, { run: runUserUpdate }] = useRj(
    UpdateUserState
  )
  const [
    { pending: updatingPassword, error: passwordUpdateError },
    { run: runUserChangePassword },
  ] = useRj(ChangeUserPassword)

  const [, { run: runCreateOrg }] = useRj(CreateOrganization)
  const [, { run: runDeleteOrg }] = useRj(DeleteOrganization)
  const [, { run: runLeaveOrg }] = useRj(LeaveOrganization)
  const [, { run: runVerifyEmail }] = useRj(VerifyEmail)
  const [, { run: runDeleteProfile }] = useRj(DeleteProfile)
  const [, { run: resendVerificationEmail }] = useRj(ResendVerificationEmail)

  const updateProfile = useMemo(
    () =>
      runUserUpdate
        .onSuccess((profile) => {
          updateUserState(normalizeUserOrgs(profile))
        })
        .curry(),
    [runUserUpdate, updateUserState]
  )

  const createOrganization = useMemo(
    () =>
      runCreateOrg
        .onSuccess((org) => {
          updateUserState({
            ...profile,
            organizationsById: { ...profile.organizationsById, [org.id]: org },
            organizationsIds: profile.organizationsIds.concat(org.id),
          })
        })
        .curry(),
    [profile, runCreateOrg, updateUserState]
  )

  const deleteOrganization = useMemo(
    () =>
      runDeleteOrg
        .onSuccess((orgId) => {
          updateUserState(removeUserOrgs(profile, orgId))
        })
        .curry(),
    [runDeleteOrg, updateUserState, profile]
  )

  const leaveOrganization = useMemo(
    () =>
      runLeaveOrg
        .onSuccess((orgId) => {
          updateUserState(removeUserOrgs(profile, orgId))
        })
        .curry(),
    [runLeaveOrg, updateUserState, profile]
  )

  const verifyEmail = useMemo(
    () => {
      return runVerifyEmail
        .onSuccess(() => {
          updateUserState({
            ...profile,
            email_verified: true
          })
        })
        .curry()
    },
    [profile, runVerifyEmail, updateUserState]
  )

  const deleteProfile = useMemo(() => {
    return runDeleteProfile
      .onSuccess(() => {
        logout()
      })
      .curry()
  }, [logout, runDeleteProfile])

  const changePassword = useMemo(
    () =>
      runUserChangePassword
        .onSuccess(({ token }) => {
          setTokens({ accessToken: token })
          updateUserState({
            ...profile,
            has_password: true,
          })
        })
        .curry(),
    [profile, runUserChangePassword, setTokens, updateUserState]
  )

  return [
    {
      pending: updating || updatingPassword,
      error: error ?? passwordUpdateError,
      profile,
    },
    {
      updateProfile,
      changePassword,
      createOrganization,
      deleteOrganization,
      leaveOrganization,
      verifyEmail,
      deleteProfile,
      resendVerificationEmail
    },
  ]
}

import { Field, useFormikContext } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import Button, { ButtonGroup } from "../../../../../components/Button"
import FieldSwitch from "../../../../../components/fields/FieldSwitch"
import Icon from "../../../../../components/Icon"
import EditDataFrame, {
  EditDataSection,
} from "../../EditDataFrame/EditDataFrame"

const EditDataFormInner = ({ t, readOnly }) => {
  const { values, setFieldValue } = useFormikContext()
  return (
    <EditDataSection title={t("field:document.resource_list.resources_conf")}>
      <p className="mb-2">
        {t("field:document.resource_list.show_type_message")}
      </p>
      <div className="text-center pt-3">
        <ButtonGroup>
          <Button
            active={values.data.showHR}
            onClick={() => setFieldValue("data.showHR", !values.data.showHR)}
            size="sm"
            disabled={readOnly}
          >
            <div className="d-flex flex-row align-items-center justify-content-start">
              <Icon
                name="hr"
                style={{ width: 20, height: 20, marginRight: 8 }}
              />
              {t(`enums:resource_type.hr`, { count: 2 })}
            </div>
          </Button>
          <Button
            active={values.data.showService}
            onClick={() =>
              setFieldValue("data.showService", !values.data.showService)
            }
            size="sm"
            disabled={readOnly}
          >
            <div className="d-flex flex-row align-items-center justify-content-start">
              <Icon
                name="service"
                style={{ width: 20, height: 20, marginRight: 8 }}
              />
              {t(`enums:resource_type.service`, { count: 2 })}
            </div>
          </Button>
          <Button
            active={values.data.showMaterial}
            onClick={() =>
              setFieldValue("data.showMaterial", !values.data.showMaterial)
            }
            size="sm"
            disabled={readOnly}
          >
            <div className="d-flex flex-row align-items-center justify-content-start">
              <Icon
                name="material"
                style={{ width: 20, height: 20, marginRight: 8 }}
              />
              {t(`enums:resource_type.material`, { count: 2 })}
            </div>
          </Button>
        </ButtonGroup>
      </div>
      <div className="mt-4">
        <Field
          name="data.groupByType"
          message={t("field:document.resource_list.show_group_message")}
          component={FieldSwitch}
          readOnly={readOnly}
        />
      </div>
      <div className="mt-4">
        <Field
          name="data.sortByType"
          message={t("field:document.resource_list.show_sort_message")}
          component={FieldSwitch}
          readOnly={readOnly}
        />
      </div>
      <div className="mt-4">
        <Field
          name="data.showUsingTasks"
          message={t("field:document.resource_list.show_tasks_message")}
          component={FieldSwitch}
          readOnly={readOnly}
        />
      </div>
      <div className="mt-4">
        <Field
          name="data.isPriceVisible"
          message={t("field:document.resource_list.show_price_message")}
          component={FieldSwitch}
          readOnly={readOnly}
        />
      </div>
      <div className="mt-4">
        <Field
          name="data.isUnitTableVisible"
          message={t("field:document.resource_list.show_unit_message")}
          component={FieldSwitch}
          readOnly={readOnly}
        />
      </div>
    </EditDataSection>
  )
}

const EditDataForm = ({
  fullEstimate,
  frag,
  save,
  remove,
  toggle,
  scrollFragIntoView,
}) => {
  const { t } = useTranslation(["translation", "enums"])
  const readOnly = fullEstimate.readonly

  return (
    <EditDataFrame
      contentType={t("documents.frag.resources.list.title")}
      frag={frag}
      save={save}
      remove={remove}
      toggle={toggle}
      scrollFragIntoView={scrollFragIntoView}
      readOnly={readOnly}
      scenarios={fullEstimate.scenarios}
    >
      <EditDataFormInner t={t} readOnly={readOnly} />
    </EditDataFrame>
  )
}

export default EditDataForm
